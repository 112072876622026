import NavbarItem from './NavbarItem/navbarItem.vue';

export default {
    data() {
        return {
            menuItems: [],
            languages: [],
            currentLanguage: {},
            currentUrl: '',
            isPrivate: payload.el === 'Private'
        }
    },
    computed: {
        currentLanguageIcon() {
            return 'dist/' + this.currentLanguage.Culture + '.png';
        }
    },
    props: [
        'container'
    ],
    mounted() {
        this.menuItems = payload.menuItems;
        this.languages = payload.languages;
        this.currentLanguage = payload.currentLocale;
        this.currentUrl = payload.currentUrl;

        // check menu item and add & remove item and put them in more item
        $(window).on('resize', () => {
            //Add timeout to show/hide sidebar before calculating place for menu items
            setTimeout(() => {  if (window.innerWidth > 768) { this.ckeckMenuItems(); } }, 80);
        });

        $('.js_sideToggle-sidebar').on('click', () => {
            setTimeout(() => { this.ckeckMenuItems(); }, 80);
        });
    },
    updated() {
        // check menu item and add & remove item and put them in more item
        this.$nextTick(() => this.ckeckMenuItems());
    },
    watch: {
        menuItems(val, oldVal) {
            if (!val.length || val.length === oldVal.length) return;
            setTimeout(() => { this.ckeckMenuItems(); }, 500);
        }
    },
    methods: {
        checkMenuMoreItem() {
            const moreItems = $(this.$el).find('.js_mainMenu .other-menu');

            if ($('.js_mainMenu .more li').length > 0)
                moreItems.removeClass('hidden');
            else
                moreItems.addClass('hidden');
        },
        getLastMenuItem() {
            return $(this.$el).find('.js_mainMenu > li:not(.more, .homeIcon)').last();
        },
        getFirstMenuItem() {
            return $(this.$el).find('.js_mainMenu li.more > ul > li').first();
        },
        countMenuItem() {
            return this.menuItems.length;
        },
        putItemToMainMenu(firstItem) {
            firstItem.insertBefore($(this.$el).find('.js_mainMenu li.more'));
        },
        putItemToMore(lastItem) {
            lastItem.attr('data-width', lastItem.outerWidth())
                .prependTo($(this.$el).find('.js_mainMenu .more > ul'));
        },
        putItemToSubmenu(value) {
            if ($(value).hasClass('dropdown')) {
                $(value).attr('class', 'dropdown-submenu js_hasSub');
            }
        },
        checkToOpenSubmenu() {
            const menuItem = $(this.$el).find('.js_mainMenu > li');
            const menuItemCount = menuItem.length;

            menuItem.removeClass('reverse');

            for (let i = Math.ceil(menuItemCount / 2) + 1; i <= menuItemCount; i++) {
                $(this.$el).find('.js_mainMenu > li:nth-child(' + i + ')').addClass('reverse');
            }
        },
        ckeckMenuItems() {
            let navwidth;

            // if menu in responsive mode and change to horizontal mode
            if (window.innerWidth < 768) {
                for (let i = 0; i < this.countMenuItem(); i++) {
                    const firstItem = this.getFirstMenuItem();
                    this.putItemToMainMenu(firstItem);
                }
                // check if dropdown menu for .more is empty or not
                this.checkMenuMoreItem();
                return;
            }

            // calculate available menu width
            let moreWidth = 0;
            if ($(this.$el).find('.js_mainMenu .more').is(":visible"))
                moreWidth = $(this.$el).find('.js_mainMenu .more').outerWidth();

            let toggleWidth = 0;
            if ($(this.$el).find('.sidebar-toggle_container').is(":visible"))
                toggleWidth = $(this.$el).find('.sidebar-toggle_container').outerWidth();

            const availableWidth = $('#nav-main-menu').outerWidth() -
                $(this.$el).find('.js_mainMenu .homeIcon').outerWidth() -
                toggleWidth -
                moreWidth -
                $(this.$el).find('.navbar-right').outerWidth() -
                70;
            //console.log("Navbar recalculate", availableWidth);

            for (let i = 0; i < this.countMenuItem(); i++) {
                navwidth = 0;
                $('.js_mainMenu > li:not(.more, .homeIcon)').each(function() {
                    navwidth += $(this).outerWidth();
                });

                if (navwidth > availableWidth) {
                    //put item from menu to .more ul
                    const lastItem = this.getLastMenuItem();
                    this.putItemToSubmenu(lastItem);
                    this.putItemToMore(lastItem);

                } else {
                    //put item from .more ul to menu
                    const firstItem = this.getFirstMenuItem();
                    if (navwidth + firstItem.data('width') < availableWidth) {
                        this.putItemToMainMenu(firstItem);
                    }
                }
            }

            // check if dropdown menu for .more is empty or not
            this.checkMenuMoreItem();

            // check if dropdown menu open in right or left
            this.checkToOpenSubmenu();

            // if a dropdown menu removed from a nested dropdown menu
            $(this.$el).find('.js_mainMenu > li.js_hasSub').attr('class', 'dropdown');
        }
        //},
        //togglePanel() {
        //    $('.js_changePasswordPanel').toggleClass('open');
        //},
        //closePanel() {
        //    $('.js_changePasswordPanel').removeClass('open');
        //},
        //preventPanel() {
        //    $('.navbar-right > li:not(.js_changePasswordPanel)').removeClass('open');
        //}
    },
    components: {
        NavbarItem
    }
}
