(function (window) {
    var config = (function () {
        let //currentViewId = null,

            hashes = {
                appForm: '#/appform',
                tasks: '#/tasks'
            },
            viewIds = {
                appForm: '#appform-view',
                tasks: '#tasks-view'
            },
            _currentViewCanLeave,
            currentViewCanLeave = function (callback) {
                if (callback)
                    _currentViewCanLeave = callback;
                else
                    return _currentViewCanLeave;
            };

        return {
            hashes: hashes,
            viewIds: viewIds,
            currentViewCanLeave: currentViewCanLeave,
            getCurrentUserId: function () {
                return payload.CurrentUser.Id;
            }
        };
    })();
    window.config = config;
})(window);
