/// <reference path="dts/payload.d.ts" />

export default class Helper {

    /**
     * 
     * @param {any} value
     * @param {any} length
     */
    public truncate(value: any, length: any) {
        let len = parseInt(length);
        if (value) {
            if (value.length > len) {
                return $.trim(value).substring(0, len).split(' ').slice(0, -1).join(' ') + '...';
            } else {
                return $.trim(value);
            }
        }
    }

    /**
     * Convert date to persian date from javascript built in function
     * @param {any} input
     */
    public persianDate(input: any) {
        let date: any = new Date(input);
        let options: any;
        let calendarType: any;
        let text: any = '';

        let weekday = () => {
            options = { weekday: 'long' };
            return date.toLocaleDateString(calendarType, options)
        };
        let month = () => {
            options = { month: 'long' };
            return date.toLocaleDateString(calendarType, options)
        };
        let year = () => {
            options = { year: 'numeric' };
            return date.toLocaleDateString(calendarType, options)
        };
        let day = () => {
            options = { day: 'numeric' };
            return date.toLocaleDateString(calendarType, options)
        };

        return `${weekday()}، ${day()} ${month()}‌ ${text} ${year()}`;
    }

    public formatDateTime(input:Date) {
        let date = this.persianDate(input);
        var time = input.toLocaleTimeString(payload.currentLocale.Culture);

        return `${date} - ${time}`;
    }

   /**
     * Get Param Value from Page URL or from `url` Argument.
     * @param {any} name
     * @param {any} url
     */
    public getURLParam(name: any, url: any) {
        let regex,
            results;

        if (!url) {
            url = window.location.href;
        }

        name = name.replace(/[\[\]]/g, '\\$&');
        regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * disable and enable buttons
     * @param {any} input
     * @param {any} scrollTop
     */
    public statefulButton(input: any, scrollTop: any) {
        let attr: any = $(input).attr('disabled');

        if (typeof attr !== typeof undefined && attr !== false) {
            $(input).removeAttr('disabled');
        } else {
            $(input).attr('disabled', 'disabled');
        }

        if (scrollTop) {
            $('html, body').animate({ scrollTop: 170 }, 600);
        }

        return false;
    };

    public enumBuilder(obj: Object) {
        let result = {};
        for (let item in obj) {
            result[result[item] = obj[item]] = item;
        }
        return result;
    };
}
