var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible === true || typeof _vm.visible === "undefined"
    ? _c(
        "div",
        {
          staticClass: "splash hidden-print",
          style: { position: _vm.position, zIndex: _vm.zindex },
        },
        [
          _c(
            "div",
            {
              staticClass: "splash-inner",
              style: { height: _vm.size, width: _vm.size },
            },
            [
              _c("div", { staticClass: "splash-circle-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "splash-circle-2" }),
              _vm._v(" "),
              _vm.showMessage === "true"
                ? _c(
                    "div",
                    {
                      staticClass: "splash-message",
                      style: { top: _vm.position + 5 },
                    },
                    [
                      _c("a", { attrs: { href: "." } }, [
                        _vm._v(_vm._s(_vm.$t("try_again"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "splash-curtain" }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }