var VacationsCalendar = function (calendarTitle, calendarDiv, yearTxt) {
    this.PersianMounths = ["فروردین", "اردیبهشت", "خرداد", "تیر", "مرداد", "شهریور", "مهر", "آبان", "آذر", "دی", "بهمن", "اسفند"];
    this.MiladiMounth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    this.NameOfDays = ["شنبه", "یکشنبه", "دوشنبه", "سه شنبه", "چهارشنبه", "پنج شنبه", "جمعه"];
    this.NameOfMiladiDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    this.Year = 0;
    this.DaysOfYear = [];
    this.CalendarDiv = calendarDiv;
    this.CalendarTitle = calendarTitle;
    this.YearTxt = yearTxt;
};

VacationsCalendar.prototype = (function () {
    function showCalendar(year) {
        var y = parseInt(year);
        if (payload.currentLocale.Culture === "fa-IR") {
            if (y !== 0 && (isNaN(y) || !(y < 1500 && y > 1300))) {
                alert(i18n.$t('vacation_calendar_management.messages.year_validate_message'));
                return;
            }
        }
        else {
            if (y !== 0 && (isNaN(y) || !(y < 2200 && y > 1800))) {
                alert(i18n.$t('vacation_calendar_management.messages.year_validate_message'));
                return;
            }
        }

        this.Year = year;
        getdaysOfYear.call(this);
    }
    function save() {

        var holidays = [];

        $('.holiday').each(function () { holidays.push($(this).data('day')); });

        utils.callWebServicenopi('WebServices/TimeSheetService.asmx', 'Save', { holidayList: holidays, year: this.Year })
            .done(function (result) {
                alert(i18n.$t('messages.save_successfully'));
            }).fail(function (jqXhr) {
                var jsonValue = $.parseJSON(jqXhr.responseText);
                alert(jsonValue.Message);
                return null;
            });
    }
    function showTable(days) {
        this.Year = days[0][7].PersianDate.substring(0, 4);
        this.CalendarTitle.text(i18n.$t('vacation_calendar_management.year_calendar') + ' ' + this.Year);
        this.YearTxt.val(this.Year);
        var table = $("<table></table>").addClass('tableCalendar');
        var tr, td, th;
        tr = $("<tr></tr>");
        th = $('<td></td>').addClass('empty');;
        tr.append(th);
        for (var j = 0; j < 12; j++) {
            if (payload.currentLocale.Culture === "fa-IR")
                th = $('<th></th>').text(this.PersianMounths[j]);
            else
                th = $('<th></th>').text(this.MiladiMounth[j]);
            tr.append(th);
        }
        table.append(tr);
        var count = 0;
        for (var i = 0; i < 37; i++) {
            tr = $("<tr></tr>");
            //NameOfDays
            if (payload.currentLocale.Culture === "fa-IR") {
                td = $('<td></td>').addClass('firstCol').text(this.NameOfDays[i % 7]);
            }
            else {
                td = $('<td></td>').addClass('firstCol').text(this.NameOfMiladiDays[i % 7]);
            }
            tr.append(td);

            for (j = 0; j < 12; j++) {
                td = $('<td></td>');
                var currentDay = days[j][i];
                if (currentDay) {
                    td.data('day', currentDay.GregorianDate)
                        .text(parseInt(currentDay.PersianDate.substring(8, 10), 10));
                    if (currentDay.Holiday) {
                        td.addClass('holiday');
                        count++;
                    }
                }
                else
                    td.addClass('empty');

                tr.append(td);
            }

            table.append(tr);
        }
        this.CalendarDiv.empty();
        this.CalendarDiv.append(table);
        $('.firstCol').addClass('btn-primary timesheet-grid-cell').click(function () {
            $(this).siblings()
                .not('.empty')
                .toggleClass('holiday ui-state-error');
        });
        $('th', '.tableCalendar').addClass('btn-primary timesheet-grid-cell');
        $('td', '.tableCalendar').not('.empty').addClass('timesheet-grid-border');
        $('.holiday', '.tableCalendar').addClass('ui-state-error');
        $('td', '.tableCalendar').not('.empty').hover(function () {
            //$(this).addClass('ui-state-hover');
        },
            function () {
               // $(this).removeClass("ui-state-hover");
            });
        $('td', '.tableCalendar').not('.empty').not('.firstCol').click(function () {
            $(this).toggleClass('ui-state-error')
                .toggleClass('holiday');
        });
    }
    function getdaysOfYear() {
        var thisObj = this;
        utils.callWebService('WebServices/TimeSheetService.asmx', 'GetDaysOfYear', { year: this.Year })
            .done(function (result) {
                showTable.call(thisObj, result.d);
            })
            .fail(function (jqXhr) {
                var jsonValue = $.parseJSON(jqXhr.responseText);
                alert(jsonValue.Message);
                return null;
            });
    }
    return {
        ShowCalendar: showCalendar,
        Save: save,
    };
})();
