var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isBanner && !_vm.isPlugin ? _c("banner") : _vm._e(),
      _vm._v(" "),
      !_vm.isHideAll
        ? _c(
            "div",
            { staticClass: "wrapper pull-right" },
            [
              !_vm.isPlugin ? _c("sidebar") : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "main-container main-container_wide dx-theme-background-color changePasswordPopup",
                  class: { plugin_main: _vm.isPlugin },
                },
                [
                  !(_vm.isPlugin || _vm.isHideMainMenu)
                    ? _c(
                        "navbar",
                        { attrs: { container: "container-fluid" } },
                        [
                          _c(
                            "li",
                            {
                              staticClass: "navbar-notification",
                              attrs: { slot: "leftNavItems" },
                              slot: "leftNavItems",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-toggle",
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    href: "#",
                                    "data-toggle": "dropdown",
                                    role: "button",
                                    "aria-expanded": "true",
                                    title: _vm.$t(
                                      "message_management.menu_title"
                                    ),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-light fa-lg fa-messages",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "caret" }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.unSeenMessagesCount > 0,
                                          expression: "unSeenMessagesCount>0",
                                        },
                                      ],
                                      staticClass:
                                        "label messageCount label-danger",
                                      attrs: { id: "lblMessage1" },
                                    },
                                    [_vm._v(_vm._s(_vm.unSeenMessagesCount))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { role: "menu" },
                                },
                                [
                                  _c(
                                    "li",
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: "/message-grid/true" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "context.view_received_messages"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.sendMessagePermission
                                        ? _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: "/message-grid/false",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "context.view_sent_message"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.sendMessagePermission
                                    ? _c("li", { staticClass: "divider" })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sendMessagePermission
                                    ? _c(
                                        "li",
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: "/send-message" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("context.send_message")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass: "navbar-notification",
                              attrs: { slot: "leftNavItems" },
                              slot: "leftNavItems",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-toggle",
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    href: "#",
                                    "data-toggle": "dropdown",
                                    role: "button",
                                    "aria-expanded": "true",
                                    title: _vm.$t("user_settings"),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa-light fa-lg fa-user-gear",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "caret" }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { role: "menu" },
                                },
                                [
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showChangePassword(
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("change_password"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              attrs: { slot: "leftNavItems" },
                              slot: "leftNavItems",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticStyle: { "font-size": "16px" },
                                  attrs: {
                                    href: "./Authentication/LogOut",
                                    title: _vm.$t("sign_out"),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa-light fa-lg fa-right-from-bracket",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "container-fluid wrapper-padding" },
                    [_c("router-view")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "DxPopup",
                    {
                      attrs: {
                        title: _vm.$t("change_password"),
                        visible: _vm.showingChangePassword,
                        "hide-on-outside-click": true,
                        "show-close-button": true,
                        "show-title": true,
                        width: "620",
                        height: "400",
                      },
                      on: { hidden: _vm.onHidden, shown: _vm.onShown },
                    },
                    [
                      [
                        _c("changePassword", {
                          on: { messageFromChild: _vm.childMessageReceived },
                        }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isHideAll
        ? _c(
            "div",
            { staticClass: "wrapper pull-right" },
            [_c("router-view")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "scroll-top", staticStyle: { display: "table" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              margin: "auto",
              display: "table-cell",
              "vertical-align": "middle",
              "text-align": "center",
            },
          },
          [_c("i", { staticClass: "fad fa-2x fa-chevron-up" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }