import { Helper } from '../../assets/ts/global';

const helper = new Helper();

export default {
    data() {
        return {
            announcementItems: []
        }
    },
    methods: {
        truncate(value, length) {
            return helper.truncate(value, length);
        }
    },
    updated() {},
    mounted() {
        this.announcementItems = payload.announcementItems
    }
}
