import utils from 'jsf/utils.js';

export function processHistory() {
    const $root = $("<div></div>");

    const init = function (id, domainObjectId, taskTypeId) {
        var url = `api/process/getbytask/${id}`;
        if (typeof id === "undefined")
            url = `api/process/getbydomain/${domainObjectId}/${taskTypeId}`;

        utils.callWebAPIp(url, null, "GET")
            .done(function (data) {
                //console.log(data);
                const graphBuilder = graph.createBuilder({
                    graphContainer: 'graph-canvas',
                    preferences: {
                        analysis: "NONE",
                        annotation: "ANNOTATION",
                        assets: "ANNOTATION",
                        assetsRaci: ["responsible"],
                        attributes: "ANNOTATION",
                        autoLayout: "1",
                        autoLayoutScope: "1",
                        boundaryEvents: "ANNOTATION",
                        bpmnIcon: "BPMN2",
                        categories: "ICON",
                        controlIndicators: "NONE",
                        controls: "ICON",
                        dedicatedPools: "0",
                        description: "ANNOTATION",
                        documents: "ANNOTATION",
                        entities: "NONE",
                        eventNameAnnotation: "ANNOTATION",
                        gatewayNameAnnotation: "ANNOTATION",
                        foldingGraph: "0",
                        format: "DEFAULT",
                        graphNodeType: "EVENT",
                        highlight: "CRITICAL_PATH",
                        highlightEdge: "ENABLED",
                        materials: "ANNOTATION",
                        multiPools: "0",
                        nameAnnotation: "ANNOTATION",
                        nodeColor: "ENABLED",
                        nodeGroupBy: "NONE",
                        objectives: "NONE",
                        orgUnits: "ICON",
                        orgUnitsRaci: ["responsible"],
                        performanceColor: "DISABLED",
                        performanceIndicators: "ICON",
                        processLinks: "ANNOTATION",
                        resources: "ICON",
                        resourcesRaci: ["responsible"],
                        riskIndicators: "ICON",
                        risks: "ICON",
                        roles: "ANNOTATION",
                        rolesRaci: ["responsible"],
                        rules: "ICON",
                        services: "ICON",
                        swimlanesGroupByX: "categories",
                        swimlanesGroupByY: "mixRasci"
                    }
                });
                graphBuilder.buildFlatMap(data);
            })
            .fail(function (xmlHttpRequest) {
                utils.showMessage(xmlHttpRequest.responseText);
            });
    };

    const activate = function (params) {
        config.currentViewCanLeave(() => true);
        const $processLogForm = $("<div></div>");
        utils.showModalHtml('Views/ProcessHistory.html').done(
            function (html) {

                $root.rayModal({
                    title: i18n.$t("title.procces_history"),
                    width: 1200,
                    height: 700,
                    onOpened: function () {
                        $root.empty();
                        $root.append(html);
                        init(params.id, params.domainObjectId, params.taskTypeId);
                    },
                    onClosed: function () {
                        $($root).rayModal('destroy');
                        $root.remove();
                    }
                });
                new legacyComponent({ i18n: vueI18n }).$mount($root[0]);
            }
        );
        $processLogForm.show();
    };

    return {
        activate: activate
    };
}
