import Grid from "../widgets/grid/grid.vue";
import NewForm from '../forms/newform/newForm.vue';
import ModalTimesheet from './modals/ModalTimesheet.vue';

export default {
    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,
            columns: [
                { field: "Id", menu: false, hidden: true },
                {
                    field: "Date",hidden: true, filterable: false, sortable: false
                },
                {
                    field: "PersianDateTime1", hidden: true, filterable: false, sortable: false
                },
                {
                    field: "PersianDateTime", groupHeaderTemplate: "#= value.substring(4) #", hidden: true, filterable: false, sortable: false
                },
                {
                    field: "StartTime", title: this.$t("timesheet_register.start_time"), filterable: false, sortable: false
                },
                {
                    field: "EndTime", title: this.$t("timesheet_register.end_time"), filterable: false, sortable: false
                },
                {
                    field: "Remainder", hidden: true, filterable: false, sortable: false
                },
                {
                    field: "Description", title: this.$t("timesheet_register.description"), filterable: false, sortable: false
                },
                {
                    field: "IsOnVacation", hidden: true,filterable: false, sortable: false
                },
                {
                    field: "FormattedDate", hidden: true, filterable: false, sortable: false
                }
            ],
            schema: {
                data: "Data",
                total: function (response) { return response.Total; }
            },
            group: { field: 'PersianDateTime' },
            toolbar: this.buildToolbarItems(),
            modalData: {},
            currentModalComponent: "",
            showTimesheetModal: 0,
            reloadGrid: 0
        }
    },
    computed: {
        additionalParameters() {
            var date = $.calendars.instance('').today();
            this.reloadGrid++;
            return {
                DateStr : this.dateToString(date)
            }
        },
        title: function () {
            return this.$route.params.folderName;
        }
    },
    methods: {
        dateToString(date) {
            return date._year + "/" + (date._month < 10 ? ("0" + date._month) : date._month) + "/" + (date._day < 10 ? ("0" + date._day) : date._day);
        },
        buildToolbarItems() {
            let mapping = (value) => {
                let hasSubActions = Array.isArray(value.subActions);
                return {
                    type: hasSubActions ? "splitButton" : "button",
                    text: (value.icon) ?
                        `<i class="${value.icon} fa-fw fa-2x"></i> ${value.text}`:
                        value.text,
                    enable: value.enable,
                    click: value.action,
                    imageUrl: value.image,
                    menuButtons: hasSubActions ? value.subActions.map(mapping) : undefined
                }
            };
            return this.getActions().map(mapping);
        },
        getActions() {
            return [
                {
                    key: "AddNewTimeSheet",
                    text: this.$t("timesheet_register.plus"),
                    icon: "fad fa-external-link",
                    action: (node, nodeData, selecteds) => {
                        this.modalData.isCreation = true;
                        this.currentModalComponent = "ModalTimesheet";
                        this.modalData.callback = () => {
                            this.reLoadeData();
                        };
                        this.showTimesheetModal++;
                    },
                    enable: true
                },
                {
                    key: "EditRule",
                    text: this.$t("edit"),
                    icon: "fad fa-edit",
                    action: (node, nodeData, selecteds) => {
                        let dataItem = nodeData[0];
                        this.modalData.isCreation = false;
                        this.modalData.id = dataItem.Id;
                        this.currentModalComponent = "ModalTimesheet";
                        this.modalData.callback = () => {
                            this.reLoadeData();
                        };
                        this.showTimesheetModal++;
                    },
                    enable: false
                },
                {
                    key: "DeleteRule",
                    text: this.$t("remove"),
                    icon: "fad fa-trash-alt",
                    // image: "dist/delete.png",
                    action: (node, nodeData, selecteds) => {
                        let dataItem = nodeData[0];
                        var id = dataItem.Id;

                        var that = this;

                        utils.confirm(i18n.$t("are_you_sure"), {
                            yes: function () {
                                var url = "api/rules/delete/" + id;

                                that.doAjaxRequest(url, {}, () => { that.reLoadeData(); }, () => { }, 'DELETE');
                            },
                            no: function () { }
                        });
                    },
                    enable: false
                }
            ];
        },
        reLoadeData() {
            this.reloadGrid++;
        },
        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            let ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",

                contentType: "application/json;charset=utf-8"
            };

            if (method) {
                ajaxConfig.method = method;
            }

            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        }
    },
    created() {
    },
    components: {
        Grid,
        NewForm,
        ModalTimesheet
    }
};
