//import 'babel-polyfill';
import Vue from "vue";
import Public from "./app.public.vue";
import Private from "./app.private.vue";
import { router } from "./app.router";
import { i18n } from "./app.culture";
import { legacyComponent } from "./component/legacyform/Legacy.js";
import Hubs from './app.hubs';
import accessTokenManager from './app.accessToken';

// DevExpress
import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.light.css';
//import 'devextreme/dist/css/dx.material.blue.light.css';
import frMessages from "devextreme/localization/messages/fr.json";
import deMessages from "devextreme/localization/messages/de.json";
import itMessages from "devextreme/localization/messages/it.json";
import svMessages from "devextreme/localization/messages/sv.json";
import arMessages from "./locales/devex/ar.json";
import heMessages from "./locales/devex/he.json";
import { locale, loadMessages } from "devextreme/localization";

// moved here from BundleConfig.cs
import 'bootstrap/js/transition.js';
import 'bootstrap/js/alert.js';
import 'bootstrap/js/button.js';
import 'jsf/noconflict.js';
import 'bootstrap/js/carousel.js';
import 'bootstrap/js/collapse.js';
import 'bootstrap/js/dropdown.js';
import 'jsf/lib/bootstrap-modal.js';
import 'bootstrap/js/tooltip.js';
import 'bootstrap/js/popover.js';
import 'bootstrap/js/scrollspy.js';
import 'bootstrap/js/tab.js';
import 'bootstrap/js/affix.js';

import 'jsf/rayControl.js';
import 'jsf/bpmsAppForm.js';
import 'jsf/config.js';
import 'jsf/vm.appform.js';
//import 'jsf/vm.tracking.js';
import 'jsf/vm.vacationCalendar.js';
import 'jsf/vm.export.js';
import 'jsf/vm.Message.js';
import 'jsf/vm.MessageForm.js';
import 'jsf/vm.Slider.js';
import 'jsf/VacationsCalendar.js';
import 'jsf/controls/Ray.controls.baseautocomplete.js';
import 'jsf/controls/Ray.controls.selectbox.js';
import 'jsf/controls/Ray.controls.numerictextbox.js';
import 'jsf/controls/Ray.controls.imageviewer.js';
import 'jsf/controls/Ray.controls.autocomplete.js';
import 'jsf/vm.gridExport.js';
import 'jsf/restful.js';
//import 'jsf/RayGrid.js';
//import 'jsf/RayGenericGrid.js';
import 'jsf/deTable.js';
import 'jsf/deGrid.js';
//import 'jsf/RayAssign.js';
import 'jsf/controls/MultiSelectDropdown.js';
import 'jsf/RayTree.js';

import 'jsf/global.js';

// Old File Attachment
//import 'jsf/controls/FileAttachmentOld.js';
//import 'jsf/controls/Ray.controls.fileattach.js';
//require('script-loader!blueimp-file-upload/js/vendor/jquery.ui.widget.js');
//require('script-loader!blueimp-tmpl/js/tmpl.js');
//require('script-loader!blueimp-load-image/js/load-image.all.min.js');
//require('script-loader!blueimp-canvas-to-blob/js/canvas-to-blob.js');
//require('script-loader!blueimp-file-upload/js/jquery.iframe-transport.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-process.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-image.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-audio.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-video.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-validate.js');
//require('script-loader!blueimp-file-upload/js/jquery.fileupload-ui.js');

// New File Attachment
import 'jsf/controls/FileAttachment.js';

//require('script-loader!@fortawesome/fontawesome-pro/js/all.min.js');
//require('script-loader!@fortawesome/fontawesome-pro/js/solid.min.js');
require('@fortawesome/fontawesome-pro/js/all.min.js');
require('@fortawesome/fontawesome-pro/js/sharp-solid.min.js');

// Arabic Calendar
require('script-loader!kbw-calendars/dist/js/jquery.calendars.js');
require('script-loader!kbw-calendars/dist/js/jquery.calendars.plus.js');
import 'kbw-calendars/dist/css/jquery.calendars.picker.css';
require('script-loader!kbw-calendars/dist/js/jquery.plugin.js');
require('script-loader!kbw-calendars/dist/js/jquery.calendars.picker.js');
require('script-loader!kbw-calendars/dist/js/jquery.calendars.islamic.js');
require('script-loader!kbw-calendars/dist/js/jquery.calendars.islamic-ar.js');
require('script-loader!kbw-calendars/dist/js/jquery.calendars.picker-ar.js');

// jQuery UI
import 'jquery-ui/themes/base/resizable.css';

new Vue({
    el: "#app",
    i18n,
    router,
    render: h => {
        if (payload.el === "Private")
            return h(Private);
        else
            return h(Public);
    },
    mounted() {
        new Hubs().init();
        new accessTokenManager();
    },
    created() {
        // Load DevExpress localization
        loadMessages(frMessages);
        loadMessages(deMessages);
        loadMessages(arMessages);
        loadMessages(itMessages);
        loadMessages(svMessages);
        loadMessages(heMessages);
        //Override some localization messages
        loadMessages({
            "fr": {
                "dxDataGrid-columnFixingLeftPosition": "À gauche",
                "dxDataGrid-columnFixingRightPosition": "À droite",
                "OK": "D'accord",
                "dxDataGrid-headerFilterOK": "D'accord",
                "dxDiagram-dialogButtonOK": "D'accord",
            }
        });

        locale(payload.currentLocale.Culture);
    }
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

