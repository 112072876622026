/*import Loading from "../../widgets/loading/loading.vue";*/
import dateFormat from "dateformat";

export default {
    props: [
        "useTitle",
        "widthSize",
        "heightSize",
        "className",
        "title",
        "breadcrumbData",
        "isCompletedTask",
        /*"loading",*/
        "customStyle"
    ],

    data() {
        return {
            //currentDate: "",
            isPlugin: payload.isPlugin,
            isHideFormHeader: payload.isHideFormHeader,
            isHideFormToolbar: payload.isHideFormToolbar,
            isHideAll: payload.isHideAll
        }
    },

    mounted() {
        //this.currentDate = payload.IsUTC
        //    ? dateFormat((new Date()).toISOString().replace('Z', ''), `${payload.currentLocale.ShortDateFormatG} ${payload.currentLocale.ShortTimeFormatG}`)+' UTC'
        //    : dateFormat(new Date(), `${payload.currentLocale.ShortDateFormatG} ${payload.currentLocale.ShortTimeFormatG}`);
    },

    components: {
        /*Loading*/
    }
}
