export default {
    getDefaultConfig(url, method, headers) {
        if (headers === void 0)
            headers = null;
        const settings = {};
        settings.url = url;
        settings.method = method;
        settings.contentType = "application/json";
        settings.cache = false;
        if (headers != null)
            settings.beforeSend = function(xhr) {
                for (const key in headers)
                    xhr.setRequestHeader(key, headers[key]);
            };
        return settings;
    },

    get(url, data, headers) {
        if (data === void 0)
            data = null;
        if (headers === void 0)
            headers = null;
        const settings = this.getDefaultConfig(url, 'GET', headers);
        settings.data = $.param(data);
        return $.ajax(settings);
    },

    post(url, data, headers, isJson) {
        if (data === void 0)
            data = null;
        if (headers === void 0)
            headers = null;
        if (isJson === void 0)
            isJson = false;
        const settings = this.getDefaultConfig(url, 'POST', headers);
        settings.data = isJson ? JSON.stringify(data) : data;
        return $.ajax(settings);
    }
}
