import NewForm from '../forms/newform/newForm.vue';
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxSelection,
    DxPager, DxPaging, DxSearchPanel,
    DxToolbar, DxItem, DxExport
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxButton as DxButtonToolbar } from 'devextreme-vue/button';
import utils from 'jsf/utils.js';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

const url = 'api/Logs/GetErrorLog';

const dataSource = createStore({
    key: 'Id',
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { "Content-type": "application/json" };
    }
});

export default {
    data() {
        return {
            dataGridRefKey: 'dataGrid',
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            url,
            dataSource
        }
    },
    methods: {
        refreshDataGrid() {
            this.dataGrid.refresh();
        },

        contentReady(e) {
            if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
        },

        selectionChanged(e) {
            e.component.collapseAll(-1);
            e.component.expandRow(e.currentSelectedRowKeys[0]);
        },

        copy(errorMessage) {
            const textArea = document.createElement("textarea");
            // Place in the top-left corner of screen regardless of scroll position.
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0;
            // Clean up any borders.
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            // Avoid flash of the white box if rendered for any reason.
            textArea.style.background = 'transparent';
            textArea.value = errorMessage.replace("<br>", "\n").replace("<br/>", "\n");
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful)
                    utils.message(i18n.$t("copied"), 1);
                else
                    utils.message(i18n.$t('unable_to_copy'), 2);
            } catch (err) {
                utils.message(i18n.$t('unable_to_copy'), 2);
            }
            document.body.removeChild(textArea);
        },

        onExporting(e) {
            e.component.beginUpdate();
            e.component.columnOption('ErrorMessage', 'visible', true);

            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(function () {
                workbook.xlsx.writeBuffer().then(function (buffer) { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ErrorLog.xlsx'); });
            }).then(function () {
                e.component.columnOption('ErrorMessage', 'visible', false);
                e.component.endUpdate();
            });
        }

    },

    updated() {
    },

    mounted() {
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        }
    },

    components: {
        NewForm,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging,
        DxSelection,
        DxSearchPanel,
        DxSelectBox,
        DxToolbar,
        DxItem,
        DxButtonToolbar,
        DxExport, Workbook, exportDataGrid
    }
}
