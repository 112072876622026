import { render, staticRenderFns } from "./MessageGrid.vue?vue&type=template&id=325f9aed"
import script from "./MessageGrid.js?vue&type=script&lang=js&external"
export * from "./MessageGrid.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\r1\\a\\InterfacingBPMS\\Source\\Rayvarz.BPMS.Web.App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('325f9aed')) {
      api.createRecord('325f9aed', component.options)
    } else {
      api.reload('325f9aed', component.options)
    }
    module.hot.accept("./MessageGrid.vue?vue&type=template&id=325f9aed", function () {
      api.rerender('325f9aed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "App/component/messageGrid/MessageGrid.vue"
export default component.exports