<template>
    <div>
        <div class="jquery-ui-scope view bpms-Message"></div>
        <div></div>
    </div>
</template>
<script type="text/javascript">
    import loading from '../widgets/loading/loading.vue';
    import { MessageForm } from 'jsf/vm.MessageForm.js';
    import { sendMessage } from 'jsf/vm.sendMessage.js';

    export default {
        data() {
            return {
            }
        },
        methods: {

        },
        mounted() { 
            //console.log(this.$route.params.messageId);

            const view = $(this.$el).children(':nth-child(1)');
            const params1 = {
                element: view,
                messageId: this.$route.params.messageId
            };
   
            MessageForm().activate(params1);
            if (this.$route.params.isReceiveMessage === "true") {
                var sendMessageHtml = "Views/ReplyMessage.html";
                const send = $(this.$el).children(':nth-child(2)');
                const params2 = {
                    element: send,
                    messageId: this.$route.params.messageId
                };
                sendMessage().activate(params2, sendMessageHtml);
            }
        },
        components: {
            loading
        }
    }
</script>
