var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c(
        "newForm",
        {
          class: [_vm.direction ? "k-rtl" : ""],
          attrs: {
            useTitle: "true",
            title: _vm.$t("logs_management.active_connections"),
          },
        },
        [
          _c(
            "DxDataGrid",
            {
              ref: _vm.dataGridRefKey,
              attrs: {
                id: "gridContainer",
                "show-borders": true,
                "data-source": _vm.dataSource,
                "remote-operations": true,
                "column-auto-width": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "sendToAllTemplate",
                  fn: function () {
                    return [
                      _c("DxButtonToolbar", {
                        attrs: {
                          icon: "message",
                          text: _vm.$t("logs_management.send_message_all"),
                        },
                        on: { click: _vm.sendMessageToAllClick },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "refreshTemplate",
                  fn: function () {
                    return [
                      _c("DxButtonToolbar", {
                        attrs: { icon: "refresh" },
                        on: { click: _vm.refreshDataGrid },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("DxLoadPanel", { attrs: { enabled: true } }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "StartDateTime",
                  "data-type": "datetime",
                  "sort-index": 1,
                  "sort-order": "desc",
                  format: _vm.datetimeFormat,
                  caption: _vm.$t("logs_management.start_datetime"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "User",
                  caption: _vm.$t("logs_management.user"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "ServerName",
                  caption: _vm.$t("logs_management.server_name"),
                },
              }),
              _vm._v(" "),
              _c(
                "DxColumn",
                { attrs: { caption: "Actions", type: "buttons" } },
                [
                  _c("DxButtonColumn", {
                    attrs: {
                      hint: _vm.$t("logs_management.send_message_connection"),
                      icon: "message",
                      "on-click": _vm.sendMessage,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "DxToolbar",
                [
                  _c("DxItem", {
                    attrs: {
                      location: "before",
                      template: "sendToAllTemplate",
                    },
                  }),
                  _vm._v(" "),
                  _c("DxItem", {
                    attrs: { location: "after", template: "refreshTemplate" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._v(" "),
              _vm._v(" "),
              _c("DxPaging", { attrs: { "page-size": 20 } }),
              _vm._v(" "),
              _c("DxPager", {
                attrs: {
                  "show-page-size-selector": true,
                  "allowed-page-sizes": [15, 20, 50],
                  "show-navigation-buttons": true,
                  "show-info": true,
                },
              }),
              _vm._v(" "),
              _c("DxFilterRow", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxHeaderFilter", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGroupPanel", { attrs: { visible: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }