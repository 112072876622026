$.fn.safeClick = function (callback) {
    const _this = this;
    const delay = arguments.length <= 1 || arguments[1] === undefined ? 500 : arguments[1];
    $(this).click(function (e) {
        const timeData = $(_this).data('timer-info');
        if (timeData !== null && typeof timeData !== 'undefined') {
            clearTimeout(timeData);
            e.preventDefault();
        } else {
            callback(e);
        }
        const time = setTimeout(function () {
            $(_this).data('timer-info', null);
        }, delay);
        $(_this).data('timer-info', time);
    });
    return this;
};

kendo.ui.Pager.prototype.options.messages =
    $.extend(kendo.ui.Pager.prototype.options.messages, {
        itemsPerPage: ""
    });

/**
 * Protect window.console method calls, e.g. console is not defined on IE
 * unless dev tools are open, and IE doesn't define console.debug
 *
 * Chrome 41.0.2272.118: debug,error,info,log,warn,dir,dirxml,table,trace,assert,count,markTimeline,profile,profileEnd,time,timeEnd,timeStamp,timeline,timelineEnd,group,groupCollapsed,groupEnd,clear
 * Firefox 37.0.1: log,info,warn,error,exception,debug,table,trace,dir,group,groupCollapsed,groupEnd,time,timeEnd,profile,profileEnd,assert,count
* Internet Explorer 11: select,log,info,warn,error,debug,assert,time,timeEnd,timeStamp,group,groupCollapsed,groupEnd,trace,clear,dir,dirxml,count,countReset,cd
 * Safari 6.2.4: debug,error,log,info,warn,clear,dir,dirxml,table,trace,assert,count,profile,profileEnd,time,timeEnd,timeStamp,group,groupCollapsed,groupEnd
 * Opera 28.0.1750.48: debug,error,info,log,warn,dir,dirxml,table,trace,assert,count,markTimeline,profile,profileEnd,time,timeEnd,timeStamp,timeline,timelineEnd,group,groupCollapsed,groupEnd,clear
 */
(function () {
    // Union of Chrome, Firefox, IE, Opera, and Safari console methods
    const methods = ["assert", "cd", "clear", "count", "countReset",
        "debug", "dir", "dirxml", "error", "exception", "group", "groupCollapsed",
        "groupEnd", "info", "log", "markTimeline", "profile", "profileEnd",
        "select", "table", "time", "timeEnd", "timeStamp", "timeline",
        "timelineEnd", "trace", "warn"];
    let length = methods.length;
    const console = (window.console = window.console || {});
    let method;
    const noop = function () { };
    while (length--) {
        method = methods[length];
        // define undefined methods as noops to prevent errors
        if (!console[method])
            console[method] = noop;
    }
})();


let oldNoty = window.noty;

window.noty = function (data) {

    if (typeof data.type === 'undefined' || data.type === 'confirm')
        data.type = 'alert';

    if (data.type === 'info' || data.type === 'information')
        data.type = 'success';

    if (typeof data.timeout === 'undefined' || data.timeout === null)
        data.timeout = 5000;

    if (typeof data.modal === 'undefined')
        data.modal = false;

    if (typeof data.text !== 'string') {
        let text = $(data.text).text().toLowerCase();
        data.text = $(data.text).html();
    }

    data.closeWith = ['click'];

    data.tracking = data.text.indexOf('tracking') >= 0 || data.text.indexOf(i18n.$t("tracking")) >= 0;

    let buttons = [];
    if (typeof data.buttons !== 'undefined') {
        for (let index in data.buttons) {
            let button = data.buttons[index];
            buttons.push(Noty.button(button.text, 'btn btn-default', button.onClick));
        }

        data.modal = true;
        delete data['timeout'];
        data.closeWith = [];
    } else {
        data.modal = false;
    }

    if (data.layout === 'top' || buttons.length === 0) {
        data.layout = payload.currentLocale.IsRightToLeft ? 'topLeft' : 'topRight';
    }

    if (data.tracking && buttons.length === 0) {
        data.timeout = 5000;
        data.closeWith = ['button'];
        data.progressBar = true;
    } else {
        data.progressBar = false;
    }


    let n = new Noty({
        text: data.text,
        layout: data.layout,
        theme: 'relax',
        closeWidth: data.closeWidth,
        type: data.type,
        modal: data.modal,
        timeout: data.timeout,
        buttons: buttons,
        closeWith: data.closeWith,
        progressBar: false,
        animation: {
            open: 'animated fadeIn',
            close: 'animated fadeOut'
        }
    }).show();
}

$.ajaxSetup({
    beforeSend: function (xhr) {
        xhr.setRequestHeader('rayheader', window.sessionStorage.getItem('accessToken'));
    }
});


$.extend($.expr[':'], {
    nothidden: function (a) {
        return $(a).css("display") !== "none" && $(a).css("visibility") !== "hidden" && !$(a).is(":disabled");
    }
});

let delay = (function () {
    let timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

function getMaxTop(items) {
    let maxTop = 0;
    $(items).each(function (index, item) {
        if ($(item).css("visibility") === "hidden" || $(item).css("display") === "none") return;

        if ($(item).prop("tagName") === "OPTION")
            return;

        if ($(item).prop("tagName") === "LI" || $(item).prop("tagName").toUpperCase() === "PATH")
            return;

        if ($(item).parent().hasClass("ulforassign"))
            return;

        if ($(item).parents(".ui-jqgrid-view").length > 0) return;

        if ($(item).parents(".RayComment").length > 0) return;

        if ($(item).parents('.RayGrid').length > 0) return;

        if ($(item).parents('.RayGenericGrid').length > 0) return;

        if ($(item).parents('.RayAssignControl').length > 0) return;

        if ($(item).parents('.RayTree, .ray-tree').length > 0) return;

        if ($(item).offset().top < 0 || parseInt($(item).css('top')) < 0) return;

        if (maxTop < $(item).offset().top + $(item).outerHeight()) {
            maxTop = $(item).offset().top;

            if (typeof $(item).attr("gridheight") == "undefined")
                maxTop += $(item).outerHeight();
            else
                maxTop += parseInt($(item).attr("gridheight"));
        }
    });

    maxTop = Math.ceil(maxTop);

    return maxTop;
}

function calculateHeight(items) {
    let height = 0;
    $(items).each(function (index, item) {
        if ($(item).css("visibility") === "hidden" || $(item).css("display") === "none") return;

        if ($(item).parents(".ui-jqgrid-view").length > 0) return;

        height += $(item).outerHeight();
    });

    height = Math.ceil(height);

    return height;
}


function fixTabPaneHeight(parent) {
    let tabPanels;
    let tabControls;

    if (parent == null || typeof parent === "undefined") {
        tabPanels = $(".BpmsForm div[role=tabpanel]");
        tabControls = $(".BpmsForm .RayTabControl");
    } else {
        tabPanels = $(parent).children("div[role=tabpanel]");
        tabControls = $(parent).children(".RayTabControl");
    }

    $(tabPanels).each(function (index, item) {
        if ($(item).prop('isr') === 'true') return;
        if ($(item).attr('isr') === 'true') return;

        if (typeof $(this).attr("old-height") !== "undefined" && $(this).attr("old-height") != "10000")
            $(this).attr("old-height", $(this).height());
        else
            $(this).attr("old-height", 10000);

        if ($(item).css("visibility") === "hidden" || $(item).css("display") === "none") return;
        const visisbleHeight = Math.floor(getMaxTop($(item).find(":visible")) - $(item).offset().top);

        if (visisbleHeight /*< parseInt($(this).attr("old-height"))*/) {
            $(item).height(visisbleHeight);
            $(this).attr("old-height", visisbleHeight);
        }

        correctVisibleHidden(item);
    });

    $(tabControls).each(function (index, tabControl) {

        if ($(tabControl).prop('isr') === 'true') return;

        if ($(tabControl).css("visibility") === "hidden" || $(tabControl).css("display") === "none") return;

        const visisbleHeight = Math.floor(calculateHeight($(tabControl).children(":visible:not(option)")));

        $(tabControl).height(visisbleHeight);
    });
}


function fixBpmsFormHeight() {
    $(".BpmsForm").each(function (index, item) {

        const visisbleHeight = Math.floor(getMaxTop($(item).find(":visible:not(option)")) - $(item).offset().top);

        const dialog = $(item).closest('.app-modal-dialog');
        if (dialog.length > 0) {
            dialog.height(visisbleHeight + 100);
            dialog.css('min-height', visisbleHeight);
        }

        $(item).height(visisbleHeight + 15);
    });
}

function fixBpmsRectangleHeight(parent) {
    const rects = $(parent).children(".RayRectangle, div[role=tabpanel], .RayTabControl");
    $(rects).each(function (index, item) {
        fixBpmsRectangleHeight(item);
        if ($(item).hasClass("RayRectangle")) {
            let height = getMaxTop($(item).children()) - $(item).offset().top + 5;
            height = Math.floor(height);
            $(item).height(height);
        }
    });
}

function correctVisibleHidden(parent) {
    const items = $(parent).find("*");

    for (let i = 0; i < items.length; i++) {
        const item = $(items[i]);
        if (item.css("visibility") === "hidden") {

            if (item.attr("display") === "undefined" || typeof item.attr("display") === "undefined")
                item.attr("display", item.css("display"));

            item.css("display", "none");
        } else if (item.css("visibility") === "visible" && item.css("display") === "none") {

            if (item.attr("display") !== "undefined" || typeof item.attr("display") !== "undefined")
                item.css("display", item.attr("display"));
        }
    }
}


function fixHeight() {
    setTimeout(function () {
        $(".ui-autocomplete-input").autocomplete('close');
        //fixTabPaneHeight();
        fixBpmsFormHeight();


        $(".ui-tabs-nav").off("click").click(function () {
                fixTabPaneHeight();
                //fixBpmsRectangleHeight(".BpmsForm");
                fixBpmsFormHeight();
            })
            .keydown(function (e) {
                if (e.keyCode !== 0 && e.keyCode !== 32)
                    return;
                fixTabPaneHeight();
                //fixBpmsRectangleHeight(".BpmsForm");
                fixBpmsFormHeight();
            });
    }, 250);
}



function logout() {
    window.location = "logout.aspx";
}

function showSessionError(status, callback) {

    /*
        Logout =  0,
        AnonymousRefresh = 1,
        LoggedIn = 2,
        RedirectToHome = 3
    */

    let title = i18n.$t('session_end_title');
    let message = i18n.$t("login_again");

    if (status === 3) {
        title = i18n.$t('session_end_title');
        message = i18n.$t('session_end_you_will_redirect_to_the_homepage');
    }
    const buttons = {};
    buttons[i18n.$t('continue')] = function () { $(d).dialog("destroy"); callback(); }
    const d = $(`<div title='${title}'>${message}</div>`).dialog({
            autoOpen: true,
            height: 200,
            width: 350,
            modal: true,
            buttons: buttons,
            close: function () {
                callback();
            }
        });
}

function checkTimeout() {
    /**
        Logout =  0,
        AnonymousRefresh = 1,
        LoggedIn = 2,
        RedirectToHome = 3
     */
    utils.callWebServicenopi("WebServices/SessionChecker.asmx", "Ping", { user: Window.currentUser })
        .done(function (res) {
            const sessionCheck = parseInt(res.d);
            if (sessionCheck !== 2) {
                switch (sessionCheck) {
                    case 0: // logout

                        try {
                            parent.postMessage('logout', eofficeUrl);
                        }
                        catch (ex) {
                            //
                            console.warn("postMessage", ex);
                        }

                        showSessionError(sessionCheck, function () { logout(); });
                        break;
                    case 1: // anonymous_refresh

                        try {
                            parent.postMessage('logout', eofficeUrl);
                        }
                        catch (ex) {
                            //
                            console.warn("postMessage", ex);
                        }

                        window.location.reload();
                        break;
                    case 3: // redirect to home

                        try {
                            parent.postMessage('logout', eofficeUrl);
                        }
                        catch (ex) {
                            //
                            console.warn("postMessage", ex);
                        }

                        showSessionError(sessionCheck, function () {
                            window.location = `./Default.aspx?rnd=${new Date().getTime()}/#/Home`;
                        });
                        break;
                }
            } else {
                setTimeout(function () { checkTimeout() }, 36000);
            }
        });
}

$(document).ready(function () {

    //router.run();
    //vm.shell.activate({ currentFolderId: null });


    //$(".clickOnce").click(function () { /*Page_ClientValidate();*/if (typeof (Page_IsValid) == "undefined" || Page_IsValid) $('.clickOnce').attr("disabled", "disabled"); });
    setContentMainPosition();
    $(document).click(function (e) {
        if ($(e.target).parents(".table, .RayDatePicker").length === 0)
            $(".md-datetimepicker-container").hide();
    });


    $(window).resize(function () {
        setContentMainPosition();
    });
    $('#topToolsButton').click(function () {
        const animTime = 1000;
        const fdiv = $('div:first', $(this));
        fdiv.show(animTime).mouseover(function () { $(this).stop(true, true).show(animTime); });
        $(this).mouseleave(function () { fdiv.hide(animTime); });
    });

    jQuery(window).scroll(function () {
        const top = jQuery(window).scrollTop() + 2;
        jQuery('#updateProgressDiv').animate({ top: top }, { queue: false, duration: 200 });
    });
    function onElementHeightChange(callback) {
        let elm = $("#contentmain");
        if (elm.height() === 0)
            elm = $("#appform");
        let lastHeight = elm.height(), newHeight;
        (function run() {
            newHeight = elm.height();
            if (lastHeight !== newHeight)
                callback();
            lastHeight = newHeight;

            if (elm.onElementHeightChangeTimer)
                clearTimeout(elm.onElementHeightChangeTimer);

            elm.onElementHeightChangeTimer = setTimeout(run, 200);
        })();
    }

    onElementHeightChange(function () {
        Layout.initFixedSidebar();
    });

    const animationTime = 500;
    const initCollapsePanel = function (item) {
        item.click(function () {
            if ($(this).next().is('.collapsed'))
                show($(this), animationTime);
            else
                collapse($(this), animationTime);
            return false;
        });
        item.each(function () {
            let saved = null;
            if ($(this).attr('id') != null)
                saved = $.cookie(`collapse${$(this).attr('id')}`);
            if (saved != null && saved === 'expanded')
                show($(this));
            else
                collapse($(this));
        });
    };
    const show = function (item/*, animationTime*/) {
        item.next().slideDown(animationTime).removeClass("collapsed");
        item.find('.ui-icon').removeClass('ui-icon-triangle-1-w').addClass('ui-icon-triangle-1-s');
        if (item.attr('id') != null)
            $.cookie(`collapse${item.attr('id')}`, 'expanded', { secure: true });
    };
    const collapse = function (item/*, animationTime*/) {
        item.next().slideUp(animationTime).addClass("collapsed");
        item.find('.ui-icon').removeClass('ui-icon-triangle-1-s').addClass('ui-icon-triangle-1-w');
        if (item.attr('id') != null)
            $.cookie(`collapse${item.attr('id')}`, null, { secure: true });
    };
    initCollapsePanel($('.collapse-panel .collapse-header'));


    //keepSessionAlive(60, 1);
    const loginTxtBlur = function () {
        if ($(this).val().length === 0)
            $(this).addClass('login-bg');
    };
    $('#userpanel .textbox').focus(function () {
        $(this).removeClass('login-bg');
    }).blur(loginTxtBlur).each(loginTxtBlur);
    //preloadImages("loading2.gif");
    Workbench.Init();
});
//If integrated in eoffice or portal
//function isIntegrated() {
//    return getParameterByName("co") == "t" || getParameterByName("pUrl");
//}
let preloadedImages = [];

function preloadImages() {
    for (let i = 0; i < preloadImages.arguments.length; i++) {
        const img = new Image();
        img.src = `${window.baseUrl}/images/${preloadImages.arguments[i]}`;
        preloadedImages.push(img);
    }
}
function setContentMainPosition() {
    let topPosition = 0;
    $('.topiconsSection li').each(function () {
        const outerHeight = $(this).outerHeight();
        const localTopPos = outerHeight > 0 ? $(this).position().top + $(this).outerHeight() : 0;
        if (localTopPos > topPosition) {
            topPosition = localTopPos;
            $('#contentmain').css("top", topPosition + 5);
        }
    });
}

function ValidateFolder() {
    let title = $("#txtFolderTitle").val();
    const titleContainer = $("#divFolderValidation");

    //titleContainer.text(' ');

    title = jQuery.trim(title);

    if (title.length > 50) {
        titleContainer.text(i18n.$t('create_physical_folder.folder_title_length_error'));
        return false;
    }

    if (title.length <= 0) {
        titleContainer.text(i18n.$t('create_physical_folder.folder_title_is_empty'));
        return false;
    }


    if (title === i18n.$t('create_physical_folder.undone_works') || title === i18n.$t('create_physical_folder.done_works') || title === i18n.$t('create_physical_folder.dont_send_request')) {
        titleContainer.text(i18n.$t('create_physical_folder.folder_title_validate_error'));
        return false;
    }


    $("#divFolderValidation span:first");

    return true;
}

function ShowHidePhFolderValidation(show) {
    if (show)
        $('#divFolderValidation').css("display", "block");
    else {
        $('#divFolderValidation').text('').css("display", "none");
    }
}

function ConfirmDeleteFolder() {
    return confirm(i18n.$t('are_you_sure'));
}

function ServiceOperationFailed() {
    alert(i18n.$t("operation_faild"));
}

let keepSessionAlive = function (duration, interval) {
    const m = 60 * 1000;
    const ed = new Date();
    const wsPath = `${window.baseUrl}webservices`;
    ed.setTime((new Date()).getTime() + (duration * m));
    const i = setInterval(function () { if ((new Date()) < ed) pingSession(wsPath); else clearInterval(i); }, interval * m);
},
    pingSession = function (wsPath) {
        utils.callWebMethod(`${wsPath}/StateService.asmx`, 'PingSession', null, function () { });
    },
    KeepCartableUpdated = function (interval) {
        const m = 60 * 1000;
        Workbench.PublishEvent(WorkbenchConfigs.EventsEnum.CartableUpdated, {});
        setInterval(function () { Workbench.PublishEvent(WorkbenchConfigs.EventsEnum.CartableUpdated, {}); }, interval * m);
    }
//note:EOA Plugin Closes BPMS Forms
function bpmsClose(message) {
    if (window.external && typeof (window.external.hidePopup) != 'undefined') {
        Workbench.PublishEvent(WorkbenchConfigs.EventsEnum.BpmsFormClosedInWinEoaPlugin, message);
    } else {
        window.top.hidePopup(true, message);
    }
}
function bpmsDialogClose(message, modalId, controlId) {
    if (message && message.length > 0) {
        const container = $(`<div>${message}</div>`);
        container.dialog({
            resizable: false,
            width: Math.ceil(500),
            height: Math.ceil(300),
            modal: true,
            direction: 'rtl',
            closeOnEscape: true,
            title: i18n.$t('message'),
            buttons: {
                "Close": function () {
                    $(this).dialog('destroy');
                    closedialog_parent(modalId, controlId);
                }
            }
        }).parent('.ui-dialog').addClass('jquery-ui-scope');
    }
}

//function portalClose(message) {
//    alert(message);
//    window.location = getParameterByName('pUrl');
//}

function toWindowsAuth() {
    window.location = 'WindowsAuth.aspx';
}


let Workbench = function () {

    // private variables and methods

    // this variables is used for publish/subscribe implementation----------------------------------
    const events = {};
    let subUid = -1;
    // ---------------------------------------------------------------------------------------------

    function setUpRightSection() {
        const rightSectionCookie = $.cookie('RightSection');
        const rightSectionWidth = rightSectionCookie == null ? 230 : parseInt(rightSectionCookie);
        const $rightSectionElement = $('.rightSection');
        const $contentSection = $('.contentSection');
        $rightSectionElement.resizable({
            handles: 'w',
            resize: function (event, ui) {
                $contentSection.css('right', ui.size.width);
                $.cookie('RightSection', ui.size.width, { expires: new Date(new Date().getFullYear(), 11, 31), secure: true });
            }
        });
        $contentSection.css('right', rightSectionWidth);
        $rightSectionElement.css('width', rightSectionWidth);

        if ($('#right_content_top .treemenu').size() > 0) {
            KeepCartableUpdated(2);
            toggleRight(false, localStorage.getItem('rightClosed') != null);
        }
    }

    function subscribeEvent(eventName, func) {
        if (!events[eventName])
            events[eventName] = [];
        const token = (++subUid).toString();
        events[eventName].push({
            token: token,
            func: func
        });
        return token;
    }

    function publishEvent(eventName, eventArgs) {
        if (!events[eventName])
            return false;
        const subscribers = events[eventName];
        let len = subscribers ? subscribers.length : 0;
        while (len--)
            subscribers[len].func(eventName, eventArgs);
        return this;
    }

    function unSubscribeEvent(token) {
        for (let ev in events)
            if (events[ev])
                for (let i = 0, j = events[ev].length; i < j; i++)
                    if (events[ev][i].token === token) {
                        events[ev].splice(i, 1);
                        return token;
                    }
        return this;
    }

    function handleEvents() {

        subscribeEvent(WorkbenchConfigs.EventsEnum.TreeNodeClicked, function (event, args) {
            // args.ItemType hichvaght pas nemishav ?!?!?!?!
            if (args.ItemType === 'Staff' || args.ItemType === 'User') return;
            $('#TaskList').taskgrid('reload', args.FolderId, args.TaskTypeId, args.Completed);
            UpdateCartable();
            //router.navigateTo("#/TasksPageUserControl?id=" + args.FolderId + "&ttId=" + args.TaskTypeId + "&Completed=" + !!args.Completed);
        });

        subscribeEvent(WorkbenchConfigs.EventsEnum.CartableUpdated, function (event, args) {
            $('#TaskList').trigger('reloadGrid');
            UpdateCartable();
        });

        subscribeEvent(WorkbenchConfigs.EventsEnum.BpmsFormClosedInWinEoaPlugin, function (event, args) {
            utils.showGenericModal('', args, function () {
                window.external.hidePopup(args);
            });
        });

    }

    // public variables and methods

    return {
        Init: function () {
            handleEvents();
            // -- todo remove -- setUpRightSection();

        },
        // whenever we want to raise an event in workbench we must use this method
        PublishEvent: function (eventName, eventArgs) {
            return publishEvent(eventName, eventArgs);
        },
        // whenever we want to write event handlers for specific event in workbench we must use this method
        SubscribeEvent: function (eventName, func) {
            return subscribeEvent(eventName, func);
        },
        UnSubscribeEvent: function (token) {
            return unSubscribeEvent(token);
        },
        ChangeTreeNodeTextBasedOnLang: function (node, itemCount, totalCount, isTaskType) {
            const regx = new RegExp('^[a-zA-Z]');
            node.html(`${!isTaskType ? '<img src="Images/folder.png" />' : '<ins class="jstree-icon">&nbsp;</ins>'}${regx.test(node.text().trim()) ? "" : node.text()}<span style="direction:ltr;unicode-bidi:embed;" class="taskCount bold-item">${itemCount >= 0 && totalCount > 0 ? `(${totalCount}/${itemCount})` : ""}</span>${regx.test(node.text().trim()) ? node.text() : ""}`);

            if (totalCount !== 0)
                $(node).attr('title', `${i18n.$t('cartable_grid.total_tasks') + totalCount + i18n.$t('cartable_grid.total_unread_tasks')}\r\n${itemCount}`);
            else
                $(node).attr('title', '');
        }
    };
}();

let WorkbenchConfigs = function () {
    return {
        EventsEnum: {
            TreeNodeClicked: 0,
            CartableUpdated: 1,
            BpmsFormClosedInWinEoaPlugin: 2
        }
    };
}();

