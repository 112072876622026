var vm = vm || {};

vm.Message = (function () {

    var id = config.viewIds.tasks,
        isInitedMessagesGrid = false,
        MessageTableid = "#MessageList",


        refresh = function () {
            $(MessageTableid).trigger('reloadGrid');
        },
        viewMessage = function (rowId) {
            var uiForm = $("<div></div>");
            utils.showModalHtml("Views/ViewMessage.html")
                .done(function (html) { 
                    uiForm.append(html).dialog({
                        modal: true,
                        title: i18n.$t("message_management.message_show"),
                        width: 500,
                        height: 400,
                        close: function () {
                            $(this).dialog("destroy").remove();
                            uiForm.empty();
                            rootItemEl.dialog("destroy").remove();
                            rootItemEl.empty();
                            $(MessageTableid).messagegrid('setSelection', rowId);
                        }
                    });

                    //$(uiForm).find(".currentPic").hide(); 
                    utils.callWebAPIp("api/message/get", { MessageId: rowId })
                        .done(function (data) {
                            $("#lblSubject").html(data.d.Subject);
                            $("#lblCreatedDate").html(data.d.CreatedAt);
                            $("#lblBody").html(data.d.Body);
                            refresh();
                            uiForm.show();

                            // end of done find news
                        });

                });

        },
        activate = function (params) {
            // if bpms opens in eoffice as a plugin , hide header and tree 
            utils.hideHeaderAndTreeFromShell();

            config.currentViewCanLeave(canLeave);
            var $tasksForm = params.element,
                MessageListGrid = $(MessageTableid),
                isReceived = params.receivedMessage;
            //$('#jquery_ui_scope_style').attr('href', 'Styles/jquery/jquery-ui-scope-default/jquery-ui.custom.min.css');
            //if (!isInitedMessagesGrid) {
            //    MessageListGrid.messagegrid(getGridOptions()).attr('tabindex', '1000');
            //    isInitedMessagesGrid = true;
            //}
            //else
            //    MessageListGrid.messagegrid('reload');
            $('.view').hide();
            $tasksForm.show();

            // if a user dosen't login , hide the grid
            //$('#contentmain').css('display', config.getCurrentUserId() ? 'block' : 'none');

            //var width = $('#messagewidthRef').width();
            //$(MessageTableid).messagegrid('setWidth', width); //ageResize();
            //$('#MessageTableid').taskgrid('setHeight', $('#contentmain').height() - 110 - (msgHeight ? msgHeight + 15 : 0));
        },
        canLeave = function () {
            return true;
        },
        handlePageResize = function () {
            //var msgHeight = $('.MessageLabel').height();
            //$('#TaskList').taskgrid('setHeight', $('#contentmain').height() - 110 - (msgHeight ? msgHeight + 15 : 0));
            var width = $('#MessagewidthRef').width();
            $(MessageTableid).Messagegrid('setWidth', width);
        },
        getGridOptions = function () {

            return {
                //taskTypeId: taskTypeId,
                query: '',
                sortColumn: 'Subject',
                sortOrder: 'desc',
                enableFormlessEvents: true,
                isEoa: utils.isIntegrated(),
                pagerBox: '#pager',
                gridContextMenu: '#gridContextMenu',
                flagBox: '#flagSelectBox',
                pageTitle: document.title,
                onLoadComplete: handlePageResize
                //,completed: completed
            };
        };

    return {
        id: id,
        activate: activate,
        refresh: refresh,
        viewMessage: viewMessage
    };
})();
