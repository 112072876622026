import Modal from "../../widgets/modal/modal.vue";
import Tree from "../../widgets/tree/tree.vue";
import { Helper } from '../../../assets/ts/global';

const helper = new Helper();

export default {
    props: [
        'showRuleModal',
        'modalData',
        'baseInfo'
    ],
    watch: {
        baseInfo(newValue, oldValue) {
            const info = JSON.parse(newValue);
            this.dataTree = info.tree;
        }

        //this.modalData.baseInfo = newValue;

           //this.dataTree = this.modalData.baseInfo.tree;
    },
    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,
            modalHeader: "",
            name: "",
            subject: "",
            taskType: "00000000-0000-0000-0000-000000000000", // load from DB
            //createDate: "",
            //responseDate: "",
            //doneDate: "",
            priority: "[Unassigned]",// load from DB
            flag: 0, // load from DB
            isRead: false,
            isTranscript: false,
            app: "[Empty]", // load from DB
            flags: [],
            taskTypes: [],
            taskPriorities: [],
            bpmApplications: [],
            dataTree: [],
            treeTemp: null,
            contextItemType: {},
            expandedItems: {},
            contextItemType: {},
            direction: payload.currentLocale.IsRightToLeft,
            folderId: null,
            mustSelectedNodeId: null,
            setSelectedNodeId: null,
            errors:[]
        }
    },
    computed: {
        showModal() {
            this.init();
            this.modalHeader = this.modalData.isCreation ? i18n.$t("rule_management.add_new_rule") :
                                                           i18n.$t("rule_management.edit_rule");

            if (!this.modalData.isCreation) {
                this.edit();
                return this.showRuleModal;
            } else {
                return this.showRuleModal;
            }
        }
    },
    methods: {
        nodeSelect(node, nodeData) {
            if (nodeData.IsSelectable) {
                this.folderId = nodeData.Id;
                this.setSelectedNodeId = nodeData.Id;
            }
        },
        validation() {
            let result = true;
            let message = "";

            if (this.name.trim() === "") {
                message += i18n.$t("rule_management.rule_modal.validation.name") + "\n";
                result = false;
            }

            if (!result)
                alert(message);

            return result;
            
        },
        submit() {
            this.errors = [];
            if (this.validation()) {

                const url = "api/rules/save";
                let data = {};
                let callback;

                data = {
                    name: this.name,
                    subject: this.subject,
                    taskType: this.taskType,
                    createDate: $('#createDate').val(),
                    responseDate: $('#responseDate').val(),
                    doneDate: $('#doneDate').val(),
                    priority: this.priority,
                    flag: this.flag,
                    isRead: this.isRead,
                    isTranscript: this.isTranscript,
                    app: this.app,
                    isNew: this.modalData.isCreation,
                    folderId: this.folderId,
                    id: this.modalData.id,
                    principalId : this.modalData.pId
                };

                callback = () => {
                    $('.cancelModal', this.$el).click();
                    this.modalData.callback();
                };

                var that = this;

                this.doAjaxRequest(url,
                    JSON.stringify(data),
                    callback,
                    function (data) {
                        for (let i = 0; i < data.length; i++) {
                            const item = data[i];
                            for (let j = 0; j < item.Errors.length; j++) {
                                const errorItem = item.Errors[j];
                                that.errors.push(errorItem.ErrorMessage);
                            }
                        }
                    },
                    'POST');
            }
        },
        init() {
            this.name = "";
            this.subject = "";
            this.taskType = "00000000-0000-0000-0000-000000000000";
            //this.createDate = "";
            //this.responseDate = "";
            //this.doneDate = "";
            $('#createDate').val('');
            $('#responseDate').val('');
            $('#doneDate').val('');
            this.priority = "[Unassigned]";
            this.flag = 0;
            this.isRead = false;
            this.isTranscript = false;
            this.app = "[Empty]";
            this.folderId = null;
            this.mustSelectedNodeId = null;
            this.setSelectedNodeId = 0;
            this.errors = [];
        },
        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            const ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",

                contentType: "application/json;charset=utf-8"
            };
            if (method) {
                ajaxConfig.method = method;
            }
            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((r) => {
                    if (typeof onFailure === 'function') {

                        let response = null;

                        if (r.status == 400) {

                            response = JSON.parse(r.responseText);
                            onFailure(response);
                        }


                    }
                });
        },
        edit() {
            const that = this;
            const id = this.modalData.id;
            const url = "api/rules/get/" + id;

            this.doAjaxRequest(
                 url, {},
                 (data) => {
                     that.Id = data.Id;
                     that.name = data.Name;
                     that.subject = data.Subject;
                     that.taskType = data.TaskType || "00000000-0000-0000-0000-000000000000";
                     $('#createDate').val(data.CreateDate);
                     $('#responseDate').val(data.ResponseDate);
                     $('#doneDate').val(data.DoneDate);
                     that.priority = data.Priority || "[Unassigned]";
                     that.flag = data.Flag || 0;
                     that.isRead = data.IsRead;
                     that.isTranscript = data.IsTranscript;
                     that.app = data.App || "[Empty]";
                     that.setSelectedNodeId = data.FolderId;
                     that.folderId = data.FolderId;
                     //that.mustSelectedNodeId = null;
                });
        }
    },
    updated() {
    },
    mounted() {
        const info = JSON.parse(this.baseInfo);
        this.dataTree = info.tree;

        this.contextItemType = helper.enumBuilder({
            None: 0,
            Folder: 1,
            Staff: 2,
            User: 3,
            DefaultPhysicalFolder: 4,
            PhysicalFolder: 5,
            TaskType: 6
        });

        this.treeTemp = $("#rayvarzBPMSKendoTreeViewTemplate", this.$el).html();

        var that = this;

        $.each(info.taskTypes, function (index, item) {
            that.taskTypes.push({
                id: item.Id,
                text: item.Text
            });
        });

        $.each(info.flags, function (index, item) {
            that.flags.push({
                id: item.Id,
                text: item.Text
            });
        });

        $.each(info.taskPriorities, function (index, item) {
            that.taskPriorities.push({
                id: item.Id,
                text: item.Text
            });
        });

        $.each(info.bpmApplications, function (index, item) {
            that.bpmApplications.push({
                id: item.Id,
                text: item.Text
            });
        });

        $('#createDate').click(function (e) {
            e.stopPropagation();
        });

        $('#responseDate').click(function (e) {
            e.stopPropagation();
        });

        $('#doneDate').click(function (e) {
            e.stopPropagation();
        });

        $('#createDate').MdPersianDateTimePicker({
            Placement: 'bottom',
            Trigger: 'click',
            EnableTimePicker: false,
            TargetSelector: '#createDate',
            Format: 'yyyy/MM/dd',
            IsGregorian: true,
            EnglishNumber: true,
        });

        $('#responseDate').MdPersianDateTimePicker({
            Placement: 'bottom',
            Trigger: 'click',
            EnableTimePicker: false,
            TargetSelector: '#responseDate',
            Format: 'yyyy/MM/dd',
            IsGregorian: true,
            EnglishNumber: true,
        });

        $('#doneDate').MdPersianDateTimePicker({
            Placement: 'bottom',
            Trigger: 'click',
            EnableTimePicker: false,
            TargetSelector: '#doneDate',
            Format: 'yyyy/MM/dd',
            IsGregorian: true,
            EnglishNumber: true,
        });

    },
    created() {
    },
    components: {
        Modal,
        Tree
    }
}
