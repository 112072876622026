import utils from 'jsf/utils.js';
import { processHistory } from 'jsf/process/ProcessHistory.js';
import { processLog } from 'jsf/vm.processLog.js';
import { appForm } from 'jsf/vm.appform.js';
import { timesheet } from 'jsf/vm.timesheet.js';
import LoadPanel from "devextreme/ui/load_panel";
import Toolbar from "devextreme/ui/toolbar";
//import Signature from '../App/Component/ConfirmDigitalSignature/ConfirmDigitalSignature.vue';

$.extend($.expr[':'], {
    nothidden: function (a) {
        return $(a).css("display") !== "none" && $(a).css("visibility") !== "hidden" && !$(a).is(":disabled");
    }
});
; (function ($, undefined) {
    // static prop in all instance of widget
    const shortkeysAlreadyAttached = false;
    let saveAllowed = true;
    const serviceUrl = 'api';
    const defaultPage = '#/TasksPage';
    const validationDivId = '#appform-validationsummary';
    const buttonsId = ".appform-buttons";
    let postponed = [];
    //Ids are enum values from Ray.BPMS.Model.ControlTypeEnum
    const classToControlMaps = [
        { controlTypeId: 0, clazz: '.RayLabel', control: 'rayLabel' },
        { controlTypeId: 1, clazz: '.RayTextBox', control: 'rayTextBox' },
        { controlTypeId: 2, clazz: '.RayDropDownList', control: 'rayDropDownList' },
        { controlTypeId: 3, clazz: '.RayAutoCompleteDropDownList', control: 'rayAutoCompleteDropDownList' },
        { controlTypeId: 4, clazz: '.RayAssignControl', control: 'rayAssignControl' },
        { controlTypeId: 5, clazz: '.RayCheckBox', control: 'rayCheckBox' },
        { controlTypeId: 6, clazz: '.RayNumericTextBox', control: 'rayNumericTextBox' },
        { controlTypeId: 7, clazz: '.RayDatePicker', control: 'rayDatePicker' },
        { controlTypeId: 8, clazz: '.RayGrid', control: 'rayGrid' },
        { controlTypeId: 9, clazz: '.RayNestedLink', control: 'rayNestedLink' },
        { controlTypeId: 10, clazz: '.RayRadioButtonList', control: 'rayRadioButtonList' },
        { controlTypeId: 11, clazz: '.RayCheckBoxList', control: 'rayCheckBoxList' },
        { controlTypeId: 12, clazz: '.RaySearchPanel', control: 'raySearchPanel' },
        { controlTypeId: 13, clazz: '.RayFileAttachment', control: 'rayFileAttachment' },
        { controlTypeId: 14, clazz: '.RayUserSignature', control: 'rayUserSignature' },
        { controlTypeId: 15, clazz: '.RayTime', control: 'rayTime' },
        { controlTypeId: 16, clazz: '.RayButton', control: 'rayButton' },
        { controlTypeId: 17, clazz: '.RayTabControl', control: 'rayTabControl' },
        { controlTypeId: 18, clazz: '.RayImageViewer', control: 'rayImageViewer' },
        { controlTypeId: 19, clazz: '.RayImageControl', control: 'rayImageControl' },
        { controlTypeId: 20, clazz: '.RayPayment', control: 'rayPayment' },
        { controlTypeId: 21, clazz: '.RayChart', control: 'rayChart' },
        { controlTypeId: 22, clazz: '.RayGauge', control: 'rayGauge' },
        { controlTypeId: 23, clazz: '.RayTree', control: 'rayTree' },
        { controlTypeId: 24, clazz: '.RayGanttChart', control: 'rayGanttChart' },
        { controlTypeId: 25, clazz: '.RayUserPicture', control: 'rayUserPicture' },
        { controlTypeId: 26, clazz: '.RayGenericGrid', control: 'rayGenericGrid' },
        { controlTypeId: 27, clazz: '.RayRichTextBox', control: 'rayRichTextBox' },
        { controlTypeId: 28, clazz: '.RayMap', control: 'rayMap' },
        { controlTypeId: 29, clazz: '.RayRectangle', control: 'rayRectangle' },
        { controlTypeId: 30, clazz: '.RayTabPage', control: 'rayTabPage' },
        { controlTypeId: 31, clazz: '.RayLine', control: 'rayLine' },
        { controlTypeId: 32, clazz: '.RayKPI', control: 'rayKPI' },
        { controlTypeId: 33, clazz: '.ArpgControl', control: 'arpgControl' },
        { controlTypeId: 34, clazz: '.RayState', control: 'rayState' },
        { controlTypeId: 35, clazz: '.RayClientChart', control: 'RayClientChart' },
        { controlTypeId: 36, clazz: '.RayDynamicImage', control: 'rayDynamicImage' },
        { controlTypeId: 37, clazz: '.RayHelp', control: 'rayHelp' },
        { controlTypeId: 38, clazz: '.RayAdvancedGantt', control: 'rayAdvancedGantt' },
        { controlTypeId: 39, clazz: '.RayRow', control: 'rayRow' },
        { controlTypeId: 40, clazz: '.RayColumn', control: 'rayColumn' },
        { controlTypeId: 41, clazz: '.RayComment', control: 'rayComment' },
        { controlTypeId: 42, clazz: '.RayDateTimePicker', control: 'rayDateTimePicker' },
        { controlTypeId: 43, clazz: '.RayCanvasPanel', control: 'rayCanvasPanel' },
        { controlTypeId: 45, clazz: '.Accordion', control: 'accordion' },
        { controlTypeId: 46, clazz: '.AccordionSection', control: 'accordionSection' },
        //{ clazz: '', control: '' }
    ];

    $.widget('ui.bpmsAppForm', {
        lastFocusedElement: null,
        lastFocusedGrid: null,
        closeModalButton: null,
        options: {
            nested: null,
            // appName
            // pageInstanceId
            // Id
            // objState
            appFormView: '#appform-content',
            formId: '',
            isCustomForm: false,
            isStarterForm: false,
            taskId: '',
            taskStatus: '',
            domainObjectId: '',
            applierControl: '',
            isDraft: '',
            taskTypeId: '',
            resetGridPage: false,
            lastSubmitData: null,
            isTopMenu: false,
            showLoading:
                function () { },
            autoSaveHandler: null,
            loadPanel: null,
            toolbar: null,
        },

        appFormTitlebarHtml: `
            <div>
                <input type="image" style="display:none;min-height:35px" disabled="disabled"/>
                <div class="securityTitle"></div>
                <span style="float: right;" class="right-title">
                <button type="button" style="display:none;float:right !important;margin-left:5px !important;" class="titlebar-close ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close" role="button" title="Close">
                  <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
                  <span class="ui-button-text">Close</span>
                </button>
            </div>`,
        appFormTitlebarHtmlLeft: `
            <div>
                <input type="image" style="display:none;min-height:35px" disabled="disabled"/>
                <div class="securityTitle"></div>
                <span style="float: left;" class="right-title">
                <button type="button" style="display:none;float:left !important;margin-right:5px !important;" class="titlebar-close ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close" role="button" title="Close">
                  <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
                  <span class="ui-button-text">Close</span>
                </button>
            </div>`,
        reportMenuHtml:
            `<div class="jquery-ui-scope ">
  <ul id="ReportsMenu" class="no-print menu-container" style="inline-block" >
    <li class="ui-corner-all ui-state-default">
     <span><img src="images/Reports.png">{{$t("report")}}</span>
     <ul id="ReportsMenu_list"></ul>
   </li>
  </ul>
</div></br></br>`,
        appFormHtml:
            `<div class="ui-widget jquery-ui-scope view ui-corner-bottom app-form row" id="appform">
  <div class="ui-widget-content">
    <div class="col-lg-6">
      <div id="divApplierPrincipal" class="no-print form-group" style="display: none">
        <label for="cbApplierPrincipals">{{$t("send_as_staff")}}:</label>
        <select id="cbApplierPrincipals" class="form-control" />
      </div>
    </div>
    <fieldset class="form-fieldset">
      <div id="appform-content" class="col-xs-12"></div>
    </fieldset>
    <div style="display: none;" class="form-validate-summary alert alert-danger">
    <ul id="appform-validationsummary">
    </ul>
  </div>
  <div class="appform-buttons" align="center"></div>
  </div>
</div>`,

        dto: null,
        applierPrincipalId: null,

        _create: function () {
        },

        getControlStateByTypeId: function (controlTypeId) {
            var result = classToControlMaps.filter(function (item) { return item.controlTypeId === controlTypeId; });
            return result ? result[0] : null; // or undefined
        },
        getControlStateByClazz: function (clazz) {
            var result = classToControlMaps.filter(function (item) { return item.clazz === clazz; });
            return result ? result[0] : null; // or undefined
        },

        _init: function () {
            if (this.options.isDraft)
                this.options.isStarterForm = true;
            const self = this;
            this.dto = null;

            this.appForm = $(this.appFormHtml);
            if (this.options.nested)
                this._createNestedDialog();
            else {
                this.appFormTitlebar = $(this.appFormTitlebarHtml);
            }
            self._AddTooltip(this.appFormTitlebar);

            let html = this.appForm.html();
            const goBackHtml = '';

            if (this.options.isStarterForm) {
                //goBackHtml = '<div class="form-prev-icon-menu-state"><a class="btn btn-primary" class="prev-inside-form" style="background: rgba(28,175,154,1)">return<span class="glyphicon glyphicon-arrow-left"></span></a></div>';
            }

            //console.info("Set window.onbeforeunload");
            window.onbeforeunload = function () { return ""; }
            html = `${goBackHtml}<div class="form-container-responsive">${html}</div>`;

            this.appForm.html(html);

            if (!this.options.nested) {
                self.options.toolbarContainer.prepend(this.appFormTitlebar.children());
                this.appFormTitlebar = self.options.toolbarContainer;
            }

            this._createAppForm().done(function () {

                if (typeof self.options.formLoadedCallback !== "undefined")
                    self.options.formLoadedCallback(self.dto);

                try {
                    if (self.options.nested) {
                        let width;
                        let height = null;
                        //if (self.dto.formState.IsResponsive !== true) {
                        //    width = self.dto.formState.FormWidth + 50;
                        //    height = self.dto.formState.FormHeight + 50;
                        //} else
                        width = `col-lg-${self.dto.formState.ResponsiveWdith}`;

                        self.element.rayModal("rebind",
                            {
                                width: width,
                                height: height,
                                title: self.dto.formState.Title
                            });
                    }

                } catch (ex) {
                    console.error("modal rebind", ex);
                }


                if (!self.options.nested) {
                    const formContainerResponsive = self.appForm.find(".form-container-responsive");
                    //var colWidth = 'col-xs-12' + ' col-md-' + self.dto.formState.ResponsiveWdith;

                    //formContainerResponsive.addClass('center-block ' + colWidth);
                    formContainerResponsive.attr('isr', true);
                    formContainerResponsive.append($('<div class="clearfix"></div>'));
                }

                if (self.options.isStarterForm) {
                    let parentId = utils.getParameterByName('Parent');
                    if (parentId == 'undefind')
                        parentId = '00000000-0000-0000-0000-000000000000';
                    //if (parentId == '00000000-0000-0000-0000-000000000000' || parentId.length == 36) {

                    const isPlugin = window.location.toString().toLowerCase().indexOf('co=t') >= 0;

                    if (!isPlugin)
                        self.appForm.find('.form-prev-icon-menu-state:first').css('display', 'block');

                    self.appForm.find('.form-prev-icon-menu-state:first a').unbind('click').bind('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        //var url;
                        //if (parentId == '00000000-0000-0000-0000-000000000000' || parentId == '' || parentId == null)
                        //    url = '#/Home';
                        //else
                        //    url = '#/Home/' + parentId;

                        //router.navigateTo(url);

                        //console.log("Go to Home menu", parentId);
                        router.push({ name: 'home-menu' });

                        self.appForm.find('.form-prev-icon-menu-state').hide();
                    });
                    //}
                }

                if (self.appForm.find('.old-form:first', self.element).length !== 0) {
                    const width = $('.BpmsForm', self.element).width();
                    const heigth = $('.BpmsForm', self.element).height();

                    if (!self.options.nested)
                        self.appForm.width(width);
                    //else
                    //    self.element.rayModal('option', 'width', width + 40);
                }


                //self._initFormState();
                self._attachShortcutKeys();

                self.appForm.find(".app-form:first").addClass("form-shadow");

                // our requests are ajax requests, so we reject it if a submit happens!
                self.appForm.find("#fakeForm").submit(function () {
                    return true;
                });

                self.element.focusin(function (e) {
                    e.stopPropagation();
                    e.preventDefault();

                    self.lastFocusedElement = $(e.target);
                    self.lastFocusedGrid = $(e.target).closest(".RayGrid");
                    //console.log("focusin", self.lastFocusedElement);
                });

                if (self.options.nested === false || self.options.nested == null)
                    self._fixTabIndex();

                self.appForm.find('[data-toggle="popover"]').popover({ container: 'body', placement: 'auto top' });

                // Set localizable elements
                let langAttribute = `data-caption-${payload.currentLocale.Language}`;
                let langDataset = `caption${payload.currentLocale.Language}`;
                self.appForm.find(".localizable").each(function () {
                    let caption = $(this).attr(langAttribute);
                    if (!caption)
                        caption = this.dataset[langDataset];
                    if (caption) {
                        if (this.tagName === "A")
                            this.innerText = caption;
                        else {
                            //Remove all newlines
                            Array.from($(this).contents().filter(function () { return this.nodeName === 'BR'; })).forEach(item => { item.remove(); });
                            //Removing all texts from the end and replacing the first one by localised caption
                            var array = Array.from($(this).contents().filter(function () { return this.nodeType === 3; }));
                            for (var i = array.length - 1; i >= 0; i--) {
                                if (i == 0) { $(array[i]).replaceWith(caption); } else { $(array[i]).remove(); }
                            }
                            //Old method, doesnt work with multiple lines (text/br/text)
                            //$(this).contents().filter(function () { return this.nodeType == 3; }).first().replaceWith(caption);
                        }
                    }
                });

                self.appForm.find(".localizableTitle").each(function () {
                    const caption = $(this).attr(langAttribute);
                    if (caption)
                        $(this).attr("title", caption);
                });

                self.appForm.find(".localizablePlaceholder").each(function () {
                    const caption = $(this).attr(langAttribute);
                    if (caption)
                        $(this).attr("placeholder", caption);
                });
                //if (self.element.find('.RayRow').length > 0 && self.options.nested) {
                //    self.element.width('500px');
                //    self.element.closest('.ui-dialog').width('530px');
                //}
            });
        },

        _AddTooltip: function (toolbar) {

            toolbar.find(".titlebar-close").attr("title", i18n.$t("close"));
        },

        _findTopMostDialog: function () { return $('.modal:visible'); },

        _attachShortcutKeys: function () {
            const self = this;
            if (!shortkeysAlreadyAttached) {
                $(window).bind("keydown", function (event) {
                    let dialog;
                    if (event.ctrlKey || event.metaKey) {
                        dialog = self._findTopMostDialog();
                        if (dialog === null) return;
                        switch (String.fromCharCode(event.which).toLowerCase()) {
                            case "s":
                                event.stopImmediatePropagation();
                                event.preventDefault();
                                $(dialog).find(".save-and-back-btn:visible").click();
                                break;
                            case "b":
                                event.stopImmediatePropagation();
                                event.preventDefault();
                                $(dialog).find(".new-btn:visible").click();
                        }
                    } else if (event.which === 27) { // escape , close dialog
                        dialog = self._findTopMostDialog();
                        if (dialog === null) return;
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        const escbtn = $(dialog).find(".esc-btn:visible");
                        escbtn.focus();
                        escbtn.click();
                    }
                });
            }
        },

        _fixOverlayTabIndex: function (ownerId) {
            $("a[tabindex], input[tabindex], select[tabindex], " +
                "button[tabindex], textarea[tabindex], [tabindex]").each(function () {
                    if (typeof $(this).attr("owner") != "undefined")
                        return;
                    $(this).attr("old-tabindex", $(this).attr("tabindex"));
                    $(this).attr("tabindex", "-1");
                    $(this).attr("owner", ownerId);
                });
        },

        _removeOverlayTabIndex: function (ownerId) {
            $(`[owner='${ownerId}']`).each(function () {
                $(this).attr("tabindex", $(this).attr("old-tabindex"));
                $(this).removeAttr("old-tabindex");
                $(this).removeAttr("owner");
            });
        },

        _createNestedDialog: function () {
            const self = this;
            const myId = `form_modal_${utils.rayGuid()}`;
            this._fixOverlayTabIndex(myId);
            this.element.attr("guid", myId);
            this.lastScrollTop = $(window).scrollTop();

            //this.element.dialog({
            //    autoOpen: false,
            //    modal: true,
            //    closeOnEscape: false,
            //    resizable: false,
            //    close: function () {
            //        self._removeOverlayTabIndex($(this).attr("guid"));
            //        $(window).scrollTop(self.lastScrollTop);
            //        window.scrollData = self.lastScrollTop;
            //    }
            //}).addClass("app-modal-dialog").removeClass("ui-widget-content");

            //this.appFormTitlebar = $('.ui-dialog-titlebar', this.element.parent());
            //this.appFormTitlebar.empty().addClass('jquery-ui-scope').append($(this.appFormTitlebarHtml).html());

            //this.closeModalButton = this.appFormTitlebar.find(".titlebar-close").css('display', 'block').addClass('esc-btn');

            if (this.options.parentBpmsAppForm !== null &&
                typeof this.options.parentBpmsAppForm !== "undefined" &&
                this.options.parentBpmsAppForm.nested) {
            }

            const modalOptions = {
                modalKey: `modal_key_${this.options.nested.pageInsId}`,
                parent: this.options.parentBpmsAppForm,
                hiddenFooter: true,
                onClose: function () {
                    self._removeOverlayTabIndex(self.element.attr("guid"));
                    $(window).scrollTop(self.lastScrollTop);
                    window.scrollData = self.lastScrollTop;
                    const saveButton = $(`#save_${self.options.nested.Id}${self.options.nested.pageInsId}`);

                    // make a call to clean up the nested form data
                    //utils.callWebAPI(`api/form/cleandata?pageInstanceId=${self.dto.formState.PageInstanceId}`, {}, null, null, 'POST');

                    //if (saveButton.attr('isdirty') == 'true')
                    //    self._toRestorDirtyObject(self.options.nested.objstate, self.options.nested.Id, self.options.nested.pageInsId).done(function () {
                    //        //self.closeForm();
                    //    });

                    return true;
                }
            };

            if (this.options.nested && this.options.nested !== null && typeof this.options.nested !== "undefined")
                modalOptions.title = this.options.nested.title;

            modalOptions.mode = this.options.nested.editorMode;
            //console.log("Nested editor mode", this.options.nested.editorMode);

            this.element.rayModal(modalOptions);

            this.appFormTitlebar = $('.modal-header', this.element.closest('.modal-content'));
            this.appFormTitlebar.find('h4').nextAll('*').remove();

            if (payload.currentLocale.IsRightToLeft) {
                this.appFormTitlebar.append($(this.appFormTitlebarHtmlLeft).html());
                this.appFormTitlebar.addClass("headerToTheLeft");
            } else {
                this.appFormTitlebar.append($(this.appFormTitlebarHtml).html());
                this.appFormTitlebar.addClass("headerToTheRight");
            }

            this.loadPanel = new LoadPanel($('.loadpanel'), {
                shadingColor: 'rgba(0,0,0,0.4)',
                visible: false,
                showIndicator: true,
                showPane: true,
                shading: true,
                hideOnOutsideClick: false
            });

            self.options.showLoading = function (visible) {
                //if (localStorage.dbpDebugMode === 'true')
                //    console.log("Show loading JS", visible);
                if (visible)
                    self.loadPanel.show();
                else
                    self.loadPanel.hide();
            };
        },

        _createAppForm: function () {
            const self = this;

            let method;
            if (this.options.nested)
                method = `loadform-nested/${this.options.nested.pageInsId}/${this.options.nested.Id}/${this.options.nested.objstate}`;
            else if (this.options.isDraft === true)
                method = `loadform-draft/${this.options.taskId}/${this.options.taskTypeId}/${this.options.taskStatus}`;
            else if (this.options.isStarterForm === true)
                method = `loadform-starter/${this.options.formId}`;
            else if (this.options.domainObjectId !== "")
                method = `loadform-domain/${this.options.domainObjectId}/${this.options.taskTypeId}`;
            else
                method = `loadform-task/${this.options.taskId}/${this.options.taskStatus}`;

            self._changedItems = [];

            this.loadPanel = new LoadPanel($('.loadpanel'), {
                shadingColor: 'rgba(0,0,0,0.4)',
                visible: false,
                showIndicator: true,
                showPane: true,
                shading: true,
                hideOnOutsideClick: false
            });

            self.options.showLoading = function (visible) {
                //if (localStorage.dbpDebugMode === 'true')
                //    console.log("Show loading JS 2", visible);
                if (visible)
                    self.loadPanel.show();
                else
                    self.loadPanel.hide();
            };

            self.options.showLoading(true);
            let startCreate = new Date().getTime();

            return this._ajaxPostRequest.call(self, serviceUrl, method, null, true, "GET", "").done(function (fs) {
                self.element.empty();
                self.appForm.appendTo(self.element);

                // for vue-i18n, wrap it into a component
                //new legacyComponent({ i18n: vueI18n }).$mount(self.element[0]);

                self.validationDiv = $(validationDivId, self.element);
                self._wireupTitlebarHandlers();

                if (self.options.nested) {
                    const c = parseInt($(fs.html).find(".BpmsForm:first").css("height"));
                    //$(self.element).dialog("option", "minHeight", c + 10);
                }

                self.dto = fs;

                if (localStorage.dbpDebugMode === 'true') {
                    let elapsed = new Date().getTime() - startCreate;
                    if (localStorage.dbpDebugMode === 'true')
                        console.log(`Form '${self.dto.formTitle}' get data: ${elapsed} ms`);
                    startCreate = new Date().getTime();
                }

                self._handleResponse(fs.formState).done(function (result) {
                    if (result === 'dontContinue')
                        return;
                    appForm().currentBpmsAppForm(self);
                    const form = self.appForm.find('#appform-content');
                    form.empty().append(fs.html.replace('<br />', '<br>'));
                    form.attr("pageinstanceid", fs.formState.PageInstanceId);

                    new legacyComponent({ i18n: vueI18n })
                        .$mount(form[0])
                        .$nextTick(function () {
                            const width = $('.BpmsForm', self.element).width();
                            if (!self.options.nested) {
                                if ($('.old-form:first', self.element).length !== 0) {
                                    $('#appform', self.element).css('max-width', width + 2);
                                    $('.ui-widget-content:first', self.element).addClass('col-xs-12');
                                    $('.ui-widget-content:first', self.element).addClass('responsive-content-widget');
                                }
                                else {
                                    $('.ui-widget-content:first', self.element).addClass('col-xs-12');
                                    $('.ui-widget-content:first', self.element).addClass('responsive-content-widget');
                                }

                                //self.appForm.width(width);
                            } else {
                                const maxWidth = $(document).width() - 100;
                                //if (!fs.formState.IsResponsive)
                                //    self.element.dialog('option', 'width', maxWidth < (width + 40) ? maxWidth : width + 40);
                                //else
                                //    self.element.dialog('option', 'width', 'auto');
                                $('.ui-widget-content:first', self.element).addClass('col-xs-12');
                                $('.ui-widget-content:first', self.element).addClass('responsive-content-widget');
                            }

                            utils.applyUiButtons(self.element);
                            self._initTitlebar();

                            // Async version
                            //setTimeout(function () {
                            //    let start = new Date().getTime();
                            //    self._initControls();
                            //    let elapsed = new Date().getTime() - start;
                            //    console.log("Form initControls total", elapsed);

                            //    start = new Date().getTime();
                            //    if (!self.options.nested) {
                            //        self._showPdfForm();
                            //    }
                            //    self._setControlsValidators();
                            //    self._addPossibleEvents();
                            //    elapsed = new Date().getTime() - start;
                            //    console.log("Form init stage 3", elapsed);
                            //}, 100);

                            let start = new Date().getTime();
                            self._initControls();

                            //if (!self.options.nested)
                            //    self._showPdfForm();

                            //self._setControlsValidators();
                            self._addPossibleEvents();

                            form.show();
                            $('#appform', self.element).show();
                            if (self.options.nested) {

                                //if (fs.formState.IsResponsive) {
                                self.element.attr('isr', true);
                                let cssClass = `col-xs-12 col-lg-${fs.formState.ResponsiveWdith}`;

                                if (payload.isPlugin)
                                    cssClass = `col-xs-12 col-sm-${fs.formState.ResponsiveWdith}`;

                                self.element.closest('.ui-dialog').addClass(cssClass);
                                //}

                                //self.element.dialog('open');
                                appForm().currentBpmsAppForm(self);
                            }
                        });

                    //self._fixTabIndex();
                }).fail(function (a, b, c) {
                    utils.showAjaxError(a, b, c);
                });
            }).fail(function (a, b, c) {
                if (a.status === 500)
                    utils.showAjaxError(a, b, c);
                else if (a.status === 410) {
                    utils.message(i18n.$t('errors.application_is_not_deployed'), 3);
                    router.push({ name: 'home' });
                }
                else if (a.status === 403) {
                    utils.message(i18n.$t('errors.you_are_not_allowed_to_view_form'), 3);
                    router.push({ name: 'home' });
                }
            }).always(function () {
                //utils.hideProgress();
                self.options.showLoading(false);
                utils.fixHeight();
                if (localStorage.dbpDebugMode === 'true') {
                    let elapsed = new Date().getTime() - startCreate;
                    if (localStorage.dbpDebugMode === 'true')
                        console.log(`Form '${self.dto.formTitle}' created: ${elapsed} ms`);
                }
            }).promise();
        },

        _showApplierPrincipals: function () {
            const lbl = $('[for=cbApplierPrincipals]', this.element);
            lbl.html(`${i18n.$t("send_as_staff")}:`);

            const self = this;
            const cb = $('#cbApplierPrincipals', this.element);
            const staffs = this.dto.staffs;
            if (staffs) {
                for (let i = 0; i < staffs.length; i++)
                    cb.append(`<option value="${staffs[i].ID}">${staffs[i].Title}</option>`);
                if (staffs.length > 0)
                    self.applierPrincipalId = staffs[0].ID;
                cb.change(function () {
                    self.applierPrincipalId = $(this).val();
                    self.updateForm('applierControl', false, true);
                });
                $('#divApplierPrincipal', this.element).show();

                if (staffs.length === 1)
                    cb.attr("disabled", "disabled");

            } else
                $('#divApplierPrincipal', this.element).hide();
        },

        //_showPdfForm: function () {
        //    //const self = this;
        //    const pdfform = $('#divPdfForm', this.element);
        //    const uploadPdfForm = this.dto.formState.PdfForm;

        //    console.log('_showPdfForm', uploadPdfForm, pdfform);

        //    if (uploadPdfForm)
        //        pdfform.show();
        //    else
        //        pdfform.hide();
        //},

        _wireupTitlebarHandlers: function () {
            const self = this;

            //$('.titlebar-printWord', this.appFormTitlebar).click(function () {
            //    var that = this;
            //    var filename = '';
            //    $(this).attr('disabled', 'disabled');
            //    self.updateForm().done(function () {
            //        setTimeout(function () {
            //            utils.getWord(filename, false, true);
            //            //window.location.href = "cms/Modal_Download.aspx?pageInstanceId=" + self.dto.formState.PageInstanceId +
            //            //"&FileName=" + filename + "&TimeSpan=" + new Date().getTime();
            //        }, 2000);
            //    }).always(function () {
            //        $(that).removeAttr("disabled");
            //    });
            //    return false;
            //});

            $('.formHelperButton .dx-button').keypress(function (event) {
                if (event.which == "13")
                    return false;
            });
        },

        _addSaveButton: function () {
            const self = this;
            this.appFormButtons = this.appForm.find(buttonsId);
            //if ((this.options.isStarterForm === true && this.dto.formState.CanSave === true && this.dto.formState.SaveButtonOnBottom === true) || (!this.options.isStarterForm && !this.dto.formState.IsNestedForm && this.dto.formState.CanSave && this.dto.formState.SaveButtonOnBottom)) {
            if (this.dto.formState.CanSave &&
                !this.dto.formState.IsNestedForm &&
                this.dto.formState.SaveButtonOnBottom) {
                const btn = $(`<button class='btn btn-default'>${i18n.$t("save_form")}</button>`)
                    .bind("click keydown", function (e) {
                        // save without validations
                        self.saveFormNoValidation(true);
                    })
                    .prependTo(self.appFormButtons);

                //btn.css("margin-right", "50px");
            }

            // save button
            //if (this.element.find('[DefaultAction=Save]').length > 0
            //    && this.dto.formState.CanSave
            //    && !this.dto.formState.IsNestedForm) {

            //    this.element.find('[DefaultAction=Save]').click(function () {
            //        self.saveForm(true);
            //        return false;
            //    });
            //}
        },

        _addPossibleEvents: function () {
            const self = this;
            this.appFormButtons = this.appForm.find(buttonsId);
            this.appFormButtons.empty();
            if (this.options.isStarterForm) {
                if (this.dto.formState.NeedSubmitButton)
                    this._addSubmitButton();

                if (this.element.find('[DefaultAction=Submit]').length > 0 && this.options.isStarterForm)
                    this.element.find('[DefaultAction=Submit]').click(function () {
                        self.submit().always(function () {
                            setInterval(function () {
                                //this.btn.disabled = false;
                            }, 1500);
                        });
                    });

                // save button
                if (this.element.find('[DefaultAction=Save]').length > 0
                    && this.dto.formState.CanSave
                    && !this.dto.formState.IsNestedForm) {

                    this.element.find('[DefaultAction=Save]').click(function (e) {
                        e.preventDefault();
                        self.saveForm(true);
                        return false;
                    });
                }

            } else
                this._getPossibleButtons();
        },

        _sendSecretCode: function (userId, pageInstanceId) {
            utils.callWebAPI('api/form/sendsecretcode', { userId: userId, pageInstanceId: pageInstanceId });
        },

        _generateEvents: function (eventsProxy, DisabledEvents) {
            const self = this;
            const generateMessageSpan = function (text) {
                return $('<span>').addClass("event-message ui-widget ui-state-highlight ui-corner-all").text(text);
            };

            self.appFormButtons.empty();
            if (eventsProxy.AccessDenied) {
                const messages = eventsProxy.Messages;
                let btnCt = generateMessageSpan(messages[0]);
                btnCt.addClass("no-print");
                self.appFormButtons.append(btnCt);
                self.appFormButtons.append('<br\>');
                btnCt = generateMessageSpan(i18n.$t("errors.operation_is_not_allowed"));
                btnCt.addClass("no-print");
                self.appFormButtons.append(btnCt);
            }
            else {
                const events = eventsProxy.Events;
                const messages = eventsProxy.Messages;
                const buttonGroup = $('<div class="btn-group" style="vertical-align: top;"></div>');
                self.appFormButtons.append(buttonGroup);

                for (let i in events) {
                    const event = events[i];
                    const btn = $('<button class="btn btn-primary eventbutton">').attr('type', 'button').attr('id', event.Id).attr('signaturerequired', event.SignatureRequired ? 'true' : 'false');

                    if (event.ButtonColor != null && event.ButtonColor != '') {
                        btn.css('cssText', `background-color: rgba${event.ButtonColor} !important;`);
                        if (event.ButtonHoverColor != null && event.ButtonHoverColor != '') {
                            $(btn).hover(function () {
                                $(this).css('cssText', `background-color: rgba${event.ButtonHoverColor} !important;`);
                            }, function () {
                                $(this).css('cssText', `background-color: rgba${event.ButtonColor} !important;`);
                            });
                        }
                    }
                    if (event.Icon)
                        btn.css("background-image", `url(api/file/getappfile?imageId={${event.Icon}})`).addClass('eventButton');
                    btn.addClass("no-print");
                    //var span = $('<span>').text(event.Title);
                    $(btn).append(event.Title);
                    //if (event.Icon)
                    //    span.css("background-image", "url(Handlers/applicationfilehandler.ashx?Id={" + event.Icon + "})").addClass('eventButton');
                    btn.bind("click keydown", function (e) {
                        if (e.type.toLowerCase() !== "click" && e.keyCode !== 0 && e.keyCode && e.keyCode !== 32)
                            return;

                        const thisbtn = this;
                        thisbtn.disabled = true;
                        const signatureRequired = $(this).attr('signaturerequired') === "true";
                        const promise = self._callFormEvent($(this).attr('id'), signatureRequired);

                        promise.done(function (result) {
                            if (self.options.nested) {
                                self.options.parentBpmsAppForm.getForm(self.options.nested.Id);
                                self.options.showLoading(false);
                                if (result != 'dontContinue') 
                                    self.closeForm();
                                else
                                    thisbtn.disabled = false;
                            }
                        }).fail(function (a, b, c) {
                            thisbtn.disabled = false;
                            self.options.showLoading(false);
                            if (self.options.nested) {
                                utils.showAjaxError(a, b, c);
                            }
                        }).always(function () {
                            //debugger;
                            thisbtn.disabled = false;
                            if (self.options.nested) {
                                setInterval(function () { thisbtn.disabled = false; }, 1500);
                            }
                        });
                    });

                    buttonGroup.append(btn);
                }


                for (let j in messages)
                    !this._isNullOrWhiteSpace(messages[j]) && self.appFormButtons.append(generateMessageSpan(messages[j]));

                if (this.options.nested)
                    this._addNestedButton();

                self._addSaveButton();
            }
            self._fixTabIndex();

            if (DisabledEvents && self.appFormButtons) {
                //console.log("Events", DisabledEvents);
                self.appFormButtons.find('.eventbutton').show();
                DisabledEvents.forEach(item => {
                    self.appFormButtons.find(`.eventbutton#${item}`).hide();
                });
            }
        },

        _fixTabIndex: function () {
            const self = this;

            setTimeout(function () {
                let biggestTabIndex = 0;
                self.element.find("input, button, select, textarea, *[tabindex]").each(function () {
                    const index = parseInt($(this).attr("tabindex"));
                    if (index > biggestTabIndex)
                        biggestTabIndex = index;
                });

                self.element.find(".appform-buttons:first").find("button").each(function () {
                    $(this).attr("tabindex", ++biggestTabIndex);
                });

                let firstElement = $(self.element.find("[tabindex=1]:nothidden:first")[0]);

                if (typeof firstElement === "undefined" || firstElement.length === 0) {
                    let minTab = 100000;
                    let elems = self.element.find("[tabindex]:nothidden");
                    for (let i = 0; i < elems.length; i++) {
                        const tab = parseInt($(elems[i]).attr("tabindex"));
                        if (!isNaN(tab) && tab < minTab && tab !== 0 && tab !== -1)
                            minTab = tab;
                    }

                    firstElement = $(self.element.find(`[tabindex=${minTab}]:first`));
                }

                let lastElement = $(self.element.find(`[tabindex=${biggestTabIndex}]`)[0]);
                while (lastElement !== null && typeof lastElement !== "undefined" && lastElement.is(":disabled")) {
                    biggestTabIndex--;
                    lastElement = $(self.element.find(`[tabindex=${biggestTabIndex}]`)[0]);
                }

                self.lastElement = lastElement;
                lastElement.on("keydown",
                    (function (e) {

                        if (e.keyCode === 9) {
                            if (self.lastElement[0] !== this) {
                                return;
                            }

                            e.stopImmediatePropagation();
                            e.preventDefault();
                            firstElement.focus();
                        }
                    }));

                if (self.lastFocusedElement)
                    //console.log("set focus", self.lastFocusedElement.is(':nothidden'), self.lastFocusedElement.is(':visible'),
                    //    self.lastFocusedElement.is('button'), self.lastFocusedElement.hasClass('RayGrid'), self.lastFocusedElement);
                    
                    if (($(self.lastFocusedElement).is(':nothidden') && $(self.lastFocusedElement).is(':visible'))
                        || $(self.lastFocusedElement).is('button') || $(self.lastFocusedElement).hasClass('RayGrid')) {
                        self.lastFocusedElement.focus({ preventScroll: true });
                        //self.scrollTo(self.lastFocusedElement[0]);
                    }
                    else {
                        if (self.lastFocusedGrid.length !== 0 && $(self.lastFocusedGrid).is(':nothidden') && $(self.lastFocusedGrid).is(':visible')) {
                            //self.scrollTo(self.lastFocusedGrid[0]);
                        } else {
                            let nextElem = $(self.lastFocusedElement.next("[tabindex]:nothidden:visible:first"));
                            if (nextElem.length === 0) {
                                firstElement.focus({ preventScroll: true });
                                self.lastFocusedElement = firstElement;
                            } else {
                                nextElem.focus({ preventScroll: true });
                                self.lastFocusedElement = firstElement;
                            }
                        }
                    }

                //if (self.dto.formState.FocusedControl !== '' || self.dto.formState.FocusedControl !== null) {
                if (self.dto && self.dto.formState && self.dto.formState.FocusedControl) {
                    let element = $(`[serverid=${self.dto.formState.FocusedControl}]`);
                    if (element.is('button') || element.is('textarea') || element.is('input')) {
                        element.focus({ preventScroll: true });
                    }
                    else {
                        let input = element.find('input:first');
                        if (input.length === 0)
                            input = element.find('textarea:first');

                        input.focus({ preventScroll: true });
                    }

                    self.lastFocusedElement = element;
                }

                if (typeof window.scrollData !== "undefined") {
                    $(window).scrollTop(window.scrollData);
                    delete window["scrollData"];
                }
            }, 100);
        },

        scrollTo: function (element) {
            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth);

            //console.log('scrollTo', isInView, element);
            if (!isInView)
                element.scrollIntoView();
        },

        closeForm: function () {
            clearInterval(this.options.autoSaveHandler);
            if (this.options.nested) {
                try {
                    this.element.rayModal('close');
                    //this.element.dialog('destroy');
                } catch (e) {
                    console.error(e);
                }
                appForm().currentBpmsAppForm(this.options.parentBpmsAppForm);
            }
        },

        closeAllNestedForm: function () {

            if (this.options.nested) {
                this.element.rayModal('close');
                this.element.rayModal('destroy');
            }

            if (this.options.parentBpmsAppForm)
                this.options.parentBpmsAppForm.closeAllNestedForm();
            this.element.remove();

        },

        _getPossibleButtons: function () {
            const self = this;
            let method = `form-events/${this.dto.formState.PageInstanceId}`;
            // TODO: Add this code
            if (this.options.nested)
                method = `nested-form-events/${this.dto.formState.PageInstanceId}`;
            self._ajaxPostRequest.call(self, serviceUrl, method, null, false, "GET", "").done(function (result) {
                self._generateEvents(result, self.dto.formState.DisabledEvents);
            }).fail(function () {
                self.appFormButtons.html($('<div>').addClass("event-message alert alert-danger").text(i18n.$t("errors.events_cannot_be_loaded")));
            });
        },

        _setSaveButtonEvent: function (btn, callback) {
            const self = this;
            btn.click(function () {
                if (btn.attr('disabled') || btn.attr('disabled') == 'disabled')
                    return;
                self.element.find('.next-btn').attr('disabled', 'disabled');
                self.element.find('.before-btn').attr('disabled', 'disabled');
                self._saveChanges();
                self.saveForm().done(function (result) {
                    if (result === 'dontContinue') {
                        self._showInvalidMessage("");
                        callback(false);
                        return;
                    }

                    if (self.options.nested)
                        self.options.parentBpmsAppForm.getForm(self.options.nested.Id);

                    if (result === "Question") {
                    } else if (result !== 'Warning') {
                        if (self.options.nested.objstate === "New" || self.options.nested.objstate === "NewBaseInfo") {
                            btn.attr('disabled', 'disabled');
                        } else {
                            btn.removeAttr('disabled');
                        }
                        const addButton = $(`#new_${self.options.nested.Id}${self.options.nested.pageInsId}`);
                        if (addButton) {
                            addButton.removeAttr('disabled');
                        }
                        utils.message(i18n.$t("save_successfully"), 1);
                        btn.attr("isdirty", false);

                        if (typeof callback == "function")
                            callback(true);
                    }
                }).fail(function (a, b, c) {
                    utils.showAjaxError(a, b, c);
                });
            });
        },
        _addNestedButton: function () {
            const self = this;
            if (self.dto.formState.IsIFormObject === "true" || self.dto.formState.IsIFormObject === true) {
                self.options.nested.objstate = 'View';
            }
            let savebuttonTitle = i18n.$t("save_and_return");
            let cancelbuttonTitle = i18n.$t("cancel_and_return");
            if (self.options.nested && self.options.nested.savebuttonTitle)
                savebuttonTitle = self.options.nested.savebuttonTitle;
            if (self.options.nested && self.options.nested.cancelbuttonTitle)
                cancelbuttonTitle = self.options.nested.cancelbuttonTitle;
            if (self.options.nested && self.options.nested.objstate === "View") {
                cancelbuttonTitle = i18n.$t("return");
                savebuttonTitle = i18n.$t("return");
            }

            //save button
            if (!self.options.nested.invisibleSaveButton &&
                self.options.nested.objstate !== 'View') {

                const btn = $("<button class='save-and-back-btn save-btn no-print btn btn-primary'></button>")
                    .text(savebuttonTitle)
                    .attr("id", `save_${self.options.nested.Id}${self.options.nested.pageInsId}`)
                    .attr("isDirty", self.dto.formState.IsDirty !== null ? self.dto.formState.IsDirty : true)
                    .appendTo(self.appFormButtons.children('.btn-group:first'));


                if (self.dto.formState.SaveAndCloseButtonEnabled === false || self.options.nested.objstate === 'Select')
                    btn.hide();

                self._setSaveButtonEvent(btn, function (result) {
                    //console.log("SaveButtonEvent", self.options.nested, self.dto.formState.CloseModalOnApply, self.dto.formState.NavigationButtonsEnabled);

                    //if ((self.options.nested && result == true && self.options.nested.editorMode !== 'edit')
                    //    || (self.dto.formState.CloseModalOnApply === true && self.options.nested.editorMode === 'edit' && self.dto.formState.NavigationButtonsEnabled === false && result == true)) {
                    //self.closeForm();
                    if (result == true) {
                        self.element.rayModal('close');
                    }
                });
            }

            // escape button , top left
            $(this.closeModalButton).click(function () {
                self._toRestorDirtyObject(self.options.nested.objstate, self.options.nested.Id, self.options.nested.pageInsId).done(function () {
                    self.element.rayModal('close');

                });
            });

            //navigatebutton
            if (self.options.nested &&
                self.options.nested.gridEditor === true) {

                if (self.options.nested &&
                    self.options.nested.editorMode === "add" &&
                    self.dto.formState.SaveAndNewButtonEnabled !== false) {

                    const addbutton = $(`<button class='new-btn no-print btn btn-warning'>${i18n.$t("save_and_new")}</button>`)
                        .attr("id", `new_${self.options.nested.Id}${self.options.nested.pageInsId}`);

                    self._setSaveButtonEvent(addbutton, function (result) {
                        if (!result) return;
                        if (self.options.nested.objstate === "New" || self.options.nested.objstate === "NewBaseInfo") {
                            const saveButton = $(`#save_${self.options.nested.Id}${self.options.nested.pageInsId}`);
                            saveButton.removeAttr('disabled');
                        }
                        const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                        const map = self.getControlStateByTypeId(gridControlState.ControlType);
                        self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'goNewMode', self.element);
                    });

                    addbutton.appendTo(self.appFormButtons.children('.btn-group:first'));
                    //addbutton.attr('disabled', 'disabled');
                }

                if (self.options.nested &&
                    self.options.nested.editorMode === "edit" &&
                    self.options.nested.totalRowCount > 1 &&
                    self.dto.formState.NavigationButtonsEnabled === true) {
                    let navInProgress = false;
                    //------------- before
                    let beforebutton = $(`<button class='before-btn no-print btn btn-default'><i class='fad fa-chevron-circle-left'></i> ${i18n.$t("save_and_previous")}</button>`)
                        .attr("title", i18n.$t("prev_row"))
                        .attr("id", `before_${self.options.nested.Id}${self.options.nested.pageInsId}`);
                    self._setSaveButtonEvent(beforebutton, function (result) {
                        if (result == true) {
                            if (navInProgress)
                                return;
                            navInProgress = true;

                            const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                            const map = self.getControlStateByTypeId(gridControlState.ControlType);

                            const res = self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'canGoPrev', self.element);
                            if (!res) {
                                navInProgress = false;
                                return;
                            }
                            self._toRestorDirtyObject(self.options.nested.objstate, self.options.nested.Id, self.options.nested.pageInsId, false).done(function () {
                                self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'goPrevRow', self.element);
                            });
                        }
                    });

                    if (payload.currentLocale.IsRightToLeft)
                        beforebutton.insertAfter(self.appFormButtons.find('.save-btn:first'));
                    else
                        beforebutton.insertBefore(self.appFormButtons.find('.save-btn:first'));
                    //------------- next

                    const nextbutton = $(`<button class='next-btn no-print btn btn-primary'>${i18n.$t("save_and_next")} <i class='fad fa-chevron-circle-right'></i></button>`)
                        .attr("title", i18n.$t("next_row"))
                        .attr("id", `next_${self.options.nested.Id}${self.options.nested.pageInsId}`);
                    self._setSaveButtonEvent(nextbutton, function (result) {
                        if (result == true) {
                            if (navInProgress)
                                return;
                            navInProgress = true;

                            const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                            const map = self.getControlStateByTypeId(gridControlState.ControlType);

                            const res = self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'canGoNext', self.element);
                            if (!res) {
                                navInProgress = false;
                                return;
                            }

                            self._toRestorDirtyObject(self.options.nested.objstate, self.options.nested.Id, self.options.nested.pageInsId, false).done(function () {
                                const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                                const map = self.getControlStateByTypeId(gridControlState.ControlType);
                                self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'goNextRow', self.element);
                            });
                        }});
                    if (payload.currentLocale.IsRightToLeft)
                        nextbutton.insertBefore(self.appFormButtons.find('.save-btn:first'));
                    else
                        nextbutton.insertAfter(self.appFormButtons.find('.save-btn:first'));



                    const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                    const map = self.getControlStateByTypeId(gridControlState.ControlType);
                    //Check visibility of next/previous buttons
                    if (!self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'checkVisibilityOfPrevButton')) {
                        beforebutton.css('visibility', 'hidden');
                    }
                    if (!self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'checkVisibilityOfNextButton')) {
                        nextbutton.css('visibility', 'hidden');
                    } else {
                        //When next button is present, Save and Return btn shouldnt be primary, but default
                        self.element.find(".save-and-back-btn").removeClass('btn-primary');
                        self.element.find(".save-and-back-btn").addClass('btn-default');
                    }
                }
            }
        },

        _cancelAction: function (reload, close) {
            if (typeof close == "undefined")
                close = true;
            const self = this;
            self.cancelNestedForm(self.options.nested.Id).done(function (result) {
                if (self.options.nested.gridEditor && self.options.parentBpmsAppForm) {
                    const gridControlState = self._findControlState(self.options.parentBpmsAppForm.dto.formState.ControlStates, self.options.nested.gridId);
                    if (gridControlState) {
                        if (reload == null || reload === 'undefined')
                            reload = true;
                        const map = self.getControlStateByTypeId(gridControlState.ControlType);
                        self._invokeParentWidgetMember(`${map.clazz}[serverId=${gridControlState.Id}]`, map.control, 'cancelRow', self.element, reload, close);
                        self.options.parentBpmsAppForm.getForm(self.options.nested.Id);
                    }
                }
                if (self.options.nested && !self.options.nested.gridEditor) {
                    self.closeForm();
                }
            });
        },

        _saveChanges: function () {
            const self = this;
            const dialog = self._findTopMostDialog();
            if (dialog == null)
                return;
            const item = $(dialog).find(".ajaxControl:focus");
            item.change();
        },

        _clearDirtyState: function () {
            const self = this;
            const dialog = self._findTopMostDialog();
            if (dialog === null) return;
            const btnSave = $(dialog).find(".save-btn");
            if (btnSave && btnSave.length > 0)
                $(btnSave).attr("isdirty", false);
        },

        _setDirtyState: function () {
            const self = this;
            const dialog = self._findTopMostDialog();
            if (dialog === null) return;
            const btnSave = $(dialog).find(".save-btn");
            if (btnSave && btnSave.length > 0)
                $(btnSave).attr("isdirty", true);
        },

        _toRestorDirtyObject: function (objstate, nestedId, pageinstance, close) {
            if (typeof close == "undefined")
                close = true;

            const self = this;
            const $def = $.Deferred();
            $def.isRejected = false;
            const saveButton = $(`#save_${nestedId}${pageinstance}`);
            if (objstate !== "New" && objstate !== "NewBaseInfo") {
                const dialog = self._findTopMostDialog();
                if (dialog !== null) {
                    if ($(saveButton).attr("isdirty") === true || $(saveButton).attr("isdirty") === "true") {
                        saveButton.attr("isdirty", false);
                        self._cancelAction({ reload: false }, close);
                    }

                    const buttonIsVisible = $(saveButton).is(":visible");
                    const isDirty = ($(saveButton).attr("isdirty") === true || $(saveButton).attr("isdirty") === "true");

                    if (!isDirty && !buttonIsVisible)
                        self.options.parentBpmsAppForm.getForm(self.options.nested.Id);
                }
            } else if (objstate === "New" || objstate === "NewBaseInfo") {
                if ($(saveButton).attr("isdirty") === true || $(saveButton).attr("isdirty") === "true") {
                    //delete new object
                    saveButton.attr("isdirty", false);
                    self._cancelAction({
                        reload: false
                    }, close);
                }
            }
            if ($def.isRejected === false)
                $def.resolve();
            return $def.promise();
        },

        _addSubmitButton: function () {
            const self = this;
            const btn = $(`<button class='btn btn-primary'>${i18n.$t("submit_form")}</button>`)
                .bind("click keydown", function (e) {
                    if (!self._checkIfSaveIsAllowed()) return;
                    if (e.type.toLowerCase() !== "click" && e.keyCode !== 0 && e.keyCode && e.keyCode !== 32)
                        return;
                    const thisbtn = this;
                    thisbtn.disabled = true;
                    self.submit().always(function () {
                        setInterval(function () {
                            thisbtn.disabled = false;
                        }, 1500);
                    });
                })
                .appendTo(self.appFormButtons);

            self.options.saveBtnDefault = btn;

            this._addSaveButton();
        },

        _initTitlebar: function () {
            const self = this, dto = this.dto;
            if (this.element.is('#modalDialog')) {
                self.element.parents('.modal').find('.headerToolbar').remove();
            } else {
                self.element.parents('.panel').find('.headerToolbar').remove();
            }
            const toolbarEl = document.createElement('div');
            toolbarEl.classList.add('headerToolbar');

            if (payload.currentLocale.IsRightToLeft) {
                toolbarEl.classList.add('headerToolbarOnTheLeft');
            } else {
                toolbarEl.classList.add('headerToolbarOnTheRight');
            }

            toolbarEl.classList.add('formHelperButton');
            toolbarEl.classList.add('no-print');
            if (this.element.is('#modalDialog'))
                $('h4.modal-title').after(toolbarEl);
            else
                $('span.app-title').after(toolbarEl);

            this.toolbar = new Toolbar(toolbarEl, {
                items: [       
                    {
                        //titlebar-debugInfo
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            text: i18n.$t("debug_info"),
                            icon: 'fad fa-wrench',
                            onClick() {
                                var debugInfo = self.dto.formState.DebugInfo;
                                var debugInfoItemsHtml = "";
                                for (var key in debugInfo) {
                                    if (!debugInfo.hasOwnProperty(key)) continue;
                                    var obj = debugInfo[key];
                                    if (obj == null) continue;
                                    debugInfoItemsHtml += `<div><h5>${key}: <label>${obj}</label></h5>`
                                }
                                DevExpress.ui.dialog.custom({
                                    title: i18n.$t("debug_info"),
                                    messageHtml: `<div class="col-md-800">${debugInfoItemsHtml}</div>`
                                }).show();
                            },
                        },
                        visible: localStorage.dbpDebugMode === 'true' && this.dto.formState.DebugInfo != null
                    },

                    {
                        //titlebar-historyTree
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-history',
                            text: i18n.$t("process_tree"),
                            onClick() {
                                if (typeof self.options.showHistoryModalCallback !== "undefined")
                                    self.options.showHistoryModalCallback();
                            },
                        },
                        visible: !self.options.isStarterForm && !self.options.nested && self.dto.formState.IsTreeHistory === true
                    },
                    {
                        //titlebar-historyGraph
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-monitor-heart-rate',
                            text: i18n.$t("process_graph"),
                            onClick() {
                                var id = router.app.$route.params.id;
                                var domainObjectId = router.app.$route.query.dId;
                                var taskTypeId = router.app.$route.query.fid;
                                if (typeof id === "undefined")
                                    id = router.app.$route.query.tId;
                                processLog().activate({ id: id, domainObjectId: domainObjectId, taskTypeId: taskTypeId });
                            },
                        },
                        visible: !self.options.isStarterForm && !self.options.nested && self.dto.formState.IsGraphHistory === true
                    },
                    {
                        //titlebar-historyGraph2
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-project-diagram',
                            text: i18n.$t("process_history"),
                            onClick() {
                                var id = router.app.$route.params.id;
                                var domainObjectId = router.app.$route.query.dId;
                                var taskTypeId = router.app.$route.query.fid;
                                if (typeof id === "undefined")
                                    id = router.app.$route.query.tId;
                                processHistory().activate({ id: id, domainObjectId: domainObjectId, taskTypeId: taskTypeId });
                            },
                        },
                        visible: !self.options.isStarterForm && !self.options.nested && self.dto.formState.IsGraphHistory === true
                    },
                    {
                        //titlebar-save
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-save',
                            text: i18n.$t("save_form"),
                            onClick() {
                                // save without validations
                                self.saveFormNoValidation(true);
                            },
                        },
                        visible: dto.formState.CanSave && !dto.formState.IsNestedForm
                    },
                    {
                        //titlebar-print
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-print',
                            text: i18n.$t("print_form"),
                            onClick() {
                                window.print();
                            },
                        },
                        visible: dto.formState.NeedPrintButton
                    },
                    {
                        //titlebar-timesheet
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-calendar-alt',
                            text: i18n.$t("time_sheet"),
                            onClick() {
                                self._onClickTimeSheet();
                            },
                        },
                        visible: dto.formState.HasTimeSheet && config.getCurrentUserId()
                    },
                    {
                        //titlebar-taskassign
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-clipboard-user',
                            text: i18n.$t("assign"),
                            onClick() {
                                const params = {
                                    ids: [router.app.$route.params.id],
                                    folderId: router.app.$route.params.folderId
                                };
                                if (typeof self.options.showTaskAssignModalCallback !== "undefined")
                                    self.options.showTaskAssignModalCallback(params);
                            },
                        },
                        visible: !self.options.nested && !self.options.isStarterForm && self.dto.formState.IsAssignable
                    },
                    {
                        //titlebar-exportword 
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-file-word',
                            text: i18n.$t("word_output"),
                            onClick() {
                                if (self.options.nested && self.options.nested.editorMode == 'view')
                                    window.open(`api/file/getExported?isPDF=false&pageInstanceId=${self.dto.formState.PageInstanceId}`);
                                else
                                    self.updateForm().done(function () {
                                        setTimeout(function () {
                                            window.open(`api/file/getExported?isPDF=false&pageInstanceId=${self.dto.formState.PageInstanceId}`);
                                        }, 500);
                                    });
                            },
                        },
                        visible: dto.formState.HasWord && (dto.formState.ExportType == 0 || dto.formState.ExportType == 3)
                    },
                    {
                        //titlebar-exportpdf
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-file-pdf',
                            text: i18n.$t("pdf_output"),
                            onClick() {
                                if (self.options.nested && self.options.nested.editorMode == 'view')
                                    window.open(`api/file/getExported?isPDF=true&pageInstanceId=${self.dto.formState.PageInstanceId}`);
                                else
                                    self.updateForm().done(function () {
                                        window.open(`api/file/getExported?isPDF=true&pageInstanceId=${self.dto.formState.PageInstanceId}`);
                                    });
                            },
                        },
                        visible: dto.formState.HasWord && (dto.formState.ExportType == 1 || dto.formState.ExportType == 3)
                    },
                    {
                        //titlebar-previewpdf
                        locateInMenu: 'always',
                        widget: 'dxButton',
                        options: {
                            icon: 'fad fa-eye',
                            text: i18n.$t("pdf_preview"),
                            onClick() {
                                if (self.options.nested && self.options.nested.editorMode == 'view')
                                    window.open(`api/file/getExported?isPDF=true&isPreview=true&pageInstanceId=${self.dto.formState.PageInstanceId}`, "_blank");
                                else
                                    self.updateForm().done(function () {
                                        window.open(`api/file/getExported?isPDF=true&isPreview=true&pageInstanceId=${self.dto.formState.PageInstanceId}`, "_blank");
                                    });
                            },
                        },
                        visible: dto.formState.HasWord && (dto.formState.ExportType == 1 || dto.formState.ExportType == 3)
                    }
                ]
            });

            if (self.options.nested) {
                $('#lblTaskFormTitle', this.appFormTitlebar).text(self.options.nested.title);
                $('#TaskTitleDiv', this.appFormTitlebar).hide();
            } else {
                if (dto.taskSubject) {
                    if (dto.isStarterForm === true)
                        $('#lblTaskFormTitle', this.appFormTitlebar).text(dto.formTitle);
                    else
                        $('#lblTaskSubject', this.appFormTitlebar).text(dto.taskSubject);

                    $('#imgTaskState', this.appFormTitlebar).toggle(dto.formState.IsCompleted);
                } else {
                    $('#lblTaskFormTitle', this.appFormTitlebar).text(dto.formTitle);
                    $('#taskTitleDiv', this.appFormTitlebar).hide();
                }

                if (dto.securityTitle)
                    $(".securityTitle").text(dto.securityTitle);
            }

            if (dto.formState.CanSave && !dto.formState.IsNestedForm) {
                if (payload.autoSaveInterval > 0) {
                    self.options.autoSaveHandler = setInterval(function () {
                        if (self.saveAllowed) {
                            if (self.element.is(':visible')) {
                                self.saveFormNoValidation(false);
                            } else {
                                clearInterval(self.options.autoSaveHandler);
                            }
                        }
                    }, payload.autoSaveInterval * 60 * 1000);
                }
            }
        },

        _initControls: function () {
            const self = this;
            if (localStorage.dbpDebugMode === 'true')
                console.log(`Form '${self.dto.formTitle}' init controls`);
            const controlstates = this.dto.formState.ControlStates;
            this.validationDiv.empty().parent().hide();
            let start = new Date().getTime();
            let elapsed = new Date().getTime() - start;
            this.saveAllowed = true;
            // what to postpone
            this.postponed = [];
            for (let cs in controlstates) {
                const c = controlstates[cs];
                const ct = c.ControlType;
                if (ct == 17) {
                    //console.log("Found tab", c.Id, c.Behavior.LazyLoad, c.Behavior.SelectedTabIndex);
                    if (c.Behavior.LazyLoad)
                        for (let i = 0; i < c.Behavior.TabsChild.length; i++) {
                            //if (i === c.Behavior.SelectedTabIndex)
                            //    continue;
                            const tchild = c.Behavior.TabsChild[i].Value;
                            tchild.forEach(function (item) { self.postponed.push(item); });
                        }
                }
                if (ct == 45) {
                    if (c.Behavior.LazyLoad)
                        for (let i = 0; i < c.Behavior.SectionsChild.length; i++) {
                            //if (i === c.Behavior.SelectedTabIndex)
                            //    continue;
                            const tchild = c.Behavior.SectionsChild[i].Value;
                            tchild.forEach(function (item) { self.postponed.push(item); });
                        }
                }
            }

            //console.log("postponed controls", this.postponed);

            let makeReadOnly = self.options.nested && self.options.nested.editorMode == "view";

            const allVldtr = this.dto.formState.Validators;

            // Init tab controls and accordions first, sort them to load parents first and childs after (very important for later initialization)
            // Type 17 is tab control, 45 is accordion, 30 is tab section and 46 is an accordion sectiojn 
            var sortedTabAccControls = controlstates.filter(x => x.ControlType == 17 || x.ControlType == 45).sort((a, b) => {
                //If a or b is the first tabControl
                if (a.ParentId == null && b.ParentId == null) return 0;
                if (a.ParentId == null) return -1;
                if (b.ParentId == null) return 1;
                //If b is a child of a
                var aChildren = Array.from(controlstates.filter(x => (x.ControlType == 30 || x.ControlType == 46) && x.ParentId == a.Id), x => x.Id);
                if (aChildren.includes(b.ParentId)) return -1;
                //If a is a child of b
                var bChildren = Array.from(controlstates.filter(x => (x.ControlType == 30 || x.ControlType == 46) && x.ParentId == b.Id), x => x.Id);
                if (bChildren.includes(a.ParentId)) return 1;
                //If noone is a direct child, leave as it is
                return 0;
            });
            for (let cs in sortedTabAccControls) {
                const c = sortedTabAccControls[cs];
                if (this.postponed.indexOf(c.Id) > -1)
                    continue;
                const ct = c.ControlType;
                const map = this.getControlStateByTypeId(ct);
                if (makeReadOnly)
                    c.Behavior.Enabled = false;

                try {
                    //console.log("init control", c.Id, ct);
                    this._invokeWidgetMember(`${map.clazz}[serverId=${c.Id}]`, map.control, null, {
                        controlState: c,
                        bpmsAppForm: this,
                        rayControlChanged: $.proxy(this._onRayControlChanged, this)
                    });
                    self._setControlState(c);

                    this._invokeWidgetMember(`${map.clazz}[serverId=${c.Id}]`, map.control, 'clearValidators');
                    for (let i in allVldtr) {
                        if (allVldtr[i].ControlId == c.Id && this._isVisibleControl(allVldtr[i].ControlId))
                            this._setControlValidator(allVldtr[i]);
                    }
                } catch (ex) {
                    console.error("Error in tabs/accordion init", ex);
                }
            }

            // init all other controls
            for (let cs in controlstates) {
                const c = controlstates[cs];
                if (this.postponed.indexOf(c.Id) > -1 || c.ControlType == 17 || c.ControlType == 45)
                    continue;
                //console.log("postponed", c.Id);
                start = new Date().getTime();
                const ct = c.ControlType;
                const map = this.getControlStateByTypeId(ct);
                if (makeReadOnly)
                    c.Behavior.Enabled = false;

                try {
                    //console.log("init control", c.Id, ct);
                    this._invokeWidgetMember(`${map.clazz}[serverId=${c.Id}]`, map.control, null, {
                        controlState: c,
                        bpmsAppForm: this,
                        rayControlChanged: $.proxy(this._onRayControlChanged, this)
                    });
                    self._setControlState(c);

                    this._invokeWidgetMember(`${map.clazz}[serverId=${c.Id}]`, map.control, 'clearValidators');
                    for (let i in allVldtr) {
                        if (allVldtr[i].ControlId == c.Id && this._isVisibleControl(allVldtr[i].ControlId))
                            this._setControlValidator(allVldtr[i]);
                    }
                } catch (ex) {
                    console.error("Error in init contorls", ex);
                }

                if (localStorage.dbpDebugMode === 'true') {
                    elapsed = new Date().getTime() - start;
                    if (elapsed > 100)
                        if (localStorage.dbpDebugMode === 'true')
                            console.log(`Long initControls ${map.control} ${c.Id}: ${elapsed} ms`);
                }
            }
        },

        _initControl: function (id) {
            if (this.postponed.indexOf(id) < 0)
                return;
            let startC = new Date().getTime();

            const self = this;
            let cs = this.dto.formState.ControlStates.find(x => x.Id === id);
            //console.log("_initControl", id, cs);
            if (cs) {
                const ct = cs.ControlType;
                const map = self.getControlStateByTypeId(ct);
                try {
                    this._invokeWidgetMember(`${map.clazz}[serverId=${cs.Id}]`, map.control, null, {
                        controlState: cs,
                        bpmsAppForm: this,
                        rayControlChanged: $.proxy(this._onRayControlChanged, this)
                    });
                    self._setControlState(cs);
                    self.postponed = self.postponed.filter(e => e !== id);
                } catch (ex) {
                    console.error("Error in init contorls", ex);
                }
                if (localStorage.dbpDebugMode === 'true') {
                    let elapsedC = new Date().getTime() - startC;
                    if (elapsedC > 100)
                        if (localStorage.dbpDebugMode === 'true')
                            console.log(`Long initControl ${map.control} ${cs.Id}: ${elapsedC} ms`);
                }

            }
        },

        _changedItems: [],

        _onRayControlChanged: function (el, cs) {
            const self = this;

            if (self._changedItems.find(function (s) { return s.Id === cs.Id }) === undefined)
                self._changedItems.push(cs);

            if (cs.Behavior.AutoUpdate) {
                //console.log("Autopostback", cs, cs.Behavior.AutoUpdate);
                let serverId = $($(el)[0].target).attr('serverId');
                if (typeof serverId == 'undefined' || serverId == null || serverId == '')
                    serverId = $(el).attr('serverId');
                self.updateForm(serverId)
                    .fail(function (ex) {
                        console.error(ex);
                        self._changedItems = [];
                    }).done(function () {
                        self._changedItems = [];
                    });
                self._setDirtyState();
            }
        },

        _setControlsState: function (controlstates) {
            for (let cs in controlstates) {
                if (this.postponed && this.postponed.indexOf(controlstates[cs].Id) > -1)
                    continue;

                this._applyParentsBehavior(controlstates, controlstates[cs]);
            }

            //apply new behaviour
            for (let cs in controlstates) {
                if (this.postponed && this.postponed.indexOf(controlstates[cs].Id) > -1)
                    continue;

                this._setControlState(controlstates[cs]);
            }

            //fix display after rendered
            setTimeout(function () {
                $(window).trigger('resize');
            }, 100);
        },

        _setControlState: function (cs) {
            try {
                const map = this.getControlStateByTypeId(cs.ControlType);
                this._invokeWidgetMember(`${map.clazz}[serverId=${cs.Id}]`, map.control, 'option', 'controlState', cs);
                this._invokeWidgetMember(`${map.clazz}[serverId=${cs.Id}]`, map.control, 'setState', cs);
            }
            catch (ex) {
                console.error("Error in setControlState", ex);
            }
        },

        _isParentsEnabled: function (controlstates, cs) {
            if (cs == null) return true;
            if (!cs.ParentId)
                return cs.Behavior.Enabled;
            return cs.Behavior.Enabled && this._isParentsEnabled(controlstates, this._findControlState(controlstates, cs.ParentId));
        },

        _isParentsVisible: function (controlstates, cs) {
            if (cs == null)
                return true;
            if (!cs.ParentId)
                return cs.Behavior.Visible;
            return cs.Behavior.Visible && this._isParentsVisible(controlstates, this._findControlState(controlstates, cs.ParentId));
        },

        _applyParentsBehavior: function (controlstates, cs) {
            cs.Behavior.Enabled = this._isParentsEnabled(controlstates, cs);
            cs.Behavior.Visible = this._isParentsVisible(controlstates, cs);
        },

        _findControlState: function (controlstates, id) {
            for (let cs in controlstates) {
                if (id == controlstates[cs].Id)
                    return controlstates[cs];
            }
        },

        _invokeWidgetMember: function (selector, widgetName, member, arg1, arg2, returnWhenNotInitString) {
            try {
                var isW = $(selector, this.element).is(":data('ui-" + widgetName + "')");
                //console.log("_invokeWidgetMember", selector, $(selector, this.element).length, widgetName, isW, member, arg1, arg2);
                //$(selector, this.element)[widgetName]);
                if (member)
                    if (arg2)
                        return $(selector, this.element)[widgetName](member, arg1, arg2);
                    else
                        return $(selector, this.element)[widgetName](member, arg1);
                else
                    return $(selector, this.element)[widgetName](arg1);
            } catch (ex) {
                var h = $(selector, this.element).length;
                if (returnWhenNotInitString && !isW) return returnWhenNotInitString;
                //console.error(`${selector} ${widgetName} ${h} ${isW}`, ex);
            }
            return null;
        },

        _invokeParentWidgetMember: function (selector, widgetName, member, arg1, arg2, args3) {
            try {
                if (member)
                    if (args3)
                        return $(selector, this.options.parentBpmsAppForm.element)[widgetName](member, arg1, arg2, args3);
                    else if (arg2)
                        return $(selector, this.options.parentBpmsAppForm.element)[widgetName](member, arg1, arg2);
                    else
                        return $(selector, this.options.parentBpmsAppForm.element)[widgetName](member, arg1);
                else
                    return $(selector, this.options.parentBpmsAppForm.element)[widgetName](arg1);
            } catch (ex) {
                console.error(ex, `${selector} ${widgetName}`);
            }
            return null;
        },

        getForm: function (sourceControlId) {
            return this._postData({
                sourceControlId: sourceControlId,
                pageInstanceId: this.dto.formState.PageInstanceId,
                inputFormData: null,
                eventType: 'AutoUpdate',
                applierPrincipalId: this.applierPrincipalId
            }, "form", true, "");
        },

        saveFormObject: function () {
            return this._ajaxPostRequest.call(this, serviceUrl, `form/${this.dto.formState.PageInstanceId}`, this._getFormData(), true, "PUT", "");
        },

        saveForm: function (showMsg) {
            const self = this;
            const method = self.options.nested ? 'SaveNested' : 'Save';
            const returnPromise = this._postData(this._createPostData(this.options.nested ? this.options.nested.Id : null, method), "form");
            returnPromise.done(function (status) {
                if (showMsg) {
                    if (status !== 'dontContinue')
                        utils.message(i18n.$t("messages.save_successfully"), 1);
                    else
                        utils.message(i18n.$t('errors.task_not_found'), 3);
                }

            });
            return returnPromise;
        },

        // method for silently saving data without validation, custom scripts, parsing new data from BE etc.
        saveFormNoValidation: function (showMsg) {
            if (!this._checkIfSaveIsAllowed()) return;
            this.saveAllowed = false;
            var self = this;
            var data = this._createPostData("", "Save", false);
            if (localStorage.dbpDebugMode === 'true')
                console.log("saveFormNoValidation", data);
            data.DoNotInitControls = true;
            this._postData(data, "form", true, null, true)
                .done(function (status) {
                    if (showMsg)
                    {
                        if (status !== 'dontContinue')
                            utils.message(i18n.$t("messages.save_successfully"), 1);
                        else
                            utils.message(i18n.$t('errors.task_not_found'), 3);
                    }
                    self.saveAllowed = true;
                });
        },

        deleteNestedObject: function (nestedControlId) {
            return this._postData(this._createPostData(nestedControlId, 'DeleteNestedObject'), 'form', true);
        },

        cancelNestedForm: function (nestedControlId) {
            return this._ajaxPostRequest.call(self, serviceUrl, `nestedform/${nestedControlId}`, null, true, "Delete", `pageInstanceId=${this.dto.formState.PageInstanceId}`);
        },

        submit: function () {
            if (!this._checkIfSaveIsAllowed()) return;
            this.saveAllowed = false;
            return this._postData(this._createSubmitData(), "form");
        },

        confirmAction: function (eventId) {
            return this._ajaxPostRequest.call(this, serviceUrl, 'confirm-action', null, false, "PUT", {
                pageInstanceId: this.dto.formState.PageInstanceId, eventId: eventId
            });
        },

        close: function (doUpdateParent) {
            if (!this.options.nested)
                return;

            if (doUpdateParent)
                this.options.parentBpmsAppForm.getForm(this.options.nested.Id);
            this.element.rayModal('close');
            //this.element.dialog('destroy');
        },

        updateForm: function (sourceControlId, skipValidation, sync) {
            if (!sourceControlId)
                sourceControlId = "";
            return this._postData(this._createPostData(sourceControlId, "AutoUpdate"), "form", true, sync);
        },

        setFilter: function (sourceControlId) {
            const self = this;

            const qs = {
                pageInstanceId: this.dto.formState.PageInstanceId,
                sourceControlId: sourceControlId
            };

            this._ajaxPostRequest.call(self, serviceUrl, "set-filter", this._getFormData(false), false, "PUT", qs).done(function (result) {
                const cs = result;
                self.resetGridPage = true;
                self._setControlsState(cs);
            });
        },
        resetFilter: function (sourceControlId) {
            const self = this;

            const qs = {
                pageInstanceId: this.dto.formState.PageInstanceId,
                sourceControlId: sourceControlId
            };

            this._ajaxPostRequest.call(self, serviceUrl, "reset-filter", null, false, "DELETE", qs).done(function (result) {
                const cs = result;
                self.resetGridPage = true;
                self._setControlsState(cs);
            });
        },
        _callFormEvent: function (eventId, signatureRequired) {
            return this._postData(this._createPostData(eventId, "EventCall"), "form", false, null, false, signatureRequired)
                .done(function () { })
                .promise();
            // TODO: fail must added
            //.fail(ajaxError);
        },

        _createPostData: function (sourceControlId, eventType, isFilter) {
            if (isFilter == null)
                isFilter = true;

            return {
                sourceControlId: sourceControlId,
                pageInstanceId: this.dto.formState.PageInstanceId,
                inputFormData: this._getFormData(isFilter),
                eventType: eventType,
                applierPrincipalId: this.applierPrincipalId
            };
        },

        _getChangedControlsValue: function (isFiltred) {
            const def = $.Deferred();
            def.isRejected = false;

            let controlstates = this._changedItems;

            if (!isFiltred)
                controlstates = this.dto.formState.ControlStates;

            const ignoreControls = ['rayLabel', 'rayNestedLink', 'raySearchPanel', 'rayButton', 'rayTabControl', 'rayChart', 'rayTree', 'rayGanttChart',
                'rayMap', 'rayRectangle', 'rayTabPage', 'RayClientChart', 'rayHelp', 'rayAdvancedGantt', 'rayRow', 'rayLine', 'rayColumn', 'accordion', 'accordionSection'];

            for (let cs in controlstates) {
                const ct = controlstates[cs].ControlType;
                const map = this.getControlStateByTypeId(ct);
                if (ignoreControls.includes(map.control))
                    continue;
                controlstates[cs].Value = this._invokeWidgetMember(`${map.clazz}[serverId=${controlstates[cs].Id}]`, map.control, 'getValue');
                if (((controlstates[cs].Value != null) && (controlstates[cs].Value instanceof jQuery))) {
                    const id = controlstates[cs].Id;
                    def.reject(`_getControlsValue failed,${id}.Value equals object[ ]`);
                    def.isRejected = true;
                    break;
                }
            }
            if (!def.isRejected)
                def.resolve(controlstates);
            return def.promise();
        },

        _createSubmitData: function () {
            return this._createPostData("", "Submit");
        },

        _getFormData: function (isFilter) {
            const self = this;
            if (isFilter == null)
                isFilter = true;

            const ignoreControls = ['rayLabel', 'rayNestedLink', 'raySearchPanel', 'rayButton', 'rayTabControl', 'rayChart', 'rayTree', 'rayGanttChart',
                'rayMap', 'rayRectangle', 'rayTabPage', 'RayClientChart', 'rayHelp', 'rayAdvancedGantt', 'rayRow', 'rayLine', 'rayColumn', 'accordion', 'accordionSection'];

            const formData = [];
            this._getChangedControlsValue(isFilter)
                .done(function (states) {
                    for (let i in states) {
                        const ct = states[i].ControlType;
                        const map = self.getControlStateByTypeId(ct);
                        if (ignoreControls.includes(map.control))
                            continue;

                        formData.push({
                            Id: states[i].Id,
                            Value: states[i].Value
                        });
                    }
                })
                .fail(function (ex) {
                    console.error(ex);
                });
            return formData;
        },

        _redirectToTasksPage: function () {
            const possibleRoutes = ["home", "home-menu", "tasksGrid"];
            const isPlugin = window.location.toString().toLowerCase().indexOf('co=t') >= 0;
            if (!isPlugin && (router.from.name != null && $.inArray(router.from.name, possibleRoutes) > 0)) {
                //console.log("go to previous", router.app);
                router.go(-1);
            }
            else {
                if (!isPlugin) {
                    //console.log("go to task list", router.from, router.app.$route.params);
                    router.push({ path: router.from.path });
                }
                else {
                    if (typeof router.app.$route.params.folderId !== 'undefined') {
                        //console.log("appform, navigate to task", router.app.$route.params);
                        router.push({
                            name: 'tasksGrid',
                            params: {
                                folderId: router.app.$route.params.folderId,
                                folderName: router.app.$route.params.folderName,
                                isDraft: router.app.$route.params.isDraft
                            }
                        });
                        return;
                    }
                    utils.callWebAPI("api/tasks/getfirst", { isCheckStaffs: true }, (data) => {
                        if (data)
                            router.push({
                                name: 'tasksGrid',
                                params: {
                                    folderId: data.Id,
                                    folderName: `${data.Text} - ${data.Text}`
                                }
                            });
                    });
                    // var staffs = payload.treeItems[0].ChildItems.filter(function (t) {
                    //     return t.ItemType === 2
                    // })
                    // if (staffs.length !== 0 && payload.ExpandStaffTree) {
                    //     router.push({
                    //         name: 'tasksGrid',
                    //         params: {
                    //             folderId: staffs[0].ChildItems[0].Id,
                    //             folderName: staffs[0].Text + " - " + staffs[0].ChildItems[0].Text
                    //         }
                    //     });
                    //     return;
                    // }
                    // router.push({
                    //     name: 'tasksGrid',
                    //     params: {
                    //         folderId: payload.treeItems[0].ChildItems[0].Id,
                    //         folderName: payload.treeItems[0].ChildItems[0].Text
                    //     }
                    // });
                }
            }

            window.top.postMessage("formSubmitted", "*");
        },


        /* Form Validation */
        _clearControlsValidators: function () {
            const controlStates = this.dto.formState.ControlStates;
            for (let cs in controlStates) {
                if (this.postponed && this.postponed.indexOf(controlStates[cs].Id) > -1)
                    continue;
                this._clearControlValidators(controlStates[cs]);
            }
        },

        _clearControlValidators: function (cs) {
            try {
                const map = this.getControlStateByTypeId(cs.ControlType);
                this._invokeWidgetMember(`${map.clazz}[serverId=${cs.Id}]`, map.control, 'clearValidators');
            } catch (ex) {
                console.error("Error in _clearControlValidators", ex);
            }
        },

        _setControlsValidators: function () {
            this._clearControlsValidators();
            const allVldtr = this.dto.formState.Validators;
            for (let i in allVldtr) {
                if (this.postponed && this.postponed.indexOf(allVldtr[i].ControlId) > -1)
                    continue;
                if (this._isVisibleControl(allVldtr[i].ControlId))
                    this._setControlValidator(allVldtr[i]);
            }
        },

        _setControlValidators: function (id) {
            const allVldtr = this.dto.formState.Validators;
            for (let i in allVldtr) {
                if (allVldtr[i].ControlId == id && this._isVisibleControl(allVldtr[i].ControlId))
                    this._setControlValidator(allVldtr[i]);
            }
        },

        _isVisibleControl: function (cId) {
            const ctrlstates = this.dto.formState.ControlStates;
            for (let i in ctrlstates)
                if (ctrlstates[i].Id == cId && ctrlstates[i].Behavior.Visible)
                    return true;
            return false;
        },

        _setControlValidator: function (validator) {
            try {
                let map = this.getControlStateByClazz('.' + validator.ControlTypeString);
                if (map)
                    this._invokeWidgetMember(`${map.clazz}[serverId=${validator.ControlId}]`,
                        map.control, 'setValidator', validator);
            }
            catch (ex) {
                console.error("Error in _setControlValidator", ex);
            }
        },

        _isValidateForm: function () {
            const controlstates = this.dto.formState.ControlStates;
            const result = {
                isValid: true,
                messages: []
            };

            for (let cs in controlstates) {
                const cso = controlstates[cs];
                const ct = cso.ControlType;
                const map = this.getControlStateByTypeId(ct);
                if (map.control === "rayTabControl" || map.control === "accordion") {
                    this._invokeWidgetMember(`#appform-content[pageinstanceid=${this.dto.formState.PageInstanceId}]  ${map.clazz}[serverId=${cso.Id}]`, map.control, 'expandLazy');
                }
            }
            //Init all postponed elements to be able to run the validation
            if (this.postponed) {
                for (let ppId in this.postponed) {
                    try {
                        const cso = controlstates.find(x => x.Id == this.postponed[ppId]);
                        const ct = cso.ControlType;
                        const map = this.getControlStateByTypeId(ct);
                        this._invokeWidgetMember(`${map.clazz}[serverId=${cso.Id}]`, map.control, null, {
                            controlState: cso,
                            bpmsAppForm: this,
                            rayControlChanged: $.proxy(this._onRayControlChanged, this)
                        });
                    }
                    catch (ex) {
                        console.error("Error in _isValidateForm", ex);
                    }
                }
            }

            for (let cs in controlstates) {
                const cso = controlstates[cs];
                const ct = cso.ControlType;
                const map = this.getControlStateByTypeId(ct);
                try {
                    const elemQuery = `#appform-content[pageinstanceid=${this.dto.formState.PageInstanceId}]  ${map.clazz}[serverId=${cso.Id}]`;
                    // only check elements if they are visible to the user
                    if (!cso.Behavior.Visible || this._parentIsInvisible(cso)) continue;

                    var returnWhenNotInitString = "NotInitialized";
                    const controlResult = this._invokeWidgetMember(elemQuery, map.control, 'isValidate', null, null, returnWhenNotInitString);
                    if (controlResult === returnWhenNotInitString) {
                        for (var key in this.dto.formState.Validators) {
                            if (!this.dto.formState.Validators.hasOwnProperty(key)) continue;
                            var validator = this.dto.formState.Validators[key];
                            if (validator == null) continue;

                            if (cso.Id === validator.ControlId && !cso.Value) {
                                result.isValid = false;
                                result.messages.push.apply(result.messages, [{ message: validator.Text, controlId: cso.Id, controlTypeString: map.control }]);
                            }
                        }
                        continue;
                    }
                    if (!controlResult || controlResult.length === 0)
                        console.error("controlNotFound", cso.Id);
                    if (!controlResult.isValid && controlResult.length !== 0) {
                        result.isValid = false;
                        result.messages.push.apply(result.messages, controlResult.messages);

                        ////Filter to show only unique messages
                        //var uniqueMessages = [];
                        //$.each(result.messages, function (i, el) {
                        //    if (!uniqueMessages.find((element) => element.controlId == el.controlId && element.type == el.type)) uniqueMessages.push(el);
                        //});
                        //result.messages = uniqueMessages;
                    }
                }
                catch (ex) {
                    console.error("Error in _isValidateForm", ex);
                }
            }
            return result;
        },

        _parentIsInvisible: function (obj) {
            var currentItem = obj;
            while (currentItem != null && currentItem.ParentId != null) {
                var parent = this.dto.formState.ControlStates.find(item => item.Id === currentItem.ParentId);
                currentItem = parent;
                if (!currentItem.Behavior.Visible) return true;
            }
            return false;
        },

        _isValidateControl: function (serverId) {
            const controlstates = this.dto.formState.ControlStates;
            for (let cs in controlstates) {
                if (controlstates[cs].Id !== serverId)
                    continue;

                const ct = controlstates[cs].ControlType;
                const map = this.getControlStateByTypeId(ct);
                try {
                    return this._invokeWidgetMember(`#appform-content[pageinstanceid=${this.dto.formState.PageInstanceId}]  ${map.clazz}[serverId=${controlstates[cs].Id}]`, map.control, 'isValidate');
                }
                catch (ex) {
                    console.error("Error in _isValidateControl", ex);
                }
            }
            // return ???
            return {
                isValid: true,
                message: []
            };
        },

        _showInvalidMessage: function (objects) {
            //console.log("_showInvalidMessage", messages);
            const $ul = this.validationDiv;
            $ul.empty();
            for (let i in objects) {
                var obj = objects[i];
                const $li = $(`<li><a onclick="dealWithErrorLink(event, '${obj.controlId}', '${obj.controlTypeString}');" href="#">${obj.message}</a></li>`);
                $ul.append($li);
            }
            if (objects.length > 0)
                $ul.parent().show();
            else
                $ul.parent().hide();
        },

        _destroy: function () {
        },

        _getEventListenersCount: function (obj, eventName) {
            let i = 0;
            if (obj != null)
                for (let e in obj.data('events'))
                    if (!eventName || eventName == e)
                        i++;
            return i;
        },


        _findBpmAppControl: function (id) {
            return $(`.ajaxControl[serverId=${id}]`, this.element);
        },

        _setOption: function (key, value) {
            this._super(key, value);
        },

        // params, r,d = response
        _handleResponse: function (result) {
            const self = this,
                def = $.Deferred(),
                cs = result.ControlStates;
            this.dto.formState = result;

            const msgs = this._parseMessages(this.dto.formState.Infos);

            //console.log("Handle form response", msgs.messageLevel, msgs);
            if (msgs.messageLevel === 'Critical') {
                utils.showGenericModalWithContainer('', msgs.messageList);
                def.resolve('dontContinue');
                // then close All nested Form & Redirect
                this.closeAllNestedForm();
                this._redirectToTasksPage();
            }

            else if (msgs.messageLevel === 'Question') {
                const innerFunc = function (answer) {
                    return utils.callRest('api/question-asnwer', 'PUT', null, false, { pageInstanceId: self.dto.formState.PageInstanceId, answer: answer });
                };

                const innerFunc2 = function (result) {
                    let callback = function () { };
                    let btn = null;

                    if (self.options.lastNestedButton === 0) {
                        callback = self.saveAndBackCallBack;
                        btn = self.options.saveAndBackButton;
                    }

                    if (self.options.lastNestedButton === 1) {
                        callback = self.saveAndNewCallBack;
                        btn = self.options.saveAndNewButton;
                    }

                    if (result === 'dontContinue') {
                        self._showInvalidMessage("");
                        $.proxy(callback, self)(false);
                        return;
                    }

                    self.options.parentBpmsAppForm.getForm(self.options.nested.Id);

                    if (result == "Question")
                        btn.attr('disabled', 'disabled');
                    else if (result !== 'Warning') {
                        if (self.options.nested.objstate === "New" || self.options.nested.objstate === "NewBaseInfo") {
                            btn.attr('disabled', 'disabled');
                        } else
                            btn.removeAttr('disabled');

                        const addButton = $(`#new_${self.options.nested.Id}${self.options.nested.pageInsId}`);
                        if (addButton)
                            addButton.removeAttr('disabled');

                        utils.showMessage(i18n.$t("save_successfully"), 2000);
                        btn.attr("isdirty", false);

                        if (typeof callback == "function")
                            $.proxy(callback, self)(true);
                    }
                };

                utils.confirm(msgs.messageList[0].outerHTML, {
                    yes: function () {
                        innerFunc(true)
                            .done(function () {
                                if (self.options.saveBtnDefault != null)
                                    self.options.saveBtnDefault.removeAttr("disabled");
                                const returnPromise = self._postData.apply(self, self.options.lastSubmitData);
                                returnPromise.done(function (status) {
                                    //innerFunc2(status);
                                });
                            })
                            .fail(function () { });
                    },
                    no: function () {
                        innerFunc(false)
                            .done(function () {
                                if (self.options.saveBtnDefault != null)
                                    self.options.saveBtnDefault.removeAttr("disabled");
                                const returnPromise = self._postData.apply(self, self.options.lastSubmitData);
                                returnPromise.done(function (status) {
                                    //innerFunc2(status);
                                });
                            })
                            .fail(function () { });
                    }
                });
            }

            else if (msgs.messageLevel === 'Error') {
                utils.showGenericModalWithContainer('', msgs.messageList);
                def.resolve('dontContinue');
                //self._setControlsValidators();
                //self._setControlsState(cs);
                if (result.ForcePostBack === true) {
                    self._changedItems = [];
                    self.updateForm();
                }
            }

            else if (msgs.messageLevel === 'Warning') {
                utils.confirm(msgs.messageList[0].outerHTML, {
                    yes: function () {
                        //...
                        self.confirmAction(result.Infos[0].EventId).done(function () {
                            utils.showMessage(i18n.$t("messages.your_request_has_been_sent"));
                            // if nested???
                            //noty({
                            //    text: i18n.$t("messages.your_request_has_been_sent"), type: 'success', layout: 'center', timeout: 4000
                            //});
                            if (self.options.nested)
                                self.closeForm();
                            def.resolve('Warning');
                        })
                            .fail(function (a, b, c) {
                                def.resolve('');
                                utils.showAjaxError(a, b, c);
                                // self.redire3ct

                            }).always(function () {
                                if (self.options.nested) {
                                    if (!self.options.nested.gridEditor) {
                                        self.element.rayModal('close');
                                    }
                                } else
                                    self._redirectToTasksPage();
                            });
                    },
                    no: function () {
                        if (result.IsNestedForm) {
                            self.cancelNestedForm(result.NestedControlId);
                        }
                        def.resolve('Warning');
                        self._setControlsValidators();
                        self._setControlsState(cs);
                    }
                });
            }

            else if (msgs.messageLevel === 'Message') {
                const params = {};
                params[i18n.$t('ok')] = function () {
                    $(msgs.messageList).rayModal('close');
                }
                utils.showGenericModalWithContainer(msgs.title, msgs.messageList, null, params);

                if (self.dto.formState.RedirectUrl) {
                    self._redirectToTasksPage();
                } else {
                    self._setControlsValidators();
                    self._setControlsState(cs);
                }
                def.resolve();
            }

            else if (msgs.messageLevel === 'Info') {
                let timeout = 5000;
                if (this.options.isStarterForm)
                    timeout = null;

                if (msgs.messageList && msgs.messageList.length > 0) {
                    noty({
                        text: msgs.messageList, type: 'success', layout: 'top', closeWith: ['button'], timeout: timeout
                    });
                    if (!self.options.nested)
                        self._redirectToTasksPage();
                    def.resolve('');
                } else {
                    // TODO:
                    if (self.dto.formState.RedirectUrl) {
                        self._redirectToTasksPage();
                    } else {
                        self._setControlsValidators();
                        self._setControlsState(cs);
                    }
                    def.resolve();
                }
            }

            else if (msgs.messageLevel === 'Load') {
                let timeout = 5000;
                if (this.options.isStarterForm)
                    timeout = null;

                if (msgs.messageList && msgs.messageList.length > 0) {
                    noty({
                        text: msgs.messageList, type: 'success', layout: 'top', closeWith: ['button'], timeout: timeout
                    });
                    if (!self.options.nested)
                        self._redirectToTasksPage();
                    def.resolve('');
                } else {
                    // TODO:
                    if (self.dto.formState.RedirectUrl) {
                        self._redirectToTasksPage();
                    } else {
                        self._setControlsValidators();
                        self._setControlsState(cs);
                    }
                    def.resolve();
                }
            }
            if (result.IsDirty === false) {
                self._clearDirtyState();
            }

            if (this.dto.formState.DisabledEvents && self.appFormButtons) {
                //console.log("DE responce", this.dto.formState.DisabledEvents, msgs);
                self.appFormButtons.find('.eventbutton').show();
                this.dto.formState.DisabledEvents.forEach(function (item) {
                    self.appFormButtons.find(`.eventbutton#${item}`).hide();
                });
            }
            return def.promise();
        },


        _parseMessages: function (msgs) {
            let msg;
            let messageList;
            let hasWarning = false;
            let hasError = false;
            let hasCritical = false;
            let hasInfo = false;
            let hasMessage = false;
            let hasQuestion = false;
            let messagesLevel = 'Load';

            for (let i in msgs) {
                msg = msgs[i];
                switch (msg.TypeStr) {
                    case 'Critical': hasCritical = true;
                        break;
                    case 'Error': hasError = true;
                        break;
                    case 'Warning': hasWarning = true;
                        break;
                    case 'Info': hasInfo = true;
                        break;
                    case 'Message': hasMessage = true;
                        break;
                    case 'Question': hasQuestion = true;
                        break;
                }
                if (!messageList)
                    messageList = $('<ul/>').addClass('bpmsInfo');
                if (msgs.length > 1 || !hasMessage)
                    messageList.append($(`<li class="${msg.TypeStr}" />`).append(msg.Title ? `<h2>${msg.Title}</h2>` : '').append(msg.Message));
                //messageList.append($('<li class="' + msg.TypeStr + '" />').append("<h2>" + msg.Message + "</h2>"));
                else {
                    messageList = $("<div/>");
                    messageList.html(msg.Message);
                }
            }
            let messageTitle = '';
            if (msgs && msgs.length === 1)
                messageTitle = msgs[0].Title;
            if (hasCritical)
                messagesLevel = 'Critical'; // Redirect
            else if (hasQuestion)
                messagesLevel = 'Question';
            else if (hasError)
                messagesLevel = 'Error';
            else if (hasWarning)
                messagesLevel = 'Warning';  // confirm
            else if (hasMessage)
                messagesLevel = 'Message'; // modal message
            return {
                messageLevel: messagesLevel,
                messageList: messageList,
                title: messageTitle
            };
        },

        _fakeSubmit: function () {
            const currentDialog = this._findTopMostDialog();
            if (currentDialog === null)
                $(".fakeForm:first").submit();
            else
                $(currentDialog).find(".fakeForm:first").submit();
        },

        _postData: function (postData, method, skipValidation, sync, skipInit, signatureRequired) {

            if (postData.applierPrincipalId === null)
                postData.applierPrincipalId = "";
            const qs = "";
            //if (method === 'form') {
            //qs = "pageInstanceId=" + postData.pageInstanceId
            //    + "&sourceControlId=" + postData.sourceControlId
            //    + "&eventType=" + postData.eventType
            //    + "&applierPrincipalId=" + postData.applierPrincipalId
            //    + "&inputFormData=" + postData.inputFormData;
            //}
            //this._fakeSubmit();
            this.options.lastSubmitData = [postData, method, skipValidation, sync];

            const self = this;
            const def = $.Deferred();
            let validationResult = {};
            const dontShowMsgInfos = method === 'AutoUpdate';
            const params = {
                method: method,
                dontShowMsgInfos: dontShowMsgInfos
            };


            if (!skipValidation)
                validationResult = this._isValidateForm();

            // if want to only update DomainObject without custom scripts, reinit controls (used for tasks save/autosave)
            if (skipInit) {
                self._ajaxPostRequest.call(self, serviceUrl, method, postData, sync, "POST", qs)
                    .done(function (res) { def.resolve(res); })
                    .fail(function (a, b, c) { utils.showAjaxError(a, b, c); });
            }
            else {
                if (skipValidation || validationResult.isValid) {
                    //utils.showProgress();
                    self._showInvalidMessage([]);
                    self.options.showLoading(true);
                    const postDataPostback = function () {
                        self.options.showLoading(true);
                        let startPost = new Date().getTime();
                        if (postData.eventType === 'AutoUpdate')
                            self.element.find(".save-and-back-btn").attr('disabled', 'disabled');
                        self._ajaxPostRequest.call(self, serviceUrl, method, postData, sync, "POST", qs).done(function (result) {
                            self._handleResponse(result).done(function (res) {
                                def.resolve(res);
                            }).fail(function (res) {
                                console.error('fail in postData', res);
                            });
                        }).fail(function (a, b, c) {
                            utils.showAjaxError(a, b, c);
                            def.reject(b);
                        }).always(function () {
                            //utils.hideProgress();
                            self.options.showLoading(false);
                            self.saveAllowed = true;
                            utils.fixHeight();
                            self._fixTabIndex();
                            if (localStorage.dbpDebugMode === 'true') {
                                let elapsed = new Date().getTime() - startPost;
                                if (localStorage.dbpDebugMode === 'true')
                                    console.log(`Form '${self.dto.formTitle}' post-back: ${elapsed} ms`);
                            }
                            self.element.find(".save-and-back-btn").removeAttr('disabled');
                        });
                    };
                    //
                    // Manage Secret Code (Signature Required)
                    //
                    if (signatureRequired) {
                        self._sendSecretCode(config.getCurrentUserId(), self.dto.formState.PageInstanceId);
                        let secretCode = null;
                        //
                        // Show popup to ask secret code
                        //
                        DevExpress.ui.dialog.custom({
                            title: i18n.$t("secret_code.title"),
                            messageHtml:
                                `<div class="col-md-400">
                                        <div>
                                            <h5>
                                                <label>` + i18n.$t("secret_code.message") + `</label>
                                            </h5>
                                        </div>
                                        <div align="center">
                                            <h4>
                                                <label for="secret_code">` + i18n.$t("secret_code.code") + `</label><input style="padding-left:5px; text-align:center;" id="secret-code" class="passz" type="text"/>
                                            </h4>
                                        </div>
                                        <div>
                                            <small><dfn>
                                                <label>` + i18n.$t("secret_code.legal_sentence") + `</label>
                                            </dfn></small>
                                        </div>
                                        <div style="text-align: center; margin-top: 20px;">
                                            <button id="submit-button" class="btn btn-primary">` + i18n.$t("secret_code.submit") + `</button>
                                            <button id="cancel-button" class="btn btn-secondary">` + i18n.$t("secret_code.cancel") + `</button>
                                        </div>
                                    </div>`,
                            buttons: [                             
                                {
                                    text: i18n.$t("cancel"),
                                    onClick: function () {
                                        return { result: false };
                                    },
                                    visible:false
                                }
                            ],
                            
                            popupOptions: {
                                onShown: function (e) {
                                    $("#secret-code").focus();
                                    $("#submit-button").on("click", function () {
                                        const secretCode = $("#secret-code").val();
                                        if (secretCode) {
                                            utils.callWebAPI('api/form/checksecretcode', { userId: config.getCurrentUserId(), pageInstanceId: self.dto.formState.PageInstanceId, code: secretCode }, function (result) {
                                                if (result) {
                                                    postDataPostback();
                                                    e.component.hide();
                                                }
                                                else {
                                                    utils.message(i18n.$t("secret_code.expired"), 2);
                                                    def.reject();
                                                    self.options.showLoading(false);
                                                    self.saveAllowed = true;
                                                }
                                            }, null, "GET", false);
                                            
                                        } else {
                                            utils.message(i18n.$t("secret_code.required"), 2);
                                            def.reject();
                                            self.options.showLoading(false);
                                            self.saveAllowed = true;
                                        }
                                        
                                    });
                                    $("#cancel-button").on("click", function () {
                                        def.reject();
                                        self.options.showLoading(false);
                                        self.saveAllowed = true;
                                        e.component.hide();
                                    });                                      
                                }
                            }

                        }).show();
                    } else {
                        postDataPostback();
                    }
                } else {
                    this._showInvalidMessage(validationResult.messages);
                    self.saveAllowed = true;
                    self.element.find('.next-btn').removeAttr('disabled');
                    self.element.find('.before-btn').removeAttr('disabled');
                    def.reject();
                }
            }

            return def.promise();
        },


        _ajaxPostRequest: function (url, method, data, sync, type, qs) {
            const self = this;
            if (type === undefined || type === null)
                type = "GET";

            if (typeof qs !== "undefined" && qs !== null && qs !== "" && typeof qs !== "object")
                url = `${url}/${method}?${qs}`;
            else if (typeof qs !== "undefined" && qs !== null && qs !== "" && typeof qs === "object")
                url = `${url}/${method}?${jQuery.param(qs)}`;
            else
                url = `${url}/${method}`;

            return $.ajax(
                {
                    type: type,
                    async: true,
                    url: url,
                    cached: false,
                    data: data ? JSON.stringify(data) : null,
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    beforeSend: function (xhr) {
                        if (self.dto) {
                            xhr.setRequestHeader('pageInstanceId', self.dto.formState.PageInstanceId);
                        }
                        if (self.options != 'undefined' && self.options != null)
                            xhr.setRequestHeader('TaskInstanceId', self.options.taskId);

                        //TODO:AccessToken
                        if (window.sessionStorage.getItem('accessToken')) {
                            xhr.setRequestHeader('rayheader', window.sessionStorage.getItem('accessToken'));
                        }
                    }
                }).promise();
        },

        // titlebar icons handlers
        _onClickTimeSheet: function () {
            const obj = {
                id: router.app.$route.params.id,
                cmp: (router.app.$route.params.status === 'Completed')
            };

            timesheet().activate(obj);
        },
        _isNullOrWhiteSpace: function (str) {
            return str === null || str.match(/^ *$/) !== null;
        },

        _checkIfSaveIsAllowed: function () {
            if (!this.saveAllowed) {
                utils.message(i18n.$t('errors.save_in_progress'), 3);
                return false;
            }
            return true;
        }

    });
})(jQuery);
