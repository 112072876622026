var localeMessages = {
    user: 'User',
    staff: 'Staff',
    save_successfully: 'Saved Successfully.',
    error_saving: 'Error Saving',
    help: 'Help',
    ok: 'Ok',
    send_as_staff: 'Send as staff',
    remove_record: 'Remove record',
    homepage: 'Home Page',
    view_form: 'Form View',
    confirm: 'Confirm',
    prev_row: 'Prev row',
    next_row: 'Next row',
    save_and_new: 'Save and New',
    save_and_next: 'Save and Next',
    save_and_previous: 'Save and Previous',
    save_and_return: 'Save and Return',
    cancel_and_return: 'Cancel and Return',
    submit_form: 'Submit form',
    return: 'Return',
    save_form: 'Save Form',
    login: 'Login',
    sign_in: 'Sign-in',
    username: 'Username',
    password: 'Password',
    date: 'Date',
    author: 'Author',
    more_details: 'Show more details',
    show: 'Show',
    close: 'Close',
    open: 'Open',
    languages: 'Languages',
    try_again: 'Try Again',
    tracking: 'Tracking',
    tracking_code: 'Tracking code',
    history: 'History',
    process_level: 'Process level: <strong>{level}</strong>',
    loading: 'Loading',
    uploading: 'Upload',
    save: 'Save',
    remove: 'Remove',
    attention: '* Attention:',
    select: 'Select',
    please: 'Please',
    doing: 'Doing',
    upload_doing: 'Uploading and Saving',
    page_refresh: 'To see the changes, refresh the cache (Ctrl-F5).',
    send: 'Send',
    insert: 'Insert',
    edit: 'Edit',
    delete: 'Delete',
    reply: 'Reply',
    are_you_sure: 'Are you sure?',
    control: 'Control',
    show_error_details: 'Show error details',
    warning: 'Warning',
    search: 'Search',
    empty: 'Empty',
    new: 'New',
    priority: 'Priority',
    priority_low: 'Low',
    priority_normal: 'Meduim',
    priority_high: 'High',
    priority_very_high: 'Urgent',
    subject: 'Subject',
    created_date: 'Created at',
    response_deadline: 'Response deadline',
    done_date: 'Done at',
    sender: 'Sender',
    starter_user: 'Starter user',
    starter_staff: 'Staff user',
    is_assignable: 'Is Assignable',
    insert_label: 'Label',
    continue: 'Continue',
    submit: 'Submit',
    cancel: 'Cancel',
    cancel_nested: 'Cancel',
    other: 'Other',
    settings: 'settings',
    change_theme: 'Themes',
    message: 'Message',
    messages: {
        not_any_record_found: 'Record was not found.',
        not_history_for_tracking_code_found: 'No history found for tracking code.',
        not_selected_principals: 'No principals are selected rename folder.',
        write_dont_work_folder_name: 'Please select a name for To-Do Tasks folder.',
        write_done_work_folder_name: 'Please select a name for Completed Tasks folder.',
        write_dont_send_request_folder_name: 'Please select a name for Draft Request folder.',
        save_attntion: 'Are you sure you want to proceed?',
        changing_name: 'Renaming...',
        sucsses_message: 'Changes are successfully saved.',
        file_volume: 'File size',
        file_upload: 'should not exceed 300 kb.',
        file_type: 'Upload a file with jpg, png or gif format.',
        delete_sucsses: 'Deleted Successfully',
        reset_confirm: 'Are you sure you want to reset the background image as a default?',
        doing_sucsses: 'Completed  Successfully',
        file_type_attention: 'Only jpg, png or gif files are supported.',
        delete_confirm_massage: 'Are you sure you want to delete?',
        your_request_has_been_sent: 'Your request has been sent successfully.',
        confirm_message: 'Are you sure?',
        save_successfully: 'Saved Successfully.',
        password_not_currect: 'Username or password is not correct.',
        connection_not_safe: 'Connection is not secured.',
        pleas_try_later: 'Please try again',
        username_or_password_is_null: 'Username or password is not correct.',
        control_behavior_not_exists: 'There is no form behavior for this form element.'
    },
    apply: 'Apply',
    all_messages: 'All messages',
    new_message: 'New message',
    messages_sent: 'Sent messages',
    messages_receive: 'Received messages',
    messages_receive_notification: 'You have a new message!',
    sign_out: 'Sign out',
    change_password: 'Change password',
    'first-page': 'First page',
    'view-form': 'View form',
    invalid_date: 'Invalid date',
    operation_faild: 'Operation failed.',
    session_end_you_will_redirect_to_the_homepage:
        'Your session has end, you will redirect to the homepage.',
    session_end_title: 'Session timeout.',
    login_again: 'Please login again!',
    server_shutdown_template: 'Attention! Server is shutting down at 12:00 am.',
    server_notif_message: 'Enter the message you want to send to',
    server_notif_message_users: 'user(s)',
    server_notif_message_sent: 'Message was successfully sent!',
    server_notif_message_error: 'Error has occurred!',
    process_tree: 'History Tree',
    debug_info: 'Debug information',
    process_graph: 'History Graph',
    process_history: 'Process History',
    print_form: 'Print',
    time_sheet: 'Timesheet',
    assign: 'Forward',
    word_output: 'Export to Word',
    pdf_output: 'Export to PDF',
    pdf_preview: 'Preview PDF',
    word_print: 'Print from Word',
    report: 'Reports',
    pdf_download: 'Download PDF File : ',
    select_file: 'Select File :',
    send_pdf: 'Send PDF File',
    by_level: 'Staff:',
    view_all_staff: 'View all staff',
    image: 'Image',
    of: 'of',
    error: 'Error',
    has_form_less_events: 'has form less events',
    more_items: 'More items...',
    'retrieve-data-error': 'Retrieve data has failed.',
    upload_pdf_file: 'Submit pdf file.',
    download_pdf_file: 'Download pdf file',
    export: 'Export',
    not_selected: 'Not selected',
    news_detaile: 'News Detailes',
    new_task: 'New Task',
    select_date: 'Please select the start and finish date',
    id: 'ID',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    copied: 'Copied',
    unable_to_copy: 'Unable to copy',
    password_setting: {
        current_password: 'Current password',
        new_password: 'New password',
        confirm_new_password: 'Confirm new password',
        messages: {
            current_password_is_wrong: 'Current password is wrong!',
            passwords_do_not_match: "Passwords don't match!",
            new_password_same_as_current: 'New password cannot be the same as the current password!',
            currentPassword_is_empty: 'Current password is empty',
            newPassword_is_empty: 'New password is empty',
            confirmNewPassword_is_empty: 'Confirm new password is empty'
        }
    },
    title: {
        news: 'News',
        announcements: 'Announcements',
        procces_history: 'Process History'
    },
    search_panel_control: {
        filter: 'Filter',
        clear_filter: 'Clear Filter'
    },
    grid_control: {
        add: 'Add',
        add_inline: 'Inline Add',
        edit: 'Edit',
        edit_inline: 'Inline Edit',
        del: 'Delete',
        undel: 'Undelete',
        export_excel: 'Export to Excel',
        import_excel: 'Import from Excel',
        template_excel: 'Download Excel template',
        save: 'Save',
        cancel: 'Cancel',
        view: 'View',
        export: 'Export',
        grid_is_in_editing_mode: 'is in editing mode, please apply changes.',
        grid_is_in_editing_mode_you_cannot_do_anything:
            'You are not allowed to do any operations in editing mode.',
        grid_error_retrieving_data: 'There was an error fetching the data.',
        no_rows_selected: 'No rows selected',
        remove_selected_row: 'Remove selected row?',
        only_selected_columns_will_be_exported: 'Only selected columns will be exported',
        items_get_error: 'Error occurred during get list!',
        noDataText: 'There is no data to display.',
        saveChanges: 'Save changes',
        discard: 'Discard changes',
        saved: 'Saved',
        save_unsuccessful: 'Save was unsuccessful',
        sum: 'Sum',
        avg: 'Avg',
    },
    attachment_control: {
        add: 'Add',
        new: 'New',
        select: 'Select',
        scan: 'Scan',
        new_version: 'New Version',
        delete: 'Delete',
        delete_all: 'Delete All',
        revert: 'Revert',
        edit: 'Edit',
        download: 'Download',
        noFileExists: 'No file exists',
        select_file: 'Select File',
        upload_file: 'Upload File',
        upload_files: 'Upload Files',
        upload_files_all: 'Upload All Files',
        upload_cancel: 'Cancel Upload',
        view: 'View',
        accepted_types: 'Accepted file types',
        preview: 'Preview',
        one_file: '1 file',
        files: 'files',
        each: 'each',
        messages: {
            folder_default_not_found: 'Default folder not found!',
            delete_file: 'Are you sure you want to delete?',
            delete_all_files: 'Are you sure you want to delete ALL files?',
            save_Changes: 'Are you sure you want to proceed?',
            error_try_again: 'Error occurred during save, please try again!',
            file_edit_dont_allow: 'You are not allowed to edit this type of file!',
            desktop_file_launcher_notFound:
                "DesktopFileLauncher service not found! Download from <a href='FileLuncher/FileLauncherSetup.exe' style='font-weight: bold;'> here <a/> ",
            reverted_Successfully: 'The file reverted successfully.',
            file_upload_count_error:
                'The number of files added exceeds the limit allowed and can not be upload!',
            default_file_delete_error: 'Deleting the default file is not possible!',
            default_file_edit_error: 'Editing the default file is not possible!',
            file_type_error: 'The file type is not allowed!',
            scan_upload_error: 'Error occurred during scan!',
            scan_service_error: 'Scanner service not found!',
            select_file_error: 'No items selected!',
            file_edit_error: 'Error occurred during edit!',
            file_upload_successfully: 'The file uploaded successfully!',
            file_size_error: 'The file size exceeds the limit allowed and cannot be uploaded!',
            file_repeat_name_error: 'The file name is repeated!',
            file_select_count_error: 'The number of files selected exceeds the limit allowed!',
            file_upload_error: 'Error occurred during upload!',
            file_name_repeated_by_param: 'File {repeatedName} is repeated.',
            'scan-opration-encountered-problem': 'There was a Problem with scanning',
            file_is_in_edit_mode: 'The file is in edit mode',
            dont_allow_edit_file: 'Editing this type of file is not possible!',
            file_download_new_error: "Newly uploaded file can't be downloaded",
            file_open_new_error: "Newly uploaded file can't be opened"
        }
    },
    export_control: {
        title: 'Select Type Of Export',
        titlesetting: 'title Setting',
        showTableTitle: 'Display Title In Export',
        backGround: 'Background Color',
        fontname: 'Font Name',
        fontsize: 'Font Size',
        fontcolor: 'Font Color',
        borderstyle: 'Border Style',
        bordercolor: 'Border Color',
        columnsetting: 'Columns Setting',
        cellsetting: 'Cells Setting',
        createfile: 'Create File',
        bodysetting: 'Body Setting',
        exit: 'Exit',
        columnfontstyle: 'Column Font Style',
        context: 'Context',
        contextstyle: 'Context Style',
        date: 'Date',
        twodigit: 'Number With up To Two Decimal Digits',
        fourdigit: 'Number With up To Four Decimal Digits'
    },
    chart_control: {
        error_in_Get_Data: 'Getting Chart Data Failed ===>',
        updating_chart: 'Loading the chart…',
        updating_map: 'Loading the map…',
        error_in_get_map_data: 'Error in retrieving the map data.',
        loading: 'Loading...',
        exportButtonTitle: 'Export',
        printButtonTitle: 'Print',
        rangeSelectorFrom: 'From',
        rangeSelectorTo: 'To',
        rangeSelectorZoom: 'Zoom',
        downloadPNG: 'Download image PNG',
        downloadJPEG: 'Download image JPEG',
        downloadPDF: 'Download document PDF',
        downloadSVG: 'Download image SVG',
        downloadXLS: 'Download XLC',
        downloadCSV: 'Download Text',
        printChart: 'Print Chart',
        contextButtonTitle: 'Chart context menu',
        month_january: 'January',
        month_february: 'February',
        month_march: 'March',
        month_april: 'April',
        month_may: 'May',
        month_june: 'June',
        month_july: 'July',
        month_august: 'August',
        month_september: 'September',
        month_october: 'October',
        month_november: 'November',
        month_december: 'December',
        short_month_january: 'Jan',
        short_month_february: 'Feb',
        short_month_march: 'Mar',
        short_month_april: 'Apr',
        short_month_may: 'May',
        short_month_june: 'Jun',
        short_month_july: 'Jul',
        short_month_august: 'Aug',
        short_month_september: 'Sep',
        short_month_october: 'Oct',
        short_month_november: 'Nov',
        short_month_december: 'Dec',
        weekday_monday: 'Monday',
        weekday_tuesday: 'Tuesday',
        weekday_wednesday: 'Wednesday',
        weekday_thursday: 'Thursday',
        weekday_friday: 'Friday',
        weekday_saturday: 'Saturday',
        weekday_sunday: 'Sunday'
    },
    assign_control: {
        no_name: 'no_name',
        delete: 'delete',
        messages: {
            item_is_repeated: 'Item  {displayMember}  is repeated.'
        }
    },
    tree_control: {
        expand_collapse: 'Expand/Collapse',
        error: 'Error',
        warning: 'warning',
        add_new: 'Add',
        show: 'Show',
        edit: 'Edit',
        delete: 'Delete',
        messages: {
            item_delete_confirm: 'Are you sure you want to delete?',
            item_get_info_error: 'Error occurred during get information!'
        }
    },
    comment_control: {
        delete_confirm: 'Are you sure you want to delete?',
        back: 'back',
        save: 'Apply',
        empty: "Comment can't be empty"
    },
    dropdown_control: {
        select: 'Select...',
        messages: {
            items_get_error: 'Error occurred during get list!'
        }
    },
    context: {
        new_folder: 'New',
        edit_folder: 'Edit',
        delete_folder: 'Delete',
        open_in_new_window: 'Open in new window',
        rules: 'Manage folders rules',
        messages: 'Messages',
        send_message: 'Send message',
        view_received_messages: 'View received messages',
        view_sent_message: 'View sent messages'
    },
    cartable_grid: {
        open_in_new_window: 'Open in new window',
        history: 'History',
        tree_history: 'Tree',
        graph_history: 'Graph',
        time_sheet: 'Timesheet',
        change_folder: 'Change folder',
        flag: 'Flag',
        no_flag: 'Without flag',
        assignTask: 'Assign',
        FolderService_CanNotTransfer: 'Cannot transfer to this folder',
        total_tasks: 'Total tasks in this folder: ',
        total_unread_tasks: 'Total unread tasks: '
    },
    create_physical_folder: {
        add_modal_header: 'Create folder',
        edit_modal_header: 'Edit folder',
        folder_title: 'Title',
        folder_type: 'Type',
        dont_send_request: 'Draft requests',
        undone_works: 'Pending Tasks',
        done_works: 'Completed Tasks',
        folder_title_empty: 'Folder title is empty',
        folder_title_length_error: 'Title length should not exceed 50 characters.',
        folder_title_validate_error: 'Name is invalid.'
    },
    footer: {
        powered_by_raybpms: 'Powered by DBP',
        rights: 'Interfacing Technologies Corporation. All rights reserved.',
        url: 'https://www.interfacing.com/',
        help: 'Help',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'Error on tracking!',
        tracking_code_is_empty: 'Tracking code is empty!',
        error_on_delete: 'An error occurred during delete operation',
        please_select_pdf_file: 'Please select a pdf file',
        operation_is_not_allowed: 'You are not allowed.',
        events_cannot_be_loaded: 'Events cannot be loaded!',
        server_error: 'Server error',
        you_are_not_allowed_to_view_form: "You don't have permission to access the form",
        access_denied: 'Access Denied',
        print_failed: 'Print failed',
        error_occured: 'An error occurred, please contact administrator.',
        task_not_found: 'Could not find this task.It may have been executed by another user.',
        application_is_not_deployed: 'Application is not deployed.',
        save_in_progress: 'Saving is currently in progress, please try again later.'
    },
    Lock: {
        'bpms-lock-expired': 'Lock is expired!',
        'maximum-bpms-lock-user': 'Concurrent users limit exceeds the license!',
        'bpms-lock-failed': 'Lock not found!'
    },
    slider_managment: {
        title: 'Slider images',
        slider_show_is_repeat: "There is a repetition in slider's image order.",
        slider_show_is_empty: 'Image order is empty.',
        upload_message: 'Your images are more than limit!',
        image_displaying: 'Image Order',
        same_name_massage: 'An image exists with the same name!',
        image_is_too_large: 'Image size is too large',
        name: 'Name',
        image: 'Image',
        pic_type: 'Select an image (jpg,png,gif) file of less than 80 KB.',
        image_required: 'Select an image.',
        level_required: 'Select the order of the image',
        new: 'New',
        edit: 'Edit',
        empty: 'empty'
    },
    cartable_management: {
        cartable_managment_title: 'Task-List Management – Rename Default Folders',
        cartable_date_setup: 'Date settings within Task-List  ',
        status: 'Status:',
        exact: 'Accurate',
        limited: 'Approximated',
        exact_dsc: 'Show exact date and time',
        limited_dsc: 'Show past days approximately',
        select_person: 'Select a person, job position or a group to rename main folder',
        dont_works: 'To-Do Tasks folders:',
        done_work: 'Completed Task folders:',
        dont_send_request: 'Draft Requests:',
        changing_folder_name: 'Rename Folders',
        attention_message: 'To see the latest changes please login again.',
        tree_expanded: 'Pending folder of the first role will be selected after the login.',
        hide_default_user_folders: 'Hide user default folders ( such as pending, working and draft )',
        expand_folders_by_default: "Expand all user's and staff's folders by default",
        tree: 'Tree folders'
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'Vacation calendar',
        year: 'Year:',
        show_calendar: 'Show calendar ',
        year_calendar: 'Year',
        messages: {
            year_validate_message: 'The year is not valid!'
        }
    },
    appearance_management: {
        ui_setup: 'Personalization',
        back_image: 'Background',
        default_theme: 'Default Theme',
        banner: 'Top Banner',
        back_image_dsc: 'To select the login page and home page, use the form below.',
        default_theme_dsc: 'Please select one of the below as a default Task-List theme.',
        banner_dsc: 'Use the form below to select a site banner.NOTE: ',
        before_login: 'Before login image:',
        after_login: 'Login image:',
        current_img: 'Current Image',
        location: 'Location:',
        repeat: 'Repeat',
        no_repeat: 'Without Repeat',
        center: 'Center',
        back_color: 'Background Color',
        upload_save: 'Upload & Save',
        select_theme: 'Select Theme',
        preview: 'Theme Preview',
        set_theme: 'Apply theme',
        banner_attention: '',
        height_attention: "Image's height should not exceed 149 pixels.",
        image_attention: "Banner's image height should be 149 pixels.",
        back_image_file: "Banner's default image",
        right_iamge: 'Left-Side Image:',
        center_image: 'Center Image:',
        left_image: 'Right-Side Image:',
        for_field: 'For Filed:',
        select_image_file: 'Please select an image file',
        delete_current_banner: 'Delete current banner',
        image_restore: 'Restore default image',
        select_theme_for_current_user: 'Select theme for the current user.',
        file_type: 'Only jpg, png or gif files are supported.',
        server_wide: 'Server-wide',
        user: 'User',
        save: 'Save'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                'Only image size, icon shape, location and text size will change, Are you sure you want to proceed?',
            save_sucsses_all: 'Changes are applied on everything.',
            save_confirm: 'Are you sure you want to proceed?',
            save_sucsses: 'Changes are successfully saved.',
            insert_number: 'To change the icon or image size you can only enter the number.',
            icon_size: 'Icon size should be between 25 to 140 pixels.',
            file_type: 'Only jpg, png or gif files are supported.',
            icon_volume: 'the icon/ image size should not exceed 30kb.',
            insert_only_number: 'you can enter numbers only'
        },
        home_ui_setting: 'Home Settings',
        items_menu: 'Items Menu',
        description: 'To add menus to Home page, Select the item and change its settings.',
        menu_setting: 'Menu settings',
        show_menu: 'Show menu in Home page',
        first_level: 'Show in first level',
        not_show_top: 'Hide in top menu',
        display_name: 'Shown name',
        icon_width: 'Icon width',
        icon_width_ph: 'icon/image width',
        icon_height: 'Icon height',
        icon_height_ph: 'Icon/image height',
        home_menu_icon: 'menu icon/image in Home page',
        select_file: 'Please select a file with jpg, png or gif format.',
        icon_view: 'icon/image shape',
        circul: 'Circle',
        squared: 'Square',
        rectangle: 'Rectangle',
        text_location: 'Text Location',
        up_image: 'Top Image',
        center_image: 'Center Image',
        bottom_image: 'Bottom Image',
        up_text: 'Top Text',
        center_text: 'Middle Text',
        bottom_text: 'Bottom Text',
        delete_image: 'Delete Image',
        text_color: 'Text Color',
        float_text_color: 'Float Text Color',
        hilight_text_color: 'Highlight Text Color',
        save_button_title: 'All settings except image, shown name and adding to menu',
        doing_settup: 'Apply changes to all',
        check_mark: 'Showing in Home page',
        published_date: 'Published date:'
    },
    news_management: {
        message: {
            insert_title: 'Please insert the title.',
            title_character_length: 'Title length should not exceed 255 characters.',
            text_character_length: 'Text length should not exceed 4000 characters.',
            insert_text: 'Please insert the text.',
            number_priority: 'Select the priority by numbers.',
            number_large: 'The selected number for news priority is more than 4 digits.',
            file_volume: 'File size should be less than 100kb.'
        },
        news: 'News',
        add: 'Add',
        title: 'Title',
        text: 'Text',
        text_color: 'Text Color',
        enable_show: 'Visible for ordinary users',
        news_setup: 'News setup',
        news_image: 'News Image',
        news_priority: 'News Priority',
        current_pic: 'Uploaded pictures',
        delete_curr_pic: 'Delete current image',
        pic_type: 'A jpg, png or gif file format less than 100 kb.',
        id: 'ID',
        createdAt: 'Date',
        username: 'Username',
        control: 'Control'
    },
    message_management: {
        messages: {
            repeated_recipient: 'The Recipient is repeated',
            title_is_empty: 'Message title is empty',
            success_send: 'The message sent successfully',
            unselected_recipient: 'No recipient selected.'
        },
        reply_message: 'Reply',
        title: 'Title:',
        message_text: 'Message body:',
        attachment: 'Attachments',
        recipients: 'Recipients',
        select: 'Select ...',
        add_to_list: 'Add to list',
        back: 'Return',
        sended: 'Sent Message',
        sended_by: 'Sent by:',
        send_date: 'Sent time:',
        message_show: 'Show Message',
        no_permission: 'You are not allowed to access!',
        subject: 'Subject:',
        date_time_send: 'Sent Time and Date',
        sender: 'Sender',
        receiver: 'Receiver',
        menu_title: 'messages',
        received: 'Received Message',
        send_email: 'Send Email',
        send_sms: 'Send SMS'
    },
    task_assign: {
        messages: {
            success_assign: 'Successfully forwarded.',
            unselected_recipient: 'No recipient selected.'
        },
        assign: 'Forward - Assign',
        recipients: 'Recipients',
        description: 'Description',
        last_dsc: 'Use current description',
        out_work: 'Terminate current task',
        keep_sender: 'Keep current sender'
    },
    process_log: {
        status: 'Status',
        event: 'Event',
        user: 'User',
        user_position: 'Job Position',
        work_title: 'Job title',
        responsible: 'Responsible',
        create_date: 'Task start date',
        done_date: 'Task finish date',
        folder_name: 'Folder name',
        start_task: 'Starter activity',
        human_task: 'Human activity',
        fork_task: 'Forked activity',
        join_task: 'Joined activity',
        system_task: 'System activity',
        common_task: 'Common activity'
    },
    timesheet_register: {
        messages: {
            time_reng: 'Start date cannot exceed the finish date',
            select_row: 'Please select a row.',
            delete_timesheet_confirm: 'Are you sure you want to delete current timesheet?',
            save_time_confirm:
                'This period is selected for another task. Do you want to replace it for current taks?',
            can_not_edit: 'This period is saved for a completed task. Edit is not permitted.',
            date_not_valid: 'Date is not valid'
        },
        errors: {
            error_in_remaining_time: 'Error occurred in remaining time',
            get_timesheet: 'Error occurred during timesheet export',
            error_in_save: 'Error occurred during save',
            error: 'Error',
            error_in_delete: 'Error occurred during delete'
        },
        work_dsc: 'Job Description',
        date: 'Date',
        start_time: 'Start time',
        end_time: 'End time',
        remaining_time: 'Remaining time',
        description: 'Description',
        timesheet: 'Timesheet',
        current_work: 'Assign to current task',
        persian_date: 'Date',
        weekly_plan: 'Weekly Plan',
        prev_week: 'Previous Week',
        next_week: 'Next Week',
        plus: 'Add',
        all_work: 'All current works',
        delete: 'Del'
    },
    delegation_management: {
        messages: {
            select_user: 'Please select the referral user.',
            select_date: 'Please select the start and finish date',
            select_folder: 'Please select the folders that delegator can view',
            compare_fromDate_toDate: 'finish date is greater than start date'
        },
        folder_to_delegate: 'Folders that delegate can view:',
        delegations: 'Delegations',
        delegation_edit: 'Edit delegation',
        delegation_user: 'Delegate user:',
        all_time: 'Permanent',
        from_date: 'From Date:',
        to_date: 'To date',
        start_date: 'Start Date',
        finish_date: 'Finish date',
        delegate_to: 'Delegate to:',
        more_item: 'More items',
        all_item: 'All items',
        remove_delegate: 'Remove Delegate',
        delegation_create: 'Add',
        no_records: 'No record match your keywords',
        delegation_add: 'Add delegation',
        delegation_user_placeholder: 'Type to search',
        more_users: 'There are more results, please use better keywords'
    },
    rule_criterias: {
        new_rule: 'New Rule',
        edit_rule: 'Edit Rule',
        no_permission: 'You need a Permission!',
        tag_error: 'Error occurred during tag update!',
        flag_error: 'Error occurred during flag update!',
        no_actions: 'No action!',
        action: 'Action',
        rule_name: 'Rule Name',
        operation: 'Operation',
        select_folder: 'Select Folder'
    },
    ray_payment: {
        messages: {
            close_pay_win: 'First, close the payment window.'
        },
        wait_status: 'Waiting for the payment.',
        payed_status: 'Payed',
        failed_status: 'Error in the payment',
        not_payed_status: 'Not payed',
        currency: ' Rial',
        through: 'Through',
        pay: 'Payment'
    },
    ray_datetimepicker: {
        date_not_valid: 'Date is invalid!',
        time_not_valid: 'Time is invalid!',
        ok: 'Ok'
    },
    logs_management: {
        show_details:
            'Enable show error details. By enabling this option users will see the full technical error message on every error occurrence.',
        error_number: 'ID',
        date: 'Created at',
        level: 'Level',
        staff_id: 'Staff Id',
        application_name: 'Application Name',
        server_name: 'Server Name',
        error_log: 'Error logs',
        error_message: 'Error message',
        api_log: 'API calls log',
        id: 'Id',
        session: 'Session',
        api: 'API',
        method: 'Method',
        severity: 'Severity',
        search: 'Search...',
        key: 'Key parameter',
        duration: 'Duration (ms)',
        epc_log: 'EPC calls log',
        user_log: 'User log',
        result: 'Result code',
        application_log: 'Application Log',
        copy_clipboard: 'Copy to clipboard',
        user: 'User',
        login_datetime: 'Login Date Time',
        logout_datetime: 'Logout Date Time',
        active_connections: 'Active connections',
        send_message_all: 'Send message to all',
        send_message_connection: 'Send message to this connection',
        start_datetime: 'Start Date Time'
    },
    rule_management: {
        title: 'Manage folders rules',
        operation: 'Operation',
        rule_name: 'Name',
        add_new_rule: 'New Rule',
        edit_rule: 'Edit Rule',
        are_you_sure: 'Are you sure to delete rule?',
        rule_modal: {
            name: 'Rule Name',
            subject: 'Subject',
            task_type: 'Type',
            create_date: 'Create Date',
            response_date: 'Response Deadline',
            done_date: 'Done Date',
            priority: 'Priority',
            flag: 'Flag',
            read: 'Read',
            transcript: 'Transcript',
            app: 'Application',
            in_folder: 'Folder',
            not_selected_option: 'Not Selected',
            validation: {
                name: 'Name is required'
            },
            clear_tooltip: 'clear'
        }
    },
    today: 'Today',
    form: 'Form',
    form_behavior: 'Form Behavior',
    close_form_warning: 'Are you sure you want to exit? Any change will be lost.',
    yes: 'Yes',
    no: 'No',
    secret_code: {
        title: 'Approval Authentication',
        message: 'We sent you a secret code to your email. Please copy and paste it here.',
        code: 'Code:',
        legal_sentence:
            'By Approving with your e-signature, you are signing the document electronically. You agree that</br>your electronic signature has the same legal validity and effect as your handwritten signature on</br>the document, and that it has the same meaning as your handwritten signature.',
        expired: 'The code is invalid or expired',
        required: 'The code is required',
        submit: 'Submit',
        cancel: 'Cancel'
    },
    user_settings: 'User settings',
    audit_trail: {
        title: 'Audit trail',
        operation_time: 'Operation Time',
        application_name: 'Application name',
        user: 'User',
        operation: 'Operation',
        entity_name: 'Entity name',
        entity_definition: 'Entity definition',
        task_subject: 'Task subject',
        field_name: 'Field name',
        old_value: 'Old value',
        new_value: 'New value'
    }
}
export default localeMessages
