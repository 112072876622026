var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "dropdown-menu login-form js_login-form dx-theme-background-color dx-widget",
    },
    [
      _c(
        "form",
        {
          staticClass: "form-horizontal",
          on: {
            submit: function ($event) {
              return _vm.submit()
            },
          },
        },
        [
          _c("fieldset", [
            _c("div", { class: { "dx-rtl": _vm.isRTL } }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("sign_in")))]),
              _vm._v(" "),
              _c("div", { staticClass: "dx-fieldset" }, [
                _c("div", { staticClass: "dx-field" }, [
                  _c("div", { staticClass: "dx-field-label" }, [
                    _vm._v(_vm._s(_vm.$t("username"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dx-field-value" },
                    [
                      _c("DxTextBox", {
                        attrs: {
                          id: "login-username",
                          "show-clear-button": true,
                          "rtl-enabled": _vm.isRTL,
                          placeholder: _vm.$t("username"),
                        },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dx-fieldset" }, [
                _c("div", { staticClass: "dx-field" }, [
                  _c("div", { staticClass: "dx-field-label" }, [
                    _vm._v(_vm._s(_vm.$t("password"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dx-field-value" },
                    [
                      _c("DxTextBox", {
                        attrs: {
                          id: "login-password",
                          "show-clear-button": true,
                          mode: "password",
                          valueChangeEvent: "keyup",
                          "rtl-enabled": _vm.isRTL,
                          placeholder: _vm.$t("password"),
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.errorTxt) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "col-sm-6 col-sm-offset-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-block",
                    class: _vm.loading ? "disabled" : "",
                    attrs: { type: "button", id: "login-submit" },
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("login")) +
                        "\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }