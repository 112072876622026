import utils from 'jsf/utils.js';

export function GridExport() {
    var root = $("<div></div>");
    var columnNames, columnModel, controlState, url, pageInstanceId, sortIndex, sortDirection;
    var $grid, selectedRows = [], isInit = false;
    //params: columnNames, columnModel, controlState, url, pageInstanceId, sortIndex,sortDirection
    var activate = function (params) {

        columnNames = params.columnNames;
        columnModel = params.columnModel;
        selectedRows = [];
        isInit = false;

        const delButton = $.grep(columnModel, function (e) {
            return e.index === 'delButton';
        });

        if (delButton.length > 0) {
            columnNames.splice(0, 1);
            columnModel.splice(0, 1);
        }

        controlState = params.controlState;
        url = params.url + "/export";
        pageInstanceId = params.pageInstanceId;
        sortIndex = params.sortIndex;
        sortDirection = params.sortDirection;

        const $gridExportform = $('.bpms-gridExportform');
        utils.showModalHtml('Views/GridExport.html').done(
            function (html) {
                root.rayModal({
                    modal: true,
                    title: i18n.$t('grid_control.only_selected_columns_will_be_exported'),
                    show: {
                        effect: 'blind',
                        duration: 1000
                    },
                    width: 'col-lg-12',
                    height: 620,
                    onClosed: function () {
                        root.empty();
                    },
                    onOpened: function () {
                        root.empty();
                        root.append(html);

                        root.find("#apply").text(i18n.$t('grid_control.export_excel'));

                        init();

                        $(".ui-paging-info").hide();

                        setTimeout(function () {
                            $grid = $("#gridExport");
                            const parentWidth = $grid.closest('.modal-body').width();
                            $grid.setGridWidth(parentWidth);

                            $("input[type='checkbox'][offval='no']").off("click").on("click", function () { return false; });

                        }, 250);

                    }
                });
            }
        );
        $gridExportform.show();
    };
    var init = function () {
        $grid = $("#gridExport");
        $grid.jqGrid({
            mtype: "Post",
            loadonce: false,
            loadui: 'disable',
            direction: (payload.currentLocale.IsRightToLeft ? "rtl" : "ltr"),
            datatype: (controlState.PreviewMode || !controlState.Behavior.Visible ? '' : 'JSON'),
            url: url,
            ajaxGridOptions: { contentType: 'application/json; charset=utf-8' },
            ajaxRowOptions: { async: true },
            loadBeforeSend: function (xhr) {
                xhr.setRequestHeader('CSRF_Token', $.trim($("[name=__RequestVerificationToken]:first").val()));
            },
            serializeGridData: function (pdata) {
                const params = new Object();
                params.page = pdata.page;
                params.pageSize = pdata.rows;
                params.sortIndex = pdata.sidx;
                params.sortDirection = pdata.sord;
                params.pageInstance = pageInstanceId,
                    params.clientId = controlState.Id,
                    params.rightToLeft = controlState.RightToLeft;
                params.bindingId = controlState.BindingId;
                return JSON.stringify(params);
            },
            jsonReader: {
                root: function (o) { return o.d.Rows; },
                page: function (o) { return o.d.Page; },
                total: function (o) { return o.d.Total; },
                records: function (o) { return o.d.Records; },
                userdata: function (o) { return o.d.userData; },
                repeatitems: false
            },
            colNames: columnNames,
            colModel: columnModel,
            viewrecords: true,
            scrollrows: true,
            autowidth: true,
            shrinkToFit: true,
            page: 1,
            loadError: function () { utils.modalDialog(i18n.$t("grid_control.items_get_error")); },
            pager: $("#gridExportPager"),
            rowNum: 100,
            rownumbers: true,
            rownumWidth: 50,
            caption: '',
            height: 400,
            sortname: controlState.InitialSortColumns,
            sortorder: controlState.InitialSortDirectionString,
            scroll: 100,
            multiselect: true,
            pgbuttons: false,
            // to save selection state
            onSelectAll: function (rowIds, status) {
                selectedRows = [];
                if (status === true) {
                    for (var i = 0; i < rowIds.length; i++) {
                        const rowitem = $.grep(selectedRows, function (e) {
                            return e.rowId === rowIds[i];
                        });
                        if (rowitem == null || rowitem === 'undefined' || rowitem.length === 0) {
                            selectedRows.push({ rowId: rowIds[i], status: status });
                        }
                    }
                }
            },
            onSelectRow: function (rowId, status, e) {
                const rowitem = $.grep(selectedRows, function (e) {
                    return e.rowId === rowId;
                });
                if (status === false) {
                    if (rowitem.length > 0) {
                        const index = selectedRows.indexOf(rowitem[0]);
                        selectedRows.splice(index, 1);
                    }
                } else {
                    if (rowitem === 'undefined' || rowitem.length === 0)
                        selectedRows.push({ rowId: rowId, status: status });
                }
            },
            gridComplete: function () {
                const checkall = $("#cb_gridExport").attr('checked') === 'checked';
                if (checkall === true) {
                } else {
                    for (let i = 0; i < selectedRows.length; i++) {
                        const rowId = selectedRows[i].rowId;
                        $("#jqg_gridExport_" + rowId).attr('checked', true);
                        $grid.find('tr#' + rowId).addClass('ui-state-highlight').attr('aria-selected', 'true');
                    }
                }
            },
            loadComplete: function () {
                if (isInit === false) {
                    $("#cb_gridExport").click();
                    isInit = true;
                }
            }
        });
        const parentWidth = $grid.closest('.modal-body').width();
        $grid.setGridWidth(parentWidth);
        //add checkbox to header
        $grid.closest("div.ui-jqgrid-view").find("div.ui-jqgrid-hdiv table.ui-jqgrid-htable tr.ui-jqgrid-labels > th.ui-th-column > div.ui-jqgrid-sortable")
            .each(function (a, b, c) {
                const $check = $("<input type='checkbox'>");
                $check.attr('id', $(this).attr('id') + '_check');
                $check.addClass("checkcolumn");
                $check.attr('checked', 'checked');
                $check.insertBefore(this);
            });
        $("#gridExportPager_center").hide();
        $("#gridExportPager_left").hide();

        $("#apply").unbind('click').bind('click', function () {
            apply();
        });
    };

    var apply = function () {
        console.log('export to excel ...');
        let rows = [];
        const columns = {};
        const checkall = $("#cb_gridExport").attr('checked') === 'checked';
        if (checkall === false) {
            rows = $grid.getGridParam('selarrrow');
        }
        $(".checkcolumn:checked").each(function (index, item) {
            //var columnid = $(item).next().text();//.attr("id").replace('jqgh_gridExport_', '').replace('_check', '');
            //columns.push(columnid);

            const currentItem = $(item);

            const columnid = currentItem.attr("id").replace('jqgh_gridExport_', '').replace('_check', '');
            const title = currentItem.next().text();

            columns[columnid] = title;
        });

        const params = {
            sortIndex: sortIndex,
            sortDirection: sortDirection,
            pageInstance: pageInstanceId,
            clientId: controlState.Id,
            bindingId: controlState.BindingId,
            selectedrows: rows,
            selectedcolumns: columns
        };
        utils.callWebAPIp("api/grid/kendo/export/excel", params, 'POST', true)
            .done(function (result) {
                const url = result.d;
                if (url && url != '')
                    window.open(url);
            })
            .fail(function (e, status, thrownError) {
                utils.showAjaxError(e, status, thrownError, controlState.Id, false);
            });
    };
    return {
        activate: activate
    };
};
