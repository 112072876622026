import ContextMenu from "../contextMenu/contextMenu.vue";

export default {
    props: [
        "columns",
        "data",
        "schema"
    ],
    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,
            treeListEl: null,
            treeListData: null,
        }
    },
    watch: {
        data() {
            
            var ds = new kendo.data.TreeListDataSource({
                data: this.data,
                schema: this.schema
            })

            this.treeListData.setDataSource(ds);
        }
    },
    computed: {
    },
    methods: {
        init() {
            if (!this.treeListData) {
                this.treeListEl = $(this.$el).find('.rayvarzBPMSTreeList');
                this.treeListData = this.treeListEl.kendoTreeList(this.buildConfig()).data('kendoTreeList');
            }
        },
        buildConfig() {
            return {
                columns: this.buildColumns(),
                dataSource: this.buildDataSource(),
                resizable: true,
                //dataBound: function (e) {
                //    console.log("dataBound", this.columns);
                //    for (var i = 0; i < this.columns.length; i++)
                //        this.autoFitColumn(i);
                //}
            }
        },
        buildColumns() {
            return this.columns;
        },
        buildDataSource() {
            let dSource = Array.isArray(this.data) ?

                    new kendo.data.TreeListDataSource({
                        data: this.data,
                        schema: this.schema
                    })

                : [];

            return dSource;
        }
    },
    updated() {
    },
    mounted() {
        this.init();
    },
    components: {
    }
}
