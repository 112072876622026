//import dateFormat from "dateformat";
import DataSource from "devextreme/data/data_source";
import DataGrid from "devextreme/ui/data_grid";
import TabPanel from "devextreme/ui/tab_panel";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';
import Restful from 'jsf/restful.js';
import utils from 'jsf/utils.js';

let EnumGridNestedAction;
let GridState;
let gridEl;
let pageSize;
let gridInEditMode = false;

const GridConstants = (function () {
    function GridConstants() {
    }
    return GridConstants;
}());
let EditType;
let Aggregate;
var RayGrid = (function () {
    function RayGrid(options, el) {
        this.sortColumn = null;
        this.sortDirection = null;
        this.columnMap = {};
        this.foreignKeyValues = {};
        this.columns = [];
        this.fieldMaps = [];
        //private toolbarItems: kendo.ui.ToolBarItem[] = [];
        this.isToolbarInitialized = false;
        this.removeColumn = null;
        this.actionColumn = null;
        this.lastPostData = [];
        this.isInitialized = false;
        this.aggregateFields = [];
        this.gridState = GridState.None;
        this.currentRecordId = null;
        this.currentRowIndex = null;
        this.navigateModeinProgress = false;
        this.currentModalDialog = null;
        this.requiredFieldValidationMessage = null;
        this.currentOffClickHandler = null;
        this.element = el;
        this.options = options;
        this.isArabic = payload.currentLocale.Culture.toLowerCase() === "ar-sa";
        this.widthInProc = false;
        this.hasExpandColumns = false;
        this.columnsWidth = [];
        this.requiredColumns = [];
        this.defaultPageSize = 20;
        this.columnRecalculated = false;
        this.firstLoad = true;
        this.loadForeignValueColumns = true;
        this.currentLocalStorageKey = '';
        this.localStorageContainsCurrentState = false;
        this.notifSent = false;

        this.showHorizontalLines = false;
        this.showVerticalLinesInBody = false;
        this.showVerticalLinesInHeader = false;
        this.alternatingRowColors = false;
        this.showOuterBorder = false;
        this.summaryOptions = [];

        this._create();
    }

    RayGrid.prototype._create = function () {
        this.mapColumns();
        this.initDataSource();
        this.createGrid();
    };

    RayGrid.prototype.createGrid = function () {
        const _this = this;

        _this.currentLocalStorageKey = _this.options.controlState.Id + ":Enabled:" + _this.options.controlState.Behavior.Enabled + ":" + _this.options.controlState.Columns.map((a) => a.DataField).join("_");
        _this.localStorageContainsCurrentState = localStorage.hasOwnProperty(_this.currentLocalStorageKey);

        let filterRow = null;
        if (this.options.controlState.ShowFilter) {
            filterRow = { visible: true, applyFilter: 'auto' };
        }
        let summaryRow = null;
        if (this.options.controlState.ShowAggregate) {
            var colsWithSummary = _this.options.controlState.Columns.filter(item => item.AggregateKey && item.AggregateColString !== "None");
            if (colsWithSummary) {
                var totalItems = [];
                colsWithSummary.forEach(col => {
                    let type = '';
                    switch (col.AggregateColString) {
                        case "Avg": type = 'avg'; break;
                        case "Sum": type = 'sum'; break;
                    }
                    totalItems.push({
                        column: col.DataField,
                        summaryType: type
                    });

                });
                summaryRow = { totalItems: totalItems };
                this.summaryOptions = totalItems;
            }
        }
        const apage = this.element.attr("defaultpage");
        let allowedPageSizeArray = [5, 10, 20, 30, 50, 100];

        if (apage && parseInt(apage)) {
            var pSize = parseInt(apage);

            this.defaultPageSize = pSize;
            if (!allowedPageSizeArray.includes(pSize)) {
                allowedPageSizeArray.push(pSize);
                allowedPageSizeArray = allowedPageSizeArray.sort(function (a, b) { return a - b; });;
            }
        }
        const sVerticalLinesInHeader = this.element.attr("showVerticalLinesInHeader");
        if (sVerticalLinesInHeader)
            this.showVerticalLinesInHeader = sVerticalLinesInHeader.toLowerCase() === "true";

        const sVerticalLinesInBody = this.element.attr("showVerticalLinesInBody");
        if (sVerticalLinesInBody)
            this.showVerticalLinesInBody = sVerticalLinesInBody.toLowerCase() === "true";

        const sHorizontalLines = this.element.attr("showHorizontalLines");
        if (sHorizontalLines)
            this.showHorizontalLines = sHorizontalLines.toLowerCase() === "true";
            
        if (!this.showHorizontalLines)
            _this.element.addClass('hideHorizontalLines')

        const altRowColors = this.element.attr("alternatingRowColors");
        if (altRowColors)
            this.alternatingRowColors = altRowColors.toLowerCase() === "true";

        const sOuterBorder = this.element.attr("showOuterBorder");
        if (sOuterBorder)
            this.showOuterBorder = sOuterBorder.toLowerCase() === "true";


        var editingObj = {
            mode: 'batch',
            allowUpdating: _this.options.controlState.EnableInlineEdit && _this.options.controlState.Behavior.Enabled,
            editEnabled: _this.options.controlState.EnableInlineEdit && _this.options.controlState.Behavior.Enabled,
            allowAdding: _this.options.controlState.EnableInlineNew && _this.options.controlState.Behavior.Enabled,
            insertEnabled: _this.options.controlState.EnableInlineNew && _this.options.controlState.Behavior.Enabled,
            allowDeleting: _this.options.controlState.RemoveButton.Enabled && _this.options.controlState.Behavior.Enabled,
            removeEnabled: _this.options.controlState.RemoveButton.Enabled && _this.options.controlState.Behavior.Enabled,
            selectTextOnEditStart: true,
            startEditAction: 'click',
        };
        var toolbarItems = [
            {
                //We have only one add button
                location: 'before',
                widget: 'dxButton',
                cssClass: 'GridToolbarButton',
                options: {
                    text: i18n.$t("grid_control.add"),
                    icon: '',
                    onClick(e) {
                        if (_this.options.controlState.EnableInlineNew) {
                            //Inline add
                            _this.gridEl.addRow();
                            _this.gridEl.deselectAll();
                        }
                        else if (_this.options.controlState.AddButton.Enabled) {
                            //Modal add
                            _this.onAddModal_Click(e.event);
                        }
                        else {
                            //No add is configured
                        }
                    },
                },
                visible: _this.options.controlState.Behavior.Enabled && (_this.options.controlState.EnableInlineNew || _this.options.controlState.AddButton.Enabled)
            },
            {
                location: 'before',
                name: 'revertButton',
                cssClass: 'GridToolbarButton',
                showText: 'always',
                options: {
                    text: i18n.$t("grid_control.discard"),
                    icon: '',
                    type: 'danger',
                },
                visible: _this.options.controlState.Behavior.Enabled
            },
            {
                location: 'before',
                name: 'saveButton',
                cssClass: 'GridToolbarButton',
                showText: 'always',
                options: {
                    text: i18n.$t("grid_control.saveChanges"),
                    icon: '',
                    type: 'success',
                },
                visible: _this.options.controlState.Behavior.Enabled
            },
            {
                location: 'after',
                locateInMenu: "always",
                widget: 'dxButton',
                cssClass: 'GridToolbarInMenuButton',
                options: {
                    text: i18n.$t("grid_control.template_excel"),
                    icon: 'download',
                    onClick(e) {
                        utils.callWebAPI("api/grid/template", {
                            pageInstance: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                            clientId: _this.options.controlState.Id,
                            bindingId: _this.options.controlState.BindingId,
                        },
                            function (result) {
                                console.log(`Export Succeed: ${result.d}`);
                                window.open(result.d);
                            },
                            function (error) {
                                console.log(`Export Error: ${error}`);
                            }, "POST");
                    },
                    visible: _this.options.controlState.EnableImport
                }
            },
            {
                location: 'after',
                locateInMenu: "always",
                widget: 'dxFileUploader',
                cssClass: 'dx-toolbar-menu-action dx-toolbar-hidden-button GridExcelImport GridToolbarInMenuButton',
                options: {
                    text: '',
                    icon: 'import',
                    selectButtonText: i18n.$t("grid_control.import_excel"),
                    name: "files",
                    inputAttr: { id: 'inputId' },
                    uploadMode: 'useForm',
                    onContentReady: function (e) {
                        e.element.find(".dx-fileuploader-button").dxButton("instance").option("icon", "import")
                    },
                    onValueChanged: function (e) {
                        const file = e.value[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                            utils.callWebAPI("api/grid/import", {
                                pageInstance: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                                clientId: _this.options.controlState.Id,
                                bindingId: _this.options.controlState.BindingId,
                                file: reader.result
                            },
                                function (result) {
                                    _this.options.bpmsAppForm.options.showLoading(false);
                                    utils.showGenericModal("Import Result", result.msg.replace(/(?:\r\n|\r|\n)/g, '<br>'));
                                    _this.dataSource.reload();
                                },
                                function (error) {
                                    _this.options.bpmsAppForm.options.showLoading(false);
                                    utils.showGenericModal(i18n.$t('error_saving'), error.responseText, null, "ltr");
                                },
                                "POST", null,
                                function () {
                                    _this.options.bpmsAppForm.options.showLoading(true);
                                });
                        };
                    },
                },
                visible: _this.options.controlState.EnableImport
            },
            {
                location: 'after',
                locateInMenu: "always",
                cssClass: 'GridToolbarInMenuButton',
                name: "exportButton",
                options: {
                    text: i18n.$t("grid_control.export_excel"),
                    icon: 'export',
                    visible: _this.options.controlState.EnableExport
                }
            }
        ];
        var mdSettings;
        var expandColumns = _this.columns.filter(c => c.editType == EditType.RichTextExpand);
        if (expandColumns.length == 0) {
            mdSettings = {
                enabled: false
            };
        } else if (expandColumns.length == 1) {
            mdSettings = {
                enabled: true,
                template: function(element, info) {
                    _this.templateForOneRichText(element, info, expandColumns[0]);
                },
            };
        } else {
            mdSettings = {
                enabled: true,
                template: function (element, info) {
                    _this.templateForMultipleRichText(element, info, expandColumns);
                },
            };
        }
        _this.gridEl = new DataGrid(this.element, {
            columns: this.columns,
            dataSource: this.dataSource,
            columnFixing: { enabled: true },
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true,
                grouping: false
            },
            selection: {
                mode: 'none',
            },
            filterRow: filterRow,
            rtlEnabled: this.options.controlState.RightToLeft && payload.currentLocale.IsRightToLeft,
            summary: summaryRow,
            selectable: true,
            scrollable: true,
            sortable: {
                mode: "single",
                allowUnsort: true
            },
            resizable: true,
            paging: {
                pageSize: this.defaultPageSize,
            },
            noDataText: i18n.$t("grid_control.noDataText"),
            pager: {
                visible: true,
                allowedPageSizes: allowedPageSizeArray,
                showPageSizeSelector: true,
                showInfo: true,
                showNavigationButtons: true,
                displayMode: 'compact'
            },
            showColumnLines: true, //work together with css, to ot show column line for data rows
            showRowLines: this.showHorizontalLines,
            rowAlternationEnabled: this.alternatingRowColors,
            showBorders: this.showOuterBorder,

            allowColumnReordering: true,
            allowColumnResizing: true,
            columnAutoWidth: true,

            columnResizingMode: 'widget',
            stateStoring: {
                enabled: true,
                type: 'localStorage',
                storageKey: _this.currentLocalStorageKey
            },
            repaintChangesOnly: true,
            editing: editingObj,
            masterDetail: mdSettings,
            onRowValidating(e) {
                if (!e.isValid) {
                    if (!_this.notifSent) {
                        utils.message(i18n.$t("grid_control.save_unsuccessful"), 2);
                        _this.notifSent = true;
                    }
                    $(window).unbind("click", this.currentOffClickHandler);
                    this.currentOffClickHandler = null;
                }
            },
            onSaving(e) {
                e.cancel = true;
                if (e.changes.length) {
                    console.log(e.changes);
                    //Deal with time in utc or dates
                    e.changes.forEach(change => {
                        if (change.data) {
                            var dataKeys = Object.keys(change.data);
                            dataKeys.forEach(key => {
                                //Done to send to backend correct data field, important dureing the update of the foreign key
                                if (key != _this.getOriginalDateFieldByColumnName(key)) {
                                    var item = change.data[key];

                                    var oldKey = key;
                                    var newKey = _this.getOriginalDateFieldByColumnName(key);
                                    Object.defineProperty(change.data, newKey, Object.getOwnPropertyDescriptor(change.data, oldKey));
                                    delete change.data[oldKey];
                                }

                                var col = _this.options.controlState.Columns.find(x => x.DataField === key);
                                if (col) {
                                    switch (col.EditType) {
                                        case EditType.Date:
                                        case EditType.GregorianDate:
                                        case EditType.ServerDateTime:
                                            if (change.data[key] && typeof change.data[key] === 'string') change.data[key] = change.data[key].replace('Z', '');
                                    }
                                }
                            });
                        }
                    });

                    console.log('Updated changed: ');
                    console.log(e.changes);

                    e.promise = _this.sendBatchRequest(`api/grid/de/inline/save`, e.changes).done(() => {
                        _this.options.rayControlChanged(_this.element, _this.options.controlState);
                        utils.message(i18n.$t("grid_control.saved"), 1);
                        e.component.cancelEditData();
                        e.component.refresh(true);
                    });
                }
            },
            remoteOperations: true,
            export: {
                enabled: true
            },
            onExporting(e) {
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Main');

                DevExpress.excelExporter.exportDataGrid({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                    customizeCell: function (options) {
                        if (options.gridCell.rowType === "data") {
                            var val = options.gridCell.value;
                            var dataField = options.gridCell.column.dataField;

                            if (_this.foreignKeyValues[dataField]) {
                                //If lookup is configured for this column, framework will transform data itself
                                var columnLookup = _this.gridEl.columnOption(dataField, 'lookup');
                                if (!columnLookup || !columnLookup.dataSource || columnLookup.dataSource.length == 0) {
                                    var fkPair = _this.foreignKeyValues[dataField].find(x => x.Value.toUpperCase() == val.toString().toUpperCase());
                                    if (fkPair) {
                                        options.excelCell.value = fkPair.Name;
                                    }
                                }
                            }

                            if (val.toString().toLowerCase() == 'true')
                                options.excelCell.value = i18n.$t("yes");
                            if (val.toString().toLowerCase() == 'false')
                                options.excelCell.value = i18n.$t("no");

                            var regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
                            if (regexForHTML.test(val)) {
                                var span = document.createElement('span');
                                span.innerHTML = val;
                                var spanStyle = $(span).find('style');
                                if (spanStyle) spanStyle.remove();
                                var newTextValue = span.textContent || span.innerText;
                                options.excelCell.value = newTextValue.trim();
                            }
                        }
                    } 
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        var caption = _this.options.controlState.InitCaption.replace(/ /g, "_");
                        if (!caption) {
                            caption = 'Grid';
                        }
                        var fileName = `${caption}_${utils.rayGuid()}.xlsx`;
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
                });
                e.cancel = true;
            },
            onContentReady(e) {
                _this.onDataBound();

                if (_this.pageSize != e.component.pageSize()) {
                    if (_this.pageSize === undefined) {
                        _this.pageSize = e.component.pageSize();
                    }
                    else {
                        _this.pageSize = e.component.pageSize();
                    }
                }
                //Hide whole pager
                if (e.component.pageSize() > _this.dataSource._totalCount && _this.defaultPageSize > _this.dataSource._totalCount) {
                    _this.element.find('.dx-pager').hide();
                } else {
                    _this.element.find('.dx-pager').show();
                }


                //Hide pagination when the number of items is lower than page size
                if (e.component.pageSize() > _this.dataSource._totalCount) {
                    //_this.gridEl.option("pager.visible", false);
                    _this.element.find('.dx-pages .dx-page-indexes').hide();
                    _this.gridEl.option('pager.showInfo', false);
                } else {
                    //_this.gridEl.option("pager.visible", true);
                    _this.element.find('.dx-pages .dx-page-indexes').show();
                    _this.gridEl.option('pager.showInfo', true);
                }

                if (_this.defaultPageSize > _this.dataSource._totalCount) {
                    _this.element.find('.dx-pager .dx-page-sizes').hide();
                } else {
                    _this.element.find('.dx-pager .dx-page-sizes').show();
                }



                var cols = _this.columns.filter(cl => cl.fixedColumnMode > 0);
                if (cols && cols.length > 0) {
                    for (let index = 0; index < cols.length; index++) {
                        var colIndex = _this.columns.findIndex(x => x.dataField === cols[index].dataField);

                        let savedStatus = window.localStorage.getItem(_this.currentLocalStorageKey);
                        if (!savedStatus) {
                            e.component.option("columns[" + colIndex + "].fixed", true);
                            e.component.option("columns[" + colIndex + "].fixedPosition", cols[index].fixedColumnMode == 1 ? "left" : "right");
                        }
                        
                    }
                }

                //Custom calcuulations for summary row
                var summaries = _this.summaryOptions;
                var data = _this.dataSource.items();
                if (summaries) {
                    for (const summary of summaries) {
                        var field = summary.column;
                        var type = summary.summaryType;

                        var values = data.map(a => parseFloat(a[field]) ? parseFloat(a[field]) : 0);
                        var count = values.length;
                        var sum = values.reduce((a, b) => a + b, 0);

                        var text;
                        switch (type) {
                            case 'sum':;
                                text = `${i18n.$t("grid_control.sum")}: ${sum.toLocaleString()}`;
                                break;
                            case 'avg':
                                var avg = sum * 1.0 / count;
                                text = `${i18n.$t("grid_control.avg")}: ${avg.toLocaleString()}`;
                                break;
                            default:
                                break;
                        }
                        var index = summaries.indexOf(summary);
                        var sumItem = _this.gridEl.element().find('.dx-datagrid-total-footer .dx-datagrid-summary-item').parent().eq(index);
                        if (sumItem) {
                            sumItem.find('.dx-datagrid-summary-item').text(text);
                        }
                    }
                }
                setTimeout(function () {
                    _this.gridEl.updateDimensions();
                }, 100);
                
            },
            onRowPrepared(e) {
                //Add unique id to each tr object
                if (e.rowType == "data") {
                    e.rowElement[0].setAttribute('data-uid', _this.getRowDataId(e.data));
                    //e.rowElement.attr('data-uid', e.data.Id)
                }

                ////Hide expand/collapse default column
                //e.rowElement.find("td.dx-command-expand").hide();
            },
            onCellPrepared(e) {
                if (e.rowType == "data") {
                    e.cellElement.attr('data-fieldName', e.column.name)
                    if (_this.requiredColumns.includes(e.column.name)) {
                        e.cellElement.addClass("inlineEditRequired");
                    }
                    if (_this.options.controlState.Behavior.Enabled && _this.options.controlState.EnableInlineEdit && e.column.allowEditing) {
                        e.cellElement.addClass("gridCellEditable");
                    }

                    var cols = _this.columns.filter(cl => cl.dataField && e.column.dataField == cl.dataField && cl.colorStyles && cl.colorStyles.length > 0);
                    if (cols && cols.length> 0) {
                        for (let csi = 0; csi < cols[0].colorStyles.length; csi++) {
                            const cstyle = cols[0].colorStyles[csi];
                            let isApplyStyle = false;

                            if (cstyle.Condition) {
                                isApplyStyle = _this.resolveCondition(e, cstyle.Condition, _this.columns, cols[0]);
                                //console.log("Check condition (row, column, cell value, style condition, function, result)", index, cidx, valueC, cstyle.Condition, f, isApplyStyle);
                            } else {
                                let value = e.values[e.columnIndex];
                                if (value != null) {
                                    var fkValues = _this.foreignKeyValues[e.column.dataField];
                                    if (fkValues && fkValues.length) {
                                        var fkValue = fkValues.find(x => x.Value == value);
                                        if (fkValue) {
                                            value = fkValue.Name;
                                        }
                                    }
                                    isApplyStyle = value.toString().toLowerCase().trim() === cstyle.Value.toString().toLowerCase().trim();
                                }
                                
                                //console.log("Check value (row, column, cell value, style value, result)", index, cidx, value, cstyle.Value, isApplyStyle);
                            }

                            if (isApplyStyle) {
                                if (cols[0].editType == EditType.CheckBox) {
                                    var tempClass = `tempCheckboxClass${utils.rayGuid()}`;
                                    e.cellElement.find('input').addClass(tempClass);

                                    var checkBoxCss = `.RayGrid tr.dx-row td input[type=checkbox].${tempClass}:after { background-color:${cstyle.ColorStr} !important; border-color:${cstyle.ColorStr} !important; }`;
                                    if(e.element.find('style').length == 0){
                                        e.element.prepend(document.createElement("style"));
                                    }
                                    var styleElem = e.element.find('style')[0];
                                    styleElem.innerHTML =  `${styleElem.innerHTML} ${checkBoxCss}`;
                                }
                                else {
                                    let css = `color: ${cstyle.ColorStr} !important;`;
                                    if (cstyle.IsBackgroundColor)
                                        css += `background-color:${cstyle.BackgroundColorStr} !important;`
                                    let ecss = e.cellElement[0].style.cssText;
                                    //if (ecss) console.log("-= CSS =-", ecss, `${css}${ecss}`);
                                    e.cellElement.css('cssText', `${css}${ecss}`);
                                }

                                if (cstyle.Icon) {
                                    let ic = ``;
                                    if (cstyle.IsIconHasOwnColor) { ic += `color: ${cstyle.IconColorStr} !important;`; }
                                    let s = `<span class='colorizedConditionalIcon' style="${ic}">
                                               <i class='${cstyle.Icon} fa-sm'></i>
                                            </span>`;

                                    if (cstyle.IsHideValue) {
                                        e.cellElement[0].innerHTML = s;
                                    } else {
                                        var elToPrependIconTo = e.cellElement;
                                        if (e.cellElement.find('.contentParent').length == 1) { elToPrependIconTo = e.cellElement.find('.contentParent').first(); }
                                        elToPrependIconTo.prepend(s);
                                    }
                                }
                                break;                                
                            }
                         }
                    }
                    //Remove master details icon
                    if (e.cellElement[0].classList.contains('dx-datagrid-expand'))
                        e.cellElement[0].classList.remove('dx-datagrid-expand');
                    if (e.cellElement[0].childNodes.length > 0 && e.cellElement[0].childNodes[0].classList && e.cellElement[0].childNodes[0].classList.contains('dx-datagrid-group-closed'))
                        e.cellElement[0].childNodes[0].classList.remove('dx-datagrid-group-closed');
                    if (e.cellElement.find('.dx-datagrid-group-opened'))
                        e.cellElement.find('.dx-datagrid-group-opened').remove();

                    if (e.cellElement.find('.richTextExpandButton')) {
                        var aTagExpand = e.cellElement.closest('tr').find('a.richTextExpandAction');
                        var aTagCollapse = e.cellElement.closest('tr').find('a.richTextCollapseAction');
                        aTagExpand.on("click", function () { e.component.expandRow(e.row.key); aTagExpand.hide(); aTagCollapse.show(); })
                        aTagCollapse.on("click", function () { e.component.collapseRow(e.row.key); aTagCollapse.hide(); aTagExpand.show(); })
                    }

                    var maxHeight = _this.options.controlState.MaxRowHeight;
                    if (maxHeight) {
                        e.cellElement.children().css('max-height', `${maxHeight}px`);
                        //If the max height is more than 16px, means more than 1 row of text, make minimum height 16, to not show scroll when there is only 1 line
                        if (maxHeight >= 16) //Content font size
                            e.cellElement.children().css('min-height', `16px`);
                    }
                    //Rich Text Popup
                    if (e.column.editType == 16) {
                        var popupBtn = e.cellElement.find('.grid-popup-button');
                        if (popupBtn) {
                            popupBtn.on("click", function (e) {
                                _this.showRichTextPopup(e);
                            });
                        }
                    }
                       
                }
                if (e.rowType == "header") {
                    var col = _this.options.controlState.Columns.find(item => item.DataField === e.column.name);
                    if (col) {
                        e.cellElement.css("text-align", col.TextAlignString).css("direction", col.TextDirectionString);
                        if (!_this.showVerticalLinesInHeader) {
                            e.cellElement.css("border-right", "0px").css("border-left", "0px");
                        }
                    }
                }
            },
            toolbar: { items: toolbarItems },
            //onToolbarPreparing(e) {
                //Customization of default toolbar buttons
                //e.toolbarOptions.items.forEach((item) => {
                //    //Add tooltip to export button
                //    if (item.name === 'exportButton') {
                //        item.options.hint = i18n.$t("grid_control.export_excel");
                //    }
                //});
            //},
            onEditorPreparing: function (e) {
                if (_this.notifSent) _this.notifSent = false;
                if (e.editType == EditType.DropDown && e.parentType === "dataRow" && e.editorOptions.dataSource) {
                    e.editorOptions.dataSource.paginate = true;
                    e.editorOptions.dataSource.pageSize = 20;
                }
                if (e.editType == EditType.Time) {
                    e.editorOptions.type = "time";
                    e.editorOptions.dateSerializationFormat = `${payload.currentLocale.ShortTimeFormatG_JS}`;
                }
                if (e.editType == EditType.CheckBox && e.alignment === "left") {
                    e.editorElement.addClass('checkboxAlignedToLeft')
                }
                if (e.parentType === "dataRow" && e.editType == EditType.CheckBox) {
                    e.editorOptions.value = e.value == null ? false : e.value;
                }
                if (e.dataType == "datetime" && !e.editorOptions.value) {
                    e.editorOptions.value = null;
                }

                _this.setSaveOnClickAway();

            },
            onEditCanceled: function (e) {
                $(window).unbind("click", this.currentOffClickHandler);
                this.currentOffClickHandler = null;
            },

        });
        _this.gridEl.on('rowDblClick', $.proxy(this.onGrid_DblClick, this));
        
        $(document).bind("keyup keydown", function (e) {
            if (e.ctrlKey && e.which == 83 && _this.gridInEditMode) {
                _this.gridEl.saveEditData();
            }
        });

        if (mdSettings.enabled) {
            _this.gridEl.element().addClass('GridHasMasterDetailColumn');
        }
        if (_this.options.controlState.MaxRowHeight) {
            _this.gridEl.element().addClass('maxRowHeightSet');
        }
    };

    RayGrid.prototype.sendBatchRequest = function(url, changes) {
        const d = $.Deferred();

        //Add grid parameters
        const params = {
            changes: changes,
            clientId: this.options.controlState.Id,
            pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            gridType: this.options.controlState.ObjectTypeString,
            bindingId: this.options.controlState.BindingId
        };

        $.ajax(url, {
            method: 'POST',
            data: JSON.stringify(params),
            cache: false,
            contentType: 'application/json',
            xhrFields: { withCredentials: true },
        }).done(d.resolve).fail((xhr) => {
            var errorMessage = xhr.statusText;
            if (xhr.responseJSON) {
                if (xhr.responseJSON.Message)
                    errorMessage = xhr.responseJSON.Message.replace(/<br\/>/gi, '\n');
                if (xhr.responseJSON.message)
                    errorMessage = xhr.responseJSON.message.replace(/<br\/>/gi, '\n');
            }
            d.reject(errorMessage);
        });

        return d.promise();
    }


    RayGrid.prototype.recalculateColumnsWidth = function () {
        var _this = this;
        if (!_this.columnRecalculated && !_this.localStorageContainsCurrentState) {
            const totalWidth = _this.element.width();
            var gridCols = _this.gridEl.option('columns');
            for (let _i = 0, _a = _this.options.controlState.Columns; _i < _a.length; _i++) {
                const col = _a[_i];

                var gridCol = gridCols.find(x => x.dataField == col.DataField);
                if (gridCol) {
                    var gridColIndex = gridCols.indexOf(gridCol);
                    var colWidth = col.Width;
                    if (_this.widthInProc) {
                        var newWidth = Math.floor(totalWidth * colWidth / 100);
                        if (newWidth < 30)
                            _this.gridEl.columnOption(gridColIndex, 'minWidth', newWidth);
                        else if (colWidth != newWidth)
                            _this.gridEl.columnOption(gridColIndex, 'width', newWidth);
                    }
                }
            }
            _this.gridEl.updateDimensions();
            _this.columnRecalculated = true;
        }
    };

    RayGrid.prototype.setState = function (controlState) {
        //console.log("Grid: setState", controlState);
        var that = this;
        this.gridState = GridState.None;
        this.options.controlState = controlState;
        this.options.controlState.Behavior.Visible ? this.element.show() : this.element.hide();
        this.options.controlState.Behavior.Visible ? this.element.parent().show() : this.element.parent().hide();
        //if (this.isInitialized && this.options.controlState.AutoGeneratedColumns) {
        //    this.options.controlState.Columns = this.AutoColumns;
        //}
        //if (!this.options.controlState.AutoGeneratedColumns)
        //    this.mapColumns();
        if (this.options.controlState.DoInit === true && this.isInitialized) {
            this.cachedData = null;
            const dataSource = this.dataSource;
            const totalPagesBeforeRead = that.gridEl.pageCount();
            dataSource.reload().then(function () {
                const totalPagesAfterRead = that.gridEl.pageCount();
                const curentPage = dataSource.pageIndex() + 1;
                console.log("Table pages", totalPagesBeforeRead, totalPagesAfterRead, curentPage);
                if (totalPagesBeforeRead !== totalPagesAfterRead && curentPage !== 1) {
                    dataSource.pageIndex(0);
                    dataSource.reload(); //necessary to change page
                    this.recalculateColumnsWidth();
                }
            });
        }
        if (this.options.controlState.Caption !== null) {
            this.element.find('.grid-title').text(this.options.controlState.Caption);
            $("label[for='" + this.options.controlState.Id + "']").text(this.options.controlState.Caption);
        }

        if (!this.options.controlState.EnableImport && !this.options.controlState.EnableExport) {
            this.gridEl.element().addClass('GridTopRightToolbarButtonNotNeeded');
        }

        this.toggleColumns();
        ////console.log("Grid: set state", this.options.controlState.Behavior.Visible, this.element, this.element.parent);
        this.isInitialized = true;
    };


    RayGrid.prototype.toggleColumns = function () {
        var _this = this;
        if (_this.gridEl === null) return;

        for (let _i = 0; _i < _this.gridEl.columnCount(); _i++) {
            var gridCol = _this.gridEl.columnOption(_i);

            //Show/hide row number column
            var showRowNumberColumn = !_this.options.controlState.HideRowNumber;
            if (gridCol.title == "GeneratedRowNumber" && gridCol.visible != showRowNumberColumn) {
                _this.gridEl.columnOption(_i, 'visible', showRowNumberColumn);
            }

            //Show/hide row actions column
            var showRowActionsColumn = _this.options.controlState.RemoveButton.Enabled || _this.options.controlState.EditButton.Enabled || _this.options.controlState.ViewButton.Enabled;
            if (gridCol.title == 'CustomActions' && gridCol.visible != showRowActionsColumn) {
                _this.gridEl.columnOption(_i, 'visible', showRowActionsColumn);
            }

            //Show/hide other columns
            var stateCol = _this.options.controlState.Columns.find(x => x.DataField == gridCol.name);
            if (stateCol && gridCol.visible != stateCol.Visible) {
                _this.gridEl.columnOption(_i, 'visible', stateCol.Visible);
            }
        }
    };


    RayGrid.prototype.requiredFieldValidator = function (obj) {
        //console.log("Grid: requiredFieldValidator");
        if (this.requiredFieldValidationMessage == null)
            this.requiredFieldValidationMessage = obj.message;
        if (this.gridState === GridState.None)
            obj.message = this.requiredFieldValidationMessage;
        else {
            obj.message = `${this.options.controlState.InitCaption} ${i18n.$t("grid_control.grid_is_in_editing_mode")}`;
            return false;
        }
        return this.dataSource.total() > 0;
    };

    RayGrid.prototype.initDataSource = function () {
        const _this = this;

        this.dataSource = new DataSource({
            load: function (options) {
                const deferred = $.Deferred();
                if (!_this.options.controlState.Behavior.Visible) {
                    deferred.resolve([], { totalCount: 0 });
                }
                else if (_this.cachedData != null)
                {
                    for (let index = 0; index < _this.cachedData.data.length; index++) {
                        _this.cachedData.data[index] = _this.transformOriginalDataFieldToField(_this.cachedData.data[index]);
                    }
                    const items = _this.cachedData;
                    _this.cachedData = null;
                    deferred.resolve(items, { totalCount: items.length });
                }
                else
                {
                     _this.readDevExpressData(options)
                        .done(function (resultData)
                        {
                            //When it is the first load of the grid, recalculate column sizes on the next databoud
                            if (_this.firstLoad) {
                                _this.columnRecalculated = false;
                                _this.firstLoad = false;
                            }

                            var res = resultData.d;
                            var loadResult = res.LoadResult;
                            for (let index = 0; index < loadResult.data.length; index++) {
                                loadResult.data[index] = _this.transformOriginalDataFieldToField(loadResult.data[index]);
                                loadResult.data[index].ManuallyGeneratedRowNumber = index + 1 + options.skip;

                                for (let field in _this.foreignKeyValues) {
                                    var val = loadResult.data[index][field];
                                    if (val) {
                                        if (!(typeof val === "string")) {
                                            val = JSON.stringify(val);
                                        }
                                        if (utils.isGuid(val)) {
                                            val = val.toUpperCase();
                                        }

                                    }
                                    loadResult.data[index][field] = val;
                                }

                            }

                            let needLoadFK = false;
                            for (let field in _this.foreignKeyValues) {
                                needLoadFK = true;
                                break;
                            }
                            if (needLoadFK) {
                                var loadAll = _this.options.controlState.EnableInlineEdit || _this.options.controlState.EnableInlineNew;
                                _this.readForeignKeys(loadResult.data, loadAll)
                                    .done(function (fkResult) {
                                        for (let key in _this.foreignKeyValues) {
                                            var currentArray = fkResult.d[_this.getOriginalDateFieldByColumnName(key)];
                                            if (currentArray && currentArray.length > 0) {
                                                _this.foreignKeyValues[key] = currentArray.concat(_this.foreignKeyValues[key]);
                                            }
                                            if (loadAll && _this.loadForeignValueColumns) {
                                                var colIndex = _this.gridEl.option('columns').indexOf(_this.gridEl.option('columns').find(x => x.dataField == key));
                                                var lookupObj = { dataSource: currentArray, displayExpr: 'Name', valueExpr: 'Value' };
                                                _this.gridEl.columnOption(colIndex, 'lookup', lookupObj);
                                            }
                                        }
                                        _this.loadForeignValueColumns = false;
                                        deferred.resolve(loadResult.data, {
                                            totalCount: loadResult.totalCount,
                                            groupCount: loadResult.groupCount,
                                        });

                                    })
                                    .fail(function (e) {
                                        deferred.reject(`Error while retrieving Foreign Key data ${_this.options.controlState.Id}`);
                                    });
                            }
                            else {
                                deferred.resolve(loadResult.data, {
                                    totalCount: loadResult.totalCount,
                                    groupCount: loadResult.groupCount,
                                });
                            }
                        })
                        .fail(function (e) { deferred.reject(`Error while reading data ${_this.options.controlState.Id}`); });
                }
                return deferred.promise();
            }
        });
        //const originalFilter = this.dataSource.filter;
        //this.dataSource.filter = function () {
        //    if (arguments.length > 0 && typeof arguments[0].filters !== "undefined" && arguments[0].filters.length > 0) {
        //        for (let i = 0; i < arguments[0].filters.length; i++) {
        //            const value = arguments[0].filters[i].value;
        //            if (typeof value !== "boolean" && typeof value !== "number")
        //                arguments[0].filters[i].value = value;
        //        }
        //    }
        //    if (arguments.length > 0)
        //        this.trigger("filtering", arguments);
        //    return originalFilter.apply(this, arguments);
        //};
        //return this.dataSource;
    };

    RayGrid.prototype.readDevExpressData = function (data) {
        var _this = this;
        let sortDirection = 'asc';
        let sortColumn = null;

        if (this.options.controlState.InitialSortDirectionString != null && this.options.controlState.InitialSortDirectionString != '') {
            sortDirection = this.options.controlState.InitialSortDirectionString.toLowerCase();
            sortColumn = this.options.controlState.InitialSortColumns;
        }

        if (data.filter != null && typeof data.filter !== 'undefined' && data.filter.length > 0) {
            _this.dealWithFilterBeforeReadData(data.filter);
        }
    
        if (data.sort != null && typeof data.sort !== 'undefined' && data.sort.length > 0) {
            for (let sort in data.sort)
                data.sort[sort].selector = this.getOriginalDateFieldByColumnName(data.sort[sort].selector);

            sortDirection = data.sort[0].desc ? 'desc' : 'asc';
            sortColumn = data.sort[0].selector; // this.getOriginalDateFieldByColumnName(data.sort[0].selector);
            this.sortDirection = sortDirection;
            this.sortColumn = sortColumn;
        }

        var columnTypes = this.options.controlState.Columns.map(function (x) { const { OriginDataField, DataType, EditType } = x; return { OriginDataField, DataType, EditType }; })
        const params = {
            bindingId: this.options.controlState.BindingId,
            clientId: this.options.controlState.Id,
            pageInstance: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            //filters: data.filter,
            loadOptions: data,
            sortDirection: sortDirection,
            sortIndex: sortColumn,//x.DataField
            columnTypes: columnTypes,
            isLoadingAll: data.isLoadingAll
        };
        const headers = {
            pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId
        };
        return Restful.post(_this.getReadDataSourceUrl(), params, headers, true);
    };

    RayGrid.prototype.dealWithFilterBeforeReadData = function (filter) {
        var _this = this;
        if (Array.isArray(filter[0])) {
            for (let f in filter) {
                if (Array.isArray(filter[f])) _this.dealWithFilterBeforeReadData(filter[f]);
            }
        }
        else {
            filter[0] = this.getOriginalDateFieldByColumnName(filter[0]);

            if (filter[1] == '=' && filter[2] == false) {
                filter[1] = '<>';
                filter[2] = true;
            }
        }
    };

    RayGrid.prototype.getReadDataSourceUrl = function () {
        return this.options.controlState.ObjectTypeString === "BaseInfo" ? "api/grid/de/getdata" : "api/grid/de/getdataM";
    };

    RayGrid.prototype.readForeignKeys = function (rows, loadAll) {
        //console.log("Grid: readForeignKeys");
        const dataToSubmit = [];
        for (let field in this.foreignKeyValues) {
            const item = {
                Column: this.getOriginalDateFieldByColumnName(field),
                Values: []
            };
            for (let i = 0; i < rows.length; i++)
                if (rows[i][field] != null && rows[i][field] !== '')
                    item.Values.push(rows[i][field]);
            dataToSubmit.push(item);
        }
        const params = {
            pageInstance: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            controlId: this.options.controlState.Id,
            values: dataToSubmit,
            loadAllValues: loadAll
        };
        return Restful.post('api/grid/kendo/getselected', params, { pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId }, true);
    };

    RayGrid.prototype.findColumnByName = function (name) {
        for (let _i = 0, _a = this.options.controlState.Columns; _i < _a.length; _i++) {
            const item = _a[_i];
            if (item.DataField === name)
                return item;
        }
        return null;
    };

    RayGrid.prototype.getTemplateForOneRichText = function (options, expandColumn) {
        var val = options.data[expandColumn.dataField];
        var newDiv = $('<div>').addClass('richTextBoxMasterDetail');
        newDiv.append($('<h2>').addClass('richTextBoxMasterDetailCaption').html(expandColumn.caption));
        newDiv.append($('<div>').addClass('richTextBoxMasterDetailContent').html(val));
        return newDiv;
    };

    RayGrid.prototype.templateForOneRichText = function (container, options, expandColumn) {
        var _this = this;
        _this.getTemplateForOneRichText(options, expandColumn).appendTo(container);
    };
    RayGrid.prototype.templateForMultipleRichText = function (container, options, expandColumns) {
        var _this = this;
        var items = [];
        expandColumns.forEach((expandColumn) => {
            if (options.data[expandColumn.dataField]) {
                items.push({
                    title: expandColumn.caption,
                    template: _this.getTemplateForOneRichText(options, expandColumn),
                });
            }
        });
        var firstLoad = true;
        var newDiv = $('<div>').addClass('richTextBoxMasterDetailTab');
        new TabPanel(newDiv, {
            items: items,
            height: 'auto',
            scrollByContent: true,
            showNavButtons: true,
            deferRendering: false,
            onContentReady: function (e) {

                //Reset items to the tab, in order to force tab panel to resize correctly and for scroll functionaility/nav buttons to appear when multiple tabs present and are hidden by screen size
                if (firstLoad) {
                    setTimeout(function () { 
                        e.component.option("items", e.component.option("items"));

                        var maxHeight = 0;
                        Array.from(e.element.find('.richTextBoxMasterDetail')).forEach((el) => {
                            if ($(el).height() > maxHeight) {
                                maxHeight = $(el).height();
                            }
                        });
                        var finalHeight = maxHeight + 24; //title margin
                        if (finalHeight > 300) {
                            finalHeight = 300; //max height
                            finalHeight = 300; //max height
                            e.element.find('.dx-multiview-item-container').css('overflow', 'auto');

                        }
                        e.element.find('.dx-multiview-item-container').height(finalHeight);
                        _this.gridEl.updateDimensions();
                        
                    }, 50);
                    firstLoad = false;
                }
            }
        });
        newDiv.appendTo(container);
    };


    RayGrid.prototype.mapColumns = function () {
        const _this = this;
        this.columns = [];
        this.fieldMaps["id"] = { type: 'PrimaryKey' };
        var colIndex = 0;
        // row number column
        this.columns.push({
            dataField: "ManuallyGeneratedRowNumber",
            title: "GeneratedRowNumber",
            caption: "#",
            dataType: 'number',
            width: '40',
            visible: !this.options.controlState.HideRowNumber,
            //visibleIndex: colIndex,
            allowSorting: false,
            allowResizing: false,
            allowReordering: false,
            allowSearch: false,
            allowEditing: false,
            allowExporting: false,
            allowFiltering: false,
            allowGrouping: false
        });
        colIndex++;
        var actionColumnButtons = [];
        var actionColumnButtonsWidth = 25;
        if (_this.options.controlState.RemoveButton.Enabled && _this.options.controlState.Behavior.Enabled) {
            
            actionColumnButtons.push({
                hint: i18n.$t('grid_control.del'),
                template: `<a href="#" style='margin-top: -5px;' class="dx-link dx-icon dx-link-icon" title="${i18n.$t('grid_control.del')}">
                                <span class="fa-trash-can fa-thin"></span>
                           </a>`,
                onClick(e) {
                    e.event.preventDefault();
                    _this.gridEl.deleteRow(e.row.rowIndex);
                    _this.setSaveOnClickAway();
                },
                visible(e) {
                    return !e.row.removed;
                }
            });
            actionColumnButtons.push({
                hint: i18n.$t('grid_control.undel'),
                template: `<a href="#" style='margin-top: -5px;' class="dx-link dx-icon dx-link-icon" title="${i18n.$t('grid_control.undel')}">
                                <span class="fa-rotate-left fa-thin"></span>
                           </a>`,
                onClick(e) {
                    e.event.preventDefault();
                    _this.gridEl.undeleteRow(e.row.rowIndex);
                    if (!_this.gridEl.hasEditData()) {
                        $(window).unbind("click", this.currentOffClickHandler);
                        this.currentOffClickHandler = null;
                    }
                },
                visible(e) {
                    return e.row.removed;
                }
            });
            actionColumnButtonsWidth += 25;
        }
        if (_this.options.controlState.EditButton.Enabled && _this.options.controlState.Behavior.Enabled) {
            actionColumnButtons.push({
                hint: i18n.$t('edit'),
                template: `<a href="#" style='margin-top: -5px;' class="dx-link dx-icon dx-link-icon" title="${i18n.$t('edit')}">
                                <span class="fa-pencil fa-thin"></span>
                           </a>`,
                onClick(e) {
                    e.event.preventDefault();
                    _this.onEditModal_Click(_this.getRowDataId(e.row.data), e.row.rowIndex);
                }
            });
            actionColumnButtonsWidth += 25;
        }
        if (_this.options.controlState.ViewButton.Enabled) {
            actionColumnButtons.push({
                hint: i18n.$t('grid_control.view'),
                template: `<a href="#" style='margin-top: -5px;' class="dx-link dx-icon dx-link-icon" title="${i18n.$t('grid_control.view')}">
                                <span class="fa-eye fa-thin"></span>
                           </a>`,
                onClick(e) {
                    e.event.preventDefault();
                    _this.onViewModal_Click(_this.getRowDataId(e.row.data));
                }
            });
            actionColumnButtonsWidth += 25;
        }
        this.columns.push({
            title: "CustomActions",
            caption: "",
            type: 'buttons',
            dataType: 'object',
            buttons: actionColumnButtons,
            width: actionColumnButtonsWidth,
            visible: actionColumnButtons && actionColumnButtons.length != 0,
            //visibleIndex: colIndex,
            allowSorting: false,
            allowResizing: false,
            allowReordering: false,
            allowSearch: false,
            allowEditing: false,
            allowExporting: false,
            allowFiltering: false,
            allowGrouping: false,
            fixed: false
        });
        colIndex++;


        this.widthInProc = this.options.controlState.WidthInPercents;
        for (let _i = 0, _a = this.options.controlState.Columns; _i < _a.length; _i++) {
            const col = _a[_i];
            if (col.DataType === 'Fake') continue;
            this.columnMap[col.OriginDataField] = col.DataField;
            const fieldType = this.dotNetTypeToJavaScriptType(col.EditType);
            //if (col.Visible)
            //    this.columnsWidth.push(col.Width);

            var currentWidth = col.Width;
            if (this.widthInProc) {
                currentWidth = $`${currentWidth}%`;
            } else {
                if (currentWidth < 30)
                    currentWidth = 30;
            }

            var columnDataType = _this.getDataType(col.EditType);

            var inlineValidations = [];
            if (col.Validations && col.Validations.length > 0) {

                for (var valIndex = 0; valIndex < col.Validations.length; valIndex++) {
                    var validation = col.Validations[valIndex];

                    //TypeEnum.RequiredFieldValidator
                    if (validation.ValidationType == 0) {
                        inlineValidations.push({ type: 'required', message: col.RFVMess });
                        _this.requiredColumns.push(col.DataField);
                    }

                    //TypeEnum.RangeValidator
                    if (validation.ValidationType == 1) {

                        var currentValidation;

                        if (columnDataType == 'date' || columnDataType == 'datetime') {

                            var format = '';
                            if (columnDataType == 'date') format = `${payload.currentLocale.ShortDateFormatG_JS}`;
                            if (columnDataType == 'datetime') format = `${payload.currentLocale.ShortDateFormatG_JS} ${payload.currentLocale.ShortTimeFormatG_JS}`;
                            debugger;
                            var min = '';
                            if (validation.MinimumValue) {
                                var minDate = utils.convertTicksToDate(validation.MinimumValue);
                                min = utils.formatDate(minDate, format);
                            }
                            var max = '';
                            if (validation.MaximumValue) {
                                var maxDate = utils.convertTicksToDate(validation.MaximumValue);
                                max = utils.formatDate(maxDate, format);
                            }
                            currentValidation = {
                                type: 'custom',
                                message: col.RVMess,
                                min: min,
                                max: max,
                                validationCallback: validateDateRange
                            }
                        }
                        else {
                            currentValidation = {
                                type: 'range',
                                message: col.RVMess,
                            }
                            if (validation.MinimumValue) {
                                currentValidation.min = validation.MinimumValue;
                            }
                            if (validation.MaximumValue) {
                                currentValidation.max = validation.MaximumValue;
                            }

                        }
                        inlineValidations.push(currentValidation);
                    }


                }
            }


            const column = {
                caption: col.HeaderText,
                dataField: col.DataField,
                dataType: columnDataType,
                format: null,
                visible: col.Visible,
                width: currentWidth,
                allowSorting: col.Sortable,
                allowResizing: col.Resizable,
                allowEditing: col.Editable,
                allowFiltering: col.EditType != EditType.DropDown,
                editType: col.EditType,
                cellTemplate: function (element, info) {
                    var el = _this.getTemplate(info, col);
                    if (col.editType == EditType.CheckBox && col.TextAlignString && col.TextAlignString.toLowerCase() == "left") {
                        el.addClass('checkboxAlignedToLeft');
                    }
                    element.append(el).css("text-align", col.TextAlignString).css("direction", col.TextDirectionString).css("vertical-align", "middle");
                },
                validationRules: inlineValidations,
                colorStyles: col.ColorStyles,
                fixedColumnMode: col.FixedColumnMode
            };
            var format = _this.getFormat(col.EditType);
            if (format) {
                column.format = format;
            }

            if (col.TextAlignString) {
                column.alignment = col.TextAlignString.toLowerCase();
            }

            if (columnDataType == "date") {
                column.dataFormat = `${payload.currentLocale.ShortDateFormatG_JS}`;
                column.dateSerializationFormat = `${payload.currentLocale.ShortDateFormatG_JS}`;
            }
            if (columnDataType == "datetime") {
                column.dataFormat = `${payload.currentLocale.ShortDateFormatG_JS} ${payload.currentLocale.ShortTimeFormatG_JS}`;
                column.dateSerializationFormat = `${payload.currentLocale.ShortDateFormatG_JS} ${payload.currentLocale.ShortTimeFormatG_JS}`;
            }
            if (col.EditType == EditType.Time) {
                column.dataFormat = `${payload.currentLocale.ShortTimeFormatG_JS}`;
                column.dateSerializationFormat = `${payload.currentLocale.ShortTimeFormatG_JS}`;
            }

            const field = {
                type: this.dotNetTypeToJavaScriptType(col.EditType),
                editable: this.isColumnEditable(col),
                validation: null
            };
            if (col.RFVMess != null)
                field.validation = { reqiored: true };
            this.fieldMaps[col.DataField] = field;
            if (col.EditType == EditType.DropDown)
                this.foreignKeyValues[col.DataField] = [];
            this.columns.push(column);
        }
    };


    function validateDateRange(e) {
        var value = e.value;
        //if value is not entered, skip it, because its the work of required rule not range validation
        if (!value) return true;
        var min, max;
        if (e.rule) {
            min = e.rule.min;
            max = e.rule.max;
        }
        var valid = true;

        if (min) {
            var minDate = new Date(min);
            if (minDate > value)
                valid = false;
        }
        if (max) {
            var maxDate = new Date(max);
            if (value > maxDate)
                valid = false;
        }
        return valid;
    }

    RayGrid.prototype.showRichTextPopup = function (e) {
        e.preventDefault();

        var field = $(e.currentTarget).attr("data-field");
        var header = field;
        for (var i = 0; i < this.columns.length; i++) {
            if (this.columns[i].dataField == field)
                header = this.columns[i].caption;
        }

        var rowEl = $(e.currentTarget).closest("tr");
        var rowId = rowEl[0].dataset.uid;
        var rows = this.dataSource._items.filter(x => x.id == rowId);

        if (rows && rows.length == 1) {
            var row = rows[0];

            var text = row[field];
            const el = document.createElement('div');
            el.classList.add('richTextPopupContent');
            $(el).html(text);

            $("<div></div>").kendoWindow({
                title: header,
                width: "90%",
                resizable: false,
                modal: true,
            }).data("kendoWindow").content(el).center().open();
        }
    }
    RayGrid.prototype.getRowDataId = function (data) {
        return data[Object.keys(data).find(key => key.toLowerCase() === 'id')];
    }
    RayGrid.prototype.isColumnEditable = function (column) {
        return column.Editable && column.EditType !== EditType.None;
    };

    RayGrid.prototype.getDataType = function (type) {
        switch (type) {
            case EditType.CheckBox:
                return "boolean";
            case EditType.Numeric:
            case EditType.Numeric1:
            case EditType.Numeric2:
            case EditType.Numeric3:
                return "number";
            case EditType.DateBaseOnCulture:
            case EditType.Date:
            case EditType.GregorianDate:
                return "date";
            case EditType.Time:
            case EditType.GregorianDateTime:
            case EditType.ServerDateTime:
                return "datetime";
            case EditType.DropDown:
            case EditType.TextBox:
            case EditType.RichTextScroll:
            case EditType.RichTextExpand:
            case EditType.RichTextPopup:
            case EditType.RichTextTooltip:
            default:
                return "string";
        }
    };
    RayGrid.prototype.getFormat = function (type) {
        switch (type) {
            case EditType.DateBaseOnCulture:
            case EditType.Date:
            case EditType.GregorianDate:
                return `${payload.currentLocale.ShortDateFormatG_JS}`;
            case EditType.Time:
                return `${payload.currentLocale.ShortTimeFormatG_JS}`;
            case EditType.GregorianDateTime:
            case EditType.ServerDateTime:
                return `${payload.currentLocale.ShortDateFormatG_JS} ${payload.currentLocale.ShortTimeFormatG_JS}`;
            case EditType.Numeric:
                return { type: "fixedPoint", precision: 0 };
            case EditType.Numeric1:
                return { type: "fixedPoint", precision: 1 };
            case EditType.Numeric2:
                return { type: "fixedPoint", precision: 2 };
            case EditType.Numeric3:
                return { type: "fixedPoint", precision: 3 };
            default:
                return "";
        }
    };

    RayGrid.prototype.getTemplate = function (info, column) {
        var _this = this;
        var val = info.text;

        if ((info.value || info.value === 0) && column.EditType == EditType.DropDown) {
            val = info.value;
            if (!(typeof val === "string"))
                val = JSON.stringify(val);
            if (this.foreignKeyValues[column.DataField]) {
                for (let _i = 0, _a = _this.foreignKeyValues[column.DataField]; _i < _a.length; _i++) {
                    const fk = _a[_i];
                    if (fk.Value == val || fk.Value.toLowerCase() == val.toLowerCase())
                        val = fk.Name;
                }
            }
        }
        switch (column.EditType) {
            case EditType.CheckBox:
                var chkAlignmentClass = '';
                switch (column.TextAlignString.toLowerCase()) {
                    case 'left': chkAlignmentClass = 'chkInGridToLeft'; break;
                    case 'right': chkAlignmentClass = 'chkInGridToRight'; break;
                    case 'center': chkAlignmentClass = 'chkInGridToMiddle'; break;
                    case 'unset':
                        if (_this.options.controlState.RightToLeft) {
                            chkAlignmentClass = 'chkInGridToRight';
                        } else {
                            chkAlignmentClass = 'chkInGridToLeft';
                        }
                        break;
                    default: break;
                }
                var checked = info.value == true || val == 'Yes' || val == 'true'|| val == 'True' ? 'checked="checked"' : '';
                return `<input class='checkboxInGrid ${chkAlignmentClass}' type="checkbox" ${checked}  onclick="return false;" ></input>`;
            case EditType.RichTextScroll:
                return `<div style="overflow:auto; white-space:normal;"><div class='contentParent' style="display:table-cell; vertical-align:middle;">${val}</div></div>`;
            case EditType.RichTextExpand:
                var buttonsToExpandCollapse = '';
                if (val) {
                    buttonsToExpandCollapse = `<a class='richTextExpandAction' href='javascript:void(0)'>(Click to expand)</a>
                                               <a class='richTextCollapseAction' style='display: none;' href='javascript:void(0)'>(Click to collapse)</a>`;
                }
                return `<div class='richTextExpandButton' style="overflow:hidden; white-space:normal;"><div class='contentParent' style="display:table-cell; vertical-align:middle;">${buttonsToExpandCollapse}</div></div>`;
            case EditType.RichTextPopup:
                var eyeIcon = `<a style="position:absolute; right:0; margin-top:-1px;" class="k-button k-button-view grid-popup-button" data-field="${column.DataField}"><i class="fa-light fa-up-right-and-down-left-from-center"></i></a>`;
                if (!val) eyeIcon = '';
                return `<div style="position:relative">${eyeIcon}<div class='contentParent' style="overflow:hidden; white-space:normal; margin-right:30px;">${val}</div></div>`;
            case EditType.RichTextTooltip:
                return `<div class="tooltip-item" style="overflow:hidden; white-space:normal;"><div class='contentParent' style="display:table-cell; vertical-align:middle;">${val}</div></div>`;
            default:
                return `<div style="overflow:auto; white-space:normal; padding:3px;"><div class='contentParent' style="vertical-align:middle;">${val}</div></div>`;
        }
    };

    RayGrid.prototype.getValidator = function (column) {
        let validator = '';
        if (column.RFVMess != null)
            validator = `required="required" data-required-msg='${column.RFVMess}'`;
        if (column.RVMess != null)
            validator += `data-min-value='${column.RVMin}' data-max-value='${column.RVMax}' data-range-msg='${column.RVMess}'`;
        return validator;
    };

    RayGrid.prototype.dotNetTypeToJavaScriptType = function (type) {
        switch (type) {
            case EditType.CheckBox:
                return "boolean";
            case EditType.Numeric:
            case EditType.Numeric1:
            case EditType.Numeric2:
            case EditType.Numeric3:
                return "number";
            default:
                return "string";
        }
    };

    RayGrid.prototype.getOriginalDateFieldByColumnName = function (columnName) {
        for (let key in this.columnMap)
            if (this.columnMap[key] === columnName)
                return key;
        return null;
    };

    RayGrid.prototype.getDataFieldByOriginalDataField = function (originalDataField) {
        const column = this.columnMap[originalDataField];
        return typeof column === "undefined" ? null : column;
    };

    RayGrid.prototype.getDataTypeByDataField = function (field) {
        for (let col in this.columns)
            if (this.columns[col].field === field)
                return this.columns[col].dataType;
        return null;
    };

    RayGrid.prototype.transformOriginalDataFieldToField = function (row) {
        const newRow = {};
        for (let key in row)
            if (key.toLowerCase() !== 'id')
                newRow[this.getDataFieldByOriginalDataField(key)] = row[key];
            else
                newRow[key] = row[key];
        return newRow;
    };

    RayGrid.prototype.getSelectedRowId = function () {
        const selectedItem = this.gridEl.getSelectedRowsData()[0];
        return selectedItem == null ? null : selectedItem.id;
    };

   

    RayGrid.prototype.showModal = function (action, modaldiv) {
        //console.log("Grid: showModal");
        if (modaldiv === void 0) { modaldiv = null; }
        let nestedData = null;
        switch (action) {
            case EnumGridNestedAction.New:
                nestedData = JSON.parse(this.options.controlState.AddButton.Parameters);
                nestedData.savebuttonTitle = i18n.$t('save_and_return');
                nestedData.cancelbuttonTitle = i18n.$t('cancel_nested');
                nestedData.gridEditor = true;
                nestedData.editorMode = "add";
                nestedData.gridId = this.options.controlState.Id;
                break;
            case EnumGridNestedAction.Edit:
                nestedData = JSON.parse(this.options.controlState.EditButton.Parameters);
                nestedData.savebuttonTitle = i18n.$t('save_and_return');
                nestedData.cancelbuttonTitle = i18n.$t('cancel_nested');
                nestedData.gridEditor = true;
                nestedData.editorMode = "edit";
                //In this moment grid can be loading, get the number of visible rows 
                nestedData.totalRowCount = this.gridEl.getVisibleRows().length;
                nestedData.gridId = this.options.controlState.Id;
                break;
            case EnumGridNestedAction.View:
                nestedData = JSON.parse(this.options.controlState.ViewButton.Parameters);
                nestedData.editorMode = "view";
                break;
            default:
                console.warn(`${action[action]} doesn't need modal dialogs.`);
                return;
        }
        if (modaldiv == null)
            modaldiv = $('<div id="modalDialog" />');
        const params = {
            nested: nestedData,
            parentBpmsAppForm: this.options.bpmsAppForm
        };
        modaldiv.bpmsAppForm(params);
    };

    RayGrid.prototype.newTargetFormObject = function () {
        let self = this;
        let deferred = $.Deferred();
        let params = {
            clientId: this.options.controlState.Id,
            pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId
        };

        utils.callWebAPIp("api/grid/newobject", params, 'POST', true)
            .done(function () { deferred.resolve(); })
            .fail(function (e, status, thrownError) {
                utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                deferred.reject();
            });
        return deferred.promise();
    };
       
    RayGrid.prototype.editTargetFormObject = function (selectedRowId) {
        if (selectedRowId === void 0)
            selectedRowId = null;
        let self = this;
        let deferred = $.Deferred();
        let params = {
            selectedRowKey: selectedRowId == null ? this.getSelectedRowId() : selectedRowId,
            clientId: this.options.controlState.Id,
            pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            bindingId: self.options.controlState.BindingId
        };

        utils.callWebAPIp("api/grid/activateobject", params, 'POST', true)
            .done(function () { deferred.resolve(); })
            .fail(function () { deferred.reject(); });
        return deferred.promise();
    };

    RayGrid.prototype.viewTargetFormObject = function (selectedRowId) {
        if (selectedRowId === void 0)
            selectedRowId = null;
        const self = this;
        const deferred = $.Deferred();
        const params = {
            selectedRowKey: selectedRowId == null ? this.getSelectedRowId() : selectedRowId,
            clientId: this.options.controlState.Id,
            pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            bindingId: self.options.controlState.BindingId
        };

        utils.callWebAPIp("api/grid/activateobject", params, 'POST', true)
            .done(function () { deferred.resolve(); })
            .fail(function (e) { deferred.reject(); });
        return deferred.promise();
    };

    RayGrid.prototype.attachRichTextTooltipHandlers = function () {
        try {
            $(this.element).kendoTooltip({
                filter: ".tooltip-item",
                position: "bottom",
                showAfter: 500,
                //autoHide: false,
                //content: function (e) { return `<div style='width: 90%;'>${e.target.html()}</div>`; },
                //content: kendo.template("<div style='width: 90%;'>#= target.html() #</div>"),
                content: kendo.template("#= target.html() #"),
                width: 600,
                callout: false,
            });
        }
        catch (e) { }
    };

    RayGrid.prototype.onDataBound = function (e) {
        this.attachRichTextTooltipHandlers();
        if (this.navigateModeinProgress == true)
            this.navigate();
        this.recalculateColumnsWidth();
    };

    RayGrid.prototype.resolveCondition = function (e, condition, columns, col) {
        var _this = this;
        var conditionWorkingCopy = condition;
        //replace columns
        const regexp = /col\[([^\[]+)\]|val\[([^\[]+)\]/g;
        var matches = condition.matchAll(regexp);
        for (const match of matches) {
            var isSelectValue = match[0].startsWith("val");
            var field = isSelectValue ? match[2] : match[1];
            let valueC = "";
            if (isSelectValue)
                valueC = `'${_this.getSelectValueOfColumnByField(e,columns, field)}'`;
            else {            
                var cols = columns.filter(cl => cl.dataField && cl.dataField == field );
                if (cols && cols.length > 0) {
                    var fieldValue = _this.getCellValue(e, cols[0]);
                    valueC = fieldValue.Value;
                    if (fieldValue.Type === 1)
                        valueC = `'${fieldValue.Value}'`;
                }
            
            }
            conditionWorkingCopy = conditionWorkingCopy.replace(match[0], valueC);
        }

        if (conditionWorkingCopy.indexOf("value") != -1) {
            var fieldValue = _this.getCellValue(e,col);
            let valueC = fieldValue.Value;
            if (fieldValue.Type === 1)
                valueC = `'${fieldValue.Value}'`;
            conditionWorkingCopy = conditionWorkingCopy.replace(/value/gi, valueC);
        }

        let f = `return ${conditionWorkingCopy.replace(/=/g, '==').replace(/>==/g, '>=')
            .replace(/<==/g, '<=').replace(/and/gi, '&&').replace(/or/gi, '||')};`;

        let cf = new Function(f);
        return cf.call(this);
    };

    RayGrid.prototype.getValueOfColumnByField = function(e, field) {
        var cell = e.row.cells.filter(cl => cl.column.dataField === field);

        var value = '';
        if (cell && cell.length>0) {
            value = e.values[cell[0].columnIndex];
        }
        
        return value;
    };

    RayGrid.prototype.getSelectValueOfColumnByField = function (e, columns, field) {
        var col = columns.find(x => x.dataField == field);
        if (!col) return '';
        var cell = e.row.cells.filter(cl => cl.column.dataField === field);

        var value = '';
        if (cell && cell.length > 0) {
            value = e.values[cell[0].column.index];
        }
        if (col.editType != EditType.DropDown)
            return value;
        
        var retVal = '';
        let value1 = this.foreignKeyValues[col.dataField].find(el => el.Value.toString().toLowerCase().trim() === value.toString().toLowerCase().trim());
        if (value1)
            retVal = value1.Value;
        return retVal;
    };

    RayGrid.prototype.getCellValue = function(e, col) {
        var _this = this;
        if (!col.dataField) return;
        let value = _this.getValueOfColumnByField(e, col.dataField);

        let vtype = 0;
        if (col.editType == EditType.Date
            || col.editType == EditType.GregorianDate
            || col.editType == EditType.DateBaseOnCulture
            || col.editType == EditType.ServerDateTime) {
            value = utils.formatDate(value || ''.toString().replace('Z', ''), 'yyyy-MM-dd');
            vtype = 1;
        }
        else if (col.editType == EditType.GregorianDateTime) {
            value = utils.formatDate(value, 'yyyy-MM-dd');
            vtype = 1;
        }
        else if (col.editType == EditType.DropDown) {
            let value1 = this.foreignKeyValues[col.dataField].find(el => el.Value.toString().toLowerCase().trim() === value.toString().toLowerCase().trim());
            if (value1)
                value = value1.Name;
            else
                value = '';
            vtype = 1;
        }
        else if (col.editType == EditType.TextBox)
            vtype = 1;
        else if (col.editType == EditType.CheckBox)
            value = value.toString().toLowerCase();

        if (value == null || value.toString().length === 0)
            value = '0';

        return { Value: value, Type: vtype };
    };

    RayGrid.prototype.onGrid_DblClick = function (e) {
        //console.log("Grid: onGrid_DblClick", this.options.controlState, this.options.bpmsAppForm.options.nested);
        const _this = this;
        //e.preventDefault();

        if ($(e.event.target).parents(".dx-master-detail-row").length) {
            //double click inside opened master details row, do nothing
            return;
        }

        if (this.gridState !== GridState.None && !$(e.target).hasClass('k-input') && $(e.target).closest(".k-numerictextbox").length === 0) {
            this.onInlineSave_Click(e);
            return;
        }
        var data = e.data;
        if (this.gridState != GridState.None || data == null)
            return;
        //const row = $(e.target).closest('[role=row]');
        //if (row.length === 0 || typeof row.data('uid') === 'undefined')
        //    return;
        if (this.options.controlState.CanSelect && this.options.bpmsAppForm.options.nested) {
            const params = {
                selectedRowKey: _this.getRowDataId(data),
                dropDownClientId: this.options.bpmsAppForm.options.nested.Id,
                gridId: this.options.controlState.Id,
                pageInstance: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                parentPageInstanceId: this.options.bpmsAppForm.dto.formState.ParentPageInstanceId,
                bindingId: this.options.controlState.BindingId
            };
            utils.callWebService("WebServices/jqGridService.asmx", "SelectRayAutoCompleteDropDownItem", params, true)
                .done(function () {
                    _this.options.bpmsAppForm.close(true);
                }).fail(function (ex, status, thrownError) {
                    utils.showAjaxError(ex, status, thrownError, _this.options.controlState.Id, false);
                });
        }
        else if (this.options.controlState.EditButton.Enabled && this.options.controlState.Behavior.Enabled)
            this.onEditModal_Click(_this.getRowDataId(data), e.rowIndex);
        else if (this.options.controlState.ViewButton.Enabled)
            this.onViewModal_Click(_this.getRowDataId(data));
    };

    RayGrid.prototype.setSaveOnClickAway = function () {
        const _this = this;

        //Save reference to handler so we can unbind on save
        _this.currentOffClickHandler = function () {
            $(_this.element).unbind("click");
            //_this.element.find(".inline-save").click();
            _this.gridEl.saveEditData();

            $(window).unbind("click", this.currentOffClickHandler);
            _this.currentOffClickHandler = null;
        };
        //set handler click in window 
        $(window).bind("click", _this.currentOffClickHandler);
        //but don't do it when clicking on grid
        $(_this.element).click(function (event) {
            event.stopPropagation();
        });
        //nor on any devexpress popups
        $(".dx-popup-wrapper").die("click");
        $(".dx-popup-wrapper").live("click", function (event) {
            event.stopPropagation();
        });
    };
    RayGrid.prototype.onAddModal_Click = function (e) {
        const _this = this;
        e.preventDefault();
        if (this.gridState != GridState.None) {
            utils.showMessage(i18n.$t("grid_control.grid_is_in_editing_mode_you_cannot_do_anything"), 2500, "warning");
            return;
        }
        this.options.bpmsAppForm
            .saveFormObject()
            .done(function () {
                _this.newTargetFormObject()
                    .done(function () {
                        _this.showModal(EnumGridNestedAction.New);
                    });
            });
    };
    RayGrid.prototype.onViewModal_Click = function (rowId) {
        const _this = this
        if (this.gridState != GridState.None) {
            utils.showMessage(i18n.$t("grid_control.grid_is_in_editing_mode_you_cannot_do_anything"), 2500, "warning");
            return;
        }
        if (!rowId) {
            utils.showMessage(i18n.$t("grid_control.no_rows_selected"), 1500, "warning");
            return;
        }
        this.options.bpmsAppForm
            .saveFormObject()
            .done(function () {
                _this.viewTargetFormObject(rowId)
                    .done(function () {
                        _this.showModal(EnumGridNestedAction.View);
                    });
            });
    };

    RayGrid.prototype.onEditModal_Click = function (rowId, rowIndex) {
        if (this.gridState != GridState.None) {
            utils.showMessage(i18n.$t("grid_control.grid_is_in_editing_mode_you_cannot_do_anything"), 2500, "warning");
            return;
        }
        if (!rowId) {
            utils.showMessage(i18n.$t("grid_control.no_rows_selected"), 1500, "warning");
            return;
        }
        // current index for modal navigation
        this.currentRowIndex = rowIndex;
        this.showEditModal(rowId);
    };

    RayGrid.prototype.showEditModal = function (rowId, dialog) {
        const _this = this;
        if (rowId === void 0) { rowId = null; }
        if (dialog === void 0) { dialog = null; }
        this.options.bpmsAppForm
            .saveFormObject()
            .done(function () {
                _this.editTargetFormObject(rowId)
                    .done(function () {
                        _this.showModal(EnumGridNestedAction.Edit, dialog);
                    });
            });
    };
    RayGrid.prototype.getPageIndex = function (index) {
        const page = Math.floor(index / this.dataSource.pageSize());
        return index = index - (page * this.dataSource.pageSize());
    };

    RayGrid.prototype.navigate = function (dialog) {
        //console.log("Grid: navigate");
        if (dialog === void 0) { dialog = null; }
        this.navigateModeinProgress = false;
        //const row = this.dataSource.at(this.getPageIndex(this.currentRowIndex));
        const rowId = this.gridEl.getVisibleRows()[this.currentRowIndex].key.id;
        this.showEditModal(rowId, dialog == null ? this.currentModalDialog : dialog);
    };
    RayGrid.prototype.modalCancelRow = function (dialogform, reload, closeDialog) {
        if (typeof closeDialog !== "undefined")
            closeDialog = true;
        const rowId = this.getSelectedRowId();
        if (dialogform && closeDialog) {
            dialogform.dialog('close');
            dialogform.dialog('destroy');
        }
    };
    RayGrid.prototype.goNewMode = function (dialog) {
        const _this = this;
        this.options.bpmsAppForm
            .saveFormObject()
            .done(function () {
                _this.newTargetFormObject()
                    .done(function () {
                        _this.showModal(EnumGridNestedAction.New, dialog);
                    });
            });
    };

    RayGrid.prototype.goNextRow = function (dialog) {
        this.currentModalDialog = dialog;
        const nextRowIndex = this.currentRowIndex + 1;
        const nextRowPage = Math.floor(nextRowIndex / this.dataSource.pageSize()) + 1;
        if (nextRowIndex >= this.dataSource.totalCount()) {
            this.navigateModeinProgress = false;
            return false;
        }
        if (this.dataSource.pageIndex() + 1 == nextRowPage) {
            this.navigateModeinProgress = true;
            this.currentRowIndex = nextRowIndex;
            this.navigate(dialog);
            return true;
        }
        if (this.dataSource.pageIndex() + 1 < nextRowPage && nextRowPage <= this.gridEl.pageCount()) {
            this.navigateModeinProgress = true;
            this.currentRowIndex = nextRowIndex;
            //Next page
            //Rework
            //this.dataSource.navigateToRow(nextRowPage - 1); // index instead of number
            //this.currentRowIndex = 0;
            return true;
        }
        if (nextRowPage < this.gridEl.pageCount()) {
            this.navigateModeinProgress = false;
            return false;
        }
    };

    RayGrid.prototype.checkVisibilityOfNextButton = function () {
        if (this.currentRowIndex == this.gridEl.getVisibleRows().length - 1)
            return false;
        else
            return true;
    };
    RayGrid.prototype.checkVisibilityOfPrevButton = function () {
        if (this.currentRowIndex == 0)
            return false;
        else
            return true;
    };

    RayGrid.prototype.goPrevRow = function (dialog) {
        this.currentModalDialog = dialog;
        const prevRowIndex = this.currentRowIndex - 1;
        const prevRowPage = Math.floor(prevRowIndex / this.dataSource.pageSize()) + 1;
        if (prevRowIndex < 0) {
            this.navigateModeinProgress = false;
            return false;
        }
        if (this.dataSource.pageIndex() + 1 == prevRowPage) {
            this.navigateModeinProgress = true;
            this.currentRowIndex = prevRowIndex;
            this.navigate(dialog);
            return true;
        }
        if (this.dataSource.pageIndex() + 1 > prevRowPage && prevRowPage >= 0) {
            this.navigateModeinProgress = true;
            this.currentRowIndex = prevRowIndex;
            //Previous page
            //Rework
            //this.dataSource.navigateToRow(prevRowPage - 1); // index instead of number
            //this.currentRowIndex = this.dataSource.pageSize() - 1; //last index of full last page
            return true;
        }
    };

    RayGrid.prototype.canGoNext = function () {
        const nextvRowIndex = this.currentRowIndex + 1;
        return nextvRowIndex < this.dataSource.totalCount();
    };

    RayGrid.prototype.canGoPrev = function () {
        const prevRowIndex = this.currentRowIndex - 1;
        return prevRowIndex >= 0;
    };

    RayGrid.prototype.getValue = function () {
        return this.getControlValue();
    };

    RayGrid.prototype.getControlValue = function () {
        return this.getSelectedRowId();
    };

    RayGrid.prototype.booleanDropDownFilterEditor = function (args) {
        let items = [
            { name: i18n.$t('submit'), value: true },
            { name: i18n.$t('cancel'), value: false }
        ];
        args.element.kendoDropDownList({
            dataSource: items,
            dataTextField: "name",
            dataValueField: "value",
            valuePrimitive: true
        });
    };

    RayGrid.prototype.booleanDropDownFilterEditorUI = function (input) {
        input.element.kendoDropDownList({
            dataSource: {
                data: [
                    { text: i18n.$t('submit'), value: 'true' },
                    { text: i18n.$t('cancel'), value: 'false' }
                ]
            },
            dataTextField: "text",
            dataValueField: "value",
            valuePrimitive: true,
            optionLabel: "All"
        });
    };
    return RayGrid;
}());

(function ($) {
    $.widget('ui.rayGrid', $.ui.rayControl, {
        _grid: RayGrid,
        validators: [],
        _create: function () {
            this.validators = [];
            this._grid = new RayGrid(this.options, this.element);
            if (this.options.controlState.MinHeight)
                this.element[0].style.minHeight = `${this.options.controlState.MinHeight}px`;
            if (this.options.controlState.Height)
                this.element[0].style.maxHeight = `${this.options.controlState.Height}px`;
        },
        cancelRow: function (dialogform, reload, closeDialog) {
            this._grid.modalCancelRow(dialogform, reload, closeDialog);
        },
        setState: function (cs) {
            this._grid.setState(cs);
            this.setHelp(cs);
        },
        requiredFieldValidator: function (validator) {
            return this._grid.requiredFieldValidator(validator);
        },
        goNewMode: function (dialog) {
            this._grid.goNewMode(dialog);
        },
        goNextRow: function (dialog) {
            return this._grid.goNextRow(dialog);
        },
        checkVisibilityOfNextButton: function () {
            return this._grid.checkVisibilityOfNextButton();
        },
        checkVisibilityOfPrevButton: function () {
            return this._grid.checkVisibilityOfPrevButton();
        },
        goPrevRow: function (dialog) {
            return this._grid.goPrevRow(dialog);
        },
        canGoNext: function () {
            return this._grid.canGoNext();
        },
        canGoPrev: function () {
            return this._grid.canGoPrev();
        },
        getValue: function () {
            return this._grid.getValue();
        },
        getControlValue: function () {
            return this._grid.getControlValue();
        },
        getCaptionElement: function () {
            return this.element.parent().find('label.localizable');
        }
    });
})(jQuery);
(function (EnumGridNestedAction) {
    EnumGridNestedAction[EnumGridNestedAction["View"] = 0] = "View";
    EnumGridNestedAction[EnumGridNestedAction["New"] = 1] = "New";
    EnumGridNestedAction[EnumGridNestedAction["Edit"] = 2] = "Edit";
    EnumGridNestedAction[EnumGridNestedAction["Delete"] = 3] = "Delete";
})(EnumGridNestedAction || (EnumGridNestedAction = {}));
GridConstants.TopToolToolBarTemplate = '';
GridConstants.FooterToolbarTemplate = '';
GridConstants.ErrorTemplate = '';
(function (GridState) {
    GridState[GridState["None"] = 0] = "None";
    GridState[GridState["NewInline"] = 1] = "NewInline";
    GridState[GridState["EditInline"] = 2] = "EditInline";
})(GridState || (GridState = {}));
let SortDirection;
(function (SortDirection) {
    SortDirection[SortDirection["Asc"] = 0] = "Asc";
    SortDirection[SortDirection["Desc"] = 1] = "Desc";
})(SortDirection || (SortDirection = {}));
let TextDirection;
(function (TextDirection) {
    TextDirection[TextDirection["ltr"] = 0] = "ltr";
    TextDirection[TextDirection["rtl"] = 1] = "rtl";
})(TextDirection || (TextDirection = {}));
let TextAlign;
(function (TextAlign) {
    TextAlign[TextAlign["Left"] = 0] = "Left";
    TextAlign[TextAlign["Center"] = 1] = "Center";
    TextAlign[TextAlign["Right"] = 2] = "Right";
})(TextAlign || (TextAlign = {}));
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["TextBox"] = 1] = "TextBox";
    EditType[EditType["CheckBox"] = 2] = "CheckBox";
    EditType[EditType["Numeric"] = 3] = "Numeric";
    EditType[EditType["Date"] = 4] = "Date";
    EditType[EditType["Time"] = 5] = "Time";
    EditType[EditType["Numeric1"] = 6] = "Numeric1";
    EditType[EditType["Numeric2"] = 7] = "Numeric2";
    EditType[EditType["Numeric3"] = 8] = "Numeric3";
    EditType[EditType["DropDown"] = 9] = "DropDown";
    EditType[EditType["GregorianDate"] = 10] = "GregorianDate";
    EditType[EditType["GregorianDateTime"] = 11] = "GregorianDateTime";
    EditType[EditType["DateBaseOnCulture"] = 12] = "DateBaseOnCulture";
    EditType[EditType["ServerDateTime"] = 13] = "ServerDateTime";
    EditType[EditType["RichTextScroll"] = 14] = "RichTextScroll";
    EditType[EditType["RichTextExpand"] = 15] = "RichTextExpand";
    EditType[EditType["RichTextPopup"] = 16] = "RichTextPopup";
    EditType[EditType["RichTextTooltip"] = 17] = "RichTextTooltip";
})(EditType || (EditType = {}));
(function (Aggregate) {
    Aggregate[Aggregate["None"] = 0] = "None";
    Aggregate[Aggregate["Sum"] = 1] = "Sum";
    Aggregate[Aggregate["Avg"] = 2] = "Avg";
})(Aggregate || (Aggregate = {}));

