import loading from '../widgets/loading/loading.vue';
import utils from 'jsf/utils.js';

export default {
    data() {
        return {
            max: 6,
            attachments: [],
            receivers: []
        }
    },

    components: {
        loading
    },

    mounted() {
        this.init();
    },

    methods: {
        getPrincipalTitle() {
            const that = this;

            utils.callWebAPI("api/principal/principals", null,
                function (result) {
                    const $input = $('.cb-users');
                    $input.typeahead({
                        source: function (query, process) {
                            return process(result, true);
                        },

                        displayText: function (item) {
                            //console.log('user', item);
                            return item.id === "!#more#" ? item.name : `[${item.typeName}] ${item.name}`;
                        },

                        autoSelect: true,
                        items: that.max,

                        afterSelect: function (e) {
                            if (e.id === '!#more#') {
                                that.max += 10;
                                $input.typeahead('changeMax', that.max);
                                $input.typeahead('lookup', $input.val() || '').focus();
                                return;
                            }
                            const usr = $(".cb-users").typeahead('getActive');
                            if (usr != null) {
                                let notExists = true;
                                $(that.receivers).each(function () {
                                    if (this.id === usr.id) {
                                        notExists = false;
                                        return false;
                                    }
                                });

                                if (notExists === false)
                                    utils.showMessage(i18n.$t("message_management.messages.repeated_recipient"), 3000, 'information');

                                if (notExists === true) {
                                    that.receivers.push(usr);
                                    const container = $(".message-receivers");
                                    const div = $("<div class='pull-left'></div>");
                                    const deletebutton = $("<button class='btn btnDelete btn-xs' style='background-color:transparent;' id='" + usr.id + "'> <i class='fad fa-2x fa-trash'></i> </button>");
                                    const title = $(" <span class='message-receivers-user-name'>" + usr.name + "</span>");
                                    $(deletebutton).unbind('click').bind('click', function () {
                                        const parent = $(this).parent();
                                        const aid = $(this).attr("id");
                                        $(parent).remove();

                                        if (aid != null) {
                                            $(that.receivers).each(function () {
                                                if (this.id === aid) {
                                                    const index = that.receivers.indexOf(this);
                                                    that.receivers.splice(index, 1);
                                                    return false;
                                                }
                                            });
                                        }
                                    });
                                    div.append(title);
                                    div.append(deletebutton);
                                    container.append(div);
                                }
                            }
                        }
                    });
                    $('.btn-users').click(function () {
                        $input.typeahead('lookup', '').focus();
                    });
                });
        },

        init() {
            const that = this;
            const page = "Views/SendMessage.html";
            const $uiForm = $(this.$el).children('div:first');
            $uiForm.empty();
            config.currentViewCanLeave(true);

            utils.showModalHtml(page)
                .done(function (html) {
                    $uiForm.append(html);
                    $uiForm.css("display", "block");
                    new legacyComponent({ i18n: vueI18n }).$mount($uiForm[0]);
                    that.getPrincipalTitle();

                    $(".upload-file").unbind('click').bind('click', function (e) {
                        e.preventDefault();
                        $(".uploader").click();
                    });

                    $(".uploader").unbind('change').bind('change', function (e) {
                        e.preventDefault();
                        const reader = new FileReader();
                        const myId = utils.rayGuid();
                        let fileType = '';
                        let fileName = '';
                        reader.onload = function (e) {
                            console.log(e.target.result);
                            const attach = { id: myId, file: e.target.result.split(',')[1], name: fileName, mimeType: fileType };
                            that.attachments.push(attach);
                            const p = $("<div class='pull-left'></div>");
                            const deletebutton = $("<button class='btn btnDelete btn-xs' style='background-color:transparent;' id='" + myId + "'> <i class='fad fa-2x fa-trash'></i> </button>");
                            const downloadInput = $(" <span class='attachments-item'>" + fileName + "</span>");
                            $(deletebutton).unbind('click').bind('click', function () {
                                const parent = $(this).parent();
                                const aid = $(this).attr("id");
                                $(parent).remove();
                                if (aid != null) {
                                    $(that.attachments).each(function () {
                                        if (this.id === aid) {
                                            const index = that.attachments.indexOf(this);
                                            that.attachments.splice(index, 1);
                                            return false;
                                        }
                                    });
                                }
                            });
                            $(downloadInput).unbind('click').bind('click', function () { });
                            const container = $(".message-attachments");
                            p.append(downloadInput);
                            p.append(deletebutton);
                            container.append(p);
                            $(".uploader").val('');
                        }
                        fileType = this.files[0].type;
                        reader.readAsDataURL(this.files[0]);
                        fileName = this.files[0].name;
                    });

                    $("#sendSMS").unbind('change').bind('change', function () {
                        if ($(this).is(':checked'))
                            $('#counter').show();
                        else
                            $('#counter').hide();
                    });

                    $(".BodyText").off('input').on('input', function (e) {
                        //console.log("text changed", $(".BodyText").val(), $(".BodyText").text());
                        const q = $(".BodyText").val().length;
                        $('#counter').text(q + '/1600');
                    });

                    $(".message-btn-submit").unbind('click').bind('click', function (e) {
                        e.preventDefault();
                        if ($(".Title").val() == null || $(".Title").val() == '') {
                            utils.messagePD('message_management.messages.title_is_empty', 2);
                        //    utils.confirm(i18n.$t("message_management.messages.title_is_empty"), {
                        //        yes: function () { send(); },
                        //        no: function () { }
                        //    });
                        }
                        else
                            send();
                    });

                    $(".cb-users").unbind('click');

                    function send() {
                        let replyID = null;
                        if (page === "Views/ReplyMessage.html") {
                            replyID = $("#messageId").text();
                            that.receivers.push({ id: $(".current-msg").attr("senderId"), name: $("#msgFrom").text() });
                        }
                        if (that.receivers.length > 0) {
                            const obj = {
                                Attachments: that.attachments,
                                Receivers: that.receivers,
                                Title: $(".Title").val(),
                                Body: $(".BodyText").val(),
                                ReplyTo: replyID,
                                IsSendEmail: $("#sendEmail").is(':checked'),
                                IsSendSMS: $("#sendSMS").is(':checked')
                        };
                            utils.callWebAPIp("api/message/send", obj, "POST", true)
                                .done(function () {
                                    that.attachments = [];
                                    that.receivers = [];
                                    router.push({ path: "/message-grid/true" });
                                    utils.showMessage(i18n.$t("message_management.messages.success_send"), 5000, 'success');
                                }).fail(function (e) {
                                    console.error(e);
                                });
                        } else
                            utils.showMessage(i18n.$t("message_management.messages.unselected_recipient"), 5000, 'default');
                    }
                });
            $(".view").hide();
        }
    }
}
