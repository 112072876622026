<template>
    <modal v-bind:showModal="showModal" data-backdrop="false" v-bind:size="{'my-col':true,'col-lg-6':true}">
        <div slot="header">
            <label>{{$t("assign")}}</label>
        </div>
        <div slot="body">
            <div class="row">
                <div class="col-sm-6">
                    <div id="ca-management" class="form-group">
                        <label>{{$t("task_assign.recipients")}}</label>
                        <div id="pacPrincipals" style="width: 300px; height: 100px;"></div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="radio">
                        <label>
                            <input type="checkbox" v-model="chkDesc" @change="changeDesc" />{{$t("task_assign.last_dsc")}}
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="checkbox" v-model="chkDeleteTask" />{{$t("task_assign.out_work")}}
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="checkbox" v-model="chkKeepSender" />{{$t("task_assign.keep_sender")}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>{{$t("task_assign.description")}}</label>
                        <textarea cols="20"
                                  rows="2"
                                  class="form-control"
                                  v-model="txtDesc"
                                  v-bind:disabled="chkDesc"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer">

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div v-if="errors.length" class="form-validate-summary alert alert-danger">
                        <ul id="appform-validationsummary">
                            <li :style="{textAlign : (direction ? 'right' : 'left')}" v-for="e in errors">{{e}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="col-sm-4 col-sm-offset-4">
                <button type="button" class="btn btn-primary btn-block" @click="save">{{$t("send")}}</button>
            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from "../widgets/modal/modal.vue";

    export default {
        props: [
            'showTaskAssign',
            'modalData'
        ],
        watch: {
        },
        data() {
            return {
                direction: payload.currentLocale.IsRightToLeft,
                txtDesc: "",
                chkDesc: false,
                chkDeleteTask: false,
                chkKeepSender: false,
                errors: [],
                taskInstanceIds: null,
                folderId: null
            }
        },
        methods: {
            init() {
                $('#pacPrincipals').baseautocomplete('clearSelectedItems');
                this.taskInstanceIds = this.modalData.ids;
                this.folderId = this.modalData.folderId;
                this.errors = [];
                this.txtDesc = "";
                this.chkDesc = false;
                this.chkDeleteTask = false;
                this.chkKeepSender = false;
            },
            changeDesc() {
                if (this.chkDesc)
                    this.txtDesc = "";
            },
            save() {
                if (this.validation()) {

                    let url = "api/tasks/set-assignment";

                    var principalIds = $('#pacPrincipals').baseautocomplete("getSelectedIds");

                    var model = {};
                    model.principals = principalIds;
                    model.description = this.txtDesc;
                    model.useOldDesc = this.chkDesc;
                    model.deleteTask = this.chkDeleteTask;
                    model.keepSender = this.chkKeepSender;
                    model.taskInstanceIds = Array.isArray(this.taskInstanceIds) ? this.taskInstanceIds : [this.taskInstanceIds];
                    model.folderId = this.folderId;

                    let callback = (result) => {
                        $('.close', this.$el).click();
                        this.modalData.callback();
                    };

                    var that = this;

                    this.doAjaxRequest(url,
                        JSON.stringify(model),
                        callback,
                        function (data) {

                            for (var i = 0; i < data.length; i++) {
                                var item = data[i];
                                for (var j = 0; j < item.Errors.length; j++) {
                                    var errorItem = item.Errors[j];
                                    that.errors.push(i18n.$t(errorItem.ErrorMessage));
                                }
                            }
                        },
                        'POST');
                }
            },
            validation() {
                this.errors = [];
                var result = true;

                var principalIds = $('#pacPrincipals').baseautocomplete("getSelectedIds");

                if (principalIds === null || typeof principalIds === "undefined" || principalIds.length === 0) {
                    this.errors.push(i18n.$t("task_assign.messages.unselected_recipient"));
                    result = false;
                }

                return result;
            },
            doAjaxRequest(url, data, onSuccess, onFailure, method) {
                let ajaxConfig = {
                    url: url,
                    data: data,
                    dataType: "json",

                    contentType: "application/json;charset=utf-8"
                };
                if (method) {
                    ajaxConfig.method = method;
                }
                $.ajax(ajaxConfig)
                    .done((result) => {
                        if (typeof onSuccess === 'function')
                            onSuccess(result);
                    }).error((r) => {
                        if (typeof onFailure === 'function') {

                            var response = null;

                            if (r.status == 400) {

                                response = JSON.parse(r.responseText);
                                onFailure(response);
                            }
                        }
                    });
            },
        },
        computed: {
            showModal() {
                this.init();
                return this.showTaskAssign;
            }
        },
        mounted() {
            var $pacprincipals = $('#pacPrincipals');

            this.doAjaxRequest(
                "api/tasks/get-principals", {},
                (data) => {
                    $pacprincipals.baseautocomplete({
                        source: data,
                        FAiconList: ['fad fa-user', 'fad fa-thin fa-users', 'fad fa-thin fa-users-class'],
                        async: false
                    });

                    $("#pacPrincipals_list").css("overflow-y", "auto");
                });

            //$pacprincipals.baseautocomplete({
            //    source: "api/tasks/get-principals",
            //    FAiconList: ['fad fa-user', 'fad fa-users', 'fad fa-users-class'],
            //    async: false
            //});

            //$("#pacPrincipals_list").css("overflow-y", "auto");

        },
        components: {
            Modal
        }
    }

</script>
