<template>
    <div id="app">
        <!--load banner-->
        <banner v-if="isBanner && !isPlugin"></banner>

        <div class="wrapper pull-right">

            <!--load navbar-->
            <navbar container="container" v-if="!(isPlugin || isHideMainMenu)">

                <li class="js_loginPanel" v-if="enterLogin" v-on:click.stop.prevent="preventPanel()" slot="leftNavItems">
                    <a href="#" class="dropdown-toggle" v-on:click="togglePanel($event)" v-bind:title='$t("login")'>
                        <div style="display: inline-block; font-size: 16px"><i class="fa-light fa-lg fa-right-to-bracket mr5"></i></div>
                        {{$t('sign_in')}}
                        <span class="caret"></span>
                    </a>

                    <login></login>
                </li>
            </navbar>

            <div class="container wrapper-padding">
                <!--load pages here-->
                <router-view></router-view>
            </div>

            <div class="scroll-top" style="display: table;">
                <div style="margin: auto; display: table-cell; vertical-align: middle; text-align: center;"><i class="fad fa-2x fa-chevron-up"></i></div>
            </div>

            <!--<footer v-bind:class="{hasHomeData: 'public-footer'}" v-if="!isPlugin && showFooter" style="position:fixed;bottom:-15px;width:100%">
        <small>
            <ul class="horizontal" style="margin-bottom: 0">
                <li><a :href="$t('footer.url')" target="_blank"><i class="fad fa-lg fa-copyright"></i> {{$t('footer.rights')}}</a></li>
            </ul>
            <ul class="horizontal">
                <li><i class="fad fa-lg fa-info-square"></i> {{$t('footer.powered_by_raybpms')}} {{version}}</li>
                <li><a :href="$t('footer.help_url')" target="_blank"><i class="fad fa-lg fa-question-square"></i> {{$t('footer.help')}}</a></li>
            </ul>
        </small>
    </footer>-->
        </div>
    </div>
</template>

<script>
    import Modal from './component/widgets/modal/modal.vue';
    import Banner from './component/banner/banner.vue';
    import Navbar from './component/navbar/navbar.vue';
    import Login from './component/login/login.vue';

    import { App } from './assets/ts/global';
    import Restfull from './assets/ts/restful'
    import themes from "devextreme/ui/themes";

    const app = new App();

    export default {
        data() {
            return {
                isBanner: payload.isBanner,
                bodyBackground: payload.bodyBackground,
                hasHomeData: payload.announcementItems.length > 0 && payload.newsItems.length > 0,
                isPlugin: payload.isPlugin,
                isHideMainMenu: payload.isHideMainMenu,
                isHideFormHeader: payload.isHideFormHeader,
                isHideFormToolbar: payload.isHideFormToolbar,
                isHideAll: payload.isHideAll,
                version: payload.version,
                isEnterLogin: true,
            }
        },
        computed: {
            //showFooter() {
            //    return !payload.hideFooter
            //},
            enterLogin() {
                //console.log("public", this.$route.params);
                var val = !payload.useIDP || (typeof (this.$route.params.startparam) != 'undefined' && this.$route.params.startparam == "directlogin");
                this.isEnterLogin = val;
                return val;
            }
        },
        mounted() {
            // apply theme and force default
            //if (!localStorage.dbpTheme)
            localStorage.dbpTheme = 'material.blue.light';
            themes.current(localStorage.dbpTheme);

            //when user logs out he should not be allowed to click back button. 
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', function () {
                history.pushState(null, null, document.URL);
            });


            // load ts file
            app.loadMethods();

            $(window).on('click', (e) => {
                if (e.button === 0)
                    this.closePanel();

            });

            $('.navbar-right > li:not(.js_loginPanel)').on('click', (e) => {
                if (e.button === 0)
                    this.closePanel();
            })

            //If sso is enabled, redirect to authenticate without waiting for user click
            if (!this.isEnterLogin) {
                this.ssoLogin();
            }
        },
        methods: {
            togglePanel() {
                $('.js_loginPanel').toggleClass('open');
            },
            closePanel() {
                $('.js_loginPanel').removeClass('open');
            },
            preventPanel() {
                $('.navbar-right > li:not(.js_loginPanel)').removeClass('open')
            },
            ssoLogin: function () {
                Restfull.get('Authentication/LoginAutoSSO', {}).done((ar) =>
                    window.location.href = ar.link + "?RelayState=" + encodeURIComponent(ar.RelayState) + "&SAMLRequest=" + encodeURIComponent(ar.SAMLRequest));
            }
        },
        components: {
            Banner,
            Modal,
            Navbar,
            Login
        }
    }

</script>
