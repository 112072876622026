import utils from 'jsf/utils.js';

export function MessageForm() {
    const destroyAllNestedForms = function (bpmAppForm) {
        if (!bpmAppForm)
            return;

        bpmAppForm.closeAllNestedForm();
    };
    const canLeave = function () {
        // TODO: check form dirty and then confirm to exit
        const result = true;
        if (result)
            destroyAllNestedForms(currentBpmsAppForm());

        return result;
    };
    const self = this;
    const validationSummaryId = "#appform-validationsummary";
    let _currentBpmsAppForm = null;
    const activate = function (params) {
        // if bpms opens in eoffice as a plugin , hide header and tree
        utils.hideHeaderAndTreeFromShell();

        config.currentViewCanLeave(canLeave);
        $('.view').hide();
        $('#contentmain').show();
        const $appForm = params.element;
        const messageId = params.messageId;

        utils.callWebAPIp("api/message/get", {MessageId: messageId})
            .done(function (data) {
                const msg = data.d;
                $appForm.load("StaticViews/ViewMessage.html", function () {
                    $appForm.show();

                    utils.callWebAPIp("api/message/getparent", {ReplyTo: data.d.ReplyTo})
                        .done(function (result) {
                            const container = $("#ParentMessages");

                            $(result.d).each(function () {
                                const parentmsg = this;
                                utils.callWebAPIp("api/message/attachments", {MessageId: this.Id})
                                    .done(function (attachs) {
                                        const rowdiv = $("<div class='row parent-message-row'></div>");
                                        const header = $("<div class='row message-header'></div>");
                                        const headcontent = $("<div class='message-header-content'></div>");
                                        const divx = $("<div></div>");
                                        const divy = $(`<div id='${parentmsg.Id}' class='message-header-body msgBody'>${parentmsg.Body.slice(0, 140)} ...<button type='button' class='btn btn-primary pull-left btn-sm btnReply' style='margin-top:5px; padding:5px; margin-right:3px; margin-Bottom:5px;font-size:12px'>${i18n.$t("show")}</button></div>`);
                                        const subjectdiv = $("<div class='col-md-7'></div>");
                                        const subject = $(`<span class='message-subject'>${parentmsg.Subject}</span>`);
                                        const infodiv = $("<div class='col-md-5'></div>");
                                        const infox = $("<div class='col-md-6' serverid=Column></div>");
                                        const infoy = $("<div class='col-md-6' serverid=Column1></div>");
                                        const sendby1 = $(`<span style='font-size:11px'>${i18n.$t("message_management.sended_by")}</span>`);
                                        const sendby2 = $(`<span style='font-size:11px'>${parentmsg.SenderTitle}</span>`);
                                        infox.append(sendby1);
                                        infox.append(sendby2);
                                        const datelabel = $(`<span style='font-size:11px;'>${i18n.$t("message_management.send_date")}</span>`);
                                        const date = $(`<span style='font-size:11px'>${parentmsg.CreatedAt}</span>`);
                                        infoy.append(datelabel);
                                        infoy.append(date);
                                        infodiv.append(infox);
                                        infodiv.append(infoy);
                                        subjectdiv.append(subject);
                                        divx.append(subjectdiv);
                                        divx.append(infodiv);
                                        headcontent.append(divx);
                                        const br1 = $("<br/>");
                                        const br2 = $("<br/>");
                                        headcontent.append(br1);
                                        headcontent.append(br2);
                                        headcontent.append(divy);
                                        header.append(headcontent);
                                        rowdiv.append(header);

                                        const msgcontent = $(`<div class='hide message-content' id='msg_${parentmsg.Id}'></div>`);
                                        const textdiv = $("<div class='col-md-9 message-text-content'></div>");
                                        const contentx = $("<div class='col-md-9 message-text-content'></div>");
                                        const contenty = $("<div class='col-md-3 message-attach-content'></div>");
                                        const textcontent = $("<div class='message-text-div'></div>");
                                        const text = $(`<textarea rows='20' ReadOnly='ReadOnly' class='form-control' value=''>${parentmsg.Body}</textarea>`);
                                        textcontent.append(text);
                                        contentx.append(textcontent);
                                        const div1 = $("<div class='message-attach'></div>");
                                        const div2 = $("<div class='panel panel-Gray'></div>");
                                        const div3 = $(`<div class='panel-heading panel-heading-Gray'><i class='fad fa-paperclip'></i> ${i18n.$t("message_management.attachment")}</div>`);
                                        const div4 = $("<div class='panel-body'></div>");
                                        const div5 = $("<div class='row' style='margin:1px'></div>");
                                        const div6 = $("<div class='form-group'></div>");
                                        const div7 = $("<div class='row message-attach-row'></div >");
                                        $(attachs.d).each(function () {
                                            const div = $("<div></div>");
                                            const br = $("<br/>");
                                            const downloadInput = $(` <a href='api/file/getfile?outputType=attachment&imageId=${this.Id}' style='margin:0; padding:0' class='pull-right'>${this.Name}</span>`);
                                            div.append(downloadInput);
                                            div7.append(div);
                                            div7.append(br);
                                        });
                                        div6.append(div7);
                                        div5.append(div6);
                                        div4.append(div5);
                                        div3.append(div4);
                                        div2.append(div3);
                                        div1.append(div2);
                                        contenty.append(div1);
                                        msgcontent.append(contentx);
                                        msgcontent.append(contenty);
                                        rowdiv.append(msgcontent);

                                        container.append(rowdiv);
                                        $(".msgBody").click(function () {
                                            const id = $(this).attr('id');
                                            if ($(`#msg_${id}`).hasClass("hide"))
                                                $(`#msg_${id}`).removeClass("hide");
                                        });

                                    });
                            });
                            $("#messageId").html(messageId);
                            $(".current-msg").attr("senderId", msg.Sender);
                            $("#msgSubject").html(msg.Subject);
                            $("#msgDate").html(msg.CreatedAt);
                            $("#msgBody").html(msg.Body);
                            $("#msgFrom").html(msg.SenderTitle);

                            const sendedMessage = $(`<span for='Body' style='font-weight: bold'> ${i18n.$t("message_management.sended")}</span>`);
                            const receivedMessage = $(`<span for='Body' style='font-weight: bold'> ${i18n.$t("message_management.received")}</span>`);
                            if (msg.IsSentMessage) {
                                $("#messageState").html(sendedMessage);
                            }
                            else {
                                $("#messageState").html(receivedMessage);
                            }

                        });
                    new legacyComponent({i18n: vueI18n}).$mount($appForm[0]);
                });

                utils.callWebAPIp("api/message/attachments", {MessageId: messageId})
                    .done(function (data) {
                        const container = $("#MessageAttachments");
                        const align = payload.currentLocale.IsRightToLeft ? 'padding: 0 5px;' : 'float: left;padding: 0 5px;';
                        $(data.d).each(function () {
                            const div = $("<div></div>");
                            div.attr('style', align);
                            const br = $("<br/>");
                            const downloadInput = $(` <a href='api/file/getfile?outputType=attachment&imageId=${this.Id}' style='margin:0; padding:0' class='pull-right' >${this.Name}</span>`);
                            div.append(downloadInput);
                            container.append(div);
                            container.append(br);
                        });

                        utils.callWebAPIp("api/message/count")
                            .done(function (data) {
                                const messageCount = data.d;
                                if (messageCount == 0)
                                    $('.messageCount').removeClass("label-danger").text("");
                                else
                                    $('.messageCount').addClass("label-danger").text(messageCount);
                            });
                    });
                // comment it if signal is on

            });


    };
    const currentBpmsAppForm = function (form) {
        if (form)
            _currentBpmsAppForm = form;
        else
            return _currentBpmsAppForm;
    };


    return {
        pageInstanceId: function () {
            //call utils.callWebService from taskgrid & currentbpmsappform is null
            if (_currentBpmsAppForm == null)
                return null;
            return _currentBpmsAppForm.dto.formState.PageInstanceId;
        },
        activate: activate,
        validationSummaryId: validationSummaryId,
        currentBpmsAppForm: currentBpmsAppForm
    };
}

