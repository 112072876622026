var localeMessages = {
    user: 'Utilisateur',
    staff: 'personnel',
    save_successfully: 'Sauvegardé avec succès.',
    error_saving: "Erreur d'enregistrement",
    help: 'Aide',
    ok: 'Ok',
    send_as_staff: 'Envoyer en tant que personnel',
    remove_record: "Enlever l'enregistrement",
    homepage: "Page d'accueil",
    view_form: 'Visualiser le formulaire',
    confirm: 'Confirmer',
    prev_row: 'Ligne précédente',
    next_row: 'Prochaine ligne',
    save_and_new: 'Sauvegarder et nouveau',
    save_and_next: 'Enregistrer et suivant',
    save_and_previous: 'Enregistrer et précédent',
    save_and_return: 'Sauvegarder et retourner',
    cancel_and_return: 'Annuler et retourner',
    submit_form: 'Soumettre le formulaire',
    return: 'Retourner',
    save_form: 'Sauvegarder le formulaire',
    login: 'Ouvrir une session',
    sign_in: 'Se connecter',
    username: 'Utilisateur',
    password: 'Mot de passe',
    date: 'Date',
    author: 'Auteur',
    more_details: 'Afficher plus de détails',
    show: 'Afficher',
    close: 'Fermer',
    open: 'Ouvrir',
    languages: 'Langues',
    try_again: 'Essayer de nouveau',
    tracking: 'Suivi',
    tracking_code: 'Numéro de suivi',
    history: 'Historique',
    process_level: 'Niveau de processus: <strong>{level}</strong>',
    loading: 'Chargement en cours',
    uploading: 'Télécharger',
    save: 'Sauvegarder',
    remove: 'Enlever',
    attention: '* Attention:',
    select: 'Sélectionner',
    please: 'SVP',
    doing: 'En cours',
    upload_doing: 'Téléchargement et enregistrement en cours',
    page_refresh: 'Pour voir les modifications, actualisez la mémoire tampon (Ctrl-F5).',
    send: 'Envoyer',
    insert: 'Insérer',
    edit: 'Modifier',
    delete: 'Supprimer',
    reply: 'Répondre',
    are_you_sure: 'En êtes-vous sûr?',
    control: 'Contrôle',
    show_error_details: "Afficher les détails de l'erreur",
    warning: 'Avertissement',
    search: 'Recherche',
    empty: 'Vide',
    new: 'Nouveau',
    priority: 'Priorité',
    priority_low: 'Bas',
    priority_normal: 'Normal',
    priority_high: 'Haut',
    priority_very_high: 'Très haut',
    subject: 'Sujet',
    created_date: 'Date de création',
    response_deadline: 'Délai de réponse',
    done_date: 'Terminer le',
    sender: 'Expéditeur',
    starter_user: 'Utilisateur débutant',
    starter_staff: 'Utilsateur personnel',
    is_assignable: 'Est assignable',
    insert_label: "Insérer l'étiquette",
    continue: 'Continuer',
    submit: 'Soumettre',
    cancel: 'Annuler',
    cancel_nested: 'Annuler',
    other: 'Autre',
    settings: 'Paramètres',
    change_theme: 'Thèmes',
    message: 'Message',
    messages: {
        not_any_record_found: "L'enregistrement n'a pas été trouvé.",
        not_history_for_tracking_code_found: 'Aucun historique trouvé pour le code de suivi.',
        not_selected_principals: "Aucun dossier d'entités n'est sélectionné renommer le dossier.",
        write_dont_work_folder_name: 'Veuillez sélectionner un nom pour le dossier tâches à effectuer.',
        write_done_work_folder_name: 'Veuillez sélectionner un nom pour le dossier tâches terminées.',
        write_dont_send_request_folder_name:
            'Veuillez sélectionner un nom pour le dossier projet en cours.',
        save_attntion: 'Êtes-vous sûr de vouloir continuer?',
        changing_name: 'Changement de nom en cours...',
        sucsses_message: 'Les modifications sont enregistrées avec succès.',
        file_volume: 'Taille du fichier',
        file_upload: 'Ne devrait pas dépasser 300 kb.',
        file_type: 'Télécharger un fichier au format jpg, png ou gif.',
        delete_sucsses: 'Supprimé avec succès',
        reset_confirm: "Êtes-vous sûr de vouloir réinitialiser l'image d'arrière-plan par défaut?",
        doing_sucsses: 'Terminé avec succès',
        file_type_attention: 'Seuls les fichiers jpg, png ou gif sont pris en charge.',
        delete_confirm_massage: 'Êtes-vous sûr de vouloir supprimer?',
        your_request_has_been_sent: 'Votre demande a été envoyée avec succès.',
        confirm_message: 'Êtes-vous certain de vouloir faire ce changement?',
        save_successfully: 'Enregistré avec succès.',
        password_not_currect: "Nom d'utilisateur ou mot de passe incorrect.",
        connection_not_safe: "La connexion n'est pas sécurisée.",
        pleas_try_later: 'Veuillez réessayer.',
        username_or_password_is_null: "Nom d'utilisateur ou mot de passe incorrect.",
        control_behavior_not_exists:
            "Il n'existe aucun comportement de formulaire pour cet élément de formulaire."
    },
    apply: 'Appliquer',
    all_messages: 'Tous les messages',
    new_message: 'Nouveau message',
    messages_sent: 'Messages envoyés',
    messages_receive: 'Messages reçus',
    messages_receive_notification: 'Vous avez un nouveau message!',
    sign_out: 'Déconnexion',
    change_password: 'Changer le mot de passe',
    'first-page': 'Première page',
    'view-form': 'Visualiser formulaire',
    invalid_date: 'Date invalide',
    operation_faild: "L'opération a échoué.",
    session_end_you_will_redirect_to_the_homepage:
        "Votre session s'est terminée, vous allez être redirigé vers la page d'accueil.",
    session_end_title: 'Expiration de la session.',
    login_again: 'Veuillez vous reconnecter!',
    server_shutdown_template: "Attention! Le serveur s'arrête à minuit.",
    server_notif_message: 'Saisissez le message que vous souhaitez envoyer au',
    server_notif_message_users: 'utilisateur(s)',
    server_notif_message_sent: 'Le message a été envoyé avec succès!',
    server_notif_message_error: 'Une erreur est survenue!',
    process_tree: 'Arborescence de processus',
    debug_info: 'Informations de débogage',
    process_graph: 'Graphique de processus',
    process_history: 'Historique de processus',
    print_form: 'Imprimer',
    time_sheet: 'Feuille de temps',
    assign: 'Transférer',
    word_output: 'Exporter vers Word',
    pdf_output: 'Exporter vers PDF',
    pdf_preview: 'Aperçu du PDF',
    word_print: 'Imprimer à partir de Word',
    report: 'Rapports',
    pdf_download: 'Télécharger fichier PDF : ',
    select_file: 'Sélectionner fichier:',
    send_pdf: 'Envoyer fichier PDF',
    by_level: 'Personnel:',
    view_all_staff: 'Voir tout le personnel',
    image: 'Image',
    of: 'de',
    error: 'Erreur',
    has_form_less_events: "a forme moins d'événements",
    more_items: "Plus d'éléments...",
    'retrieve-data-error': 'La récupération des données a échoué.',
    upload_pdf_file: 'Soumettre fichier pdf.',
    download_pdf_file: 'Télécharger fichier pdf',
    export: 'Exporter',
    not_selected: 'Non sélectionné',
    news_detaile: 'Détails de nouvelles',
    new_task: 'Nouvelle tâche',
    id: 'ID',
    select_all: 'Sélectionner tout',
    deselect_all: 'Déselectionner tout',
    copied: 'Copié',
    unable_to_copy: 'Impossible de copier',
    password_setting: {
        current_password: 'Mot de passe actuel',
        new_password: 'Nouveau mot de passe',
        confirm_new_password: 'Confirmer nouveau mot de passe',
        messages: {
            current_password_is_wrong: 'Le mot de passe actuel est incorrect!',
            passwords_do_not_match: 'Les mots de passe ne correspondent pas!',
            new_password_same_as_current:
                'Le nouveau mot de passe ne peut pas être le même que le mot de passe actuel!',
            currentPassword_is_empty: 'Le mot de passe actuel est vide!',
            newPassword_is_empty: 'Le nouveau mot de passe est vide!',
            confirmNewPassword_is_empty: 'Confirmer le nouveau mot de passe est vide!'
        }
    },
    title: {
        news: 'Nouvelles',
        announcements: 'Annonces',
        procces_history: 'Historique du processus'
    },
    search_panel_control: {
        filter: 'Filtre',
        clear_filter: 'Effacer le filtre'
    },
    grid_control: {
        add: 'Ajouter',
        add_inline: 'Ajouter une ligne',
        edit: 'Éditer',
        edit_inline: 'Modifier la ligne',
        del: 'Supprimer',
        undel: 'Annuler',
        export_excel: 'Exporter vers Excel',
        import_excel: 'Importer depuis Excel',
        template_excel: 'Télécharger le modèle Excel',
        save: 'Sauvegarder',
        cancel: 'Annuler',
        view: 'Visualiser',
        export: 'Exporter',
        grid_is_in_editing_mode: 'Actuellement en mode édition, veuillez appliquer les modifications.',
        grid_is_in_editing_mode_you_cannot_do_anything:
            "Vous ne pouvez pas effectuer aucune opération en mode d'édition.",
        grid_error_retrieving_data: "Une erreur s'est produite lors de la récupération des données.",
        no_rows_selected: 'Aucune ligne sélectionnée',
        remove_selected_row: 'Supprimer la ligne sélectionnée?',
        only_selected_columns_will_be_exported: 'Seules les colonnes sélectionnées seront exportées',
        items_get_error: "Une erreur s'est produite lors de l'obtention de la liste!",
        noDataText: "Il n'y a pas de données à afficher.",
        saveChanges: 'Sauvegarder',
        discard: 'Annuler',
        saved: 'Enregistré',
        save_unsuccessful: "Échec de l'enregistrement",
        sum: 'Som',
        avg: 'Moy',
    },
    attachment_control: {
        add: 'Ajouter',
        new: 'Nouveau',
        select: 'Sélectionner',
        scan: 'Scanner',
        new_version: 'Nouvelle version',
        delete: 'Supprimer',
        delete_all: 'Supprimer tous',
        revert: 'Revenir',
        edit: 'Éditer',
        download: 'Télécharger',
        noFileExists: 'Aucun fichier éxiste',
        select_file: 'Sélectionner fichier',
        upload_file: 'Téléverser fichier',
        upload_files: 'Téléverser fichiers',
        upload_files_all: 'Téléverser tous les fichiers',
        upload_cancel: 'Annuler le téléversement',
        view: 'Visualiser',
        accepted_types: 'Types de fichiers acceptés',
        preview: 'Aperçu',
        one_file: '1 fichier',
        files: 'fichiers',
        each: 'chacun',
        messages: {
            folder_default_not_found: 'Dossier par défaut non trouvé!',
            delete_file: 'Êtes-vous sûr que vous voulez supprimer?',
            delete_all_files: 'Êtes-vous sûr que vous voulez supprimer tous les fichiers?',
            save_Changes: 'Êtes-vous sûr de vouloir continuer?',
            error_try_again: "Une erreur s'est produite lors de la sauvegarde. Veuillez réessayer!",
            file_edit_dont_allow: "Vous n'êtes pas autorisé à éditer ce type de fichier",
            desktop_file_launcher_notFound:
                "Service DesktopFileLauncher introuvable! Télécharger à partir de <a href='FileLuncher/FileLauncherSetup.exe' style='font-weight: bold;'> here <a/> ",
            reverted_Successfully: 'Le fichier a été rétabli avec succès.',
            file_upload_count_error:
                'Le nombre de fichiers ajoutés dépasse la limite autorisée et ne peut pas être téléchargé!',
            default_file_delete_error: "La suppression du fichier par défaut n'est pas possible!",
            default_file_edit_error: "La modification du fichier par défaut n'est pas possible!",
            file_type_error: "Le type de fichier n'est pas autorisé!",
            scan_upload_error: "Une erreur s'est produite lors de l'analyse!",
            scan_service_error: 'Service de scanner non trouvé!',
            select_file_error: 'Aucun élément sélectionné!',
            file_edit_error: "Une erreur s'est produite pendant l'édition!",
            file_upload_successfully: 'Le fichier fut téléchargé avec succès!',
            file_size_error:
                'La taille du fichier dépasse la limite autorisée et ne peut pas être téléchargée!',
            file_repeat_name_error: 'Le nom du fichier est répété!',
            file_select_count_error: 'Le nombre de fichiers sélectionnés dépasse la limite autorisée!',
            file_upload_error: "Une erreur s'est produite lors du téléchargement!",
            file_name_repeated_by_param: 'le fichier {repeatedName} est dupliqué.',
            'scan-opration-encountered-problem': 'Une erreur est survenue lors de la numérisation',
            file_is_in_edit_mode: 'Le fichier est en mode édition',
            dont_allow_edit_file: 'La modification de ce type de fichier n’est pas possible!',
            file_download_new_error: 'Le fichier récemment téléchargé ne peut pas être téléchargé',
            file_open_new_error: 'Le fichier récemment téléchargé ne peut pas être ouvert'
        }
    },
    export_control: {
        title: "Sélectionnez le type d'exportation",
        titlesetting: 'titre réglage',
        showTableTitle: 'Afficher le titre en exportation',
        backGround: 'Couleur de fond',
        fontname: 'Nom de la police',
        fontsize: 'Taille de police',
        fontcolor: 'Couleur de la police',
        borderstyle: 'Style de bordure',
        bordercolor: 'Couleur de la bordure',
        columnsetting: 'Paramétrage des colonnes',
        cellsetting: 'Réglage des cellules',
        createfile: 'Créer fichier',
        bodysetting: 'Réglage du corps',
        exit: 'Sortie',
        columnfontstyle: 'Style de police de la colonne',
        context: 'Contexte',
        contextstyle: 'Style de contexte',
        date: 'Date',
        twodigit: "Nombre avec jusqu'à deux chiffres décimaux",
        fourdigit: "Nombre avec jusqu'à quatre chiffres décimaux"
    },
    chart_control: {
        error_in_Get_Data: "Échec de l'obtention des données de graphique ===>",
        updating_chart: 'Mise à jour du graphique…',
        updating_map: 'Mise à jour de la carte…',
        error_in_get_map_data: 'Erreur lors de la récupération des données cartographiques.',
        loading: 'Chargement...',
        exportButtonTitle: 'Exporter',
        printButtonTitle: 'Imprimer',
        rangeSelectorFrom: 'Depuis',
        rangeSelectorTo: 'Depuis',
        rangeSelectorZoom: 'Zoom',
        downloadPNG: "Télécharger l'image PNG",
        downloadJPEG: "Télécharger l'image JPEG",
        downloadPDF: 'Télécharger le document PDF',
        downloadSVG: "Télécharger l'image SVG",
        downloadXLS: 'Télécharger XLC',
        downloadCSV: 'Télécharger le texte',
        printChart: 'Imprimer le graphique',
        contextButtonTitle: 'Menu contextuel du graphique',
        month_january: 'Janvier',
        month_february: 'Février',
        month_march: 'Mars',
        month_april: 'Avril',
        month_may: 'Mai',
        month_june: 'Juin',
        month_july: 'Juillet',
        month_august: 'Août',
        month_september: 'Septembre',
        month_october: 'Octobre',
        month_november: 'Novembre',
        month_december: 'Décembre',
        short_month_january: 'Jan',
        short_month_february: 'Fév',
        short_month_march: 'Mar',
        short_month_april: 'Avr',
        short_month_may: 'Mai',
        short_month_june: 'Jun',
        short_month_july: 'Jul',
        short_month_august: 'Aoû',
        short_month_september: 'Sep',
        short_month_october: 'Oct',
        short_month_november: 'Nov',
        short_month_december: 'Déc',
        weekday_monday: 'Lundi',
        weekday_tuesday: 'Mardi',
        weekday_wednesday: 'Mercredi',
        weekday_thursday: 'Jeudi',
        weekday_friday: 'Vendredi',
        weekday_saturday: 'Samedi',
        weekday_sunday: 'Dimanche'
    },
    assign_control: {
        no_name: 'sans_nom',
        delete: 'Supprimer',
        messages: {
            item_is_repeated: "l'item  {displayMember}  est répété."
        }
    },
    tree_control: {
        expand_collapse: 'Agrandir/réduire',
        error: 'Erreur',
        warning: 'Avertissement',
        add_new: 'Ajouter',
        show: 'Montrer',
        edit: 'Éditer',
        delete: 'Supprimer',
        messages: {
            item_delete_confirm: 'Êtes-vous sûr de vouloir supprimer?',
            item_get_info_error: "Erreur survenue lors de l'obtention d'information!"
        }
    },
    comment_control: {
        delete_confirm: 'Êtes-vous sûr de vouloir supprimer?',
        back: 'retour',
        save: 'Appliquer',
        empty: 'Le commentaire ne peut pas être vide'
    },
    dropdown_control: {
        select: 'Sélectionner...',
        messages: {
            items_get_error: "Erreur survenue lors de l'obtention d'information!"
        }
    },
    context: {
        new_folder: 'Nouveau',
        edit_folder: 'Éditer',
        delete_folder: 'Supprimer',
        open_in_new_window: 'Ouvrir dans une nouvelle fenêtre',
        rules: 'Gérer les règles des dossiers',
        messages: 'Messages',
        send_message: 'Envoyer un message',
        view_received_messages: 'Afficher les messages reçus',
        view_sent_message: 'Afficher les messages envoyés'
    },
    cartable_grid: {
        open_in_new_window: 'Ouvrir dans une nouvelle fenêtre',
        history: 'Historique',
        tree_history: 'Arborescence',
        graph_history: 'Graphique',
        time_sheet: 'Feuille de temps',
        change_folder: 'Changer le dossier',
        flag: 'Signaler',
        no_flag: 'Pas de signalement',
        assignTask: 'Assigner',
        FolderService_CanNotTransfer: 'Impossible de transférer dans ce dossier',
        total_tasks: 'Toutes les tâches: ',
        total_unread_tasks: 'Toutes les tâches non lues: '
    },
    create_physical_folder: {
        add_modal_header: 'Créer un dossier',
        edit_modal_header: 'Modifier le dossier',
        folder_title: 'Titre',
        folder_type: 'Type',
        dont_send_request: 'Ne pas envoyé la requête ',
        undone_works: 'Tâches en attente',
        done_works: 'Tâches terminées',
        folder_title_empty: 'Le titre du dossier est vide',
        folder_title_length_error: 'La longueur du titre ne doit pas excéder 50 caractères.',
        folder_title_validate_error: "Le nom n'est pas valide."
    },
    footer: {
        powered_by_raybpms: 'Propulsé par BPMS',
        rights: 'Interfacing Technologies Corporation. Tous droits réservés',
        url: 'https://www.interfacing.com/',
        help: 'Aide',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'Erreur lors du suivi!',
        tracking_code_is_empty: 'Le code de suivi est vide!',
        error_on_delete: "Une erreur s'est produite lors de l'opération de suppression",
        please_select_pdf_file: 'Veuillez sélectionner un fichier PDF',
        operation_is_not_allowed: "Vous n'êtes pas autorisé à faire cette opération.",
        events_cannot_be_loaded: 'Les événements ne peuvent pas être chargés!',
        server_error: 'Erreur de serveur',
        you_are_not_allowed_to_view_form: "vous n'avez pas la permission d'accéder au formulaire",
        access_denied: 'Accès refusé',
        print_failed: "Échec de l'impression",
        error_occured: "Une erreur s'est produite, veuillez contacter l'administrateur.",
        task_not_found:
            'Impossible de trouver cette tâche. Il a peut-être été exécuté par un autre utilisateur.',
        application_is_not_deployed: "L'application n'est pas déployée.",
        save_in_progress: "L'enregistrement est actuellement en cours, veuillez réessayer plus tard."
    },
    Lock: {
        'bpms-lock-expired': 'Le verrou est expirée!',
        'maximum-bpms-lock-user': 'La limite des utilisateurs simultanés dépasse la licence!',
        'bpms-lock-failed': 'Verrou introuvable!'
    },
    slider_managment: {
        title: "Curseur de réglage de l'image",
        slider_show_is_repeat: "Il y a une répétition dans l'ordre d'image du curseur.",
        slider_show_is_empty: "L'ordre des images est vide.",
        upload_message: 'Vos images sont plus que la limite permise!',
        image_displaying: 'Ordre des images',
        same_name_massage: 'Une image existe avec le même nom!',
        image_is_too_large: "La taille de l'image est trop grande",
        name: 'Nom',
        image: 'Image',
        pic_type: 'Sélectionnez un fichier image (jpg, png, gif) de moins de 80 Kb.',
        image_required: 'Sélectionnez une image.',
        level_required: "Sélectionnez l'ordre de l'image",
        new: 'Nouveau',
        edit: 'Éditer',
        empty: 'vide'
    },
    cartable_management: {
        cartable_managment_title: 'Gestion des listes de tâches – Renommer les dossiers par défaut',
        cartable_date_setup: 'Paramètres de date dans la liste des tâches',
        status: 'Statut:',
        exact: 'Exact',
        limited: 'Limité',
        exact_dsc: "Afficher la date et l'heure exactes",
        limited_dsc: 'Afficher les jours précédents approximativement',
        select_person:
            'Sélectionner une personne, un poste ou un groupe pour renommer le dossier principal',
        dont_works: 'Dossiers tâches à effectuer:',
        done_work: 'Dossiers de tâches terminés:',
        dont_send_request: 'Requêtes brouillons:',
        changing_folder_name: 'Renommer les dossiers',
        attention_message: 'Pour voir les dernières modifications, veuillez vous connecter à nouveau.',
        tree_expanded: 'Le dossier du premier rôle sera sélectionné après la connexion.',
        hide_default_user_folders:
            "Masquer les dossiers par défaut de l'utilisateur (tels que l'attente, le travail et le brouillon )",
        expand_folders_by_default:
            "Développez tous les dossiers de l'utilisateur et du personnel par défaut",
        tree: "Dossier d'arborescence"
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'Calendrier des congés',
        year: 'Année:',
        show_calendar: 'Montrer le calendrier ',
        year_calendar: 'Année',
        messages: {
            year_validate_message: "L'année n'est pas valide!"
        }
    },
    appearance_management: {
        ui_setup: 'Personnalisation',
        back_image: "Fonds d'image",
        default_theme: 'Thème par défaut',
        banner: 'Bannière du haut de page',
        back_image_dsc:
            "Pour sélectionner la page de connexion et la page d'accueil, utilisez le formulaire ci-dessous.",
        default_theme_dsc:
            "Veuillez sélectionner l'une des options ci-dessous comme thème de liste des tâches par défaut.",
        banner_dsc: 'Utilisez le formulaire ci-dessous pour sélectionner une bannière de site. Note: ',
        before_login: "Avant l'image de connexion:",
        after_login: 'Image de connexion:',
        current_img: 'Image en cours',
        location: 'Endroit:',
        repeat: 'Répété',
        no_repeat: 'Sans répétition',
        center: 'Centré',
        back_color: 'Couleur de fond',
        upload_save: 'Télécharger et sauvegarder',
        select_theme: 'Choisir le thème',
        preview: 'Prévisionner le thème',
        set_theme: 'Appliquer le thème',
        banner_attention: '',
        height_attention: "La hauteur de l'image ne doit pas dépasser 149 pixels.",
        image_attention: "La hauteur de l'image de bannière doit être de 149 pixels.",
        back_image_file: 'Image par défaut de bannière',
        right_iamge: 'Image côté gauche:',
        center_image: 'Image au centre:',
        left_image: 'Image côté droit:',
        for_field: 'Pour le champ:',
        select_image_file: 'Veuillez sélectionner un fichier image',
        delete_current_banner: 'Supprimer la bannière actuelle',
        image_restore: "Restaurer l'image par défaut",
        select_theme_for_current_user: "Sélectionner le thème de l'utilisateur actuel.",
        file_type: 'Seuls les fichiers jpg, png ou gif sont pris en charge.',
        server_wide: "À l'échelle du serveur",
        user: 'Utilisateur',
        save: 'Sauvegarder'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                "Seule la taille de l'image, la forme de l'icône, l'emplacement et la taille du texte vont changer, êtes-vous sûr de vouloir continuer?",
            save_sucsses_all: 'Les changements sont appliqués sur tout.',
            save_confirm: 'Êtes-vous sûr de vouloir continuer?',
            save_sucsses: 'Les modifications sont enregistrées avec succès.',
            insert_number:
                "Pour modifier l'icône ou la taille de l'image, vous ne pouvez saisir que le numéro.",
            icon_size: "La taille de l'icône doit être comprise entre 25 et 140 pixels.",
            file_type: 'Seuls les fichiers jpg, png ou gif sont pris en charge.',
            icon_volume: "L'icône/taille de l'image ne doit pas dépasser 30 Ko.",
            insert_only_number: 'vous ne pouvez saisir que des chiffres'
        },
        home_ui_setting: "Paramètres de la page d'accueil",
        items_menu: 'Options du Menu',
        description:
            "Pour ajouter des menus à la page d'accueil, sélectionnez l'élément et modifiez ses paramètres.",
        menu_setting: 'Paramètres du menu',
        show_menu: "Afficher le menu dans la page d'accueil",
        first_level: 'Afficher au premier niveau',
        not_show_top: 'Masquer dans le menu supérieur',
        display_name: 'Nom affiché',
        icon_width: "Largeur de l'icône",
        icon_width_ph: "icône/largeur de l'image",
        icon_height: "Hauteur de l'icône",
        icon_height_ph: "Icône/hauteur de l'image",
        home_menu_icon: "icône de menu/image dans la page d'accueil",
        select_file: 'Veuillez sélectionner un fichier au format jpg, png ou gif.',
        icon_view: "icône/forme d'image",
        circul: 'Cercle',
        squared: 'Carré',
        rectangle: 'Rectangle',
        text_location: 'Emplacement du texte',
        up_image: 'Image vers le haut',
        center_image: 'Image vers le centre',
        bottom_image: 'Image vers le bas',
        up_text: 'Texte vers le haut',
        center_text: 'Texte vers le centre',
        bottom_text: 'Texte vers le bas',
        delete_image: 'Image supprimée',
        text_color: 'Couleur de texte',
        float_text_color: 'Couleur du texte flottante',
        hilight_text_color: 'Surligner la couleur du texte',
        save_button_title: "Tous les paramètres sauf l'image, le nom affiché et l'ajout au menu",
        doing_settup: 'Appliquez les modifications à tous les changements',
        check_mark: "Affichage dans la page d'accueil",
        published_date: 'Date de publication:'
    },
    news_management: {
        message: {
            insert_title: 'Veuillez insérer le titre.',
            title_character_length: 'La longueur du titre ne doit pas excéder 255 caractères.',
            text_character_length: 'La longueur du texte ne doit pas excéder 4000 caractères.',
            insert_text: 'Veuillez insérer le texte.',
            number_priority: 'Sélectionner la priorité par numéros.',
            number_large:
                'Le numéro sélectionné pour la priorité des nouvelles est de plus de 4 chiffres.',
            file_volume: 'La taille du fichier doit être inférieure à 100 Ko.'
        },
        news: 'Nouvelles',
        add: 'Ajouter',
        title: 'Titre',
        text: 'Texte',
        text_color: 'Couleur du texte',
        enable_show: 'Visible pour les utilisateurs ordinaires',
        news_setup: 'Configuration des nouvelles',
        news_image: 'Nouvelles image',
        news_priority: 'Priorité des nouvelles',
        current_pic: 'Photos téléchargées',
        delete_curr_pic: "Supprimer l'image actuelle",
        pic_type: 'Format de fichier jpg, png ou gif inférieur À 100 Ko.',
        id: "numéro d'identification",
        createdAt: 'Date',
        username: "Nom d'utilisateur",
        control: 'Contrôle'
    },
    message_management: {
        messages: {
            repeated_recipient: 'Le destinataire est répété',
            title_is_empty: 'Le titre du message est vide, voulez-vous continuer?',
            success_send: 'Le message a été envoyé avec succès',
            unselected_recipient: 'Aucun destinataire sélectionné.'
        },
        reply_message: 'Réponse',
        title: 'Titre:',
        message_text: 'Corps du message:',
        attachment: 'Pièce jointes',
        recipients: 'Destinataires',
        select: 'Sélectionner ...',
        add_to_list: 'Ajouter à la liste',
        back: 'Retourner',
        sended: 'Envoyer un message',
        sended_by: 'Envoyer par:',
        send_date: "Heure d'envoi du message:",
        message_show: 'Montrer le message',
        no_permission: "Vous n'êtes pas autorisé à accéder!",
        subject: 'Sujet:',
        date_time_send: "Heure et date d'envoi",
        sender: 'Expéditeur',
        receiver: 'Destinataire',
        menu_title: 'Titre menu',
        received: 'Message reçu',
        send_email: 'Envoyer un courrier',
        send_sms: 'Envoyer un SMS'
    },
    task_assign: {
        messages: {
            success_assign: 'Transféré avec succès.',
            unselected_recipient: 'Aucun destinataire sélectionné.'
        },
        assign: 'Transférer-assigner',
        recipients: 'Destinataires',
        description: 'Description',
        last_dsc: 'Utiliser la description actuelle',
        out_work: 'Terminer la tâche en cours',
        keep_sender: "Conserver l'expéditeur actuel"
    },
    process_log: {
        status: 'Statut',
        event: 'Évènement',
        user: 'Utilisateur',
        user_position: 'Poste',
        work_title: 'Titre du poste',
        responsible: 'Responsable',
        create_date: 'Date de création de la tâche',
        done_date: 'Date de fin de tâche',
        folder_name: 'Nom du dossier',
        start_task: 'Activité qui agi comme démarreur',
        human_task: 'Activité humaine',
        fork_task: 'Activité fourchue',
        join_task: 'Activité jointe',
        system_task: 'Activité du système',
        common_task: 'Activité commun/e'
    },
    timesheet_register: {
        messages: {
            time_reng: 'La date de début ne peut excéder la date de fin',
            select_row: 'Veuillez sélectionner une ligne.',
            delete_timesheet_confirm: 'Êtes-vous sûr de vouloir supprimer la feuille de temps actuelle?',
            save_time_confirm:
                'Cette période est sélectionnée Tor une autre tâche. Voulez-vous le remplacer pour les tâches courantes?',
            can_not_edit:
                "Cette période est sauvegardée pour une tâche terminée. Modifier n'est pas autorisé.",
            date_not_valid: "La date n'est pas valide"
        },
        errors: {
            error_in_remaining_time: "Une erreur s'est produite dans le temps restant",
            get_timesheet: "Une erreur s'est produite pendant l'exportation de feuille de temps",
            error_in_save: "Une erreur s'est produite lors de l'enregistrement",
            error: 'Erreur',
            error_in_delete: "Une erreur s'est produite lors de la suppression"
        },
        work_dsc: 'Description de tâche',
        date: 'Date',
        start_time: 'Heure de début',
        end_time: 'Heure de fin',
        remaining_time: 'Temps restant',
        description: 'Description',
        timesheet: 'Feuille de temps',
        current_work: 'Assigner à la tâche en cours',
        persian_date: 'Date',
        weekly_plan: 'Plan hebdomadaire',
        prev_week: 'Semaine précédente',
        next_week: 'Semaine prochaine',
        plus: 'Ajouter',
        all_work: 'Tous les travaux en cours',
        delete: 'Supprimer'
    },
    delegation_management: {
        messages: {
            select_user: "Veuillez sélectionner l'utilisateur de référence.",
            select_date: 'Veuillez sélectionner la date de début et de fin',
            select_folder: 'Veuillez sélectionner les dossiers que le délégateur peut afficher',
            compare_fromDate_toDate: 'la date de fin est supérieure à la date de début'
        },
        folder_to_delegate: 'Dossiers qui peuvent être délégués:',
        delegations: 'Déléguer',
        delegation_edit: 'Modifier la délégation',
        delegation_user: 'Utilisateur délégué:',
        all_time: 'Permanent',
        from_date: 'À partir de',
        to_date: "Jusqu'au",
        start_date: 'Date de début',
        finish_date: 'Date de fin',
        delegate_to: 'Déléguer à:',
        more_item: "Plus d'éléments",
        all_item: "Plus d'éléments",
        remove_delegate: 'Supprimer un délégué',
        delegation_create: 'Ajouter',
        no_records: 'Aucun enregistrement ne correspond à vos mots clés',
        delegation_add: 'Ajouter une délégation',
        delegation_user_placeholder: 'Entrer pour rechercher',
        more_users: 'There are more results, please use better keywords'
    },
    rule_criterias: {
        new_rule: 'Nouvelle règle',
        edit_rule: 'Modifier la règle',
        no_permission: "Vous avez besoin d'une autorisation!",
        tag_error: 'Erreur survenue lors de la mise à jour des balises!',
        flag_error: 'Erreur survenue lors de la mise à jour du signalement!',
        no_actions: "Pas d'action!",
        action: 'Action',
        rule_name: 'Nom de la règle',
        operation: 'Opération',
        select_folder: 'Sélectionner le dossier'
    },
    ray_payment: {
        messages: {
            close_pay_win: "Tout d'abord, fermez la fenêtre de paiement."
        },
        wait_status: 'En attente du paiement.',
        payed_status: 'Payé',
        failed_status: 'Erreur lors du paiement',
        not_payed_status: 'No payé',
        currency: ' Devise',
        through: 'Par',
        pay: 'Paiement'
    },
    ray_datetimepicker: {
        date_not_valid: "La date n'est pas valide!",
        time_not_valid: "L'heure n'est pas valide!",
        ok: "D'accord"
    },
    logs_management: {
        show_details:
            "Activer afficher les détails d'erreur. En activant cette option, les utilisateurs verront le message d'erreur technique complet à chaque occurrence d'erreur.",
        error_number: 'Nombre',
        date: 'Créer par',
        level: 'Niveau',
        staff_id: "Numéro d'employé",
        application_name: "Nom de l'application",
        server_name: 'Nom du serveur',
        error_log: "Log d'erreurs",
        error_message: "Message d'erreur",
        api_log: "Journaux d'appels API",
        id: 'Id',
        session: 'Session',
        api: 'API',
        method: 'Méthode',
        severity: 'Gravité',
        search: 'Recherche...',
        key: 'Key parameter',
        duration: 'Duration (ms)',
        epc_log: 'Journal des appels EPC',
        user_log: 'Journal utilisateur',
        result: 'Code de résultat',
        application_log: "Journal d'activité",
        copy_clipboard: 'Copier dans le presse-papier',
        user: 'Utilisateur',
        login_datetime: 'Date et heure de connexion',
        logout_datetime: 'Déconnexion Date et heure',
        active_connections: 'Connexion active',
        send_message_all: 'Envoyer un message à tous',
        send_message_connection: 'Envoyer un message à cette connexion',
        start_datetime: 'Date et heure de début'
    },
    rule_management: {
        title: 'Gérer les règles des dossiers',
        operation: 'Opération',
        rule_name: 'Nom',
        add_new_rule: 'Nouvelle règle',
        edit_rule: 'Éditer la règle',
        are_you_sure: 'Êtes-vous sûr de vouloir supprimer la règle?',
        rule_modal: {
            name: 'Nom de la règle',
            subject: 'Subjet',
            task_type: 'Type',
            create_date: 'Date de création',
            response_date: 'Délai de réponse',
            done_date: 'Date pour la fin de la règle',
            priority: 'Priorité',
            flag: 'Drapeau',
            read: 'Lu',
            transcript: 'Transcription',
            app: 'Application',
            in_folder: 'Dossier',
            not_selected_option: 'Aucune option sélectionnée',
            validation: {
                name: 'Le nom est requis'
            },
            clear_tooltip: 'clair'
        }
    },
    today: "Aujourd'ui",
    form: 'Forme',
    form_behavior: 'Comportement du formulaire',
    close_form_warning: 'Êtes-vous sûr de vouloir quitter ? Toute modification sera perdue.',
    yes: 'Oui',
    no: 'Non',
    secret_code: {
        title: "Authentification d'approbation",
        message:
            'Nous vous avons envoyé un code secret sur votre email. Veuillez le copier et le coller ici.',
        code: 'Code :',
        legal_sentence:
            "En approuvant avec votre signature électronique, vous signez le document électroniquement. Vous convenez que</br>votre signature électronique a la même valeur juridique et le même effet que votre signature manuscrite sur</br>le document, et qu'elle a la même signification que votre signature manuscrite.",
        expired: 'Le code est invalide ou expiré',
        required: 'Le code est obligatoire',
        submit: 'Soumettre',
        cancel: 'Annuler'
    },
    user_settings: 'Paramètres utilisateur',
    audit_trail: {
        title: "Piste d'audit",
        operation_time: "Moment de l'opération",
        application_name: "Nom de l'application",
        user: 'Utilisateur',
        operation: 'Opération',
        entity_name: "Nom de l'entité",
        entity_definition: "Définition de l'entité",
        task_subject: 'Sujet de la tâche',
        field_name: 'Nom de domaine',
        old_value: 'Ancienne valeur',
        new_value: 'Nouvelle valeur'
    },
    select_date: 'Veuillez sélectionner la date de début et de fin'
}
export default localeMessages
