var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.isBanner && !_vm.isPlugin ? _c("banner") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wrapper pull-right" },
        [
          !(_vm.isPlugin || _vm.isHideMainMenu)
            ? _c("navbar", { attrs: { container: "container" } }, [
                _vm.enterLogin
                  ? _c(
                      "li",
                      {
                        staticClass: "js_loginPanel",
                        attrs: { slot: "leftNavItems" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.preventPanel()
                          },
                        },
                        slot: "leftNavItems",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: { href: "#", title: _vm.$t("login") },
                            on: {
                              click: function ($event) {
                                return _vm.togglePanel($event)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa-light fa-lg fa-right-to-bracket mr5",
                                }),
                              ]
                            ),
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("sign_in")) +
                                "\n                    "
                            ),
                            _c("span", { staticClass: "caret" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("login"),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container wrapper-padding" },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "scroll-top", staticStyle: { display: "table" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              margin: "auto",
              display: "table-cell",
              "vertical-align": "middle",
              "text-align": "center",
            },
          },
          [_c("i", { staticClass: "fad fa-2x fa-chevron-up" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }