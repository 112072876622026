import { DxTextBox } from 'devextreme-vue';
import utils from 'jsf/utils.js';

export default {
    components: {
        DxTextBox
    },

    data() {
        return {

            //isLoginSSO: payload.useIDP,
            isRTL: payload.currentLocale.IsRightToLeft,
            loading: false,

            username: '',
            password: '',
            errorTxt: '',
            model: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        submit() {
            //console.log("Login", this.username, this.password);
            this.loading = true;

            this.prepareLogin();
            const data = {
                UserName: this.username,
                Sha256Fromclient: this.model.password,
                Sha1PasswordFromClient: this.model.username
            };

            utils.callWebAPIp("api/login", data, "POST").done(res => {
                console.log("Login user/pwd", "Redirect to", res);
                window.location = res;
            }).fail((err) => {
                console.log('login error', err.responseText);
                this.loading = false;
                this.errorTxt = JSON.parse(err.responseText).ExceptionMessage;
                // clean load balancer's cookies to get chance to connect to another slave server
                $.cookie("AWSALB", null, { path: '/' });
                $.cookie("AWSALBCORS", null, { path: '/' });
            });
        },

        prepareLogin() {
            this.model.username = Sha1.hash(this.username.toLowerCase() + this.password);
            this.model.password = SHA256(this.password);
        }
    },

    mounted() {
        $('#login-password').on('keypress', (e) => {
            if (e.keyCode == 13)
                $('#login-submit').click();
            $('.js_loginPanel').addClass('open');
        });
    }
}
