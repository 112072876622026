import NewForm from '../forms/newform/newForm.vue';
import ModalDelegation from './Delegation.vue';
import utils from 'jsf/utils.js';
import { DxButton } from 'devextreme-vue';
import { DxDataGrid, DxColumn, DxPager, DxPaging } from 'devextreme-vue/data-grid';
import LoadPanel from "devextreme/ui/load_panel";

export default {
    components: {
        NewForm, ModalDelegation,
        DxButton, DxDataGrid, DxColumn, DxPager, DxPaging
    },

    data() {
        return {
            modalData: {},
            loadPanel: null,
            currentModalComponent: "",
            showDelegationModal: 0,
            isGridRowSelected: false,
            gridSelectedRow: {},
            dataGridRefKey: 'dataGrid',
            pageSizes: [5, 10, 20]
        }
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        }
    },

    methods: {
        onSelectionChanged({ selectedRowsData }) {
            this.gridSelectedRow = selectedRowsData;
            this.isGridRowSelected = true;
        },

        onClickAdd() {
            const that = this;
            this.loadPanel.show();
            this.loadData(function () {
                that.modalData.isCreation = true;
                that.currentModalComponent = "ModalDelegation";
                that.modalData.callback = () => { that.dataGrid.refresh(); };1
                that.showDelegationModal++;
                that.loadPanel.hide();
            })
        },

        onClickEdit() {
            const that = this;
            this.loadPanel.show();
            this.loadData(function () {
                const dataItem = that.gridSelectedRow[0];
                that.modalData.isCreation = false;
                that.modalData.formData = dataItem;
                that.currentModalComponent = "ModalDelegation";
                that.modalData.callback = () => { that.dataGrid.refresh(); };
                that.showDelegationModal++;
                that.loadPanel.hide();
            })
        },

        onClickDelete() {
            const dataItem = this.gridSelectedRow[0];
            const id = dataItem.DestinationId;

            const that = this;
            utils.confirm(i18n.$t("are_you_sure"), {
                yes: function () { utils.callWebAPI("api/delegations/delete/" + id, {}, () => { that.dataGrid.refresh(); }, null, 'DELETE'); },
                no: function () { }
            });
        },
        loadData(callBackFunction) {
            const that = this;

            if (that.modalData.baseInfo && that.modalData.baseInfo.users) {
                callBackFunction();
            }
            else {
                utils.callWebAPI("api/delegations/load-data", {}, (data) => {
                    that.modalData.baseInfo = data;
                    callBackFunction();
                });
            }
        }

    },

    created() {
        const that = this;
        this.loadPanel = new LoadPanel($('.loadpanel'), {
            shadingColor: 'rgba(0,0,0,0.4)',
            visible: false,
            showIndicator: true,
            showPane: true,
            shading: true,
            hideOnOutsideClick: false
        });

    }
};
