<template>
    <modal v-bind:showModal="showModal" data-backdrop="false" v-bind:size="{'my-col':true,'col-lg-12':true}">
        <div slot="header">
            <label>{{$t("title.procces_history")}}</label>
        </div>
        <div slot="body">

            <div v-bind:class="[direction ? 'k-rtl' : '' ]">

                <treeList v-bind:columns="columns"
                          v-bind:schema="schema"
                          v-bind:data="modalData">
                </treeList>

            </div>
        </div>
    </modal>
</template>

<script>
    import Modal from "../../widgets/modal/modal.vue";
    import TreeList from "../../widgets/TreeList/treeList.vue";

    export default {
        props: [
            'showTracking',
            'modalData'
        ],
        watch: {
        },
        data() {
            return {
                direction: payload.currentLocale.IsRightToLeft,
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Id: {
                                type: "string"
                            },
                            parentId: {
                                field: "ParentId",
                                nullable: true
                            }
                        },
                        expanded: true
                    }
                },
                columns: [
                    { field: 'Id', hidden: true },
                    {
                        field: 'StateName',
                        sortable: false,
                        title: i18n.$t("process_log.status"),
                        attributes: {
                            style: "white-space:normal;font-size:11px"
                        },
                        width: 300,
                        headerAttributes: {
                            style: "font-size:10px"
                        }
                    },
                    { field: 'EventName', sortable: false, title: i18n.$t("process_log.event"), headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'UserTitle', sortable: false, title: i18n.$t("process_log.user"), minScreenWidth: 720, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'StaffTitle', sortable: false, title: i18n.$t("process_log.user_position"), minScreenWidth: 720, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskSubject', sortable: false, title: i18n.$t("process_log.work_title"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskPrincipalTitle', sortable: false, title: i18n.$t("process_log.responsible"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskCreationDate', sortable: false, title: i18n.$t("process_log.create_date"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskResponseDate', sortable: false, title: i18n.$t("process_log.done_date"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskFolderTitle', sortable: false, title: i18n.$t("process_log.folder_name"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'AssignList', sortable: false, title: i18n.$t("task_assign.assign"), minScreenWidth: 960, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } }
                ],
                localData:[]
            }
        },
        computed: {
            showModal() {
                return this.showTracking;
            }
        },
        components: {
            Modal,
            TreeList
        }
    }

</script>
