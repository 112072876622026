import ContextMenu from "../contextMenu/contextMenu.vue";

export default {
    props: [
        "hasContextMenu",
        "contextMenuItems",
        "data",
        "nodeSelect",
        "clearSearchItem",
        "mustSelectedNodeId",
        "template",
        "isLazy",
        "dataSourceUrl",
        "hasRootNode",
        "hasDragDrop",
        "hasCheckbox",
        "checkChildren",
        "preserveExpandStatus",
        "expandRootItem",
        "expandUniqueId",
        "selectedNodeId",
        "setSelectedNodeId",
        "nodeCheck",
        "checkedNodes",
        "treeLoaded",
        "loadOnDemand",
        "enableSearch"
    ],

    data() {
        return {
            expandedNodes: JSON.parse(window.sessionStorage.getItem(this.expandUniqueId)) || {},
            treeEl: null,
            treeData: null,
            direction: payload.currentLocale.IsRightToLeft,
            HasCartablAccess: payload.permissions['ShowTasks']
        }
    },

    computed: {
        treeContextItems() {
            return typeof this.contextMenuItems == 'function' ? this.getContextMenuItems : this.contextMenuItems;
        }
    },

    watch: {
        data() {
            if (this.data && this.data.length)
                this.updateDataSource();
        },

        mustSelectedNodeId() {
            this.onMustSelectedNodeIdChanged();
        },

        setSelectedNodeId() {
            if (this.setSelectedNodeId != 1) {
                this.selectTreeNode(this.setSelectedNodeId, false, true);
            } else {
                const selectedItem = this.treeData.select();
                this.nodeSelect(selectedItem, this.treeData.dataItem(selectedItem));
            }
        },

        checkedNodes() {
            if (this.checkedNodes && this.checkedNodes.length)
                this.checkTreeView();
            else
                this.treeEl.find('.k-checkbox-wrapper input').prop('checked', false).trigger('change');
        }
    },

    methods: {
        /**
         * initialize kendo tree view
         */
        init() {
            if (this.data && this.data.length) {
                this.treeData = this.treeEl.kendoTreeView(this.buildConfig()).data('kendoTreeView');
                this.initAdditionalEvent();
            }
        },
        /**
         * build related config of kendo tree view
         * @returns kendo tree view config
         */
        buildConfig() {
            const self = this;
            //console.log("Tree config", this.hasCheckbox, this.checkChildren);
            return {
                dataSource: this.buildDataSource(),
                template: this.template ? this.template : null,
                select: this.onSelect,
                dataBound: this.onDataBound,
                //drag: this.onDrag,
                //dragstart: this.onDragStart,
                //dragend: this.onDragEnd,
                //drop: this.drop,
                check: this.onCheck,
                change: function () {
                    const nodeId = self.getNodeId(this.select());
                    self.$emit('itemSelected', nodeId);
                },
                //navigate: this.onNavigate,
                expand: this.onExpand,
                collapse: this.onCollapse,
                //dragAndDrop:this.hasDragDrop=='true',
                checkboxes: this.hasCheckbox == 'true' ?
                    (this.checkChildren == 'true' ? { checkChildren: true } : true) :
                    false,
                loadOnDemand: this.loadOnDemand == 'false' ? false : true
            }
        },

        setProp(id, prop, value) {
            const dataItem = this.treeData.dataSource.get(id);

            if (dataItem)
                dataItem.set(prop, value);
        },

        setExpanded(data) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].NodeOpen === true) {
                    data[i].expanded = true;
                }
                else {
                    if (this.expandedNodes[data[i].Id] != undefined && this.expandedNodes[data[i].Id] == true)
                        data[i].expanded = true;
                }
                if (this.expandRootItem) {
                    if (data[i].ItemType === 2 || typeof data[i].ItemType === 'undefined' || this.expandedNodes[data[i].Id] === true) {
                        data[i].expanded = true;
                    }
                }
                if (data[i].ChildItems && data[i].ChildItems.length) {
                    this.setExpanded(data[i].ChildItems);
                }

                if (typeof this.selectedNodeId !== "undefined") {
                    data[i].selected = this.selectedNodeId === data[i].Id ? true : false;
                }
            }
        },

        buildDataSource() {
            const dataSourceConfig = {
                schema: {
                    model: {
                        id: "Id",
                        text: "Text",
                        displayName: "DisplayName",
                        hasChildren: "HasChildren",
                        image: "ImageUrl",
                        expanded: this.expandRootItem === true ? true : false,
                        selected: "Selected",
                        parentId: "ParentId"
                    }
                }
            };
            if (this.data) {
                this.setExpanded(this.data);

                dataSourceConfig.data = this.data;
                dataSourceConfig.schema.model.children = "ChildItems";
                if (typeof this.treeLoaded === "function")
                    this.treeLoaded();
            } else {
                dataSourceConfig.transport.read = (options) => {
                    const data = {
                        id: options.id,
                        isLazy: this.isLazy == 'true'
                    };
                    console.log("tree load data");
                    $.ajax({
                        url: this.dataSourceUrl,
                        type: "GET",
                        dataType: "json",
                        contentType: "application/json;charset=utf-8",
                        success: (result) => {
                            this.setExpanded(result);
                            options.success(result);
                            if (typeof this.treeLoaded === "function")
                                this.treeLoaded();
                        }
                    });
                };
            }
            return new kendo.data.HierarchicalDataSource(dataSourceConfig);
        },
        /**
         * if kendo tree view was inited then just update dataSource, otherwise init kendo
         */
        checkTreeView() {
            const dataItems = this.treeData.dataItems();

            this.setTreeViewValues(dataItems);
        },
        setTreeViewValues(dataItems) {
            var that = this;
            dataItems.forEach(function (dataItem) {
                that.checkedNodes.forEach(function (value) {
                    if (value.indexOf(dataItem.id) > -1) {
                        dataItem.set("checked", true);
                    }
                    if (dataItem.hasChildren) {
                        // checking child dataItems recursively
                        that.setTreeViewValues(dataItem.children.data());
                    }
                });
            });
        },

        updateDataSource() {
            if (this.treeData)
                this.treeData.setDataSource(this.buildDataSource());
            else
                this.init();
        },

        getNodeId(node) {
            const n = this.treeEl.data('kendoTreeView').dataItem(node);
            if (n) {
                return n.id;
            }
        },

        getNodeById(id) {
            return this.treeData.dataSource.get(id);
        },
        onSelect(e) {
            if (typeof this.nodeSelect === 'function') {

                if (this.clearSearchItem != null && this.clearSearchItem != undefined) {
                    const selectedItem = this.treeData.select();
                    if (selectedItem == null || selectedItem.data() == null || (selectedItem.data().uid != this.treeData.dataItem(e.node).uid))
                        this.clearSearchItem();
                }

                this.nodeSelect(e.node, this.treeData.dataItem(e.node));
            }
        },
        onDrag(e) {},
        onDragStart(e) {},
        onDragEnd(e) {},

        onExpand(e) {
            const id = this.getNodeId(e.node);
            this.expandedNodes[id] = true;

            if (this.preserveExpandStatus === "true")
                window.sessionStorage.setItem(this.expandUniqueId, JSON.stringify(this.expandedNodes));
        },

        onCollapse(e) {
            const id = this.getNodeId(e.node);
            this.expandedNodes[id] = false;

            if (this.preserveExpandStatus === "true")
                window.sessionStorage.setItem(this.expandUniqueId, JSON.stringify(this.expandedNodes));
        },
        onDrop(e) {},
        onNavigate(e) {},
        onChange(e) {},

        onCheck(e) {
            if (typeof this.nodeCheck === 'function')
                this.nodeCheck(this.treeData.dataSource.view());
        },
        onDataBound(e) {
            this.onMustSelectedNodeIdChanged();
        },
        getNodeBySelector(selector) {
            return this.treeEl.data('kendoTreeView').dataItem(selector);
        },
        getContextMenuItems(node) {
            if (typeof this.contextMenuItems == 'function') {
                const nodeData = this.treeData.dataItem(node);
                return this.contextMenuItems(node, nodeData);
            }
        },

        treeContextItemSelect(target, callback) {
            const nodeData = this.treeData.dataItem(target);
            callback(target, nodeData);
        },
        /**
         * this will check @mustSelectedNodeId and if it was presented then tree will be checked by
         * given node id. if it was there then it will be selected.
         */
        onMustSelectedNodeIdChanged() {
            //if (this.treeData && this.mustSelectedNodeId) {
            //    let currentSelectedNode = this.treeData.select();
            //    if (currentSelectedNode.length) {
            //        let currentSelectedNodeData = this.treeData.dataItem(this.treeData.select());
            //        if (currentSelectedNodeData && currentSelectedNodeData.Id != this.mustSelectedNodeId)
            //            this.selectTreeNode(this.mustSelectedNodeId, null, true);
            //    }
            //}
        },
        selectTreeNode(nodeId, nodeSlc, triggerSelect) {
            if (!nodeSlc) {
                const mustSelectedNodeData = this.treeData.dataSource.get(nodeId);
                if (mustSelectedNodeData) {
                    nodeSlc = this.treeData.findByUid(mustSelectedNodeData.uid);
                    this.$emit('itemSelected', mustSelectedNodeData.Id);

                    this.treeData.select(nodeSlc);
                }
            } else {
                this.treeData.select(nodeSlc);
            }
            if (triggerSelect) {
                this.treeData.trigger("select", {
                    node: nodeSlc
                });
            }

        },
        addNode(nodeData, parentNodeData) {
            if (this.treeData) {
                const parent = this.treeData.findByUid(parentNodeData.uid);
                this.treeData.append(nodeData, parent);
            }
        },
        editNode(oldData, newData) {
            if (this.treeData) {
                const nodeData = this.treeData.dataSource.get(oldData.Id);
                if (nodeData) {
                    this.treeData.text(this.treeData.findByUid(nodeData.uid), newData.Text);
                }
            }
        },
        removeNode(nodeData) {
            if (this.treeData) {
                this.treeData.remove(this.treeData.findByUid(nodeData.uid));
            }
        },
        updateAttribute(nodeId, attrName, attrValue) {
            if (this.treeData) {
                const nodeData = this.treeData.dataSource.get(nodeId);
                if (nodeData.hasOwnProperty(attrName))
                    nodeData.set(attrName, attrValue);
            }
        },
        /**
         * bind click to tree selected node, so tree will reselect it even if it was selected.
         * it's useful in situation like cartable usage (changing to other page and returning to
         * tree by reselecting node)
         */
        initAdditionalEvent() {
            this.treeEl
                .off("click.selectSelectedTreeNode", ".k-state-selected")
                .on("click.selectSelectedTreeNode", ".k-state-selected", (e) => {
                    this.selectTreeNode(null, e.target, true);
                });

            $(".treeViewSearch", this.$el).on("input", this.handleSearch);
            $('.clearTreeViewSearch', this.$el).on('click', () => {
                $('.treeViewSearch', this.$el).val("");
                this.handleSearch();
            });
        },

        handleSearch() {
            var query = $(".treeViewSearch", this.$el).val().toLowerCase();
            var dataSource = $(".rayvarzBPMSTreeView", this.$el).data("kendoTreeView").dataSource;

            this.filter(dataSource, query);
        },
        // Sets the "hidden" field on items that match the query.
        filter(dataSource, query) {
            var hasVisibleChildren = false;
            var data = dataSource instanceof kendo.data.HierarchicalDataSource && dataSource.data();

            for (var i = 0; i < data.length; i++) {
          
                var item = data[i];
                var text = item.Text ? item.Text.toLowerCase() : null;
                var displayName = item.DisplayName ? item.DisplayName.toLowerCase(): null;
                var itemVisible =      query === true // parent already matches
                                    || query === "" // query is empty
                                    || (text && text.indexOf(query) >= 0 )// item text matches query
                                    || (displayName && displayName.indexOf(query) >= 0); // item display name matches query

                var anyVisibleChildren = this.filter(item.children, itemVisible || query); // pass true if parent matches

                hasVisibleChildren = hasVisibleChildren || anyVisibleChildren || itemVisible;

                item.hidden = !itemVisible && !anyVisibleChildren;
            }

            if (data) {
                // Re-apply the filter on the children.
                dataSource.filter({ field: "hidden", operator: "neq", value: true });
            }

            return hasVisibleChildren;
        }
    },
    updated() {
        this.updateDataSource();
    },
    mounted() {
        this.treeEl = $('.rayvarzBPMSTreeView', this.$el);
        this.init();
    },
    components: {
        ContextMenu
    }
}
