import Grid from "../widgets/grid/grid.vue";
import Restful from '../../assets/ts/restful';
import utils from 'jsf/utils.js';
import TrackingHistory from '../tracking/modals/TrackingHistory.vue';
import TaskAssign from '../taskassign/TaskAssign.vue';
import { processLog } from 'jsf/vm.processLog.js';
import { processHistory } from 'jsf/process/ProcessHistory.js';
import { timesheet } from 'jsf/vm.timesheet.js';

const flagFAIcon = 'fad fa-flag';

export default {
    props: [
    ],
    data() {
        const that = this;
        const selectedItems = null;
        const hiddenColumns = {};

        changeFolder = function (currentFolderId, selectedFolderId) {
            const taskIds = that.selectedItems;
            utils.callWebMethod("WebServices/FolderService.asmx", "ChangeTaskFolder",
                { taskIds: taskIds, sourceFolderId: currentFolderId, destFolderId: selectedFolderId }, function () {
                    that.reLoadeData();
                    alert(that.$t('save_successfully'));

                }, function () {
                    alert(that.messages.FolderService_CanNotTransfer);
                });
        };

        reloadGridCartabl = function () {
            that.reLoadeData();
        };

        return {
            messages: [],
            modalData: [],
            currentModalComponent: "",
            showTrackingHistory: 0,
            boldOnField: "IsReaded",
            // detailTemplate: "<span>Sample Detail</span>",
            detailConfig: this.detailConfigFn,
            direction: payload.currentLocale.IsRightToLeft,
            columns: [],
            contextMenuItems: this.buildContextMenuItems(),
            gridContextOpen: this.contextMenuOpen,
            toolbar: this.buildToolbarItems(),
            folderId: undefined,
            reloadGrid: 0,
            showTaskAssign: 0,
            currentModal: "",
            modalDataTaskAssign: {},
            height: this.getHeightOfWindow(),
            buildColumns: this.getColumns,
            buildToolbar: this.buildToolbarItems
        }
    },

    computed: {
        additionalParameters() {
            this.reloadGrid++;
            return {
                FolderId: this.$route.params.folderId,
                ParentId: this.$route.params.parentId,
                Sord: "desc"
            }
        },
        title: function () {
            return this.$route.params.folderName;
        },
        isNotDraft: function () {
            let isDraft = false;
            if ((typeof this.$route.params.isDraft == 'boolean' && this.$route.params.isDraft) || this.$route.params.isDraft === 'true')
                isDraft = true;
            //console.log("isNotDraft", this.$route.params.isDraft, isDraft, this.$route.params);

            return !isDraft;
        }
    },
    //watch: {
    //    additionalParameters() {
    //        this.reloadGrid++;
    //    },
    //},
    methods: {
        getMessages() {
            const that = this;
            if (!this.messages || !this.messages.length)
                utils.callWebAPI("api/task/messages", {}, (data) => {
                    //console.log("get messages", data);
                    that.messages = data;
                }, null, null, false);
        },

        getActions() {
            console.log("getActions", this.$route.params);
            const that = this;
            this.getMessages();

            const options = [
                {
                    key: "OpenInNewWindow",
                    text: this.messages.open_in_new_window,
                    icon: "fad fa-thin fa-external-link",
                    action: (node, nodeData, selecteds) => {
                        console.log("OpenInNewWindow", node, nodeData, selecteds);
                        for (let i in nodeData) {
                            const dataItem = nodeData[i];
                            window.open(
                                `${window.location.pathname}#/taskForm/${dataItem.Id}/${this.$route.params.folderId}/${dataItem.TaskTypeId}/${dataItem.Status}/${dataItem.TaskTypeKind == '2'}/${encodeURIComponent(this.$route.params.folderName)}`);
                        }
                    }
                }
            ];

            //if (this.isNotDraft)
            //    options.push({
            //        key: "MoveToFolder",
            //        text: this.messages.change_folder,
            //        icon: "fad fa-thin fa-folders",
            //        action: (node, nodeData, selecteds) => {
            //            const taskIds = [];
            //            $(selecteds).each(function() { taskIds.push(this.Id); });
            //            this.selectedItems = taskIds;
            //            utils.showModalDialog("StaticViews/FolderSelectionPage.aspx",
            //                i18n.$t("rule_criterias.select_folder"),
            //                "400", "300",
            //                `&pId=&fId=${this.$route.params.folderId}&showDefaultFolders=true&tId=${nodeData[0].Id}`);
            //        }
            //    });

            if (this.isNotDraft)
                options.push({
                    key: "Flag",
                    text: this.messages.flag,
                    icon: "fad fa-thin fa-flag",
                    subActions: [
                        {
                            key: "no_flag",
                            text: this.messages.no_flag,
                            action: (node, nodeData, selecteds) => {
                                this.changeFlag("0", nodeData[0]);
                            }
                        },
                        {
                            key: "red_flag",
                            text: "",
                            icon: `${flagFAIcon} iconColor-red`,
                            action: (node, nodeData, selecteds) => {
                                this.changeFlag("1", nodeData[0]);
                            }
                        },
                        {
                            key: "green_flag",
                            text: "",
                            icon: `${flagFAIcon} iconColor-green`,
                            action: (node, nodeData, selecteds) => {
                                this.changeFlag("2", nodeData[0]);
                            }
                        },
                        {
                            key: "blue_flag",
                            text: "",
                            icon: `${flagFAIcon} iconColor-blue`,
                            action: (node, nodeData, selecteds) => {
                                this.changeFlag("3", nodeData[0]);
                            }
                        }
                    ]
                });

            //console.log("Build task parameters", this.$route.params);
            if (!this.isNotDraft)
                options.push({
                    key: "DeleteTask",
                    text: i18n.$t("delete"), 
                    icon: "fad fa-thin fa-trash",
                    action: (node, nodeData, selecteds) => {
                        utils.confirm(i18n.$t("are_you_sure"), {
                            yes: function () {
                                //const dataItem = nodeData[0];
                                const taskIds = [];
                                $(selecteds).each(function () { taskIds.push(this.Id); });
                                const ids = taskIds.join(';');
                                //console.log("delete task", ids);
                                that.doAjaxRequest("api/tasks/DeleteDraft?taskInstanceIds=" + ids,
                                    {},
                                    (data) => { that.reLoadeData(); },
                                    (data) => { console.log(data); },
                                    'POST');
                            },
                            no: function () { }
                        });
                    }
                });
            return options;
        },

        detailConfigFn: function (e) {
            //console.log("detailConfigFn", e, this.isNotDraft);
            const self = this;
            if (!this.isNotDraft)
                return;
            this.getMessages();
            const dataItem = e.data;
            const detailWrapper = $('<div>');
            const detailCell = e.detailRow.children('td.k-detail-cell');

            const tagdiv = `<div class='cartableGridTags cartableGridDetails' title="${this.messages.label}" style = 'display: inline-block' ></div>`;
            const tags = $(tagdiv)
                .append('<i class="fal fa-fw fa-thin fa-lg fa-tags black"></i>')
                .append(dataItem.Tag == '' ? ' <span class="cartableGridEmptyTag">' + this.$t("insert_label") + '</span>' : ` <span>${dataItem.Tag}</span>`);

            const tagsEditFn = (e) => {
                tags.off('click.cartableGridTagsEdit');
                const tagsSpan = tags.children('span');
                const editInput = $('<input type="text"/>');
                const prevTag = tagsSpan.html();
                editInput.val(tagsSpan.hasClass('cartableGridEmptyTag') ? '' : prevTag);
                editInput.on('blur', (e) => {
                    const newTag = editInput.val();
                    //dataItem.set('Tag', newTag); // will update @dataItem but will also refresh grid row
                    $(e.target).parent().off('click.cartableGridTagsEdit').on('click.cartableGridTagsEdit', tagsEditFn);
                    if (prevTag == newTag) {
                        editInput.replaceWith(
                            $("<span class='" + (newTag == '' ? 'cartableGridEmptyTag' : '') + "'>")
                                .html(newTag == '' ? this.$t("insert_label") : newTag)
                        );
                    } else {
                        this.doAjaxRequest("WebServices/TaskInstanceService.asmx/SetTaskInstanceTag",
                            JSON.stringify({
                                taskInstanceId: dataItem.Id,
                                tag: newTag,
                                isActive: dataItem.Status === "Active" ? true : false
                            }),
                            (data) => {
                                editInput.replaceWith(
                                    $("<span class='" + (newTag == '' ? 'cartableGridEmptyTag' : '') + "'>")
                                        .html(newTag == '' ? this.$t("insert_label") : newTag)
                                );
                            },
                            null,
                            "POST"
                        );
                    }
                });
                tagsSpan.replaceWith(editInput);
            };
            tags.off('click.cartableGridTagsEdit').on('click.cartableGridTagsEdit', tagsEditFn);

            const treediv = `<a class='TreeHistory cartableGridDetails' title="` + this.messages.history_tree + `"><i class='fal fa-fw fa-thin fa-lg fa-history black'></i></a>`;
            const treeHistory = $(treediv);
            const viewTreeHistory = () => {
                const url = "api/tracking/get-tree-task/" + dataItem.Id;
                const that = this;
                this.doAjaxRequest(
                    url,
                    {},
                    (data) => {
                        if (data != null && data.length) {
                            that.currentModalComponent = "TrackingHistory";
                            that.modalData = data;
                            that.showTrackingHistory++;
                        } else
                            alert(i18n.$t("messages.not_any_record_found"));
                    });
            };
            treeHistory.on('click', viewTreeHistory);

            const graphdiv = `<a class='GraphHistory cartableGridDetails' title='${this.messages.history_graph}'><i class='fal fa-fw fa-thin fa-lg fa-monitor-heart-rate black'></i></a>`;
            const graphHistory = $(graphdiv);
            graphHistory.on('click', () => { processLog().activate({ id: dataItem.Id }); });

            const graphdivNew = `<a class='GraphHistory cartableGridDetails' title='${this.messages.process_graph}'><i class='fal fa-fw fa-thin fa-lg fa-project-diagram black'></i></a>`;
            const graphHistoryNew = $(graphdivNew);
            graphHistoryNew.on('click', () => { processHistory().activate({ id: dataItem.Id }); });

            const timeSheet = $(`<a class='TimeSheet cartableGridDetails' title='${this.messages.planner}'><i class='fal fa-fw fa-thin fa-lg fa-calendar-alt black'></i></a>`);
            const viewTimeSheet = () => {
                if (dataItem.lenght === 0)
                    return;
                const params = {
                    element: $('.bpmsTimesheetForm', this.$el)
                };
                timesheet().activate({
                    id: dataItem.Id,
                    cmp: false
                }, params);
            };
            timeSheet.on('click', viewTimeSheet);

            const assigndiv = `<a class='assignTask cartableGridDetails' title="` + this.messages.assignTask + `"><i class='fal fa-fw fa-thin fa-lg fa-clipboard-user black'></i></a>`;
            const assignTask = $(assigndiv);
            const viewassignTask = () => {
                if (dataItem.lenght === 0)
                    return;

                self.modalDataTaskAssign.ids = dataItem.Id;
                self.modalDataTaskAssign.folderId = self.$route.params.folderId;
                self.modalDataTaskAssign.callback = () => {
                    self.reLoadeData();

                    const params = {
                        text: self.$t('task_assign.messages.success_assign'),
                        type: 'information',
                        layout: 'center',
                        timeout: 3000
                    };

                    noty(params);
                };

                self.currentModal = "TaskAssign";
                self.showTaskAssign++;
            };
            assignTask.on('click', viewassignTask);

            if (dataItem.Description) detailWrapper.append($("<p>").html(dataItem.Description));
            detailWrapper.append(timeSheet);
            if (dataItem.IsByTreeHistory) detailWrapper.append(treeHistory);
            if (dataItem.IsByGraphHistory) {
                detailWrapper.append(graphHistory);
                detailWrapper.append(graphHistoryNew);
            }
            if (dataItem.IsAssignable) detailWrapper.append(assignTask);
            detailWrapper.append(tags);
            detailCell.append(detailWrapper);
        },

        contextMenuOpen(selecteds, node, nodeData) {
            const mapping = (value) => {
                return {
                    key: value.key,
                    text: value.text,
                    icon: value.icon,
                    action: value.action,
                    items: value.subActions ?
                        value.subActions.map(mapping) :
                        undefined
                };
            };

            const taskIds = [];
            $(selecteds).each(function () {
                const n = $(this).children('td').children('input[type="checkbox"]')[0];
                const t = $(n).attr('taskid');
                taskIds.push(t);
            });

            const n = $(node).children('td').children('input[type="checkbox"]')[0];
            const nodeTaskId = $(n).attr('taskid');

            const isAssign = nodeData.IsAssignable;
            const isFormLessEvents = nodeData.HasFormlessEvents;
            const actions = this.getActions();

            const self = this;

            if (isAssign) {
                actions.push(
                    {
                        key: "assignTask",
                        text: this.messages.assignTask,
                        icon: "fad fa-thin fa-clipboard-user",
                        // image: "dist/assignTask.png",
                        action: (node, nodeData, selecteds) => {
                            const taskIds = [];
                            $(selecteds).each(function () { taskIds.push(this.Id); });

                            self.modalDataTaskAssign.ids = taskIds;
                            self.modalDataTaskAssign.folderId = self.$route.params.folderId;
                            self.modalDataTaskAssign.callback = () => {
                                self.reLoadeData();

                                const params = {
                                    text: self.$t('task_assign.messages.success_assign'),
                                    type: 'information',
                                    layout: 'center',
                                    timeout: 3000
                                };

                                noty(params);
                            };

                            self.currentModal = "TaskAssign";
                            self.showTaskAssign++;
                        }
                    }
                );
            }
            self.contextMenuItems = actions.map(mapping);
            if (isFormLessEvents) {
                Restful.get("api/FormlessEvents/", { taskIds: taskIds }, null
                ).done((data) => {
                    $(data).each(function () {
                        if (this.TaskId === nodeTaskId) {
                            $(this.Events).each(function () {
                                let messageList = '';
                                actions.push(
                                    {
                                        key: this.Id,
                                        text: this.Title,
                                        image: "",
                                        action: (node, nodeData) => {
                                            const id = this.Id;
                                            const buttons = [];
                                            buttons[i18n.$t("close")] = function () {
                                                $(messageList).rayModal('close');
                                                self.reLoadeData();
                                            }
                                            Restful.post(
                                                "api/FormlessEvents/" + id, taskIds, null, true).done(function (rslt) {
                                                    const infos = rslt;
                                                    $(self.$el).LoadingOverlay('hide');
                                                    if (infos && infos.length > 0) {
                                                        messageList = $('<ul>').addClass("bpmsInfo");
                                                        let ix = 0;
                                                        for (let i = 0; i < infos.length; i++)
                                                            messageList.append($('<li class="' + infos[i].TypeStr + '" />').append((infos[i].IsHeading ? "<h1>" + (++ix) + ". " : "<h2>") + infos[i].Title + (infos[i].IsHeading ? "</h1>" : "</h2>")).append("<pre>" + infos[i].Message + "</pre>"));

                                                        utils.showGenericModalWithContainer("", messageList, self.taskEventCallBack, buttons);
                                                    }
                                                }).fail(function (ex) {
                                                    const err = ex.responseJSON;
                                                    console.log(err);
                                                    messageList = $('<ul>').addClass("bpmsInfo");
                                                    messageList.append("<pre>" + err.ExceptionMessage + "</pre>");
                                                    utils.showGenericModalWithContainer("", messageList, self.taskEventCallBack, buttons);
                                                    $(self.$el).LoadingOverlay('hide');
                                                });
                                            $(self.$el).LoadingOverlay('show', {
                                                zIndex: 100000
                                            });
                                        }
                                    }
                                );
                                self.contextMenuItems = actions.map(mapping);
                            });
                            return;
                        }
                    });
                });
            }
        },

        taskEventCallBack() {
            if (payload.IsPlugin) {
                window.bpmsClose('');
                return;
            }
            this.reLoadeData();
        },

        reLoadeData() {
            this.reloadGrid++;
        },

        buildContextMenuItems() {
            //console.log("buildContextMenu", this.$route.params);
            const mapping = (value) => {
                return {
                    key: value.key,
                    text: (value.icon) ?
                        `<i class="${value.icon} fa-fw fa-lg"></i> ${value.text}` :
                        value.text,
                    //imageUrl: value.image,
                    action: value.action,
                    items: value.subActions ?
                        value.subActions.map(mapping) :
                        undefined
                };
            };
            return this.getActions().map(mapping);
        },

        buildToolbarItems() {
            //console.log("Task grid: buildToolbarItems");
            const mapping = (value) => {
                const hasSubActions = Array.isArray(value.subActions);
                return {
                    type: hasSubActions ? "splitButton" : "button",
                    text: (value.icon) ? `<i class="${value.icon} fa-fw fa-lg"></i> ${value.text}` : value.text,
                    enable: false,
                    click: value.action,
                    menuButtons: hasSubActions ? value.subActions.map(mapping) : undefined
                }
            };
            return this.getActions().map(mapping);
        },

        getColumns(onSuccess) {
            //console.log("getColumns", this.$route.params);
            this.getMessages();

            this.columns = [
                { field: "Id", name: "Id", title: this.$t('id'), menu: false, hidden: true },
                {
                    field: "Flag", name: "Flag", title: this.$t('cartable_grid.flag'), filterable: false, sortable: true,
                    width: 70,
                    template: (dataItem) => {
                        let iconClass = "";
                        switch (dataItem.Flag) {
                            case 1:
                                iconClass = `${flagFAIcon} iconColor-red`;
                                break;
                            case 2:
                                iconClass = `${flagFAIcon} iconColor-green`;
                                break;
                            case 3:
                                iconClass = `${flagFAIcon} iconColor-blue`;
                                break;
                        }
                        return `<span taskId='${dataItem.Id}'><i class='${iconClass} fa-fw fa-lg'></i></span>`;
                    }
                    , minScreenWidth: 810
                },
                {
                    field: "PriorityId", name: "PriorityId", title: this.$t('priority'), filterable: false, sortable: true,
                    width: 76,
                    template: (dataItem) => {
                        let icon = "", title, color;
                        switch (dataItem.PriorityId) {
                            case 1:
                                icon = 'fas fa-thin fa-arrow-alt-circle-down', title = this.$t('priority_low'), color = "green";
                                break;
                            case 2:
                                icon = "fas fa-thin fa-minus-circle", title = this.$t('priority_normal'), color = "gold";
                                break;
                            case 3:
                                icon = 'fas fa-thin fa-arrow-alt-circle-up', title = this.$t('priority_high'), color = "DarkOrange";
                                break;
                            case 4:
                                icon = 'fas fa-thin fa-exclamation-circle', title = this.$t('priority_very_high'), color = "red";
                                break;
                        }

                        if (icon)
                            return `<i class="fa-lg ${icon}" style="color:${color}"></i> ${title}`;
                        return '';
                        //return `<img src='dist/${image}.png' title='${title}'/>`;
                    },
                    minScreenWidth: 620

                },
                {
                    field: "Subject", name: "Subject", title: this.$t('subject'), width: 250, filterable: false, sortable: true,
                    template: (dataItem) => {
                        const isDraft = dataItem.TaskTypeKind == '2';
                        const isNotification = dataItem.TaskTypeKind == '1';
                        const plugin = payload.isPlugin ? '?co=t' : '';

                        //const params = {
                        //    id: dataItem.Id,
                        //    folderId: this.$route.params.folderId,
                        //    taskTypeId: dataItem.TaskTypeId,
                        //    status: dataItem.Status,
                        //    isDraft: isDraft,
                        //    folderName: this.$route.params.folderName
                        //};

                        const notificationStatus = dataItem.Status === "Completed" ? dataItem.Status : "ReadOnly";
                        //console.log("task url", dataItem.Subject, this.$route.params);
                        const p = this.$route.params.parentId != null ? `/${this.$route.params.parentId}` : '';                 
                        return isNotification
                            ? `<a title="${dataItem.Subject}" href='${window.location.pathname}${plugin}#/taskForm/${dataItem.Id}/${this.$route.params.folderId}/${dataItem.TaskTypeId}/${notificationStatus}/${isDraft}/${encodeURIComponent(this.$route.params.folderName)}${p}'><i class="fad fa-bells transcript-icon"></i> ${(dataItem.Subject != '' && dataItem.Subject != null) ? dataItem.Subject : dataItem.TaskTypeTitle}</a>`
                            : `<a title="${dataItem.Subject}" href='${window.location.pathname}${plugin}#/taskForm/${dataItem.Id}/${this.$route.params.folderId}/${dataItem.TaskTypeId}/${dataItem.Status}/${isDraft}/${encodeURIComponent(this.$route.params.folderName)}${p}'>${(dataItem.Subject != '' && dataItem.Subject != null) ? dataItem.Subject : dataItem.TaskTypeTitle}</a>`;
                    },
                    minwidth: 200
                },
                {
                    field: "CreationDate", name: "CreationDate", title: this.$t('created_date'), filterable: false, sortable: true, width: 120,
                    template: (dataItem) => {
                        if ($.cookie("IsExactDate") == 'false' || $.cookie("IsExactDate") == 'undefined')
                            return $.timeago(dataItem.CreationDate);
                        return dataItem.CreationDate;
                    },
                    minScreenWidth: 670
                },
                {
                    field: "ResponseDate", name: "ResponseDate", title: this.$t('response_deadline'), filterable: false, sortable: true, minScreenWidth: 200, width: 120, minScreenWidth: 770,
                    template: (dataItem) => {
                        if (dataItem.ResponseDate != '' && dataItem.ResponseDate != null && ($.cookie("IsExactDate") == 'false' || $.cookie("IsExactDate") == 'undefined'))
                            return $.timeago(dataItem.ResponseDate);
                        return dataItem.ResponseDate;
                    }
                },
                {
                    field: "DoneDate", name: "DoneDate", title: this.$t('done_date'), filterable: false, sortable: true, minScreenWidth: 500, width: 120, minScreenWidth: 860,
                    template: (dataItem) => {
                        if (dataItem.DoneDate != '' && dataItem.DoneDate != null && ($.cookie("IsExactDate") == 'false' || $.cookie("IsExactDate") == 'undefined'))
                            return $.timeago(dataItem.DoneDate);
                        if (dataItem.DoneDate === null)
                            dataItem.DoneDate = "";

                        return dataItem.DoneDate;
                    }
                },
                { field: "Sender", name: "Sender", title: this.$t('sender'), filterable: false, sortable: true, minScreenWidth: 500, width: 120, minScreenWidth: 720 },
                { field: "StarterUser", name: "StarterUser", title: this.$t('starter_user'), width: 120, filterable: false, sortable: true, hidden: true },
                { field: "StarterStaff", name: "StarterStaff", title: this.$t('starter_staff'), width: 120, filterable: false, sortable: true, hidden: true },
                { field: "IsAssignable", name: "IsAssignable", title: this.$t('is_assignable'), filterable: false, sortable: false, hidden: true, menu: false },
                { field: "IsByGraphHistory", name: "IsByGraphHistory", title: '', filterable: false, sortable: false, hidden: true, menu: false },
                { field: "IsByTreeHistory", name: "IsByTreeHistory", title: '', filterable: false, sortable: false, hidden: true, menu: false },
                { field: "HasFormlessEvents", name: "HasFormlessEvents", title: this.$t('has_form_less_events'), menu: false, hidden: true }
            ];

            //console.log("CartGr: getColumns 2", this.columns.length, this.columns);

            const taskTypeId = this.$route.params.folderId;
            if (taskTypeId != null && taskTypeId != '') {
                const that = this;

                this.doAjaxRequest("api/tasks/get-columns/" + taskTypeId, {}, (data) => {
                    if (data != null && data.length) {
                        $(data).each(function () {
                            that.columns.push({
                                field: this.Name, name: this.Name, title: this.Title, filterable: false, sortable: true,
                                filterable: false, sortable: true, hidden: !this.Default, width: 150
                            });
                        });
                    }
                });

                if (typeof onSuccess === 'function')
                    onSuccess();

                return this.columns;
            }
        },

        getHeightOfWindow() {
            let totalHeight = window.innerHeight;
            totalHeight = totalHeight - 150;
            if (!payload.hideFooter)
                totalHeight = totalHeight - 20;
            if (payload.isBanner === true)
                totalHeight = totalHeight - 149;
            return totalHeight;
        },

        changeFlag(flag, nodeData) {
            this.doAjaxRequest("api/tasks/SetTaskInstanceFlag",
                {
                    flagId: flag,
                    isActive: nodeData.Status === "Active" ? "true" : "false",
                    taskInstanceId: nodeData.Id
                },
                (data) => {
                    nodeData.set('Flag', parseInt(flag));
                });
        },

        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            const ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",
                async: false,
                contentType: "application/json;charset=utf-8"
            };
            if (method) {
                ajaxConfig.method = method;
            }
            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        }
    },

    updated() { },

    mounted() {
        //console.log("moartGr: mounted", this.columns);
        //this.buildColumns();
        //console.log("CartGr: mounted 2", this.columns);
        //this.getColumns();
        //    this.additionalParameters = {
        //        FolderId: this.$route.params.folderId,
        //        TaskTypeId: this.$route.params.taskTypeId,
        //        Sidx: "CreationDate",
        //        Sord: "desc"
        //    };
    },

    created() {
        //console.log("created");
    },

    components: {
        Grid,
        TrackingHistory,
        TaskAssign
    }
}
