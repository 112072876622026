import NewForm from './newform/newForm.vue';
/*import loading from '../widgets/loading/loading.vue';*/
import utils from 'jsf/utils.js';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
    components: {
        NewForm,
/*        loading,*/
        DxLoadPanel
    },

    data() {
        return {
            loadingVisible: true,
            modalData: [],
            currentModalComponent: "",
            showTrackingHistory: 0,
            widthSize: 0,
            heightSize: 0,
            className: "",
            title: "",
            sampleSize: "col-sm-12",
            counter: 0,
            currentView: "test",
            currentIndex: -1,
            isCompletedTask: false,
            customStyle: '',
            breadcrumbs: []
        }
    },

    watch: {
        '$route'() { this.init(); }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            if (this.$route.params.isTopMenu === "true")
                this.getTopMenuBreadCrumbs();
            else
                this.getHomeMenuItemBreadCrumbs();

            this.afterFormLoaded({
                title: this.$t("loading"),
                formState: {
                    IsResponsive: true,
                    ResponsiveWdith: 6
                }
            });

            const params = {
                formId: this.$route.params.id,
                isTopMenu: this.$route.params.isTopMenu,
                isStarterForm: true,
                isDraft: false,
                formLoadedCallback: $.proxy(this.afterFormLoaded, this),
                /*showLoading: $.proxy(this.showLoading, this),*/
                toolbarContainer: $(this.$el).find('.toolbar').empty(),
                showHistoryModalCallback: $.proxy(this.showHistoryModal, this)
            };

            $(this.$el).find(".js_load-form").append("<div />").bpmsAppForm(params);
        },

        getTopMenuBreadCrumbs() {
            this.breadcrumbs = [];
            const self = this;
            const formId = this.$route.params.id;
            const sorted = self.sortTopMenus();
            if (formId != null) {
                self.getTopMenuItemParent(formId, sorted, self.breadcrumbs);
                self.breadcrumbs.push({ text: i18n.$t("homepage"), url: "/" });
                self.breadcrumbs = self.breadcrumbs.reverse();
            }
        },

        getTopMenuItemParent(parentId, menus, breadcrumbs) {
            const self = this;
            $(menus).each(function () {
                if (this.id === parentId) {
                    breadcrumbs.push(this);
                    self.getTopMenuItemParent(this.parentId, menus, breadcrumbs);
                }
            });
        },

        sortTopMenus() {
            const self = this;
            const pId = '';
            const list = [];
            $(payload.menuItems).each(function () {
                self.getTopMenuChilds(this, list, pId);
            });
            return list;
        },

        getTopMenuChilds(m, list, parentId) {
            const self = this;
            const i = m.Id;
            list.push({ text: m.Text, url: "" + m.Url, parentId: parentId, id: i });
            $(m.ChildItems).each(function () {
                self.getTopMenuChilds(this, list, i);
            });
        },

        getHomeMenuItemBreadCrumbs() {
            const self = this;
            const formId = this.$route.params.id;
            if (formId != null) {
                utils.callWebAPI("api/home/breadcrumbs", { id: formId }, (data) => {
                    this.breadcrumbs = [];
                    for (let bc of data.breadcrumbs)
                        if (bc.Text.length === 0)
                            this.breadcrumbs.push({ text: i18n.$t("homepage"), url: "/" });
                        else if (bc.Id === formId)
                            this.breadcrumbs.push({ text: bc.Text, url: "" });
                        else
                            this.breadcrumbs.push({ text: bc.Text, url: "/" + bc.Id });
                });
            }
        },

        afterFormLoaded(formData) {
            this.title = formData.formTitle;

            //if (formData.formState.IsResponsive) {
                this.className = payload.isPlugin ? `col-xs-${formData.formState.ResponsiveWdith}` : `col-xs-12 col-lg-${formData.formState.ResponsiveWdith}`;
                this.customStyle = '';
        //    } else
        //        this.customStyle = `width: ${formData.formState.FormWidth + 50}px; min-height:${formData.formState.FormHeight + 30}px`;
        },

        //showLoading(visible) {
        //    const self = this;
        //    console.log("Show loading Vue", visible);
        ////    this.$nextTick(function () {
        ////        //self.loadingVisible = visible;
        ////        if (visible)
        ////            $.LoadingOverlay('hide', true);
        ////        visible ? $.LoadingOverlay('show') : $.LoadingOverlay('hide', true);
        ////    });
        //},

        showHistoryModal() {
            let url = "";
            let id = this.$route.params.id;
            if (typeof id === "undefined")
                id = this.$route.query.tId;

            if (typeof id === "undefined") {
                const domainObjectId = router.app.$route.query.dId;
                const taskTypeId = router.app.$route.query.fid;
                url = "api/tracking/get-tree-task-domain?domainObjectId=" + domainObjectId + "&taskTypeId=" + taskTypeId;
            } else {
                url = "api/tracking/get-tree-task/" + id;
            }

            const that = this;

            this.doAjaxRequest(url, {},
                (data) => {
                    if (data != null && data.length) {
                        that.currentModalComponent = "TrackingHistory";
                        that.modalData = data;
                        that.showTrackingHistory++;
                    } else
                        alert(i18n.$t("messages.not_any_record_found"));
                });
        },

        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            const ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",
                contentType: "application/json;charset=utf-8"
            };

            if (method)
                ajaxConfig.method = method;

            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        }
    },
}
