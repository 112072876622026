import utils from 'jsf/utils.js';

const TreeConstants = (function () {
    function TreeConstants() {
    }
    return TreeConstants;
}());
let EnumTreeMenuItemAction;
let EnumActionResult;
let EnumExpandedTree;
var RayTree = (function () {
    function RayTree(element, options) {
        this.tree = null;
        this.contextMenu = null;
        this.dataSource = null;
        this.expandedNodes = {};
        this.lastScrollTopValue = 0;
        this.expandStatus = EnumExpandedTree.Normal;
        this.element = element;
        this.options = options;
        this._create();
    }
    RayTree.prototype._create = function () {
        const _this = this;
        if (!payload.currentLocale.IsRightToLeft)
            $(this.element).removeClass('k-rtl');
        const self = this;
        this.treeElement = this.element.children(".ray-tree");
        this.contextMenuElement = this.element.children(".context-menu");
        const expandbtn = this.element.children(".expandbtn");
        this.expandStatus = this.options.controlState.ExpandStatus;
        // init kendo data source
        this.initDataSource();
        this.treeElement.kendoTreeView({
            dataSource: this.dataSource,
            dataTextField: "Title",
            template: TreeConstants.treeItemTemplate,
            select: $.proxy(self.onTreeItemSelected, this),
            expand: $.proxy(self.onTreeNodeExpanded, this),
            collapse: $.proxy(self.onTreeNodeCollapsed, this),
            dataBound: $.proxy(self.onDataBound, this),
        });
        // Tooltip
        this.treeElement.kendoTooltip({
            filter: "li span.k-in",
            content: "",
            position: "bottom",
            width: 200,
            showAfter: 1000,
            animation: false,
            requestStart: function (e) {
                const tooltipText = this.target().children('i:first').attr('title');
                if ($('.context-menu.k-widget').is(':visible') || $.trim(tooltipText) === '')
                    this.hide();
            },
            show: function (e) {
                const tooltipText = this.target().children('i:first').attr('title');
                if ($('.context-menu.k-widget').is(':visible') || $.trim(tooltipText) === '') {
                    this.hide();
                    return;
                }
                this.content.text(tooltipText);
            }
        });

        this.contextMenuElement.kendoContextMenu({
            target: this.treeElement,
            filter: '.k-item',
            open: $.proxy(self.onContextMenuOpening, this),
            select: $.proxy(self.onContextMenuItemSelected, this)
        });
        if (this.options.controlState.EnableSelect)
            this.initSelectMode();
        this.tree = this.treeElement.data("kendoTreeView");
        this.contextMenu = this.contextMenuElement.data("kendoContextMenu");
        expandbtn.on("click", function (e) {
            e.preventDefault();
            if (_this.expandStatus === EnumExpandedTree.Expanded) {
                _this.expandStatus = EnumExpandedTree.Collapsed;
            }
            else {
                _this.expandStatus = EnumExpandedTree.Expanded;
            }
            _this.dataSource.read();
        });
    };
    RayTree.prototype.getValue = function () {
        return this.options.controlState.Value;
    };
    RayTree.prototype.initSelectMode = function () {
        const _this = this;
        const self = this;
        if (!this.options.controlState.EnableSelect)
            return;
        this.treeElement.on("dblclick", function (e) {
            const selectedValue = _this.getSelectedItemId();
            if (selectedValue != null && selectedValue.length === 36) {
                const comboId = _this.options.bpmsAppForm.options.nested.Id;
                const treeid = _this.options.controlState.Id;
                const params = {
                    selectedRowKey: selectedValue,
                    dropDownClientId: comboId,
                    gridId: treeid,
                    pageInstance: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                    parentPageInstanceId: _this.options.bpmsAppForm.dto.formState.ParentPageInstanceId,
                    bindingId: _this.options.controlState.BindingId
                };
                utils.callWebService("WebServices/jqGridService.asmx", "SelectRayAutoCompleteDropDownItem", params, true).done(function (rslt) {
                    self.options.bpmsAppForm.close(true);
                }).fail(function (ex, status, thrownError) {
                    utils.showAjaxError(ex, status, thrownError, self.options.controlState.Id, false);
                });
            }
        });
    };
    RayTree.prototype.setState = function (state) {
        this.expandStatus = this.options.controlState.ExpandStatus;
        if (state.Behavior.Visible)
            this.dataSource.read();
        this.options.controlState = state;
        this.options.controlState.Behavior.Visible ? this.element.show() : this.element.hide();
    };
    RayTree.prototype.requiredFieldValidator = function () {
        return true;
    };
    RayTree.prototype.getSelectedItemId = function () {
        const selectedItem = this.tree.select();
        return selectedItem.length === 0 ? null : this.tree.dataItem(selectedItem).id;
    };
    RayTree.prototype.getNodeId = function (node) {
        return this.tree.dataItem(node).id;
    };
    RayTree.prototype.getSelectedParentId = function () {
        const selectedItem = this.tree.select();
        if (selectedItem.length === 0)
            return null;
        const parent = this.tree.parent(selectedItem);
        if (parent.length === 0)
            return null;
        const parentData = this.tree.dataItem(parent);
        if (typeof parentData !== "undefined" && typeof parentData.id !== "undefined")
            return parentData.id;
        return null;
    };
    RayTree.prototype.initDataSource = function () {
        const _this = this;
        const self = this;
        this.dataSource = new kendo.data.HierarchicalDataSource({
            transport: {
                read: function (options) {
                    if (!_this.options.controlState.Behavior.Visible) {
                        options.success([]);
                        return;
                    }
                    let parentValue = null;
                    if (typeof options.data !== 'undefined' && typeof options.data.Id != 'undefined')
                        parentValue = options.data.Id;
                    const params = {
                        pageInstanceId: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                        formControlName: _this.options.controlState.Id,
                        parentValue: parentValue,
                        bindingId: _this.options.controlState.BindingId
                    };
                    $.get('api/TreeNodes', params)
                        .done(function (items) {
                        for (let _i = 0, items_1 = items; _i < items_1.length; _i++) {
                            const item = items_1[_i];
                            let isExpanded = _this.expandedNodes[item.Id];
                            if (_this.expandStatus === EnumExpandedTree.Normal) {
                                if (typeof isExpanded === 'undefined' && item.Id === 'root')
                                    isExpanded = true;
                                item.expanded = typeof isExpanded === 'undefined' ? false : isExpanded;
                            }
                            else if (_this.expandStatus === EnumExpandedTree.Expanded) {
                                item.expanded = true;
                            }
                            else if (_this.expandStatus === EnumExpandedTree.Collapsed) {
                                item.expanded = false;
                            }
                        }
                        options.success(items);
                    });
                },
                parameterMap: $.proxy(self.parameterMap, this)
            },
            schema: {
                model: {
                    id: "Id",
                    hasChildren: "HasChildren"
                }
            }
        });
    };
    RayTree.prototype.onTreeNodeExpanded = function (e) {
        const id = this.getNodeId(e.node);
        this.expandedNodes[id] = true;
        this.lastScrollTopValue = this.element.scrollTop();
    };
    RayTree.prototype.onTreeNodeCollapsed = function (e) {
        const id = this.getNodeId(e.node);
        this.expandedNodes[id] = false;
    };
    RayTree.prototype.onDataBound = function () {
        this.element.scrollTop(this.lastScrollTopValue);
    };
    RayTree.prototype.onTreeItemSelected = function (e) {
        console.log(e);
        if (this.options.controlState.EnableSelect)
            return;
        const selectedValue = this.tree.dataItem(e.node).id;
        this.options.controlState.Value = selectedValue;
        this.lastScrollTopValue = this.element.scrollTop();
        //if (!this.options.controlState.EnableSelect) {
        //    this.options.bpmsAppForm.updateForm(this.options.controlState.Id)
        //        .done(() => {
        //            this.options.controlState.Value = '';
        //        })
        //        .fail((e, status, thrownError) => {
        //            this.options.controlState.Value = '';
        //            utils.showAjaxError(e, status, thrownError, this.options.controlState.Id, false);
        //        });
        //}
        if (selectedValue !== null && this.lastSelectedValue !== selectedValue) {
            this.options.rayControlChanged(this.element, this.options.controlState);
            this.lastSelectedValue = selectedValue;
            this.lastSelected = e.node;
        }
    };
    RayTree.prototype.onContextMenuOpening = function (e) {
        // hide all tooltips
        $('.k-tooltip').hide();
        // select contxt menu target element before opening the context menu
        this.tree.select(e.target);
        const newItem = this.contextMenuElement.children(".add-item");
        const editItem = this.contextMenuElement.children(".edit-item");
        const viewItem = this.contextMenuElement.children(".view-item");
        const deleteItem = this.contextMenuElement.children(".delete-item");
        // depand on what user defined for Tree behaviours, we enable/disable menu items
        // note: only new-item is allowed for root node.
        this.contextMenu.enable(newItem, this.isActionAllowed(EnumTreeMenuItemAction.New));
        this.contextMenu.enable(editItem, this.isActionAllowed(EnumTreeMenuItemAction.Edit));
        this.contextMenu.enable(viewItem, this.isActionAllowed(EnumTreeMenuItemAction.View));
        this.contextMenu.enable(deleteItem, this.isActionAllowed(EnumTreeMenuItemAction.Delete));
    };
    RayTree.prototype.onContextMenuItemSelected = function (e) {
        const action = $(e.item).data("action");
        if (!this.isActionAllowed(action))
            return;
        switch (action) {
            case EnumTreeMenuItemAction.New:
                this.createNode(this.getSelectedItemId());
                break;
            case EnumTreeMenuItemAction.Edit:
                this.updateOrViewNode(this.getSelectedItemId(), this.getSelectedParentId(), EnumTreeMenuItemAction.Edit);
                break;
            case EnumTreeMenuItemAction.View:
                this.updateOrViewNode(this.getSelectedItemId(), this.getSelectedParentId(), EnumTreeMenuItemAction.View);
                break;
            case EnumTreeMenuItemAction.Delete:
                this.deleteNode(this.getSelectedItemId());
                break;
        }
    };
    RayTree.prototype.createNode = function (parentId) {
        const _this = this;
        this.options
            .bpmsAppForm
            .saveFormObject().done(function () {
            const params = {
                PageInstanceId: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                FormControlName: _this.options.controlState.Id,
                ParentNodeId: parentId
            };
            $.post("api/TreeNode", params).done(function () { return _this.showModal(EnumTreeMenuItemAction.New); });
        })
            .fail(function (ex) {
            console.error(`Error while creating new node in ${_this.options.controlState.Id}`, ex);
        });
    };
    RayTree.prototype.setTargetObject = function (selectedId, parentId) {
        const params = {
            PageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            FormControlName: this.options.controlState.Id,
            ParentNodeId: parentId,
            BindingId: this.options.controlState.BindingId
        };
        return $.ajax({
            method: "PUT",
            url: `api/TreeNode/${this.getSelectedItemId()}`,
            data: params,
            cache: false
        });
    };
    RayTree.prototype.updateOrViewNode = function (selectedId, parentId, action) {
        const _this = this;
        this.options
            .bpmsAppForm
            .saveFormObject().done(function () {
            _this.setTargetObject(selectedId, parentId).done(function () { return _this.showModal(action); });
        })
            .fail(function (ex) {
            console.error(`Error while ${action[action]}node in ${_this.options.controlState.Id}`, ex);
        });
    };
    RayTree.prototype.deleteNode = function (nodeId) {
        const _this = this;
        const self = this;
        utils.modalConfirmation(i18n.$t('tree_control.messages.item_delete_confirm'), function (result) {
            if (!result)
                return;
            const params = {
                pageInstanceId: _this.options.bpmsAppForm.dto.formState.PageInstanceId,
                formControlName: _this.options.controlState.Id,
                parentValue: _this.getSelectedParentId(),
                bindingId: _this.options.controlState.BindingId,
                isForced: false
            };
            $.ajax({
                method: "DELETE",
                url: `api/TreeNode/${_this.getSelectedItemId()}/?${$.param(params)}`,
                cache: false
            }).done(function (res) {
                const messageList = $('<ul/>').addClass("bpmsInfo");
                if (res.Result == EnumActionResult.OK) {
                    _this.options.bpmsAppForm.saveFormObject()
                        .done(function () {
                        _this.tree.remove(_this.tree.select());
                        _this.options.rayControlChanged(_this.element, _this.options.controlState);
                    })
                        .fail(function (e, status, thrownError) {
                        utils.showAjaxError(e, status, thrownError, self.options.controlState.Id, false);
                    });
                }
                messageList.append($('<li>').append($("<h2>").html(res.Message)));
                if (res.Result == EnumActionResult.Error) {
                    $('li', messageList).addClass('Error');
                    utils.modalDialogWithContainer(i18n.$t('tree_control.error'), messageList, function () {
                        // after error
                    });
                }
                else if (res.Result == 2) {
                    $('li', messageList).addClass('Warning');
                    utils.modalConfirmation(i18n.$t('tree_control.warning'), function (resultdialog) {
                        const _this = this;
                        if (resultdialog == true) {
                            params.isForced = true;
                            $.ajax({
                                method: "DELETE",
                                url: `api/TreeNode/${this.getSelectedItemId()}/?${$.param(params)}`,
                                cache: false
                            }).done(function (res) {
                                _this.tree.remove(_this.tree.select());
                                _this.options.rayControlChanged(_this.element, _this.options.controlState);
                            }).fail(function (e, status, thrownError) {
                                utils.showAjaxError(e, status, thrownError, _this.options.controlState.Id, false);
                            });
                        }
                        else {
                            // after warning
                        }
                    }, messageList);
                }
            })
                .fail(function (err) {
                console.error("Error while deleting a tree node", err);
            });
        });
    };
    RayTree.prototype.showModal = function (action) {
        let nestedData = null;
        switch (action) {
            case EnumTreeMenuItemAction.New:
                nestedData = JSON.parse(this.options.controlState.NewParams.toString());
                break;
            case EnumTreeMenuItemAction.Edit:
                nestedData = JSON.parse(this.options.controlState.EditParams);
                break;
            case EnumTreeMenuItemAction.View:
                nestedData = JSON.parse(this.options.controlState.ViewParams);
                break;
            default:
                console.warn(`${action[action]} doesn't need modal dialogs.`);
                return;
        }
        const modaldiv = $('<div id="modalDialog" />');
        const params = {
            nested: nestedData,
            parentBpmsAppForm: this.options.bpmsAppForm
        };
        modaldiv.bpmsAppForm(params);
    };
    /**
     * @description checks if action item is allowed
     * @param action
     */
    RayTree.prototype.isActionAllowed = function (action) {
        // root node is totally different from other nodes, we need to know if selected node is root
        const isSelectedRoot = this.getSelectedItemId() === 'root';
        switch (action) {
            case EnumTreeMenuItemAction.New:
                return this.options.controlState.EnableNew &&
                    this.options.controlState.NewParams !== "" &&
                    this.options.controlState.Behavior.Enabled;
            case EnumTreeMenuItemAction.Edit:
                return !isSelectedRoot &&
                    this.options.controlState.EnableEdit &&
                    this.options.controlState.EditParams !== "" &&
                    this.options.controlState.Behavior.Enabled;
            case EnumTreeMenuItemAction.View:
                return !isSelectedRoot &&
                    this.options.controlState.EnableView &&
                    this.options.controlState.ViewParams !== "";
            case EnumTreeMenuItemAction.Delete:
                return !isSelectedRoot &&
                    this.options.controlState.EnableDelete &&
                    this.options.controlState.Behavior.Enabled;
            default:
                return false;
        }
    };
    /**
     * @description map parameters for kendo dataSourceTransport
     */
    RayTree.prototype.parameterMap = function (options, type) {
        if (type === "read") {
            let parentValue = null;
            if (typeof options.data !== 'undefined' && typeof options.data.Id != 'undefined')
                parentValue = options.data.Id;
            return {
                pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
                formControlName: this.options.controlState.Id,
                parentValue: parentValue,
                bindingId: this.options.controlState.BindingId
            };
        }
        return options;
    };
    return RayTree;
}());
TreeConstants.treeItemTemplate = "<i title='#:item.Tooltip#'></i>#:item.Title#";
(function ($) {
    $.widget('ui.rayTree', $.ui.rayControl, {
        tree: null,
        _create: function () {
            this.tree = new RayTree(this.element, this.options);
        },
        getValue: function () {
            return this.tree.getValue();
        },
        setState: function (cs) {
            this.tree.setState(cs);
            this.setHelp(cs);
        },
        requiredFieldValidator: function () {
            return this.tree.requiredFieldValidator();
        }
    });
})(jQuery);
(function (EnumTreeMenuItemAction) {
    EnumTreeMenuItemAction[EnumTreeMenuItemAction["View"] = 0] = "View";
    EnumTreeMenuItemAction[EnumTreeMenuItemAction["New"] = 1] = "New";
    EnumTreeMenuItemAction[EnumTreeMenuItemAction["Edit"] = 2] = "Edit";
    EnumTreeMenuItemAction[EnumTreeMenuItemAction["Delete"] = 3] = "Delete";
})(EnumTreeMenuItemAction || (EnumTreeMenuItemAction = {}));
(function (EnumActionResult) {
    EnumActionResult[EnumActionResult["OK"] = 0] = "OK";
    EnumActionResult[EnumActionResult["Error"] = 1] = "Error";
    EnumActionResult[EnumActionResult["Warning"] = 2] = "Warning";
    EnumActionResult[EnumActionResult["None"] = 3] = "None";
})(EnumActionResult || (EnumActionResult = {}));
(function (EnumExpandedTree) {
    EnumExpandedTree[EnumExpandedTree["Normal"] = 0] = "Normal";
    EnumExpandedTree[EnumExpandedTree["Expanded"] = 1] = "Expanded";
    EnumExpandedTree[EnumExpandedTree["Collapsed"] = 2] = "Collapsed";
})(EnumExpandedTree || (EnumExpandedTree = {}));

