/// <reference path="../../../node_modules/@types/jquery/index.d.ts" />
/// <reference path="dts/payload.d.ts" />

export class Helper {
    /**
     *
     * @param {any} value
     * @param {any} length
     */
    public truncate(value: any, length: any) {
        const len = parseInt(length);
        if (value) {
            if (value.length > len)
                return $.trim(value).substring(0, len).split(' ').slice(0, -1).join(' ') + '...';
            else
                return $.trim(value);
        }
        return null;
    }

    /**
     * Get Param Value from Page URL or from `url` Argument.
     * @param {any} name
     * @param {any} url
     */
    public getURLParam(name: any, url: any) {
        if (!url)
            url = window.location.href;

        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * disable and enable buttons
     * @param {any} input
     * @param {any} scrollTop
     */
    public statefulButton(input: any, scrollTop: any) {
        const attr: any = $(input).attr('disabled');

        if (typeof attr !== typeof undefined && attr !== false)
            $(input).removeAttr('disabled');
        else
            $(input).attr('disabled', 'disabled');

        if (scrollTop)
            $('html, body').animate({ scrollTop: 170 }, 600);

        return false;
    };

    public enumBuilder(obj: Object) {
        const result = {};
        for (let item in obj)
            result[result[item] = obj[item]] = item;
        return result;
    };
}

export class Sidebar {
    /**
     * @param {any} slideToggle
     * @param {any} sidebarContainer
     */
    public open(slideToggle: any, sidebarContainer: any) {
        slideToggle.toggleClass('collapsed');
        sidebarContainer.toggleClass('side_open');
    }

    /**
     *
     * @param value
     */
    public removeScroller(value: any) {
        if (value.hasClass('js_sidebarOpen'))
            value.removeClass('js_sidebarOpen');
        else
            value.addClass('js_sidebarOpen');
    }
}

export class App {
    public loadMethods() {
        const sidebar = new Sidebar();

        const $mainContainer = $('.main-container');
        const $sidebarContainer = $('.js_sidebar-container');
        const $sidebarToggleInSidebar = $('.js_sideToggle-sidebar');
        const $sidebarToggleInHeader = $('.js_sideToggle-header');
        const $scrollUpButton = $('.scroll-top');
        const $navBar = $('.js_navbar');

        $(window).on('resize', () => {
            if (window.outerWidth > 768) {
                if ($('body').hasClass('js_sidebarOpen')) {
                    $('body').removeClass('js_sidebarOpen');
                    sidebar.open($sidebarToggleInHeader, $sidebarContainer);
                }
            }
        });

        // side bar arrow and toggle container
        $sidebarToggleInSidebar.on('click', () => {
            $('.js_sideToggle-sidebar').toggleClass('iconreverse');
            $('.js_personal-information').toggleClass('hide');
            $('.user-avatar').toggleClass('hide');
            $('.user-profile-container').toggleClass('hide');
            $sidebarContainer.toggleClass('side_close');
            $mainContainer.toggleClass('main-container_wide');
        });

        $sidebarToggleInHeader.on('click', () => {
            $('html, body').animate({ scrollTop: 0 }, 200);
            sidebar.open($sidebarToggleInHeader, $sidebarContainer);
            sidebar.removeScroller($('body'));
        });

        // add fixed class to navbar
        if (!payload.isPlugin)
            $(window).on('scroll', (event: any) => {
                event.stopPropagation();
                const scrollTop = $(window).scrollTop();
                const headerOffset = $('header').offset().top;

                if (headerOffset === 0) {
                    $navBar.addClass('navbar-fixed');
                    $sidebarContainer.addClass('sidebar-container_fix');
                    return;
                }

                if (scrollTop > headerOffset) {
                    $navBar.addClass('navbar-fixed');
                    $sidebarContainer.addClass('sidebar-container_fix');
                    $scrollUpButton.addClass('scroll-top_show');

                } else {
                    $navBar.removeClass('navbar-fixed');
                    $sidebarContainer.removeClass('sidebar-container_fix');
                    $scrollUpButton.removeClass('scroll-top_show');
                }
            });

        // scroll up button
        $scrollUpButton.on('click', () => {
            $('html, body').animate({ scrollTop: 0 }, 300);
        });
    }
}
