var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("cartable_management.cartable_managment_title"),
        className: "col-xs-12",
      },
    },
    [
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("label", { staticClass: "radio" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("cartable_management.tree")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "radio" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isOpenCartablFolders,
                    expression: "isOpenCartablFolders",
                  },
                ],
                attrs: { type: "checkbox", value: "false" },
                domProps: {
                  checked: Array.isArray(_vm.isOpenCartablFolders)
                    ? _vm._i(_vm.isOpenCartablFolders, "false") > -1
                    : _vm.isOpenCartablFolders,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.isOpenCartablFolders,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "false",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.isOpenCartablFolders = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isOpenCartablFolders = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isOpenCartablFolders = $$c
                      }
                    },
                    _vm.isOpenCartablFoldersChange,
                  ],
                },
              }),
              _vm._v(
                _vm._s(_vm.$t("cartable_management.expand_folders_by_default"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "radio" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.expandStaffFolders,
                    expression: "expandStaffFolders",
                  },
                ],
                attrs: { type: "checkbox", value: "false" },
                domProps: {
                  checked: Array.isArray(_vm.expandStaffFolders)
                    ? _vm._i(_vm.expandStaffFolders, "false") > -1
                    : _vm.expandStaffFolders,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.expandStaffFolders,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "false",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.expandStaffFolders = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.expandStaffFolders = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.expandStaffFolders = $$c
                      }
                    },
                    _vm.expandStaffFoldersChange,
                  ],
                },
              }),
              _vm._v(_vm._s(_vm.$t("cartable_management.tree_expanded"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "radio" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.hideUserDefaultFolders,
                    expression: "hideUserDefaultFolders",
                  },
                ],
                attrs: { type: "checkbox", value: "false" },
                domProps: {
                  checked: Array.isArray(_vm.hideUserDefaultFolders)
                    ? _vm._i(_vm.hideUserDefaultFolders, "false") > -1
                    : _vm.hideUserDefaultFolders,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.hideUserDefaultFolders,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "false",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.hideUserDefaultFolders = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.hideUserDefaultFolders = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.hideUserDefaultFolders = $$c
                      }
                    },
                    _vm.hideUserDefaultFoldersChange,
                  ],
                },
              }),
              _vm._v(
                _vm._s(_vm.$t("cartable_management.hide_default_user_folders"))
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "radio" }, [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("cartable_management.cartable_date_setup"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cartableDate,
                    expression: "cartableDate",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "rbtCartableDate",
                  value: "true",
                },
                domProps: { checked: _vm._q(_vm.cartableDate, "true") },
                on: {
                  change: [
                    function ($event) {
                      _vm.cartableDate = "true"
                    },
                    _vm.cartableDateChange,
                  ],
                },
              }),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("cartable_management.exact")) +
                  "\n\n                    (" +
                  _vm._s(_vm.$t("cartable_management.exact_dsc")) +
                  ")\n                "
              ),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cartableDate,
                    expression: "cartableDate",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "rbtCartableDate",
                  value: "false",
                },
                domProps: { checked: _vm._q(_vm.cartableDate, "false") },
                on: {
                  change: [
                    function ($event) {
                      _vm.cartableDate = "false"
                    },
                    _vm.cartableDateChange,
                  ],
                },
              }),
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("cartable_management.limited")) +
                  "\n\n                    (" +
                  _vm._s(_vm.$t("cartable_management.limited_dsc")) +
                  ")\n                "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { class: _vm.colMd, attrs: { id: "ca-management" } }, [
          _c("label", { staticClass: "radio" }, [
            _vm._v(_vm._s(_vm.$t("cartable_management.select_person"))),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "pacPrincipals" } }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("br"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("label", { staticClass: "radio" }, [
                _vm._v(_vm._s(_vm.$t("cartable_management.dont_works"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.workingAlias,
                    expression: "workingAlias",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.workingAlias },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.workingAlias = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("label", { staticClass: "radio" }, [
                _vm._v(_vm._s(_vm.$t("cartable_management.done_work"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.completedAlias,
                    expression: "completedAlias",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.completedAlias },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.completedAlias = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("label", { staticClass: "radio" }, [
                _vm._v(_vm._s(_vm.$t("cartable_management.dont_send_request"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.draftAlias,
                    expression: "draftAlias",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.draftAlias },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.draftAlias = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { disabled: _vm.disable != 0 },
                  on: {
                    click: function ($event) {
                      return _vm.saveFolderNames()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.$t("save")) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row form-group" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("span", [
            _vm._v("\n                " + _vm._s(_vm.$t("attention"))),
            _c("br"),
            _vm._v(
              "\n                " +
                _vm._s(_vm.$t("cartable_management.attention_message")) +
                "\n            "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }