import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LocalizationHelper } from './assets/ts/localizationHelper';

import en from './Locales/en';
import fr from './Locales/fr';
import ar from './Locales/ar';
import he from './Locales/he';
import sv from './Locales/sv';
import de from './Locales/de';

Vue.use(VueI18n);

// init i18n
export const i18n = new VueI18n({
    locale: payload.currentLocale.Language,
    fallbackLocale: 'en',
    messages: {
        "en": en,
        "fr": fr,
        "ar": ar,
        "he": he,
        "sv": sv,
        "de": de
    }
});

global.i18n = new LocalizationHelper(i18n);
global.vueI18n = i18n;
