var localeMessages = {
    user: 'Benutzer',
    staff: 'Personal',
    save_successfully: 'Erfolgreich gespeichert.',
    error_saving: 'Fehler beim Speichern',
    help: 'Hilfe',
    ok: 'Ok',
    send_as_staff: 'Als Personal senden',
    remove_record: 'Datensatz entfernen',
    homepage: 'Hauptseite',
    view_form: 'Form Ansicht',
    confirm: 'Bestätigen Sie',
    prev_row: 'Vorherige Zeile',
    next_row: 'Nächste Zeile',
    save_and_new: 'Speichern und Neu',
    save_and_next: 'Speichern und Weiter',
    save_and_previous: 'Speichern und Zurück',
    save_and_return: 'Speichern und zurückkehren',
    cancel_and_return: 'Abbruch und Rückgabe',
    submit_form: 'Formular abschicken',
    return: 'Rückkehr',
    save_form: 'Formular speichern',
    login: 'Anmeldung',
    sign_in: 'Anmeldung',
    username: 'Benutzername',
    password: 'Passwort',
    date: 'Datum',
    author: 'Autor',
    more_details: 'Mehr Details anzeigen',
    show: 'Anzeigen',
    close: 'Schließen Sie',
    open: 'Öffnen Sie',
    languages: 'Sprachen',
    try_again: 'Try Again',
    tracking: 'Nachverfolgung',
    tracking_code: 'Verfolgungs-Code',
    history: 'Geschichte',
    process_level: 'Prozess-Ebene: <strong>{level}</strong>',
    loading: 'Laden',
    uploading: 'Hochladen',
    save: 'Speichern Sie',
    remove: 'Entfernen',
    attention: '* Achtung!',
    select: 'Wählen Sie',
    please: 'Bitte',
    doing: 'Tun',
    upload_doing: 'Hochladen und Speichern',
    page_refresh: 'Um die Änderungen zu sehen, aktualisieren Sie den Cache (Strg-F5).',
    send: 'Senden Sie',
    insert: 'Einfügen.',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    reply: 'Antwort',
    are_you_sure: 'Sind Sie sicher?',
    control: 'Kontrolle',
    show_error_details: 'Fehlerdetails anzeigen',
    warning: 'Warnung',
    search: 'Suche',
    empty: 'Leere',
    new: 'Neu',
    priority: 'Priorität',
    priority_low: 'Niedrig',
    priority_normal: 'Medium',
    priority_high: 'Hoch',
    priority_very_high: 'Dringend',
    subject: 'Thema',
    created_date: 'Erstellt am',
    response_deadline: 'Antwortfrist',
    done_date: 'Geschehen zu',
    sender: 'Absender',
    starter_user: 'Starter Benutzer',
    starter_staff: 'Benutzer des Personals',
    is_assignable: 'Ist zuordenbar',
    insert_label: 'Etikett',
    continue: 'Weiter',
    submit: 'Einreichen',
    cancel: 'Abbrechen',
    cancel_nested: 'Abbrechen',
    other: 'Andere',
    settings: 'Einstellungen',
    change_theme: 'Themen',
    chart_control: {
        month_january: 'Januar',
        month_february: 'Februar',
        month_march: 'März',
        month_april: 'April',
        month_may: 'Mai',
        month_june: 'Juni',
        month_july: 'Juli',
        month_august: 'August',
        month_september: 'September',
        month_october: 'Oktober',
        month_november: 'November',
        month_december: 'Dezember',
        short_month_january: 'Jan',
        short_month_february: 'Feb',
        short_month_march: 'Mrz',
        short_month_april: 'Apr',
        short_month_may: 'Mai',
        short_month_june: 'Jun',
        short_month_july: 'Jul',
        short_month_august: 'Aug',
        short_month_september: 'Sep',
        short_month_december: 'Dez',
        short_month_november: 'Nov',
        short_month_october: 'Okt',
        error_in_Get_Data: 'Das Abrufen der Diagrammdaten ist fehlgeschlagen ===>',
        updating_chart: 'Das Diagramm wird geladen…',
        updating_map: 'Die Karte wird geladen…',
        error_in_get_map_data: 'Fehler beim Abrufen der Kartendaten.',
        loading: 'Wird geladen...',
        exportButtonTitle: 'Export',
        printButtonTitle: 'Drucken',
        rangeSelectorFrom: 'Aus',
        rangeSelectorTo: 'Zu',
        rangeSelectorZoom: 'Zoomen',
        downloadPNG: 'Bild PNG herunterladen',
        downloadJPEG: 'Bild im JPEG-Format herunterladen',
        downloadPDF: 'Dokument PDF herunterladen',
        downloadSVG: 'Bild SVG herunterladen',
        downloadXLS: 'Laden Sie XLC herunter',
        downloadCSV: 'Text herunterladen',
        printChart: 'Diagramm drucken',
        contextButtonTitle: 'Kontextmenü des Diagramms',
        weekday_monday: 'Montag',
        weekday_tuesday: 'Dienstag',
        weekday_wednesday: 'Mittwoch',
        weekday_thursday: 'Donnerstag',
        weekday_friday: 'Freitag',
        weekday_saturday: 'Samstag',
        weekday_sunday: 'Sonntag'
    },
    user_settings: 'Benutzereinstellungen',
    audit_trail: {
        title: 'Buchungskontrolle',
        operation_time: 'Betriebszeit',
        application_name: 'Anwendungsname',
        user: 'Benutzer',
        operation: 'Betrieb',
        entity_name: 'Entitätsname',
        entity_definition: 'Entitätsdefinition',
        task_subject: 'Betreff der Aufgabe',
        field_name: 'Feldname',
        old_value: 'Alter Wert',
        new_value: 'Neuer Wert'
    },
    message: 'Nachricht',
    messages: {
        not_any_record_found: 'Datensatz wurde nicht gefunden.',
        not_history_for_tracking_code_found: 'Für den Tracking-Code wurde kein Verlauf gefunden.',
        not_selected_principals: 'Es sind keine Hauptbenutzer ausgewählt, Ordner umbenennen.',
        write_dont_work_folder_name:
            'Bitte wählen Sie einen Namen für den Ordner „To-Do-Aufgaben“ aus.',
        write_done_work_folder_name:
            'Bitte wählen Sie einen Namen für den Ordner „Abgeschlossene Aufgaben“ aus.',
        write_dont_send_request_folder_name:
            'Bitte wählen Sie einen Namen für den Ordner „Entwurfsanforderung“ aus.',
        save_attntion: 'Are you sure you want to proceed?',
        changing_name: 'Umbenennung...',
        sucsses_message: 'Änderungen wurden erfolgreich gespeichert.',
        file_volume: 'File size',
        file_upload: 'sollte 300 kb nicht überschreiten.',
        file_type: 'Laden Sie eine Datei im JPG-, PNG- oder GIF-Format hoch.',
        delete_sucsses: 'Erfolgreich gelöscht',
        reset_confirm:
            'Sind Sie sicher, dass Sie das Hintergrundbild als Standard zurücksetzen möchten?',
        doing_sucsses: 'Erfolgreich beendet',
        file_type_attention: 'Only jpg, png or gif files are supported.',
        delete_confirm_massage: 'Sind Sie sicher, dass Sie löschen möchten?',
        your_request_has_been_sent: 'Ihre Anfrage wurde erfolgreich gesendet.',
        confirm_message: 'Bist du sicher?',
        save_successfully: 'Erfolgreich gespeichert.',
        password_not_currect: 'Benutzername oder Passwort sind nicht korrekt.',
        connection_not_safe: 'Die Verbindung ist nicht gesichert.',
        pleas_try_later: 'Bitte versuche es erneut',
        username_or_password_is_null: 'Benutzername oder Passwort sind nicht korrekt.',
        control_behavior_not_exists: 'There is no form behavior for this form element.'
    },
    apply: 'Anwenden',
    all_messages: 'All messages',
    new_message: 'Neue Nachricht',
    messages_sent: 'Gesendeten Nachrichten',
    messages_receive: 'Empfangene Nachrichten',
    messages_receive_notification: 'Du hast eine neue Nachricht!',
    sign_out: 'Abmelden',
    change_password: 'Kennwort ändern',
    'first-page': 'Erste Seite',
    'view-form': 'Formular ansehen',
    invalid_date: 'Ungültiges Datum',
    operation_faild: 'Operation fehlgeschlagen.',
    session_end_you_will_redirect_to_the_homepage:
        'Ihre Sitzung ist beendet, Sie werden zur Startseite weitergeleitet.',
    session_end_title: 'Session-Timeout.',
    login_again: 'Bitte melden Sie sich erneut an!',
    server_shutdown_template: 'Aufmerksamkeit! Der Server wird um 12:00 Uhr heruntergefahren.',
    server_notif_message: 'Geben Sie die Nachricht ein, an die Sie senden möchten',
    server_notif_message_users: 'Benutzer',
    server_notif_message_sent: 'Message was successfully sent!',
    server_notif_message_error: 'Ein Fehler ist aufgetreten!',
    process_tree: 'Geschichtsbaum',
    process_graph: 'Verlaufsdiagramm',
    debug_info: 'Debug-Informationen',
    process_history: 'Process History',
    print_form: 'Print',
    time_sheet: 'Stundenzettel',
    assign: 'Nach vorne',
    word_output: 'Nach Word exportieren',
    pdf_output: 'Als PDF exportieren',
    pdf_preview: 'PDF-Vorschau anzeigen',
    word_print: 'Drucken aus Word',
    report: 'Berichte',
    pdf_download: 'PDF-Datei herunterladen:',
    select_file: 'Datei aussuchen :',
    send_pdf: 'PDF-Datei senden',
    by_level: 'Mitarbeiter:',
    view_all_staff: 'Alle Mitarbeiter anzeigen',
    image: 'Bild',
    of: 'von',
    error: 'Fehler',
    has_form_less_events: 'hat Form weniger Ereignisse',
    more_items: 'More items...',
    'retrieve-data-error': 'Das Abrufen der Daten ist fehlgeschlagen.',
    upload_pdf_file: 'PDF-Datei einreichen.',
    download_pdf_file: 'PDF-Datei herunterladen',
    export: 'Export',
    not_selected: 'Nicht ausgewählt',
    news_detaile: 'Nachrichtendetails',
    new_task: 'Neue Aufgabe',
    id: 'ID',
    select_date: 'Bitte wählen Sie das Start- und Enddatum aus',
    select_all: 'Wählen Sie Alle',
    deselect_all: 'Alle abwählen',
    copied: 'Kopiert',
    unable_to_copy: 'Kopieren nicht möglich',
    password_setting: {
        current_password: 'Aktuelles Passwort',
        new_password: 'Neues Kennwort',
        confirm_new_password: 'Bestätige neues Passwort',
        messages: {
            current_password_is_wrong: 'Aktuelles Passwort ist falsch!',
            passwords_do_not_match: 'Passwörter stimmen nicht überein!',
            new_password_same_as_current:
                'Das neue Passwort darf nicht mit dem aktuellen Passwort identisch sein!',
            currentPassword_is_empty: 'Current password is empty',
            newPassword_is_empty: 'Neues Passwort ist leer',
            confirmNewPassword_is_empty: 'Bestätigen Sie, dass das neue Passwort leer ist'
        }
    },
    title: {
        news: 'Nachricht',
        announcements: 'Ankündigungen',
        procces_history: 'Prozesshistorie'
    },
    search_panel_control: {
        filter: 'Filter',
        clear_filter: 'Filter löschen'
    },
    grid_control: {
        add: 'Hinzufügen',
        add_inline: 'Inline-Hinzufügen',
        edit: 'Bearbeiten',
        edit_inline: 'Inline-Bearbeitung',
        del: 'Löschen',
        undel: 'Stornieren',
        export_excel: 'Nach Excel exportieren',
        import_excel: 'Import from Excel',
        template_excel: 'Laden Sie die Excel-Vorlage herunter',
        save: 'Speichern',
        cancel: 'Stornieren',
        view: 'Sicht',
        export: 'Export',
        grid_is_in_editing_mode:
            'befindet sich im Bearbeitungsmodus, bitte übernehmen Sie die Änderungen.',
        grid_is_in_editing_mode_you_cannot_do_anything:
            'Im Bearbeitungsmodus dürfen Sie keine Vorgänge ausführen.',
        grid_error_retrieving_data: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.',
        no_rows_selected: 'Keine Zeilen ausgewählt',
        remove_selected_row: 'Ausgewählte Zeile entfernen?',
        only_selected_columns_will_be_exported: 'Es werden nur ausgewählte Spalten exportiert',
        items_get_error: 'Beim Abrufen der Liste ist ein Fehler aufgetreten!',
        noDataText: 'Es sind keine Daten zum Anzeigen vorhanden.',
        saveChanges: 'Änderungen speichern',
        discard: 'Änderungen verwerfen',
        saved: 'Gerettet',
        save_unsuccessful: 'Speichern war nicht erfolgreich',
        sum: 'Sum',
        avg: 'Durchschn',
    },
    attachment_control: {
        add: 'Hinzufügen',
        new: 'Neu',
        select: 'Wählen',
        scan: 'Scan',
        new_version: 'Neue Version',
        delete: 'Löschen',
        delete_all: 'Alles löschen',
        revert: 'Zurückkehren',
        edit: 'Edit',
        download: 'Herunterladen',
        noFileExists: 'Es existiert keine Datei',
        select_file: 'Datei aussuchen',
        upload_file: 'Datei hochladen',
        upload_files: 'Daten hochladen',
        upload_files_all: 'Alle Dateien hochladen',
        upload_cancel: 'Hochladen abbrechen',
        view: 'Sicht',
        accepted_types: 'Akzeptierte Dateitypen',
        preview: 'Vorschau',
        one_file: '1 Datei',
        files: 'Dateien',
        each: 'jede',
        messages: {
            folder_default_not_found: 'Standardordner nicht gefunden!',
            delete_file: 'Sind Sie sicher, dass Sie löschen möchten?',
            delete_all_files: 'Sind Sie sicher, dass Sie ALLE Dateien löschen möchten?',
            save_Changes: 'Sind Sie sicher, dass Sie fortfahren möchten?',
            error_try_again: 'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!',
            file_edit_dont_allow: 'Sie dürfen diesen Dateityp nicht bearbeiten!',
            desktop_file_launcher_notFound:
                "DesktopFileLauncher-Dienst nicht gefunden! <a href='FileLuncher/FileLauncherSetup.exe' style='font-weight: Bold;'> hier <a/> herunterladen",
            reverted_Successfully: 'Die Datei wurde erfolgreich wiederhergestellt.',
            file_upload_count_error:
                'Die Anzahl der hinzugefügten Dateien überschreitet das zulässige Limit und kann nicht hochgeladen werden!',
            default_file_delete_error: 'Das Löschen der Standarddatei ist nicht möglich!',
            default_file_edit_error: 'Das Bearbeiten der Standarddatei ist nicht möglich!',
            file_type_error: 'Der Dateityp ist nicht zulässig!',
            scan_upload_error: 'Beim Scan ist ein Fehler aufgetreten!',
            scan_service_error: 'Scannerdienst nicht gefunden!',
            select_file_error: 'Keine Artikel ausgewählt!',
            file_edit_error: 'Beim Bearbeiten ist ein Fehler aufgetreten!',
            file_upload_successfully: 'Die Datei wurde erfolgreich hochgeladen!',
            file_size_error:
                'Die Dateigröße überschreitet das zulässige Limit und kann nicht hochgeladen werden!',
            file_repeat_name_error: 'Der Dateiname wird wiederholt!',
            file_select_count_error:
                'Die Anzahl der ausgewählten Dateien überschreitet das zulässige Limit!',
            file_upload_error: 'Beim Hochladen ist ein Fehler aufgetreten!',
            file_name_repeated_by_param: 'Datei {repeatedName} wird wiederholt.',
            'scan-opration-encountered-problem': 'Beim Scannen ist ein Problem aufgetreten',
            file_is_in_edit_mode: 'Die Datei befindet sich im Bearbeitungsmodus',
            dont_allow_edit_file: 'Eine Bearbeitung dieses Dateityps ist nicht möglich!',
            file_download_new_error: 'Die neu hochgeladene Datei kann nicht heruntergeladen werden',
            file_open_new_error: 'Die neu hochgeladene Datei kann nicht geöffnet werden'
        }
    },
    export_control: {
        title: 'Wählen Sie die Art des Exports aus',
        titlesetting: 'Titel Einstellung',
        showTableTitle: 'Titel Einstellung',
        backGround: 'Hintergrundfarbe',
        fontname: 'Schriftartenname',
        fontsize: 'Schriftgröße',
        fontcolor: 'Font Color',
        borderstyle: 'Grenzstil',
        bordercolor: 'Randfarbe',
        columnsetting: 'Spalteneinstellung',
        cellsetting: 'Zelleneinstellung',
        createfile: 'Erstelle Datei',
        bodysetting: 'Körpereinstellung',
        exit: 'Ausfahrt',
        columnfontstyle: 'Spaltenschriftstil',
        context: 'Kontext',
        contextstyle: 'Kontextstil',
        date: 'Datum',
        twodigit: 'Zahl mit bis zu zwei Dezimalstellen',
        fourdigit: 'Zahl mit bis zu vier Dezimalstellen'
    },
    assign_control: {
        no_name: 'kein_name',
        delete: 'löschen',
        messages: {
            item_is_repeated: 'Element {displayMember} wird wiederholt.'
        }
    },
    tree_control: {
        expand_collapse: 'Expandieren/Kollabieren',
        error: 'Fehler',
        warning: 'Warnung',
        add_new: 'Hinzufügen',
        show: 'Zeigen',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        messages: {
            item_delete_confirm: 'Sind Sie sicher, dass Sie löschen möchten?',
            item_get_info_error: 'Beim Abrufen der Informationen ist ein Fehler aufgetreten!'
        }
    },
    comment_control: {
        delete_confirm: 'Sind Sie sicher, dass Sie löschen möchten?',
        back: 'zurück',
        save: 'Anwenden',
        empty: 'Der Kommentar darf nicht leer sein'
    },
    dropdown_control: {
        select: 'Wählen...',
        messages: {
            items_get_error: 'Beim Abrufen der Liste ist ein Fehler aufgetreten!'
        }
    },
    context: {
        new_folder: 'Neu',
        edit_folder: 'Bearbeiten',
        delete_folder: 'Löschen',
        open_in_new_window: 'In einem neuen Fenster öffnen',
        rules: 'Ordnerregeln verwalten',
        messages: 'Mitteilungen',
        send_message: 'Nachricht senden',
        view_received_messages: 'Empfangene Nachrichten anzeigen',
        view_sent_message: 'Gesendete Nachrichten anzeigen'
    },
    cartable_grid: {
        open_in_new_window: 'In einem neuen Fenster öffnen',
        history: 'Geschichte',
        tree_history: 'Baum',
        graph_history: 'Graph',
        time_sheet: 'Stundenzettel',
        change_folder: 'Ändere den Ordner',
        flag: 'Flagge',
        no_flag: 'Ohne Flagge',
        assignTask: 'Zuordnen',
        FolderService_CanNotTransfer: 'Die Übertragung in diesen Ordner ist nicht möglich',
        total_tasks: 'Gesamtaufgaben in diesem Ordner:',
        total_unread_tasks: 'Gesamtzahl der ungelesenen Aufgaben:'
    },
    create_physical_folder: {
        add_modal_header: 'Ordner erstellen',
        edit_modal_header: 'Ordner bearbeiten',
        folder_title: 'Titel',
        folder_type: 'Typ',
        dont_send_request: 'Entwurfsanfragen',
        undone_works: 'Ausstehende Aufgaben',
        done_works: 'Abgeschlossene Aufgaben',
        folder_title_empty: 'Der Ordnertitel ist leer',
        folder_title_length_error: 'Die Titellänge sollte 50 Zeichen nicht überschreiten.',
        folder_title_validate_error: 'Der Name ist ungültig.'
    },
    footer: {
        powered_by_raybpms: 'Unterstützt von DBP',
        rights: 'Interfacing Technologies Corporation. Alle Rechte vorbehalten.',
        url: 'https://www.interfacing.com/',
        help: 'Helfen',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'Fehler beim Tracking!',
        tracking_code_is_empty: 'Tracking-Code ist leer!',
        error_on_delete: 'Beim Löschvorgang ist ein Fehler aufgetreten',
        please_select_pdf_file: 'Bitte wählen Sie eine PDF-Datei aus',
        operation_is_not_allowed: 'Du darfst nicht.',
        events_cannot_be_loaded: 'Veranstaltungen können nicht geladen werden!',
        server_error: 'Serverfehler',
        you_are_not_allowed_to_view_form: 'Sie haben keine Berechtigung, auf das Formular zuzugreifen',
        access_denied: 'Zugriff abgelehnt',
        print_failed: 'Der Druck ist fehlgeschlagen',
        error_occured: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Administrator.',
        task_not_found:
            'Diese Aufgabe konnte nicht gefunden werden. Möglicherweise wurde sie von einem anderen Benutzer ausgeführt.',
        application_is_not_deployed: 'Die Anwendung ist nicht bereitgestellt.',
        save_in_progress: 'Der Speichervorgang läuft gerade. Bitte versuchen Sie es später noch einmal.'
    },
    Lock: {
        'bpms-lock-expired': 'Sperre ist abgelaufen!',
        'maximum-bpms-lock-user': 'Das Limit gleichzeitiger Benutzer überschreitet die Lizenz!',
        'bpms-lock-failed': 'Schloss nicht gefunden!'
    },
    slider_managment: {
        title: 'Slider-Bilder',
        slider_show_is_repeat: 'Es gibt eine Wiederholung in der Bildreihenfolge des Schiebereglers.',
        slider_show_is_empty: 'Die Bildreihenfolge ist leer.',
        upload_message: 'Ihre Bilder sind mehr als grenzenlos!',
        image_displaying: 'Bildreihenfolge',
        same_name_massage: 'Es existiert ein Bild mit demselben Namen!',
        image_is_too_large: 'Die Bildgröße ist zu groß',
        name: 'Name',
        image: 'Bild',
        pic_type: 'Wählen Sie eine Bilddatei (jpg, png, gif) mit weniger als 80 KB aus.',
        image_required: 'Wählen Sie ein Bild aus.',
        level_required: 'Wählen Sie die Reihenfolge des Bildes aus',
        new: 'Neu',
        edit: 'Bearbeiten',
        empty: 'leer'
    },
    cartable_management: {
        cartable_managment_title: 'Aufgabenlistenverwaltung – Standardordner umbenennen',
        cartable_date_setup: 'Datumseinstellungen in der Aufgabenliste',
        status: 'Status:',
        exact: 'Genau',
        limited: 'Angenähert',
        exact_dsc: 'Genaues Datum und Uhrzeit anzeigen',
        limited_dsc: 'Vergangene Tage ungefähr anzeigen',
        select_person:
            'Wählen Sie eine Person, eine Jobposition oder eine Gruppe aus, um den Hauptordner umzubenennen',
        dont_works: 'Ordner für To-Do-Aufgaben:',
        done_work: 'Abgeschlossene Aufgabenordner:',
        dont_send_request: 'Entwurfsanfragen:',
        changing_folder_name: 'Ordner umbenennen',
        attention_message: 'Um die neuesten Änderungen anzuzeigen, melden Sie sich bitte erneut an.',
        tree_expanded: 'Der ausstehende Ordner der ersten Rolle wird nach der Anmeldung ausgewählt.',
        hide_default_user_folders:
            'Standardordner des Benutzers ausblenden (z. B. „Ausstehend“, „In Arbeit“ und „Entwurf“)',
        expand_folders_by_default: 'Erweitern Sie standardmäßig alle Benutzer- und Mitarbeiterordner',
        tree: 'Baumordner'
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'Urlaubskalender',
        year: 'Jahr:',
        show_calendar: 'Kalender anzeigen',
        year_calendar: 'Jahr',
        messages: {
            year_validate_message: 'Das Jahr ist ungültig!'
        }
    },
    appearance_management: {
        ui_setup: 'Personalisierung',
        back_image: 'Hintergrund',
        default_theme: 'Standardthema',
        banner: 'Top-Banner',
        back_image_dsc:
            'Um die Anmeldeseite und die Startseite auszuwählen, verwenden Sie das untenstehende Formular.',
        default_theme_dsc:
            'Bitte wählen Sie eines der folgenden Themen als Standardthema für die Aufgabenliste aus.',
        banner_dsc: 'Verwenden Sie das Formular unten, um ein Website-Banner auszuwählen.HINWEIS:',
        before_login: 'Bild vor dem Login:',
        after_login: 'Login-Bild:',
        current_img: 'Aktuelles Bild',
        location: 'Standort:',
        repeat: 'Wiederholen',
        no_repeat: 'Ohne Wiederholung',
        center: 'Center',
        back_color: 'Hintergrundfarbe',
        upload_save: 'Hochladen und speichern',
        select_theme: 'Thema wählen',
        preview: 'Themenvorschau',
        set_theme: 'Thema anwenden',
        height_attention: 'Die Bildhöhe sollte 149 Pixel nicht überschreiten.',
        image_attention: 'Die Bildhöhe des Banners sollte 149 Pixel betragen.',
        back_image_file: 'Standardbild des Banners',
        right_iamge: 'Bild links:',
        center_image: 'Mittleres Bild:',
        left_image: 'Bild rechts:',
        for_field: 'Für Feld:',
        select_image_file: 'Bitte wählen Sie eine Bilddatei aus',
        delete_current_banner: 'Aktuelles Banner löschen',
        image_restore: 'Standardbild wiederherstellen',
        select_theme_for_current_user: 'Wählen Sie ein Thema für den aktuellen Benutzer aus.',
        file_type: 'Es werden nur JPG-, PNG- oder GIF-Dateien unterstützt.',
        server_wide: 'Serverweit',
        user: 'Benutzer',
        save: 'Speichern'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                'Nur Bildgröße, Symbolform, Position und Textgröße ändern sich. Sind Sie sicher, dass Sie fortfahren möchten?',
            save_sucsses_all: 'Änderungen werden auf alles angewendet.',
            save_confirm: 'Sind Sie sicher, dass Sie fortfahren möchten?',
            save_sucsses: 'Änderungen wurden erfolgreich gespeichert.',
            insert_number:
                'Um das Symbol oder die Bildgröße zu ändern, können Sie nur die Zahl eingeben.',
            icon_size: 'Die Symbolgröße sollte zwischen 25 und 140 Pixel liegen.',
            file_type: 'Es werden nur JPG-, PNG- oder GIF-Dateien unterstützt.',
            icon_volume: 'Die Symbol-/Bildgröße sollte 30 KB nicht überschreiten.',
            insert_only_number: 'Sie können nur Zahlen eingeben'
        },
        home_ui_setting: 'Home-Einstellungen',
        items_menu: 'Menü „Elemente“.',
        description:
            'Um der Startseite Menüs hinzuzufügen, wählen Sie das Element aus und ändern Sie seine Einstellungen.',
        menu_setting: 'Menüeinstellungen',
        show_menu: 'Menü auf der Startseite anzeigen',
        first_level: 'In der ersten Ebene anzeigen',
        not_show_top: 'Im oberen Menü ausblenden',
        display_name: 'Angezeigter Name',
        icon_width: 'Symbolbreite',
        icon_width_ph: 'Symbol-/Bildbreite',
        icon_height: 'Symbolhöhe',
        icon_height_ph: 'Symbol-/Bildhöhe',
        home_menu_icon: 'Menüsymbol/Bild auf der Startseite',
        select_file: 'Bitte wählen Sie eine Datei im JPG-, PNG- oder GIF-Format aus.',
        icon_view: 'Symbol-/Bildform',
        circul: 'Kreis',
        squared: 'Quadrat',
        rectangle: 'Rechteck',
        text_location: 'Textposition',
        up_image: 'Oberes Bild',
        center_image: 'Bild zentrieren',
        bottom_image: 'Unteres Bild',
        up_text: 'Top-text',
        center_text: 'Mittlerer Text',
        bottom_text: 'Fußzeile',
        delete_image: 'Lösche Bild',
        text_color: 'Textfarbe',
        float_text_color: 'Float-Textfarbe',
        hilight_text_color: 'Markieren Sie die Textfarbe',
        save_button_title: 'Alle Einstellungen außer Bild, angezeigter Name und Hinzufügen zum Menü',
        doing_settup: 'Änderungen auf alle anwenden',
        check_mark: 'Änderungen auf alle anwenden',
        published_date: 'Veröffentlichungsdatum:'
    },
    news_management: {
        message: {
            insert_title: 'Bitte geben Sie den Titel ein.',
            title_character_length: 'Die Titellänge sollte 255 Zeichen nicht überschreiten.',
            text_character_length: 'Die Textlänge sollte 4000 Zeichen nicht überschreiten.',
            insert_text: 'Bitte geben Sie den Text ein.',
            number_priority: 'Wählen Sie die Priorität anhand der Zahlen aus.',
            number_large:
                'Die ausgewählte Nummer für die Nachrichtenpriorität besteht aus mehr als 4 Ziffern.',
            file_volume: 'Die Dateigröße sollte weniger als 100 KB betragen.'
        },
        news: 'Nachricht',
        add: 'Hinzufügen',
        title: 'Titel',
        text: 'Text',
        text_color: 'Textfarbe',
        enable_show: 'Für normale Benutzer sichtbar',
        news_setup: 'Nachrichten-Setup',
        news_image: 'Nachrichtenbild',
        news_priority: 'Nachrichtenpriorität',
        current_pic: 'Hochgeladene Bilder',
        delete_curr_pic: 'Delete current image',
        pic_type: 'Ein JPG-, PNG- oder GIF-Dateiformat mit weniger als 100 KB.',
        id: 'ID',
        createdAt: 'Datum',
        username: 'Username',
        control: 'Kontrolle'
    },
    message_management: {
        messages: {
            repeated_recipient: 'Der Empfänger wird wiederholt',
            title_is_empty: 'Der Nachrichtentitel ist leer',
            success_send: 'Die Nachricht wurde erfolgreich gesendet',
            unselected_recipient: 'Kein Empfänger ausgewählt.'
        },
        reply_message: 'Antwort',
        title: 'Titel:',
        message_text: 'Nachrichtentext:',
        attachment: 'Anhänge',
        recipients: 'Empfänger',
        select: 'Wählen ...',
        add_to_list: 'Zur Liste hinzufügen',
        back: 'Zurückkehren',
        sended: 'Nachricht senden',
        sended_by: 'Gesendet von:',
        send_date: 'Sendezeit:',
        message_show: 'Zeige Nachricht',
        no_permission: 'Ihnen ist der Zutritt nicht gestattet!',
        subject: 'Thema:',
        date_time_send: 'Sendezeit und -datum',
        sender: 'Absender',
        receiver: 'Empfänger',
        menu_title: 'Mitteilungen',
        received: 'Nachricht erhalten',
        send_email: 'E-Mail senden',
        send_sms: 'SMS senden'
    },
    task_assign: {
        messages: {
            success_assign: 'Erfolgreich weitergeleitet.',
            unselected_recipient: 'Kein Empfänger ausgewählt.'
        },
        assign: 'Weiterleiten – Zuweisen',
        recipients: 'Empfänger',
        description: 'Beschreibung',
        last_dsc: 'Aktuelle Beschreibung verwenden',
        out_work: 'Aktuelle Aufgabe beenden',
        keep_sender: 'Behalten Sie den aktuellen Absender bei'
    },
    process_log: {
        status: 'Status',
        event: 'Ereignis',
        user: 'Benutzer',
        user_position: 'Berufliche Stellung',
        work_title: 'Berufsbezeichnung',
        responsible: 'Verantwortlich',
        create_date: 'Startdatum der Aufgabe',
        done_date: 'Enddatum der Aufgabe',
        folder_name: 'Ordnernamen',
        start_task: 'Starter-Aktivität',
        human_task: 'Menschliche Aktivität',
        fork_task: 'Gespaltene Aktivität',
        join_task: 'Verbundene Aktivität',
        system_task: 'Systemaktivität',
        common_task: 'Gemeinsame Aktivität'
    },
    timesheet_register: {
        messages: {
            time_reng: 'Das Startdatum darf das Enddatum nicht überschreiten',
            select_row: 'Bitte wählen Sie eine Zeile aus.',
            delete_timesheet_confirm:
                'Sind Sie sicher, dass Sie die aktuelle Arbeitszeittabelle löschen möchten?',
            save_time_confirm:
                'Dieser Zeitraum wird für eine andere Aufgabe ausgewählt. Möchten Sie es durch aktuelle Takes ersetzen?',
            can_not_edit:
                'Dieser Zeitraum wird für eine abgeschlossene Aufgabe gespeichert. Bearbeiten ist nicht gestattet.',
            date_not_valid: 'Datum ist ungültig'
        },
        errors: {
            error_in_remaining_time: 'In der verbleibenden Zeit ist ein Fehler aufgetreten',
            get_timesheet: 'Beim Export der Arbeitszeittabelle ist ein Fehler aufgetreten',
            error_in_save: 'Beim Speichern ist ein Fehler aufgetreten',
            error: 'Fehler',
            error_in_delete: 'Beim Löschen ist ein Fehler aufgetreten'
        },
        work_dsc: 'Arbeitsbeschreibung',
        date: 'Datum',
        start_time: 'Startzeit',
        end_time: 'Endzeit',
        remaining_time: 'Verbleibende Zeit',
        description: 'Beschreibung',
        timesheet: 'Stundenzettel',
        current_work: 'Der aktuellen Aufgabe zuweisen',
        persian_date: 'Date',
        weekly_plan: 'Wöchentlicher Plan',
        prev_week: 'Letzte Woche',
        next_week: 'Nächste Woche',
        plus: 'Hinzufügen',
        all_work: 'Alle aktuellen Werke',
        delete: 'Löschen'
    },
    delegation_management: {
        messages: {
            select_user: 'Bitte wählen Sie den Empfehlungsbenutzer aus.',
            select_date: 'Bitte wählen Sie das Start- und Enddatum aus',
            select_folder: 'Bitte wählen Sie die Ordner aus, die der Delegierende anzeigen kann',
            compare_fromDate_toDate: 'Das Enddatum liegt nach dem Startdatum'
        },
        folder_to_delegate: 'Ordner, die der Delegierte anzeigen kann:',
        delegations: 'Delegationen',
        delegation_edit: 'Delegation bearbeiten',
        delegation_user: 'Delegierter Benutzer:',
        all_time: 'Dauerhaft',
        from_date: 'Ab Datum:',
        to_date: 'Miteinander ausgehen',
        start_date: 'Startdatum',
        finish_date: 'Endtermin',
        delegate_to: 'Delegieren Sie an:',
        more_item: 'Delegieren Sie an:',
        all_item: 'Alle Elemente',
        remove_delegate: 'Delegierten entfernen',
        delegation_create: 'Hinzufügen',
        no_records: 'Kein Datensatz entspricht Ihren Schlüsselwörtern',
        delegation_add: 'Delegation hinzufügen',
        delegation_user_placeholder: 'Tippe um zu suchen',
        more_users: 'Es gibt mehr Ergebnisse, bitte verwenden Sie bessere Schlüsselwörter'
    },
    secret_code: {
        cancel: 'Stornieren',
        submit: 'Einreichen',
        required: 'Der Code ist erforderlich',
        expired: 'Der Code ist ungültig oder abgelaufen',
        legal_sentence:
            'Durch die Genehmigung mit Ihrer elektronischen Signatur unterzeichnen Sie das Dokument elektronisch. Sie erklären sich damit einverstanden, dass </br> Ihre elektronische Signatur die gleiche Rechtsgültigkeit und Wirkung wie Ihre handschriftliche Unterschrift auf dem Dokument hat und dass sie dieselbe Bedeutung wie Ihre handschriftliche Unterschrift hat.',
        code: 'Code:',
        message:
            'Wir haben Ihnen einen Geheimcode an Ihre E-Mail-Adresse gesendet. Bitte kopieren Sie es und fügen Sie es hier ein.',
        title: 'Approval Authentication'
    },
    no: 'Nein',
    yes: 'Ja',
    close_form_warning: 'Sie sind sicher, dass Sie beenden wollen? Jede Änderung geht verloren.',
    form_behavior: 'Formularverhalten',
    form: 'Bilden',
    today: 'Heute',
    rule_management: {
        rule_modal: {
            clear_tooltip: 'klar',
            validation: {
                name: 'Name ist erforderlich'
            },
            not_selected_option: 'Name ist erforderlich',
            in_folder: 'Ordner',
            app: 'Anwendung',
            transcript: 'Transkript',
            read: 'Lesen',
            flag: 'Flagge',
            priority: 'Priorität',
            done_date: 'Fertigdatum',
            response_date: 'Antwortfrist',
            create_date: 'Erstellungsdatum',
            task_type: 'Typ',
            subject: 'Thema',
            name: 'Regelname'
        },
        are_you_sure: 'Möchten Sie die Regel wirklich löschen?',
        edit_rule: 'Regel bearbeiten',
        add_new_rule: 'Neue Regel',
        rule_name: 'Name',
        operation: 'Betrieb',
        title: 'Ordnerregeln verwalten'
    },
    logs_management: {
        start_datetime: 'Startdatum und Uhrzeit',
        send_message_connection: 'Nachricht an diese Verbindung senden',
        send_message_all: 'Senden Sie eine Nachricht an alle',
        active_connections: 'Aktive Verbindungen',
        logout_datetime: 'Abmeldedatum und Uhrzeit',
        login_datetime: 'Anmeldedatum und Uhrzeit',
        user: 'Benutzer',
        copy_clipboard: 'In die Zwischenablage kopieren',
        application_log: 'Anwendungsprotokoll',
        result: 'Ergebniscode',
        user_log: 'Benutzerprotokoll',
        epc_log: 'EPC-Anrufprotokoll',
        duration: 'Dauer (ms)',
        key: 'Schlüsselparameter',
        search: 'Suchen...',
        severity: 'Schwere',
        method: 'Methode',
        api: 'API',
        session: 'Sitzung',
        id: 'Id',
        api_log: 'API-Aufrufprotokoll',
        error_message: 'Fehlermeldung',
        error_log: 'Fehlerprotokolle',
        server_name: 'Servername',
        application_name: 'Anwendungsname',
        staff_id: 'Mitarbeiter ID',
        level: 'Ebene',
        date: 'Hergestellt in',
        error_number: 'Nummer',
        show_details:
            'Aktivieren Sie Fehlerdetails anzeigen. Durch die Aktivierung dieser Option sehen Benutzer bei jedem Fehlerauftritt die vollständige technische Fehlermeldung.'
    },
    ray_datetimepicker: {
        ok: 'OK',
        time_not_valid: 'Die Zeit ist ungültig!',
        date_not_valid: 'Datum ist ungültig!'
    },
    ray_payment: {
        pay: 'Zahlung',
        through: 'Durch',
        currency: 'Rial',
        not_payed_status: 'Nicht bezahlt',
        failed_status: 'Fehler bei der Zahlung',
        payed_status: 'Bezahlt',
        wait_status: 'Warten auf die Zahlung.',
        messages: {
            close_pay_win: 'Schließen Sie zunächst das Zahlungsfenster.'
        }
    },
    rule_criterias: {
        select_folder: 'Ordner auswählen',
        operation: 'Betrieb',
        rule_name: 'Regelname',
        action: 'Aktion',
        no_actions: 'Keine Aktion!',
        flag_error: 'Beim Flag-Update ist ein Fehler aufgetreten!',
        tag_error: 'Beim Tag-Update ist ein Fehler aufgetreten!',
        no_permission: 'Sie benötigen eine Erlaubnis!',
        edit_rule: 'Regel bearbeiten',
        new_rule: 'Neue Regel'
    }
}
export default localeMessages
