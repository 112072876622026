var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        showModal: _vm.showModal,
        "data-backdrop": "false",
        size: { "my-col": true, "col-lg-12": true },
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("title.procces_history")))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c(
          "div",
          { class: [_vm.direction ? "k-rtl" : ""] },
          [
            _c("treeList", {
              attrs: {
                columns: _vm.columns,
                schema: _vm.schema,
                data: _vm.modalData,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }