import Loading from '../widgets/loading/loading.vue';
export default {
    data() {
        return {
            sliderItems: [],
            currentSlide: 1
        }
    },
    mounted() {
        this.sliderItems = Array.isArray(payload.sliderItems) ? payload.sliderItems : [];
    },
    components: {
        Loading
    }
}
