var localeMessages = {
    user: 'משתמש',
    staff: 'תפקיד',
    save_successfully: ' נשמר בהצלחה',
    error_saving: 'שגיאה בשמירה',
    help: 'עזרה',
    ok: 'אוקיי',
    send_as_staff: 'שלח כתפקיד',
    remove_record: 'הסר רשומה',
    homepage: 'דף הבית',
    view_form: 'תצוגת טופס',
    confirm: 'אשר',
    prev_row: 'שורה קודמת',
    next_row: 'השורה הבאה',
    save_and_new: 'שמירה וחדש',
    save_and_next: 'שמור והבא',
    save_and_previous: 'שמור וקודם',
    save_and_return: 'שמור וחזור',
    cancel_and_return: 'בטל וחזור',
    submit_form: 'הגש טופס',
    return: 'חזור',
    save_form: 'שמור טופס',
    login: 'כניסה',
    sign_in: 'היכנס',
    username: 'שם משתמש',
    password: 'סיסמה',
    date: 'תאריך',
    author: 'מחבר',
    more_details: 'הצג פרטים נוספים',
    show: 'הצג',
    close: 'סגור',
    open: 'פתח',
    languages: 'שפות',
    try_again: 'נסה שוב',
    tracking: 'מעקב',
    tracking_code: 'קוד מעקב',
    history: 'היסטוריה',
    process_level: 'רמת התהליך: <strong>{level}</strong>',
    loading: 'טוען',
    uploading: 'העלאה',
    save: 'שמור ',
    remove: 'הסר',
    attention: '* תשומת לב:',
    select: 'בחר',
    please: 'Please',
    doing: 'Doing',
    upload_doing: 'מעלה ושומר',
    page_refresh: 'כדי לראות את השינויים, רענן את המטמון (Ctrl-F5).',
    send: 'לִשְׁלוֹחַ',
    insert: 'לְהַכנִיס',
    edit: 'לַעֲרוֹך',
    delete: 'לִמְחוֹק',
    reply: 'תשובה',
    are_you_sure: 'האם אתה בטוח?',
    control: 'פקד',
    show_error_details: 'הצג פרטי שגיאה',
    warning: 'אזהרה',
    search: 'חפש',
    empty: 'ריק',
    new: 'חדש',
    priority: 'עדיפות',
    priority_low: 'נמוך',
    priority_normal: 'בינוני',
    priority_high: 'גבוה',
    priority_very_high: 'דחוף',
    subject: 'נושא',
    created_date: 'נוצר ב',
    response_deadline: 'מועד אחרון לתגובה',
    done_date: 'בוצע ב',
    sender: 'שולח',
    starter_user: 'משתמש מתחיל',
    starter_staff: 'תפקיד מתחיל',
    is_assignable: 'ניתן להקצאה',
    insert_label: 'תווית',
    continue: 'המשך',
    submit: 'שלח',
    cancel: 'לְבַטֵל',
    cancel_nested: 'בטל',
    other: 'אחר',
    settings: 'הגדרות',
    change_theme: 'ערכות נושא',
    message: 'הודעה',
    messages: {
        not_any_record_found: 'הרשומה לא נמצאה.',
        not_history_for_tracking_code_found: 'לא נמצאה היסטוריה עבור קוד המעקב.',
        not_selected_principals: 'NO PRINCIPALS ARE SELECTED RENAME FOLDER.',
        write_dont_work_folder_name: 'אנא בחר שם לתיקיית משימות המטלות.',
        write_done_work_folder_name: 'אנא בחר שם לתיקיית המשימות שהושלמו.',
        write_dont_send_request_folder_name: 'אנא בחר שם לתיקיית טיוטת בקשות.',
        save_attntion: 'האם אתה בטוח שברצונך להמשיך?',
        changing_name: 'משנה שם ...',
        sucsses_message: 'השינויים נשמרו בהצלחה.',
        file_volume: 'גודל הקובץ',
        file_upload: 'לא יכול לחרוג מעל 300 kb.',
        file_type: 'העלה קובץ בפורמט jpg, png או gif.',
        delete_sucsses: 'נמחק בהצלחה',
        reset_confirm: 'האם אתה בטוח שברצונך לאפס את תמונת הרקע כברירת מחדל?',
        doing_sucsses: 'הושלם בהצלחה',
        file_type_attention: 'רק קבצי jpg, png או gif נתמכים.',
        delete_confirm_massage: 'אתה בטוח שאתה רוצה למחוק?',
        your_request_has_been_sent: 'בקשתך נשלחה בהצלחה.',
        confirm_message: 'האם אתה בטוח?',
        save_successfully: 'נשמר בהצלחה.',
        password_not_currect: 'שם המשתמש או הסיסמה אינם נכונים.',
        connection_not_safe: 'החיבור לא מאובטח.',
        pleas_try_later: 'אנא נסה שוב',
        username_or_password_is_null: 'שם המשתמש או הסיסמה אינם נכונים.',
        control_behavior_not_exists: 'There is no form behavior for this form element.'
    },
    apply: 'החל',
    all_messages: 'כל ההודעות',
    new_message: 'הודעה חדשה',
    messages_sent: 'הודעות שנשלחו',
    messages_receive: 'הודעות שהתקבלו',
    messages_receive_notification: 'יש לך הודעה חדשה!',
    sign_out: 'התנתק',
    change_password: 'שנה סיסמא',
    'first-page': 'עמוד ראשון',
    'view-form': 'צפה בטופס',
    invalid_date: 'תאריך לא חוקי',
    operation_faild: 'פעולה נכשלה',
    session_end_you_will_redirect_to_the_homepage: 'ההפעלה שלך הסתיימה, אתה מנותב לדף הבית.',
    session_end_title: 'המערכת הגיעה למגבלת הזמן הקצוב להפעלה.',
    login_again: 'אנא התחבר שוב!',
    server_shutdown_template: 'תשומת הלב! השרת נכבה בשעה 12:00 בבוקר.',
    server_notif_message: 'הזן את ההודעה שברצונך לשלוח אל',
    server_notif_message_users: 'משתמשים',
    server_notif_message_sent: 'ההודעה נשלחה בהצלחה!',
    server_notif_message_error: 'אירעה שגיאה!',
    process_tree: 'עץ ההיסטוריה',
    debug_info: 'מידע על ניפוי באגים',
    process_graph: 'גרף היסטוריה',
    process_history: 'היסטוריית תהליכים',
    print_form: 'הדפס',
    time_sheet: 'גליון זמנים',
    assign: 'העבר',
    word_output: 'ייצא ל-Word',
    pdf_output: 'ייצא ל-PDF',
    pdf_preview: 'תצוגה מקדימה של PDF',
    word_print: 'הדפס מ- Word',
    report: 'דוחות',
    pdf_download: 'הורד קובץ PDF:',
    select_file: 'בחר קובץ :',
    send_pdf: 'שלח קובץ PDF',
    by_level: 'צוות:',
    view_all_staff: 'ראה את כל הצוות',
    image: 'תמונה',
    of: 'של',
    error: 'שגיאה',
    has_form_less_events: 'HAS FORM LESS EVENTS',
    more_items: 'עוד פריטים...',
    'retrieve-data-error': 'אחזור נתונים נכשל.',
    upload_pdf_file: 'הגש קובץ pdf.',
    download_pdf_file: 'הורד קובץ pdf',
    export: 'Export',
    not_selected: 'לא נבחר',
    news_detaile: 'NEWS DETAILES',
    new_task: 'משימה חדשה',
    select_date: 'אנא בחר את תאריך ההתחלה והסיום',
    id: 'ID',
    select_all: 'בחר הכל',
    deselect_all: 'הסר סימון מהכל',
    copied: 'מוּעֲתָק',
    unable_to_copy: 'לא ניתן להעתיק',
    password_setting: {
        current_password: 'סיסמה נוכחית',
        new_password: 'סיסמה חדשה',
        confirm_new_password: 'אשר סיסמה חדשה',
        messages: {
            current_password_is_wrong: 'הסיסמה הנוכחית שגויה!',
            passwords_do_not_match: 'סיסמאות אינן תואמות!',
            new_password_same_as_current: 'סיסמה חדשה לא יכולה להיות זהה לסיסמה הנוכחית!',
            currentPassword_is_empty: 'הסיסמה הנוכחית ריקה',
            newPassword_is_empty: 'הסיסמה החדשה ריקה',
            confirmNewPassword_is_empty: 'אשר שהסיסמה החדשה ריקה'
        }
    },
    title: {
        news: 'חדשות',
        announcements: 'הכרזות',
        procces_history: 'היסטורית התהליך'
    },
    search_panel_control: {
        filter: 'מסנן',
        clear_filter: 'נקה מסנן'
    },
    grid_control: {
        add: 'הוסף',
        add_inline: 'הוסף',
        edit: 'ערוך',
        edit_inline: 'ערוך',
        del: 'מחק',
        undel: 'לְבַטֵל',
        export_excel: 'ייצא לאקסל',
        import_excel: 'ייבא מאקסל',
        template_excel: 'הורד תבנית אקסל',
        save: 'שמור',
        cancel: 'בטל',
        view: 'הצג',
        export: 'ייצא',
        grid_is_in_editing_mode: 'אתה נמצא במצב עריכה, אנא החל שינויים.',
        grid_is_in_editing_mode_you_cannot_do_anything: 'אינך רשאי לבצע שום פעולות במצב עריכה.',
        grid_error_retrieving_data: 'אירעה שגיאה באחזור הנתונים.',
        no_rows_selected: 'לא נבחרו שורות',
        remove_selected_row: 'להסיר את השורה שנבחרה?',
        only_selected_columns_will_be_exported: 'רק העמודות שנבחרו יוצאו',
        items_get_error: 'אירעה שגיאה במהלך קבל רשימה!',
        noDataText: 'אין נתונים להציג.',
        saveChanges: 'שמור שינויים',
        discard: 'בטל שינויים',
        saved: 'שמור',
        save_unsuccessful: 'השמירה לא הצליחה',
        sum: 'סכ',
        avg: 'ממוצע',
    },
    attachment_control: {
        add: 'הוסף',
        new: 'חדש',
        select: 'בחר',
        scan: 'סרוק',
        new_version: 'גרסה חדשה',
        delete: 'מחק',
        delete_all: 'מחק הכל',
        revert: 'חזור',
        edit: 'ערוך',
        download: 'הורד',
        noFileExists: 'אין קובץ',
        select_file: 'בחר קובץ',
        upload_file: 'העלה קובץ',
        upload_files_all: 'העלה את כל הקבצים',
        upload_cancel: 'בטל את ההעלאה',
        view: 'הצג',
        messages: {
            folder_default_not_found: 'תיקיית ברירת המחדל לא נמצאה!',
            delete_file: 'האם אתה בטוח שברצונך למחוק?',
            delete_all_files: 'האם אתה בטוח שברצונך למחוק את כל הקבצים?',
            save_Changes: 'האם אתה בטוח שברצונך להמשיך?',
            error_try_again: 'אירעה שגיאה במהלך השמירה, אנא נסה שוב!',
            file_edit_dont_allow: 'אינך רשאי לערוך קובץ מסוג זה!',
            desktop_file_launcher_notFound:
                "שירות DesktopFileLauncher לא נמצא! הורד מ- <a href='FileLuncher/FileLauncherSetup.exe' style='font-weight: bold;'> לכאן <a/> ",
            reverted_Successfully: 'הקובץ הוחזר בהצלחה.',
            file_upload_count_error: 'מספר הקבצים שנוספו חורג מהמגבלה המותרת ולא ניתן להעלותם!',
            default_file_delete_error: 'מחיקת קובץ ברירת המחדל אינה אפשרית!',
            default_file_edit_error: 'עריכת קובץ ברירת המחדל אינה אפשרית!',
            file_type_error: 'סוג הקובץ אינו מורשה!',
            scan_upload_error: 'אירעה שגיאה במהלך הסריקה!',
            scan_service_error: 'שירות הסורק לא נמצא!',
            select_file_error: 'לא נבחרו פריטים!',
            file_edit_error: 'אירעה שגיאה במהלך העריכה!',
            file_upload_successfully: 'הקובץ הועלה בהצלחה!',
            file_size_error: 'גודל הקובץ חורג מהמגבלה המותרת ולא ניתן להעלותו!',
            file_repeat_name_error: 'שם הקובץ חוזר על עצמו!',
            file_select_count_error: 'מספר הקבצים שנבחרו חורג מהמגבלה המותרת!',
            file_upload_error: 'אירעה שגיאה במהלך ההעלאה!',
            file_name_repeated_by_param: 'הקובץ {repeatName} חוזר על עצמו.',
            'scan-opration-encountered-problem': 'הייתה בעיה בסריקה',
            file_is_in_edit_mode: 'הקובץ נמצא במצב עריכה',
            dont_allow_edit_file: 'עריכת קובץ מסוג זה אינה אפשרית!',
            file_download_new_error: 'לא ניתן להוריד קובץ שהועלה לאחרונה',
            file_open_new_error: 'לא ניתן לפתוח קובץ שהועלה לאחרונה'
        },
        upload_files: 'העלה קבצים',
        accepted_types: 'סוגי קבצים מקובלים',
        preview: 'תצוגה מקדימה',
        one_file: 'קובץ אחד',
        files: 'קבצים',
        each: 'כל אחד'
    },
    export_control: {
        title: 'בחר את סוג הייצוא',
        titlesetting: 'הגדרת כותרת',
        showTableTitle: 'הצגת כותרת בייצוא',
        backGround: 'צבע רקע',
        fontname: 'שם גופן',
        fontsize: 'גודל גופן',
        fontcolor: 'צבע גופן',
        borderstyle: 'סגנון גבול',
        bordercolor: 'צבע גבול',
        columnsetting: 'הגדרת עמודות',
        cellsetting: 'הגדרת תאים',
        createfile: 'צור קובץ',
        bodysetting: 'הגדרת גוף',
        exit: 'יציאה',
        columnfontstyle: 'סגנון גופן בעמודה',
        context: 'הקשר',
        contextstyle: 'סגנון ההקשר',
        date: 'תאריך',
        twodigit: 'מספר עם עד שתי ספרות עשרוניות',
        fourdigit: 'מספר עם עד ארבע ספרות עשרוניות'
    },
    chart_control: {
        error_in_Get_Data: 'קבלת נתוני התרשים נכשלה ===>',
        updating_chart: 'טוען את התרשים ...',
        updating_map: 'טוען את המפה ...',
        error_in_get_map_data: 'שגיאה באחזור נתוני המפה.',
        loading: 'טוען...',
        exportButtonTitle: 'יְצוּא',
        printButtonTitle: 'הדפס',
        rangeSelectorFrom: 'מ',
        rangeSelectorTo: 'ל',
        rangeSelectorZoom: 'תקריב',
        downloadPNG: 'تحميل الصورة PNG',
        downloadJPEG: 'הורד תמונה JPEG',
        downloadPDF: 'הורד מסמך PDF',
        downloadSVG: 'הורד תמונה SVG',
        downloadXLS: 'הורד את XLC',
        downloadCSV: 'הורד טקסט',
        month_january: 'ינואר',
        month_february: 'פברואר',
        month_march: 'מרץ',
        month_april: 'אפריל',
        month_may: 'מאי',
        month_june: 'יוני',
        month_july: 'יולי',
        month_august: 'אוגוסט',
        month_september: 'ספטמבר',
        month_october: 'אוקטובר',
        month_november: 'נובמבר',
        month_december: 'דצמבר',
        short_month_january: 'ינו׳',
        short_month_february: 'פבר׳',
        short_month_march: 'מרץ',
        short_month_april: 'אפר׳',
        short_month_may: 'מאי',
        short_month_june: 'יונ׳',
        short_month_july: 'יול׳',
        short_month_august: 'אוג׳',
        short_month_september: 'ספט׳',
        short_month_october: 'אוק׳',
        short_month_november: 'נוב׳',
        short_month_december: 'דצמ׳',
        weekday_monday: 'יוֹם שֵׁנִי',
        weekday_tuesday: 'יוֹם שְׁלִישִׁי',
        weekday_wednesday: 'יום רביעי',
        weekday_thursday: 'יוֹם חֲמִישִׁי',
        weekday_friday: 'יוֹם שִׁישִׁי',
        weekday_saturday: 'יום שבת',
        weekday_sunday: 'יוֹם רִאשׁוֹן',
        printChart: 'הדפס תרשים',
        contextButtonTitle: 'תפריט ההקשר של תרשים'
    },
    assign_control: {
        no_name: 'אין שם',
        delete: 'מחק',
        messages: {
            item_is_repeated: 'הפריט {displayMember} חוזר על עצמו.'
        }
    },
    tree_control: {
        expand_collapse: 'הרחב / כווץ',
        error: 'שגיאה',
        warning: 'אזהרה',
        add_new: 'הוסף',
        show: 'הצג',
        edit: 'ערוך',
        delete: 'מחק',
        messages: {
            item_delete_confirm: 'האם אתה בטוח שברצונך למחוק?',
            item_get_info_error: 'אירעה שגיאה במהלך קבלת מידע!'
        }
    },
    comment_control: {
        delete_confirm: 'האם אתה בטוח שברצונך למחוק?',
        back: 'חזור',
        save: 'החל',
        empty: 'התגובה לא יכולה להיות ריקה'
    },
    dropdown_control: {
        select: 'בחר...',
        messages: {
            items_get_error: 'ERROR OCCURRED DURING GET LIST!'
        }
    },
    context: {
        new_folder: 'חדש',
        edit_folder: 'ערוך',
        delete_folder: 'מחק',
        open_in_new_window: 'פתח בחלון חדש',
        rules: 'נהל כללי תיקיות',
        messages: 'הודעות',
        send_message: 'שלח הודעה',
        view_received_messages: 'צפה בהודעות שהתקבלו',
        view_sent_message: 'צפה בהודעות שנשלחו'
    },
    cartable_grid: {
        open_in_new_window: 'פתח בחלון חדש',
        history: 'ההיסטוריה',
        tree_history: 'עץ',
        graph_history: 'גרף',
        time_sheet: 'גליון זמנים',
        change_folder: 'החלף תיקייה',
        flag: 'סמן בדגל',
        no_flag: 'בלי דגל',
        assignTask: 'הקצה',
        FolderService_CanNotTransfer: 'לא ניתן להעביר לתיקיה זו',
        total_tasks: 'סה"כ המשימות בתיקיה זו: ',
        total_unread_tasks: 'סה"כ משימות שלא נקראו: '
    },
    create_physical_folder: {
        add_modal_header: 'צור תיקיה',
        edit_modal_header: 'ערוך תיקיה',
        folder_title: 'כותרת',
        folder_type: 'סוג',
        dont_send_request: 'בקשות טיוטה',
        undone_works: 'משימות ממתינות',
        done_works: 'משימות שהושלמו',
        folder_title_empty: 'כותרת התיקיה ריקה',
        folder_title_length_error: 'אורך הכותרת מוגבל ל -50 תווים.',
        folder_title_validate_error: 'השם אינו חוקי.'
    },
    footer: {
        powered_by_raybpms: 'מופעל על ידי DBP',
        rights: 'תאגיד טכנולוגיות ממשק. כל הזכויות שמורות.',
        url: 'https://www.interfacing.com/',
        help: 'עזרה',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'שגיאה במעקב!',
        tracking_code_is_empty: 'קוד המעקב ריק!',
        error_on_delete: 'אירעה שגיאה במהלך פעולת המחיקה',
        please_select_pdf_file: 'אנא בחר קובץ PDF',
        operation_is_not_allowed: 'אתה לא רשאי.',
        events_cannot_be_loaded: 'לא ניתן לטעון אירועים!',
        server_error: 'שגיאת שרת',
        you_are_not_allowed_to_view_form: 'אין לך הרשאה לגשת לטופס',
        access_denied: 'הגישה נדחתה',
        print_failed: 'ההדפסה נכשלה',
        error_occured: 'ארעה שגיאה, אנא פנה למנהל המערכת.',
        task_not_found: 'לא ניתן היה למצוא את המשימה הזו. ייתכן שהוא הופעל על ידי משתמש אחר.',
        application_is_not_deployed: 'האפליקציה לא נפרסת.',
        save_in_progress: 'השמירה מתבצעת כעת, אנא נסה שוב מאוחר יותר.'
    },
    Lock: {
        'bpms-lock-expired': 'תוקף הנעילה פג!',
        'maximum-bpms-lock-user': 'מגבלת משתמשים בו זמנית חורגת מהרישיון!',
        'bpms-lock-failed': 'נעילה לא נמצאה!'
    },
    slider_managment: {
        title: 'תמונות מחוון',
        slider_show_is_repeat: 'סדר תמונת המחוון חוזר על עצמו.',
        slider_show_is_empty: 'סדר התמונה ריק.',
        upload_message: 'YOUR IMAGES ARE MORE THAN LIMIT!',
        image_displaying: 'סדר תמונה',
        same_name_massage: 'תמונה קיימת עם אותו שם!',
        image_is_too_large: 'גודל התמונה גדול מדי',
        name: 'שם',
        image: 'תמונה ',
        pic_type: 'בחר קובץ תמונה (jpg, png, gif) של פחות מ 80 KB.',
        image_required: 'בחר תמונה.',
        level_required: 'בחר את סדר התמונה',
        new: 'חדש',
        edit: 'ערוך',
        empty: 'ריק'
    },
    cartable_management: {
        cartable_managment_title: 'ניהול רשימת משימות - שנה שם תיקיות ברירת מחדל',
        cartable_date_setup: 'הגדרות תאריך ברשימת המשימות ',
        status: 'סטטוס:',
        exact: 'מדויק',
        limited: 'משוער',
        exact_dsc: 'הראה תאריך ושעה מדויקים',
        limited_dsc: 'הראה את הימים האחרונים בקירוב',
        select_person: 'בחר אדם, משרה או קבוצה לשינוי שם התיקיה הראשית',
        dont_works: 'תיקיות משימות מטלות:',
        done_work: 'תיקיות המשימות שהושלמו:',
        dont_send_request: 'בקשות טיוטה:',
        changing_folder_name: 'שנה את שם התיקיות',
        attention_message: 'כדי לראות את השינויים האחרונים התחבר שוב.',
        tree_expanded: 'התיקיה הממתינה של הצוות הראשון תיבחר לאחר הכניסה.',
        hide_default_user_folders: 'הסתר תיקיות ברירת מחדל של משתמשים (כגון בהמתנה, עבודה וטיוטה)',
        expand_folders_by_default: 'הרחב את כל תיקיות המשתמש והצוות כברירת מחדל',
        tree: 'תיקיות עץ'
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'לוח שנה לחופשות',
        year: 'שנה:',
        show_calendar: 'הצג לוח שנה',
        year_calendar: 'שנה',
        messages: {
            year_validate_message: 'השנה לא תקפה!'
        }
    },
    appearance_management: {
        ui_setup: 'התאמה אישית',
        back_image: 'רקע',
        default_theme: 'ברירת המחדל של ערכת הנושא',
        banner: 'באנר עליון',
        back_image_dsc: 'לבחירת דף הכניסה ודף הבית, השתמש בטופס למטה.',
        default_theme_dsc:
            'אנא בחר אחד מערכות הנושא שלמטה את ברירת המחדל של ערכת הנושא לרשימת המשימות.',
        banner_dsc: 'השתמש בטופס שלמטה כדי לבחור באנר לאתר. הערה:',
        before_login: 'לפני תמונת ההתחברות:',
        after_login: 'תמונת ההתחברות:',
        current_img: 'תמונה נוכחית',
        location: 'מיקום:',
        repeat: 'חזור',
        no_repeat: 'ללא חזרה',
        center: 'מרכז',
        back_color: 'צבע רקע',
        upload_save: 'העלה ושמור',
        select_theme: 'בחר ערכת נושא',
        preview: 'תצוגה מקדימה של ערכת נושא',
        set_theme: 'החל ערכת נושא',
        banner_attention: '',
        height_attention: 'גובה התמונה מותר עד 149 פיקסלים.',
        image_attention: 'גובה תמונת הכרזה מותר עד 149 פיקסלים.',
        back_image_file: 'ברירת המחדל של תמונת הכרזה',
        right_iamge: 'תמונת צד שמאל:',
        center_image: 'תמונת מרכז:',
        left_image: 'תמונת צד ימין:',
        for_field: 'עבור שדה:',
        select_image_file: 'אנא בחר קובץ תמונה',
        delete_current_banner: 'מחק את הבאנר הנוכחי',
        image_restore: 'שחזר את תמונת ברירת המחדל',
        select_theme_for_current_user: 'בחר ערכת נושא עבור המשתמש הנוכחי.',
        file_type: 'רק קבצי jpg, png או gif נתמכים.',
        server_wide: 'שרת רחב',
        user: 'מִשׁתַמֵשׁ',
        save: 'להציל'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                'רק גודל התמונה, צורת הסמל, מיקום וגודל הטקסט ישתנו, האם אתה בטוח שברצונך להמשיך?',
            save_sucsses_all: 'שינויים מוחלים על הכל.',
            save_confirm: 'האם אתה בטוח שברצונך להמשיך?',
            save_sucsses: 'השינויים נשמרו בהצלחה.',
            insert_number: 'כדי לשנות את גודל הסמל או התמונה הזן רק ספרות.',
            icon_size: 'Icon size should be between 25 to 140 pixels.',
            file_type: 'רק קבצי jpg, png או gif נתמכים.',
            icon_volume: 'גודל הסמל / התמונה מוגבל ל- 30kb.',
            insert_only_number: 'אתה יכול להזין מספרים בלבד'
        },
        home_ui_setting: 'הגדרות דף הבית',
        items_menu: 'תפריט פריטים',
        description: 'כדי להוסיף תפריטים לדף הבית, בחר את הפריט ושנה את הגדרותיו.',
        menu_setting: 'הגדרות תפריט',
        show_menu: 'הצג תפריט בדף הבית',
        first_level: 'הראה ברמה הראשונה',
        not_show_top: 'הסתר בתפריט העליון',
        display_name: 'שם מוצג',
        icon_width: 'רוחב סמל ',
        icon_width_ph: 'רוחב סמל / תמונה',
        icon_height: 'גובה סמל',
        icon_height_ph: 'גובה סמל / תמונה',
        home_menu_icon: 'תפריט סמל / תמונה בדף הבית',
        select_file: 'אנא בחר קובץ בפורמט jpg, png או gif.',
        icon_view: 'צורת סמל / תמונה',
        circul: 'מעגל',
        squared: 'ריבוע',
        rectangle: 'מלבן',
        text_location: 'מיקום טקסט',
        up_image: 'תמונה למעלה',
        center_image: 'תמונה באמצע',
        bottom_image: 'תמונה בתחתית',
        up_text: 'טקסט למעלה',
        center_text: 'טקסט באמצע',
        bottom_text: 'טקסט בתחתית',
        delete_image: 'מחק תמונה',
        text_color: 'צבע טקסט',
        float_text_color: 'צבע טקסט צף',
        hilight_text_color: 'הדגש צבע טקסט',
        save_button_title: 'כל ההגדרות למעט תמונה, שם מוצג והוספה לתפריט',
        doing_settup: 'החל שינויים על כולם',
        check_mark: 'מוצג בדף הבית',
        published_date: 'תאריך פרסום:'
    },
    news_management: {
        message: {
            insert_title: 'אנא הכנס את הכותרת.',
            title_character_length: 'אורך הכותרת לא יכול לעלות מעל 255 תווים.',
            text_character_length: 'אורך הטקסט לא יכול לעלות מעל 4000 תווים.',
            insert_text: 'אנא הכנס את הטקסט.',
            number_priority: 'בחר את העדיפות לפי מספרים.',
            number_large: 'המספר שנבחר בעדיפות חדשות הוא יותר מ 4 ספרות.',
            file_volume: 'הקובץ חייב להיות פחות מ -100 KB.'
        },
        news: 'חדשות',
        add: 'הוסף',
        title: 'כותרת',
        text: 'טקסט',
        text_color: 'צבע טקסט',
        enable_show: 'גלוי למשתמשים רגילים',
        news_setup: 'הגדרת חדשות',
        news_image: 'תמונת חדשות',
        news_priority: 'עדיפות חדשות',
        current_pic: 'הועלו תמונות',
        delete_curr_pic: 'מחק את התמונה הנוכחית',
        pic_type: 'גודל קובץ jpg, png או gif - פחות מ -100 kb.',
        id: 'תעודת זהות',
        createdAt: 'תאריך',
        username: 'שם משתמש',
        control: 'בקרה'
    },
    message_management: {
        messages: {
            repeated_recipient: 'הנמען חוזר על עצמו',
            title_is_empty: 'כותרת ההודעה ריקה, האם ברצונך להמשיך?',
            success_send: 'ההודעה נשלחה בהצלחה',
            unselected_recipient: 'לא נבחר נמען.'
        },
        reply_message: 'השב',
        title: 'כותרת:',
        message_text: 'גוף ההודעה:',
        attachment: 'קבצים מצורפים',
        recipients: 'נמענים',
        select: 'בחר ...',
        add_to_list: 'הוסף לרשימה',
        back: 'חזור',
        sended: 'הודעה נשלחה',
        sended_by: 'נשלח על - ידי:',
        send_date: 'זמן נשלח:',
        message_show: 'הצג הודעה',
        no_permission: 'אתה לא מורשה גישה!',
        subject: 'נושא:',
        date_time_send: 'נשלח בשעה ותאריך',
        sender: 'שולח',
        receiver: 'מקבל',
        menu_title: 'הודעות',
        received: 'הודעה שהתקבלה',
        send_email: 'שלח אימייל',
        send_sms: 'שלח מסרון'
    },
    task_assign: {
        messages: {
            success_assign: 'הועבר בהצלחה.',
            unselected_recipient: 'לא נבחר נמען.'
        },
        assign: 'העבר - הקצה',
        recipients: 'נמענים',
        description: 'תיאור',
        last_dsc: 'השתמש בתיאור הנוכחי',
        out_work: 'סיים את המשימה הנוכחית',
        keep_sender: 'שמור את השולח הנוכחי'
    },
    process_log: {
        status: 'סטטוס',
        event: 'אירוע',
        user: 'משתמש',
        user_position: 'המשרה',
        work_title: 'תואר התפקיד',
        responsible: 'אחראי',
        create_date: 'תאריך התחלת המשימה',
        done_date: 'תאריך סיום המשימה',
        folder_name: 'שם התיקיה',
        start_task: 'פעולת התחלה',
        human_task: 'פעולת אנוש',
        fork_task: 'פעילות מזלג',
        join_task: 'פעילות צירוף ',
        system_task: 'פעילות מערכת',
        common_task: 'פעילות משותפת'
    },
    timesheet_register: {
        messages: {
            time_reng: 'תאריך ההתחלה לא יכול לחרוג מתאריך הסיום',
            select_row: 'אנא בחר שורה.',
            delete_timesheet_confirm: 'האם אתה בטוח שברצונך למחוק את גליון הזמנים הנוכחי?',
            save_time_confirm: 'תקופה זו נבחרה למשימה אחרת. האם ברצונך להחליף אותה עבור פעולות נוכחיות?',
            can_not_edit: 'תקופה זו נשמרת למשימה שהושלמה. עריכה אינה מורשית.',
            date_not_valid: 'התאריך אינו תקף'
        },
        errors: {
            error_in_remaining_time: 'אירעה שגיאה בזמן שנותר',
            get_timesheet: 'אירעה שגיאה בייצוא הגליון',
            error_in_save: 'אירעה שגיאה במהלך השמירה',
            error: 'שגיאה',
            error_in_delete: 'אירעה שגיאה במהלך המחיקה'
        },
        work_dsc: 'תיאור התפקיד',
        date: 'תאריך',
        start_time: 'זמן התחלה',
        end_time: 'זמן סיום',
        remaining_time: 'זמן שנותר',
        description: 'תיאור',
        timesheet: 'גליון זמנים',
        current_work: 'הקצה למשימה הנוכחית',
        persian_date: 'תאריך',
        weekly_plan: 'תכנית שבועית',
        prev_week: 'שבוע שעבר',
        next_week: 'שבוע הבא',
        plus: 'הוסף',
        all_work: 'כל העבודות הנוכחיות',
        delete: 'מחק'
    },
    delegation_management: {
        messages: {
            select_user: 'אנא בחר את משתמש ההפניה.',
            select_date: 'אנא בחר את תאריך ההתחלה והסיום',
            select_folder: 'אנא בחר את התיקיות שהמאציל יכול להציג',
            compare_fromDate_toDate: 'תאריך הסיום גדול יותר מתאריך ההתחלה'
        },
        folder_to_delegate: 'תיקיות שנציג יכול להציג:',
        delegations: 'הקצאות',
        delegation_edit: 'ערוך הקצאה',
        delegation_user: 'הקצא משתמש:',
        all_time: 'קבוע',
        from_date: 'מתאריך:',
        to_date: 'לתאריך',
        start_date: 'תאריך התחלה',
        finish_date: 'תאריך סיום',
        delegate_to: 'האציל ל:',
        more_item: 'עוד פריטים',
        all_item: 'כל הפריטים',
        remove_delegate: 'הסר האצלה',
        delegation_create: 'הוסף',
        no_records: 'אין רשומה שתואמת  למילות המפתח שלך',
        delegation_add: 'הוסף הקצאה',
        delegation_user_placeholder: 'הקלד לחיפוש',
        more_users: 'ישנן תוצאות נוספות, אנא השתמש במילות מפתח טובות יותר'
    },
    rule_criterias: {
        new_rule: 'צור כלל חדש',
        edit_rule: 'ערוך כלל',
        no_permission: 'אתה צריך אישור!',
        tag_error: 'אירעה שגיאה במהלך עדכון התגית!',
        flag_error: 'אירעה שגיאה במהלך עדכון הדגל!',
        no_actions: 'אין פעולה!',
        action: 'פעולה',
        rule_name: 'שם הכלל',
        operation: 'פעולה',
        select_folder: 'בחר תיקייה'
    },
    ray_payment: {
        messages: {
            close_pay_win: 'ראשית, סגור את חלון התשלום.'
        },
        wait_status: 'ממתין לתשלום.',
        payed_status: 'שולם',
        failed_status: 'שגיאה בתשלום',
        not_payed_status: 'לא שולם',
        currency: ' שח',
        through: 'דרך',
        pay: 'תשלום'
    },
    ray_datetimepicker: {
        date_not_valid: 'התאריך אינו חוקי!',
        time_not_valid: 'הזמן אינו חוקי!',
        ok: 'בסדר'
    },
    logs_management: {
        show_details:
            'אפשר הצג פרטי שגיאה. על ידי הפעלת אפשרות זו המשתמשים יראו את הודעת השגיאה הטכנית המלאה בכל אירוע שגיאה. ',
        error_number: 'מספר',
        date: 'נוצר ב',
        level: 'רמה',
        staff_id: 'מזהה תפקיד',
        application_name: 'שם אפליקציה',
        error_log: 'יומני שגיאות',
        error_message: 'הודעת שגיאה',
        api_log: 'יומן שיחות API',
        id: 'Id',
        session: 'מוֹשָׁב',
        api: 'API',
        method: 'שיטה',
        severity: 'חוּמרָה',
        search: 'לחפש...',
        key: 'פרמטר מפתח',
        duration: 'משך (מילישניות)',
        epc_log: 'יומן שיחות EPC',
        user_log: 'יומן משתמש',
        result: 'קוד תוצאה',
        application_log: 'יומן יישומים',
        copy_clipboard: 'העתק ללוח',
        user: 'מִשׁתַמֵשׁ',
        login_datetime: 'כניסה תאריך שעה',
        logout_datetime: 'התנתק תאריך זמן',
        server_name: 'Server Name',
        active_connections: 'קשרים פעילים',
        send_message_all: 'שלח הודעה לכולם',
        send_message_connection: 'שלח הודעה לחיבור זה',
        start_datetime: 'תאריך התחלה זמן'
    },
    rule_management: {
        title: 'נהל כללי תיקיות',
        operation: 'פעולה',
        rule_name: 'שם',
        add_new_rule: 'כלל חדש',
        edit_rule: 'ערוך כלל',
        are_you_sure: 'האם אתה בטוח שברצונך למחוק את הכלל?',
        rule_modal: {
            name: 'שם הכלל',
            subject: 'נושא',
            task_type: 'סוג',
            create_date: 'תאריך יצירה',
            response_date: 'מועד אחרון לתגובה',
            done_date: 'תאריך השלמה',
            priority: 'עדיפות',
            flag: 'Flag',
            read: 'Read',
            transcript: 'תעתיק',
            app: 'יישום',
            in_folder: 'תיקיה',
            not_selected_option: 'לא נבחר',
            validation: {
                name: 'שם נדרש'
            },
            clear_tooltip: 'נקה'
        }
    },
    today: 'תאריך של היום',
    form: 'טופס',
    form_behavior: 'התנהגות הטופס',
    close_form_warning: 'אתה בטוח שאתה רוצה לצאת? כל שינוי יאבד.',
    yes: 'כן',
    no: 'לא',
    secret_code: {
        title: 'אימות אישור',
        message: 'שלחנו לך קוד סודי למייל שלך. אנא העתק והדבק אותו כאן.',
        code: 'קוד:',
        legal_sentence:
            'על ידי אישור עם החתימה האלקטרונית שלך, אתה חותם על המסמך באופן אלקטרוני. אתה מסכים ש</br>לחתימה האלקטרונית שלך יש תוקף משפטי ואפקט זהה לחתימה בכתב יד שלך על</br>המסמך, ושיש לה משמעות זהה לחתימה בכתב יד שלך.',
        expired: 'הקוד לא חוקי או שפג תוקפו',
        required: 'הקוד נדרש',
        submit: 'שלח',
        cancel: 'לְבַטֵל'
    },
    user_settings: 'הגדרות משתמש',
    audit_trail: {
        title: 'מסלול ביקורת',
        operation_time: 'זמן פעולה',
        application_name: 'שם אפליקציה',
        user: 'מִשׁתַמֵשׁ',
        operation: 'מבצע',
        entity_name: 'שם הישות',
        entity_definition: 'הגדרת ישות',
        task_subject: 'נושא המשימה',
        field_name: 'שם שדה',
        old_value: 'ערך ישן',
        new_value: 'ערך חדש'
    }
}
export default localeMessages
