var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("slider_managment.title"),
        className: "col-xs-12",
      },
    },
    [
      _c(
        "div",
        { class: [_vm.direction ? "k-rtl" : ""] },
        [
          _c("grid", {
            attrs: {
              columns: _vm.columns,
              reloadGrid: _vm.reloadGrid,
              dataSourceUrl: "api/slider/list",
              toolbar: _vm.toolbar,
              selectable: "false",
              hasPaging: _vm.hasPaging,
            },
          }),
          _vm._v(" "),
          _c(_vm.currentModalComponent, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showSliderModal,
                expression: "showSliderModal",
              },
            ],
            tag: "component",
            attrs: {
              showSliderModal: _vm.showSliderModal,
              modalData: _vm.modalData,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }