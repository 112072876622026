<template>
    <newForm v-bind:title='$t("tracking")' className="col-xs-12">

        <div class="row form-group">

            <label class="control-label" v-bind:style="inlinCSS"><b>{{$t("tracking_code")}} :</b></label>
            <div class="col-md-3">
                <input type="text"
                       class="form-control"
                       v-model="trackingCode" />
            </div>
            <div class="col-md-4" style="margin-top:2px;">
                <button class="btn btn-primary" v-on:click="submitTracking">{{$t("tracking")}}</button>
                <!--<button class="btn btn-primary" v-on:click="submitHistory"></button>-->
                <button class="btn btn-primary" v-on:click="submitHistoryInModal" v-bind:disabled="disableModal!=0">{{$t("history")}}</button>
            </div>
        </div>
        <div class="row form-group" v-if="result.length">
            <ul>
                <li v-for="item in result" v-html="item"></li>
            </ul>
        </div>

        <div v-bind:class="[direction ? 'k-rtl' : '' ]" v-if="showGrid">
            <hr />
            <treeList v-bind:columns="columns"
                      v-bind:schema="schema"
                      v-bind:data="localData">
            </treeList>

        </div>

        <component v-show="showTrackingHistory"
                   :is="currentModalComponent"
                   v-bind:showTracking="showTrackingHistory"
                   v-bind:modalData="modalData">
        </component>
    </newForm>
</template>

<script type="text/javascript">
    import NewForm from '../forms/newform/newForm.vue';
    import TreeList from "../widgets/TreeList/treeList.vue";
    import TrackingHistory from './modals/TrackingHistory.vue';

    export default {
        data() {
            return {
                modalData: [],
                currentModalComponent: "",
                showTrackingHistory: 0,
                result: [],
                trackingCode: "",
                inlinCSS: payload.currentLocale.IsRightToLeft ? { float: 'right', marginRight: '15px', marginTop: '6px' } : { float: 'left', marginLeft: '15px', marginTop: '6px' },
                showGrid: false,
                localData: [],
                loadGrid: "false",
                direction: payload.currentLocale.IsRightToLeft,
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            Id: {
                                type: "string"
                            },
                            parentId: {
                                field: "ParentId",
                                nullable: true
                            }
                        },
                        expanded: true
                    }
                },
                columns: [
                    {
                        field: 'Id',
                        hidden: true
                    },
                    {
                        field: 'StateName',
                        sortable: false,
                        title: i18n.$t("process_log.status"),
                        attributes: {
                            style: "white-space:normal;font-size:11px"
                        },
                        width: 340,
                        headerAttributes: {
                            style: "font-size:10px"
                        }
                    },
                    { field: 'EventName', sortable: false, title: i18n.$t("process_log.event"), headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'UserTitle', sortable: false, title: i18n.$t("process_log.user"), minScreenWidth: 720, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'StaffTitle', sortable: false, title: i18n.$t("process_log.user_position"), minScreenWidth: 720, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskSubject', sortable: false, title: i18n.$t("process_log.work_title"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskPrincipalTitle', sortable: false, title: i18n.$t("process_log.responsible"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskCreationDate', sortable: false, title: i18n.$t("process_log.create_date"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskResponseDate', sortable: false, title: i18n.$t("process_log.done_date"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'TaskFolderTitle', sortable: false, title: i18n.$t("process_log.folder_name"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } },
                    { field: 'AssignList', sortable: false, title: i18n.$t("task_assign.assign"), minScreenWidth: 810, headerAttributes: { style: "font-size:10px" }, attributes: { style: "font-size:11px" } }
                ],
                disableModal: 0
            }
        },
        methods: {
            submitTracking() {
                this.result = [];
                if (this.validation()) {
                    var url = "api/tracking/get-info/" + this.trackingCode;
                    var that = this;
                    this.doAjaxRequest(url, {}, (data) => {
                        if (data != null && data.length) {
                            for (var i = 0; i < data.length; i++) {
                                var content = data[i].Info.length > 0 ? data[i].Info : data[i].LocalState;

                                if (content.length > 0) {
                                    that.result.push(i18n.$t("process_level", { level: content }));
                                }
                            }
                        } else {
                            that.result.push(i18n.$t("messages.not_any_record_found"));
                        }
                    });
                }
            },

            submitHistory() {
                this.showGrid = false;
                this.localData = [];

                if (this.validation()) {
                    var url = "api/tracking/get-tree/" + this.trackingCode;
                    var that = this;
                    this.doAjaxRequest(url, {}, (data) => {
                        if (data != null && data.length) {
                            that.showGrid = true;
                            that.localData = data;
                        }
                    });
                }
            },

            submitHistoryInModal() {
                if (this.validation()) {
                    this.disableModal = 1;
                    this.submitTracking();
                    var url = "api/tracking/get-tree/" + this.trackingCode;
                    var that = this;
                    this.doAjaxRequest(
                        url,
                        {},
                        (data) => {
                            if (data != null && data.length) {

                                that.currentModalComponent = "TrackingHistory";
                                that.modalData = data;
                                that.showTrackingHistory++;

                            } else {
                                alert(i18n.$t("messages.not_any_record_found"));
                            }

                            that.disableModal = 0;
                        });
                }
            },
            validation() {
                if (this.trackingCode.trim() === "") {
                    alert(i18n.$t("errors.tracking_code_is_empty"));
                    return false;
                }

                return true;
            },
            changeData() {
                this.showGrid = true;
                this.localData = this.data;
            },
            doAjaxRequest(url, data, onSuccess, onFailure, method) {

                let ajaxConfig = {
                    url: url,
                    data: data,
                    dataType: "json",
                    contentType: "application/json;charset=utf-8"
                };

                if (method) {
                    ajaxConfig.method = method;
                }

                $.ajax(ajaxConfig)
                    .done((result) => {
                        if (typeof onSuccess === 'function')
                            onSuccess(result);
                    }).error((error) => {
                        if (typeof onFailure === 'function') {

                            var response = null;

                            if (error.status == 400) {
                                response = JSON.parse(error.responseText);
                                onFailure(response);
                            }
                        }
                    });
            },
            init() {
                this.data = [];
                this.localData = [];
                this.showGrid = false;
            }
        },
        mounted() {
        },
        created() {
        },
        components: {
            NewForm,
            TreeList,
            TrackingHistory
        }
    }

</script>

