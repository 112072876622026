﻿
export default {
    props: [
        "item"
    ],
    data() {
        return {
        };
    },
    methods: {
    },
    updated() {
    },
    mounted() {
    },
    components: {
    }
}