var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("vacation_calendar_management.vacation_calendar_title"),
        loading: _vm.loading,
        className: "col-xs-12",
      },
    },
    [_c("div", { staticClass: "jquery-ui-scope view bpms-vacationform" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }