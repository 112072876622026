var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newsItems.length !== 0
    ? _c("div", { staticClass: "panel panel-default" }, [
        _c("div", { staticClass: "panel-body" }, [
          _c("h2", { staticClass: "panel_title" }, [
            _vm._v(_vm._s(_vm.$t("title.news"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "news_container" }, [
            _c(
              "ul",
              { staticClass: "media-list" },
              _vm._l(_vm.newsItems, function (item) {
                return _c(
                  "li",
                  { staticClass: "media", attrs: { id: item.Id } },
                  [
                    _c("div", { staticClass: "media-left" }, [
                      _c(
                        "div",
                        {
                          staticClass: "news_object pull-left",
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#pnl-" + item.Id,
                          },
                        },
                        [
                          item.ImageUrl
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "api/file/getappfile?imageId=" +
                                    [item.ImageUrl],
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "media-body" }, [
                      _c(
                        "div",
                        {
                          staticClass: "media-heading news_title",
                          attrs: {
                            "data-toggle": "modal",
                            "data-backdrop": "true",
                            "data-target": "#pnl-" + item.Id,
                          },
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n                                " +
                                _vm._s(item.Title) +
                                "\n                            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text-justify",
                          style: { color: item.Color },
                          attrs: {
                            "data-toggle": "modal",
                            "data-target": "#pnl-" + item.Id,
                          },
                        },
                        [
                          _vm._v(
                            "\n\n                            " +
                              _vm._s(_vm.truncate(item.Summary, 100)) +
                              "\n\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "a",
                            {
                              staticClass: "news_detail",
                              attrs: {
                                href: "#",
                                "data-toggle": "modal",
                                "data-target": "#pnl-" + item.Id,
                              },
                            },
                            [
                              _vm._v(
                                "\n\n                                    " +
                                  _vm._s(_vm.$t("date")) +
                                  ": " +
                                  _vm._s(item.CreatedAt) +
                                  "\n                                      |  \n                                    " +
                                  _vm._s(_vm.$t("more_details")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "modal",
                      { attrs: { id: "pnl-" + item.Id, size: "modal-lg" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-justify",
                            attrs: { slot: "body" },
                            slot: "body",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-12 text-center" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(item.Title) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            item.ImageUrl
                              ? _c("div", { staticClass: "row" }, [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-md-10 col-md-offset-1",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img-responsive",
                                        attrs: {
                                          src:
                                            "api/file/getappfile?imageId=" +
                                            [item.ImageUrl],
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-10 col-md-offset-1" },
                                [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(item.Body) +
                                        "\n                                    "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md-10 col-md-offset-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "news_info text-right" },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(_vm.$t("date")) +
                                          ": " +
                                          _vm._s(item.CreatedAt) +
                                          "\n                                          |  \n                                        " +
                                          _vm._s(_vm.$t("author")) +
                                          ": " +
                                          _vm._s(item.UserTitle) +
                                          "\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.loading
                ? _c("loading", { attrs: { position: "absolute" } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "pagination" },
                _vm._l(_vm.totalPages, function (n) {
                  return _c(
                    "li",
                    {
                      class: {
                        active: n == _vm.currentPage,
                        disabled: _vm.loading,
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.pageChange(n)
                            },
                          },
                        },
                        [_vm._v(_vm._s(n))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }