var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "newForm",
        {
          attrs: {
            useTitle: "true",
            widthSize: _vm.widthSize,
            heightSize: _vm.heightSize,
            className: _vm.className,
            title: _vm.title,
            customStyle: _vm.customStyle,
            isCompletedTask: _vm.isCompletedTask,
          },
        },
        [
          _c(
            "ol",
            {
              staticClass: "breadcrumb",
              attrs: { slot: "breadcrumb" },
              slot: "breadcrumb",
            },
            _vm._l(_vm.breadcrumbs, function (crumb) {
              return _c(
                "li",
                { key: crumb.url },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "breadcrumb-item",
                      attrs: { to: crumb.url },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(crumb.text) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "js_load-form" }),
        ]
      ),
      _vm._v(" "),
      _c(_vm.currentModalComponent, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTrackingHistory,
            expression: "showTrackingHistory",
          },
        ],
        tag: "component",
        attrs: {
          showTracking: _vm.showTrackingHistory,
          modalData: _vm.modalData,
        },
      }),
      _vm._v(" "),
      _c("DxLoadPanel", {
        attrs: {
          container: ".js_load-form",
          shading: true,
          "hide-on-outside-click": false,
          "shading-color": "rgba(0,0,0,0.4)",
        },
        model: {
          value: _vm.loadingVisible,
          callback: function ($$v) {
            _vm.loadingVisible = $$v
          },
          expression: "loadingVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }