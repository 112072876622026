<template>
    <newForm v-bind:title='$t("vacation_calendar_management.vacation_calendar_title")' v-bind:loading="loading" className="col-xs-12">
        <div class="jquery-ui-scope view bpms-vacationform"></div>
    </newForm>

</template>
<script type="text/javascript">
    import loading from '../widgets/loading/loading.vue';
    import NewForm from '../forms/newform/newForm.vue';
    export default {
        data() {
            return {
                loading: true
            }
        },
        methods: {

        },
        mounted() {

            this.$nextTick(function () {
                const container = $(this.$el).find('.bpms-vacationform');
                const params = {
                    element: container,
                    onLoaded: () => {
                        this.loading = false;
                    }
                };
                vm.vacation.activate(params);
            });


        },
        components: {
            loading,
            NewForm
        }
    }
</script>
