var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side_close",
      class: {
        "sidebar-container": true,
        "js_sidebar-container": true,
        "with-banner": _vm.hasBanner,
      },
      staticStyle: { "z-index": "3" },
      attrs: { id: "sidebar" },
    },
    [
      _c(
        "div",
        {
          staticClass: "profile-info",
          class: { "sidebar-footer": true, "with-banner": _vm.hasBanner },
        },
        [
          _c("div", { staticClass: "col-sm-10" }, [
            _vm.hasAvatar
              ? _c("div", {
                  staticClass:
                    "user-profile-container col-sm-4 circle-picture-profile medium hide",
                  staticStyle: {
                    "background-image": 'url("api/file/getprofileimage")',
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "personal-information js_personal-information hide",
                class: { "col-sm-8": _vm.hasAvatar },
              },
              [
                _c("h5", { attrs: { title: _vm.userTitle } }, [
                  _vm._v(_vm._s(_vm.userTitle)),
                ]),
                _vm._v(" "),
                _c("span", { attrs: { title: _vm.userStaff } }, [
                  _vm._v(_vm._s(_vm.userStaff)),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 sidebar-toggle-container" }, [
            _c(
              "div",
              {
                staticClass: "sidebar-toggle js_sideToggle-sidebar iconreverse",
                class: { iconreverse: _vm.isRTL },
              },
              [_c("i", { staticClass: "fad fa-2x fa-arrow-left" })]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("CartableTree", {
        class: { "sidebar-tree": true, "with-banner": _vm.hasBanner },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { "font-size": "11px", margin: "2px 10px" } }, [
        _c("a", { attrs: { href: _vm.customerUrl } }, [
          _c("img", {
            staticClass: "img-responsive",
            staticStyle: { "margin-bottom": "5px" },
            attrs: { src: _vm.customerLogo },
          }),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("a", { attrs: { href: _vm.customerUrl, target: "_blank" } }, [
            _c("i", { staticClass: "fad fa-lg fa-copyright" }),
            _vm._v(" "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.customerName) } }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("i", { staticClass: "fad fa-lg fa-info-square" }),
          _vm._v(
            " " +
              _vm._s(_vm.$t("footer.powered_by_raybpms")) +
              " " +
              _vm._s(_vm.version) +
              " (" +
              _vm._s(_vm.creationDate) +
              ")\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("a", { attrs: { href: _vm.helpUrl, target: "_blank" } }, [
            _c("i", { staticClass: "fad fa-lg fa-question-square" }),
            _vm._v(" " + _vm._s(_vm.$t("footer.help"))),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }