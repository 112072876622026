var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c(
        "newForm",
        {
          class: [_vm.direction ? "k-rtl" : ""],
          attrs: { useTitle: "true", title: _vm.$t("logs_management.api_log") },
        },
        [
          _c(
            "DxDataGrid",
            {
              ref: _vm.dataGridRefKey,
              attrs: {
                id: "gridContainer",
                "show-borders": true,
                "data-source": _vm.dataSource,
                "remote-operations": true,
                "column-auto-width": true,
                "allow-column-resizing": true,
                "allow-column-reordering": true,
                width: "100%",
                "key-expr": "Id",
              },
              on: {
                "selection-changed": _vm.selectionChanged,
                "content-ready": _vm.contentReady,
                exporting: _vm.onExporting,
              },
              scopedSlots: _vm._u([
                {
                  key: "refreshTemplate",
                  fn: function () {
                    return [
                      _c("DxButtonToolbar", {
                        attrs: { icon: "refresh" },
                        on: { click: _vm.refreshDataGrid },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "detailTemplate",
                  fn: function (ref) {
                    var log = ref.data
                    return [
                      log.data.Timings
                        ? _c("div", { staticStyle: { position: "relative" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                staticStyle: {
                                  position: "absolute",
                                  right: "50px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.copy(log.data.Timings)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("Copy to clipboard")))]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "error-message",
                              staticStyle: {
                                "max-height": "300px",
                                overflow: "auto",
                              },
                              domProps: { innerHTML: _vm._s(log.data.Timings) },
                            }),
                          ])
                        : _c("div"),
                    ]
                  },
                },
              ]),
            },
            [
              _c("DxSearchPanel", {
                attrs: {
                  visible: true,
                  width: 240,
                  placeholder: _vm.$t("logs_management.search"),
                },
              }),
              _vm._v(" "),
              _c("DxFilterRow", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxHeaderFilter", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGroupPanel", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGrouping", { attrs: { "auto-expand-all": true } }),
              _vm._v(" "),
              _c("DxExport", { attrs: { enabled: true } }),
              _vm._v(" "),
              _c(
                "DxToolbar",
                [
                  _c("DxItem", { attrs: { name: "searchPanel" } }),
                  _vm._v(" "),
                  _c("DxItem", {
                    attrs: { location: "after", template: "refreshTemplate" },
                  }),
                  _vm._v(" "),
                  _c("DxItem", { attrs: { name: "exportButton" } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._v(" "),
              _c("DxSelection", { attrs: { mode: "single" } }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Id",
                  width: 60,
                  caption: _vm.$t("logs_management.id"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Date",
                  "data-type": "datetime",
                  "sort-index": 1,
                  "sort-order": "desc",
                  caption: _vm.$t("logs_management.date"),
                  format: _vm.datetimeFormat,
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: { "data-field": "User", caption: _vm.$t("user") },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "SessionID",
                  caption: _vm.$t("logs_management.session"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Duration",
                  "data-type": "number",
                  caption: _vm.$t("logs_management.duration"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Method",
                  caption: _vm.$t("logs_management.method"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "API",
                  caption: _vm.$t("logs_management.api"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Key",
                  caption: _vm.$t("logs_management.key"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Timings",
                  visible: false,
                  caption: "Timings",
                },
              }),
              _vm._v(" "),
              _c("DxMasterDetail", {
                attrs: { enabled: false, template: "detailTemplate" },
              }),
              _vm._v(" "),
              _vm._v(" "),
              _c("DxPaging", { attrs: { "page-size": 15 } }),
              _vm._v(" "),
              _c("DxPager", {
                attrs: {
                  "show-page-size-selector": true,
                  "allowed-page-sizes": [15, 20, 50],
                  "show-navigation-buttons": true,
                  "show-info": true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }