import Grid from "../widgets/grid/grid.vue";
import NewForm from '../forms/newform/newForm.vue';
import ModalRule from './modals/ModalRule.vue';

export default {
    watch: {
        '$route'(to, from) {
            this.init();
        }
    },
    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,
            columns: [
                { field: "Id", title: this.$t('id'), menu: false, hidden: true },
                {
                    field: "Name", title: this.$t("rule_management.rule_name"), filterable: false, sortable: true
                }
            ],
            schema: {
                data: "Data",
                total: function (response) { return response.Total; }
            },
            toolbar: this.buildToolbarItems(),
            modalData: {},
            currentModalComponent: "",
            showRuleModal: 0,
            reloadGrid: 0,
            pId: "",
            dataSourceUrl: "",
            baseInfo: ""
        }
    },
    methods: {
        init() {
            let that = this;

            let pId = this.modalData.pId = this.$route.params.pId;
            console.log('modal data : ' + this.modalData.pId);
            that.dataSourceUrl = "api/rules/" + pId;

            this.doAjaxRequest(
                "api/rules/load-data/" + pId, {},
                (data) => {

                    that.baseInfo = JSON.stringify(data);

                });
        },
        buildToolbarItems() {
            let mapping = (value) => {
                let hasSubActions = Array.isArray(value.subActions);
                return {
                    type: hasSubActions ? "splitButton" : "button",
                    text: (value.icon) ? `<i class="${value.icon} fa-fw fa-lg"></i> ${value.text}` : value.text,
                    enable: value.enable,
                    click: value.action,
                    imageUrl: value.image,
                    menuButtons: hasSubActions ? value.subActions.map(mapping) : undefined
                }
            };
            return this.getActions().map(mapping);
        },
        getActions() {
            return [
                {
                    key: "AddNewRule",
                    text: this.$t("rule_management.add_new_rule"),
                    icon: "fad fa-plus-square",
                    action: (node, nodeData, selecteds) => {
                        this.modalData.isCreation = true;
                        this.modalData.pId = this.$route.params.pId;
                        this.currentModalComponent = "ModalRule";
                        this.modalData.callback = () => {
                            this.reLoadeData();
                        };
                        this.showRuleModal++;
                    },
                    enable: true
                },
                {
                    key: "EditRule",
                    text: this.$t("edit"),
                    icon: "fad fa-edit",
                    action: (node, nodeData, selecteds) => {
                        let dataItem = nodeData[0];
                        this.modalData.isCreation = false;
                        this.modalData.id = dataItem.Id;
                        this.modalData.pId = this.$route.params.pId;
                        this.currentModalComponent = "ModalRule";
                        this.modalData.callback = () => {
                            this.reLoadeData();
                        };
                        this.showRuleModal++;
                    },
                    enable: false
                },
                {
                    key: "DeleteRule",
                    text: this.$t("remove"),
                    icon: "fad fa-trash-alt",
                    action: (node, nodeData, selecteds) => {
                        let dataItem = nodeData[0];
                        let id = dataItem.Id;

                        let that = this;

                        utils.confirm(i18n.$t("rule_management.are_you_sure"), {
                            yes: function () {
                                var url = "api/rules/delete/" + id;

                                that.doAjaxRequest(url, {} ,() => { that.reLoadeData(); }, () => { }, 'DELETE');
                            },
                            no: function () { }
                        });
                    },
                    enable: false
                }
            ];
        },
        reLoadeData() {
            this.reloadGrid++;
        },
        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            let ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",

                contentType: "application/json;charset=utf-8"
            };
            if (method) {
                ajaxConfig.method = method;
            }
            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        }
    },
    created() {
        this.init();
    },
    components: {
        Grid,
        NewForm,
        ModalRule
    }
};
