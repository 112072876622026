<template>
    <form class="form-horizontal changePasswordForm" action="your-action" @submit="handleSubmit($event)">
        <fieldset>
            <div>
                <div class="dx-fieldset">
                    <div class="dx-field">
                        <div class="dx-field-label">{{$t("password_setting.current_password")}}</div>
                        <div class="dx-field-value">
                            <DxTextBox :ref="currentPasswordRefName"
                                       id="currentPassword"
                                       :show-clear-button="true"
                                       v-model="CurrentPassword"
                                       mode="password"
                                       valueChangeEvent="keyup"
                                       :placeholder='$t("password_setting.current_password")'>
                                <DxValidator id="currentPasswordValidator">
                                    <DxRequiredRule :message='$t("password_setting.messages.currentPassword_is_empty")' />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>
                </div>
                <div class="dx-fieldset">
                    <div class="dx-field">
                        <div class="dx-field-label">{{$t("password_setting.new_password")}}</div>
                        <div class="dx-field-value">
                            <DxTextBox :ref="newPasswordRefName"
                                       id="newPassword"
                                       :show-clear-button="true"
                                       v-model="NewPassword"
                                       mode="password"
                                       valueChangeEvent="keyup"
                                       :placeholder='$t("password_setting.new_password")'>
                                <DxValidator id="newPasswordValidator">
                                    <DxRequiredRule :message='$t("password_setting.messages.newPassword_is_empty")' />
                                    <DxCompareRule :comparison-target="passwordComparisonWithCurrent" comparisonType="!=="
                                                   :message='$t("password_setting.messages.new_password_same_as_current")' />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>
                </div>
                <div class="dx-fieldset">
                    <div class="dx-field">
                        <div class="dx-field-label">{{$t("password_setting.confirm_new_password")}}</div>
                        <div class="dx-field-value">
                            <DxTextBox :ref="confirmNewPasswordRefName"
                                       id="newConfirmPassword"
                                       :show-clear-button="true"
                                       v-model="ConfirmNewPassword"
                                       mode="password"
                                       valueChangeEvent="keyup"
                                       :placeholder='$t("password_setting.confirm_new_password")'>
                                <DxValidator id="newConfirmPasswordValidator">
                                    <DxRequiredRule :message='$t("password_setting.messages.confirmNewPassword_is_empty")' />
                                    <DxCompareRule :comparison-target="passwordComparisonWithNew"
                                                   :message='$t("password_setting.messages.passwords_do_not_match")' />
                                </DxValidator>
                            </DxTextBox>
                        </div>
                    </div>
                </div>
            </div>
            <!--this tag must be replaced by validator in vue js-->
            <p class="text-danger">
                {{errorTxt}}
            </p>
            <div class="form-group">
                <div class="col-sm-6 col-sm-offset-6">
                    <DxButton type="success"
                              class="btn btn-primary btn-block"
                              :use-submit-behavior="true"
                              id="changePasswordSave"
                              :text='$t("grid_control.save")' />
                </div>
            </div>
        </fieldset>
    </form>
</template>
<script>
    import { DxTextBox } from 'devextreme-vue/text-box';
    import DxButton from 'devextreme-vue/button';
    import { DxValidator, DxRequiredRule, DxCompareRule } from 'devextreme-vue/validator';
    import { Models } from '../../assets/ts/models';
    import NewForm from '../forms/newform/newForm.vue';
    import utils from 'jsf/utils.js';
    const bpmsModel = new Models();

    const currentPasswordRefName = "currentPasswordRefValue";
    const newPasswordRefName = "newPasswordRefValue";
    const confirmNewPasswordRefName = "confirmNewPasswordRefValue";
    export default {
        components: {
            DxValidator,
            DxRequiredRule,
            DxCompareRule,
            DxTextBox,
            DxButton,
            NewForm
        },
        data() {
            return {
                currentPasswordRefName, newPasswordRefName, confirmNewPasswordRefName,
                CurrentPassword: '',
                NewPassword: '',
                ConfirmNewPassword: '',
                errorTxt: '',
                loading: false,
                buttonOptions: {
                    text: 'Register',
                    type: 'success',
                    useSubmitBehavior: true,
                },
                passwordOptions: {
                    mode: 'password',
                }
            };
        },
        mounted() {
            this.$root.$on('PasswordChangeFormReset', () => {
                this.resetForm();
            });
            this.$root.$on('PasswordChangeFormInit', () => {
                this.initForm();
            });
        },
        computed: {
            currentPwdTB: function () {
                return this.$refs[currentPasswordRefName].instance;
            },
            newPwdTB: function () {
                return this.$refs[newPasswordRefName].instance;
            },
            confirmNewPwdTB: function () {
                return this.$refs[confirmNewPasswordRefName].instance;
            },
        },
        methods: {
            passwordComparisonWithNew() {
                return this.NewPassword;
            },
            passwordComparisonWithCurrent() {
                return this.CurrentPassword;
            },
            resetForm(e) {
                this.errorTxt = "";
                this.CurrentPassword = '';
                this.currentPwdTB.reset();
                this.NewPassword = '';
                this.newPwdTB.reset();
                this.ConfirmNewPassword = '';
                this.confirmNewPwdTB.reset();
                if (e)
                    e.srcElement.reset();
            },
            initForm() { },
            handleSubmit(e) {
                $('#changePasswordSave').addClass('disabled')
                this.loading = true;
                var model = {
                    OldPasswordHash: SHA256(this.CurrentPassword),
                    NewPasswordHash: SHA256(this.NewPassword)
                };
                bpmsModel.ChangePassword(model).done(res => {
                    console.log(res);
                    $('#changePasswordSave').removeClass('disabled')
                    if (res === "1") {
                        this.errorTxt = "";
                        var params = {
                            text: this.$t('messages.sucsses_message'),
                            type: 'information',
                            layout: 'center',
                            timeout: 3000
                        };
                        noty(params);
                        this.resetForm(e);
                        this.$emit('messageFromChild', true);
                    }
                    else {
                        if (res == "-1")
                            this.errorTxt = i18n.$t('password_setting.messages.current_password_is_wrong');
                        else
                            this.errorTxt = res;// 
                        return;
                    }

                }).fail((err) => {
                    this.errorTxt = i18n.$t('password_setting.messages.current_password_is_wrong');
                    $('#changePasswordSave').removeClass('disabled');
                });
                e.preventDefault();
            },
        },
    };
</script>
<style scoped>
    form {
        margin: 10px;
    }
</style>

