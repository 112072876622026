var vm = vm || {};

vm.vacation = (function () {
	let canLeave = function () {
	    return true;
	};
    let firstTime = true,
        $vacationForm,

        activate = function (params) {
            config.currentViewCanLeave(canLeave);

            $vacationForm = params.element;

            utils.showModalHtml('Views/VacationCalendar.html').done(function (html) {
                $vacationForm.append(html);
                new legacyComponent({i18n: vueI18n}).$mount($vacationForm[0]);
                const vc = new VacationsCalendar($('#titleCalendar'), $('#VacationsCalendar'), $('#inputYear'));
                $('#showCalendar').click(function () {
                    vc.ShowCalendar($('#inputYear').val());
                });
                $('#saveButton').click(function () {
                    vc.Save();
                });
                vc.ShowCalendar(0);
                params.onLoaded();
            });

            $('.view').hide();
            $vacationForm.show();
        };

    return {
		activate: activate
	};
})();
