export default class Restful {
    private static getDefaultConfig(url: string, method: HttpVerb, headers: { [name: string]: string } = null): JQueryAjaxSettings {
        let settings: JQueryAjaxSettings = {};
        settings.url = url;
        settings.method = HttpVerb[method];
        settings.contentType = "application/json";
        settings.cache = false;

        settings.beforeSend = (xhr) => {
            if (headers != null)
                for (let key in headers)
                    xhr.setRequestHeader(key, headers[key]);
            else
                xhr.setRequestHeader("__RequestVerificationToken", $("[name='__RequestVerificationToken']").val() + ""); 
        };

        return settings;
    }

    public static get(url: string, data: Object = null, headers: { [name: string]: string } = null): JQueryXHR {
        let settings = this.getDefaultConfig(url, HttpVerb.GET, headers);
        settings.data = $.param(data);
        return $.ajax(settings);
    }

    public static post(url: string, data: Object = null, headers: { [name: string]: string } = null, isJson: boolean = false): JQueryXHR {
        let settings = this.getDefaultConfig(url, HttpVerb.POST, headers);
        settings.data = isJson ? JSON.stringify(data) : data;
        return $.ajax(settings);
    }
}

enum HttpVerb {
    POST,
    GET,
    PUT,
    DELETE,
    PATCH
}
