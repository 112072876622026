
export default class {
    constructor() {
        this.setAccessToken();
    }

    getAccessToken() {
        return global.sessionStorage.gettItem('accessToken');
    }

    setAccessToken() {
        global.sessionStorage.setItem('accessToken', payload.token);
    }
}
