const DevExpress = require("devextreme/bundles/modules/core");
require("devextreme/integration/jquery");
const ui = DevExpress.ui = require("devextreme/bundles/modules/ui");
ui.dialog = require("devextreme/ui/dialog");
//ui.notify = require("devextreme/ui/notify");
import notify from 'devextreme/ui/notify';

import { appForm } from 'jsf/vm.appform.js';

export default  {
    generateCacheKey(webMethod, dataObject) {
        let cacheKey = '';
        if (webMethod === 'GetListItems') {
            const validNames = ['ddSetadRegistration',
                'dduniPiRegistration',
                'ddUniRegistration',
                'ddEduProvinceRegistration',
                'ddLivProvinceRegistration',
                'ddFirstPersonTypeRegistration',
                'ddEduRegistration',
                'ddLicenseRegistration',
                'ddUniGlbRegistration'];

            let generated = false;
            for (let i = 0; i < validNames.length; i++)
                if (dataObject.controlId === validNames[i]) {
                    generated = true;
                    break;
                }

            if (generated)
                cacheKey = dataObject.controlId + dataObject.keywords + dataObject.maxResults;

        } else if (webMethod === "GenerateMenuItems" || webMethod === "GetUiMenu") {
            cacheKey = gbr;
            if (gbr === '')
                cacheKey = "abcdef";
        } else if (webMethod === "GetNews") {
            cacheKey = $.cookie("principalId") === "5A47FEF0-5E9E-4A6C-8112-1F2808B12FF5" ? "abcd" : "cdb";
            cacheKey = cacheKey + dataObject.page;
        }
        if (cacheKey !== '')
            return `cid=${Sha1.hash(cacheKey)}`;
        return '';
    },

    callWebMethod(serviceUrl, methodName, dataObject, onSuccess, onError, requestHeaders, sync) {
        methodName = `${methodName}?${this.generateCacheKey(methodName, dataObject)}`;
        console.warn('callWebMethod', `${serviceUrl}/${methodName}`);
        const that = this;
        $.ajax(
            {
                type: "POST",
                async: !sync,
                url: `${serviceUrl}/${methodName}`,
                cached: false,
                data: dataObject ? JSON.stringify(dataObject) : "{}",
                dataType: "json",
                headers: that.getHeaders(),
                contentType: "application/json; charset=utf-8",
                success: onSuccess,
                error: onError
            });
    },

    callRest(serviceUrl, methodName, dataObject, sync, queryString) {
        //methodName = methodName + "?" + generateCacheKey(methodName, dataObject);
        if (typeof queryString !== "undefined" && queryString !== "" && queryString !== null) {
            queryString = $.param(queryString);
            serviceUrl = `${serviceUrl}?${queryString}`;
        }
        //console.log('callRest', serviceUrl);
        const that = this;
        return $.ajax(
            {
                type: methodName,
                async: !sync,
                url: serviceUrl,
                cached: false,
                data: dataObject ? JSON.stringify(dataObject) : "{}",
                dataType: "json",
                headers: that.getHeaders(),
                contentType: "application/json; charset=utf-8"
            }).promise();
    },

    callWebService(serviceUrl, methodName, dataObject, sync) {
        methodName = `${methodName}?${this.generateCacheKey(methodName, dataObject)}`;
        console.warn('callWebService', serviceUrl, methodName);
        const that = this;
        return $.ajax(
            {
                type: "POST",
                async: !sync,
                url: `${serviceUrl}/${methodName}`,
                cached: false,
                data: dataObject ? JSON.stringify(dataObject) : "{}",
                dataType: "json",
                headers: that.getHeaders(),
                contentType: "application/json; charset=utf-8"
            }).promise();
    },

    callWebServicenopi(serviceUrl, methodName, dataObject, sync) {
        methodName = `${methodName}?${this.generateCacheKey(methodName, dataObject)}`;
        console.warn('callWebServicenopi', `${serviceUrl}/${methodName}`);
        const that = this;
        return $.ajax(
            {
                type: "POST",
                async: !sync,
                url: `${serviceUrl}/${methodName}`,
                cached: false,
                data: dataObject ? JSON.stringify(dataObject) : "{}",
                dataType: "json",
                headers: that.getHeaders(),
                contentType: "application/json; charset=utf-8"
            }).promise();
    },

    callWebAPI(url, data, onSuccess, onFailure, method, async, beforeSend) {
        const ajaxConfig = {
            url: url,
            data: method === "POST" ? JSON.stringify(data) : data,
            dataType: "json",
            method: method || "GET",
            headers: this.getHeaders(),
            async: async == null,
            contentType: "application/json;charset=utf-8"
        };
        if (typeof beforeSend === 'function')
            ajaxConfig.beforeSend = beforeSend;
        $.ajax(ajaxConfig)
            .done((result) => {
                if (typeof onSuccess === 'function')
                    onSuccess(result);
            }).error((error) => {
                if (typeof onFailure === 'function') {
                    if (error.status === 400) {
                        const response = JSON.parse(error.responseText);
                        onFailure(response);
                    }
                    else {
                        onFailure(error);
                    }
                }
            });
    },

    callWebAPIp(url, data, method, async, contenttype) {
        const ajaxConfig = {
            url: url,
            data: method === "POST" ? JSON.stringify(data) : data,
            dataType: "json",
            method: method || "GET",
            headers: this.getHeaders(),
            async: async == null,
            contentType: contenttype || "application/json;charset=utf-8"
        };
        return $.ajax(ajaxConfig).promise();
    },

    getParameterByName(name) {
        const results = router.app.$route.params[name];
        return results == null ? "" : decodeURIComponent(results.replace(/\+/g, " "));
    },

    getRequestHeader(pi) {
        const requestHeaders = [];
        try {
            requestHeaders.push({ Key: "CSRF_Token", value: $("[name=__RequestVerificationToken]:first").val() });

            pi = pi ? pi : appForm.pageInstanceId();
            if (typeof pi !== "undefined")
                requestHeaders.push({ Key: "pageInstanceId", Value: pi ? pi : appForm.pageInstanceId() });
        } catch (ex) {
        }
        return requestHeaders;
    },

    getHeaders(pi) {
        const requestHeaders = {};
        try {
            requestHeaders["CSRF_Token"] = $.trim($("[name=__RequestVerificationToken]:first").val());
            pi = pi ? pi : appForm.pageInstanceId();
            if (typeof pi !== "undefined")
                requestHeaders["pageInstanceId"] = pi;
        } catch (ex) {

        }
        return requestHeaders;
    },

    showAjaxError(e, status, thrownError, controlId, redirectPage) {
        if (!thrownError && thrownError !== "")
            return;

        let errorContainer = $("<div />");
        let dialogTitle = thrownError;
        const messageList = $("<div/>").addClass("bpmsInfo");
        let details = e.responseText;
        let title = thrownError;

        if (e.status === 500) {
            dialogTitle = i18n.$t("errors.server_error");
            const errorObj = JSON.parse(e.responseText);
            title = errorObj.ExceptionMessage;
            details = errorObj.StackTrace;


            if (errorObj.ExceptionType === 'Ray.BPMS.Web.App.Infrastructure.FormAccessDeniedException') {
                this.showMessage(i18n.$t('errors.you_are_not_allowed_to_view_form'), 20000, "warning");
                router.go(-1);
                return;
            }

            const id = this.rayGuid();

            const errorTitle = (`<h4>${title}</h4>`);
            const errorShowMore = $(`<button class='btn btn-danger' data-toggle='collapse'>${i18n.$t("show_error_details")}</button>`).attr("data-toggle", `#t_${id}`);
            const errorStackTrace = $(`<div class='collapse' style='word-wrap:break-word;direction:ltr;text-align:left'><hr />${details}</div>`).attr("id", `t_${id}`);

            if (!(details === null || details === 'null'))
                errorContainer.append(errorShowMore)
                    .append(errorStackTrace);

            errorContainer.append(errorTitle);

            errorShowMore.click(function () {
                errorStackTrace.collapse();
                errorShowMore.hide();
            });

            errorContainer.rayModal({
                title: dialogTitle,
                width: "col-sm-6",
                onClosed: function () {
                    router.go(-1);
                }
            });

            return;
        }
        if (controlId)
            dialogTitle += ` - ${i18n.$t("control")}: ${controlId}`;

        messageList.append(details);

        errorContainer = $("<div></div>").append(`<h2>${title}:</h2>`).append(messageList);

        $(errorContainer).rayModal({
            title: dialogTitle,
            width: "col-sm-6",
            onClosed: function () {

            }
        });
    },

    showModalDialog(url, title, modalWidth, modalHeight, queryString) {
        const modalId = 'divModal';
        const $divDialog = $('<div></div>');
        $divDialog.attr('id', modalId);
        $divDialog.height(modalHeight);
        //let pid = router.app.$route.params;
        const iframe = $(`<iframe src='${url}?${queryString}' frameborder='0' Width="100%" Height="100%" scrolling ='auto'></iframe>`);
        $divDialog.append(iframe);
        new legacyComponent({ i18n: vueI18n }).$mount($divDialog[0]);
        //document.forms[0].appendChild(divDialog[0]);
        $(function () {
            $divDialog.rayModal({
                title: title,
                width: modalWidth,
                height: modalHeight
            });
        });
    },

    showModalHtml(url) {
        url = `api/HtmlLoader/${url}`;
        const that = this;
        const promise = $.ajax(
            {
                url: url,
                dataType: "html",
                cache: false,
                headers: that.getHeaders()
            });

        promise.fail(function (res) {
            try {
                const message = JSON.parse(res.responseText);
                if (message.ExceptionType === "System.UnauthorizedAccessException")
                    that.showMessage(i18n.$t('errors.access_denied'), 1500, "warning");
            } catch (ex) {
                that.showMessage(i18n.$t('errors.error_occured'), 1500, "warning");
            }
        });
        return promise;
    },

    modalConfirmation(message, callbackFunction, mycontainer, width, validator, title) {
        if (typeof width === "undefined" || width === null)
            width = 300;

        if (typeof title === "undefined")
            title = i18n.$t("confirm");

        let container = $(`<div>${message}</div>`);
        if (mycontainer)
            container = mycontainer;
        const btns = {};
        btns[i18n.$t("submit")] = function () {
            if (typeof validator === "function") {
                if (validator())
                    container.rayModal('close');
            } else container.rayModal('close');
            callbackFunction(true);
        };
        btns[i18n.$t("cancel")] = function () {
            container.rayModal('close');
            callbackFunction(false);
        };

        container.rayModal({
            resizable: false,
            width: parseInt(width),
            modal: true,
            direction: 'rtl',
            closeOnEscape: false,
            title: title,
            buttons: btns
        }).parent('.ui-dialog').addClass('jquery-ui-scope');

        container.closest('.modal-content').find('.close').css('display', 'none');
    },

    showGenericModalWithContainer(title, container, callbackFunction, buttons) {
        //let windowHeight = $(window).height() - 30;
        //let containerHeight = container.height();
        container.css({ 'margin-left': '10px', 'margin-right': '10px' });
        container.rayModal({
            resizable: true,
            modal: true,
            width: 'col-lg-6',
            //minWidth: 600,
            closeOnEscape: false,
            hide: 'slide',
            show: 'fade',
            title: title,
            buttons: buttons,
            autoResize: true,
            onClosed: function () {
                if (callbackFunction != null) callbackFunction(true);
                $(container).rayModal('destroy');
                container.remove();
            }
        }).parent('.ui-dialog').addClass('jquery-ui-scope');
        //if (containerHeight > windowHeight);
    },

    showGenericModal(title, message, callbackFunction, direction) {
        const container = $(`<div dir="${direction}">${message}</div>`);
        this.showGenericModalWithContainer(title, container, callbackFunction);
    },

    modalDialog(title, message, modalWidth, modalHeight) {
        const container = $(`<div>${message}</div>`);
        const buttons = {};
        buttons[i18n.$t('ok')] = function () {
            container.rayModal('close');
        };
        container.rayModal({
            resizable: false,
            width: Math.ceil(modalWidth),
            height: Math.ceil(modalHeight),
            modal: true,
            closeOnEscape: true,
            title: title,
            buttons: buttons
        }).parent('.ui-dialog').addClass('jquery-ui-scope');
    },

    modalDialogWithContainer(message, container, callbackFunction) {
        //var windowHeight = $(window).height();

        const buttons = {};
        buttons[i18n.$t('ok')] = function () {
            container.rayModal('close');
        };

        container.rayModal({
            resizable: false,
            width: 'col-lg-6',
            modal: true,
            direction: 'rtl',
            closeOnEscape: true,
            title: message,
            buttons: buttons,
            onClosed: function () {
                if (callbackFunction != null) callbackFunction();
                $(container).rayModal('destroy');
                container.remove();
            }
        }).parent('.ui-dialog').addClass('jquery-ui-scope');

        //if (container.height() > windowHeight)
        //    container.dialog({ height: windowHeight - 50 });
    },

    confirm(message, callback) {
        //noty({
        //    text: message,
        //    type: 'confirm',
        //    layout: 'center',
        //    modal: true,
        //    buttons: [
        //        {
        //            text: i18n.$t("submit"), onClick: function ($noty) {
        //                $noty.close();
        //                callback.yes();
        //            },
        //        },
        //        {
        //            text: i18n.$t("cancel"), onClick: function ($noty) {
        //                $noty.close();
        //                callback.no();
        //            }
        //        }]
        //});

        const myDialog = DevExpress.ui.dialog.custom({
            title: "Confirmation",
            messageHtml: message,
            buttons: [
                {
                    text: i18n.$t("yes"),
                    onClick: function () {
                        return {result: true}
                    }
                },
                {
                    text: i18n.$t("no"),
                    onClick: function () {
                        return {result: false}
                    }
                }
            ]
        });
        myDialog.show().done(function (dialogResult) {
            //console.log("dialog", dialogResult.result);
            if (dialogResult.result)
                callback.yes();
            else
                callback.no();
        });
    },

    message(message, type) {
        //let msg = i18n.$t(message);
        notify(this.getToast(message, type), { position: 'top right', direction: 'down-stack' });
    },

    messagePD(message, type) {
        const msg = i18n.$t(message);
        notify(this.getToast(msg, type), { position: 'top right', direction: 'down-stack' });
    },

    getToast(msg, type) {
        let typeDesc = 'info';
        switch (type) {
            case 1:
                typeDesc = 'success';
                break;
            case 2:
                typeDesc = 'error';
                break;
            case 3:
                typeDesc = 'warning';
                break;
            case 4:
                typeDesc = 'info';
                break;
        }

        return {
            message: msg,
            type: typeDesc,
            width: 'auto',
            minWidth: 400,
            displayTime: 3000,
            position: {
                my: 'right top',
                at: 'top right',
                of: window,
                offset: '0 0'
            }
        };
    },

    showMessage(message, timeout, type) {
        //let params = {
        //    text: message,
        //    type: 'information',
        //    layout: 'center',
        //    timeout: 5000
        //};
        //if (timeout)
        //    params.timeout = timeout;
        //if (type)
        //    params.type = type;
        //noty(params);

        if (!timeout) timeout = 5000;
        if (!type) type = "success";
        notify({
            message: message,
            type: type,
            width: 'auto',
            minWidth: 400,
            displayTime: timeout,
            position: {
                my: 'top right',
                at: 'top right',
                of: window,
                offset: '0 0'
            }
        });
    },

    getParentContainer() {
        return (window.parentModalId != null && window.parentModalId != '-1')
            ? window.parent.document.getElementById(`_dialogframe${window.parentModalId}`).contentWindow
            : window.parent;
    },

    showProgress() { },

    hideProgress() { },

    applyUiButtons(scope) {
        if (!(scope.length > 0)) {
            return;
        }

        const buttons = scope.find("button:not([isr='true'])");
        if (buttons.length > 0)
            scope.find("button:not([isr='true'])").button();

        scope.find("button.btn-icon-add").button('option', 'icons', { primary: 'ui-icon-plus' });
        scope.find("button.btn-icon-edit").button('option', 'icons', { primary: 'ui-icon-pencil' });
        scope.find("button.btn-icon-delete").button('option', 'icons', { primary: 'ui-icon-trash' });
        scope.find("button.btn-icon-scan").button('option', 'icons', { primary: 'ui-icon-print' });
        scope.find("button.btn-icon-reset").button('option', 'icons', { primary: 'ui-icon-home' });
        scope.find("button.btn-icon-next").button('option', 'icons', { primary: 'ui-icon-triangle-1-w' });
        scope.find("button.btn-icon-prev").button('option', 'icons', { primary: 'ui-icon-triangle-1-e' });
        scope.find("button.btn-icon-last").button('option', 'icons', { primary: 'ui-icon-seek-first' });
        scope.find("button.btn-icon-first").button('option', 'icons', { primary: 'ui-icon-seek-end' });
        scope.find("button.btn-icon-gear").button('option', 'icons', { primary: 'ui-icon-gear' });
        scope.find("button.btn-icon-search").button('option', 'icons', { primary: 'ui-icon-search' });
        scope.find("button.btn-icon-mail-closed").button('option', 'icons', { primary: 'ui-icon-mail-closed' });
        scope.find("button.btn-icon-gear-mail-closed").button('option',
            'icons',
            {
                primary: 'ui-icon-gear',
                secondary: 'ui-icon-mail-closed'
            });
        scope.find("button.btn-icon-newwin").button('option', 'icons', { primary: 'ui-icon-newwin' });
        scope.find("button.btn-icon-remove").button('option', 'icons', { primary: 'ui-icon-trash' });
        scope.find("button.btn-icon-existFile").button('option', 'icons', { primary: 'ui-icon-folder-open' });
        scope.find("button.btn-icon-download").button('option', 'icons', { primary: 'ui-icon-arrowthickstop-1-s' });
        scope.find("button.btn-icon-up").button('option', 'icons', { primary: '.ui-icon-arrowthick-1-n' });
        scope.find("button.btn-icon-down").button('option', 'icons', { primary: '.ui-icon-carat-1-s' });
        scope.find("button.btn-no-text").button('option', 'text', false);
    },

    isIntegrated() {
        // todo :
        return getParameterByName("co") === "t" || getParameterByName("pUrl");
    },

    isDoc(name) {
        switch (this.getFileExtension(name)) {
            case "doc":
            case "docx":
                return true;
            default:
                return false;
        }
    },

    isPdf(name) {
        return getFileExtension(name) === 'pdf';
    },

    isImage(name) {
        switch (this.getFileExtension(name)) {
            case "jpg":
            case "gif":
            case "bmp":
            case "jpeg":
            case "png":
            case "tiff":
            case "tif":
                return true;
            default:
                return false;
        }
    },

    getFileExtension(fileName) {
        return (/[.]/.exec(fileName)) ? (/[^.]+$/.exec(fileName))[0].toLowerCase() : undefined;
    },

    hideHeaderAndTreeFromShell() {
        if (this.getParameterByName('co') === 't') {
            $('.rightSection').css('display', 'none');
            $('.contentSection').css('right', "0").css('top', '0');
            $('.headerSection').css('display', 'none');
            //$('.contentSection').css('top', '0px');
            $('.topiconsSection').css('display', 'none');
            $('#contentmain').css('top', '0');
        }
    },

    rayGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
            function (c) {
                const r = Math.random() * 16 | 0;
                const v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
    },

    convertJSONDate(s) {
        if (typeof s === 'string') {
            const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
            let a = reISO.exec(s);
            if (a) {
                console.log("Date converted from ISO", s, new Date(s));
                return new Date(s);
            }
            const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
            a = reMsAjax.exec(s);
            if (a) {
                const b = a[1].split(/[-+,.]/);
                console.log("Date converted from MSAJAX", s, new Date(b[0] ? +b[0] : 0 - +b[1]));
                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }
        return s;
    },

    isScrolledIntoView(elem) {
        const $elem = $(elem);
        const $window = $(window);

        const docViewTop = $window.scrollTop();
        const docViewBottom = docViewTop + $window.height();

        const elemTop = $elem.offset().top;
        const elemBottom = elemTop + $elem.height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    },

    setSelectionRange(input, selectionStart, selectionEnd) {
        if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
            const range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }
    },

    encodeRFC5987ValueChars(str) {
        return encodeURIComponent(str).// Note that although RFC3986 reserves "!", RFC5987 does not,
            // so we do not need to escape it
            replace(/['()]/g, escape).// i.e., %27 %28 %29
            replace(/\*/g, '%2A').// The following are not required for percent-encoding per RFC5987,
            // so we can allow for a little better readability over the wire: |`^
            replace(/%(?:7C|60|5E)/g, unescape);
    },

    b64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str),
            c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
    },

    getFileChangeMode(code) {
        let ret = '';
        switch (code) {
            case 0:
                ret = 'None';
                break;
            case 1:
                ret = 'Create';
                break;
            case 2:
                ret = 'RevertPendding';
                break;
            case 3:
                ret = 'RevertVersion';
                break;
            case 4:
                ret = 'DeleteCompelet';
                break;
            case 5:
                ret = 'SetDelete';
                break;
            case 6:
                ret = 'DeleteFromBpms';
                break;
            case 7:
                ret = 'Pendding';
                break;
            case 8:
                ret = 'Commit';
                break;
            case 9:
                ret = 'NewVersion';
                break;
            case 10:
                ret = 'PenddingCommit';
                break;
            default:
        }
        return ret;
    },

    closeSSODialog(message) {
        $('#divModal').dialog('close');
        this.showMessage(message, 6000, 'success');
    },

    fixHeight() {
        const that = this;
        setTimeout(function () {
            $(".ui-autocomplete-input").autocomplete('close');
            //fixTabPaneHeight();
            that.fixBpmsFormHeight();


            $(".ui-tabs-nav").off("click")
                .click(function () {
                    that.fixTabPaneHeight();
                    //fixBpmsRectangleHeight(".BpmsForm");
                    that.fixBpmsFormHeight();
                })
                .keydown(function (e) {
                    if (e.keyCode !== 0 && e.keyCode !== 32)
                        return;
                    that.fixTabPaneHeight();
                    //fixBpmsRectangleHeight(".BpmsForm");
                    that.fixBpmsFormHeight();
                });
        }, 250);
    },

    fixBpmsFormHeight() {
        $(".BpmsForm").each(function (index, item) {
            const visisbleHeight = Math.floor(getMaxTop($(item).find(":visible:not(option)")) - $(item).offset().top);

            const dialog = $(item).closest('.app-modal-dialog');
            if (dialog.length > 0) {
                dialog.height(visisbleHeight + 100);
                dialog.css('min-height', visisbleHeight);
            }

            $(item).height(visisbleHeight + 15);
        });
    },

    fixTabPaneHeight(parent) {
        let tabPanels;
        let tabControls;

        if (parent == null || typeof parent === "undefined") {
            tabPanels = $(".BpmsForm div[role=tabpanel]");
            tabControls = $(".BpmsForm .RayTabControl");
        } else {
            tabPanels = $(parent).children("div[role=tabpanel]");
            tabControls = $(parent).children(".RayTabControl");
        }

        $(tabPanels).each(function (index, item) {
            if ($(item).prop('isr') === 'true') return;
            if ($(item).attr('isr') === 'true') return;

            if (typeof $(this).attr("old-height") !== "undefined" && $(this).attr("old-height") != "10000")
                $(this).attr("old-height", $(this).height());
            else
                $(this).attr("old-height", 10000);

            if ($(item).css("visibility") === "hidden" || $(item).css("display") === "none") return;
            const visisbleHeight = Math.floor(getMaxTop($(item).find(":visible")) - $(item).offset().top);

            if (visisbleHeight /*< parseInt($(this).attr("old-height"))*/) {
                $(item).height(visisbleHeight);
                $(this).attr("old-height", visisbleHeight);
            }

            correctVisibleHidden(item);
        });

        $(tabControls).each(function (index, tabControl) {

            if ($(tabControl).prop('isr') === 'true') return;

            if ($(tabControl).css("visibility") === "hidden" || $(tabControl).css("display") === "none") return;

            const visisbleHeight = Math.floor(calculateHeight($(tabControl).children(":visible:not(option)")));

            $(tabControl).height(visisbleHeight);
        });
    },

    resizeRichTextBox(parent, enabled) {
        var elToResize = parent.find('div.RayRichTextBox.ajaxControl');

        var labelHeight = elToResize.siblings('label').outerHeight(true);
        if (elToResize.siblings('label') && elToResize.siblings('label').text() && elToResize.siblings('label').text().length > 0 && elToResize.siblings('label').outerHeight(true) < 10)
            labelHeight = 30; // If label contains text, but its size is minimal (means delayed init due to lazy loading or hidden sections) apply default height
        var toolbarHeight = parent.find('.ck-toolbar').outerHeight(true);
        if (toolbarHeight == 0 && enabled)
            toolbarHeight = 40; // If rich text enabled, means modifiable, means toolbar is present, set default toolbar height

        var heightToSubstract = labelHeight + toolbarHeight;
        var heightValue = `calc(100% - ${heightToSubstract}px)`;

        //If richtext is disabled (only html render) get max height value if set, to calculate the height for html content to make it scrollable
        if (!enabled) {
            var parentMaxHeightString = parent.find('div.RayRichTextBox.ajaxControl').eq(0).parent('.RayRichTextBox')[0].style.maxHeight;
            if (parentMaxHeightString) {
                var parentMaxHeightString = parseInt(parentMaxHeightString);
                if (parentMaxHeightString) {
                    var maxHeightForContent = parentMaxHeightString - heightToSubstract;
                    if (elToResize.height() >= maxHeightForContent) {
                        heightValue = `${maxHeightForContent}px`;
                    }
                }
            }
        }
        elToResize.css({ height: heightValue });
        return heightValue;
    },
    
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    createGUID() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
            c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    convertTicksToDate: function (nbrTicks) {
        //If due to cast errors, the number is bigger than max number of ticks
        var nbrMaxTicks = 3155378975999999999;
        if (nbrTicks > nbrMaxTicks) { return new Date('01/01/9999'); }
        //ticks are in nanotime; convert to microtime
        let ticksToMicrotime = nbrTicks / 10000;
        //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
        let epochMicrotimeDiff = 62135596800000;
        //new date is ticks, converted to microtime, minus difference from epoch microtime
        let date = new Date(ticksToMicrotime - epochMicrotimeDiff);
        //remove automatically added timzone offset
        let timezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + timezoneOffset);

    },
    isGuid: function (value) {
        var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
        var match = regex.exec(value);
        return match != null;
    },
    convertDateToTicks: function (date) {
        //621355968000000000 : ticks between 1st Jan 0001 and 1st Jan 1970.
        return ((date * 10000) + 621355968000000000);
    },
    formatDate(inputDate, format) {
        if (!inputDate) return '';
        if (!(inputDate instanceof Date)) {
            throw new Error('Invalid Date parameter. Expected a Date object.');
        }

        var dateValue = new Date(inputDate);
        const padZero = (value) => (value < 10 ? `0${value}` : `${value}`);
        const parts = {
            yyyy: dateValue.getFullYear(),
            MM: padZero(dateValue.getMonth() + 1),
            dd: padZero(dateValue.getDate()),
            HH: padZero(dateValue.getHours()),
            hh: padZero(dateValue.getHours() > 12 ? dateValue.getHours() - 12 : dateValue.getHours()),
            mm: padZero(dateValue.getMinutes()),
            ss: padZero(dateValue.getSeconds()),
            tt: dateValue.getHours() < 12 ? 'AM' : 'PM'
        };

        return format.replace(/yyyy|MM|dd|HH|hh|mm|ss|tt/g, (match) => parts[match]);
    }

}
