var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: { title: _vm.$t("rule_management.title"), className: "col-xs-12" },
    },
    [
      _c(
        "div",
        { class: [_vm.direction ? "k-rtl" : ""] },
        [
          _c("grid", {
            attrs: {
              columns: _vm.columns,
              schema: _vm.schema,
              reloadGrid: _vm.reloadGrid,
              filterable: "false",
              dataSourceUrl: _vm.dataSourceUrl,
              hasContextMenu: "false",
              hasCheckbox: "false",
              hasDetail: "false",
              hasSearch: "false",
              pagingType: "scroll",
              selectable: "false",
              columnsMenu: "false",
              resizable: "false",
              sortable: "true",
              hasServerSorting: "false",
              toolbar: _vm.toolbar,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.currentModalComponent, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRuleModal,
            expression: "showRuleModal",
          },
        ],
        tag: "component",
        attrs: {
          showRuleModal: _vm.showRuleModal,
          modalData: _vm.modalData,
          baseInfo: _vm.baseInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }