import NewForm from '../forms/newform/newForm.vue';
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxPager,
    DxPaging,
    DxExport,
    DxToolbar,
    DxItem,
    DxLoadPanel,
    DxButton as DxButtonColumn
} from 'devextreme-vue/data-grid';
import { DxButton as DxButtonToolbar } from 'devextreme-vue/button';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import utils from 'jsf/utils.js';

const url = 'api/Logs/GetActiveConnections';

const dataSource = createStore({
    key: 'Id',
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { "Content-type": "application/json" };
    }
});

export default {
    data() {
        return {
            dataGridRefKey: 'dataGrid',
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            url,
            dataSource
        }
    },
    methods: {
        refreshDataGrid() {
            this.dataGrid.refresh();
        },

        sendMessage(e) {
            const userIds = [].concat(e.row.data.UserId);
            this.sendMessageToUsers(userIds);
        },

        sendMessageToAllClick() {
            const userIds = this.dataGrid.getVisibleRows().map(a => a.data.UserId);
            this.sendMessageToUsers(userIds);
        },

        sendMessageToUsers(userIds) {
            const messageSample = i18n.$t("server_shutdown_template");
            var promptMessage = `${i18n.$t("server_notif_message")} ${userIds.length} ${i18n.$t("server_notif_message_users")}:`;
            const text = prompt(promptMessage, messageSample);
            if (text)
            {
                utils.callWebAPIp("api/Logs/SendMessageToUsers", { userIds: userIds, text: text }, 'POST')
                .done(function (result) {
                    utils.message(i18n.$t("server_notif_message_sent"), 1);
                })
                .fail(function (e, status, thrownError) {
                    utils.message(i18n.$t("server_notif_message_error"), 2);
                });

            }
        }
    },

    updated() {
    },

    mounted() {
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        }
    },

    components: {
        NewForm,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging,
        DxExport,
        DxToolbar,
        DxItem,
        DxLoadPanel,
        DxButtonColumn,
        DxButtonToolbar
    }
}
