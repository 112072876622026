import Tree from "../widgets/tree/tree.vue";
import CreatePhysicalFolder from "./contextmodals/CreatePhysicalFolder.vue";
import { Helper } from '../../assets/ts/global';
import hubProxy from '../../assets/ts/signalr';
import utils from 'jsf/utils.js';

const helper = new Helper();
export default {
    data() {
        const currentTaskCount = [];
        return {
            data: [],
            init: false,
            direction: payload.currentLocale.IsRightToLeft,
            contextItemType: {},
            showContextModal: 0,
            currentModalComponent: "",
            mustSelectedNodeId: null,
            setSelectedNodeId: null,
            modalData: {},
            treeTemp: null,
            expandedItems: {},
            nodeId: null,
            expandRootItem: payload.isOpenCartablFolders,
            loaded: false
        };
    },

    computed: {
        selectedNodeId() {
            if (this.mustSelectedNodeId)
                return this.mustSelectedNodeId;

            if (!this.data || this.data.length === 0)
                return null;

            const staffs = this.data[0].ChildItems.filter(t => t.ItemType === 2);
            let id = this.data[0].ChildItems[0].Id;
            if (staffs.length !== 0 && payload.ExpandStaffTree === true)
                id = staffs[0].ChildItems[0].Id;
            return id;
        }
    },

    methods: {
        reloadTreeNodes() {
            const that = this;
            this.loaded = false;
            //console.log("reload task tree");
            utils.callWebAPI("api/tasktree", {}, (data) => {
                that.data = data;
                that.loaded = true;
                //that.initAfterLoad();
            });
        },

        /**
         * this will build context menu Items depend on given nodeData
         * @param {selector} target target of contextMenu
         * @param {Object} nodeData data of target
         * @returns contextMenu Items
         */
        contextItemsLoader(target, nodeData) {
            const itemType = this.contextItemType[nodeData.ItemType];
            let items;
            //let NewWindowItem = {
            //    'text': i18n.$t("context.open_in_new_window"),
            //    'icon': "fad fa-external-link",
            //    'separator_after': true,
            //    'action': (node, nodeData) => {
            //        return;
            //        if (obj.find('a').data('href'))
            //            window.open(obj.find('a').data('href'));
            //        else
            //            window.open(obj.find('a').attr('href'));
            //    }
            //};
            const RuleCriteriaItem = {
                key: 'RuleCriteriaItem',
                'text': i18n.$t("context.rules"),
                'icon': "fad fa-book",
                'separator_before': true,
                'action': (node, nodeData) => { this.$router.push({ name: 'rule-management', params: { pId: nodeData.Id } }); }
            };

            const NewPhysicalFolderItem = {
                key: 'NewPhysicalFolderItem',
                'text': i18n.$t("context.new_folder"),
                'icon': "fad fa-plus-square",
                'action': (node, nodeData) => {
                    this.modalData.isCreation = true;
                    this.modalData.callback = () => { this.reloadTreeNodes(); };
                    this.modalData.nodeDataItem = nodeData;
                    this.modalData.nodeDataItem.ItemTypeString = this.contextItemType[nodeData.ItemType];
                    this.currentModalComponent = "CreatePhysicalFolder";
                    this.showContextModal++;
                }
            };

            const EditPhysicalFolderItem = {
                key: 'EditPhysicalFolderItem',
                'text': i18n.$t("context.edit_folder"),
                icon: "fad fa-edit",
                'action': (node, nodeData) => {
                    this.modalData.isCreation = false;
                    this.modalData.callback = (editedNode) => {
                        this.$refs.tree.editNode(nodeData, editedNode);
                    };
                    this.modalData.nodeDataItem = nodeData;
                    this.modalData.nodeDataItem.ItemTypeString = this.contextItemType[nodeData.ItemType];
                    this.currentModalComponent = "CreatePhysicalFolder";
                    this.showContextModal++;
                }
            };

            const DeletePhysicalFolderItem = {
                key: 'DeletePhysicalFolderItem',
                'text': i18n.$t("context.delete_folder"),
                icon: "fad fa-trash-alt",
                'action': (node, nodeData) => {
                    var that = this;
                    utils.confirm(i18n.$t("are_you_sure"),
                        {
                            yes: function () { utils.callWebAPI("api/folder/DeletePhysicalFolder", { id: nodeData.Id }, () => { that.$refs.tree.removeNode(nodeData); }); },
                            no: function () { }
                        });
                }
            };

            switch (itemType) {
                case 'TaskType':
                    return [];
                case 'PhysicalFolder':
                    return [NewPhysicalFolderItem, EditPhysicalFolderItem, DeletePhysicalFolderItem];
                case 'Folder':
                    return [];
                case 'Staff':
                case 'User':
                    return [NewPhysicalFolderItem, RuleCriteriaItem];
                case 'DefaultPhysicalFolder':
                    return [];
            }

            return items;
        },

        /**
         * Event that will trigger on tree node select
         * @param {selector} node
         * @param {Object} nodeData
         */
        clearSearchItem() {
            sessionStorage.setItem('cartableSearchItem', '');
        },

        nodeSelect(node, nodeData) {
            if (nodeData.IsSelectable) {
                //console.log("Node selected", nodeData);
                this.$router.push({
                    name: 'tasksGrid',
                    params: {
                        parentId: nodeData.ParentId,
                        folderId: nodeData.Id,
                        folderName: nodeData.Text,
                        isDraft: nodeData.IsDraft
                    }
                });
            }
        },

        /**
         * Update attribute of given nodeId in tree
         * @param {Guid} nodeId target node id
         * @param {String} attrName target node attribute name
         * @param {any} attrValue target node attribute value
         */
        updateNodeAttribute(nodeId, attrName, attrValue) {
            this.$refs.tree.updateAttribute(nodeId, attrName, attrValue);
        },

        treeLoaded() {
            var self = this;
            //console.log("TaskTree: re-count");
            utils.callWebAPI("api/tasktree/count", {}, result => { self.currentTaskCount = result; });

            window.redirectToSelectedNode = function () {
                const selectedNode = self.selectedNodeId;
                if (selectedNode) { //} == null || selectedNode === undefined) {
                    const staffs = self.data[0].ChildItems.filter(t => t.ItemType === 2);
                    //console.log("Initial Node selected", self.data[0].ChildItems[0]);
                    if (staffs.length === 0 || payload.ExpandStaffTree === false)
                        router.push({
                            name: 'tasksGrid',
                            params: {
                                folderId: self.data[0].ChildItems[0].Id,
                                folderName: self.data[0].Text + " - " + self.data[0].ChildItems[0].Text
                            }
                        });
                    else
                        router.push({
                            name: 'tasksGrid',
                            params: {
                                folderId: staffs[0].ChildItems[0].Id,
                                folderName: staffs[0].Text + " - " + staffs[0].ChildItems[0].Text
                            }
                        });
                } else {
                    if (self.setSelectedNodeId === selectedNode)
                        self.selectNode(1);
                    else
                        self.selectNode(selectedNode);
                }
            }
        },

        sumerizeFolderCount(taskCount, treeItems) {
            const summerizeFolders = {};
            for (let i = 0; i < taskCount.length; i++) {
                let parentTitle = '';
                if (taskCount[i].ParentId in summerizeFolders) continue;
                let nodeElement = this.$refs.tree.getNodeById(taskCount[i].ParentId);
                if (nodeElement === null || nodeElement === undefined)
                    nodeElement = this.getNodeById(taskCount[i].ParentId, this.data);
                if (nodeElement != null && nodeElement.FolderType !== "Draft") {
                    if (nodeElement.ParentId === null)
                        nodeElement.ParentId = nodeElement.PrincipalId;
                    if (nodeElement.ParentId != null) {
                        let parentElement = this.$refs.tree.getNodeById(nodeElement.ParentId);
                        if (parentElement === null || parentElement === undefined)
                            parentElement = this.getNodeById(nodeElement.ParentId, this.data);
                        parentTitle = parentElement.Text;
                    }

                    const title = nodeElement.Text;
                    let folderCount = 0;

                    for (let j = 0; j < taskCount.length; j++)
                        if (taskCount[j].ParentId === taskCount[i].ParentId)
                            folderCount += taskCount[j].Count;

                    summerizeFolders[taskCount[i].ParentId] = {
                        title: parentTitle + " -> " + title,
                        count: folderCount
                    };
                }
            }
            return summerizeFolders;
        },

        getNodeById(id, items) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].Id === id)
                    return items[i];
                else {
                    let result = false;
                    if (items[i].ChildItems != null && items[i].ChildItems.length !== 0)
                        result = this.getNodeById(id, items[i].ChildItems);
                    if (result)
                        return result;
                }
            }
        },

        initProxyHub() {
            const self = this;
            var messages = [];
            $.extend(hubProxy.client, {
                taskInstanceRecived: function (treeItems, taskCounts) {
                    if (localStorage.dbpDebugMode === 'true')
                        console.log("SignalR: taskInstanceRecived");

                    messages = [];
                    self.data = treeItems;
                    const summerizeFolders = self.sumerizeFolderCount(taskCounts);
                    const sumerizedCurrentTaskCount = self.sumerizeFolderCount(self.currentTaskCount);

                    const rootFolder = self.data[0];
                    for (let parentId in summerizeFolders) {
                        if (summerizeFolders.hasOwnProperty(parentId)) {
                            if (typeof summerizeFolders[parentId] !== "undefined" &&
                                typeof sumerizedCurrentTaskCount[parentId] !== "undefined" &&
                                summerizeFolders[parentId].count > sumerizedCurrentTaskCount[parentId].count) {

                                if ((utils.getParameterByName("co") !== "t/" || utils.getParameterByName("co") === "t")) {
                                    messages.push({
                                        nodeId: parentId,
                                        title: summerizeFolders[parentId].title
                                    });
                                }

                                //for grid refresh
                                if (rootFolder) {
                                    const taskFolders = rootFolder.ChildItems;
                                    const node = self.getNodeById(parentId, taskFolders);
                                    //Update task list if you are on it
                                    if (router.app.$route.name === 'tasksGrid' && router.app.$route.params["folderId"] === node.Id) {
                                        self.$router.push({
                                            name: 'tasksGrid',
                                            params: {
                                                parentId: node.ParentId,
                                                folderId: node.Id,
                                                folderName: node.Text,
                                                isDraft: node.IsDraft,
                                                //Unique parameter to bypass "redundant navigation" validation
                                                uniqueParameter: new Date().getTime()
                                            }
                                        });
                                    }
                                }

                            }
                        }
                    }

                    if (messages.length === 1)
                        self.showNotification(messages[0].nodeId, messages[0].title, self.getNodeById(messages[0].nodeId, self.data));
                    else if (messages.length > 1) {
                        let title = "";
                        for (let i = 0; i < messages.length; i++)
                            title += (i + 1).toString() + " - " + messages[i].title + "\r\n";
                        self.showNotification(null, title, self.getNodeById(messages[0].nodeId, self.data));
                    }

                }
            });
        },

        showNotification(id, title, node) {
            const self = this;
            const dir = payload.currentLocale.IsRightToLeft ? 'rtl' : 'ltr';
            var options = {
                body: title,
                icon: "./dist/bpms-notif.png",
                dir: dir
            }

            Notification.requestPermission(function () {
                //console.log("Send browser notification", options, node);
                var instance = new Notification(i18n.$t('new_task'), options);
                instance.onclick = function (e) { self.nodeSelect(node, node); }
                setTimeout(function () { instance.close(); }, 4000);
            });
        },

        treeItemSelected(x) {
            this.mustSelectedNodeId = x;
        },

        selectNode(x) {
            this.setSelectedNodeId = x;
        },

        initAfterLoad() {
            this.init = true;

            this.contextItemType = helper.enumBuilder({
                None: 0,
                Folder: 1,
                Staff: 2,
                User: 3,
                DefaultPhysicalFolder: 4,
                PhysicalFolder: 5,
                TaskType: 6
            });
            this.treeTemp = $("#rayvarzBPMSKendoTreeViewTemplate", this.$el).html();

            setTimeout(() => {
                if (this.$route.name === 'tasksGrid' && this.$route.params.folderId)
                    this.mustSelectedNodeId = this.$route.params.folderId;
            },
                500);

            if (!payload.isPlugin)
                this.initProxyHub();

        }
    },

    updated() { },

    created() {
        this.reloadTreeNodes();
    },

    mounted() {
        this.init = true;

        this.contextItemType = helper.enumBuilder({
            None: 0,
            Folder: 1,
            Staff: 2,
            User: 3,
            DefaultPhysicalFolder: 4,
            PhysicalFolder: 5,
            TaskType: 6
        });
        this.treeTemp = $("#rayvarzBPMSKendoTreeViewTemplate", this.$el).html();

        setTimeout(() => {
            if (this.$route.name === 'tasksGrid' && this.$route.params.folderId)
                this.mustSelectedNodeId = this.$route.params.folderId;
        },
            500);

        if (!payload.isPlugin)
            this.initProxyHub();
    },
    components: {
        Tree,
        CreatePhysicalFolder
    }
}
