var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c("h1", {
        staticStyle: { "margin-top": "0px" },
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _vm._v(" "),
      _c("grid", {
        attrs: {
          columns: _vm.columns,
          additionalParameters: _vm.additionalParameters,
          reloadGrid: _vm.reloadGrid,
          boldOnField: _vm.boldOnField,
          filterable: "false",
          dataSourceUrl: "api/tasks",
          detailConfig: _vm.detailConfig,
          hasContextMenu: "true",
          hasCheckbox: "true",
          hasDetail: "true",
          hasPaging: "true",
          hasSearch: _vm.isNotDraft,
          isDraft: !_vm.isNotDraft,
          pagingType: "scroll",
          selectable: "multiple",
          columnsMenu: "true",
          resizable: "true",
          sortable: "true",
          height: _vm.height,
          contextMenuItems: _vm.contextMenuItems,
          gridContextOpen: _vm.contextMenuOpen,
          getColumns: _vm.buildColumns,
          toolbar: _vm.toolbar,
          getToolbar: _vm.buildToolbar,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "js_load-form" }),
      _vm._v(" "),
      _c("div", { staticClass: "bpmsTimesheetForm" }),
      _vm._v(" "),
      _c(_vm.currentModalComponent, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTrackingHistory,
            expression: "showTrackingHistory",
          },
        ],
        tag: "component",
        attrs: {
          showTracking: _vm.showTrackingHistory,
          modalData: _vm.modalData,
        },
      }),
      _vm._v(" "),
      _c(_vm.currentModal, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTaskAssign,
            expression: "showTaskAssign",
          },
        ],
        tag: "component",
        attrs: {
          showTaskAssign: _vm.showTaskAssign,
          modalData: _vm.modalDataTaskAssign,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }