import CartableTree from '../cartabletree/cartableTree.vue';

export default {
    data() {
        return {
            userTitle: '',
            userStaff: '',
            staffTooltip: [],
            hasAvatar: payload.hasAvatar,
            isRTL: payload.currentLocale.IsRightToLeft,
            version: payload.version,
            creationDate: payload.creationDate,
            customerUrl: payload.customerUrl,
            customerName: payload.customerName,
            helpUrl: payload.helpUrl 
        }
    },
    computed: {
        customerLogo() {
            return payload.customerLogo;
        },
        //logoUrl() {
        //    let url = 'https://interfacing.com';
        //    return this.customerLogo === 'api/file/getappfile?action=customerlogo' ? '#' : url;
        //},
        hasBanner() {
            return payload.hasBanner;
        }
    },
    mounted() {

        $(window).on('scroll',
            () => {
                this.rePosition();
            });

        $(window).on('resize',
            () => {
                this.rePosition();
            });

        this.rePosition();

        this.userTitle = payload.CurrentUser.Title;
        if (payload.CurrentUser.Staffs !== null && payload.CurrentUser.Staffs.length > 0)
            if (payload.CurrentUser.Staffs.length === 1)
                this.userStaff = payload.CurrentUser.Staffs[0].Title;
    },
    methods: {
        rePosition() {
            if (!this.hasBanner) {
                $(this.$el).css('top', '0');
                return;
            }

            if (window.pageYOffset > 149) {
                $(this.$el).css('top', '0');
            } else {
                if (window.pageYOffset < 149) {
                    $(this.$el).css('top', 149 - window.pageYOffset + 'px');
                }
                else
                    $(this.$el).css('top', '149px');
            }

        }
    },
    components: {
        CartableTree
    }
}
