import NewForm from '../forms/newform/newForm.vue';
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxPager, DxPaging, DxSearchPanel,
    DxToolbar, DxItem, DxExport
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxButton as DxButtonToolbar } from 'devextreme-vue/button';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

const url = 'api/Logs/GetLog';

const dataSource = createStore({
    key: 'Id',
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { "Content-type": "application/json" };
    }
});

export default {
    data() {
        return {
            dataGridRefKey: 'dataGrid',
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            url,
            dataSource
        }
    },
    methods: {
        refreshDataGrid() {
            this.dataGrid.refresh();
        },

        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(function () {
                workbook.xlsx.writeBuffer().then(function (buffer) { saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ActivityLog.xlsx'); });
            });
        }
    },

    updated() {
    },

    mounted() {
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        }
    },

    components: {
        NewForm,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging,
        DxToolbar,
        DxItem,
        DxButtonToolbar,
        DxSearchPanel,
        DxExport, Workbook, exportDataGrid
    }
}
