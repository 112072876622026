export default {
    data() {
        return {
            bannerItems: payload.bannerItems,
            bannerStyles: '',
            isLeftBanner: payload.isLeftBanner,
            isCenterBanner: payload.isCenterBanner,
            isRightBanner:payload.isRightBanner
        }
    },
    methods: {
        checkBannerItemEmpty(value) {
            return jQuery.isEmptyObject(value);
        }
    },
    mounted() {
        if (this.bannerItems.BackgroundImageUrl) {
            this.bannerStyles = 'url(api/file/getfile?imageId=' + this.bannerItems.BackgroundImageUrl + ')';
        }
    }
}
