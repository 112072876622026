<template>
    <modal v-bind:showModal="showModal" data-backdrop="false" v-bind:size="{'my-col':true,'col-lg-8':true}">
        <div slot="header">
            <label>{{modalHeader}}</label>
        </div>
        <div slot="body">
            <div class="row">
                <div class="col-sm-12">

                    <div class="form-group col-md-6 col-xs-12">
                        <label for="inpNewsPicture" class="btn btn-primary">{{$t("slider_managment.image")}} : {{fileName}}</label>
                        <input type="file" id="inpNewsPicture" @change="fileUploadChange" style="display:none" v-if="!inEdit">

                        <p class="help-block" v-if="!inEdit">{{$t("slider_managment.pic_type")}}</p>
                        <hr />
                        <label>{{$t("slider_managment.image_displaying")}}</label>
                        <select style=" width: 55px;margin-top:7px" v-model="level">
                            <option></option>
                            <option value="5">{{$t("slider_managment.empty")}}</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                    </div>

                    <div class="form-group col-md-6 col-xs-12" v-if="imageData!==''">
                        <img class="img-responsive" v-if="imageData!==''" :src="imageData">
                    </div>

                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div v-if="errors.length" class="form-validate-summary alert alert-danger">
                        <ul id="appform-validationsummary">
                            <li :style="{textAlign : (direction ? 'right' : 'left')}" v-for="e in errors">{{e}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <button class="btn btn-success" @click="submit()">
                        {{$t("save")}}
                    </button>
                    <button class="btn btn-default cancelModal"
                            data-dismiss="modal">
                        {{$t("cancel_nested")}}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from "../../widgets/modal/modal.vue";

    export default {
        props: [
            'showSliderModal',
            'modalData'
        ],
        data() {
            return {
                direction: payload.currentLocale.IsRightToLeft,
                modalHeader: '',
                errors: [],
                imageData: '',
                fileName: '',
                level: '',
                localData: [],
                inEdit: false,
                id: ''
            }
        },
        computed: {
            showModal() {
                this.init();


                this.modalHeader = this.modalData.isCreation ? i18n.$t("slider_managment.new") : i18n.$t("slider_managment.edit");

                if (!this.modalData.isCreation) {
                    this.inEdit = true;
                    this.id = this.modalData.id;
                    this.imageData = "api/file/getfile?outputType=inline&imageId=" + this.modalData.id;
                }

                this.loadData();
                return this.showSliderModal;
            }
        },
        methods: {
            init() {
                this.errors = [];
                this.imageData = '';
                this.fileName = '';
                this.level = '';
                this.localData = [];
                this.inEdit = false;
                this.id = '';
            },
            submit() {
                if (this.validation()) {
                    //console.log(this.base64MimeType(this.imageData));

                    var url = '';
                    var data = {};
                    var method = '';

                    if (this.inEdit) {
                        url = 'api/slider/edit';

                        data = {
                            level: this.level,
                            id: this.id
                        };

                        method = 'PUT';

                    } else {
                        url = 'api/slider/add';

                        data = {
                            mimeType: this.base64MimeType(this.imageData),
                            level: this.level,
                            imageData: this.imageData,
                            name: this.fileName
                        };

                        method = 'POST';
                    }

                    var callback = (result) => {
                        $('.cancelModal', this.$el).click();

                        var params = {
                            text: this.$t('messages.sucsses_message'),
                            type: 'information',
                            layout: 'center',
                            timeout: 3000
                        };
                        noty(params);

                        this.modalData.callback();
                    };

                    var that = this;

                    this.doAjaxRequest(url,
                        JSON.stringify(data),
                        callback,
                        function (data) {
                            for (var i = 0; i < data.length; i++) {
                                var item = data[i];
                                for (var j = 0; j < item.Errors.length; j++) {
                                    var errorItem = item.Errors[j];
                                    that.errors.push(i18n.$t(errorItem.ErrorMessage));
                                }
                            }
                        },
                        method);
                }
            },
            edit() {
            },
            validation() {
                this.errors = [];
                var result = true;

                if (!this.inEdit) {
                    if (this.localData.length > 3) {
                        this.errors.push(i18n.$t("slider_managment.upload_message"));
                        result = false;
                        return result;
                    }
                }

                if (this.level === '') {
                    this.errors.push(i18n.$t("slider_managment.level_required"));
                    result = false;
                }

                if (!this.inEdit) {

                    if (this.imageData === '') {
                        this.errors.push(i18n.$t("slider_managment.image_required"));
                        result = false;
                    } else {
                        var that = this;
                        this.localData.forEach(function (item) {
                            if (that.fileName === item.Name) {
                                that.errors.push(i18n.$t("slider_managment.same_name_massage"));
                                result = false;
                            }

                            if (that.level == item.Level) {
                                that.errors.push(i18n.$t("slider_managment.slider_show_is_repeat"));
                                result = false;
                            }
                        });
                    }
                } else {
                    var that = this;
                    this.localData.forEach(function (item) {
                        if (that.id !== item.Id) {
                            if (that.level == item.Level) {
                                that.errors.push(i18n.$t("slider_managment.slider_show_is_repeat"));
                                result = false;
                            }
                        }
                    });
                }

                return result;
            },
            fileUploadChange(e) {
                var that = this;
                var self = $("#inpNewsPicture");

                function reset() {
                    self.wrap('<form>').parent('form').trigger('reset');
                    self.unwrap();
                }

                var reader = new FileReader();
                var file = self[0].files[0];

                if (file.type !== "image/png" && file.type !== "image/gif" && file.type !== "image/jpeg") {
                    alert(i18n.$t("messages.file_type_attention"));
                    reset();
                    return;
                } else if (file.size > 81920) {
                    alert(i18n.$t("slider_managment.image_is_too_large"));
                    reset();
                    return;
                }

                reader.onloadend = function () {
                    that.imageData = reader.result;
                    that.fileName = file.name;
                    reset();
                }

                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            loadData() {
                var that = this;
                this.doAjaxRequest('api/slider/list',
                    {},
                    (result) => {
                        that.localData = result.Data;
                        if (that.inEdit) {
                            for (var i = 0; i < result.Data.length; i++) {
                                var item = result.Data[i];

                                if (that.id == item.Id) {
                                    that.fileName = item.Name;
                                    that.level = item.Level;
                                }
                            }
                        }
                    },
                    () => { },
                    'POST');

            },
            base64MimeType(encoded) {
                var result = null;

                if (typeof encoded !== 'string') {
                    return result;
                }

                var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

                if (mime && mime.length) {
                    result = mime[1];
                }

                return result;
            },
            doAjaxRequest(url, data, onSuccess, onFailure, method) {
                let ajaxConfig = {
                    url: url,
                    data: data,
                    dataType: "json",

                    contentType: "application/json;charset=utf-8"
                };
                if (method) {
                    ajaxConfig.method = method;
                }
                $.ajax(ajaxConfig)
                    .done((result) => {
                        if (typeof onSuccess === 'function')
                            onSuccess(result);
                    }).error((error) => {
                        if (typeof onFailure === 'function') {

                            var response = null;

                            if (error.status == 400) {
                                response = JSON.parse(error.responseText);
                                onFailure(response);
                            }
                        }
                    });
            }
        },
        mounted() {
            this.errors = [];
        },
        created() {
        },
        components: {
            Modal
        }
    }

</script>
