import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import { Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload } from '@ckeditor/ckeditor5-image';
//import Image from '@ckeditor/ckeditor5-image/src/image';
//import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
//import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
//import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
//import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
//import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
//import ImageBlock from '@ckeditor/ckeditor5-image/src/imageblock';
//import ImageInline from '@ckeditor/ckeditor5-image/src/imageinline';
//import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting.js';
//import ImageResizeButtons from '@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import { Table, TableToolbar, TableProperties, TableCellProperties } from '@ckeditor/ckeditor5-table';
//import Table from '@ckeditor/ckeditor5-table/src/table';
//import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
//import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
//import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import { BlockToolbar } from '@ckeditor/ckeditor5-ui';
import { HeadingButtonsUI } from '@ckeditor/ckeditor5-heading';
import { ParagraphButtonUI } from '@ckeditor/ckeditor5-paragraph';
import utils from 'jsf/utils.js';
import * as arTranslation from './controls/ckeditor/translations/ar.js';
import * as deTranslation from './controls/ckeditor/translations/de.js';
import * as frTranslation from './controls/ckeditor/translations/fr.js';
import * as heTranslation from './controls/ckeditor/translations/he.js';
import * as svTranslation from './controls/ckeditor/translations/sv.js';

export default function RichTextEditor(element, parent, controlState, control) {
    const started = new Date().getTime();
    if (localStorage.dbpDebugMode === 'true')
        console.log(`RichText '${controlState.Id}' init started`);
    DecoupledEditor.create(element[0],
        {
            plugins: [
                Essentials,
                Alignment,
                FontSize, FontFamily, FontColor, FontBackgroundColor,
                UploadAdapter,
                Autoformat,
                Bold, Italic, Strikethrough, Underline,
                BlockQuote,
                CKFinder,
                Heading,
                Image, ImageToolbar, ImageCaption, ImageStyle, ImageUpload, ImageResize,
                Indent, IndentBlock,
                Link,
                List,
                MediaEmbed,
                Paragraph,
                PasteFromOffice,
                Table, TableToolbar, TableProperties, TableCellProperties,
                TextTransformation,
                Base64UploadAdapter,
                BlockToolbar, ParagraphButtonUI, HeadingButtonsUI
            ],
            toolbar: [
                'heading',
                '|',
                'fontfamily',
                'fontsize',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                '|',
                'alignment',
                '|',
                'numberedList',
                'bulletedList',
                '|',
                'indent',
                'outdent',
                '|',
                'link',
                'blockquote',
                'imageUpload',
                'insertTable',
                'mediaEmbed',
                '|',
                'undo',
                'redo'
            ],
            blockToolbar: [
                'paragraph', 'heading1', 'heading2', 'heading3',
                '|',
                'bold', 'italic', 'link',
                '|',
                'bulletedList', 'numberedList',
                '|',
                'blockQuote', 'uploadImage'
            ],
            image: {
                toolbar: [
                    'imageStyle:inline', 'imageStyle:block', 'imageStyle:side',
                    '|',
                    'imageResize',
                    '|',
                    'imageTextAlternative'
                ],
                resizeUnit: 'px',
                /*styles: ['alignLeft', 'alignCenter', 'alignRight'],*/
                resizeOptions: [
                    {
                        name: 'imageResize:original',
                        value: null,
                        icon: 'original'
                    },
                    {
                        name: 'imageResize:50',
                        value: '50',
                        icon: 'medium'
                    },
                    {
                        name: 'imageResize:75',
                        value: '75',
                        icon: 'large'
                    }
                ],
                insert: {
                    type: 'auto'
                }
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    'tableProperties', 'tableCellProperties'
                ]
            },
            link: {
                decorators: {
                    addTargetToExternalLinks: {
                        mode: 'automatic',
                        callback: url => /^(https?:)?\/\//.test(url),
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                }
            },
            language: payload.currentLocale.Language
        })
        .then(editor => {
            control.editor = editor;
            const toolbarContainer = parent.find(`#${controlState.Id}-toolbar-container`);
            toolbarContainer[0].appendChild(editor.ui.view.toolbar.element);
            editor.model.document.on('change:data',
                () => {
                    if (controlState)
                        control._trigger('rayControlChanged', {}, controlState);
                });

            // fix weird issue with missed space
            editor.keystrokes.set('space',
                (data, stop) => {
                    const insertPosition = editor.model.document.selection.getFirstPosition();
                    editor.model.change(writer => {
                        writer.insertText(' ', insertPosition);
                    });
                });
            window.editor = editor;
            if (localStorage.dbpDebugMode === 'true') {
                const elapsed = new Date().getTime() - started;
                console.log(`RichText '${controlState.Id}' ready: ${elapsed}ms`);
            }
            //Resize rich text box after toolbar init
            setTimeout(function () {
                var heightCss = utils.resizeRichTextBox(parent, true);
                editor.editing.view.change(writer => {
                    writer.setStyle('height', heightCss, editor.editing.view.document.getRoot());
                });
            }, 300);
        })
        .catch(error => {
            console.error(error);
        });
}
