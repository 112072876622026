var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        showModal: _vm.showModal,
        "data-backdrop": "false",
        size: { "my-col": true, "col-lg-8": true },
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.modalHeader))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group col-md-6 col-xs-12" }, [
              _c(
                "label",
                {
                  staticClass: "btn btn-primary",
                  attrs: { for: "inpNewsPicture" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("slider_managment.image")) +
                      " : " +
                      _vm._s(_vm.fileName)
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.inEdit
                ? _c("input", {
                    staticStyle: { display: "none" },
                    attrs: { type: "file", id: "inpNewsPicture" },
                    on: { change: _vm.fileUploadChange },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.inEdit
                ? _c("p", { staticClass: "help-block" }, [
                    _vm._v(_vm._s(_vm.$t("slider_managment.pic_type"))),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("label", [
                _vm._v(_vm._s(_vm.$t("slider_managment.image_displaying"))),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.level,
                      expression: "level",
                    },
                  ],
                  staticStyle: { width: "55px", "margin-top": "7px" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.level = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option"),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "5" } }, [
                    _vm._v(_vm._s(_vm.$t("slider_managment.empty"))),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [_vm._v("2")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "3" } }, [_vm._v("3")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "4" } }, [_vm._v("4")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.imageData !== ""
              ? _c("div", { staticClass: "form-group col-md-6 col-xs-12" }, [
                  _vm.imageData !== ""
                    ? _c("img", {
                        staticClass: "img-responsive",
                        attrs: { src: _vm.imageData },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8" }, [
            _vm.errors.length
              ? _c(
                  "div",
                  { staticClass: "form-validate-summary alert alert-danger" },
                  [
                    _c(
                      "ul",
                      { attrs: { id: "appform-validationsummary" } },
                      _vm._l(_vm.errors, function (e) {
                        return _c(
                          "li",
                          {
                            style: {
                              textAlign: _vm.direction ? "right" : "left",
                            },
                          },
                          [_vm._v(_vm._s(e))]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("save")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default cancelModal",
                attrs: { "data-dismiss": "modal" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("cancel_nested")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }