var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        showModal: _vm.showModal,
        "data-backdrop": "false",
        size: { "my-col": true, "col-lg-6": true },
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("assign")))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group", attrs: { id: "ca-management" } },
              [
                _c("label", [_vm._v(_vm._s(_vm.$t("task_assign.recipients")))]),
                _vm._v(" "),
                _c("div", {
                  staticStyle: { width: "300px", height: "100px" },
                  attrs: { id: "pacPrincipals" },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkDesc,
                      expression: "chkDesc",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.chkDesc)
                      ? _vm._i(_vm.chkDesc, null) > -1
                      : _vm.chkDesc,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.chkDesc,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.chkDesc = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.chkDesc = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.chkDesc = $$c
                        }
                      },
                      _vm.changeDesc,
                    ],
                  },
                }),
                _vm._v(
                  _vm._s(_vm.$t("task_assign.last_dsc")) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkDeleteTask,
                      expression: "chkDeleteTask",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.chkDeleteTask)
                      ? _vm._i(_vm.chkDeleteTask, null) > -1
                      : _vm.chkDeleteTask,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.chkDeleteTask,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.chkDeleteTask = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.chkDeleteTask = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.chkDeleteTask = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  _vm._s(_vm.$t("task_assign.out_work")) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.chkKeepSender,
                      expression: "chkKeepSender",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.chkKeepSender)
                      ? _vm._i(_vm.chkKeepSender, null) > -1
                      : _vm.chkKeepSender,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.chkKeepSender,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.chkKeepSender = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.chkKeepSender = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.chkKeepSender = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  _vm._s(_vm.$t("task_assign.keep_sender")) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v(_vm._s(_vm.$t("task_assign.description")))]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.txtDesc,
                    expression: "txtDesc",
                  },
                ],
                staticClass: "form-control",
                attrs: { cols: "20", rows: "2", disabled: _vm.chkDesc },
                domProps: { value: _vm.txtDesc },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.txtDesc = $event.target.value
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-8" }, [
            _vm.errors.length
              ? _c(
                  "div",
                  { staticClass: "form-validate-summary alert alert-danger" },
                  [
                    _c(
                      "ul",
                      { attrs: { id: "appform-validationsummary" } },
                      _vm._l(_vm.errors, function (e) {
                        return _c(
                          "li",
                          {
                            style: {
                              textAlign: _vm.direction ? "right" : "left",
                            },
                          },
                          [_vm._v(_vm._s(e))]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4 col-sm-offset-4" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-block",
              attrs: { type: "button" },
              on: { click: _vm.save },
            },
            [_vm._v(_vm._s(_vm.$t("send")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }