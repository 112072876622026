import utils from 'jsf/utils.js';

export function appForm() {
    const self = this;
    //const appFormId = '#appform';
    //const titleId = "#appform-title";
    //const contentId = "#appform-content";
    //let buttonsId = "#appform-buttons";
    //let lblTaskFormTitleId = '#lblTaskFormTitle';
    //let lblSecurityTitleId = '#lblSecurityTitle';
    //let lblTaskSubjectId = '#lblTaskSubject';
    //let taskTitleDiv = '#taskTitleDiv';
    const validationSummaryId = "#appform-validationsummary";
    //_currentBpmsAppForm = null,
    let offset = null;
    const activate = function(params) {

        // if bpms opens in eoffice as a plugin , hide header and tree 
        utils.hideHeaderAndTreeFromShell();

        config.currentViewCanLeave(canLeave);
        //$('.wrapper-padding').hide();
        //$('#contentmain').show();
        const appForm = $('.bpms-appform').empty();
        $('<div></div>').appendTo(appForm).bpmsAppForm(params);
        appForm.show();
        offset = $(".view:visible").offset();

        const floatingFunc = function() {
            const innerFunc = function(formElem, titleElem) {

                let floatingClass = 'floating';

                const topNavbar = $('#nav-bar-top');
                if ($(topNavbar).is(':visible') === false || $(topNavbar).css('position') !== 'fixed')
                    floatingClass = 'floating-top';

                let top = 0;
                if (offset !== null && typeof offset !== "undefined")
                    top = offset.top;

                if (typeof $(titleElem).offset !== "undefined" &&
                    typeof $(titleElem).offset() != "undefined" &&
                    $(window).scrollTop() > $(titleElem).offset().top) {

                    const appFormWidth = $(formElem).width();
                    $(titleElem).addClass(floatingClass);
                    $(titleElem).width(appFormWidth);
                    $(titleElem).css('max-width', `${appFormWidth}px`);

                } else if ($(window).scrollTop() <= top || $(window).scrollTop() === 0) {
                    $(titleElem).removeClass(floatingClass);
                    $(titleElem).width('');
                    $(titleElem).css('max-width', '');
                }
            };

            //let appTitles = $('.titlebar:first');
            //$(appTitles).each(function (index, item) {
            //    let form = $(item).parent();
            //    innerFunc(form, item);
            //});
            const item = $('.titlebar:first');
            const form = $(item).parent();
            innerFunc(form, item);

        };

        function inIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        }

        const isPlugin = inIframe();
        if (!isPlugin) {
            $(window).scroll(function() {
                floatingFunc();
            });

            $(window).resize(function() {
                floatingFunc();
            });
        }

    };

    const currentBpmsAppForm = function (form) {
        const global = (0, eval)("this");
        //console.log("currentBpmsAppForm", form, global._currentBpmsAppForm);
        if (form) {
            global._currentBpmsAppForm = form;
            return undefined;
        } else
            return global._currentBpmsAppForm;
    };

    const canLeave = function() {
        // TODO: check form dirty and then confirm to exit
        const result = true;
        if (result)
            destroyAllNestedForms(currentBpmsAppForm());

        return result;
    };

    const destroyAllNestedForms = function(bpmAppForm) {
        if (!bpmAppForm)
            return;

        bpmAppForm.closeAllNestedForm();
    };

    return {
        pageInstanceId: function () {
            let mostTopDialog = null;
            let top_z_index = -1;
            $(".modal.fade.in").each(function (index, item) {
                const z_index = $(item).css("z-index");
                if (z_index > top_z_index) {
                    top_z_index = z_index;
                    mostTopDialog = item;
                }
            });

            let pageInstanceId;
            if (mostTopDialog != null && typeof mostTopDialog != "undefined") {
                const form = $(mostTopDialog).find("[pageinstanceid]");
                pageInstanceId = form.attr("pageInstanceId");
            } else
                pageInstanceId = $("[pageinstanceid]").attr("pageInstanceId");

            if (pageInstanceId == null || typeof pageInstanceId === "undefined") {
                const cf = self.currentBpmsAppForm();
                if (cf != null)
                    pageInstanceId = cf.dto.formState.PageInstanceId;
            }

            return pageInstanceId;

        },
        activate: activate,
        validationSummaryId: validationSummaryId,
        currentBpmsAppForm: currentBpmsAppForm
    };
};
