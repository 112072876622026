﻿export default {
    name: 'navbarItem',
    props: [
        'item'
    ],
    methods: {
        checkChild(item) {
            return (item.ChildItems.length > 0);
        }
    }
}