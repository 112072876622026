import utils from 'jsf/utils.js';

const DevExpress = require("devextreme/bundles/modules/core");
require("devextreme/integration/jquery");
import DateBox from "devextreme/ui/date_box";
import { DxButton } from 'devextreme-vue';
import dateFormat from "dateformat";

export function timesheet() {
    let obj = {};
    let startTimeTag = '';
    let endTimeTag = '';
    let RemainderTag = '';
    let descriptionTag = '';
    let taskSubjectTag = '';
    let TSubjectTag = '';
    let dateTag;
    let editDate = new Date();
    let saveTag = '';
    let saveTextTag = '';
    let timeSheetGrid;

    const taskTimeSheetEmptyId = '1A81F40F-187E-4844-A4C3-BAF2BD7C40D1';
    let taskInstanceId = ''; //set in activate method
    let taskCompleted = ''; //set in activate method
    //const currentDate = new Date();
    //const MustUpdateRemainder = false;

    let date = new Date();
    let Remainder = 0;
    let withoutTaskInstance = '';
    const rootEl = $('<div></div>');
    let rootItemEl;
    const timeSheetItemModalWrapper = $('<div>');

    let weekly;
    let validationSummary;
    let modalDialog;
    let editDateBox;

    const initializeVariables = function () {
        rootItemEl = timeSheetItemModalWrapper.children().clone();
        taskInstanceId = obj.id;
        taskCompleted = obj.cmp;
        withoutTaskInstance = !taskInstanceId;
        dateTag = $('#dpTimesheet', rootItemEl);
        startTimeTag = $('#startTime', rootItemEl);
        endTimeTag = $('#endTime', rootItemEl);
        RemainderTag = $('#Remainder', rootItemEl);
        descriptionTag = $('#txtdesc', rootItemEl);
        taskSubjectTag = $('#lblTaskSubject', rootItemEl);
        TSubjectTag = $('#lblTSubject', rootItemEl);
        saveTag = $('#btnOk', rootItemEl);
        saveTextTag = $('#btnOk > span', rootItemEl);
        weekly = $('#TaskTimeSheet_Weekly', rootItemEl);
        validationSummary = $('span#lblValidationSummary', rootItemEl);
        timeSheetGrid = $('#jqGrid_TaskTimeSheet', rootEl);
    };

    const endTimeBiggerThanStartTime = function () {
        return startTimeTag.val() < endTimeTag.val();
    };

    //const validateDate = function (datestr) {
    //    if (datestr.length === 10)
    //        if ($.calendars.instance('')
    //            .isValid(datestr.substring(0, 4), datestr.substring(5, 7), datestr.substring(8, 10)))
    //            return true;
    //    return false;
    //};

    const validate = function () {
        let isValid = true;
        let msg = '';
        const $span = $('#lblValidationSummaryDlg');

        if (!endTimeBiggerThanStartTime()) {
            isValid = false;
            msg = `${msg + i18n.$t("timesheet_register.messages.time_reng")}<br />`;
        }

        //if (!validateDate(editDate)) {
        //    isValid = false;
        //    msg = msg + i18n.$t("timesheet_register.messages.date_not_valid") + '<br />';
        //}

        if (isValid)
            $span.hide();
        else {
            $span.html(msg);
            $span.show(1000);
        }

        return isValid;
    };

    const closeDialog = function () {
        if (modalDialog != null && typeof modalDialog !== "undefined") {
            modalDialog.rayModal('close');
            modalDialog = null;
        }
    };

    const saveTimeSheet = function (taskTimeId, startTime, endTime, remainder, desc, date) {
        const param = {
            id: taskTimeId,
            startTime: startTime,
            endTime: endTime,
            remainder: remainder,
            desc: desc,
            taskInstanceId: taskInstanceId,
            date: date
        };

        utils.callWebAPIp("api/timesheet/update", param, 'POST')
            .done(function () {
                timeSheetGrid.trigger("reloadGrid");
                $("#jqGrid_TaskInstance").trigger("reloadGrid");
                closeDialog();
            })
            .fail(function (jqXHR) {
                const jsonValue = $.parseJSON(jqXHR.responseText);
                utils.showGenericModal(i18n.$t("timesheet_register.errors.error_in_save"), jsonValue.Message);
            });
    };

    const computeRemainderTime = function (s, e, r) {
        const sta = parseInt(s.substr(0, 2), 10) * 60 + parseInt(s.substr(3, 2), 10);
        const en = parseInt(e.substr(0, 2), 10) * 60 + parseInt(e.substr(3, 2), 10);
        return Remainder - (en - sta);
    };

    const updateRemainderTag = function () {
        let rem = computeRemainderTime(startTimeTag.val(), endTimeTag.val(), Remainder);
        if (rem < 0)
            rem = 0;
        let m = rem % 60;
        let h = (rem - m) / 60;
        if (m < 10)
            m = `0${m}`;
        if (h < 10)
            h = `0${h}`;
        RemainderTag.val(`${h}:${m}`);
    };

    const setTaskInstanceSubject = function (tinsId) {
        utils.callWebAPIp("api/task/getsubject", { taskInstanceId: tinsId }, 'GET')
            .done(function (result) {
                let subject;
                if (result.d)
                    subject = result.d;
                else
                    utils.callWebAPIp("api/task/getsubjectcompleted", { taskInstanceCompletedId: tinsId }, 'GET')
                        .done(function(result2) {
                            subject = result2.d ? result2.d : null;
                        })
                        .fail(function(jqXHR) {
                            const jsonValue = $.parseJSON(jqXHR.responseText);
                            utils.showGenericModal(i18n.$t("timesheet_register.errors.error"), jsonValue.Message);
                        });

                taskSubjectTag.text(subject);
                descriptionTag.val(subject);
            }).
            fail(function (jqXHR) {
                const jsonValue = $.parseJSON(jqXHR.responseText);
                utils.showGenericModal(i18n.$t("timesheet_register.errors.error"), jsonValue.Message);
            });
    };

    const initialize = function () {
        dateTag.click(function (e) {
            e.stopPropagation();
        });

        editDateBox = new DateBox(dateTag, {
            value: editDate,
            type: "date",
            pickerType: "list",
            showClearButton: true,
            showAnalogClock: false,
            rtlEnabled: payload.currentLocale.IsRightToLeft,
            dropDownOptions: {
                toolbarItems: [
                    {
                        widget: "dxButton",
                        toolbar: "bottom",
                        location: "before",
                        options: {
                            text: i18n.$t("today"),
                            onClick: function () {
                                dateBox.option("value", new Date());
                                dateBox.close();
                            }
                        }
                    }
                ]
            },
            onValueChanged: function (data) {
                if (data.value == null)
                    editDate = new Date();
                else
                    editDate = new Date(data.value);
            }
        });

        //dateTag.mask("9999/99/99");
        startTimeTag.timeEntry({ show24Hours: 'true' });
        endTimeTag.timeEntry({ show24Hours: 'true' });
        RemainderTag.mask("99:99");
        saveTag.click(function () {
            if (validate()) {
                saveTimeSheet($('#TaskTimeSheetId').val(),
                    startTimeTag.val(),
                    endTimeTag.val(),
                    RemainderTag.val(),
                    descriptionTag.val(),
                    editDate);
            }
        });
        if (taskCompleted) {
            weekly.parent().parent().hide();
        }
        if (withoutTaskInstance) {
            $("#TaskTimeSheet_TaskInstanceId").hide();
            TSubjectTag.parent().parent().css('display', 'none');
        } else {
            const onChangedFn = function () {
                $(this).data('changed', 'true');
            };
            const onBlurFn = function () {
                if ($(this).data('changed')) {
                    $(this).data('changed', null);
                    if (!endTimeBiggerThanStartTime()) {
                        validationSummary.text(i18n.$t("timesheet_register.messages.time_reng"));
                        validationSummary.show(1000);
                    } else
                        validationSummary.hide(1000);
                    updateRemainderTag();
                }
            };
            startTimeTag.change(onChangedFn);
            endTimeTag.change(onChangedFn);
            startTimeTag.blur(onBlurFn);
            endTimeTag.blur(onBlurFn);
            saveTextTag.text(i18n.$t("timesheet_register.current_work"));
            setTaskInstanceSubject(taskInstanceId);
        }
    };

    const handlePageResize = function () {
        //timeSheetGrid.setGridWidth($('#TimeSheet').width() - 10, true);
        timeSheetGrid.setGridWidth(650, true);
    };

    const dateToString = function (date) {
        //return date._year + "/" + (date._month < 10 ? ("0" + date._month) : date._month) +
        //    "/" + (date._day < 10 ? ("0" + date._day) : date._day);
        return dateFormat(date, 'yyyy/mm/dd');
    };

    const setTaskTimeInstanceControlsModal = function (ttsId, startTime, endTime, remainder, desc, date) {
        $('#TaskTimeSheetId').val(ttsId);
        startTimeTag.val(startTime);
        endTimeTag.val(endTime);
        RemainderTag.val(remainder);
        editDate = new Date(date);
        editDateBox = new DateBox(dateTag, { value: editDate });
        if (desc === i18n.$t("empty") && ttsId.toUpperCase() === taskTimeSheetEmptyId)
            if (!taskInstanceId)
                descriptionTag.val('');
            else
                setTaskInstanceSubject(taskInstanceId);
        else
            descriptionTag.val(desc);
    };

    const updateRemainderTime = function (tasktimesheetId) {
        utils.callWebAPIp("api/timesheet/getremainingtime", { taskInstanceId: taskInstanceId, tasktimesheetId: tasktimesheetId }, 'GET')
            .done(function (result) {
                Remainder = result.d;
                updateRemainderTag();
            }).fail(function (msg) {
                utils.showGenericModal(i18n.$t("timesheet_register.errors.error_in_remaining_time"), msg.responseText);
            });
    };

    const showTimeSheetItemModal = function (data) {
        const template =
            `<div class="col-sm-12">
    <div class="row">
        <div class="col-sm-12">
            <h5 class="lblSubject"></h5>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtDate">${i18n.$t("date")}:</label>
                <div id="txtDate"></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtBeginTime">${i18n.$t("timesheet_register.start_time")}:</label>
                <input type="text" class="form-control" style="text-align:left;direction:ltr" id="txtBeginTime">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtEndTime">${i18n.$t("timesheet_register.end_time")}:</label>
                <input type="text" class="form-control" style="text-align:left;direction:ltr" id="txtEndTime">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label for="txtRemainingTime">${i18n.$t("timesheet_register.remaining_time")}:</label>
                <input type="text" class="form-control" style="text-align:left;direction:ltr" id="txtRemainingTime">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="txtDescription">${i18n.$t("timesheet_register.description")}:</label>
                <textarea class="form-control" rows="4" id="txtDescription"></textarea>
            </div>
        </div>
    </div>
    <div class="row">
        <span id="lblValidationSummaryDlg" style="color:Red;"></span>
    </div>
    <div class="col-sm-4 col-sm-offset-4">
        <button id="btnOk" class="btn btn-primary btn-block">${i18n.$t("save")}</button>
    </div>
</div>`;

        const el = $(template);
        $(document.body).append(el);

        taskSubjectTag = $('.lblSubject:first', el);
        dateTag = $('#txtDate', el);
        startTimeTag = $('#txtBeginTime', el);
        endTimeTag = $('#txtEndTime', el);
        RemainderTag = $('#txtRemainingTime', el);
        descriptionTag = $('#txtDescription', el);
        saveTag = $('#btnOk', el);

        initialize();

        modalDialog = el;

        el.rayModal({
            title: i18n.$t("timesheet_register.timesheet"),
            width: "col-sm-6"
        });

        setTaskTimeInstanceControlsModal(data.Id,
            data.startTime,
            data.endTime,
            data.remainder,
            data.description,
            data.formattedDate);
        if (!withoutTaskInstance) // && data.Id.toUpperCase() == taskTimeSheetEmptyId)
            updateRemainderTime(data.Id);
        //$('#TimeSheetItemModal').dialog('option', { zIndex: '5' }).dialog('open');
        //rootItemEl = timeSheetItemModalWrapper.children().clone();
        //rootItemEl.rayModal();
    };

    const showTimeSheetModalIfIsEditable = function (data) {
        const param = { timeSheetId: data.Id, taskInstanceId: taskInstanceId };
        utils.callWebAPIp("api/timesheet/iseditable", param, 'GET')
            .done(function (result) {
                if (result.d === 'OtherTask')
                    utils.modalConfirmation(i18n.$t("timesheet_register.messages.save_time_confirm"),
                        function (r) { if (r) showTimeSheetItemModal(data); });
                else if (result.d === 'CompletedTask')
                    utils.modalDialog(i18n.$t("warning"), i18n.$t("timesheet_register.messages.can_not_edit"), 400, 130);
                else
                    showTimeSheetItemModal(data, taskInstanceId);
            });
    };

    const parseDate = function (date) {
        return new Date(date);
    };

    const deleteTimeSheet = function (taskTimeId) {
        if (taskTimeId.toUpperCase() === taskTimeSheetEmptyId)
            return;

        utils.modalConfirmation(i18n.$t("timesheet_register.messages.delete_timesheet_confirm"),
            function (confirm) {
                if (confirm) {
                    utils.callWebAPIp(`api/timesheet/delete/${taskTimeId}`, null, "POST")
                        .done(function () {
                            timeSheetGrid.trigger("reloadGrid");
                            $("#jqGrid_TaskInstance").trigger("reloadGrid");
                        }).fail(function (jqXHR) {
                            const jsonValue = $.parseJSON(jqXHR.responseText);
                            utils.showGenericModal(i18n.$t("timesheet_register.errors.error_in_delete"), jsonValue.Message);
                            timeSheetGrid.trigger("reloadGrid");
                            $("#jqGrid_TaskInstance").trigger("reloadGrid");
                        });
                }
            });
    };

    const initializeGrids = function () {
        $(window).bind('resize', function () { handlePageResize(); });
        timeSheetGrid.jqGrid({
            url: 'api/timesheet/getweek',
            datatype: "json",
            pager: "#timesheetpager",
            mtype: "post",
            toolbar: ['true', 'top'],
            ajaxGridOptions: { contentType: 'application/json; charset=utf-8' },
            serializeGridData: function (postData) {
                if (postData.searchField === 'undefined') postData.searchField = null;
                if (postData.searchString === 'undefined') postData.searchString = null;
                if (postData.searchOper === 'undefined') postData.searchOper = null;
                if (postData.filters === 'undefined') postData.filters = null;
                postData.dateStr = dateToString(date);
                return JSON.stringify(postData);
            },
            colNames: [
                '', '', '', /*i18n.$t("timesheet_register.persian_date"), */i18n.$t("timesheet_register.date"),
                i18n.$t("timesheet_register.start_time"), i18n.$t("timesheet_register.end_time"),
                i18n.$t("timesheet_register.remaining_time"), i18n.$t("timesheet_register.description"), '', ''
            ],
            colModel: [
                { name: 'Id', index: 'Id', jsonmap: 'Id', hidden: true, sortable: false },
                { name: 'empty', index: 'empty', width: 150, sortable: false },
                { name: 'date', index: 'date', jsonmap: 'Date', hidden: true, sortable: false },
                //{
                //    name: 'persiandate1',
                //    index: 'persiandate1',
                //    width: 120,
                //    jsonmap: 'PersianDateTime1',
                //    hidden: true,
                //    formatter: 'string',
                //    sortable: false
                //},
                {
                    name: 'datestr',
                    index: 'datestr',
                    width: 120,
                    jsonmap: 'LocalDateTime',
                    formatter: 'string',
                    sortable: false
                },
                {
                    name: 'startTime',
                    index: 'startTime',
                    width: 100,
                    jsonmap: 'StartTime',
                    sortable: false
                },
                {
                    name: 'endTime',
                    index: 'endTime',
                    width: 100,
                    jsonmap: 'EndTime',
                    sortable: false
                },
                {
                    name: 'remainder',
                    index: 'remainder',
                    width: 100,
                    jsonmap: 'Remainder',
                    hidden: withoutTaskInstance,
                    formatter: function (cellValue, options, rowObject) {
                        return cellValue === '00:00' ? '' : cellValue;
                    },
                    sortable: false
                },
                {
                    name: 'description',
                    index: 'description',
                    width: 180,
                    jsonmap: 'Description',
                    sortable: false
                },
                {
                    name: 'isonvacation',
                    index: 'isonvacation',
                    hidden: true,
                    jsonmap: 'IsOnVacation',
                    sortable: false
                },
                {
                    name: 'formattedDate',
                    index: 'FormattedDate',
                    width: 60,
                    jsonmap: 'FormattedDate',
                    sortable: false,
                    hidden: true
                }
            ],
            jsonReader: {
                root: function (obj) { return obj.d; },
                repeatitems: false
            },
            grouping: true,
            groupingView: {
                groupField: ['datestr'],
                groupColumnShow: [false],
                groupDataSorted: true
            },
            multiselect: false,
            direction: payload.currentLocale.IsRightToLeft ? "rtl" : "ltr",
            /*jqGrid_TaskTimeSheet Scroll_Paging S*/
            loadtext: `${i18n.$t("uploading")} ... `,
            pgbuttons: false,
            pginput: false,
            autoWidth: true,
            height: "100%",
            rowNum: -1,
            caption: i18n.$t("timesheet_register.weekly_plan"),
            viewrecords: false,
            loadComplete: function (data) {
                const $this = $(this);
                handlePageResize();
                $(`tr.jqgrow > td[title=${taskTimeSheetEmptyId.toLowerCase()}]`).parent().each(function () {
                    const id = $(this).attr('id');
                    $this.setCell(id, 'description', '', { 'color': 'gray' });
                    $this.setCell(id, 'startTime', '', { 'color': 'gray' });
                    $this.setCell(id, 'endTime', '', { 'color': 'gray' });
                });
                $('tr.jqgroup').each(function () {
                    if ($(this).next().find('td[title=true]').length > 0)
                        $(this).children().css('color', 'red');
                });
            },
            ondblClickRow: function (rowid) {
                const $this = $(this);
                const data = $this.getRowData(rowid);
                showTimeSheetModalIfIsEditable(data);
            }
        })
            .navGrid('#timesheetpager',
                { add: false, edit: false, del: false, refresh: false, update: false, search: false, position: "left" })
            .navButtonAdd('#timesheetpager',
                {
                    caption: i18n.$t("timesheet_register.prev_week"),
                    buttonicon: "ui-icon-carat-1-w",
                    onClickButton: function () {
                        const $this = $(this);
                        const dateStr = $this.getRowData(1).formattedDate;
                        date = parseDate(dateStr);
                        date.setDate(date.getDate() - 7);
                        $this.trigger("reloadGrid");
                    }
                })
            .navButtonAdd('#timesheetpager',
                {
                    caption: i18n.$t("timesheet_register.next_week"),
                    buttonicon: "ui-icon-carat-1-e",
                    onClickButton: function () {
                        const $this = $(this);
                        const dateStr = $this.getRowData(1).formattedDate;
                        date = parseDate(dateStr);
                        date.setDate(date.getDate() + 7);
                        $this.trigger("reloadGrid");
                    }
                })
            .navButtonAdd('#timesheetpager',
                {
                    caption: i18n.$t("timesheet_register.delete"),
                    buttonicon: "ui-icon-trash",
                    onClickButton: function (id) {
                        const $this = $(this);
                        const row = $this.getGridParam("selrow");
                        if (row != null) {
                            const data = $this.getRowData(row);
                            deleteTimeSheet(data.Id);
                        } else
                            utils.ModalDialog(i18n.$t("warning"), i18n.$t("timesheet_register.messages.select_row"), 200, 130);
                    }
                }).navButtonAdd('#timesheetpager',
                    {
                        caption: i18n.$t("timesheet_register.plus"),
                        buttonicon: "ui-icon-plus",
                        onClickButton: function (id) {
                            const $this = $(this);
                            let data = $this.getRowData(1);
                            let row = $this.getGridParam("selrow");
                            if (!row)
                                row = 1;
                            const rowCount = $this.getGridParam("reccount");
                            for (let i = row; i <= rowCount; i++) {
                                data = $this.getRowData(i);
                                if (data.Id.toLowerCase() === taskTimeSheetEmptyId.toLowerCase())
                                    break;
                            }
                            showTimeSheetModalIfIsEditable(data);
                        }
                    });

        const searchBox = $(`<div class='col-sm-12 col-lg-6'>
    <div class='input-group'>
        <div id='searchdatepicker'></div>
        <span class='input-group-btn'>
            <button id='searchTaskTimeSheet' class='btn btn-default' type='button'><i class="fad fa-search"></i></button>
        </span>
    </div>
</div>`);

        const tableHeader = $('#t_jqGrid_TaskTimeSheet').empty().append(searchBox).css('width', 'auto');

        $('#searchTaskTimeSheet').click(function () {
            //const value = $('#searchdatepicker').val();
            //date = parseDate(value);
            timeSheetGrid.trigger("reloadGrid");
        });

        $('#searchdatepicker').click(function (e) {
            e.stopPropagation();
        });

        const dateBox = new DateBox($('#searchdatepicker'),
            {
                type: "date",
                pickerType: "list",
                showClearButton: true,
                showAnalogClock: false,
                rtlEnabled: payload.currentLocale.IsRightToLeft,
                dropDownOptions: {
                    toolbarItems: [
                        {
                            widget: "dxButton",
                            toolbar: "bottom",
                            location: "before",
                            options: {
                                text: i18n.$t("today"),
                                onClick: function (x) {
                                    dateBox.option("value", new Date());
                                    dateBox.close();
                                }
                            }
                        }
                    ]
                },
                onValueChanged: function (data) {
                    if (data.value == null)
                        date = new Date();
                    else
                        date = new Date(data.value);
                }
            });

        if (withoutTaskInstance == false) {
            $(window).bind('resize', function () { handlePageResize(); });
            jQuery("#jqGrid_TaskInstance").jqGrid({
                url: 'api/timesheet/getbytask',
                datatype: "json",
                pager: "#timesheetpager2",
                mtype: "post",

                ajaxGridOptions: { contentType: 'application/json; charset=utf-8' },
                serializeGridData: function (postData) {
                    if (postData.searchField == 'undefined') postData.searchField = null;
                    if (postData.searchString == 'undefined') postData.searchString = null;
                    if (postData.searchOper == 'undefined') postData.searchOper = null;
                    if (postData.filters == 'undefined') postData.filters = null;
                    postData.taskInstanceId = taskInstanceId;
                    return JSON.stringify(postData);
                },
                colNames: [
                    '', i18n.$t("timesheet_register.date"), i18n.$t("timesheet_register.start_time"),
                    i18n.$t("timesheet_register.end_time"), i18n.$t("timesheet_register.remaining_time"),
                    i18n.$t("timesheet_register.description")
                ],
                colModel: [
                    { name: 'Id', index: 'Id', width: 180, jsonmap: 'Id', hidden: true, sortable: false },
                    {
                        name: 'datestr',
                        index: 'datestr',
                        width: 150,
                        jsonmap: 'LocalDateTime',
                        formatter: 'string',
                        sortable: false
                    },
                    {
                        name: 'startTime',
                        index: 'startTime',
                        width: 100,
                        jsonmap: 'StartTime',
                        sortable: false
                    },
                    {
                        name: 'endTime',
                        index: 'endTime',
                        width: 100,
                        jsonmap: 'EndTime',
                        sortable: false
                    },
                    {
                        name: 'remainder',
                        index: 'remainder',
                        width: 100,
                        jsonmap: 'Remainder',
                        formatter: function (cellValue, options, rowObject) {
                            return cellValue === '00:00' ? '' : cellValue;
                        },
                        sortable: false
                    },
                    {
                        name: 'description',
                        index: 'description',
                        width: 180,
                        jsonmap: 'Description',
                        sortable: false
                    }
                ],
                jsonReader: {
                    root: function (obj) { return obj.d; },
                    repeatitems: false
                },
                multiselect: false,
                direction: payload.currentLocale.IsRightToLeft ? "rtl" : "ltr",
                loadtext: `${i18n.$t("uploading")} ... `,
                pgbuttons: false,
                pginput: false,
                autoWidth: true,
                height: "100%",
                rowNum: -1,
                caption: i18n.$t("timesheet_register.all_work"),
                viewrecords: false,
                loadComplete: function (data) {
                    handlePageResize();
                }
            });
        }
    };

    const createTimeSheetItemModalDialog = function () {
        utils.applyUiButtons($('#TimeSheetItemModal'));
        rootItemEl.rayModal({ preventOpen: true });
        //rootItemEl.dialog({
        //    bgiframe: true,
        //    autoOpen: false,
        //    width: 500,
        //    resizable: false,
        //    title: '',
        //    modal: true,
        //});
    };

    const activate = function (_obj, params) {
        utils.hideHeaderAndTreeFromShell();
        config.currentViewCanLeave(this.canLeave);
        obj = _obj;
        utils.showModalHtml('Views/TimeSheet.html').done(function (html) {
            const strs = {
                "timesheet_register.work_dsc": i18n.$t("timesheet_register.work_dsc"),
                "timesheet_register.date": i18n.$t("timesheet_register.date"),
                "timesheet_register.start_time": i18n.$t("timesheet_register.start_time"),
                "timesheet_register.end_time": i18n.$t("timesheet_register.end_time"),
                "timesheet_register.remaining_time": i18n.$t("timesheet_register.remaining_time"),
                "timesheet_register.description": i18n.$t("timesheet_register.description"),
                "save": i18n.$t("save")
            };

            for (let key in strs)
                html = html.replace(`{{$t("${key}")}}`, strs[key]);

            function fn() {
                initializeVariables();
                initialize();
                initializeGrids();
                createTimeSheetItemModalDialog();
            }

            if (utils.getParameterByName('co') === 't') {
                const $timesheetForm = params.element;
                $timesheetForm.append(html).css({ width: '100%', height: '100%' });
                // new legacyComponent({ i18n: vueI18n }).$mount($timesheetForm[0]);
                $('.view').hide();
                $timesheetForm.show();
                fn();
            } else {
                rootEl.empty().append(html);
                timeSheetItemModalWrapper.append(rootEl.find('#TimeSheetItemModal'));
                rootEl.rayModal({
                    title: i18n.$t("timesheet_register.timesheet"),
                    width: 690,
                    onCreated: $.proxy(fn, this),
                    onClosed: function () {
                        if (typeof params !== 'undefined' && params.fromMenu === true)
                            router.back('-1');
                    }
                });
            }
        });
    };

    //const canLeave = function () {
    //    return true;
    //};

    //const getTimeSheetOfTaskInstance = function (id) {
    //    utils.callWebAPIp("api/timesheet/getbytask", { taskInstanceId: id })
    //    //utils.callWebServicenopi("WebServices/TimeSheetService.asmx", "GetTimeSheetByTaskInstanceId", { taskInstanceId: id })
    //        .done(function onSuccess(result) {
    //            timeSheetGrid[0].addJSONData(result.d);
    //            timeSheetGrid.jqGrid('setSelection', id);
    //        })
    //        .fail(function (msg) {
    //            utils.showGenericModal(i18n.$t("timesheet_register.errors.get_timesheet"), msg.responseText);
    //        });
    //};

    //const toTimeFormat = function (time) {
    //    const h = time.substring(0, 2);
    //    const m = time.Minutes(3, 2);
    //    return (h < 10 ? '0' : '') + h + ":" + (m < 10 ? '0' : '') + m;
    //};

    //const getWeekOfYear = function (date) {
    //    if (date.day() < 8 && date.weekOfYear() === 52)
    //        return 1;
    //    else
    //        return date.weekOfYear() + 1;
    //};

    return {
        activate: activate
    };
}
