var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        useTitle: "true",
        title: _vm.$t("appearance_management.ui_setup"),
      },
    },
    [
      _c(
        "div",
        { staticClass: "userSettingsPage", attrs: { id: "ui-form-container" } },
        [
          _c(
            "form",
            { on: { submit: _vm.submit } },
            [
              _c(
                "DxForm",
                {
                  attrs: {
                    id: "uitab",
                    "form-data": _vm.uiData,
                    "label-mode": "outside",
                    "show-colon-after-label": true,
                    "label-location": "left",
                  },
                },
                [
                  _c(
                    "DxTabbedItem",
                    [
                      _c(
                        "DxTab",
                        {
                          attrs: {
                            "col-count": 2,
                            title: _vm.$t("appearance_management.server_wide"),
                          },
                        },
                        [
                          _c(
                            "DxGroupItem",
                            { attrs: { caption: _vm.uiData.groupColors } },
                            [
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "backgroundColor",
                                    "editor-type": "dxColorBox",
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .backgroundColorDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "mandatoryColor",
                                    "editor-type": "dxColorBox",
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .mandatoryColorDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "readonlyTransparent",
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .readonlyTransparentDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxButtonItem",
                                { attrs: { "horizontal-alignment": "left" } },
                                [
                                  _c("DxButtonOptions", {
                                    staticClass: "btn btn-default btn-block",
                                    attrs: {
                                      "on-click": _vm.restoreDefaultValues,
                                      text: _vm.uiData
                                        .restoreDefaultValuesBtnText,
                                      width: "100%",
                                      "use-submit-behavior": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "DxGroupItem",
                            {
                              attrs: {
                                caption: _vm.uiData.groupEPC,
                                "col-count": 3,
                              },
                            },
                            [
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "EPCurl",
                                    "col-span": 3,
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData.EPCurlDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "EPCuser",
                                    "col-span": 3,
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData.EPCuserDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "EPCpassword",
                                    "editor-type": "dxTextBox",
                                    "editor-options": { mode: "password" },
                                    "col-span": 2,
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData.EPCpasswordDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxButtonItem",
                                {
                                  attrs: {
                                    "horizontal-alignment": "center",
                                    "col-span": 1,
                                  },
                                },
                                [
                                  _c("DxButtonOptions", {
                                    staticClass: "btn btn-default btn-block",
                                    attrs: {
                                      "on-click": _vm.testEPC,
                                      text: _vm.uiData.EPCtestDescription,
                                      width: "100%",
                                      "use-submit-behavior": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "EPCEnvironment",
                                    "col-span": 3,
                                    "editor-type": "dxSelectBox",
                                    "editor-options": {
                                      searchEnabled: true,
                                      items: _vm.uiData.EPCEnvironments,
                                      displayExpr: "Value",
                                      valueExpr: "Id",
                                    },
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .EPCEnvironmentDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "DxGroupItem",
                            { attrs: { caption: _vm.uiData.groupGPT } },
                            [
                              _c(
                                "DxSimpleItem",
                                { attrs: { "data-field": "GPTApi" } },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData.GPTApiDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "DxSimpleItem",
                                { attrs: { "data-field": "GPTAccessToken" } },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .GPTAccessTokenDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "DxGroupItem",
                            { attrs: { caption: _vm.uiData.groupMisc } },
                            [
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: { "data-field": "auditTrailEnabled" },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData
                                        .auditTrailEnabledDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "DxTab",
                        {
                          attrs: {
                            "col-count": 2,
                            title: _vm.$t("appearance_management.user"),
                          },
                        },
                        [
                          _c(
                            "DxGroupItem",
                            { attrs: { caption: _vm.uiData.groupSystem } },
                            [
                              _c(
                                "DxSimpleItem",
                                {
                                  attrs: {
                                    "data-field": "debugMode",
                                    "editor-type": "dxCheckBox",
                                  },
                                },
                                [
                                  _c("DxLabel", {
                                    attrs: {
                                      text: _vm.uiData.debugModelDescription,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "DxGroupItem",
                    { attrs: { caption: "" } },
                    [
                      _c("DxButtonItem", {
                        attrs: {
                          "button-options": _vm.saveButtonOptions,
                          "horizontal-alignment": "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          _vm._s(_vm.$t("attention")) + " " + _vm._s(_vm.$t("page_refresh"))
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }