var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        showModal: _vm.showModal,
        "data-backdrop": "false",
        size: { "my-col": true, "col-lg-8": true },
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.modalHeader))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-7 col-xs-12" }, [
            _c("div", { staticClass: "dx-fieldset" }, [
              _c("div", { staticClass: "dx-field" }, [
                _c("div", { staticClass: "dx-field-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("delegation_management.delegation_user"))
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dx-field-value" },
                  [
                    _c("DxSelectBox", {
                      attrs: {
                        "data-source": _vm.destinationData,
                        disabled: _vm.disabledCombo,
                        displayExpr: "label",
                        valueExpr: "id",
                        "search-enabled": true,
                        "search-mode": "contains",
                        "search-expr": "label",
                        "show-clear-button": true,
                      },
                      model: {
                        value: _vm.formData.DestinationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "DestinationId", $$v)
                        },
                        expression: "formData.DestinationId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dx-field" }, [
                _c("div", { staticClass: "dx-field-label" }, [
                  _vm._v(_vm._s(_vm.$t("delegation_management.all_time"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dx-field-value" },
                  [
                    _c("DxCheckBox", {
                      attrs: { value: _vm.formData.IsPermanent },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.formData, "IsPermanent", $event)
                        },
                        valueChanged: _vm.updatePermanent,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.formData.IsPermanent,
                      expression: "!formData.IsPermanent",
                    },
                  ],
                  staticClass: "dx-field",
                },
                [
                  _c("div", { staticClass: "dx-field-label" }, [
                    _vm._v(_vm._s(_vm.$t("delegation_management.from_date"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dx-field-value" },
                    [
                      _c("DxDateBox", {
                        attrs: { type: "date" },
                        model: {
                          value: _vm.formData.FromDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "FromDate", $$v)
                          },
                          expression: "formData.FromDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.formData.IsPermanent,
                      expression: "!formData.IsPermanent",
                    },
                  ],
                  staticClass: "dx-field",
                },
                [
                  _c("div", { staticClass: "dx-field-label" }, [
                    _vm._v(_vm._s(_vm.$t("delegation_management.to_date"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dx-field-value" },
                    [
                      _c("DxDateBox", {
                        attrs: { type: "date" },
                        model: {
                          value: _vm.formData.ToDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "ToDate", $$v)
                          },
                          expression: "formData.ToDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-5 col-xs-12" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(
                  _vm._s(_vm.$t("delegation_management.folder_to_delegate"))
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("DxTreeView", {
                ref: _vm.treeViewRef,
                attrs: {
                  id: "treeview",
                  height: 300,
                  items: _vm.dataTree,
                  keyExpr: "Id",
                  displayExpr: "Text",
                  itemsExpr: "ChildItems",
                  showCheckBoxesMode: "normal",
                  selectNodesRecursive: true,
                  searchEnabled: true,
                  selectByClick: true,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-6 col-xs-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6 col-xs-12" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-8" },
            [
              _vm.errors.length
                ? _c(
                    "div",
                    { staticClass: "form-validate-summary alert alert-danger" },
                    [
                      _c(
                        "ul",
                        { attrs: { id: "appform-validationsummary" } },
                        _vm._l(_vm.errors, function (e) {
                          return _c(
                            "li",
                            {
                              style: {
                                textAlign: _vm.direction ? "right" : "left",
                              },
                            },
                            [_vm._v(_vm._s(e))]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("dx-validation-summary", { attrs: { id: "summary" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("save")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default cancelModal",
                attrs: { "data-dismiss": "modal" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("cancel_nested")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }