<template>
    <div id="app">

        <!--load banner-->
        <banner v-if="isBanner && !isPlugin"></banner>

        <div class="wrapper pull-right" v-if="!isHideAll">

            <!--load sidebar-->
            <sidebar v-if="!isPlugin"></sidebar>

            <div class="main-container main-container_wide dx-theme-background-color changePasswordPopup" v-bind:class="{ plugin_main: isPlugin}">
                <!--load navbar-->
                <navbar container="container-fluid" v-if="!(isPlugin || isHideMainMenu)">
                    <li class="navbar-notification" slot="leftNavItems">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="true"
                           v-bind:title="$t('message_management.menu_title')" style="font-size: 16px">
                            <i class="fa-light fa-lg fa-messages"></i>
                            <span class="caret"></span>
                            <span id="lblMessage1" v-show="unSeenMessagesCount>0" class="label messageCount label-danger">{{unSeenMessagesCount}}</span>
                        </a>
                        <ul class="dropdown-menu" role="menu">
                            <li>
                                <router-link to="/message-grid/true">{{$t("context.view_received_messages")}}</router-link>
                                <router-link v-if="sendMessagePermission" to="/message-grid/false">{{$t("context.view_sent_message")}}</router-link>
                            </li>
                            <li v-if="sendMessagePermission" class="divider"></li>
                            <li v-if="sendMessagePermission">
                                <router-link to="/send-message">{{$t("context.send_message")}}</router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="navbar-notification" slot="leftNavItems">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="true"
                           v-bind:title="$t('user_settings')" style="font-size: 16px">
                            <i class="fa-light fa-lg fa-user-gear"></i>
                            <span class="caret"></span>
                        </a>

                        <ul class="dropdown-menu" role="menu">
                            <li>
                                <a href="#" v-on:click="showChangePassword($event)">{{$t("change_password")}}</a>
                            </li>
                        </ul>
                    </li>

                    <li slot="leftNavItems">
                        <a href="./Authentication/LogOut" v-bind:title="$t('sign_out')" style="font-size: 16px">
                            <i class="fa-light fa-lg fa-right-from-bracket"></i>
                        </a>
                    </li>
                </navbar>

                <div class="container-fluid wrapper-padding">
                    <!--load pages here-->
                    <router-view></router-view>
                </div>


                <DxPopup :title="$t('change_password')"
                         :visible="showingChangePassword"
                         :hide-on-outside-click="true"
                         :show-close-button="true"
                         :show-title="true"
                         @hidden="onHidden"
                         @shown="onShown"
                         width="620"
                         height="400">
                    <template>
                        <changePassword @messageFromChild="childMessageReceived"></changePassword>
                    </template>
                </DxPopup>
            </div>
            <div class="scroll-top" style="display: table;">
                <div style="margin: auto; display: table-cell; vertical-align: middle; text-align: center;"><i class="fad fa-2x fa-chevron-up"></i></div>
            </div>
        </div>

        <div class="wrapper pull-right" v-if="isHideAll">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
    import Banner from './component/banner/banner.vue';
    import Navbar from './component/navbar/navbar.vue';
    import Login from './component/login/login.vue';
    import Sidebar from './component/sidebar/sidebar.vue';
    import { App } from './assets/ts/global';
    import ChangePassword from "./component/settings/ChangePassword.vue";
    import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
    import { DxButton } from 'devextreme-vue/button';
    import themes from "devextreme/ui/themes";

    const app = new App();

    export default {
        data() {
            return {
                isBanner: payload.isBanner,
                bodyBackground: payload.bodyBackground,
                unSeenMessagesCount: payload.unSeenMessagesCount,
                sendMessagePermission: payload.sendMessagePermission,
                showingChangePassword: false || payload.ShowChangePassword,
                isPlugin: payload.isPlugin,
                isHideMainMenu: payload.isHideMainMenu,
                isHideFormHeader: payload.isHideFormHeader,
                isHideFormToolbar: payload.isHideFormToolbar,
                isHideAll: payload.isHideAll,
                version: payload.version
            }
        },
        computed: {
            showFooter() {
                return !payload.hideFooter
            }
        },
        mounted() {
            // apply theme and force default
            //if (!localStorage.dbpTheme)
            localStorage.dbpTheme = 'material.blue.light';
            themes.current(localStorage.dbpTheme);

            setTimeout(function () {
                app.loadMethods();
            }, 500)
        },
        methods: {
            showNotification(id, title, node) {
                var self = this;
                var dir = payload.currentLocale.IsRightToLeft == true ? 'rtl' : 'ltr';
                var options = {
                    body: title,
                    icon: "./dist/bpms-notif.png",
                    dir: dir,
                }

                Notification.requestPermission(function () {
                    console.log("Private: send notification", options);
                    var instance = new Notification(i18n.$t('new_message'), options);
                    instance.onclick = function (e) {
                        self.nodeSelect(node, node);
                    }
                    setTimeout(function () {
                        instance.close();
                    }, 4000);
                });
            },
            mounted() {
                this.$root.$on('PasswordChangeFormHide', () => {
                    this.hideChangePasswordForm();
                });
            },
            hideChangePasswordForm() {
                this.showingChangePassword = false;
                this.$root.$emit('PasswordChangeFormReset');
            },
            onHidden(e) {
                this.hideChangePasswordForm();
            },
            onShown(e) {
                this.$root.$emit('PasswordChangeFormInit');
            },
            childMessageReceived(arg1) {
                this.hideChangePasswordForm();
            },
            showChangePassword(e) {
                this.showingChangePassword = true;
                e.preventDefault();
            }
        },
        components: {
            Banner,
            Navbar,
            Login,
            Sidebar,
            ChangePassword,
            DxPopup, DxPosition, DxToolbarItem, DxButton
        }
    }
</script>
