import Modal from "../../widgets/modal/modal.vue";

export default {
    props: [
        'showContextModal',
        'modalData'
    ],
    data() {
        return {
            modalHeader: this.modalData && this.modalData.isCreation ?
                i18n.$t("create_physical_folder.add_modal_header") :
                i18n.$t("create_physical_folder.edit_modal_header"),
            folderName: "",
            folderType: 0,
            disableFolderTypeSelector: false,
            titlePlaceHolder: i18n.$t("create_physical_folder.folder_title")
        }
    },
    computed: {
        showModal() {
            this.folderName = "";
            this.folderType = 0;
            $("#divFolderValidation").text('');
            if (this.modalData) {
                if (this.modalData.isCreation) {
                    let folderType = this.modalData.nodeDataItem.FolderType;

                    if (folderType !== undefined) {
                        this.disableFolderTypeSelector = false;

                        switch (folderType) {
                            case 'Working':
                                this.folderType = 0;
                                break;
                            case 'Completed':
                                this.folderType = 1;
                                break;
                        }

                    }
                    else {
                        this.disableFolderTypeSelector = true;
                    }
                } else {
                    this.folderName = this.modalData.nodeDataItem.Text;
                    switch (this.modalData.nodeDataItem.FolderType) {
                        case 'Working':
                            this.folderType = 0;
                            break;
                        case 'Completed':
                            this.folderType = 1;
                            break;
                    }
                    this.disableFolderTypeSelector = true;
                }
            }
            return this.showContextModal;
        }
    },
    methods: {
        submit() { 
            let url = "";
            let data = {};
            let callback;
            if (this.folderName == "") {
                $("#divFolderValidation").text(i18n.$t("create_physical_folder.folder_title_empty"));
            } else { 
                if (this.modalData) {
                    if (this.modalData.isCreation) {
                        url = "api/folder/SavePhysicalFolder";
                        data = {
                            principalId: this.modalData.nodeDataItem.PrincipalId,
                            title: this.folderName.replace(/ی/gmi, 'ي').replace(/ك/gmi, 'ک'),
                            parentId: this.modalData.nodeDataItem.ItemTypeString == 'PhysicalFolder' ? this.modalData.nodeDataItem.Id : null,
                            taskInstanceType: this.folderType
                        };
                        callback = (data) => {
                            this.modalData.callback({
                                Text: this.folderName, ToolTip: this.folderName,
                                ChildItems: [],
                                HasChildren: false
                            });
                        };
                    } else {
                        url = "api/folder/UpdatePhysicalFolder";
                        data = {
                            id: this.modalData.nodeDataItem.Id,
                            title: this.folderName,
                            taskInstanceType: this.folderType
                        };
                        callback = (data) => {
                            this.modalData.nodeDataItem.Text =
                                this.modalData.nodeDataItem.ToolTip = this.folderName;
                            this.modalData.callback(this.modalData.nodeDataItem);
                        };
                    }
                }
                this.doAjaxRequest(url, data, (data) => { callback(data); }, (data) => { console.log(data); });
            }
            
        },
        doAjaxRequest(url, data, onSuccess, onFailure) {
            $.ajax({
                url: url,
                method: "GET",
                data: data,
                dataType: "json",
                contentType: "application/json;charset=utf-8"
            }).done((result) => {
                if (typeof onSuccess === 'function')
                    onSuccess(result);
                $('.cancelModal', this.$el).click();
            }).error((error) => {
                console.log(error);
                $("#divFolderValidation").text(error.responseJSON.error);
                if (typeof onFailure === 'function')
                    onFailure();
            });
        }
    },
    updated() {
    },
    mounted() {
    },
    components: {
        Modal
    }
}
