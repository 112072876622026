(function (jQuery) {
    jQuery.fn.selectbox = function (settings) {
        settings = jQuery.extend({
            onSelect: null,
            selectContainer: $('<div/>')
        }, settings);
        settings.selectContainer.mouseleave(function () { $(this).hide(500); }).hide();
        //  settings.selectContainer.mouseover(function () { $(this).stop(true, true).show(500); });
        this.each(function () {
            var currentItem = $(this);
            //var cell = currentItem.parent();
            currentItem.init = function () {
                currentItem.addClass('flagImg');
            };
            currentItem.init();
            currentItem.click(function () {
                settings.selectContainer.css('position', 'fixed').css('top', $(this).offset().top + $(this).height()).css('left', $(this).offset().left);
                settings.selectContainer.find('li').each(function () {
                    $(this).unbind('click');
                    $(this).click(function () { currentItem.select($(this), currentItem); settings.selectContainer.hide(500); });
                });
                settings.selectContainer.show(500, function () { $(document).one('click', function () { settings.selectContainer.hide(); }); });
            });
            currentItem.select = function (selected, current) {
                if (settings.onSelect != null)
                    settings.onSelect(selected, current);
                settings.selectContainer.hide(500);
            };
        });
        return this;
    };
})(jQuery);