var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c(
        "newForm",
        {
          class: [_vm.direction ? "k-rtl" : ""],
          attrs: { useTitle: "true", title: _vm.$t("audit_trail.title") },
        },
        [
          _c(
            "DxDataGrid",
            {
              attrs: {
                "show-borders": true,
                "data-source": _vm.dataSource,
                "remote-operations": true,
                "column-auto-width": true,
              },
              on: { exporting: _vm.onExporting },
              scopedSlots: _vm._u([
                {
                  key: "cellHtmlTemplate",
                  fn: function (ref) {
                    var data = ref.data
                    return [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(data.value) },
                      }),
                    ]
                  },
                },
                {
                  key: "cellHtmlTemplateEntityDefinition",
                  fn: function (ref) {
                    var data = ref.data
                    return [
                      _c("div", {
                        staticClass: "auditTrailEntityDefinitionRow",
                        domProps: { innerHTML: _vm._s(data.value) },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c("DxExport", { attrs: { enabled: true } }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Id",
                  caption: "ID",
                  "allow-header-filtering": false,
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "OperationTime",
                  caption: _vm.$t("audit_trail.operation_time"),
                  "data-type": "datetime",
                  "sort-index": 1,
                  "sort-order": "desc",
                  format: _vm.datetimeFormat,
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "ApplicationName",
                  caption: _vm.$t("audit_trail.application_name"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "User",
                  caption: _vm.$t("audit_trail.user"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Operation",
                  caption: _vm.$t("audit_trail.operation"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "EntityType",
                  caption: _vm.$t("audit_trail.entity_name"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "EntityDefinition",
                  caption: _vm.$t("audit_trail.entity_definition"),
                  "cell-template": "cellHtmlTemplateEntityDefinition",
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "TaskSubject",
                  caption: _vm.$t("audit_trail.task_subject"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "FieldName",
                  caption: _vm.$t("audit_trail.field_name"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "OldValue",
                  caption: _vm.$t("audit_trail.old_value"),
                  "cell-template": "cellHtmlTemplate",
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "NewValue",
                  caption: _vm.$t("audit_trail.new_value"),
                  "cell-template": "cellHtmlTemplate",
                  "allow-header-filtering": false,
                },
              }),
              _vm._v(" "),
              _c("DxPaging", { attrs: { "page-size": 15 } }),
              _vm._v(" "),
              _c("DxPager", {
                attrs: {
                  "show-page-size-selector": true,
                  "allowed-page-sizes": [15, 20, 50],
                  "show-navigation-buttons": true,
                  "show-info": true,
                },
              }),
              _vm._v(" "),
              _c("DxFilterRow", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxHeaderFilter", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGroupPanel", { attrs: { visible: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }