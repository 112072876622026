var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sliderItems.length !== 0
    ? _c(
        "div",
        {
          staticClass: "carousel carousel_custom slide panel panel-default",
          attrs: { id: "myCarousel", "data-ride": "carousel" },
        },
        [
          _c("loading", {
            attrs: { position: "absolute", zindex: "0", showMessage: "false" },
          }),
          _vm._v(" "),
          _c(
            "ol",
            { staticClass: "carousel-indicators" },
            _vm._l(_vm.sliderItems, function (item) {
              return _vm.sliderItems.length >= 2
                ? _c("li", {
                    attrs: {
                      "data-target": "#myCarousel",
                      "data-slide-to": "0",
                    },
                  })
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "carousel-inner" },
            _vm._l(_vm.sliderItems, function (item, index) {
              return item.ImageUrl
                ? _c(
                    "div",
                    {
                      class:
                        _vm.currentSlide === index + 1 ? "item active" : "item",
                    },
                    [
                      _c("img", {
                        staticClass: "img-responsive",
                        attrs: {
                          src:
                            "api/file/getfile?outputType=inline&imageId=" +
                            [item.ImageUrl],
                        },
                      }),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm.sliderItems.length >= 2
            ? _c(
                "a",
                {
                  staticClass: "left carousel-control",
                  attrs: { href: "#myCarousel", "data-slide": "prev" },
                },
                [
                  _c("i", {
                    staticClass: "fad fa-lg fa-chevron-left icon-next",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.sliderItems.length >= 2
            ? _c(
                "a",
                {
                  staticClass: "right carousel-control",
                  attrs: { href: "#myCarousel", "data-slide": "next" },
                },
                [
                  _c("i", {
                    staticClass: "fad fa-lg fa-chevron-right icon-prev",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }