var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.useTitle === "true" && !_vm.isPlugin
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "div",
            { staticClass: "page-header page-header_custom" },
            [_vm._t("breadcrumb")],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticStyle: { clear: "both" } }),
    _vm._v(" "),
    !_vm.isHideAll
      ? _c(
          "div",
          {
            staticClass: "form-container",
            class: _vm.className,
            style: _vm.customStyle,
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "panel panel-default dx-theme-background-color dx-widget",
              },
              [
                _c(
                  "div",
                  { staticClass: "panel-body relative form-container-body" },
                  [
                    !_vm.isHideFormHeader
                      ? _c("span", { staticClass: "app-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isHideFormToolbar
                      ? _c("div", { staticClass: "toolbar" })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isHideFormHeader || !_vm.isHideFormToolbar
                      ? _c("hr", { staticClass: "app-title" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("default"),
                  ],
                  2
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isHideAll
      ? _c(
          "div",
          {
            staticClass: "form-container",
            class: _vm.className,
            style: _vm.customStyle,
          },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }