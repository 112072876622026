var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("slider"),
      _vm._v(" "),
      _c("home-menu"),
      _vm._v(" "),
      _c("announcement"),
      _vm._v(" "),
      _c("news"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }