var localeMessages = {
    user: 'المستعمل',
    staff: 'العاملين',
    save_successfully: 'حفظ بنجاح',
    error_saving: 'خطأ حفظ',
    help: 'مساعدة',
    ok: 'موافقة',
    send_as_staff: 'إرسال كموظف',
    remove_record: 'إزالة السجل',
    homepage: 'الصفحة الرئيسية',
    view_form: 'عرض النموذج',
    confirm: 'تؤكد',
    prev_row: 'الصف السابق',
    next_row: 'الصف التالي',
    save_and_new: 'حفظ والجديد',
    save_and_next: 'حفظ والتالي',
    save_and_previous: 'حفظ والسابق',
    save_and_return: 'حفظ والعودة',
    cancel_and_return: 'إلغاء والعودة',
    submit_form: 'تقديم النموذج',
    return: 'إرجاع',
    save_form: 'حفظ النموذج',
    login: 'تسجيل الدخول',
    sign_in: 'تسجيل',
    username: 'اسم المستخدم',
    password: 'كلمه السر',
    date: 'التاريخ',
    author: 'المؤلف',
    more_details: 'عرض المزيد من التفاصيل ',
    show: 'تبين',
    close: 'غلق',
    open: 'افتح',
    languages: 'اللغات',
    try_again: 'حاول ثانية',
    tracking: 'تتبع',
    tracking_code: 'شفرة التتبع',
    history: 'التاريخ',
    process_level: 'مستوى العملية',
    loading: 'جار التحميل',
    uploading: 'رفع',
    save: 'حفظ',
    remove: 'إزالة',
    attention: 'انتباه',
    select: 'تحديد',
    please: 'رجاء',
    doing: 'تفعل',
    upload_doing: 'تحميل وحفظ',
    page_refresh: 'لمشاهدة التغييرات ، قم بتحديث ذاكرة التخزين المؤقت (Ctrl-F5).',
    send: 'إرسال',
    insert: 'إدراج',
    edit: 'تعديل',
    delete: 'يمسح',
    reply: 'رد',
    are_you_sure: 'هل أنت واثق؟',
    control: 'مراقبة',
    show_error_details: 'عرض تفاصيل الخطأ',
    warning: 'تحذير',
    search: 'بحث',
    empty: 'فارغة',
    new: 'جديد',
    priority: 'أفضلية',
    priority_low: 'منخفضة',
    priority_normal: 'عادي',
    priority_high: 'عالي',
    priority_very_high: 'عالي جدا',
    subject: 'موضوع',
    created_date: 'أنشئت في',
    response_deadline: 'رد الموعد النهائي',
    done_date: 'فعلت في',
    sender: 'مرسل',
    starter_user: 'بداية المستخدم',
    starter_staff: 'مستخدم الموظفين',
    is_assignable: 'هل يمكن التنازل عنه',
    insert_label: 'ضع الكلمة المناسبة',
    continue: 'استمر',
    submit: 'يُقدِّم',
    cancel: 'يلغيا',
    cancel_nested: 'إلغاء',
    other: 'آخر',
    settings: 'الإعدادات',
    change_theme: 'المواضيع',
    message: 'الرسالة',
    messages: {
        not_history_for_tracking_code_found: 'لم يتم العثور على السجل',
        not_selected_principals: 'لم يتم العثور على سجل لرمز التتبع',
        write_dont_work_folder_name: 'يرجى تحديد اسم لمهام المهام الواجبة',
        write_done_work_folder_name: 'الرجاء تحديد اسم لمجلد المهام المكتملة',
        write_dont_send_request_folder_name: 'الرجاء تحديد اسم لمجلد مسودة الطلب',
        save_attntion: 'هل انت متأكد انك تريد المتابعة؟',
        changing_name: 'إعادة تسمية',
        sucsses_message: 'تم حفظ التغييرات بنجاح',
        file_volume: 'حجم الملف',
        file_upload: 'يجب ألا يتجاوز 300 كيلو بايت',
        file_type: 'تحميل ملف بتنسيق jpg png أو gif',
        delete_sucsses: 'حذف بنجاح',
        reset_confirm: 'هل تريد بالتأكيد إعادة تعيين صورة الخلفية كإعداد افتراضي؟',
        doing_sucsses: 'تم بنجاح',
        file_type_attention: 'يتم دعم ملفات jpg png أو gif فقط',
        delete_confirm_massage: 'هل أنت متأكد أنك تريد حذف؟',
        your_request_has_been_sent: 'تم ارسال طلبك بنجاح',
        confirm_message: 'هل أنت واثق؟',
        save_successfully: 'حفظ بنجاح',
        password_not_currect: 'اسم المستخدم أو كلمة المرور غير صحيحة',
        connection_not_safe: 'الاتصال غير آمن',
        pleas_try_later: 'حاول مرة اخرى',
        username_or_password_is_null: 'اسم المستخدم أو كلمة المرور غير صحيحة',
        control_behavior_not_exists: 'لا يوجد سلوك نموذج لعنصر النموذج هذا',
        not_any_record_found: 'لم يتم العثور على السجل.'
    },
    apply: 'تطبيق',
    all_messages: 'جميع الرسائل',
    new_message: 'رسالة جديدة',
    messages_sent: 'الرسائل المرسلة',
    messages_receive: 'الرسائل المستلمة',
    messages_receive_notification: 'لديك رسالة جديدة!',
    sign_out: 'خروج',
    change_password: 'تغيير كلمة السر',
    'first-page': 'الصفحة الأولى',
    'view-form': 'عرض النموذج',
    invalid_date: 'تاريخ غير صالح',
    operation_faild: 'فشلت العملية.',
    session_end_you_will_redirect_to_the_homepage:
        'انتهت جلستك ، وسوف تعيد التوجيه إلى الصفحة الرئيسية.',
    session_end_title: 'مهلة جلسة.',
    login_again: 'الرجاد الدخول على الحساب من جديد!',
    server_shutdown_template: 'انتباه! يتم إغلاق الخادم الساعة 12:00 صباحًا.',
    server_notif_message: 'أدخل الرسالة التي تريد إرسالها إليها',
    server_notif_message_users: 'المستخدمين',
    server_notif_message_sent: 'تم إرسال الرسالة بنجاح!',
    server_notif_message_error: 'حدث خطأ!',
    process_tree: 'شجرة التاريخ',
    debug_info: 'معلومات التصحيح',
    process_graph: 'الرسم البياني التاريخ',
    process_history: 'تاريخ العملية',
    print_form: 'طباعة',
    time_sheet: 'ورقة التوقيت',
    assign: 'إلى الأمام',
    word_output: 'تصدير إلى Word',
    pdf_output: 'تصدير إلى PDF',
    word_print: 'طباعة من Word',
    report: 'التقارير',
    pdf_download: 'تحميل ملف PDF',
    select_file: 'اختر ملف',
    send_pdf: 'إرسال ملف PDF',
    by_level: 'العاملين',
    view_all_staff: 'عرض جميع الموظفين',
    image: 'صورة',
    of: 'من',
    error: 'خطأ',
    has_form_less_events: 'لديه شكل أقل الأحداث',
    more_items: 'المزيد من العناصر...',
    'retrieve-data-error': 'فشل استرداد البيانات.',
    upload_pdf_file: 'إرسال ملف pdf.',
    download_pdf_file: 'تحميل ملف pdf',
    export: 'تصدير',
    not_selected: 'لم يتم اختياره',
    news_detaile: 'تفاصيل الأخبار',
    new_task: 'مهمة جديدة',
    select_date: 'يرجى تحديد تاريخ البدء والانتهاء',
    id: 'هوية شخصية',
    select_all: 'اختر الكل',
    deselect_all: 'الغاء تحديد الكل',
    copied: 'نسخ',
    unable_to_copy: 'غير قادر على النسخ',
    password_setting: {
        current_password: 'كلمة المرور الحالية',
        new_password: 'كلمة مرور جديدة',
        confirm_new_password: 'تأكيد كلمة المرور الجديدة',
        messages: {
            current_password_is_wrong: 'كلمة المرور الحالية خاطئة',
            passwords_do_not_match: 'كلمات المرور غير متطابقة',
            new_password_same_as_current:
                'لا يمكن أن تكون كلمة المرور الجديدة هي نفسها كلمة المرور الحالية',
            currentPassword_is_empty: 'كلمة المرور الحالية فارغة ',
            newPassword_is_empty: 'كلمة المرور الجديدة فارغة ',
            confirmNewPassword_is_empty: 'تأكيد كلمة المرور الجديدة فارغ'
        }
    },
    title: {
        news: 'أخبار',
        announcements: 'الإعلانات',
        procces_history: 'تاريخ العملية'
    },
    search_panel_control: {
        filter: 'مرشح',
        clear_filter: 'مسح مرشح'
    },
    grid_control: {
        add: 'إضافة ',
        add_inline: 'مضمن إضافة',
        edit: 'تعديل',
        edit_inline: 'تحرير مضمن',
        del: 'حذف',
        undel: 'يلغي',
        export_excel: 'تصدير إلى Excel',
        import_excel: 'استيراد Excel',
        template_excel: 'تحميل قالب اكسل',
        save: 'حفظ',
        cancel: 'إلغاء',
        view: 'رأي',
        export: 'تصدير',
        grid_is_in_editing_mode: 'في وضع التحرير ، يرجى تطبيق التغييرات.',
        grid_is_in_editing_mode_you_cannot_do_anything:
            'غير مسموح لك بالقيام بأي عمليات في وضع التحرير. ',
        grid_error_retrieving_data: 'حدث خطأ في جلب البيانات.',
        no_rows_selected: 'لم يتم تحديد صفوف',
        remove_selected_row: 'إزالة الصف المحدد؟',
        only_selected_columns_will_be_exported: 'سيتم تصدير الأعمدة المحددة فقط',
        items_get_error: 'حدث خطأ أثناء الحصول على قائمة',
        noDataText: 'لا توجد بيانات لعرضها.',
        saveChanges: 'حفظ التغييرات',
        discard: 'تجاهل التغييرات',
        saved: 'أنقذ',
        save_unsuccessful: 'لم يكن الحفظ ناجحًا',
        sum: 'جم',
        avg: 'متوس',
    },
    attachment_control: {
        add: 'إضافة ',
        new: 'جديد',
        select: 'تحديد',
        scan: 'المسح الضوئي ',
        new_version: 'نسخة جديدة',
        delete: 'حذف',
        delete_all: 'حذف الكل',
        revert: 'العودة ',
        edit: 'تعديل',
        download: 'تحميل',
        noFileExists: 'لا يوجد ملف',
        select_file: 'اختر ملف',
        upload_file: 'رفع ملف',
        upload_files_all: 'تحميل جميع الملفات ',
        upload_cancel: 'إلغاء الرفع',
        view: 'رأي',
        messages: {
            folder_default_not_found: 'المجلد الافتراضي غير موجود',
            delete_file: 'هل أنت متأكد أنك تريد حذف؟',
            delete_all_files: 'هل أنت متأكد أنك تريد حذف جميع الملفات؟',
            save_Changes: 'هل انت متأكد انك تريد المتابعة؟',
            error_try_again: 'حدث خطأ أثناء الحفظ يرجى المحاولة مرة أخرى',
            file_edit_dont_allow: 'غير مسموح لك بتحرير هذا النوع من الملفات',
            desktop_file_launcher_notFound:
                "<a href='FileLuncher/FileLauncherSetup.exe' style='font-weight:bold؛'> هنا <a/> تنزيل من DesktopFileLauncher لم يتم العثور على خدمة",
            reverted_Successfully: 'عاد الملف بنجاح. ',
            file_upload_count_error: 'عدد الملفات المضافة يتجاوز الحد المسموح به ولا يمكن تحميله',
            default_file_delete_error: 'حذف الملف الافتراضي غير ممكن',
            default_file_edit_error: 'تحرير الملف الافتراضي غير ممكن',
            file_type_error: 'نوع الملف غير مسموح به',
            scan_upload_error: 'حدث خطأ أثناء الفحص',
            scan_service_error: 'خدمة الماسح الضوئي غير موجودة',
            select_file_error: 'لم يتم تحديد عناصر',
            file_edit_error: 'حدث خطأ أثناء التعديل',
            file_upload_successfully: 'تم تحميل الملف بنجاح',
            file_size_error: 'حجم الملف يتجاوز الحد المسموح به ولا يمكن تحميله',
            file_repeat_name_error: 'يتم تكرار اسم الملف',
            file_select_count_error: 'عدد الملفات المحددة يتجاوز الحد المسموح به',
            file_upload_error: 'حدث خطأ أثناء التحميل ',
            file_name_repeated_by_param: 'يتم تكرار الملف',
            'scan-opration-encountered-problem': 'حدثت مشكلة في المسح ',
            file_is_in_edit_mode: 'الملف في وضع التحرير ',
            dont_allow_edit_file: 'تحرير هذا النوع من الملفات غير ممكن',
            file_download_new_error: 'لا يمكن تنزيل الملف الذي تم تحميله حديثًا',
            file_open_new_error: 'لا يمكن فتح الملف الذي تم تحميله حديثًا'
        },
        upload_files: 'تحميل الملفات',
        accepted_types: 'أنواع الملفات المقبولة',
        preview: 'معاينة',
        one_file: '1 ملف',
        files: 'ملفات',
        each: 'كل'
    },
    export_control: {
        title: 'اختر نوع التصدير ',
        titlesetting: 'إعداد العنوان',
        showTableTitle: 'عرض العنوان في التصدير ',
        backGround: 'لون الخلفية',
        fontname: 'اسم الخط',
        fontsize: 'حجم الخط',
        fontcolor: 'لون الخط',
        borderstyle: 'نمط الحدود',
        bordercolor: 'لون الحدود ',
        columnsetting: 'إعداد الأعمدة ',
        cellsetting: 'إعداد الخلايا ',
        createfile: 'إنشاء ملف',
        bodysetting: 'إعداد الجسم ',
        exit: 'خروج',
        columnfontstyle: 'نمط خط العمود ',
        context: 'سياق الكلام',
        contextstyle: 'نمط السياق',
        date: 'تاريخ',
        twodigit: 'الرقم مع ما يصل إلى رقمين عشريين ',
        fourdigit: 'الرقم مع ما يصل إلى أربعة أرقام عشرية'
    },
    chart_control: {
        error_in_Get_Data: 'بيانات المخطط فشل',
        updating_chart: 'جارٍ تحميل المخطط',
        updating_map: 'جارٍ تحميل المخطط ... ',
        error_in_get_map_data: 'تحميل الخريطة',
        loading: 'تحميل...',
        exportButtonTitle: 'يصدّر',
        printButtonTitle: 'مطبعة',
        rangeSelectorFrom: 'من',
        rangeSelectorTo: 'ل',
        rangeSelectorZoom: 'تكبير',
        downloadPNG: 'تحميل الصورة PNG',
        downloadJPEG: 'تحميل الصورة بصيغة JPEG',
        downloadPDF: 'تحميل الوثيقة بصيغة PDF',
        downloadSVG: 'تنزيل الصورة SVG',
        downloadXLS: 'تحميل XLC',
        downloadCSV: 'تحميل النص',
        printChart: 'الرسم البياني للطباعة',
        contextButtonTitle: 'قائمة سياق الرسم البياني',
        month_january: 'يناير',
        month_february: 'شهر فبراير',
        month_march: 'يمشي',
        month_april: 'أبريل',
        month_may: 'يمكن',
        month_june: 'يونيو',
        month_july: 'يوليو',
        month_august: 'أغسطس',
        month_september: 'سبتمبر',
        month_october: 'اكتوبر',
        month_november: 'شهر نوفمبر',
        month_december: 'ديسمبر',
        short_month_january: 'ينا',
        short_month_february: 'فبر',
        short_month_march: 'مار',
        short_month_april: 'أبر',
        short_month_may: 'ماي',
        short_month_june: 'يون',
        short_month_july: 'يول',
        short_month_august: 'أغس',
        short_month_september: 'سبتم',
        short_month_october: 'أكت',
        short_month_november: 'نوفم',
        short_month_december: 'ديسم',
        weekday_monday: 'الاثنين',
        weekday_tuesday: 'يوم الثلاثاء',
        weekday_wednesday: 'الأربعاء',
        weekday_thursday: 'يوم الخميس',
        weekday_friday: 'جمعة',
        weekday_saturday: 'السبت',
        weekday_sunday: 'الأحد'
    },
    assign_control: {
        no_name: 'بلا اسم',
        delete: 'حذف',
        messages: {
            item_is_repeated: 'يتم تكرار العنصر '
        }
    },
    tree_control: {
        expand_collapse: 'توسيع الانهيار',
        error: 'خطأ',
        warning: 'تحذير',
        add_new: 'إضافة ',
        show: 'تبين',
        edit: 'تعديل',
        delete: 'حذف',
        messages: {
            item_delete_confirm: 'هل أنت متأكد أنك تريد حذف؟',
            item_get_info_error: 'حدث خطأ أثناء الحصول على المعلومات!'
        }
    },
    comment_control: {
        delete_confirm: 'هل أنت متأكد أنك تريد حذف؟',
        back: 'عودة',
        save: 'تطبيق',
        empty: 'لا يمكن أن يكون التعليق فارغًا'
    },
    dropdown_control: {
        select: 'تحديد',
        messages: {
            items_get_error: 'حدث خطأ أثناء الحصول على قائمة'
        }
    },
    context: {
        new_folder: 'جديد',
        edit_folder: 'تعديل',
        delete_folder: 'حذف',
        open_in_new_window: 'افتح في نافذة جديدة',
        rules: 'إدارة قواعد المجلدات ',
        messages: 'رسائل ',
        send_message: 'أرسل رسالة ',
        view_received_messages: 'عرض الرسائل المستلمة ',
        view_sent_message: 'عرض الرسائل المرسلة '
    },
    cartable_grid: {
        open_in_new_window: 'افتح في نافذة جديدة',
        history: 'التاريخ',
        tree_history: 'شجرة',
        graph_history: 'رسم بياني',
        time_sheet: 'ورقة التوقيت',
        change_folder: 'تغير مجلد',
        flag: 'علم ',
        no_flag: 'بدون علم ',
        assignTask: 'تعيين المهمة',
        FolderService_CanNotTransfer: 'لا يمكن النقل إلى هذا المجلد ',
        total_tasks: 'مجموع المهام في هذا المجلد: ',
        total_unread_tasks: 'إجمالي المهام غير المقروءة'
    },
    create_physical_folder: {
        add_modal_header: 'أنشئ مجلد',
        edit_modal_header: 'تحرير المجلد رقم',
        folder_title: 'عنوان',
        folder_type: 'اكتب',
        dont_send_request: 'مسودات طلبات ',
        undone_works: 'المهام العالقة',
        done_works: 'المهام المكتملة ',
        folder_title_empty: 'عنوان المجلد فارغ ',
        folder_title_length_error: 'يجب ألا يتجاوز طول العنوان 50 حرفًا',
        folder_title_validate_error: 'الاسم غير صالح'
    },
    footer: {
        powered_by_raybpms: 'مدعوم من BPMS ',
        rights: 'شركة تقنيات التواصل. كل الحقوق محفوظة',
        url: 'https://www.interfacing.com/',
        help: 'مساعدة',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'خطأ في التتبع!',
        tracking_code_is_empty: 'شفرة التتبع فارغة!',
        error_on_delete: 'حدث خطأ أثناء عملية الحذف ',
        please_select_pdf_file: 'يرجى اختيار ملف pdf',
        operation_is_not_allowed: 'غير مسموح لك',
        events_cannot_be_loaded: 'لا يمكن تحميل الأحداث!',
        server_error: 'خطأ في الخادم',
        you_are_not_allowed_to_view_form: 'ليس لديك إذن للوصول إلى النموذج ',
        access_denied: 'تم الرفض',
        print_failed: 'فشل الطباعة',
        error_occured: 'حدث خطأ ، يرجى الاتصال بالمسؤول',
        task_not_found: 'تعذر العثور على هذه المهمة. ربما تم تنفيذها من قبل مستخدم آخر.',
        application_is_not_deployed: 'لم يتم نشر التطبيق.',
        save_in_progress: 'جاري الحفظ حاليًا، يرجى المحاولة مرة أخرى لاحقًا.'
    },
    Lock: {
        'bpms-lock-expired': 'انتهت صلاحية القفل',
        'maximum-bpms-lock-user': 'عدد المستخدمين المتزامنين يتجاوز الترخيص',
        'bpms-lock-failed': 'قفل غير موجود'
    },
    slider_managment: {
        title: 'صور شريط التمرير',
        slider_show_is_repeat: 'هناك تكرار في ترتيب صورة شريط التمرير',
        slider_show_is_empty: 'ترتيب الصور فارغ',
        upload_message: 'صورك أكثر من الحد',
        image_displaying: 'ترتيب الصور',
        same_name_massage: 'توجد صورة بنفس الاسم',
        image_is_too_large: 'حجم الصورة أكبر من اللازم',
        name: 'اسم',
        image: 'صورة',
        pic_type: 'حدد ملف صورة (jpgpnggif) أقل من 80 كيلو بايت',
        image_required: 'اختر صورة',
        level_required: 'حدد ترتيب الصورة',
        new: 'جديد',
        edit: 'تعديل',
        empty: 'فارغة'
    },
    cartable_management: {
        cartable_managment_title: 'إدارة قائمة المهام - إعادة تسمية المجلدات الافتراضية',
        cartable_date_setup: 'إعدادات التاريخ ضمن قائمة المهام',
        status: 'الحالة',
        exact: 'دقيق',
        limited: 'تقريبي',
        exact_dsc: 'عرض التاريخ والوقت بالضبط',
        limited_dsc: 'عرض الأيام الماضية تقريبًا',
        select_person: 'حدد وظيفة الشخص أو مجموعة لإعادة تسمية المجلد الرئيسي',
        dont_works: 'مجلدات مهام المهام:',
        done_work: 'مجلدات المهام المكتملة',
        dont_send_request: 'طلبات المسودة:',
        changing_folder_name: 'إعادة تسمية المجلدات',
        attention_message: 'لمشاهدة آخر التغييرات ، يرجى تسجيل الدخول مرة أخرى',
        tree_expanded: 'سيتم اختيار مجلد معلق من الموظفين الأول بعد تسجيل الدخول',
        hide_default_user_folders: 'إخفاء مجلدات المستخدم الافتراضية (مثل العمل المعلق والمسودة)',
        expand_folders_by_default: 'قم بتوسيع جميع مجلدات المستخدم والموظفين افتراضيًا',
        tree: 'مجلدات الشجرة'
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'تقويم العطلة',
        year: 'عام:',
        show_calendar: 'عرض التقويم',
        year_calendar: 'عام',
        messages: {
            year_validate_message: 'السنة غير صالحة'
        }
    },
    appearance_management: {
        ui_setup: 'إضفاء الطابع الشخصي',
        back_image: 'خلفية',
        default_theme: 'السمة الافتراضية',
        banner: 'أعلى لافتة',
        back_image_dsc: 'لتحديد صفحة تسجيل الدخول والصفحة الرئيسية ، استخدم النموذج أدناه',
        default_theme_dsc: 'يرجى اختيار واحد من رفع الصوت عالياً باعتباره سمة قائمة المهام الافتراضية',
        banner_dsc: 'استخدم النموذج أدناه لتحديد شعار الموقع. ملاحظة',
        before_login: 'قبل صورة تسجيل الدخول',
        after_login: 'صورة تسجيل الدخول',
        current_img: 'الصورة الحالية',
        location: 'موقعك',
        repeat: 'كرر',
        no_repeat: 'بدون تكرار',
        center: 'مركز',
        back_color: 'لون الخلفية',
        upload_save: 'تحميل وحفظ',
        select_theme: 'اختر نمطا',
        preview: 'معاينة السمة',
        set_theme: 'تطبيق موضوع',
        height_attention: 'يجب ألا يتجاوز ارتفاع الصورة 149 بكسل',
        image_attention: 'يجب أن يكون ارتفاع صورة الشعار 149 بكسل',
        back_image_file: 'الصورة الافتراضية للراية',
        right_iamge: 'صورة الجانب الأيسر',
        center_image: 'صورة المركز',
        left_image: 'صورة الجانب الأيمن',
        for_field: 'ل المقدمة',
        select_image_file: 'يرجى اختيار ملف صورة',
        delete_current_banner: 'حذف الشعار الحالي',
        image_restore: 'استعادة الصورة الافتراضية',
        select_theme_for_current_user: 'حدد سمة للمستخدم الحالي',
        file_type: 'يتم دعم ملفات jpg png أو gif فقط',
        server_wide: 'على مستوى الخادم',
        user: 'مستخدم',
        save: 'يحفظ'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                'سيتم تغيير موقع شكل رمز حجم الصورة وحجم النص فقط هل أنت متأكد من رغبتك في المتابعة؟',
            save_sucsses_all: 'يتم تطبيق التغييرات على كل شيء',
            save_confirm: 'هل انت متأكد انك تريد المتابعة؟',
            save_sucsses: 'تم حفظ التغييرات بنجاح',
            insert_number: 'لتغيير الرمز أو حجم الصورة ، يمكنك إدخال الرقم فقط',
            icon_size: 'يجب أن يتراوح حجم الرمز بين 25 إلى 140 بكسل',
            file_type: 'يتم دعم ملفات jpg png أو gif فقط',
            icon_volume: 'يجب ألا يتجاوز حجم الرمز / الصورة 30 كيلو بايت',
            insert_only_number: 'يمكنك إدخال رقم فقط'
        },
        home_ui_setting: 'الإعدادات الرئيسية',
        items_menu: 'قائمة العناصر',
        description: 'لإضافة قوائم إلى الصفحة الرئيسية ، حدد العنصر وقم بتغيير إعداداته',
        menu_setting: 'إعدادات القائمة',
        show_menu: 'إظهار القائمة في الصفحة الرئيسية',
        first_level: 'تظهر في المستوى الأول',
        not_show_top: 'إخفاء القائمة العليا',
        display_name: 'الاسم المعروض',
        icon_width: 'عرض الرمز',
        icon_width_ph: 'أيقونة / عرض الصورة',
        icon_height: 'ارتفاع الرمز',
        icon_height_ph: 'أيقونة / ارتفاع الصورة',
        home_menu_icon: 'رمز القائمة / الصورة في الصفحة الرئيسية',
        select_file: 'يرجى تحديد ملف بتنسيق jpg png أو gif',
        icon_view: 'رمز / صورة الشكل',
        circul: 'دائرة',
        squared: 'ميدان',
        rectangle: 'مستطيل',
        text_location: 'نص الموقع رقم',
        up_image: 'أعلى صورة',
        center_image: 'صورة المركز',
        bottom_image: 'أسفل الصورة',
        up_text: 'النص الأعلى',
        center_text: 'النص الأوسط',
        bottom_text: 'النص السفلي',
        delete_image: 'حذف صورة',
        text_color: 'لون الخط',
        float_text_color: 'تعويم لون النص',
        hilight_text_color: 'تمييز لون النص',
        save_button_title: 'جميع الإعدادات باستثناء الصورة التي تظهر الاسم وإضافة إلى القائمة',
        doing_settup: 'تطبيق التغييرات على الكل',
        check_mark: 'عرض في الصفحة الرئيسية',
        published_date: 'تاريخ النشر'
    },
    news_management: {
        message: {
            insert_title: 'الرجاء إدخال العنوان',
            title_character_length: 'يجب ألا يتجاوز طول العنوان 255 حرفًا',
            text_character_length: 'يجب ألا يتجاوز طول النص 4000 حرف',
            insert_text: 'الرجاء إدخال النص',
            number_priority: 'حدد الأولوية بالأرقام',
            number_large: 'الرقم المحدد لأولوية الأخبار هو أكثر من 4 أرقام',
            file_volume: 'يجب أن يكون حجم الملف أقل من 100 كيلو بايت'
        },
        news: 'أخبار',
        add: 'إضافة',
        title: 'عنوان',
        text: 'نص',
        text_color: 'لون الخط',
        enable_show: 'مرئي للمستخدمين العاديين',
        news_setup: 'إعداد الأخبار',
        news_image: 'صورة الأخبار',
        news_priority: 'أولوية الأخبار',
        current_pic: 'الصور التي تم تحميلها',
        delete_curr_pic: 'حذف الصورة الحالية',
        pic_type: 'تنسيق ملف jpg png أو gif أقل من 100 كيلو بايت',
        id: 'هوية شخصية',
        createdAt: 'تاريخ',
        username: 'اسم المستخدم',
        control: 'مراقبة'
    },
    message_management: {
        messages: {
            repeated_recipient: 'يتكرر المستلم',
            title_is_empty: 'عنوان الرسالة فارغ هل تريد المتابعة؟',
            success_send: 'تم إرسال الرسالة بنجاح',
            unselected_recipient: 'لم يتم تحديد مستلم'
        },
        reply_message: 'الرد',
        title: 'عنوان:',
        message_text: 'نص الرسالة:',
        attachment: 'المرفقات',
        recipients: 'المتلقين',
        select: 'تحديد',
        add_to_list: 'أضف إلى القائمة',
        back: 'إرجاع',
        sended: 'الرسالة المرسلة',
        sended_by: 'أرسلت بواسطة',
        send_date: 'وقت الإرسال',
        message_show: 'اظهر الرسالة',
        no_permission: 'غير مسموح لك بالوصول',
        subject: 'موضوع:',
        date_time_send: 'تاريخ الإرسال والتاريخ',
        sender: 'مرسل',
        receiver: 'المتلقي',
        menu_title: 'رسائل',
        received: 'الرسالة المستلمة',
        send_email: 'ارسل بريد الكتروني',
        send_sms: 'أرسل رسالة نصية قصيرة'
    },
    task_assign: {
        messages: {
            success_assign: 'تم الإرسال بنجاح',
            unselected_recipient: 'لم يتم تحديد مستلم'
        },
        assign: 'إلى الأمام - تعيين',
        recipients: 'المتلقين',
        description: 'وصف',
        last_dsc: 'استخدم الوصف الحالي',
        out_work: 'إنهاء المهمة الحالية',
        keep_sender: 'احتفظ بالمرسل الحالي'
    },
    process_log: {
        status: 'الحالة',
        event: 'حدث',
        user: 'المستعمل',
        user_position: 'وظيفه',
        work_title: 'عنوان وظيفي',
        responsible: 'مسؤول',
        create_date: 'تاريخ بدء المهمة',
        done_date: 'تاريخ انتهاء المهمة',
        folder_name: 'إسم الملف',
        start_task: 'نشاط المبتدئين',
        human_task: 'النشاط البشري',
        fork_task: 'نشاط متشعب',
        join_task: 'النشاط المنضم',
        system_task: 'نشاط النظام',
        common_task: 'النشاط المشترك'
    },
    timesheet_register: {
        messages: {
            time_reng: 'لا يمكن أن يتجاوز تاريخ البدء تاريخ الانتهاء',
            select_row: 'يرجى اختيار صف',
            delete_timesheet_confirm: 'هل أنت متأكد من أنك تريد حذف الجدول الزمني الحالي؟',
            save_time_confirm:
                'يتم تحديد هذه الفترة تور مهمة أخرى. هل تريد استبداله بمصطلحات تاك الحالية؟',
            can_not_edit: 'يتم حفظ هذه الفترة لمهمة مكتملة. التعديل غير مسموح',
            date_not_valid: 'التاريخ غير صالح'
        },
        errors: {
            error_in_remaining_time: 'حدث خطأ في الوقت المتبقي',
            get_timesheet: 'حدث خطأ أثناء تصدير الجدول الزمني',
            error_in_save: 'حدث خطأ أثناء الحفظ',
            error: 'خطأ',
            error_in_delete: 'حدث خطأ أثناء الحذف'
        },
        work_dsc: 'المسمى الوظيفي',
        date: 'تاريخ',
        start_time: 'وقت البدء',
        end_time: 'وقت النهاية',
        remaining_time: 'الوقت المتبقي',
        description: 'وصف',
        timesheet: 'ورقة التوقيت',
        current_work: 'التعيين إلى المهمة الحالية',
        persian_date: 'تاريخ',
        weekly_plan: 'خطة أسبوعية',
        prev_week: 'الأسبوع السابق',
        next_week: 'الاسبوع القادم',
        plus: 'إضافة',
        all_work: 'جميع الأعمال الحالية',
        delete: 'حذف'
    },
    delegation_management: {
        messages: {
            select_user: 'يرجى اختيار مستخدم الإحالة',
            select_date: 'يرجى تحديد تاريخ البدء والانتهاء',
            select_folder: 'الرجاء تحديد المجلدات التي يمكن للمفوض الاطلاع عليها',
            compare_fromDate_toDate: 'تاريخ الانتهاء أكبر من تاريخ البدء'
        },
        folder_to_delegate: 'المجلدات التي يمكن للمفوض الاطلاع عليها:',
        delegations: 'الوفود',
        delegation_edit: 'تحرير التفويض',
        delegation_user: 'تفويض المستخدم:',
        all_time: 'دائم',
        from_date: 'من التاريخ:',
        to_date: 'حتى تاريخه',
        start_date: 'تاريخ البدء',
        finish_date: 'تاريخ الانتهاء',
        delegate_to: 'فوض:',
        more_item: 'المزيد من العناصر',
        all_item: 'كل الاشياء',
        remove_delegate: 'إزالة المفوض',
        delegation_create: 'إضافة',
        no_records: 'لا يوجد سجل يطابق كلماتك الرئيسية',
        delegation_add: 'إضافة تفويض',
        delegation_user_placeholder: 'اكتب للبحث',
        more_users: 'هناك المزيد من النتائج ، الرجاء استخدام كلمات رئيسية أفضل'
    },
    rule_criterias: {
        new_rule: 'قانون جديد',
        edit_rule: 'تعديل القاعدة رقم',
        no_permission: 'أنت بحاجة إلى إذن',
        tag_error: 'حدث خطأ أثناء تحديث العلامة',
        flag_error: 'حدث خطأ أثناء تحديث العلم',
        no_actions: 'لا رد فعل',
        action: 'عمل',
        rule_name: 'اسم القاعدة',
        operation: 'عملية',
        select_folder: 'اختر مجلد'
    },
    ray_payment: {
        messages: {
            close_pay_win: 'أغلق أولاً نافذة الدفع'
        },
        wait_status: 'في انتظار الدفع',
        payed_status: 'سيولي',
        failed_status: 'خطأ في الدفع',
        not_payed_status: 'لم تدفع',
        currency: 'يال',
        through: 'عبر',
        pay: 'دفع'
    },
    ray_datetimepicker: {
        date_not_valid: 'التاريخ غير صالح',
        time_not_valid: 'الوقت غير صالح',
        ok: 'نعم'
    },
    logs_management: {
        show_details:
            'تمكين إظهار تفاصيل الخطأ. بتمكين هذا الخيار ، سيرى المستخدمون رسالة الخطأ التقنية الكاملة في كل مرة تحدث فيها أخطاء',
        error_number: 'رقم',
        date: 'أنشئت في',
        level: 'مستوى',
        staff_id: 'معرف الموظفين',
        application_name: 'اسم التطبيق',
        error_log: 'سجلات الأخطاء',
        error_message: 'رسالة خطأ',
        api_log: 'سجل مكالمات API',
        id: 'بطاقة تعريف',
        session: 'حصة',
        api: 'واجهة برمجة التطبيقات',
        method: 'طريقة',
        severity: 'خطورة',
        search: 'يبحث...',
        key: 'المعلمة الرئيسية',
        duration: 'المدة (ملي ثانية)',
        epc_log: 'سجل مكالمات EPC',
        user_log: 'سجل المستخدم',
        result: 'كود النتيجة',
        application_log: 'سجل التطبيق',
        copy_clipboard: 'نسخ إلى الحافظة',
        user: 'مستخدم',
        login_datetime: 'تاريخ الدخول الوقت',
        logout_datetime: 'تاريخ الخروج الوقت',
        server_name: 'اسم الخادم',
        active_connections: 'اتصالات نشطة',
        send_message_all: 'إرسال رسالة للجميع',
        send_message_connection: 'إرسال رسالة إلى هذا الاتصال',
        start_datetime: 'تاريخ البدء الوقت'
    },
    rule_management: {
        operation: 'عملية',
        rule_name: 'اسم',
        add_new_rule: 'قانون جديد',
        edit_rule: 'تعديل القاعدة رقم',
        are_you_sure: 'هل أنت متأكد من حذف القاعدة؟',
        rule_modal: {
            name: 'اسم القاعدة',
            subject: 'موضوع',
            task_type: 'اكتب',
            create_date: 'تاريخ الانشاء',
            response_date: 'الرد الموعد النهائي',
            done_date: 'تاريخ الانتهاء',
            priority: 'أفضلية',
            flag: 'علم',
            read: 'اقرأ',
            transcript: 'نسخة طبق الأصل',
            app: 'تطبيق',
            in_folder: 'مجلد',
            not_selected_option: 'لم يتم اختياره',
            validation: {
                name: 'مطلوب اسم'
            },
            clear_tooltip: 'حذف'
        },
        title: 'إدارة قواعد المجلدات'
    },
    today: 'اليوم',
    form: 'استمارة',
    form_behavior: 'سلوك النموذج',
    close_form_warning: 'هل أنت متأكد أنك تريد الخروج؟ سيتم فقدان أي تغيير.',
    yes: 'نعم',
    no: 'رقم',
    secret_code: {
        title: 'المصادقة على الموافقة',
        message: 'لقد أرسلنا لك رمزًا سريًا إلى بريدك الإلكتروني. يرجى نسخها ولصقها هنا.',
        code: 'شفرة:',
        legal_sentence:
            'بالموافقة بتوقيعك الإلكتروني ، فإنك تقوم بتوقيع الوثيقة إلكترونيًا. أنت توافق على أن </br> توقيعك الإلكتروني له نفس الصلاحية والتأثير القانونيين لتوقيعك بخط اليد على </br> المستند ، وأن له نفس معنى توقيعك بخط اليد.',
        expired: 'الرمز غير صالح أو منتهي الصلاحية',
        required: 'الرمز مطلوب',
        submit: 'يُقدِّم',
        cancel: 'يلغي'
    },
    pdf_preview: 'معاينة قوات الدفاع الشعبي',
    user_settings: 'إعدادات المستخدم',
    audit_trail: {
        title: 'سجل تدقيق',
        operation_time: 'وقت العملية',
        application_name: 'اسم التطبيق',
        user: 'مستخدم',
        operation: 'عملية',
        entity_name: 'اسم الكيان',
        entity_definition: 'تعريف الكيان',
        task_subject: 'موضوع المهمة',
        field_name: 'اسم الحقل',
        old_value: 'القيمة القديمة',
        new_value: 'قيمة جديدة'
    }
}
export default localeMessages
