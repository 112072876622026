//vm.Slider = (function () {
//    var self = this;
//    var sliderForm = null;
//    var slides = [];
//    var removeSlides = [];
//    var deleteItems = "";
//    var id = '';
//    var index = 0;
//    var canLeave = function () {
//        return true;
//    };

//    var setupEvents = function () {
//    };
//    var GetFiles = function (params) {

//        utils.callWebServicenopi("WebServices/UiService.asmx", "GetSlides", {}, true).done(function (res) {
//            var obj = res.d;
//            utils.hideProgress();
//            CreateSliders(obj, params);
//        });
//    }
//    var Validate = function () {
//        $("#Error").empty();
//        $("#Error").removeClass("AlertError");
//        var result = true;
//        //var l = $("[serverid*=div]").length;
//        //if (l > 4) {
//        //    var error = $("<label>تعداد تصاویر بیش از حد مجاز است.</label> <br/>");
//        //    $(".errors").append(error);
//        //    result = false;
//        //}
//        var numbers = [];
//        var isrepeted = function (value) {
//            for (var i = 0; i < numbers.length; i++) {
//                if (numbers[i] == value) {
//                    {
//                        var error = $("<label>*" + i18n.$t("slider_managment.slider_show_is_repeat") + "</label> <br/>");
//                        $("#Error").append(error);
//                        //$("#Error").addClass("AlertError");
//                        result = false;
//                        break;
//                    }
//                }
//            }
//        }
//        $("[serverid*=txt]").each(function (obj, val) {
//            if ($(val).val() === '') {
//                result = false;
//                var error = $("<label>*" + i18n.$t("slider_managment.slider_show_is_repeat") + "</label> <br/>");
//                $("#Error").append(error);
//            }
//            else {

//                isrepeted($(val).val());
//                if (result == false)
//                    return result;
//                else
//                    numbers.push($(val).val())
//            }
//        });

//        return result;
//    }
//    var CreateSliders = function (obj, params) {
//        slides = obj;
//        var $uiForm = params.element;
//        config.currentViewCanLeave(canLeave);
//        {
//            $uiForm.empty();
//            utils.showModalHtml("Views/Slider.html")
//                .done(function (html) {
//                    $uiForm.append(html);

//                    // for vue-i18n, wrap it into a component
//                    new legacyComponent({ i18n: vueI18n }).$mount($uiForm[0]);
//                    initControlValue();
//                    AddSlides();
//                    params.onLoaded();
//                });
//        }
//        $(".view").hide();
//        $uiForm.show();
//    }
//    var initControlValue = function () {
//        $(".Images").empty();
//        var fileName = "";
//        var uploadFiles = [];

//        $("#UploadFile").unbind('click').bind('click', function (e) {
//            e.preventDefault();
//            var l = $("[serverid*=div]").length;
//            if (l > 3) {
//                var message = i18n.$t("slider_managment.upload_message");
//                noty({
//                    modal: true,
//                    text: message,
//                    type: 'alert',
//                    layout: 'center',
//                    closeWith: ['button']
//                });
//            }
//            else
//                $("#Uploader").click();
//        });
//        $("#btnSubmit").unbind('click').bind('click', function (e) {
//            e.preventDefault();
//            if (Validate() === true) {
//                var fromdata = new FormData();

//                for (var i = 0; i < uploadFiles.length; i++) {
//                    fromdata.append(uploadFiles[i].name, uploadFiles[i]);
//                }
//                fromdata.append('totalDeleteItems', removeSlides.length)
//                for (var i = 0; i < removeSlides.length; i++) {
//                    fromdata.append("RemoveItem" + i, removeSlides[i]);

//                }

//                $("[serverid*=txt]").each(function(obj, val) {
//                    fromdata.append($(val).attr('serverid').replace('txt', ''), $(val).val());
//                });

//                var choice = {};
//                choice.url = "Handlers/PublicFileUploadHandler.ashx";
//                choice.type = "POST";
//                choice.data = fromdata;
//                choice.contentType = false;
//                choice.processData = false;
//                choice.cache = false;
//                choice.dataType = 'json',
//                    choice.success = function (result) {
//                        utils.showMessage(i18n.$t("messages.sucsses_message"), 3000);
//                        removeSlides = [];
//                        uploadFiles = [];
//                        GetFiles();
//                    };
//                choice.error = function (err) { alert(err.statusText); };
//                $.ajax(choice);
//                event.preventDefault();
//            } else {

//            }
//        });
//        $("#Uploader").unbind('change').bind('change', function () {
//            var reader = new FileReader();
//            reader.onload = function (e) {
//                var container = $(".Images");
//                var fn = fileName.replace('.', '');
//                var imageItem = "<div class='col-sm-2'  serverId='div" + fn + "'>  <div> <button serverId='" + fn + "' class='btn  btn-xs btn-danger'><span class='glyphicon glyphicon-remove'></span><span>" + i18n.$t("remove") + "</span> </button> <fieldset> <legend style='font-size:14px;'>" + fileName
//                    + "</legend>  <img class='ImageItem col-sm-12' src='" + e.target.result + "' /> </fieldset> </div></br> <div class='form-group col-sm-12 '> <div class='col-sm-6' style='padding 2px;  padding-right 0px; text-align center;'> <label  class='control-label' style='text-align :center;font-size:12px'>" + i18n.$t("slider_managment.image_displaying") + "</label></div>  " +
//                    "  <select  serverId='txt" + fn + "' onkeypress='return event.charCode >= 48 && event.charCode <= 57'   style=' width: 40px;margin-top:7px' ><option ></option> <option value='1' >1</option>  <option value='2'>2</option> <option value='3'>3</option> <option value='4'>4</option> </select> </div> </div>";

//                container.append(imageItem);
//                $("button[serverId=" + fn + "]").unbind('click').bind('click', function (e) {
//                    e.preventDefault();
//                    var i = uploadFiles.indexOf(this);
//                    uploadFiles.splice(i, 1);
//                    var btn = this;
//                    utils.confirm(i18n.$t("messages.delete_confirm_massage"), {
//                        yes: function () {
//                            $("div[serverId=div" + $(btn).attr('serverid') + "]").remove();
//                            $("#Uploader").val('');
//                        },
//                        no: function () { return false; }
//                    });
//                });

//            }
//            if (this.files != null && this.files[0] != null) {
//                if (this.files[0].size < 80000) {
//                    var isExists = false;
//                    fileName = this.files[0].name;
//                    $(uploadFiles).each(function () {
//                        if (this.name == fileName)
//                            isExists = true;
//                    });
//                    $(slides).each(function () {
//                        var isDeleted = false;
//                        var sid = '';
//                        if (this.Name == fileName) {
//                            sid = this.Id;

//                            $(removeSlides).each(function () {
//                                if (this == sid)
//                                    isDeleted = true
//                            });
//                            if (isDeleted == false)
//                                isExists = true;
//                        }
//                    });
//                    if (isExists == false) {
//                        reader.readAsDataURL(this.files[0]);
//                        index = uploadFiles.length;
//                        uploadFiles[index] = this.files[0];
//                    }
//                    else {
//                        var message = i18n.$t("slider_managment.same_name_massage");
//                        noty({
//                            modal: true,
//                            text: message,
//                            type: 'alert',
//                            layout: 'center',
//                            closeWith: ['button']

//                        });
//                    }
//                }
//                else {
//                    var message = i18n.$t("slider_managment.image_is_too_large");
//                    noty({
//                        modal: true,
//                        text: message,
//                        type: 'alert',
//                        layout: 'center',
//                        closeWith: ['button']

//                    });
//                }
//                $("#Uploader").val('');
//            }
//        });
//    }
//    var AddSlides = function () {
//        var container = $(".Images");
//        for (var i = 0; i < slides.length; i++) {
//            container.append(createSlide(slides[i].Name, slides[i].Id));
//            $("[serverid=txt" + slides[i].Id + "]").val(slides[i].level);
//            $("button[serverId=" + slides[i].Id + "]").unbind('click').bind('click', function (e) {
//                var deleteBtn = this;
//                utils.confirm(i18n.$t("messages.delete_confirm_massage"), {
//                    yes: function () {
//                        e.preventDefault();
//                        removeSlides.push($(deleteBtn).attr('serverid'));
//                        $("div[serverId=div" + $(deleteBtn).attr('serverid') + "]").remove();
//                    },
//                    no: function () { return false; }
//                });
//            });
//        }
//    }
//    var createSlide = function (name, id) {

//        var imageItem = "<div class='col-sm-2' serverId='div" + id + "'  >  <div> <button serverId='" + id + "' class='btn btn-xs btn-danger'><span class='glyphicon glyphicon-remove'></span><span>" + i18n.$t("remove") + "</span> </button> <fieldset> <legend style='font-size:14px;'>" + name
//            + "</legend>  <img class='ImageItem col-sm-12' src='api/file/getfile?imageid=" + id + "' /> </fieldset> </br> </div>" +
//            " <div class='form-group col-sm-12 '> <div class='col-sm-6' style='padding 2px;  padding-right 0px; text-align center;'> <label  class='control-label' style='text-align :center'>" + i18n.$t("slider_managment.image_displaying") + "</label></div>" +
//            " <select  serverId='txt" + id + "' onkeypress='return event.charCode >= 48 && event.charCode <= 57'   style=' width: 40px;margin-top:7px' ><option ></option> <option value='1'>1</option><option value='2'>2</option><option value='3'>3</option><option value='4'>4</option></select></div> "
//            + " </div>";
//        return imageItem;
//    }

//    var activate = function (params) {
//        console.log("vm.Slider.js");
//        config.currentViewCanLeave(canLeave);
//        GetFiles(params);
//    };

//    return {
//        activate: activate
//    }
//})();

