(function ($) {
    let errorMessage = 'خطا در دریافت اطلاعات!';
    $.widget("ui.rayautocomplete", {
        options: {selectParams: null, previewMode: false, /* bpmsAppForm from rayControl */},
        _create: function () {
            let self = this,
                select = this.element.hide(),
                selected = select.children(":selected"),
                value = selected.val() ? selected.text() : "",
                delay = select.attr("delay") ? select.attr("delay") : 200,
                maxitems = select.attr("maxitems") ? parseInt(select.attr("maxitems")) : 20,
                basemaxitems = select.attr("maxitems") ? parseInt(select.attr("maxitems")) : 20,
                minlength = select.attr("minlength") ? select.attr("minlength") : 0,
                postback = select.attr("postback") == "true" ? true : false,
                asyncBinding = select.attr("asyncbinding") == "true" ? true : false,
                tabIndex = select.attr("tabindex") ? select.attr("tabindex") : 0,
                browserAutoCompelete = select.attr("autocomplete") ? select.attr("autocomplete") : "off",
                //pageInstanceId = select.attr('pInstanceId'),
                id = select.attr("serverId");
            let box = $("<div/>");
            let listBox = $("<div class='jquery-ui-scope'/>");
            $('body').append(listBox);
            if (select.css("position") == "absolute") {
                box.attr("style", select.attr("style"));
                box.css("display", "inline");
            }
            box.attr('id', "box_" + id);
            box.css('white-space', 'nowrap');
            select.removeAttr("onchange");
            box.insertAfter(select);
            let disabled = select.attr("disabled") || this.option('previewMode');
            select.removeAttr('disabled');
            let input = this.input = $("<input>").css("margin", "0")
                .appendTo(box)
                .val(value).height("20px")
                .autocomplete({
                    delay: delay,
                    appendTo: this.element.next(),
                    minLength: minlength,
                    disabled: disabled,
                    position: {
                        my: 'right top',
                        at: 'right bottom',
                        collision: 'none'
                    },
                    source: function (request, response) {
                        let term = request.term;

                        if (asyncBinding) {
                            // var requestHeaders = [];
                            // requestHeaders.push({ Key: "pageInstanceId", Value: self.options.pageInstanceId });
                            let postData = {
                                keywords: term,
                                controlId: id,
                                pageInstance: self.options.pageInstanceId,
                                maxResults: maxitems,
                                isSortForced: false
                            };
                            utils.callWebAPI("api/control/getitems", postData, function (data) {
                                    if (maxitems > 0 && data.d.length > maxitems)
                                        data.d[maxitems] = {Name: "", Value: "!#mopts"};
                                    response($.map(data.d, function (item) {
                                        let result;
                                        if (item.Value == '!#mopts') {
                                            result = {
                                                label: "<b>" + i18n.$t("delegation_management.more_item") + "</b>",
                                                value: "",
                                                id: item.Value,
                                                option: this
                                            }
                                        } else {
                                            let label = item.Name,
                                                terms = term.split(' ');
                                            for (let i = 0; i < terms.length; i++) {
                                                label = label.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(terms[i]) + ")(?![^<>]*>)(?![^&;]+;)", ""), "<strong>$1</strong>")
                                            }
                                            result = {
                                                label: label,
                                                value: item.Name,
                                                id: item.Value,
                                                option: this
                                            }
                                        }
                                        return result;
                                    }));
                                },
                                function (a, b, c) {
                                    utils.showAjaxError(a, b, c, id);
                                });
                        } else {
                            let matcher = new RegExp($.ui.autocomplete.escapeRegex(term), "i");
                            response(select.children("option").map(function () {
                                let text = $(this).text();
                                if (typeof this.value != "undefined" && (!term || matcher.test(text))) {
                                    let label = text;
                                    if (term) {
                                        let terms = term.split(' ');
                                        for (let i = 0; i < terms.length; i++)
                                            label = label.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(terms[i]) + ")(?![^<>]*>)(?![^&;]+;)", "gi"), "<strong>$1</strong>")
                                    }

                                    return {
                                        label: label,
                                        value: text,
                                        option: this
                                    };
                                }
                            }));
                        }
                    },

                    select: function (event, ui) {
                        ui.item.option.selected = true;
                        if (asyncBinding) {
                            //if (ui.item.id === '!#mopts') {
                            //    maxitems += basemaxitems;
                            //    self.input.autocomplete('search', input.val());
                            //    self.input.autocomplete('show');
                            //    return false;
                            //}
                            select.html($("<option value='" + ui.item.id + "' selected='selected'>" + ui.item.label + "</option>"));
                        }
                        self._trigger("selected", event, {
                            item: ui.item
                        });
                    },
                    change: function (event, ui) {
                        if (!ui.item) {
                            let matcher = new RegExp("^" + $.ui.autocomplete.escapeRegex($(this).val()) + "$", "i"), valid = false;
                            select.children("option").each(function () {
                                if ($(this).text().match(matcher)) {
                                    this.selected = valid = true;
                                    return false;
                                }
                                return true;
                            });
                            if (!valid) {
                                $(this).val("");
                                if (asyncBinding)
                                    select.empty();
                                input.data("uiAutocomplete").term = "";
                                return false;
                            }
                        }
                        return true;
                    }
                })
                .addClass("ui-widget ui-corner-right");
            //.blur(function () {
            //    $(this).autocomplete('close');
            //});


            $(input).attr("placeholder", $(select).attr("placeholder"));
            $(input).attr("autocomplete", browserAutoCompelete);
            $(input).attr("name", id);

            input.data("uiAutocomplete")._renderItem = function (ul, item) {
                return item.id == '!#mopts' ?
                    $('<li>' + item.label + '</li>')
                        .click(function (e) {
                            e.stopImmediatePropagation();
                            maxitems += basemaxitems;
                            //self.options.maxItems += self.options.baseMaxItems;
                            self.input.autocomplete("search", self.input.val());
                            return false;
                        }).appendTo(ul) :
                    $("<li></li>")
                        .data("item.autocomplete", item)
                        .append("<a>" + item.label + "</a>")
                        .appendTo(ul);
            };
            if (!this.option('selectParams')) {
                this.button = $("<button type='button'>&nbsp;</button>")
                    .attr("tabIndex", -1)
                    .attr("title", i18n.$t("delegation_management.all_item"))
                    .insertAfter(input)
                    .button({
                        icons: {
                            primary: "ui-icon-triangle-1-s"
                        },
                        text: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("autocompletebutton ui-corner-left ui-button-icon")
                    .click(function () {
                        if (input.autocomplete("widget").is(":visible")) {
                            input.autocomplete("close");
                            return;
                        }
                        //$(this).blur();
                        input.autocomplete("search", minlength > 0 ? input.val().substring(0, minlength) : "");
                        $(input).focus();
                    });
            } else {
                let $this = this;
                this.button = $("<button type='button'>&nbsp;</button>")
                    .attr("tabIndex", -1)
                    .attr("title", "انتخاب از لیست")
                    .insertAfter(input)
                    .button({
                        icons: {
                            primary: "ui-icon-newwin"
                        },
                        text: false
                    })
                    .removeClass("ui-corner-all")
                    .addClass("autocompletebutton ui-corner-left ui-button-icon")
                    .click(function () {
                        let params = {nested: JSON.parse($this.option('selectParams'))};
                        params.parentBpmsAppForm = $this.option('bpmsAppForm');
                        params.parentBpmsAppForm.saveFormObject().done(function () {
                            $('<div id="modalDialog" />').bpmsAppForm(params);
                        }).fail(function (e, status, thrownError) {
                            utils.showAjaxError(e, status, thrownError, null, false);
                        });
                        return false;
                    });
            }
            input.width(parseInt(select.width()) - parseInt(this.button.css('width')));
            if (tabIndex > 0)
                input.attr("tabindex", tabIndex);
            if (disabled) {
                this.input.attr("disabled", "disabled");
                this.button.attr("disabled", "disabled");
            }
            select.unbind('selectItem').bind('selectItem', function (e, selectedValue) {
                if (selectedValue) {
                    let requestHeaders = new Array();
                    requestHeaders.push({Key: "pageInstanceId", Value: pageInstanceId});
                    utils.callWebAPI("api/control/getitem", {
                        selectedValue: selectedValue,
                        controlId: $(this).attr('serverid'),
                        pageInstance: pageInstanceId
                    }, function (rslt) {
                        if (rslt.d) {
                            if (asyncBinding) select.empty().append($('<option value="' + selectedValue + '" selected="selected">' + rslt.d.Name + '</option>'));
                            else {
                                select.val(selectedValue);
                                if (select.val() != selectedValue)
                                    select.append($('<option value="' + selectedValue + '" selected="selected">' + rslt.d.Name + '</option>'));
                                select.val(selectedValue);
                            }
                            input.val(rslt.d.Name);
                            input.focus();
                        }
                    }, function () {
                        alert(errorMessage);
                    });
                } else {
                    if (asyncBinding)
                        select.empty();
                    else select.val("");
                    input.val("");
                    input.focus();
                    //if (postback)
                    //    window.UpdateForm(id);
                }
            });
        },

        destroy: function () {
            this.input.remove();
            this.button.remove();
            this.element.show();
            $.Widget.prototype.destroy.call(this);
        }
    });
})(jQuery);
