import HomeMenu from '../homemenu/homeMenu.vue';
import Slider from '../slider/slider.vue';
import News from '../news/news.vue';
import Announcement from '../announcement/announcement.vue';
import {router} from "../../app.router";

export default {
    components: {
        HomeMenu,
        Slider,
        News,
        Announcement
    },
    mounted() {
        if (payload.el == 'Private' && payload.isPlugin === true && false)
             utils.callWebAPI("api/tasks/getfirst", {isCheckStaffs: false}, (data) => {
                 if (data)
                     router.push({
                         name: 'tasksGrid',
                         params: {
                             folderId: data.Id,
                             folderName: data.Text + " - " + data.Text
                         }
                     });
             });
    },
}
