var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkBannerItemEmpty()
    ? _c(
        "div",
        {
          staticClass: "banner-container pull-right",
          style: { backgroundImage: _vm.bannerStyles },
        },
        [
          !_vm.isRightBanner && !_vm.isLeftBanner && !_vm.isCenterBanner
            ? _c("img", {
                staticClass: "pull-left",
                staticStyle: { visibility: "hidden" },
                attrs: {
                  src:
                    "api/file/getfile?imageid=" +
                    _vm.bannerItems.BackgroundImageUrl,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isRightBanner
            ? _c("img", {
                staticClass: "pull-left visible-lg visible-md",
                attrs: {
                  src:
                    "api/file/getfile?imageid=" + _vm.bannerItems.RightImageUrl,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLeftBanner
            ? _c("img", {
                staticClass: "pull-right visible-lg visible-md",
                attrs: {
                  src:
                    "api/file/getfile?imageid=" + _vm.bannerItems.LeftImageUrl,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isCenterBanner
            ? _c("img", {
                staticClass: "pull-center img-responsive",
                attrs: {
                  src:
                    "api/file/getfile?imageid=" +
                    _vm.bannerItems.CenterImageUrl,
                },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }