var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c(
        "newForm",
        {
          class: [_vm.direction ? "k-rtl" : ""],
          attrs: {
            useTitle: "true",
            title: _vm.$t("logs_management.user_log"),
          },
        },
        [
          _c(
            "DxDataGrid",
            {
              ref: _vm.dataGridRefKey,
              attrs: {
                id: "gridContainer",
                "show-borders": true,
                "data-source": _vm.dataSource,
                "remote-operations": true,
                "column-auto-width": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
              },
            },
            [
              _c("DxColumn", {
                attrs: {
                  "data-field": "Id",
                  "sort-index": 1,
                  "sort-order": "desc",
                  width: 60,
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "User",
                  caption: _vm.$t("logs_management.user"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "LoginDateTime",
                  "data-type": "datetime",
                  format: _vm.datetimeFormat,
                  caption: _vm.$t("logs_management.login_datetime"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "LogoutDateTime",
                  "data-type": "datetime",
                  format: _vm.datetimeFormat,
                  caption: _vm.$t("logs_management.logout_datetime"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "ServerName",
                  caption: _vm.$t("logs_management.server_name"),
                },
              }),
              _vm._v(" "),
              _c("DxPaging", { attrs: { "page-size": 20 } }),
              _vm._v(" "),
              _c("DxPager", {
                attrs: {
                  "show-page-size-selector": true,
                  "allowed-page-sizes": [15, 20, 50],
                  "show-navigation-buttons": true,
                  "show-info": true,
                },
              }),
              _vm._v(" "),
              _c("DxFilterRow", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxHeaderFilter", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGroupPanel", { attrs: { visible: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }