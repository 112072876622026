var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    { attrs: { title: _vm.messages.home_ui_setting, className: "col-xs-12" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("p", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.messages.description) +
                "\n                "
            ),
          ]),
          _c("hr"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-md-3",
            staticStyle: {
              height: "calc(100vh - 240px)",
              "overflow-y": "auto",
            },
          },
          [
            _c("label", { staticClass: "radio" }, [
              _c("strong", [_vm._v(_vm._s(_vm.messages.items_menu))]),
            ]),
            _vm._v(" "),
            _c("tree", {
              ref: "hmTree",
              attrs: {
                hasContextMenu: "false",
                data: _vm.dataTree,
                template: _vm.treeTemp,
                nodeSelect: _vm.nodeSelect,
                preserveExpandStatus: "false",
                expandUniqueId: "homeManagementTree",
                expandRootItem: _vm.expandRootItem,
                enableSearch: true,
              },
            }),
            _vm._v(" "),
            _c(
              "script",
              {
                attrs: {
                  id: "homeManagementTreeViewTemplate",
                  type: "text/x-kendo-template",
                },
              },
              [
                _vm._v(
                  '\n                <i class="fal fa-lg fa-cube"></i>\n                <span title="#=item.ToolTip#">#=item.Text#</span>\n                #if(item.Enabled){#<i class="fad fa-check-square"></i>#}#\n                #if(item.Root){#<i class="fad fa-home-alt"></i>#}#\n                #if(item.IconType==1){#<i class="fad fa-image"></i>#}#\n                #if(item.IconType==2){#<i class="fad fa-flag"></i>#}#\n                #if(item.TopMenu){#<i class="fad fa-eye-slash"></i>#}#\n            '
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedItem,
                expression: "selectedItem",
              },
            ],
            staticClass: "col-md-9",
            staticStyle: {
              height: "calc(100vh - 240px)",
              "overflow-y": "auto",
              padding: "20px",
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "radio" }, [
                _c("strong", [_vm._v(_vm._s(_vm.messages.menu_setting))]),
              ]),
            ]),
            _vm._v(" "),
            _c("fieldset", [
              _c(
                "div",
                {
                  staticClass: "row form-group",
                  class: { "dx-rtl": _vm.isRTL },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DxCheckBox", {
                        attrs: {
                          value: _vm.enabled,
                          text: _vm.messages.show_menu,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.enabled = $event
                          },
                          valueChanged: _vm.showMenuChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DxCheckBox", {
                        attrs: {
                          value: _vm.isMenu,
                          text: _vm.messages.is_menu,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.isMenu = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("fieldset", { attrs: { disabled: !_vm.enabled } }, [
              _c(
                "div",
                {
                  staticClass: "row form-group",
                  class: { "dx-rtl": _vm.isRTL },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DxCheckBox", {
                        attrs: {
                          value: _vm.showInFirstLevel,
                          text: _vm.messages.first_level,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.showInFirstLevel = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("DxCheckBox", {
                        attrs: {
                          value: _vm.removeFromTopMenu,
                          text: _vm.messages.not_show_top,
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.removeFromTopMenu = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isCategory,
                          expression: "isCategory",
                        },
                      ],
                      staticClass: "dx-fieldset",
                    },
                    [
                      _c("div", { staticClass: "dx-field" }, [
                        _c("div", { staticClass: "dx-field-label" }, [
                          _vm._v(_vm._s(_vm.messages.catgeory_order)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dx-field-value" },
                          [
                            _c("DxNumberBox", {
                              attrs: { "rtl-enabled": _vm.isRTL },
                              on: { valueChanged: _vm.catgeoryOrderChange },
                              model: {
                                value: _vm.OrderIndex,
                                callback: function ($$v) {
                                  _vm.OrderIndex = $$v
                                },
                                expression: "OrderIndex",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dx-fieldset" }, [
                    _c("div", { staticClass: "dx-field" }, [
                      _c("div", { staticClass: "dx-field-label" }, [
                        _vm._v(_vm._s(_vm.messages.display_name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dx-field-value" },
                        [
                          _c("DxTextBox", {
                            attrs: { "rtl-enabled": _vm.isRTL },
                            model: {
                              value: _vm.displayText,
                              callback: function ($$v) {
                                _vm.displayText = $$v
                              },
                              expression: "displayText",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.isFr
                    ? _c("div", { staticClass: "dx-fieldset" }, [
                        _c("div", { staticClass: "dx-field" }, [
                          _c("div", { staticClass: "dx-field-label" }, [
                            _vm._v(_vm._s(_vm.messages.display_name_fr)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dx-field-value" },
                            [
                              _c("DxTextBox", {
                                attrs: { "rtl-enabled": _vm.isRTL },
                                model: {
                                  value: _vm.displayTextFr,
                                  callback: function ($$v) {
                                    _vm.displayTextFr = $$v
                                  },
                                  expression: "displayTextFr",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isDe
                    ? _c("div", { staticClass: "dx-fieldset" }, [
                        _c("div", { staticClass: "dx-field" }, [
                          _c("div", { staticClass: "dx-field-label" }, [
                            _vm._v(_vm._s(_vm.messages.display_name_de)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dx-field-value" },
                            [
                              _c("DxTextBox", {
                                attrs: { "rtl-enabled": _vm.isRTL },
                                model: {
                                  value: _vm.displayTextDe,
                                  callback: function ($$v) {
                                    _vm.displayTextDe = $$v
                                  },
                                  expression: "displayTextDe",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAr
                    ? _c("div", { staticClass: "dx-fieldset" }, [
                        _c("div", { staticClass: "dx-field" }, [
                          _c("div", { staticClass: "dx-field-label" }, [
                            _vm._v(_vm._s(_vm.messages.display_name_ar)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dx-field-value" },
                            [
                              _c("DxTextBox", {
                                attrs: { "rtl-enabled": _vm.isRTL },
                                model: {
                                  value: _vm.displayTextAr,
                                  callback: function ($$v) {
                                    _vm.displayTextAr = $$v
                                  },
                                  expression: "displayTextAr",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSv
                    ? _c("div", { staticClass: "dx-fieldset" }, [
                        _c("div", { staticClass: "dx-field" }, [
                          _c("div", { staticClass: "dx-field-label" }, [
                            _vm._v(_vm._s(_vm.messages.display_name_sv)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dx-field-value" },
                            [
                              _c("DxTextBox", {
                                attrs: { "rtl-enabled": _vm.isRTL },
                                model: {
                                  value: _vm.displayTextSv,
                                  callback: function ($$v) {
                                    _vm.displayTextSv = $$v
                                  },
                                  expression: "displayTextSv",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isHe
                    ? _c("div", { staticClass: "dx-fieldset" }, [
                        _c("div", { staticClass: "dx-field" }, [
                          _c("div", { staticClass: "dx-field-label" }, [
                            _vm._v(_vm._s(_vm.messages.display_name_he)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dx-field-value" },
                            [
                              _c("DxTextBox", {
                                attrs: { "rtl-enabled": _vm.isRTL },
                                model: {
                                  value: _vm.displayTextHe,
                                  callback: function ($$v) {
                                    _vm.displayTextHe = $$v
                                  },
                                  expression: "displayTextHe",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row form-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.messages.icon_view))]),
                    _vm._v(" "),
                    _c("DxRadioGroup", {
                      attrs: {
                        "data-source": _vm.IconShapeDS,
                        "value-expr": "value",
                        "display-expr": "text",
                        value: _vm.displayIcon,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.displayIcon = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "dx-fieldset" }, [
                    _c("div", { staticClass: "dx-field" }, [
                      _c("div", { staticClass: "dx-field-label dxw50" }, [
                        _vm._v(_vm._s(_vm.messages.icon_width)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dx-field-value dxw50" },
                        [
                          _c("DxNumberBox", {
                            attrs: { "rtl-enabled": _vm.isRTL },
                            on: { valueChanged: _vm.iconWidthChange },
                            model: {
                              value: _vm.iconWidth,
                              callback: function ($$v) {
                                _vm.iconWidth = $$v
                              },
                              expression: "iconWidth",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dx-fieldset" }, [
                    _c("div", { staticClass: "dx-field" }, [
                      _c("div", { staticClass: "dx-field-label dxw50" }, [
                        _vm._v(_vm._s(_vm.messages.icon_height)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dx-field-value dxw50" },
                        [
                          _c("DxNumberBox", {
                            attrs: {
                              "rtl-enabled": _vm.isRTL,
                              disabled: _vm.displayIcon != 2,
                            },
                            on: { valueChanged: _vm.iconHeightChange },
                            model: {
                              value: _vm.iconHeight,
                              callback: function ($$v) {
                                _vm.iconHeight = $$v
                              },
                              expression: "iconHeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-2" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.messages.text_location))]),
                    _vm._v(" "),
                    _c("DxRadioGroup", {
                      attrs: {
                        "data-source": _vm.TextPositionDS,
                        "value-expr": "value",
                        "display-expr": "text",
                        value: _vm.textPosition,
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.textPosition = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c("div", { staticClass: "dx-fieldset" }, [
                    _c("div", { staticClass: "dx-field" }, [
                      _c("div", { staticClass: "dx-field-label dxw50" }, [
                        _vm._v(_vm._s(_vm.messages.text_color)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dx-field-value dxw50" },
                        [
                          _c("DxColorBox", {
                            attrs: { "rtl-enabled": _vm.isRTL },
                            model: {
                              value: _vm.textColor,
                              callback: function ($$v) {
                                _vm.textColor = $$v
                              },
                              expression: "textColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dx-fieldset" }, [
                    _c("div", { staticClass: "dx-field" }, [
                      _c("div", { staticClass: "dx-field-label dxw50" }, [
                        _vm._v(_vm._s(_vm.messages.float_text_color)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dx-field-value dxw50" },
                        [
                          _c("DxColorBox", {
                            attrs: { "rtl-enabled": _vm.isRTL },
                            model: {
                              value: _vm.textColorHover,
                              callback: function ($$v) {
                                _vm.textColorHover = $$v
                              },
                              expression: "textColorHover",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row form-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-5" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { for: "fileDisplayIcon" },
                      },
                      [
                        _c("i", { staticClass: "fad fa-lg fa-upload" }),
                        _vm._v(" " + _vm._s(_vm.messages.home_menu_icon)),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: { type: "file", id: "fileDisplayIcon" },
                      on: { change: _vm.fileUploadChange },
                    }),
                    _vm._v(" "),
                    _c("DxButton", {
                      attrs: {
                        text: _vm.messages.delete_image,
                        icon: "fad fa-trash",
                        type: "default",
                        "styling-mode": "contained",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removePicture()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.messages.select_file)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "dx-fieldset" }, [
                      _c("div", { staticClass: "dx-field" }, [
                        _c("div", { staticClass: "dx-field-label dxw50" }, [
                          _vm._v(_vm._s(_vm.messages.fa_icon)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dx-field-value dxw50" },
                          [
                            _c("DxTextBox", {
                              model: {
                                value: _vm.FAIcon,
                                callback: function ($$v) {
                                  _vm.FAIcon = $$v
                                },
                                expression: "FAIcon",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "dx-fieldset" }, [
                      _c("div", { staticClass: "dx-field" }, [
                        _c("div", { staticClass: "dx-field-label dxw50" }, [
                          _vm._v(_vm._s(_vm.messages.fa_icon_color)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dx-field-value dxw50" },
                          [
                            _c("DxColorBox", {
                              attrs: { "rtl-enabled": _vm.isRTL },
                              model: {
                                value: _vm.FAIconColor,
                                callback: function ($$v) {
                                  _vm.FAIconColor = $$v
                                },
                                expression: "FAIconColor",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 admin" }, [
                  _c("a", { staticClass: "menu-item" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.textPosition == 0,
                            expression: "textPosition==0",
                          },
                        ],
                        staticClass: "menu-text",
                        attrs: { id: "upper-text" },
                      },
                      [_vm._v(_vm._s(_vm.displayText))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "menu-item-shape",
                        class:
                          _vm.displayIcon == 0 ? "img-circle" : "img-rounded",
                        style: [
                          _vm.imageData !== null
                            ? { backgroundImage: "url(" + _vm.imageData + ")" }
                            : { backgroundImage: "" },
                        ],
                        attrs: { id: "thumbView" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.textPosition == 1,
                                expression: "textPosition==1",
                              },
                            ],
                            staticClass: "menu-text menu-text-middle",
                            attrs: { id: "middle-text" },
                          },
                          [_vm._v(_vm._s(_vm.displayText))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.textPosition == 2,
                            expression: "textPosition==2",
                          },
                        ],
                        staticClass: "menu-text",
                        attrs: { id: "bottom-text" },
                      },
                      [_vm._v(_vm._s(_vm.displayText))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("DxButton", {
                      attrs: {
                        width: 200,
                        text: _vm.messages.save,
                        icon: "fad fa-lg fa-save",
                        type: "default",
                        "styling-mode": "contained",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("b", [_vm._v(_vm._s(_vm.messages.attention))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.messages.page_refresh) +
                        "\n                    "
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }