; (function ($, undefined) {
    $.widget('ui.baseautocomplete', {
        options: {
            // url, function or json array
            source: [], // [id,label,iconIndex]
            iconPathList: [],
            FAiconList: [],
            multiSelect: true,
            minLength: 0,
            maxItems: 20,
            // async(true) get all data, async(false) get data chunk chunk
            async: true,
            // callback
            select: null,
            cache: {},
            pageSize: 20,
            pageCounter: 0,
            pageEndReached: false
        },

        _create: function () {
            this.options.baseMaxItems = this.options.maxItems;
            this.wrapper = $('<div class="input-group"></div>')
                .insertAfter(this.element);
            this._createInput();
            this._createButton();

            if (this.options.multiSelect) {
                this._createSelectedListbox();
            }

            this.element.hide();
            this._sourceChanged();
        },

        _destroy: function () {
            this.element.show();
            this.input.remove();
            this.button.remove();
        },

        _setOption: function (key, value) {
            this._super(key, value);
            if (key == 'source')
                this._sourceChanged();
        },

        _createInput: function () {
            var self = this;

            this.input = $('<input>').attr('id', this.element.attr('id') + '_input')
                .addClass('autocompleteinput form-control')
                //.css('width', parseInt(this.element.css('width')) - 28)
                //.css('background', 'url("dist/loading.gif") no-repeat scroll left center transparent')
                //.css('height', 20)
                .prop("disabled", true)
                .appendTo(this.wrapper)
                .autocomplete({
                    async: self.options.async,
                    //source: badan set mishavad
                    select: function(event, ui) {
                        var selectedItem = ui.item;
                        if (!self.options.multiSelect) {
                            self._addItemToList.call(self, selectedItem);
                            return false;
                        }
                        if (self._itemNotSelected.call(self, selectedItem)) {
                            self._addItemToList.call(self, selectedItem);
                            $(this).val('');
                        }

                        return false;
                    },
                    position: {
                        my: 'right top',
                        at: 'right bottom',
                        collision: 'none'
                    },
                    minLength: self.options.minLength,
                });


            $(this.input).attr("placeholder", $(this.element).attr("placeholder"));
                //.blur(function () {
                //    $(this).autocomplete('close');
                //});
            //.tooltip({
            //    tooltipClass: "ui-state-highlight"
            //});
            this.input.data("uiAutocomplete")._renderMenu = function (ul, items) {
                var that = this;

                //console.log("start render menu", items);
                self.options.pageCounter = self.options.pageSize;
                self.options.pageEndReached = false;

                $.each(items, function (index, item) {
                    that._renderItemData(ul, item);
                });

                if (self.options.pageEndReached)
                    $("<li>")
                        .attr("data-value", -1)
                        .append("... " + i18n.$t('more_items'))
                        .click(function (e) {
                            e.stopImmediatePropagation();
                            self.input.autocomplete("search", self.input.val());
                            return false;
                        })
                        .appendTo(ul);

                $(ul).find("li:odd").addClass("odd");
            };

            this.input.data("uiAutocomplete")._renderItem = function (ul, item) {
                let liitem = self.options.cache[item.id];
                if (liitem == undefined) {
                    //console.log("not in cache", self.options.pageCounter, self.options.pageEndReached, item);
                    if (self.options.pageCounter == 0) {
                        if (self.options.pageEndReached)
                            return $("<li></li>");

                        self.options.pageEndReached = true;
                        //return $("<li>")
                        //    .attr("data-value", -1)
                        //    .append("... " + i18n.$t('more_items'))
                        //    .click(function(e) {
                        //        e.stopImmediatePropagation();
                        //        self.input.autocomplete("search", self.input.val());
                        //        return false;
                        //    })
                        //    .appendTo(ul);
                        return $("<li></li>");
                    } else {
                        var img;
                        if (self.options.FAiconList.length > 0 && self.options.FAiconList[item.iconIndex])
                            img = "<i class='fa-fw " + self.options.FAiconList[item.iconIndex] + "'></i> ";
                        else if (self.options.iconPathList.length > 0 && self._getIconNameFromList(item.iconIndex))
                            img = "<img src='" + self._getIconNameFromList(item.iconIndex) + "'></img> ";

                        liitem = item.id == '!#mopts'
                            ? $('<li>' + item.label + '</li>')
                            .click(function(e) {
                                e.stopImmediatePropagation();
                                self.options.maxItems += self.options.baseMaxItems;
                                self.input.autocomplete("search", self.input.val());
                                return false;
                            })
                            : $("<li></li>")
                            .data("ui.autocomplete.item", item)
                            .append($("<a>" + (img ? img : '') + item.label + "</a>"));

                        self.options.cache[item.id] = liitem;
                        self.options.pageCounter--;
                    }
                }
                liitem.appendTo(ul);
                return liitem;

                //return item.id == '!#mopts' ?
                //    $('<li>' + item.label + '</li>')
                //    .click(function (e) {
                //        e.stopImmediatePropagation();
                //        self.options.maxItems += self.options.baseMaxItems;
                //        self.input.autocomplete("search", self.input.val());
                //        return false;
                //    }).appendTo(ul) :
                //    $("<li></li>")
                //    .data("ui.autocomplete.item", item)
                //    .append($("<a>" + (img ? img : '') + item.label + "</a>"))
                //    .appendTo(ul);
            };

            this._on(this.input, {
                //autocompletesearch: function (event, ui) {
                //    this._trigger('search', event, ui);
                //},

                autocompleteselect: function (event, ui) {
                    this._trigger("select", event, { item: ui.item });
                },
                autocompletechange: "_removeIfInvalid"
            });
        },

        _createButton: function () {
            //console.log("BAC: _createButton");
            var self = this;
            var group = $('<span class="input-group-btn"></span>')
            var button = this.button = $('<button class="btn btn-default" type="button"><i class="fad fa-chevron-down"></i></button>')
                .attr("tabIndex", -1)
                .attr("title", i18n.$t("delegation_management.all_item"))
                .addClass("autocompletebutton")
                //.css('height', '20px')
                .mousedown(function () {
                    self.wasOpen = self.input.autocomplete("widget").is(":visible");
                })
                .click(function () {
                    if (self.wasOpen) {
                        self.input.autocomplete("close");
                        return;
                    }
                    $(this).blur();
                    self.input.autocomplete("search", self.input.val());
                    //self.input.focus();
                    self.wasOpen = true;
                    $(self.input).focus();
                })
                .appendTo(group);

                group.appendTo(this.wrapper)
        },

        _createSelectedListbox: function () {
            //console.log("BAC: _createSelectedListbox");
            this.selectedListbox = $('<ul>').attr('id', this.element.attr('id') + '_list')
                .addClass('ui-widget-content ui-corner-all autocompletelist col-sm-12')
                .css('height', parseInt(this.element.css('height')) - 30)
                .insertAfter(this.wrapper);
        },

        _sourceChanged: function () {
            //console.log("BAC: _sourceChanged");
            var sourceType = $.type(this.options.source),
                deferred = $.Deferred(),
                self = this;

            var source;
            if (sourceType === "string") { // source is url
                if (self.options.async)
                    deferred.resolve($.proxy(this._getSourceAsync, this));
                else
                    $.when(this._getSourceFromUrl(this.options.source))
                        .then(function (result) { deferred.resolve(result.d); },
                            function (jqXhr, textStatus, errorThrown) { deferred.fail(jqXhr, textStatus, errorThrown); });
            } else if (sourceType === "function") { // source is function request response
                deferred.resolve(this.options.source);
            } else if (sourceType === "array") { // source is array
                deferred.resolve(this.options.source);
            } else {
                // error
            }

            deferred.done(function (result) {
                self._createAutoComplete(result);
                self.input.prop("disabled", false);
            }).fail(function (jqXhr, textStatus, errorThrown) {
                alert(errorThrown);
            })
            .always(function () {
                self.input.css('background', '');
            });
        },

        _getSourceAsync: function (request, response) {
            //console.log("BAC: _getSourceAsync");
            var self = this, term = request.term,
                postData = { keywords: term, maxItems: self.options.maxItems };
            this._getSourceFromUrl(this.options.source, postData).done(function (data) {
                if (self.options.maxItems > 0 && data.d.length > self.options.maxItems)
                    data.d[self.options.maxItems] = { id: "", label: "!#mopts" };
                response($.map(data.d, function (item) {
                    var result;
                    if (item.label == '!#mopts') {
                        result = { label: "<b>{{$t('more_items')}}</b>", id: '!#mopts' };
                    } else {
                        var label = item.label,
                            terms = term.split(' ');
                        for (var i = 0; i < terms.length; i++) {
                            label = label.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + $.ui.autocomplete.escapeRegex(terms[i]) + ")(?![^<>]*>)(?![^&;]+;)", ""), "<strong>$1</strong>");
                        }
                        result = {
                            label: label,
                            value: item.label,
                            iconIndex: item.iconIndex,
                            id: item.id,
                        };
                    }
                    return result;
                }));
            }).fail(function (a, b, c) { utils.showAjaxError(a, b, c); });
        },

        _getSourceFromUrl: function (url, dataObject) {
            return $.ajax(
                {
                    type: "POST",
                    async: true,
                    url: url,
                    cached: false,
                    data: dataObject ? JSON.stringify(dataObject) : "{}",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                });
        },

        _getIconNameFromList: function (index) {
            return this.options.iconPathList[index];
        },

        _createAutoComplete: function (source) {
            this.options.pageCounter = this.options.pageSize;
            this.options.pageEndReached = false;
            this.input.autocomplete({
                source: source,
                appendTo: this.wrapper,
            });
        },

        _removeIfInvalid: function (event, ui) {
            if (ui.item) {
                this._trigger("change", event, { item: ui.item });
                return;
            }
            var value = this.input.val(),
                valueLowerCase = value.toLowerCase(),
                valid = false;
            var labels = this._getSuggestedLabel();
            for (var key in labels) {
                var item = labels[key];
                if (item.toLowerCase() === valueLowerCase || item === value) {
                    valid = true;
                    return false;
                }
            }
            if (valid) {
                return;
            }
            this.input.val("").removeData('item');
            //.attr("title", value + " didn't match any item")
            //.tooltip("open");
            //this._delay(function () {
            //    this.input.tooltip("close").attr("title", "");
            //}, 2500);
            this.input.data("ui-autocomplete").term = "";

        },

        _getSuggestedLabel: function () {
            var labels = [],
                ul = this.wrapper.find('ul').children();
            ul.each(function () {
                labels.push($(this).text());
            });

            return labels;
        },

        _itemNotSelected: function (item) {
            var list = this.getSelectedIds.call(this);
            var selected = false;
            $(list).each(function (key, value) {
                if (value === item.id) {
                    selected = true;
                    return false;
                }
            });

            return !selected;
        },

        setSelectedItems: function (items) {
            var self = this;
            if (!this.options.multiSelect) {
                self._addItemToList(items[0]);
                return;
            }

            this.selectedListbox.empty();
            items.forEach(function (item) {
                self._addItemToList(item);
            });
        },

        setEnabled: function (enabled) {
            this.input.removeAttr("disabled");
            this.button.removeAttr("disabled");

            if (!enabled) {
                this.input.attr("disabled", "disabled");
                this.button.attr("disabled", "disabled");
            }
        },

        getSelectedIds: function () {
            if (!this.options.multiSelect) {
                if ($(this.input).data('item'))
                    var id = $(this.input).data('item').id;

                return id ? id : null;
            }

            var ids = [];
            var liarray = $(this.selectedListbox).find('li');
            liarray.each(function () {
                ids.push($(this).data('item').id);
            });
            return ids;
        },
        getSelectedItems: function () {
            if (!this.options.multiSelect) {
                var item = $(this.input).data('item');

                return item ? item : null;
            }

            var items = [];
            var liarray = $(this.selectedListbox).find('li');
            liarray.each(function () {
                items.push($(this).data('item'));
            });
            return items;
        },
        _addItemToList: function (item) {
            if (!this.options.multiSelect) {
                $(this.input).val(item.label)
                    .data('item', item);
                return;
            }

            var removeListItem = $('<div/>').addClass("ui-button ui-icon ui-icon-closethick")
                .attr('title', i18n.$t("remove")).click(function () {
                    $(this).parent().remove();
                });
            var li = $('<li/>')
                .css('list-style', 'none').addClass('ui-state-default')
                .data('item', item)
                .hover(function () {
                    $(this).addClass('ui-state-hover');
                }, function () {
                    $(this).removeClass('ui-state-hover');
                });
            var img;
            if (this.options.FAiconList.length > 0 && this.options.FAiconList[item.iconIndex])
                img = "<i class='fa-fw " + this.options.FAiconList[item.iconIndex] + "'></i> ";
            else if (this.options.iconPathList.length > 0 && this._getIconNameFromList(item.iconIndex))
                img = "<img src='" + this._getIconNameFromList(item.iconIndex) + "'></img> ";
            li.append($("<a>" + (img ? img : '') + item.value + "</a>")); // not label because <strong>
            li.append(removeListItem);
            this.selectedListbox.append(li);
        },
        clearSelectedItems: function () {
            if (!this.options.multiSelect) {
                $(this.input).val('')
                    .data('item', null);
                return;
            }

            this.selectedListbox.empty();
        }
    });
})(jQuery);

$.extend($.ui.autocomplete, {
    escapeRegex: function (value) {
        return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
    },
    filter: function (array, term) {
        var matcher = new RegExp($.ui.autocomplete.escapeRegex(term), "i");
        return $.grep(array, function (value) {
            return matcher.test(value.label || value.value || value);
        });
    }
});
