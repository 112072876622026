var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-horizontal changePasswordForm",
      attrs: { action: "your-action" },
      on: {
        submit: function ($event) {
          return _vm.handleSubmit($event)
        },
      },
    },
    [
      _c("fieldset", [
        _c("div", [
          _c("div", { staticClass: "dx-fieldset" }, [
            _c("div", { staticClass: "dx-field" }, [
              _c("div", { staticClass: "dx-field-label" }, [
                _vm._v(_vm._s(_vm.$t("password_setting.current_password"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dx-field-value" },
                [
                  _c(
                    "DxTextBox",
                    {
                      ref: _vm.currentPasswordRefName,
                      attrs: {
                        id: "currentPassword",
                        "show-clear-button": true,
                        mode: "password",
                        valueChangeEvent: "keyup",
                        placeholder: _vm.$t(
                          "password_setting.current_password"
                        ),
                      },
                      model: {
                        value: _vm.CurrentPassword,
                        callback: function ($$v) {
                          _vm.CurrentPassword = $$v
                        },
                        expression: "CurrentPassword",
                      },
                    },
                    [
                      _c(
                        "DxValidator",
                        { attrs: { id: "currentPasswordValidator" } },
                        [
                          _c("DxRequiredRule", {
                            attrs: {
                              message: _vm.$t(
                                "password_setting.messages.currentPassword_is_empty"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dx-fieldset" }, [
            _c("div", { staticClass: "dx-field" }, [
              _c("div", { staticClass: "dx-field-label" }, [
                _vm._v(_vm._s(_vm.$t("password_setting.new_password"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dx-field-value" },
                [
                  _c(
                    "DxTextBox",
                    {
                      ref: _vm.newPasswordRefName,
                      attrs: {
                        id: "newPassword",
                        "show-clear-button": true,
                        mode: "password",
                        valueChangeEvent: "keyup",
                        placeholder: _vm.$t("password_setting.new_password"),
                      },
                      model: {
                        value: _vm.NewPassword,
                        callback: function ($$v) {
                          _vm.NewPassword = $$v
                        },
                        expression: "NewPassword",
                      },
                    },
                    [
                      _c(
                        "DxValidator",
                        { attrs: { id: "newPasswordValidator" } },
                        [
                          _c("DxRequiredRule", {
                            attrs: {
                              message: _vm.$t(
                                "password_setting.messages.newPassword_is_empty"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("DxCompareRule", {
                            attrs: {
                              "comparison-target":
                                _vm.passwordComparisonWithCurrent,
                              comparisonType: "!==",
                              message: _vm.$t(
                                "password_setting.messages.new_password_same_as_current"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dx-fieldset" }, [
            _c("div", { staticClass: "dx-field" }, [
              _c("div", { staticClass: "dx-field-label" }, [
                _vm._v(_vm._s(_vm.$t("password_setting.confirm_new_password"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dx-field-value" },
                [
                  _c(
                    "DxTextBox",
                    {
                      ref: _vm.confirmNewPasswordRefName,
                      attrs: {
                        id: "newConfirmPassword",
                        "show-clear-button": true,
                        mode: "password",
                        valueChangeEvent: "keyup",
                        placeholder: _vm.$t(
                          "password_setting.confirm_new_password"
                        ),
                      },
                      model: {
                        value: _vm.ConfirmNewPassword,
                        callback: function ($$v) {
                          _vm.ConfirmNewPassword = $$v
                        },
                        expression: "ConfirmNewPassword",
                      },
                    },
                    [
                      _c(
                        "DxValidator",
                        { attrs: { id: "newConfirmPasswordValidator" } },
                        [
                          _c("DxRequiredRule", {
                            attrs: {
                              message: _vm.$t(
                                "password_setting.messages.confirmNewPassword_is_empty"
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("DxCompareRule", {
                            attrs: {
                              "comparison-target":
                                _vm.passwordComparisonWithNew,
                              message: _vm.$t(
                                "password_setting.messages.passwords_do_not_match"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-danger" }, [
          _vm._v("\n            " + _vm._s(_vm.errorTxt) + "\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            { staticClass: "col-sm-6 col-sm-offset-6" },
            [
              _c("DxButton", {
                staticClass: "btn btn-primary btn-block",
                attrs: {
                  type: "success",
                  "use-submit-behavior": true,
                  id: "changePasswordSave",
                  text: _vm.$t("grid_control.save"),
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }