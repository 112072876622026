// Copyright 2010 William Malone (www.williammalone.com)
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export function RayDrawingCanvas(rayControlObj, element, rightToLeft, tooltip) {
    //required args
    var rayControl = rayControlObj;
    var canvasId = element;
    var isRightToLeft = rightToLeft;
    var jqueryCanvasId = '#' + canvasId;
    var clearId = "btnClear" + canvasId;
    var clearTooltip = tooltip;

    var canvasWidth;
    var canvasHeight;
    var clickX_simple = new Array();
    var clickY_simple = new Array();
    var clickDrag_simple = new Array();
    var paint_simple;
    var canvas_simple;
    var orginalCanvas;
    var orginalContext;
    var context_simple;
    var clear_simple;
    var enable;
    var canvasIsDirty = false;
    var timerIsOn = 0;
    var timer;
    var parentCanvas;
    var callFillCanvas = 1;
    var canvasJQuery = null;
    var autoUpdate;

    function init() {

        enable = true;
        autoUpdate = rayControl.options.controlState.Behavior.AutoUpdate;

        canvas_simple = document.getElementById(canvasId);

        canvas_simple.oncontextmenu = function (e) {
            e.preventDefault();
        };

        canvasJQuery = $(canvas_simple);
        canvasWidth = canvas_simple.width;
        canvasHeight = canvas_simple.height;
        //console.log("canvas size 1", canvas_simple.width, canvas_simple.height);
        parentCanvas = $('#parent' + canvasId);

        orginalCanvas = document.createElement('canvas');
        orginalContext = orginalCanvas.getContext("2d");
        orginalCanvas.width = canvas_simple.width;
        orginalCanvas.height = canvas_simple.height;

        clear_simple = document.createElement('button');
        clear_simple.setAttribute('id', clearId);
        clear_simple.setAttribute('title', clearTooltip);
        clear_simple.innerHTML = '<i class="fad fa-2x fa-eraser"></i>';
        clear_simple.classList.add("btn", "btn-primary", "btn-xs");
        clear_simple.style.position = 'absolute';

        clear_simple.style.top = canvas_simple.offsetTop + canvasHeight + 3 + 'px';

        if (isRightToLeft)
            clear_simple.style.left = canvas_simple.offsetLeft + 'px';
        else
            clear_simple.style.right = canvas_simple.offsetLeft + 'px';

        clear_simple.style.display = 'none';

        parentCanvas.append(clear_simple);
        parentCanvas.append("<br/>");

        context_simple = canvas_simple.getContext("2d");

        setTimeout(function() {
                $(window).trigger('resize');
            },
            500);

        $(window).resize(resizeCanvas);


        // Add mouse events
        // ----------------
        $(jqueryCanvasId).mousedown(function (e) {
            if (enable) {
                start.call(this, e.pageX, e.pageY);
            }
        });

        $(jqueryCanvasId).mousemove(function (e) {
            if (paint_simple && enable) {
                move.call(this, e.pageX, e.pageY);
            }
        });

        $(jqueryCanvasId).mouseup(function (e) {
            if (enable) {
                end();
            }
        });

        $(jqueryCanvasId).mouseleave(function (e) {
            if (enable) {
                paint_simple = false;
            }
        });

        // Add touch event listeners to canvas element
        // -------------------------------------------
        canvas_simple.addEventListener("touchstart", function (e) {
            if (enable) {

                var mouseX = (e.changedTouches ? e.changedTouches[0].pageX : e.pageX);
                var mouseY = (e.changedTouches ? e.changedTouches[0].pageY : e.pageY);

                start.call(this, mouseX, mouseY);
            }
        }, false);

        canvas_simple.addEventListener("touchmove", function (e) {
            if (enable && paint_simple) {

                var mouseX = (e.changedTouches ? e.changedTouches[0].pageX : e.pageX);
                var mouseY = (e.changedTouches ? e.changedTouches[0].pageY : e.pageY);

                move.call(this, mouseX, mouseY);

                e.preventDefault()
            }
        }, false);

        canvas_simple.addEventListener("touchend", function (e) {
            if (enable) {
                end();
            }
        }, false);

        canvas_simple.addEventListener("touchcancel", function (e) {
            if (enable) {
                paint_simple = false;
            }
        }, false);

        // Add event to clear button
        // -------------------------
        $('#' + clearId).mousedown(function (e) {
            if (enable && canvasIsDirty) {
                clearTimeout(timer);
                clickX_simple = new Array();
                clickY_simple = new Array();
                clickDrag_simple = new Array();

                clearCanvas_simple();
                orginalContext.clearRect(0, 0, orginalCanvas.width, orginalCanvas.height);

                canvasIsDirty = false;
                timerIsOn = false;

                rayControlSetValue();

                clear_simple.style.display = 'none';

                // Resize parent
                parentCanvas.height(canvasHeight + 40);
            } 
        });
    }

    function start(x, y) {

        paint_simple = true;
        var offset = $(this).parent().offset();
        var offsetL = offset.left + this.offsetLeft;
        var offsetT = offset.top + this.offsetTop;

        var dynamicX = x - offsetL;
        var dynamicY = y - offsetT;
        addClickSimple(dynamicX, dynamicY, false);
        redrawSimple();
        clear_simple.style.display = 'block';

        // Resize parent to show clear button
        parentCanvas.height(canvasHeight + clear_simple.clientHeight + 40);
    }

    function move(x, y) {
        var offset = $(this).parent().offset();
        var offsetL = offset.left + this.offsetLeft;
        var offsetT = this.offsetTop + offset.top;

        var dynamicX = x - offsetL;
        var dynamicY = y - offsetT;
        addClickSimple(dynamicX, dynamicY, true);
        redrawSimple();
    }

    function end() {

        canvasIsDirty = true;
        paint_simple = false;
        redrawSimple();

        orginalContext.clearRect(0, 0, orginalCanvas.width, orginalCanvas.height);
        orginalCanvas.width = canvas_simple.width;
        orginalCanvas.height = canvas_simple.height;
        orginalContext.drawImage(canvas_simple, 0, 0);
        
        if (autoUpdate) {

            if (timerIsOn) {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    setControlStateValue();
                }, 2000);
            } else {
                timerIsOn = 1;
                timer = setTimeout(function () {
                    setControlStateValue();
                }, 2000);
            }
        } else {
            setControlStateValue();
        }
    }

    function resizeCanvas() {

        if (!enable) return;

        var parentWidth = parentCanvas.width();

        if (isRightToLeft)
            clear_simple.style.left = canvas_simple.offsetLeft + 'px';
        else
            clear_simple.style.right = canvas_simple.offsetLeft + 'px';

        //console.log("canvas size 2", parentCanvas, parentWidth, canvasWidth);
        //if (parentWidth < canvasWidth) {
        //    clear_simple.style.display = 'none';

        //    var newHight = (canvasHeight * parentWidth) / canvasWidth;

        //    var tempCanvas = document.createElement('canvas');
        //    tempCanvas.width = orginalCanvas.width;
        //    tempCanvas.height = orginalCanvas.height;
        //    var tmpCtx = tempCanvas.getContext('2d');

        //    // Copy to temporary canvas
        //    tmpCtx.drawImage(orginalCanvas, 0, 0);

        //    // Resize original canvas
        //    canvas_simple.setAttribute('height', newHight);
        //    canvas_simple.setAttribute('width', parentWidth);
        //    console.log("canvas size 3", canvas_simple, parentWidth, newHight);

        //    clickX_simple = new Array();
        //    clickY_simple = new Array();
        //    clickDrag_simple = new Array();

        //    context_simple.drawImage(tempCanvas, 0, 0, tempCanvas.width, tempCanvas.height, 0, 0, parentWidth, newHight);

        //    clear_simple.style.top = canvas_simple.offsetTop + newHight + 3 + 'px';

        //    if (isRightToLeft)
        //        clear_simple.style.left = canvas_simple.offsetLeft + 'px';
        //    else
        //        clear_simple.style.right = canvas_simple.offsetLeft + 'px';

        //    if (canvasIsDirty)
        //        clear_simple.style.display = 'block';

        //}
    }

    function addClickSimple(x, y, dragging) {
        clickX_simple.push(x);
        clickY_simple.push(y);
        clickDrag_simple.push(dragging);
    }

    function clearCanvas_simple() {
        context_simple.clearRect(0, 0, canvas_simple.width, canvas_simple.height);
    }

    function redrawSimple() {
        clearCanvas_simple();

        var radius = 3;
        context_simple.strokeStyle = "#000";
        context_simple.lineJoin = "round";
        context_simple.lineWidth = radius;

        for (var i = 0; i < clickX_simple.length; i++) {
            context_simple.beginPath();
            if (clickDrag_simple[i] && i) {
                context_simple.moveTo(clickX_simple[i - 1], clickY_simple[i - 1]);
            } else {
                context_simple.moveTo(clickX_simple[i] - 1, clickY_simple[i]);
            }
            context_simple.lineTo(clickX_simple[i], clickY_simple[i]);
            context_simple.closePath();
            context_simple.stroke();
        }
    }

    function getImageString() {
        if (canvasIsDirty) {
            var imageCanvas = canvas_simple.toDataURL("image/png");
            return imageCanvas.replace('data:image/png;base64,', '');
        } else {
            return "";
        }
    }

    function setControlStateValue() {
        if (canvasIsDirty) {
            rayControlSetValue();
            timerIsOn = false;
        }
    }

    function rayControlSetValue() {
        var currentVal = getImageString();
        if (rayControl.lastValue !== currentVal) {
            rayControl.options.controlState.Value = rayControl.lastValue = currentVal;
            rayControl._trigger('rayControlChanged', {}, rayControl.options.controlState);
        }
    }

    function fillCanvas(src) {
        if (callFillCanvas) {
            if (src) {
                canvasIsDirty = true;
                var image = new Image();
                rayControl.lastValue = src;
                src = "data:image/png;base64," + src;

                image.onload = function () {
                    orginalCanvas.width = image.width;
                    orginalCanvas.height = image.height;
                    orginalContext.drawImage(image, 0, 0);

                    context_simple.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvasWidth, canvasHeight);

                }

                image.src = src;

                $('#img' + canvasId).attr('src', src);

                canvasIsDirty = true;

                if (enable)
                    clear_simple.style.display = 'block';
            }

            callFillCanvas = 0;
        }
    }

    function setEnable(val) {
        enable = val;
        $('#' + canvasId).toggle(val);
        $('#img' + canvasId).toggle(!val);
        canvas_simple.style.cursor = val ? 'pointer' : 'default';

        if (!val)
            clear_simple.style.display = 'none';

    }

    return {
        init: init,
        getImageString: getImageString,
        fillCanvas: fillCanvas,
        setEnable: setEnable
    };

};
