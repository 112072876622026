import newForm from '../forms/newform/newForm.vue';
/*import loading from '../widgets/loading/loading.vue';*/
import TrackingHistory from '../tracking/modals/TrackingHistory.vue';
import TaskAssign from '../taskassign/TaskAssign.vue';

export default {
    //watch: {
    //    '$route'(to, from) {
    //        this.init();
    //    }
    //},
    data: function () {
        return {
            modalData: [],
            currentModalComponent: "",
            showTrackingHistory: 0,
            widthSize: 0,
            heightSize: 0,
            className: "",
            title: "",
            sampleSize: "col-sm-8",
            counter: 0,
            currentView: "test",
            currentIndex: -1,
            isCompletedTask: false,
            customStyle: '',
            showTaskAssign: 0,
            currentModal: "",
            modalDataTaskAssign: {},
            breadcrumbs: []
        }
    },
    methods: {
        init() {
            this.afterFormLoaded({
                title: this.$t("loading"),
                formState: {
                    IsResponsive: true,
                    ResponsiveWdith: 12
                }
            });

            const params = {
                taskId: this.$route.params.id,
                taskStatus: this.$route.params.status,
                isStarterForm: false,
                isDraft: this.$route.params.isDraft == "true",
                taskTypeId: this.$route.params.taskTypeId,
                formLoadedCallback: $.proxy(this.afterFormLoaded, this),
                /*showLoading: $.proxy(this.showLoading, this),*/
                toolbarContainer: $(this.$el).find('.toolbar').empty(),
                showHistoryModalCallback: $.proxy(this.showHistoryModal, this),
                showTaskAssignModalCallback: $.proxy(this.showTaskAssignModal, this),
                domainObjectId: ""
            };

            if (typeof this.$route.query.tId !== 'undefined') {
                params.taskId = this.$route.query.tId;
                params.taskStatus = "Active";
                params.isDraft = false;
                params.taskTypeId = this.$route.query.fid;
            }

            if (typeof this.$route.query.dId !== 'undefined' &&
                typeof this.$route.query.fid !== 'undefined') {
                params.domainObjectId = this.$route.query.dId;
                params.taskTypeId = this.$route.query.fid;
            }

            //this.showLoading(true);
            $(this.$el).find('.js_load-form').bpmsAppForm(params);
        },

        getBreadCrumbs() {
            //console.log("Taskform. show breadcrumbs", this.$route.params);
            this.breadcrumbs = [];
            this.breadcrumbs.push({ text: this.title, url: "" });

            const p = this.$route.params.parentId != null ? `/${this.$route.params.parentId}` : '';
            this.breadcrumbs.push({ text: this.$route.params.folderName, url: `/tasks/${this.$route.params.folderId}/${this.$route.params.folderName}/${this.$route.params.isDraft}${p}` });
            this.breadcrumbs = this.breadcrumbs.reverse();
        },

        afterFormLoaded(formData) {
            if (formData.taskSubject != null && formData.taskSubject != '')
                this.title = formData.taskSubject;
            else
                this.title = formData.formTitle;

            this.getBreadCrumbs();
            //if (formData.formState.IsResponsive) {
                this.className = `col-xs-12 col-lg-${formData.formState.ResponsiveWdith}`;
                this.customSyle = '';
            //} else {
            //    //var oldForm = $(this.$el).find(".old-form");
            //    //oldForm.closest(".form-container-responsive").addClass("col-sm-12");
            //    //this.customStyle = `width: ${oldForm.width() + 70}px; height:${oldForm.height() + 25}px`;
            //    this.customStyle = `width: ${formData.formState.FormWidth + 50}px; height:${formData.formState.FormHeight + 30}px`;
            //}
        },

        //showLoading(visible) {
        //    //console.log("Show task form loading Vue", visible);
        //    //    if (visible)
        //    //        $.LoadingOverlay('hide', true);
        //    //    visible ? $.LoadingOverlay('show') : $.LoadingOverlay('hide', true);
        //},

        showHistoryModal() {
            let url = "";
            let id = this.$route.params.id;
            if (typeof id === "undefined")
                id = this.$route.query.tId;

            if (typeof id === "undefined") {
                const domainObjectId = router.app.$route.query.dId;
                const taskTypeId = router.app.$route.query.fid;
                url = "api/tracking/get-tree-task-domain?domainObjectId=" + domainObjectId + "&taskTypeId=" + taskTypeId;
            } else {
                url = "api/tracking/get-tree-task/" + id;
            }

            const that = this;

            this.doAjaxRequest(
                url,
                {},
                (data) => {
                    if (data != null && data.length) {

                        that.currentModalComponent = "TrackingHistory";
                        that.modalData = data;
                        that.showTrackingHistory++;

                    } else {
                        alert(i18n.$t("messages.not_any_record_found"));
                    }
                });
        },

        showTaskAssignModal(params) {
            const that = this;
            const folderId = params.folderId;

            this.modalDataTaskAssign.ids = params.ids;
            this.modalDataTaskAssign.folderId = folderId;

            this.modalDataTaskAssign.callback = () => {

                var params = {
                    text: that.$t('task_assign.messages.success_assign'),
                    type: 'information',
                    layout: 'center',
                    timeout: 3000
                };

                noty(params);

                //console.log("Navigate to task from taskform", params);
                that.$router.push({
                    name: 'tasksGrid',
                    params: { folderId: folderId }
                });
            };

            this.currentModal = "TaskAssign";
            this.showTaskAssign++;
        },

        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            const ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",

                contentType: "application/json;charset=utf-8"
            };

            if (method)
                ajaxConfig.method = method;

            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        }
    },
    mounted() {
        this.init();
    },
    components: {
        newForm,
        /*loading,*/
        TrackingHistory,
        TaskAssign
    }
}
