var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    { attrs: { showModal: _vm.showModal, "data-backdrop": "false" } },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.modalHeader))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm._v(
                _vm._s(_vm.$t("create_physical_folder.folder_title")) + ":"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.folderName,
                  expression: "folderName",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: _vm.titlePlaceHolder },
              domProps: { value: _vm.folderName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.folderName = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm._v(
                _vm._s(_vm.$t("create_physical_folder.folder_type")) + ":"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.folderType,
                    expression: "folderType",
                  },
                ],
                staticClass: "form-control",
                attrs: { disabled: _vm.disableFolderTypeSelector },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.folderType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [
                  _vm._v(_vm._s(_vm.$t("create_physical_folder.undone_works"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [
                  _vm._v(_vm._s(_vm.$t("create_physical_folder.done_works"))),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-2" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              {
                staticStyle: { color: "red" },
                attrs: { id: "divFolderValidation" },
              },
              [
                _c("span", {
                  staticStyle: { color: "red" },
                  attrs: { id: "folderValidationMessage" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("submit")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default cancelModal",
                attrs: { "data-dismiss": "modal" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("cancel")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }