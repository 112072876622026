import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxPager, DxPaging
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

export default {
    props: [],

    data() {
        const that = this;

        return {
            dataGridRefKey: 'dataGrid',
            isReceivedMessage: that.$route.params.isReceivedMessage,
            dataSource: createStore({
                key: 'Id',
                loadUrl: 'api/message/getlist',
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.xhrFields = { withCredentials: true };
                    ajaxOptions.headers = { "Content-type": "application/json" };
                    ajaxOptions.data.isReceivedMessage = that.$route.params.isReceivedMessage;
                }
            }),
            userColumnName: this.$route.params.isReceivedMessage === "true"
                ? this.$t("message_management.sender")
                : this.$t("message_management.receiver"),
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            messageurl: window.location.pathname + "#/view-message/",
        };
    },

    watch: {
        '$route'(to, from) {
            this.userColumnName = this.$route.params.isReceivedMessage === "true"
                ? this.$t("message_management.sender")
                : this.$t("message_management.receiver");
            this.$forceUpdate();
            this.refreshDataGrid();
        }
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        },
        title: function () {
            return this.$route.params.isReceivedMessage === "true" ? this.$t('messages_receive') : this.$t('messages_sent');
        }
    },

    methods: {
        onRowPrepared(e) {
            //console.log("onRowPrepared", e.data);
            if (e.rowType === 'data') {
                if (!e.data.IsDisplayed)
                    e.rowElement.css('font-weight', 'bold');
            }
        },

        refreshDataGrid() {
            this.dataGrid.refresh();
        }
    },

    updated() { },
    mounted() { },
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging
    }
}
