import utils from 'jsf/utils.js';

export function Export() {
    let params = {};
    const canLeave = function () {
        return true;
    };

    const exportClick = function () {
        params.afoptions.showLoading(true);
        utils.callWebAPI('api/gridsimple/kendo/export', {
            bindingId: params.bindingId,
            exportType: $("#RadioButtonList").find(":checked").val(),
            pageInstanceId: params.pageInstanceId,
            clientId: params.clientId,
            header: params.header,
            footer: params.footer
        },
            function (result) {
                params.afoptions.showLoading(false);
                console.log(`Export Succeed: ${result.d}`);
                window.open(result.d);
            },
            function (err) {
                params.afoptions.showLoading(false);
                console.log(`Export Error: ${err}`);
            }, 'POST');
    };

    const activate = function (_params) {
        config.currentViewCanLeave(canLeave);
        params = _params;
        const $exportForm = $('<div class="bpms-export"></div>');
        utils.showModalHtml('Views/Export.html').done(
            function (html) {
                const strs = {
                    '@showTableTitle': i18n.$t('export_control.showTableTitle'),
                    '@titlesetting': i18n.$t('export_control.titlesetting'),
                    '@title': i18n.$t('export_control.title'),
                    '@backGround': i18n.$t('export_control.backGround'),
                    '@createfile': i18n.$t('export_control.createfile'),
                    '@bodysetting': i18n.$t('export_control.bodysetting')
                };

                for (let key in strs) {
                    const regx = new RegExp(key, 'gi');
                    html = html.replace(regx, strs[key]);
                }
                $exportForm.append(html);
                $exportForm.find('#txtTitleItemValue').val(params.caption);

                if ($exportForm)
                    $("#_btnRunExport", $exportForm).click(exportClick);
                else
                    $("#_btnRunExport").click(exportClick);
            }
        );
        $exportForm.rayModal({ title: "Export Grid" });
    };

    return {
        activate: activate
    };
}
