declare var $: any;

var hubProxy = $.connection.WorkbenchHub;
$.connection.hub.start().then(
    function () {

    }
);


export default hubProxy;


