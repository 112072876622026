var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("appearance_management.ui_setup"),
        loading: _vm.loading,
        className: "col-xs-12",
      },
    },
    [
      _c("div", { staticClass: "jquery-ui-scope view bpms-ui" }, [
        _c("div", { staticClass: "ui-widget jquery-ui-scope" }, [
          _c(
            "div",
            {
              staticClass: "ui-widget-content ui-corner-bottom",
              staticStyle: { padding: "10px" },
            },
            [
              _c("ul", { staticClass: "nav nav-tabs" }, [
                _c("li", { staticClass: "active" }, [
                  _c(
                    "a",
                    { attrs: { href: "#tabs-1", "data-toggle": "tab" } },
                    [_vm._v(_vm._s(_vm.$t("appearance_management.back_image")))]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    { attrs: { href: "#tabs-3", "data-toggle": "tab" } },
                    [_vm._v(_vm._s(_vm.$t("appearance_management.banner")))]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab-content", attrs: { id: "myTabContent" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade active in",
                      attrs: { id: "tabs-1" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "beforeLoginBanner" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.before_login"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    attrs: { id: "lblbeforeLoginBanner" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: {
                                  id: "beforeLoginBanner",
                                  type: "file",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "beforeLoginBanner",
                                      false
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.beforeLoginBanner.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.beforeLoginBanner.src +
                                              _vm.beforeLoginBanner.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.beforeLoginBanner.id,
                                                  "beforeLoginBanner"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "afterLoginBanner" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.after_login"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    attrs: { id: "lblafterLoginBanner" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: { id: "afterLoginBanner", type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "afterLoginBanner",
                                      false
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.afterLoginBanner.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.afterLoginBanner.src +
                                              _vm.afterLoginBanner.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.afterLoginBanner.id,
                                                  "afterLoginBanner"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "beforeAuthBackground" } },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("appearance_management.location")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("label", { staticClass: "radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.backgroundPosition,
                                    expression: "backgroundPosition",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "backgroundRepeat",
                                  value: "repeat",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.backgroundPosition,
                                    "repeat"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.backgroundPosition = "repeat"
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.$t("appearance_management.repeat")
                                  ) +
                                  "\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.backgroundPosition,
                                    expression: "backgroundPosition",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "backgroundRepeat",
                                  value: "no-repeat",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.backgroundPosition,
                                    "no-repeat"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.backgroundPosition = "no-repeat"
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.$t("appearance_management.no_repeat")
                                  ) +
                                  "\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "radio" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.backgroundPosition,
                                    expression: "backgroundPosition",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  name: "backgroundRepeat",
                                  value: "center",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.backgroundPosition,
                                    "center"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.backgroundPosition = "center"
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(
                                    _vm.$t("appearance_management.center")
                                  ) +
                                  "\n                                    "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "txtBackgroundColor" } },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("appearance_management.back_color")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control colorpicker",
                              staticStyle: { direction: "ltr" },
                              attrs: {
                                type: "text",
                                id: "txtBackgroundColor",
                                "data-control": "hue",
                                value: "#FFFFFF",
                                placeholder: "",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col-md-5" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-1" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.submitBackgroundTab },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("save")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { clear: "both" } }),
                      _vm._v(" "),
                      _c("br"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tab-pane fade", attrs: { id: "tabs-3" } },
                    [
                      _c("p", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("appearance_management.banner_dsc")) +
                            "\n                            "
                        ),
                        _c("em", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("appearance_management.banner_attention")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("messages.file_type_attention"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("appearance_management.height_attention")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("appearance_management.image_attention")
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "topBanner" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.back_image_file"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { attrs: { id: "lbltopBanner" } }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: { id: "topBanner", type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "topBanner",
                                      true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.topBanner.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.topBanner.src +
                                              _vm.topBanner.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.topBanner.id,
                                                  "topBanner"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "topBannerRight" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.right_iamge"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    attrs: { id: "lbltopBannerRight" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: { id: "topBannerRight", type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "topBannerRight",
                                      true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.topBannerRight.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.topBannerRight.src +
                                              _vm.topBannerRight.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.topBannerRight.id,
                                                  "topBannerRight"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "topBannerCenter" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.center_image"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    attrs: { id: "lbltopBannerCenter" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: { id: "topBannerCenter", type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "topBannerCenter",
                                      true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.topBannerCenter.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.topBannerCenter.src +
                                              _vm.topBannerCenter.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.topBannerCenter.id,
                                                  "topBannerCenter"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-xs-12 col-md-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { for: "topBannerLeft" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "appearance_management.left_image"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    attrs: { id: "lbltopBannerLeft" },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticStyle: { display: "none" },
                                attrs: { id: "topBannerLeft", type: "file" },
                                on: {
                                  change: function ($event) {
                                    return _vm.fileUploadChange(
                                      $event,
                                      "topBannerLeft",
                                      true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.topBannerLeft.serv.Size > 0
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "bannerImageBox" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "appearance_management.current_img"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass:
                                            "img-thumbnail img-responsive",
                                          attrs: {
                                            src:
                                              _vm.topBannerLeft.src +
                                              _vm.topBannerLeft.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-xs btn-danger",
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetImage(
                                                  _vm.topBannerLeft.id,
                                                  "topBannerLeft"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("remove")))]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "visible-xs" }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "row form-group" }, [
                        _c("div", { staticClass: "col-md-5" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-1" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: { click: _vm.submitBanner },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("save")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                        " + _vm._s(_vm.$t("attention"))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("page_refresh")) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }