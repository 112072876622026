var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "k-item k-state-default",
      on: {
        click: function ($event) {
          return _vm.item.action()
        },
      },
    },
    [
      _c("span", { staticClass: "k-link" }, [_vm._v(_vm._s(_vm.item.text))]),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.item.children, function (value, key, index) {
          return _c("contextMenuItem", { key: key, attrs: { item: value } })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }