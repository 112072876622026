var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "navbar_custom" }, [
    _c("nav", { staticClass: "navbar navbar-inverse js_navbar" }, [
      _c("div", { class: _vm.container }, [
        _vm.container === "container-fluid"
          ? _c(
              "div",
              {
                staticClass:
                  "sidebar-toggle_container pull-left hidden-lg profile-info",
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse navbar-collapse",
            attrs: { id: "nav-main-menu" },
          },
          [
            _c(
              "ul",
              { staticClass: "nav navbar-nav js_mainMenu" },
              [
                _vm.menuItems.length !== 0
                  ? _vm._t("beforeMenuItems")
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "homeIcon" },
                  [
                    _c("router-link", { attrs: { to: "/" } }, [
                      _c("i", {
                        staticClass: "fa-light fa-lg fa-house-chimney",
                      }),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.menuItems, function (item, key) {
                  return _c("navbarItem", { key: key, attrs: { item: item } })
                }),
                _vm._v(" "),
                _vm.menuItems.length !== 0
                  ? _vm._t("AfterMenuItems")
                  : _vm._e(),
                _vm._v(" "),
                _vm.menuItems.length !== 0
                  ? _c("li", { staticClass: "dropdown more other-menu" }, [
                      _c("a", { attrs: { "data-toggle": "dropdown" } }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("other")) +
                            "\n                            "
                        ),
                        _c("span", { staticClass: "caret" }),
                      ]),
                      _vm._v(" "),
                      _c("ul", {
                        staticClass: "dropdown-menu dropdown-menu-last",
                      }),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "nav navbar-nav navbar-right" },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown-toggle",
                      attrs: {
                        href: "#",
                        "data-toggle": "dropdown",
                        role: "button",
                        "aria-expanded": "true",
                        title: _vm.$t("languages"),
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.currentLanguageIcon,
                          title: _vm.currentLanguage.Title,
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "caret" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "dropdown-menu", attrs: { role: "menu" } },
                    _vm._l(_vm.languages, function (lang) {
                      return _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.currentUrl + "?lang=" + lang.Culture,
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "dist/" + lang.Culture + ".png",
                                title: lang.Title,
                              },
                            }),
                            _vm._v(
                              "\n                                    " +
                                _vm._s(lang.Title) +
                                "\n                                "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm._t("leftNavItems"),
              ],
              2
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sidebar-toggle js_sideToggle-header arrow-reverse" },
      [_c("i", { staticClass: "fad fa-2x fa-arrow-left" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-header" }, [
      _c(
        "button",
        {
          staticClass: "navbar-toggle collapsed",
          attrs: {
            type: "button",
            "data-toggle": "collapse",
            "data-target": "#nav-main-menu",
          },
        },
        [_c("i", { staticClass: "fad fa-2x fa-chevron-double-down" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }