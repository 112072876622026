import utils from 'jsf/utils.js';

export function sendMessage() {
    window.onhashchange = function () {
        attachments = [];
        receivers = [];
    }
    var max = 6;
    var attachments = [];
    var receivers = [];
    var that = this;

    function getPrincipalTitle() {
        var users;

        utils.callWebAPI("api/principal/users", { userID: $.cookie("principalId") },
            function (result) {
                users = result.d;
                var $input = $('.cb-users');
                $input.typeahead({
                    source: function (query, process) {
                        var source = [];
                        $(users).each(function () {
                            var l = this.label;
                            source.push({ id: this.id, name: l });
                        });
                        return process(source, true);

                    },
                    autoSelect: true, items: max,
                    afterSelect: function (e) {
                        if (e.id === '!#more#') {
                            max += 10;
                            $input.typeahead('changeMax', max);
                            $input.typeahead('lookup', $input.val() || '').focus();
                            return;
                        }
                        var usr = $(".cb-users").typeahead('getActive');
                        if (usr != null) {
                            var notExists = true;
                            $(receivers).each(function () {
                                if (this.id === usr.id) {
                                    notExists = false;
                                    return false;
                                }
                            });
                            if (notExists === false) {
                                utils.showMessage(i18n.$t("message_management.messages.repeated_recipient"), 3000, 'information');
                            }
                            if (notExists === true) {
                                receivers.push(usr);
                                var container = $(".message-receivers");
                                var div = $("<div class='pull-left'></div>");
                                var deletebutton = $("<button class='btn btnDelete btn-xs' style='background-color:transparent;' id='" + usr.id + "'> <i class='fad fa-2x fa-trash'></i> </button>");
                                var title = $(" <span class='message-receivers-user-name'>" + usr.name + "</span>");
                                $(deletebutton).unbind('click').bind('click', function () {
                                    var parent = $(this).parent();
                                    var aid = $(this).attr("id");
                                    $(parent).remove();

                                    if (aid != null) {
                                        $(receivers).each(function () {
                                            if (this.id === aid) {
                                                var index = receivers.indexOf(this);
                                                receivers.splice(index, 1);
                                                return false;
                                            }
                                        });
                                    }
                                });
                                div.append(title);
                                div.append(deletebutton);
                                container.append(div);
                            }
                        }
                    }
                });
                $('.btn-users').click(function () {
                    $input.typeahead('lookup', '').focus();
                });
            });
    }

    var activate = function (param, page) {
        var $uiForm = param.element;
        $uiForm.empty();
        config.currentViewCanLeave(true);

        utils.showModalHtml(page)
            .done(function (html) {
                $uiForm.append(html);
                $uiForm.css("display", "block");
                new legacyComponent({ i18n: vueI18n }).$mount($uiForm[0]);
                getPrincipalTitle();
                $(".upload-file").unbind('click').bind('click', function (e) {
                    e.preventDefault();
                    $(".uploader").click();
                });
                $(".uploader").unbind('change').bind('change', function (e) {
                    e.preventDefault();
                    var reader = new FileReader();
                    var myId = utils.rayGuid();
                    var fileType = '';
                    var fileName = '';
                    reader.onload = function (e) {
                        //console.log(e.target.result);
                        var attach = { id: myId, file: e.target.result.split(',')[1], name: fileName, mimeType: fileType };
                        attachments.push(attach);
                        var p = $("<div class='pull-left'></div>");
                        var deletebutton = $("<button class='btn btnDelete btn-xs' style='background-color:transparent;' id='" + myId + "'> <i class='fad fa-2x fa-trash'></i> </button>");
                        var downloadInput = $(" <span class='attachments-item'>" + fileName + "</span>");
                        $(deletebutton).unbind('click').bind('click', function () {
                            var parent = $(this).parent();
                            var aid = $(this).attr("id");
                            $(parent).remove();
                            if (aid != null) {
                                $(attachments).each(function () {
                                    if (this.id === aid) {
                                        var index = attachments.indexOf(this);
                                        attachments.splice(index, 1);
                                        return false;
                                    }
                                });
                            }
                        });
                        $(downloadInput).unbind('click').bind('click', function () {

                        });
                        var container = $(".message-attachments");
                        p.append(downloadInput);
                        p.append(deletebutton);
                        container.append(p);
                        $(".uploader").val('');
                    }
                    fileType = this.files[0].type;
                    reader.readAsDataURL(this.files[0]);
                    fileName = this.files[0].name;
                });

                $(".message-btn-submit").unbind('click').bind('click', function (e) {
                    e.preventDefault();
                    if ($(".Title").val() == null || $(".Title").val() == '') {
                        utils.confirm(i18n.$t("message_management.messages.title_is_empty"), {
                            yes: function () {
                                send();
                            },
                            no: function () { }
                        });
                    }
                    else
                        send();

                });
                $(".cb-users").unbind('click');
                function send() {
                    var replyID = null;
                    if (page == "Views/ReplyMessage.html") {
                        replyID = $("#messageId").text();
                        receivers.push({ id: $(".current-msg").attr("senderId"), name: $("#msgFrom").text() });
                    }
                    if (receivers.length > 0) {
                        var obj = {
                            Attachments: attachments,
                            Receivers: receivers,
                            Title: $(".Title").val(),
                            Body: $(".BodyText").val(),
                            ReplyTo: replyID
                        }
                        utils.callWebAPIp("api/message/send", obj, "POST", true)
                            .done(function () {
                                attachments = [];
                                receivers = [];
                                router.push({ path: "/message-grid/true" });
                                utils.showMessage(i18n.$t("message_management.messages.success_send"), 5000, 'success');
                            }).fail(function (e) {
                                console.error(e);
                            });
                    }
                    else {
                        utils.showMessage(i18n.$t("message_management.messages.unselected_recipient"), 5000, 'default');
                    }
                }
            });
        $(".view").hide();
    };
    return {
        activate: activate
    }
};
