import utils from 'jsf/utils.js';

export default {
    data() {
        return {
            homeMenuItems: [],
            breadcrumbs: []
        }
    },

    watch: {
        '$route'(to, from) {
            this.getItems();
        }
    },

    mounted() {
        this.getItems();
        //console.info("Remove window.onbeforeunload (home)");
        window.onbeforeunload = null;
    },

    methods: {
        getItems() {
            this.breadcrumbs = [];
            this.homeMenuItems = [];

            let parentId = "00000000-0000-0000-0000-000000000000";
            if (typeof this.$route.params.parentId !== "undefined")
                parentId = this.$route.params.parentId;

            utils.callWebAPI("api/home/getitems", {parentId: parentId}, (data) => {
                let style = "";
                for (let item of data.items) {
                    if (item.ParentId === parentId || item.ParentId === null) {
                        this.homeMenuItems.push(item);
                        const id = item.Id.substring(0, 8);
                        const mainColor = "#rmenu" + id + ":link{color:" + item.TextColor + "} ";
                        const hoverColor = "#rmenu" + id + ":hover{color:" + item.TextColorHover + "} ";
                        const visitedColor = "#rmenu" + id + ":visited{color:" + item.TextColorHighlight + "} ";
                        style += mainColor + hoverColor + visitedColor;
                    }
                }
                const styleEl = document.getElementById('menuStyle');
                if (styleEl)
                    styleEl.parentNode.removeChild(styleEl);

                document.head.insertAdjacentHTML('beforeend', '<style id="menuStyle">' + style + '</style>');

                for (let bc of data.breadcrumbs) {
                    if (bc.Text.length === 0)
                        this.breadcrumbs.push({text: i18n.$t("homepage"), url: "/"});
                    else
                        this.breadcrumbs.push({text: bc.Text, url: "/" + bc.Id});
                }
            });

            this.currentPage = parentId;
        },

        getBorderRadius(item) {
            if (item.IconShape === 'Circle')
                return '100%';
            return '0px';
        },
        getWidth(item) {
            return item.IconWidth.toString() + 'px';
        },
        getHeight(item) {
            if (item.IconShape === 'Circle' || item.IconShape === 'Square')
                return item.IconWidth.toString() + 'px';
            return item.IconHeight.toString() + 'px';
        },

        checkMenuTextPosition(value) {
            //if (value === 'Top') {
            //    return 'homeMenu-top';
            //} else if (value === 'Bottom') {
            //    return 'homeMenu-bottom';
            //} else {
            //    return 'homeMenu-middle';
            //}
            if (value === 'Middle')
                return 'homeMenu-middle';
            return '';
        }
    }
}
