var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.id, role: "dialog" } },
    [
      _c("div", { staticClass: "modal-dialog", class: _vm.size }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            !_vm.showDismissBtn
              ? _c(
                  "button",
                  {
                    staticClass: "close pull-left",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("\n\n                    ×\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("h4", { staticClass: "modal-title" }, [_vm._t("header")], 2),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer"),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$slots.body,
                      expression: "!$slots.body",
                    },
                  ],
                  staticClass: "btn btn-default",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [
                  _vm._v(
                    "\n\n                    " +
                      _vm._s(_vm.$t("close")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }