var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.announcementItems.length !== 0
    ? _c("div", { staticClass: "panel panel-default announcements_custom" }, [
        _c("div", { staticClass: "panel-body" }, [
          _c("h2", { staticClass: "panel_title" }, [
            _vm._v(_vm._s(_vm.$t("title.announcements"))),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "list-unstyled" },
          _vm._l(_vm.announcementItems, function (item) {
            return _c("li", { attrs: { id: item.Id } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xs-10" }, [
                  _c(
                    "a",
                    {
                      staticClass: "announcement_row",
                      attrs: {
                        "data-toggle": "collapse",
                        href: "#pnl-" + item.Id,
                        "aria-expanded": "false",
                      },
                    },
                    [
                      _c("strong", { staticClass: "announcement_title" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.Title) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "announcement_summary text-justify",
                          style: { color: item.Color },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.truncate(item.Summary, 100)) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-2 text-right announcement_tools" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-toggle": "collapse",
                          id: "btn-" + item.Id,
                          href: "#pnl-" + item.Id,
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("show")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "panel-collapse collapse",
                  attrs: { id: "pnl-" + item.Id },
                },
                [
                  _c("div", { staticClass: "announcement_detail" }, [
                    _c("div", { staticClass: "text-justify" }, [
                      _c("p", { staticClass: "announcement_detail-summary" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.Summary) +
                            "\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "announcement_detail-body" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(item.Body) +
                            "\n                        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "panel-footer" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.$t("date")) +
                          ": " +
                          _vm._s(item.CreatedAt) +
                          "\n                          |  \n                        " +
                          _vm._s(_vm.$t("author")) +
                          ": " +
                          _vm._s(item.UserTitle) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }