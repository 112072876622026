var localeMessages = {
    user: 'Användare',
    staff: 'Personal',
    save_successfully: 'Sparad framgångsrikt.',
    error_saving: 'Fel sparar',
    help: 'Hjälp',
    ok: 'Ok',
    send_as_staff: 'Skicka som personal',
    remove_record: 'Ta bort inspelning',
    homepage: 'Hemsida',
    view_form: 'Formvy',
    confirm: 'Bekräfta',
    prev_row: 'Föregående rad',
    next_row: 'Nästa rad',
    save_and_new: 'Spara och nytt',
    save_and_next: 'Spara och Nästa',
    save_and_previous: 'Spara och Föregående',
    save_and_return: 'Spara och återvända',
    cancel_and_return: 'Avbryt och återvända',
    submit_form: 'Skicka in formulär',
    return: 'Återvända',
    save_form: 'Spara formulär',
    login: 'Logga in',
    sign_in: 'Logga in',
    username: 'Användarnamn',
    password: 'Lösenord',
    date: 'Datum',
    author: 'Författare',
    more_details: 'Visa mer detaljer',
    show: 'Visa',
    close: 'Stäng',
    open: 'Öppen',
    languages: 'Språk',
    try_again: 'Försök igen',
    tracking: 'Spårning',
    tracking_code: 'Spårningskod',
    history: 'Historia',
    process_level: 'Processnivå: <strong>{level}</strong>',
    loading: 'laddning',
    uploading: 'Ladda upp',
    save: 'Spara',
    remove: 'Ta bort',
    attention: '* Uppmärksamhet:',
    select: 'Välj',
    please: 'Snälla',
    doing: 'Gör',
    upload_doing: 'Laddar up och sparar',
    page_refresh: 'För att se ändringarna, uppdatera cachen (Ctrl-F5).',
    send: 'Skicka',
    insert: 'Infoga',
    edit: 'Redigera',
    delete: 'Radera',
    reply: 'Svar',
    are_you_sure: 'Är du säker?',
    control: 'Kontroll',
    show_error_details: 'Visa feluppgifter',
    warning: 'Varning',
    search: 'Sök',
    empty: 'Tom',
    new: 'Nytt',
    priority: 'Prioritet',
    priority_low: 'Låg',
    priority_normal: 'Prioritet-Normal',
    priority_high: 'Hög',
    priority_very_high: 'Brådskande',
    subject: 'Ämne',
    created_date: 'Skapad vid',
    response_deadline: 'Svarstidsfrist',
    done_date: 'Klart vid',
    sender: 'Avsändare',
    starter_user: 'Start-användare',
    starter_staff: 'Personalanvändare',
    is_assignable: 'Kan tilldelas',
    insert_label: 'Etikett',
    continue: 'Fortsätt',
    submit: 'Skicka in',
    cancel: 'Avbryt',
    cancel_nested: 'Avbryt',
    other: 'Övrigt',
    settings: 'inställningar',
    change_theme: 'Teman',
    message: 'Meddelande',
    messages: {
        not_any_record_found: 'Record hittades inte.',
        not_history_for_tracking_code_found: 'Ingen historik hittades för spårningskod.',
        not_selected_principals: 'Inga huvudnamn är markerade byt namn på mapp.',
        write_dont_work_folder_name: 'Vänligen välj ett namn för Att göra-aktiviteter mapp.',
        write_done_work_folder_name: 'Vänligen välj ett namn för Mappen Slutförda uppgifter.',
        write_dont_send_request_folder_name: 'Vänligen välj ett namn för Utkast till förfrågan-mapp.',
        save_attntion: 'Är du säker på att du vill fortsätta?',
        changing_name: 'Namnändring...',
        sucsses_message: 'Ändringar sparas framgångsrikt.',
        file_volume: 'Filstorlek',
        file_upload: 'bör inte överstiga 300 kb.',
        file_type: 'Ladda upp en fil med jpg, png eller gif-format.',
        delete_sucsses: 'Raderas framgångsrikt',
        reset_confirm: 'Är du säker på att du vill återställa bakgrundsbilden som en standard?',
        doing_sucsses: 'Avslutat framgångsrikt',
        file_type_attention: 'Endast jpg, png- eller gif-filer stöds.',
        delete_confirm_massage: 'Är du säker på att du vill ta bort?',
        your_request_has_been_sent: 'Din begäran har skickats.',
        confirm_message: 'Är du säker?',
        save_successfully: 'Sparat framgångsrikt.',
        password_not_currect: 'Användarnamn eller lösenord är inte korrekt.',
        connection_not_safe: 'Anslutning är inte säkrad.',
        pleas_try_later: 'Försök igen',
        username_or_password_is_null: 'Användarnamn eller lösenord är inte korrekt.',
        control_behavior_not_exists: 'Det finns inget formbeteende för det här formulärelementet.'
    },
    apply: 'Tillämpa',
    all_messages: 'Alla meddelanden',
    new_message: 'Nytt meddelande',
    messages_sent: 'Skickade meddelanden',
    messages_receive: 'Mottagna meddelanden',
    messages_receive_notification: 'Du har ett nytt meddelande!',
    sign_out: 'Logga ut',
    change_password: 'Ändra lösenord',
    'first-page': 'Första sidan',
    'view-form': 'Visa formulär',
    invalid_date: 'Ogiltigt datum',
    operation_faild: 'Operationen misslyckades.',
    session_end_you_will_redirect_to_the_homepage:
        'Din session har avslutats, du kommer att omdirigera till hemsidan.',
    session_end_title: 'Sessionen har gått ut',
    login_again: 'Vänligen logga in igen!',
    server_shutdown_template: 'Uppmärksamhet! Servern stängs av klockan 12:00.',
    server_notif_message: 'Ange meddelandet du vill skicka till',
    server_notif_message_users: 'användare',
    server_notif_message_sent: 'Meddelandet har skickats!',
    server_notif_message_error: 'Fel har uppstått!',
    process_tree: 'Processträ',
    debug_info: 'Felsökningsinformation',
    process_graph: 'Processdiagram',
    process_history: 'Processhistorik',
    print_form: 'Skriva ut',
    time_sheet: 'Tidrapport',
    assign: 'Framåt',
    word_output: 'Exportera till Word',
    pdf_output: 'Exportera till PDF',
    pdf_preview: 'förhandsgranskning PDF',
    word_print: 'Skriv ut från Word',
    report: 'Rapporter',
    pdf_download: 'Ladda ner PDF-fil : ',
    select_file: 'Välj fil :',
    send_pdf: 'Skicka PDF-fil',
    by_level: 'Personal:',
    view_all_staff: 'visa all personal',
    image: 'Bild',
    of: 'av',
    error: 'Fel',
    has_form_less_events: 'har form mindre händelser',
    more_items: 'Fler objekt...',
    'retrieve-data-error': 'Hämta data har misslyckats.',
    upload_pdf_file: 'Skicka in pdf-fil.',
    download_pdf_file: 'Ladda ner pdf-fil',
    export: 'Exportera',
    not_selected: 'Ej valt',
    news_detaile: 'Nyheter',
    new_task: 'Ny uppgift',
    select_date: 'Vänligen välj start- och slutdatum',
    id: 'ID',
    select_all: 'Välj alla',
    deselect_all: 'Avmarkera alla',
    copied: 'Kopierade',
    unable_to_copy: 'Det går inte att kopiera',
    password_setting: {
        current_password: 'Nuvarande lösenord',
        new_password: 'Nytt lösenord',
        confirm_new_password: 'Bekräfta nytt lösenord',
        messages: {
            current_password_is_wrong: 'Nuvarande lösenord är fel!',
            passwords_do_not_match: 'Lösenord matchar inte!',
            new_password_same_as_current:
                'Nytt lösenord kan inte vara detsamma som det nuvarande lösenordet!',
            currentPassword_is_empty: 'Nuvarande lösenord är tomt',
            newPassword_is_empty: 'Nytt lösenord är tomt',
            confirmNewPassword_is_empty: 'Bekräfta nytt lösenord är tomt'
        }
    },
    title: {
        news: 'Nyheter',
        announcements: 'Meddelanden',
        procces_history: 'Processhistorik'
    },
    search_panel_control: {
        filter: 'Filter',
        clear_filter: 'Rensa filter'
    },
    grid_control: {
        add: 'Lägg till',
        add_inline: 'Lägg till',
        edit: 'Redigera',
        edit_inline: 'Redigera',
        del: 'Ta bort',
        undel: 'Avbryt',
        export_excel: 'Exportera till Excel',
        import_excel: 'Importera från Excel',
        template_excel: 'Ladda ner Excel-mall',
        save: 'Spara',
        cancel: 'Avbryt',
        view: 'Visa',
        export: 'Exportera',
        grid_is_in_editing_mode: 'är i redigeringsläge, vänligen tillämpa ändringar.',
        grid_is_in_editing_mode_you_cannot_do_anything:
            'du får inte utföra några operationer i redigeringsläge',
        grid_error_retrieving_data: 'Det gick inte att hämta data.',
        no_rows_selected: 'Inga rader valda',
        remove_selected_row: 'Ta bort markerat nu?',
        only_selected_columns_will_be_exported: 'Endast valda kolumner exporteras',
        items_get_error: 'Fel inträffade under get-listan!',
        noDataText: 'Det finns inga data att visa.',
        saveChanges: 'Spara ändringar',
        discard: 'Släng ändringar',
        saved: 'Sparad',
        save_unsuccessful: 'Spara misslyckades',
        sum: 'Sum',
        avg: 'Med',
    },
    attachment_control: {
        add: 'Lägg till',
        new: 'Nytt',
        select: 'Välj',
        scan: 'Skanna',
        new_version: 'Ny version',
        delete: 'Radera',
        delete_all: 'Radera Allt',
        revert: 'Återgå',
        edit: 'Redigera',
        download: 'Ladda ner',
        noFileExists: 'Ingen fil finns',
        select_file: 'Välj fil',
        upload_file: 'Ladda upp fil',
        upload_files_all: 'Ladda upp alla filer',
        upload_cancel: 'Abvrytt Ladda upp',
        view: 'Granskning',
        messages: {
            folder_default_not_found: 'Standardmappen hittades inte!',
            delete_file: 'Är du säker att du vill radera?',
            delete_all_files: 'Är du säker på att du vill ta bort ALLA filer?',
            save_Changes: 'Är du säker på att du vill fortsätta?',
            error_try_again: 'Fel inträffade under spara, försök igen!',
            file_edit_dont_allow: 'Du får inte redigera den här typen av fil!',
            desktop_file_launcher_notFound:
                'Skrivbordsfil! Launcher-hittades inte! Ladda ner från <a href=FileLuncher/FileLauncherSetup.exe style=font-weight: bold;> here <a/> ',
            reverted_Successfully: 'Filen återställdes.',
            file_upload_count_error:
                'Antalet tillagda filer överskrider den tillåtna gränsen och kan inte laddas upp!',
            default_file_delete_error: 'Det går inte att radera standardfilen!',
            default_file_edit_error: 'Det går inte att redigera standardfilen!',
            file_type_error: 'Filtypen är inte tillåten!',
            scan_upload_error: 'Ett fel inträffade under skanningen!',
            scan_service_error: 'Skannertjänsten hittades inte!',
            select_file_error: 'Inga objekt har valts!',
            file_edit_error: 'Fel inträffade under redigeringen!',
            file_upload_successfully: 'Filen laddades upp!',
            file_size_error: 'Filstorleken överskrider den tillåtna gränsen och kan inte laddas upp!',
            file_repeat_name_error: 'Filnamnet upprepas!',
            file_select_count_error: 'Antalet valda filer överskrider den tillåtna gränsen!',
            file_upload_error: 'Ett fel inträffade under uppladdningen!',
            file_name_repeated_by_param: 'Filen {repeatedName} upprepas.',
            'scan-opration-encountered-problem': 'Ett fel inträffade med skanningen',
            file_is_in_edit_mode: 'Filen är i redigeringsläge',
            dont_allow_edit_file: 'Det är inte möjligt att redigera denna typ av fil!',
            file_download_new_error: 'Nyuppladdad fil kan inte laddas ner',
            file_open_new_error: 'Nyuppladdad fil kan inte öppnas'
        },
        upload_files: 'Ladda upp filer',
        accepted_types: 'Godkända filtyper',
        preview: 'תצוגה מקדימה',
        one_file: '1 fil',
        files: 'filer',
        each: 'varje'
    },
    export_control: {
        title: 'Välj typ av export',
        titlesetting: 'titelinställning',
        showTableTitle: 'Visa titel vid export',
        backGround: 'Bakgrundsfärg',
        fontname: 'teckensnittsnamn',
        fontsize: 'Teckenstorlek',
        fontcolor: 'Teckenfärg',
        borderstyle: 'Kantlinjestil',
        bordercolor: 'Kantlinjefärg',
        columnsetting: 'kolumninställning',
        cellsetting: 'Cells Inställning',
        createfile: 'Skapa fil',
        bodysetting: 'Huvudinställning',
        exit: 'Utgång',
        columnfontstyle: 'Kolumnteckensnittsstil',
        context: 'Sammanhang',
        contextstyle: 'Sammanhang stil',
        date: 'Date',
        twodigit: 'Tal med upp till två decimalsiffror',
        fourdigit: 'Tal med upp till fyra decimalsiffror'
    },
    chart_control: {
        error_in_Get_Data: 'Att få diagramdata misslyckades ===>',
        updating_chart: 'Laddar diagrammet...',
        updating_map: 'Laddar kartan...',
        error_in_get_map_data: 'Fel vid hämtning av kartdata.',
        loading: 'Läser in...',
        exportButtonTitle: 'Exportera',
        printButtonTitle: 'Skriva ut',
        rangeSelectorFrom: 'Från',
        rangeSelectorTo: 'Till',
        rangeSelectorZoom: 'Zoom',
        downloadPNG: 'Ladda ner bild PNG',
        downloadJPEG: 'Ladda ner JPEG-bild',
        downloadPDF: 'Ladda ner PDF-dokument',
        downloadSVG: 'Ladda ner bild SVG',
        downloadXLS: 'Ladda ner XLC',
        downloadCSV: 'Ladda ner text',
        month_january: 'Januari',
        month_february: 'Februari',
        month_march: 'Mars',
        month_april: 'April',
        month_may: 'Maj',
        month_june: 'Juni',
        month_july: 'Juli',
        month_august: 'Augusti',
        month_september: 'September',
        month_october: 'Oktober',
        month_november: 'November',
        month_december: 'December',
        short_month_january: 'Jan',
        short_month_february: 'Feb',
        short_month_march: 'Mar',
        short_month_april: 'Apr',
        short_month_may: 'Maj',
        short_month_june: 'Jun',
        short_month_july: 'Jul',
        short_month_august: 'Aug',
        short_month_september: 'Sep',
        short_month_october: 'Okt',
        short_month_november: 'Nov',
        short_month_december: 'Dec',
        weekday_monday: 'Måndagen',
        weekday_tuesday: 'Tisdagen',
        weekday_wednesday: 'Onsdagen',
        weekday_thursday: 'Torsdagen',
        weekday_friday: 'Fredagen',
        weekday_saturday: 'Lördagen',
        weekday_sunday: 'Söndagen',
        printChart: 'Skriv ut diagram',
        contextButtonTitle: 'Kontextmeny för diagram'
    },
    assign_control: {
        no_name: 'inget_namn',
        delete: 'radera',
        messages: {
            item_is_repeated: 'Objekt {displayMedlem} upprepas.'
        }
    },
    tree_control: {
        expand_collapse: 'Expandera/kollaps',
        error: 'Fel',
        warning: 'varning',
        add_new: 'Lägg till',
        show: 'Visa',
        edit: 'Redigera',
        delete: 'Radera',
        messages: {
            item_delete_confirm: 'Är du säker på att du vill ta bort?',
            item_get_info_error: 'Fel uppstod under få information!'
        }
    },
    comment_control: {
        delete_confirm: 'Är du säker på att du vill ta bort?',
        back: 'tillbaka',
        save: 'Tillämpa',
        empty: 'Kommentaren får inte vara tom'
    },
    dropdown_control: {
        select: 'Välj...',
        messages: {
            items_get_error: 'Fel uppstod under get-listan!'
        }
    },
    context: {
        new_folder: 'Nytt',
        edit_folder: 'Redigera',
        delete_folder: 'Radera',
        open_in_new_window: 'Öppna i nytt fönster',
        rules: 'Hantera regler för mappar',
        messages: 'Meddelanden',
        send_message: 'Skicka meddelande',
        view_received_messages: 'Visa mottagna meddelanden',
        view_sent_message: 'Visa skickade meddelanden'
    },
    cartable_grid: {
        open_in_new_window: 'Öppna i nytt fönster',
        history: 'Historia',
        tree_history: 'Träd',
        graph_history: 'Diagram',
        time_sheet: 'Tidrapport',
        change_folder: 'Byt mapp',
        flag: 'Flagga',
        no_flag: 'Utan flagga',
        assignTask: 'Tilldela',
        FolderService_CanNotTransfer: 'Kan inte överföra till den här mappen',
        total_tasks: 'Totala uppgifter i den här mappen',
        total_unread_tasks: 'Totalt olästa uppgifter: '
    },
    create_physical_folder: {
        add_modal_header: 'Skapa mapp',
        edit_modal_header: 'Redigera mapp',
        folder_title: 'Titel',
        folder_type: 'Typ',
        dont_send_request: 'Utkast till förfrågningar',
        undone_works: 'Väntande uppgifter',
        done_works: 'Slutförda uppgifter',
        folder_title_empty: 'Mapptiteln är tom',
        folder_title_length_error: 'Titellängden bör inte överstiga 50 tecken.',
        folder_title_validate_error: 'Namn är ogiltigt.'
    },
    footer: {
        powered_by_raybpms: 'Drivs av BPMS',
        rights: 'Interfacing Technologies Corporation. Alla rättigheter förbehållna.',
        url: 'https://www.interfacing.com/',
        help: 'Hjälp',
        help_url:
            'https://www.manula.com/manuals/interfacing-technologies/bpms-user-manual/1/en/topic/1-0-the-digital-business-platform'
    },
    errors: {
        tracking_failed: 'Fel på spårning!',
        tracking_code_is_empty: 'Spårningskod är tom!',
        error_on_delete: 'Ett fel inträffade vid borttagningsåtgärd',
        please_select_pdf_file: 'Välj en pdf-fil',
        operation_is_not_allowed: 'Du är inte tillåten.',
        events_cannot_be_loaded: 'Händelser kan inte laddas!',
        server_error: 'Serverfel',
        you_are_not_allowed_to_view_form: 'du har inte behörighet att komma åt formuläret',
        access_denied: 'Åtkomst nekad',
        print_failed: 'Skriv ut misslyckades',
        error_occured: 'Ett fel inträffade, kontakta administratören.',
        task_not_found:
            'Det gick inte att hitta den här uppgiften. Det kan ha körts av en annan användare.',
        application_is_not_deployed: 'Applikationen distribueras inte.',
        save_in_progress: 'Sparning pågår för närvarande, försök igen senare.'
    },
    Lock: {
        'bpms-lock-expired': 'Låset har upphört att gälla!',
        'maximum-bpms-lock-user': 'Samtidiga användare gräns överskrider licensen!',
        'bpms-lock-failed': 'Låset hittades inte!'
    },
    slider_managment: {
        title: 'Rullande Bilder',
        slider_show_is_repeat: 'Det finns en upprepning i sliders bildordning.',
        slider_show_is_empty: 'Bildordningen är tom.',
        upload_message: 'Dina bilder är mer än gräns!',
        image_displaying: 'Bildordning',
        same_name_massage: 'En bild finns med samma namn!',
        image_is_too_large: 'Bildstorleken är för stor',
        name: 'Namn',
        image: 'Bild',
        pic_type: 'Välj en bild (jpg,png,gif) fil på mindre än 80 kB.',
        image_required: 'Välj en bild.',
        level_required: 'Välj ordningen på bilden',
        new: 'Nytt',
        edit: 'Redigera',
        empty: 'tom'
    },
    cartable_management: {
        cartable_managment_title: 'Uppgiftslistahantering – Byt namn på standardmappar',
        cartable_date_setup: 'Datuminställningar i uppgiftslistan ',
        status: 'Status:',
        exact: 'Exakt',
        limited: 'Ungefärligt',
        exact_dsc: 'Visa exakt datum och tid',
        limited_dsc: 'Visa ungefär de senaste dagarna',
        select_person: 'Välj en person, jobbposition eller en grupp att byta namn på huvudmapp',
        dont_works: 'Att göra-uppgifter mappar:',
        done_work: 'Slutförda aktivitetsmappar:',
        dont_send_request: 'Utkastbegäranden:',
        changing_folder_name: 'Byt namn på mappar',
        attention_message: 'För att se de senaste ändringarna vänligen logga in igen.',
        tree_expanded: 'Väntande mapp med den första personalen kommer att väljas efter inloggningen.',
        hide_default_user_folders: 'Dölj användarstandardmappar ( som väntande, arbeta och utkast )',
        expand_folders_by_default: 'Utöka alla användarens och personalens mappar som standard',
        tree: 'Trädmappar'
    },
    vacation_calendar_management: {
        vacation_calendar_title: 'Semesterkalender',
        year: 'År:',
        show_calendar: 'Visa kalender ',
        year_calendar: 'År',
        messages: {
            year_validate_message: 'Året är inte giltigt!'
        }
    },
    appearance_management: {
        ui_setup: 'Personalisering',
        back_image: 'Bakgrund',
        default_theme: 'Standardtema',
        banner: 'Topp Banner',
        back_image_dsc: 'För att välja inloggningssida och startsida ,använd formuläret nedan.',
        default_theme_dsc: 'Välj något av nedanstående som standard Task-List-tema.',
        banner_dsc: 'Använd formuläret nedan för att välja en webbplatsbanner. OBS: ',
        before_login: 'Före inloggningsbild:',
        after_login: 'Inloggningsbild:',
        current_img: 'Aktuell bild',
        location: 'Plats:',
        repeat: 'Upprepa',
        no_repeat: 'Utan upprepning',
        center: 'Centrera',
        back_color: 'Bakgrundsfärg',
        upload_save: 'Ladda upp & spara',
        select_theme: 'Välj tema',
        preview: 'Temaförhandsgranskning',
        set_theme: 'Tillämpa-tema',
        banner_attention: '',
        height_attention: 'Bildens höjd bör inte överstiga 149 pixlar.',
        image_attention: "Banner's bildhöjd bör vara 149 pixlar.",
        back_image_file: 'Banners standardbild',
        right_iamge: 'Bild på vänster sida:',
        center_image: 'Center Bild:',
        left_image: 'Bild på höger sida:',
        for_field: 'För Arkiverat:',
        select_image_file: 'Vänligen välj en bildfil',
        delete_current_banner: 'Ta bort aktuell banner',
        image_restore: 'Återställ standardbild',
        select_theme_for_current_user: 'Välj tema för den aktuella användaren.',
        file_type: 'Endast jpg, png eller gif-filer stöds.',
        server_wide: 'Server bred',
        user: 'Användare',
        save: 'Spara'
    },
    home_menu_management: {
        message: {
            home_ui_save_confirm:
                'Endast bildstorlek, ikonform, plats och textstorlek kommer att ändras, Är du säker på att du vill fortsätta?',
            save_sucsses_all: 'Förändringar tillämpas på allt.',
            save_confirm: 'Är du säker på att du vill fortsätta?',
            save_sucsses: 'Ändringar sparas framgångsrikt.',
            insert_number: 'För att ändra ikonen eller bildstorleken kan du bara ange numret.',
            icon_size: 'Ikonstorleken ska vara mellan 25 till 140 pixlar.',
            file_type: 'Endast jpg, png- eller gif-filer stöds.',
            icon_volume: 'ikonen/ bildstorleken bör inte överstiga 30kb.',
            insert_only_number: 'du kan bara ange siffror'
        },
        home_ui_setting: 'Heminställningar',
        items_menu: 'Meny',
        description:
            'Om du vill lägga till menyer på Startsidan, Markera objektet och ändra dess inställningar.',
        menu_setting: 'Menyinställningar',
        show_menu: 'Visa meny i Startsida',
        first_level: 'Visa i första nivån',
        not_show_top: 'Göm i toppmenyn',
        display_name: 'Visat namn',
        icon_width: 'Ikonbredd',
        icon_width_ph: 'ikon/bildbredd',
        icon_height: 'Ikonhöjd',
        icon_height_ph: 'Ikon/bildhöjd',
        home_menu_icon: 'menyikon/bild i Startsida',
        select_file: 'Vänligen välj en fil med jpg, png eller gif-format.',
        icon_view: 'ikon/bildform',
        circul: 'Cirkel',
        squared: 'Fyrkantig',
        rectangle: 'Rektangel',
        text_location: 'Textplats',
        up_image: 'Toppbild',
        center_image: 'Mittbild',
        bottom_image: 'Nedre bild',
        up_text: 'Topptext',
        center_text: 'Mellantext',
        bottom_text: 'Undertext',
        delete_image: 'Ta bort bild',
        text_color: 'Textfärg',
        float_text_color: 'Flytande textfärg',
        hilight_text_color: 'Markera textfärg',
        save_button_title: 'Alla inställningar förutom bild, visat namn och tillägg till menyn',
        doing_settup: 'Tillämpa ändringar på alla',
        check_mark: 'Visar i Startsida',
        published_date: 'Publicerat datum:'
    },
    news_management: {
        message: {
            insert_title: 'Vänligen sätt in titeln',
            title_character_length: 'Titellängden bör inte överstiga 255 tecken.',
            text_character_length: 'Textlängden bör inte överstiga 4000 tecken.',
            insert_text: 'Vänligen infoga texten.',
            number_priority: 'Välj prioriteten med siffror.',
            number_large: 'Det valda numret för nyhetsprioritet är mer än 4 siffror.',
            file_volume: 'Filstorleken bör vara mindre än 100kb.'
        },
        news: 'Nyheter',
        add: 'Lägg till',
        title: 'Titel',
        text: 'Text',
        text_color: 'Textfärg',
        enable_show: 'Synligt för vanliga användare',
        news_setup: 'Nyhetsupplägg',
        news_image: 'Nyhetsbild',
        news_priority: 'Nyhetsprioritet',
        current_pic: 'Uppladdade bilder',
        delete_curr_pic: 'Ta bort aktuell bild',
        pic_type: 'Ett jpg- png- eller gif-filformat mindre än 100 kb.',
        id: 'ID',
        createdAt: 'Datum',
        username: 'Användarnamn',
        control: 'Kontroll'
    },
    message_management: {
        messages: {
            repeated_recipient: 'Mottagaren upprepas',
            title_is_empty: 'meddelandetiteln är tom, Vill du fortsätta?',
            success_send: 'Meddelandet skickades',
            unselected_recipient: 'Ingen mottagare har valts.'
        },
        reply_message: 'Svara',
        title: 'Titel:',
        message_text: 'Meddelandetext:',
        attachment: 'Bilagor',
        recipients: 'Mottagare',
        select: 'Välj ...',
        add_to_list: 'Lägg till i listan',
        back: 'Tillbacka',
        sended: 'Skickat meddelande',
        sended_by: 'Skickat av:',
        send_date: 'Skickad tid:',
        message_show: 'Visa meddelande',
        no_permission: 'Du får inte komma åt!',
        subject: 'Ämne:',
        date_time_send: 'Skickad tid och datum',
        sender: 'Avsändare',
        receiver: 'Mottagare',
        menu_title: 'meddelanden',
        received: 'Mottaget meddelande',
        send_email: 'Skicka epost',
        send_sms: 'Skicka sms'
    },
    task_assign: {
        messages: {
            success_assign: 'Framgångsrikt vidarebefordrat.',
            unselected_recipient: 'Ingen mottagare har valts.'
        },
        assign: 'Vidarebefordra - Tilldela',
        recipients: 'Mottagare',
        description: 'Beskrivning',
        last_dsc: 'Använd aktuell beskrivning',
        out_work: 'Avsluta aktuell uppgift',
        keep_sender: 'Behåll nuvarande avsändare'
    },
    process_log: {
        status: 'Status',
        event: 'Fall',
        user: 'Användare',
        user_position: 'Job Position',
        work_title: 'Titel på jobbet',
        responsible: 'Ansvarig',
        create_date: 'Startdatum för uppgift',
        done_date: 'Arbetsuppgiftens slutdatum',
        folder_name: 'Mappnamn',
        start_task: 'Startaktivitet',
        human_task: 'Mänsklig aktivitet',
        fork_task: 'Kluven aktivitet',
        join_task: 'Förenade aktivitet',
        system_task: 'Systemaktivitet',
        common_task: 'Gemensam aktivitet'
    },
    timesheet_register: {
        messages: {
            time_reng: 'Startdatum får inte överskrida slutdatumet',
            select_row: 'Vänligen välj en rad.',
            delete_timesheet_confirm: 'Är du säker på att du vill ta bort aktuell tidrapport?',
            save_time_confirm:
                'Den här perioden är vald tor annan uppgift. Vill du ersätta den för nuvarande taks?',
            can_not_edit: 'Den här perioden sparas för en slutförd aktivitet. Redigera är inte tillåtet.',
            date_not_valid: 'Datum är inte giltigt'
        },
        errors: {
            error_in_remaining_time: 'Fel uppstod under återstående tid',
            get_timesheet: 'Fel uppstod vid tidrapportsexport',
            error_in_save: 'Fel inträffade under spara',
            error: 'Fel',
            error_in_delete: 'Fel uppstod vid borttagning'
        },
        work_dsc: 'Arbetsbeskrivning',
        date: 'Datum',
        start_time: 'Starttid',
        end_time: 'Sluttid',
        remaining_time: 'Återstående tid',
        description: 'Beskrivning',
        timesheet: 'Tidrapport',
        current_work: 'Tilldela till aktuell uppgift',
        persian_date: 'Datum',
        weekly_plan: 'Veckoplan',
        prev_week: 'Föregående vecka',
        next_week: 'Nästa vecka',
        plus: 'Lägg till',
        all_work: 'Allt nuvarande arbete',
        delete: 'Radera'
    },
    delegation_management: {
        messages: {
            select_user: 'Vänligen välj remissanvändaren.',
            select_date: 'Vänligen välj start- och slutdatum',
            select_folder: 'Vänligen välj de mappar som delegator kan visa',
            compare_fromDate_toDate: 'Slutdatum är större än startdatum'
        },
        folder_to_delegate: 'Mappar som delegerar kan visa:',
        delegations: 'Delegationer',
        delegation_edit: 'Redigera delegering',
        delegation_user: 'Delegera användare:',
        all_time: 'Permanent',
        from_date: 'Från datum:',
        to_date: 'Hitills',
        start_date: 'Startdatum',
        finish_date: 'Slutdatum',
        delegate_to: 'Delegera till:',
        more_item: 'Fler objekt',
        all_item: 'Alla pbjekt',
        remove_delegate: 'Ta bort delegat',
        delegation_create: 'Lägg till',
        no_records: 'Inga uppgifter matcha dina sökord',
        delegation_add: 'Lägg till delegering',
        delegation_user_placeholder: 'Skriv för att söka',
        more_users: 'Det finns fler resultat, använd bättre sökord'
    },
    rule_criterias: {
        new_rule: 'Ny regel',
        edit_rule: 'Redigera regel',
        no_permission: 'Du behöver en Tillåtelse!',
        tag_error: 'Fel uppstod vid tagguppdatering!',
        flag_error: 'Fel uppstod vid flagguppdatering!',
        no_actions: 'Ingen handling!',
        action: 'Åtgärd',
        rule_name: 'Regelnamn',
        operation: 'Drift',
        select_folder: 'Välj mapp'
    },
    ray_payment: {
        messages: {
            close_pay_win: 'Stäng först betalningsfönstret.'
        },
        wait_status: 'Väntar på betalningen.',
        payed_status: 'Betalas',
        failed_status: 'Fel i betalningen',
        not_payed_status: 'Ej betalad',
        currency: ' Rial',
        through: 'Genom',
        pay: 'Betalning'
    },
    ray_datetimepicker: {
        date_not_valid: 'Datum är ogiltigt!',
        time_not_valid: 'Tiden är ogiltig!',
        ok: 'Ok'
    },
    logs_management: {
        show_details:
            'Aktivera visa feldetaljer. Genom att aktivera detta alternativ användare kommer att se hela tekniska felmeddelandet på varje fel förekomst.',
        error_number: 'Antal',
        date: 'Skapad vid',
        level: 'Nivå',
        staff_id: 'Personal-Id',
        application_name: 'Ansökningsnamn',
        error_log: 'Felloggar',
        error_message: 'Felmeddelande',
        api_log: 'API-anropslogg',
        id: 'Id',
        session: 'Session',
        api: 'API',
        method: 'Metod',
        severity: 'Allvarlighetsgrad',
        search: 'Sök...',
        key: 'Nyckelparameter',
        duration: 'Varaktighet (ms)',
        epc_log: 'EPC-samtalslogg',
        user_log: 'Resultatkod',
        result: 'Resultatkod',
        application_log: 'Applikationslogg',
        copy_clipboard: 'Kopiera till urklipp',
        user: 'Användare',
        login_datetime: 'Logga ut Datum Tid',
        logout_datetime: 'Logga ut Datum Tid',
        server_name: 'Server Name',
        active_connections: 'Aktiva anslutningar',
        send_message_all: 'Skicka meddelande till alla',
        send_message_connection: 'Skicka meddelande till denna anslutning',
        start_datetime: 'Startdatum Tid'
    },
    rule_management: {
        title: 'Hantera mappars regler',
        operation: 'Drift',
        rule_name: 'Namn',
        add_new_rule: 'Ny regel',
        edit_rule: 'Redigera regel',
        are_you_sure: 'Är du säker på att ta bort regeln?',
        rule_modal: {
            name: 'Regelnamn',
            subject: 'Ämne',
            task_type: 'Typ',
            create_date: 'Skapa datum',
            response_date: 'Svarsdeadline',
            done_date: 'Klart datum',
            priority: 'Prioritet',
            flag: 'Flagga',
            read: 'Läs',
            transcript: 'Avskrift',
            app: 'Ansökan',
            in_folder: 'Mapp',
            not_selected_option: 'Ej valt',
            validation: {
                name: 'Namn krävs'
            },
            clear_tooltip: 'klart'
        }
    },
    today: 'I dag',
    form: 'Form',
    form_behavior: 'Formbeteende',
    close_form_warning:
        'Är du säker på att du vill avsluta? Alla förändringar kommer att gå förlorade.',
    yes: 'Ja',
    no: 'Nej',
    secret_code: {
        title: 'Godkännandeautentisering',
        message: 'Vi har skickat en hemlig kod till din e-post. Kopiera och klistra in det här.',
        code: 'Koda:',
        legal_sentence:
            'Genom att godkänna med din e-signatur signerar du dokumentet elektroniskt. Du samtycker till att</br>din elektroniska signatur har samma juridiska giltighet och effekt som din handskrivna signatur på</br>dokumentet, och att den har samma betydelse som din handskrivna signatur.',
        expired: 'Koden är ogiltig eller har löpt ut',
        required: 'Koden krävsהקוד נדרש',
        submit: 'Skicka in',
        cancel: 'Annullera'
    },
    user_settings: 'Användarinställningar',
    audit_trail: {
        title: 'Verifieringskedja',
        operation_time: 'Drifttid',
        application_name: 'Drifttid',
        user: 'Användare',
        operation: 'Drift',
        entity_name: 'Entitetsnamn',
        entity_definition: 'Entitetsdefinition',
        task_subject: 'Uppgiftsämne',
        field_name: 'Fält namn',
        old_value: 'Gammalt värde',
        new_value: 'Nytt värde'
    }
}
export default localeMessages
