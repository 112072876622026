import utils from "jsf/utils.js";
import FileManager from "devextreme/ui/file_manager"
import ObjectFileSystemProvider from "devextreme/file_management/object_provider"
import CustomFileSystemProvider from "devextreme/file_management/custom_provider"

(function ($, undefined) {
    $.widget("ui.rayFileAttachment", $.ui.rayControl, {
        lastValue: null,
        validators: [],
        activeFiles: [],
        deletedFiles: [],
        fileManager: {},
        maxFileCount: 0,
        maxFileSize: 0,
        fileExtensions: "",
        viewableExtensions: "",
        noDataTextMess: "",
        numberOfFilesBeingUploaded: 0,

        _create: function () {
            this.deleteItems = [];
            this.validators = [];
            const ctrl = this;
            const cs = ctrl.options.controlState;

            ctrl.maxFileCount = parseInt(ctrl.element.attr("maxfilecount"));
            ctrl.maxFileSize = ctrl.element.attr("maxfilesize");
            ctrl.fileExtensions = (!ctrl.element.attr("fileextensions") ? "" : ctrl.element.attr("fileextensions"));
            ctrl.viewableExtensions = ["jpg", "jpeg", "gif", "png", "pdf", "xml"];

            if (localStorage.dbpDebugMode === "true")
                console.log("FileAttachment", cs.Id, ctrl.maxFileCount, ctrl.maxFileSize, ctrl.fileExtensions);
            const labelEl = ctrl.element.find("label");
            const label = labelEl[0].outerHTML;
            ctrl.element.empty();
            ctrl.element.append(label);
            const tabEl = document.createElement("div");
            tabEl.classList.add("file-attachment");
            if (ctrl.maxFileCount === 1)
                tabEl.classList.add("faonefile");

            ctrl.element.append(tabEl);
            ctrl.noDataTextMess = ctrl.maxFileCount > 1 ? 'Drag files here to upload' : 'Drag file here to upload';

            var fileSelectionItemsArray = [{ name: 'download', visible: cs.Behavior.Enabled && cs.Behavior.DownloadEnabled }, { name: 'delete', visible: cs.Behavior.Enabled && cs.Behavior.DeleteEnabled }, { name: 'refresh', visible: cs.Behavior.Enabled }];
            var contextMenuItemsArray = [{ name: 'download', visible: true}, { name: 'delete', visible: cs.Behavior.Enabled && cs.Behavior.DeleteEnabled }, { name: 'refresh', visible: cs.Behavior.Enabled }];

            fileSelectionItemsArray.push({
                widget: "dxButton",
                options: {
                    text: i18n.$t('attachment_control.preview'),
                    icon: "image",
                },
                action: "preview",
                location: "before",
                visible: cs.Behavior.Enabled && cs.Behavior.DownloadEnabled,
                onClick: function (e) { ctrl._previewItem(ctrl.fileManager.getSelectedItems()[0]); }
            });
            contextMenuItemsArray.push({
                text: i18n.$t('attachment_control.preview'),
                icon: "image",
                action: "preview",
                visible: true,
            });
            if (ctrl.maxFileCount > 1) {
                fileSelectionItemsArray.push('clearSelection');
            }

            var fileInfoMessage = '';
            if (ctrl.maxFileCount > 1) {
                fileInfoMessage = `Max ${ctrl.maxFileCount} ${i18n.$t('attachment_control.files')}`;
                if (ctrl.maxFileSize && ctrl.maxFileSize > 0) {
                    fileInfoMessage += `, ${ctrl.maxFileSize}MB ${i18n.$t('attachment_control.each')}`;
                }
            } else {
                fileInfoMessage = i18n.$t('attachment_control.one_file');
                if (ctrl.maxFileSize && ctrl.maxFileSize > 0) {
                    fileInfoMessage += `, max ${ctrl.maxFileSize}MB`;
                }
            }

            var fileTypeMessage = '';
            if (ctrl.fileExtensions) {
                let i = 0;
                //Code to transform list of extension from '.jpg.png.pdf.docx' to '.jpg, .png, .pdf, .docx' for the message
                var transformedListOfExtensions = ctrl.fileExtensions.replace(/\./g, function (match) {
                    return match === "." ? (i++ === 0 ? '.' : ', .') : ', .';
                })
                fileTypeMessage = `<br>${i18n.$t('attachment_control.accepted_types')}: ${transformedListOfExtensions}`;
            }
            var toolbarItems = [
                {
                    name: 'upload',
                    text: ctrl.maxFileCount > 1 ? i18n.$t('attachment_control.upload_files') : i18n.$t('attachment_control.upload_file'),
                    disabled: false
                },
                {
                    template: `<div class="fileAttachmentInfoMessage"><b>${fileInfoMessage}</b>${fileTypeMessage}</div>`,
                    visible: cs.Behavior.Enabled,
                },
                'refresh'
            ];


            ctrl.fileManager = new FileManager(ctrl.element.find(".file-attachment"), {
                name: `fileManager${cs.Id}`,
                elementAttr: {
                    id: `fileManager${cs.Id}`
                },
                fileSystemProvider: ctrl.activeFiles,
                height: ctrl.maxFileCount > 1 ? 300 : 130,
                selectionMode: ctrl.maxFileCount > 1 && cs.Behavior.Enabled ? "multiple" : "single",
                permissions: {
                    create: false,
                    copy: false,
                    move: false,
                    delete: cs.Behavior.Enabled && cs.Behavior.DeleteEnabled,
                    rename: false,
                    upload: cs.Behavior.Enabled && cs.Behavior.UploadEnabled,
                    download: cs.Behavior.Enabled && cs.Behavior.DownloadEnabled
                },
                notifications: {
                    showPopup: false,
                },
                toolbar: {
                    items: toolbarItems,
                    fileSelectionItems: fileSelectionItemsArray
                },
                contextMenu: {
                    items: contextMenuItemsArray,
                },

                onContextMenuItemClick: function (args) {
                    if (args.itemData.action == 'preview') {
                        ctrl._previewItem(ctrl.fileManager.getSelectedItems()[0]);
                    }
                },

                onSelectionChanged: function (e) {
                    if (!e.selectedItems || e.selectedItems.length == 0) return;
                    let showPreviewButton, showDownloadButton = true;
                    if (e.selectedItems.length > 1) {
                        showPreviewButton = showDownloadButton = false;
                    } else {
                        let item = e.selectedItems[0];
                        let itemExtension = item.name.split('.').pop().toLowerCase();

                        if (!item.dataItem || !item.dataItem.dataItem || item.dataItem.dataItem.mode == "Create") {
                            showPreviewButton = showDownloadButton = false;
                        }
                        if ($.inArray(itemExtension, ctrl.viewableExtensions) == -1) showPreviewButton = false;
                    }

                    let toolbarItems = e.component.option('toolbar.fileSelectionItems');
                    let contextMenuItems = e.component.option('contextMenu.items');

                    if (cs.Behavior.DownloadEnabled) {
                        let toolbarDownloadItem = toolbarItems.find(x => x.name == 'download');
                        if (toolbarDownloadItem) toolbarDownloadItem.visible = showDownloadButton;

                        let toolbarPreviewItem = toolbarItems.find(x => x.action == 'preview');
                        if (toolbarPreviewItem) toolbarPreviewItem.visible = showPreviewButton;

                        let contextMenuDownloadItem = contextMenuItems.find(x => x.name == 'download');
                        if (contextMenuDownloadItem) contextMenuDownloadItem.visible = showDownloadButton;

                        let contextMenuPreviewItem = contextMenuItems.find(x => x.action == 'preview');
                        if (contextMenuPreviewItem) contextMenuPreviewItem.visible = showPreviewButton;
                    }

                    e.component.option('toolbar.fileSelectionItems', toolbarItems);
                    e.component.option('contextMenu.items', contextMenuItems);
                },
                // double click to open - first check if it's allowed for the file type (viewableExtensions)
                onSelectedFileOpened: function (e) { ctrl._previewItem(e.file); },
                // before uploading file check all limitations
                onFileUploading: function (e) {
                    if (localStorage.dbpDebugMode === "true")
                        console.log("onFileUploading", e.fileData, ctrl.maxFileCount, ctrl.maxFileSize, ctrl.fileExtensions);
                    const filename = e.fileData.name;
                    const fileext = filename.split(".").pop();
                    const filesize = e.fileData.size / 1024 / 1024;
                    // file exists
                    if (ctrl.activeFiles.find(f => f.name.toLowerCase() === filename.toLowerCase()) != undefined) {
                        e.errorCode = 1;
                        e.cancel = true;
                        return;
                    }
                    // check extensions
                    if (ctrl.fileExtensions && ctrl.fileExtensions.length > 0 && $.inArray(fileext, ctrl.fileExtensions.split(".")) === -1) {
                        e.errorCode = 5;
                        e.cancel = true;
                        return;
                    }
                    // check count
                    if (ctrl.activeFiles.length + ctrl.numberOfFilesBeingUploaded >= ctrl.maxFileCount) {
                        e.errorCode = 32767;
                        e.errorText = `Maximum ${ctrl.maxFileCount} file(s) can be uploaded.`;
                        e.cancel = true;
                        return;
                    }
                    // check size
                    if (ctrl.maxFileSize && ctrl.maxFileSize > 0 && filesize > ctrl.maxFileSize) {
                        e.errorCode = 6;
                        e.cancel = true;
                        return;
                    }
                    ctrl.numberOfFilesBeingUploaded++;
                },
                onFileUploaded: function (e) {
                    ctrl.numberOfFilesBeingUploaded = 0;
                },
                onToolbarItemClick: function (e) {
                    if (e.itemData == 'refresh') {
                        // Here we can control the refresh button
                        // But refresh is not doing anything, so we need to find a way to refresh the file manager
                        // to make the ui adapt to the screen size
                        //console.log("refresh");
                    }
                },
            });

            $('[data-toggle="tooltip"]').tooltip();

            //Deal with no data text
            ctrl.updateNoDataText();
        },
        _init: function () { },

        _previewItem: function (eventFile) {
            var _this = this;
            const file = eventFile.dataItem.dataItem;
            let extension = file.name.split(".").pop();
            if (extension !== undefined)
                extension = extension.toLowerCase();
            if ($.inArray(extension, _this.viewableExtensions) > -1 && file.mode !== "Create") {
                const id = file.id;
                window.open(`api/file/control/get/${id}/1`, "_blank");
            }
        },

        updateBtnUploadVisibility: function () {
            var ctrl = this;
            var toolbarItems = ctrl.fileManager.option('toolbar.items');
            var uploadDisabled = ctrl.activeFiles.length - ctrl.deletedFiles.length >= ctrl.maxFileCount;

            if (toolbarItems.find(x => x.name == 'upload').disabled != uploadDisabled) {
                toolbarItems.find(x => x.name == 'upload').disabled = uploadDisabled;
                ctrl.fileManager.option('toolbar.items', toolbarItems);
            }
        },

        updateNoDataText: function () {
            /*
            var ctrl = this;
            if (!ctrl.options.controlState.Behavior.Enabled) return;
            var changeNoDataText = window.setInterval(function () {
                var noDataEl = ctrl.element.find('span.dx-datagrid-nodata');
                if (noDataEl && noDataEl.text()) {
                    noDataEl.text(ctrl.noDataTextMess);
                    clearInterval(changeNoDataText);
                }
            }, 500);
            */
        },

        setState: function (cs) {
            //console.log("FA set state", cs);
            const visible = cs.Behavior.Visible;
            const obj = this.element;

            if (visible != null && visible === false)
                obj.addClass("hidden");
            else
                obj.removeClass("hidden");

            //if (!cs.Behavior.Enabled)
            //    ctrl.fileManager.option("disabled", true);

            this._setValue();
            this._setTabIndex(cs.Behavior.Enabled);

            this._setVisible(cs.Behavior.Visible);
            this.setCaption(cs);
        },

        onChange: function (obj) {
        },

        _setValue: function () {
            const ctrl = this;
            const cs = ctrl.options.controlState;

            if (typeof (cs.Value) === "string")
                cs.Value = JSON.parse(cs.Value);

            const csVal = cs.Value;

            if (localStorage.dbpDebugMode === "true")
                console.log("FA set value 1", cs.Id, cs.Value);
            // active files storage
            ctrl.activeFiles = [];
            // deleted files storage
            ctrl.deletedFiles = [];

            for (let f of csVal.FilesContent)
                if (f.Id.length > 0 && f.Name.length > 0 && !f.IsDelete)
                    ctrl.activeFiles.push({ id: f.Id, name: f.Name, isDirectory: false, size: f.Size, dateModified: f.Modified, mode: "None" });

            const objectProvider = new ObjectFileSystemProvider({
                data: ctrl.activeFiles
            });

            ctrl.updateBtnUploadVisibility();
            const customProvider = new CustomFileSystemProvider({
                getItems: function (pathInfo) {
                    //if (localStorage.dbpDebugMode === "true")
                    //    console.log("FileAttachment get items", ctrl.activeFiles.length);
                    return objectProvider.getItems(pathInfo);
                },

                deleteItem: (item) => {
                    const f = item.dataItem.dataItem;
                    const ctrl = this;
                    const cs = ctrl.options.controlState;
                    ctrl.fileManager.option('notifications.showPanel', false);
                    if (typeof (cs.Value) == 'string') { cs.Value = JSON.parse(cs.Value); }

                    const fileRemoveUploadedRequestModel = {
                        fileName: f.name,
                        groupId: cs.Value.GroupId
                    };

                    const url = "api/file/control/removeUploaded";

                    utils.callWebAPI(url, fileRemoveUploadedRequestModel, () => {
                        ctrl.deletedFiles.push({ id: f.id, name: f.name, isDirectory: false, size: f.size });
                        //if (localStorage.dbpDebugMode === "true")
                        //    console.log("delete item", f, ctrl.deletedFiles);

                        // let know form that value has changed
                        let result = objectProvider.deleteItems([item]);
                        if (localStorage.dbpDebugMode === "true")
                            console.log("FileAttachment Delete", cs.Id, ctrl.activeFiles.length, ctrl.deletedFiles.length, item);
                        ctrl._trigger("rayControlChanged", {}, cs);
                        ctrl.updateBtnUploadVisibility();

                        setTimeout(() => {
                            //if (localStorage.dbpDebugMode === "true")
                            //    console.log("FileAttachment Refresh", cs.Id);

                            ctrl.fileManager.option('notifications.showPanel', true);
                            ctrl.fileManager.resetOption("focusedItemKey");
                            ctrl.fileManager.refresh();
                        }, 500)

                        return result;
                    }, (data) => {
                        ctrl.fileManager.option('notifications.showPanel', true);
                        //console.log(data);
                        for (let i = 0; i < data.length; i++) {
                            const item = data[i];
                            for (let j = 0; j < item.Errors.length; j++) {
                                const errorItem = item.Errors[j];
                                that.errors.push(i18n.$t(errorItem.ErrorMessage));
                            }
                        }
                    }, 'POST');
                },

                downloadItems: (items) => {
                    //console.log("download items", items);
                    const file = items[0].dataItem.dataItem;
                    if (items.length === 1 && file.mode !== "Create")
                        window.open(`api/file/control/get/${file.id}/0`);
                },

                uploadFileChunk: (fileData, chunksInfo, destinationDir) => {
                    //console.log("upload file", fileData, chunksInfo, destinationDir);
                    var deferred = $.Deferred();

                    const data = new FormData();
                    data.append("file", chunksInfo.chunkBlob);

                    if (chunksInfo.chunkIndex === 0)
                        chunksInfo.customData.fileGuid = utils.createGUID();

                    const fileDataParam = {
                        BytesUploaded: chunksInfo.bytesUploaded,
                        ChunkIndex: chunksInfo.chunkIndex,
                        ChunkTotalCount: chunksInfo.chunkCount,
                        FileIndex: chunksInfo.fileIndex,
                        FileName: fileData.name,
                        FileSize: fileData.size,
                        FileType: fileData.type,
                        FileGuid: chunksInfo.customData.fileGuid,
                        ControlId: cs.Id,
                        PageInstanceId: ctrl.options.bpmsAppForm.dto.formState.PageInstanceId,
                        GroupId: cs.Value.GroupId
                    };

                    data.append("fileDataParam", JSON.stringify(fileDataParam));

                    const url = "api/filecontrol/upload";

                    fetch(url, {
                        method: "POST",
                        body: data
                    }).then(function (res) {
                        if (res.status === 200) {
                            deferred.resolve();
                            if (chunksInfo.chunkIndex === chunksInfo.chunkCount - 1) {
                                ctrl.activeFiles.push({
                                    id: chunksInfo.customData.fileGuid,
                                    name: fileData.name,
                                    isDirectory: false,
                                    size: fileData.size,
                                    mode: "Create"
                                });
                                // let know form that value has changedr); });

                                ctrl.updateBtnUploadVisibility();
                                ctrl._trigger("rayControlChanged", {}, cs);
                            }
                        }
                        else {
                            deferred.reject({
                                errorId: 27000,
                                errorText: "Failed to update",
                                fileItem: destinationDir
                            });
                            ctrl.fileManager.abortFileUpload();
                        }
                    }).catch(function (err) { deferred.reject(err); });

                    return deferred.promise();
                }
            });

            ctrl.fileManager.option("fileSystemProvider", customProvider);
        },

        getControlValue: function () {
            const ctrl = this;
            const cs = ctrl.options.controlState;

            if (typeof (cs.Value) === "string")
                cs.Value = JSON.parse(cs.Value);

            if (localStorage.dbpDebugMode === "true")
                console.log("FileAttachment GetValue", cs.Id, ctrl.activeFiles, ctrl.deletedFiles);
            const lst = [];
            ctrl.activeFiles.forEach(item => {
                lst.push({
                    Id: item.id,
                    VisualId: item.id,
                    Name: item.name,
                    Value: "",
                    IsDelete: false,
                    IsDefaultFile: false,
                    FilePath: "",
                    IsPresentInDms: false,
                    ChangeMode: item.mode,
                    ParentId: "",
                    Size: item.size
                });
            });
            ctrl.deletedFiles.forEach(item => {
                lst.push({
                    Id: item.id,
                    VisualId: item.id,
                    Name: item.name,
                    Value: "",
                    IsDelete: true,
                    IsDefaultFile: false,
                    FilePath: "",
                    IsPresentInDms: false,
                    ChangeMode: "SetDelete",
                    ParentId: ""
                });
            });

            cs.Value.FilesContent = lst;
            return JSON.stringify(cs.Value);
        },

        requiredFieldValidator: function () {
            return true;
        }
    });
})(jQuery);
