/*/// <reference path="../../../node_modules/@types/jquery/index.d.ts" />*/
/// <reference path="dts/payload.d.ts" />

export class LocalizationHelper {
    private i18n: VueI18n;

    constructor(vueI18N: VueI18n) {
        this.i18n = vueI18N;
    }

    public $t(key: string, value: Object = null): string {
        return this.i18n.t(key, value);
    }

    public $tc(key: string, count: number, obj: Object = null): string {
        return this.i18n.tc(key, count, obj);
    }

    //public getDateByCulture(input: any) {
    //    let date: any = new Date(input);
    //    let options: any;
    //    let calendarType: any;
    //    let text: any = '';

    //    calendarType = payload.currentLocale.Culture;

    //    let weekday = () => {
    //        options = {weekday: 'long'};
    //        return date.toLocaleDateString(calendarType, options);
    //    };
    //    let month = () => {
    //        options = {month: 'long'};
    //        return date.toLocaleDateString(calendarType, options);
    //    };
    //    let year = () => {
    //        options = {year: 'numeric'};
    //        return date.toLocaleDateString(calendarType, options);
    //    };
    //    let day = () => {
    //        options = {day: 'numeric'};
    //        return date.toLocaleDateString(calendarType, options);
    //    };

    //    if (payload.currentLocale.Language === 'fr')
    //        return `le ${weekday()} ${day()} ${month()}‌ ${text} ${year()}`;

    //    return `${weekday()}, ${day()} ${month()}‌ ${text} ${year()}`;
    //}
}

declare interface VueI18n {
    t(key: string, value: Object): string;

    tc(key: string, count: number, obj: Object): string;
}
