<template>
    <div>
        <div class="jquery-ui-scope view bpms-timesheetForm"></div>
    </div>
</template>

<script type="text/javascript">
    import loading from '../widgets/loading/loading.vue';
    import { timesheet } from 'jsf/vm.timesheet.js';
    export default {
        data() {
            return {}
        },
        methods: {

        },
        mounted() {
            const container = $('.bpms-timesheetForm', this.$el);
            const params = {
                element: container,
                fromMenu: true
            };
            const obj = {
                id: '',
                cmp: false
            };

            timesheet().activate(obj, params);
        },
        components: {
            loading
        }
    }
</script>
