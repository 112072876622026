import Vue from 'vue';
import VueRouter from 'vue-router';
import Form from './component/forms/forms.vue';
import TaskForm from './component/taskform/taskForm.vue';
import Public from './component/public/public.vue';
//import Private from './component/private/private.vue';
import Slider from './component/slidermanagement/Main.vue';
//import CartableAdmin from './component/CartableAdmin/CartableAdmin.vue';
import CartableGrid from './component/cartableGrid/cartableGrid.vue';
import VacationCalendar from './component/calendarmanagement/CalendarManagement.vue';
import UI from './component/appearancemanagement/Main.vue';
import Settings from './component/settings/Settings.vue';
//import AddNews from './component/NewsManagement/NewsManagement.vue';
import SendMessage from './component/message/SendMessage.vue';
import MessageGrid from './component/messageGrid/MessageGrid.vue';
import ViewMessage from './component/message/ViewMessage.vue';
import TimeSheet from './component/timeSheet/timesheet.vue';
import RuleManagement from "./component/rulemanagement/RuleManagement.vue";
import Delegations from "./component/delegation/DelegationList.vue";
import TimeSheetManagement from "./component/timesheet/TimeSheetManagement.vue";
import HomeManagement from "./component/homemanagement/HomeManagement.vue";
import CartableAdmin from './component/cartableadmin/Main.vue';
import TrackingManagement from './component/tracking/TrackingManagement.vue';
import MainNews from './component/newsmanagement/Main.vue';
import Error from './component/Error.vue';
import utils from 'jsf/utils.js';
// Logs
import BPMSLogs from "./component/bpmslogs/BPMSLogs.vue";
import Logs from "./component/bpmslogs/Logs.vue";
import LogsAPI from "./component/bpmslogs/LogAPI.vue";
import LogsEPC from "./component/bpmslogs/LogEPC.vue";
import ActiveConnections from "./component/userLogs/ActiveConnections.vue";
import UserLog from "./component/userLogs/UserLog.vue";
import AuditTrail from "./component/auditTrail/AuditTrail.vue";

Vue.use(VueRouter);

const routes = [
    { path: '/:parentId([a-zA-Z0-9_-]{36})', component: Public, name: 'home' },
    { path: '/', component: Public, name: 'home-menu' },
    { path: '/true', component: Public, name: 'home-menu' },
    { path: '/param/:startparam', component: Public, name: 'home-menu', props: true },
    { path: '/form/:id/:isTopMenu?', component: Form, name: "form" },
    { path: '/taskForm/:id/:folderId/:taskTypeId/:status/:isDraft/:folderName/:parentId?', component: TaskForm, name: "taskForm" },
    { path: '/tracking', component: TrackingManagement, name: "tracking" },
    { path: '/tasks/:folderId/:folderName/:isDraft/:parentId?/:uniqueParameter?', component: CartableGrid, name: 'tasksGrid' },
    { path: '/slider-managemet', component: Slider, name: "slider" },
    { path: '/cartable-admin', component: CartableAdmin, name: "cartable-admin" },
    { path: '/calendar-management', component: VacationCalendar, name: "calnedar-management" },
    { path: '/appearance-management', component: UI, name: "appearance-management" },
    { path: '/settings', component: Settings, name: "settings" },
    { path: '/news-management', component: MainNews, name: "news-management" },
    { path: '/send-message', component: SendMessage, name: "send-message" },
    { path: '/message-grid/:isReceivedMessage?', component: MessageGrid, name: "message-grid" },
    { path: '/view-message/:messageId?/:isReceiveMessage?', component: ViewMessage, name: "view-message" },
    { path: '/timesheet', component: TimeSheet, name: "timesheet" },
    { path: '/timesheet/:id/:cmp', component: TimeSheet, name: 'timesheet-legacy' },
    { path: '/logs', component: BPMSLogs, name: 'BPMSLogs' },
    { path: '/auditTrail', component: AuditTrail, name: 'AuditTrail' },
    { path: '/activeConnections', component: ActiveConnections, name: 'ActiveConnections' },
    { path: '/userLog', component: UserLog, name: 'UserLog' },
    { path: '/applogs', component: Logs, name: 'Logs' },
    { path: '/logapi', component: LogsAPI, name: 'LogsAPI' },
    { path: '/logepc', component: LogsEPC, name: 'LogsEPC' },
    { path: '/DelegationsUserControl', component: Delegations, name: "delegate-user" },
    { path: '/taskForm', component: TaskForm, name: "taskFormtop" },
    { path: '/rule-management/:pId', component: RuleManagement, name: "rule-management" },
    { path: '/delegation-management', component: Delegations, name: "delegation-management" },
    { path: '/timesheet-management', component: TimeSheetManagement, name: "timesheet-management" },
    { path: '/home-management', component: HomeManagement, name: "home-management" },
    { path: '/error/:code', component: Error, name: "error" }
];

const routePermissionMapp = {
    "taskForm": ["not-annonymous", "ShowTasks"],
    "tracking": ["ViewProcessTracking"],
    "dms": ["CMSUser"],
    "dmsManage": ["CMSAdmin"],
    "tasksGrid": ["ShowTasks"],
    "slider": ["Slider"],
    "cartable-admin": ["AdminRole", "CartableAdmin"],
    "calnedar-management": ["not-annonymous"],
    "appearance-management": ["AdminRole", "UICustomization"],
    "home-management": ["AdminRole", "HomeUICustomization"],
    "news-management": ["AdminRole", "NewsManagement"],
    "send-message": ["SendBpmsMessage"],
    "message-grid": ["not-annonymous"],
    "view-message": ["not-annonymous"],
    "timesheet": ["TimeSheet"],
    "delegation-management": ["Delegation"],
    "delegationCreate": ["Delegation"],
    "delegationEdit": ["Delegation"],
    "timesheet-legacy": ["TimeSheet"],
    "BPMSLogs": ["ViewErrors"],
    "AuditTrail": ["ViewErrors"],
    "ActiveConnections": ["ViewErrors"],
    "UserLog": ["ViewErrors"],
    "LogsAPI": ["ViewErrors"],
    "LogsEPC": ["ViewErrors"],
    //"ChangePassword": ["not-annonymous"],
    "delegate-user": ["Delegation"],
    "rule-management": ["not-annonymous"],
    "timesheet-management": ["TimeSheet"]
};

const userPermissions = JSON.parse(JSON.stringify(payload.permissions));
const routerInstance = new VueRouter({ routes: routes });

routerInstance.beforeEach((to, from, next) => {
    let isAllowed = true;
    routerInstance.from = from;

    if (typeof payload.CurrentUser !== 'undefined') {
        if (to.path === '/pending' || to.path === '/completed' || to.path === '/draft') {
            utils.callWebAPI("api/tasks" + to.path, null, (data) => {
                if (data) {
                    router.push({
                        name: 'tasksGrid',
                        params: {
                            folderId: data.Id,
                            folderName: data.Text
                        }
                    });
                    document.querySelectorAll("#sidebar .k-state-selected").forEach(e => e.classList.remove('k-state-selected'));
                    document.querySelectorAll("#sidebar .sidebar-tree .k-item span[title='" + data.Text + "']")[0].parentNode.classList.add('k-state-selected');
                }
            });
        } else if ((to.path === '/' && to.name === 'home-menu')
            && payload.newsItems.length === 0 && !payload.hasRootMenuItem
            && payload.announcementItems.length === 0) {
                next(false);

            // find staff
            if (global.redirectToSelectedNode != null)
                global.redirectToSelectedNode();
            else
                utils.callWebAPI("api/tasks/getfirst", { isCheckStaffs: true }, (data) => {
                    if (data)
                        router.push({
                            name: 'tasksGrid',
                            params: {
                                folderId: data.Id,
                                folderName: data.Text + " - " + data.Text
                            }
                        });
                });
            // let staffs = payload.treeItems[0].ChildItems.filter(t => t.ItemType === 2)
            // if (staffs.length === 0 || payload.ExpandStaffTree === false) {
            //     router.push({
            //         name: 'tasksGrid',
            //         params: {
            //             folderId: payload.treeItems[0].ChildItems[0].Id,
            //             folderName: payload.treeItems[0].Text + " - " + payload.treeItems[0].ChildItems[0].Text
            //         }
            //     });
            // } else {
            //     router.push({
            //         name: 'tasksGrid',
            //         params: {
            //             folderId: staffs[0].ChildItems[0].Id,
            //             folderName: staffs[0].Text + " - " + staffs[0].ChildItems[0].Text
            //         }
            //     });
            // }
            //return
        }
    }
    if (typeof routePermissionMapp[to.name] === "undefined") {
        next();
        return;
    }

    for (let item of routePermissionMapp[to.name]) {
        if (item == "not-annonymous")
            isAllowed = isAllowed && typeof payload.CurrentUser !== "undefined";
        else
            isAllowed = isAllowed && userPermissions[item];
    }

    if (isAllowed)
        next();
    else {
        next(false);
        routerInstance.push({ name: 'home' });
    }
});


//if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) { // detect it's IE11
//    window.addEventListener("hashchange",
//        function(event) {
//            const currentPath = window.location.hash.slice(1);
//            if (routerInstance.app.$route.path !== currentPath)
//                routerInstance.push(currentPath);
//        },
//        false);
//}

global.router = routerInstance;
export const router = routerInstance;

