import Modal from '../widgets/modal/modal.vue';
import { Helper } from '../../assets/ts/global';
import Loading from '../widgets/loading/loading.vue';
import Restful from '../../assets/ts/restful';

const helper = new Helper();

export default {
    data() {
        return {
            newsItems: payload.newsItems.sort(function (a, b) { return a.Priority - b.Priority }),
            totalPages: payload.newsTotalPages,
            loading: false,
            currentPage: 1
        }
    },
    methods: {
        truncate(value, length) {
            return helper.truncate(value, length);
        },
        pageChange(page) {
            var self = this;

            if (this.loading)
                return;

            this.currentPage = page;

            $(this.$el).LoadingOverlay('show');

            Restful.get("api/news", { pageNum: page })
                .done(x => {
                    this.loading = false;
                    this.newsItems = x;

                    $(this.$el).LoadingOverlay('hide');
                })
                .fail(x => {
                    console.Error("Reading news failed");
                    $(this.$el).LoadingOverlay('hide');
                });

        }
    },
    updated() {},
    mounted() {

    },
    components: {
        Modal,
        Loading
    }
}
