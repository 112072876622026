import Modal from "../widgets/modal/modal.vue";
import Tree from "../widgets/tree/tree.vue";
import utils from 'jsf/utils.js';
import { DxForm, DxItem } from "devextreme-vue/form";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxDateBox, DxValidationSummary } from "devextreme-vue";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxValidator, DxRequiredRule, DxAsyncRule } from 'devextreme-vue/validator';
import { DxTreeView } from 'devextreme-vue/tree-view';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import "devextreme-vue/text-area";

const treeViewRef = 'treeView';

export default {
    components: {
        Modal, Tree,
        DxForm, DxItem, DxSelectBox, DxDateBox, DxCheckBox,
        DxValidator, DxRequiredRule, DxAsyncRule, DxValidationSummary,
        DxTreeView
    },

    props: [
        'showDelegationModal',
        'modalData'
    ],

    data() {
        return {
            modalHeader: "",
            direction: payload.currentLocale.IsRightToLeft,
            dataTree: [],
            checkedNodes: [],
            destinationData: new DataSource({
                store: new ArrayStore(this.modalData.baseInfo.users),
                sort: 'label',
                paginate: true,
                pageSize: 20
            }),
            comboValue: null,
            items: [],
            disabledCombo: false,
            errors: [],
            formData: {
                DestinationId: null,
                IsPermanent: false,
                FromDate: new Date(),
                ToDate: new Date()
            },
            treeViewRef
        };
    },
    computed: {
        treeView: function () {
            return this.$refs[treeViewRef].instance;
        },

        showModal() {
            this.init();

            this.modalHeader = this.modalData.isCreation
                ? i18n.$t("delegation_management.delegation_add")
                : i18n.$t("delegation_management.delegation_edit");

            if (this.modalData.isCreation)
                this.create();
            else
                this.edit();
            return this.showDelegationModal;
        }
    },

    methods: {
        init() {
            this.checkedNodes = [];
            this.items = [];
            this.disabledCombo = false;
            this.errors = [];
            this.formData = {
                DestinationId: null,
                IsPermanent: false,
                FromDate: new Date(),
                ToDate: new Date()
            }
        },

        updatePermanent() {
            this.$forceUpdate();
        },

        ddlUsers(value) {
            this.comboValue = value;
            //this.destnationId = value.id;
        },

        validation() {
            let result = true;

            if (this.formData.DestinationId === null) {
                this.errors.push(i18n.$t("delegation_management.messages.select_user"));
                result = false;
            }

            this.checkedNodes = [];
            const selected = this.treeView.getSelectedNodes();
            //console.log("Tree validation", selected);
            for (let i = 0; i < selected.length; i++)
                this.checkedNodes.push({
                    id: selected[i].itemData.Id,
                    itemType: selected[i].itemData.ItemType
                });
            //console.log("Tree validation 2", this.checkedNodes);

            if (!this.checkedNodes.length) {
                this.errors.push(i18n.$t("delegation_management.messages.select_folder"));
                result = false;
            }

            if (!this.formData.IsPermanent && (this.formData.FromDate === '' || this.formData.ToDate === '')) {
                this.errors.push(i18n.$t("delegation_management.messages.select_date"));
                result = false;
            } else if (!this.formData.IsPermanent) {
                if (this.formData.FromDate > this.formData.ToDate) {
                    this.errors.push(i18n.$t("delegation_management.messages.compare_fromDate_toDate"));
                    result = false;
                }
            }

            return result;

        },

        submit() {
            this.errors = [];
            if (this.validation()) {

                const data = {
                    destinationId: this.formData.DestinationId,
                    fromDate: this.formData.FromDate,
                    toDate: this.formData.ToDate,
                    isPermanent: this.formData.IsPermanent,
                    delegationsTree: this.checkedNodes
                };

                //callback = (result) => {
                //    $('.cancelModal', this.$el).click();
                //    this.modalData.callback();
                //};

                var that = this;

                utils.callWebAPI("api/delegations/save", data, () => {
                    $('.cancelModal', this.$el).click();
                    this.modalData.callback();
                }, (data) => {
                    //console.log(data);
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        for (let j = 0; j < item.Errors.length; j++) {
                            const errorItem = item.Errors[j];
                            that.errors.push(i18n.$t(errorItem.ErrorMessage));
                        }
                    }
                }, 'POST');
            }
        },

        create() { },

        edit() {
            var that = this;
            this.formData = this.modalData.formData;
            this.disabledCombo = true;

            utils.callWebAPI("api/delegations/get/" + this.modalData.formData.DestinationId, {}, (data) => {
                that.treeView.unselectAll();
                data.DelegationsTree.forEach(function (node) {
                    that.treeView.selectItem(node.Id);
                    that.treeView.expandItem(node.Id);
                });
            });
        }
    },

    updated() { },

    mounted() {
        this.dataTree = this.modalData.baseInfo.tree;
        this.treeView.unselectAll();
        this.treeView.collapseAll();
    },

    created() { }
}
