import NewForm from '../forms/newform/newForm.vue';
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxSelection,
    DxPager, DxPaging, DxSearchPanel,
    DxToolbar, DxItem, DxExport
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxButton as DxButtonToolbar } from 'devextreme-vue/button';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

const url = 'api/Logs/GetEPCLog';

const dataSource = createStore({
    key: 'Id',
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { "Content-type": "application/json" };
    }
});

export default {
    data() {
        return {
            dataGridRefKey: 'dataGrid',
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            url,
            dataSource
        }
    },
    methods: {
        refreshDataGrid() {
            this.dataGrid.refresh();
        },

        contentReady(e) {
            if (!e.component.getSelectedRowKeys().length) { e.component.selectRowsByIndexes(0); }
        },

        selectionChanged(e) {
            e.component.collapseAll(-1);
            e.component.expandRow(e.currentSelectedRowKeys[0]);
        },

        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                autoFilterEnabled: true,
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LogEPCCalls.xlsx');
                    });
            });
        }
    },

    updated() {
    },

    mounted() {
    },

    computed: {
        dataGrid: function () {
            return this.$refs[this.dataGridRefKey].instance;
        }
    },

    components: {
        NewForm,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging,
        DxSelection,
        DxSearchPanel,
        DxSelectBox,
        DxToolbar,
        DxItem,
        DxButtonToolbar,
        DxExport,
        Workbook, exportDataGrid

    }
}
