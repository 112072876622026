var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c("div", {
        class: {
          rayvarzBPMSGrid: true,
          scrollPaging: _vm.pagingType === "scroll",
        },
      }),
      _vm._v(" "),
      _c("contextMenu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasContextMenu,
            expression: "hasContextMenu",
          },
        ],
        attrs: {
          target: ".rayvarzBPMSGrid",
          filter: "tbody tr",
          data: _vm.gridContextItems,
          select: _vm.gridContextItemSelect,
          open: _vm.gridContextItemOpen,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }