var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _vm.loaded
        ? _c("tree", {
            ref: "tree",
            attrs: {
              hasContextMenu: "true",
              data: _vm.data,
              contextMenuItems: _vm.contextItemsLoader,
              nodeSelect: _vm.nodeSelect,
              clearSearchItem: _vm.clearSearchItem,
              mustSelectedNodeId: _vm.mustSelectedNodeId,
              template: _vm.treeTemp,
              dataSourceUrl: "api/tasktree",
              selectedNodeId: _vm.selectedNodeId,
              preserveExpandStatus: "true",
              expandRootItem: _vm.expandRootItem,
              expandUniqueId: "cartableTree",
              treeLoaded: _vm.treeLoaded,
              setSelectedNodeId: _vm.setSelectedNodeId,
              enableSearch: false,
            },
            on: { itemSelected: _vm.treeItemSelected },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.direction
        ? _c(
            "script",
            {
              attrs: {
                id: "rayvarzBPMSKendoTreeViewTemplate",
                type: "text/x-kendo-template",
              },
            },
            [
              _vm._v(
                '\n        <span title="#=item.ToolTip#">\n            #if(item.Icon){#\n                #if(item.Icon==\'user\'){#<i class="far fa-thin fa-lg fa-user"></i>#}#\n                #if(item.Icon==\'userdelegated\'){#<i class="fad fa-lg fa-user"></i>#}#\n                #if(item.Icon==\'folder\'){#<i class="far fa-thin fa-lg fa-folder"></i>#}#\n                #if(item.Icon==\'folderdelegated\'){#<i class="fad fa-lg fa-folder"></i>#}#\n                #if(item.Icon==\'staff\'){#<i class="far fa-thin fa-lg fa-users"></i>#}#\n                #if(item.Icon==\'staffdelegated\'){#<i class="fad fa-lg fa-users"></i>#}#\n            #}#\n            #=item.Text#\n            </span>\n        <span>#=item.TasksNotify#</span>\n    '
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.direction
        ? _c(
            "script",
            {
              attrs: {
                id: "rayvarzBPMSKendoTreeViewTemplate",
                type: "text/x-kendo-template",
              },
            },
            [
              _vm._v(
                '\n        <span title="#=item.ToolTip#">\n            #if(item.Icon){#\n                #if(item.Icon==\'user\'){#<i class="far fa-thin fa-lg fa-user"></i>#}#\n                #if(item.Icon==\'userdelegated\'){#<i class="fad fa-lg fa-user"></i>#}#\n                #if(item.Icon==\'folder\'){#<i class="far fa-thin fa-lg fa-folder"></i>#}#\n                #if(item.Icon==\'folderdelegated\'){#<i class="fad fa-lg fa-folder"></i>#}#\n                #if(item.Icon==\'staff\'){#<i class="far fa-thin fa-lg fa-users"></i>#}#\n                #if(item.Icon==\'staffdelegated\'){#<i class="fad fa-lg fa-users"></i>#}#\n            #}#\n            #=item.Text#\n        </span>\n        <span style="">#=item.TasksNotify# </span>\n    '
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(_vm.currentModalComponent, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showContextModal,
                expression: "showContextModal",
              },
            ],
            tag: "component",
            attrs: {
              showContextModal: _vm.showContextModal,
              modalData: _vm.modalData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }