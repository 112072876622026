import Modal from "../../widgets/modal/modal.vue";
import { Helper } from '../../../assets/ts/global';

const helper = new Helper();

export default {
    props: [
        'showTimesheetModal',
        'modalData'
    ],
    data() {
        return {
        }
    },
    computed: {
        showModal() {
            this.init();
            return this.showTimesheetModal;
        }
    },
    methods: {
        validation() {
            var result = true;
            var message = "";

            if (this.name.trim() === "") {
                message += i18n.$t("rule_management.rule_modal.validation.name") + "\n";
                result = false;
            }

            if (!result)
                alert(message);

            return result;
            
        },
        submit() {

            if (this.validation()) {

                let url = "api/rules/save";
                let data = {};
                let callback;

                data = {
                    name: this.name,
                    subject: this.subject,
                    taskType: this.taskType,
                    createDate: $('#createDate').val(),
                    responseDate: $('#responseDate').val(),
                    doneDate: $('#doneDate').val(),
                    priority: this.priority,
                    flag: this.flag,
                    isRead: this.isRead,
                    isTranscript: this.isTranscript,
                    app: this.app,
                    isNew: this.modalData.isCreation,
                    folderId: this.folderId,
                    id: this.modalData.id
                };

                callback = (result) => {
                    $('.cancelModal', this.$el).click();
                    this.modalData.callback();
                };

                this.doAjaxRequest(url, JSON.stringify(data), callback, () => { }, 'POST');
            }
        },
        init() {
        },
        doAjaxRequest(url, data, onSuccess, onFailure, method) {
            let ajaxConfig = {
                url: url,
                data: data,
                dataType: "json",

                contentType: "application/json;charset=utf-8"
            };
            if (method) {
                ajaxConfig.method = method;
            }
            $.ajax(ajaxConfig)
                .done((result) => {
                    if (typeof onSuccess === 'function')
                        onSuccess(result);
                }).error((error) => {
                    console.log(error);
                    if (typeof onFailure === 'function')
                        onFailure();
                });
        },
        edit() {
        }
    },
    updated() {
    },
    mounted() {
        var baseInfo = this.modalData.baseInfo;

        $('#dpTimesheet').click(function (e) {
            e.stopPropagation();
        });

        $('#dpTimesheet').MdPersianDateTimePicker({
            Placement: 'bottom',
            Trigger: 'click',
            EnableTimePicker: false,
            TargetSelector: '#createDate',
            Format: 'yyyy/MM/dd',
            IsGregorian: true,
            EnglishNumber: true,
        });

    },
    created() {
    },
    components: {
        Modal
    }
}
