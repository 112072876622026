/*/// <reference path="../../../node_modules/@types/jquery/index.d.ts" />*/
import Restfull from './restful'

export class Models {
    public loginByOldSSo(data: Object) {
        //return $.ajax({
        //    method: 'POST',
        //    url: 'Authentication/LoginByOldSSo',
        //    data: data
        //});

        return Restfull.post('Authentication/LoginByOldSSo' + window.location.search, data, null, true);
    }
    public ChangePassword(data: Object) {
        //return $.ajax({
        //    method: 'POST',
        //    url: 'Authentication/ChangePassword',
        //    data: data
        //});
        return Restfull.post('Authentication/ChangePassword', data, null, true);
    }
}
