import { render, staticRenderFns } from "./forms.vue?vue&type=template&id=4119f4de"
import script from "./forms.js?vue&type=script&lang=js&external"
export * from "./forms.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\r1\\a\\InterfacingBPMS\\Source\\Rayvarz.BPMS.Web.App\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4119f4de')) {
      api.createRecord('4119f4de', component.options)
    } else {
      api.reload('4119f4de', component.options)
    }
    module.hot.accept("./forms.vue?vue&type=template&id=4119f4de", function () {
      api.rerender('4119f4de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "App/component/forms/forms.vue"
export default component.exports