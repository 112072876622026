import hubProxy from './assets/ts/signalr';
import restful from './assets/ts/restful';
import utils from 'jsf/utils.js';

let isNavigating = false;

export default class Hubs {
    init() {
        const self = this;

        $.extend(hubProxy.client, {
            sessionEnd: function (isAnnonymous) {
                self.sessionEnd(isAnnonymous);
            }
        });

        $.extend(hubProxy.client, {
            onMessageRecived: function (messageCount) {
                if (localStorage.dbpDebugMode === 'true')
                    console.log("SignalR: onMessageRecived", messageCount);

                payload.unSeenMessagesCount = messageCount;

                if (messageCount === 0) {
                    $('.messageCount').removeClass("label-danger");
                    $('.messageCount').text("");
                }
                else {
                    $('.messageCount').show();
                    $('.messageCount').addClass("label-danger");
                    $('.messageCount').text(messageCount);
                }
                const dir = payload.currentLocale.IsRightToLeft ? 'rtl' : 'ltr';
                const message = "\r\n" + i18n.$t('messages_receive_notification');
                const options = {
                    icon: "./dist/bpms-notif.png",
                    dir: dir,
                    body: message
                }

                Notification.requestPermission(function () {
                    var instance = new Notification("", options);
                    instance.onclick = function () {
                        window.focus();
                        router.push({
                            name: 'message-grid',
                            params: { isReceivedMessage: "true" }
                        });
                    }
                    setTimeout(function () {
                        instance.close();
                    }, 4000);
                });
            }
        });

        $.extend(hubProxy.client, {
            fileEdited: function (pageInstanceId, fileId, groupId, changeMode) {
                if (typeof global.attachmentFileChanged === "function")
                    global.attachmentFileChanged(pageInstanceId, fileId, groupId, changeMode);
            },
            fileEditFailed: function (pageInstanceId, result, errorMsg) {
                if (typeof global.attachmentFileChangingFailed === "function")
                    global.attachmentFileChangingFailed(pageInstanceId, result, errorMsg);
            }
        });

        $(window).on('beforeunload', () => {
            isNavigating = true;
            return undefined;
        });

        hubProxy.connection.stateChanged((state) => {
            if (state.newState == $.signalR.connectionState.reconnecting || state.newState == $.signalR.connectionState.disconnected) {
                console.log("SignarR state has changed, will check status on:", payload.currentUrl + "/authentication/status");
                restful.get(payload.currentUrl + "/authentication/status", {})
                    .done(userStatus => {
                        //alert("authentication/status " + userStatus );
                        if (userStatus == 1) {
                            console.log("SignalR initiates session end");
                            this.sessionEnd(false);
                        }
                    })
                    .fail(err => {
                        if (err.status === 503) {
                            console.log("authentication/status error", err);
                            this.sessionEnd(false);
                        }
                    });
            }

            $.connection.hub.disconnected(function () {
                console.log("SignalR disconnected");
                //this.sessionEnd();
                setTimeout(function () { $.connection.hub.start(); }, 5000); // Restart connection after 5 seconds.
            });
        });

        $.extend(hubProxy.client, {
            forceSessionEnd: function () {
                isNavigating = true;
                console.log("Force Session End", "window.location.reload");
                window.location.reload();
            }
        });

        $.extend(hubProxy.client, {
            MaintenanceScheduled: function (message) {
                if (localStorage.dbpDebugMode === 'true')
                    console.log("SignalR: MaintenanceScheduled", message);
                alert(message);
            }
        });

        $.extend(hubProxy.client, {
            MaintenanceMessageReceived: function (message) {
                if (localStorage.dbpDebugMode === 'true')
                    console.log("SignalR: MaintenanceMessageReceived", message);
                alert(message);
            }
        });

        $.extend(hubProxy.client, {
            ShowNotification: function (message, type) {
                if (localStorage.dbpDebugMode === 'true')
                    console.log("SignalR: ShowNotification", message, type);
                utils.message(message, type);
            }
        }); 
    }

    sessionEnd(isAnonymous) {
        function inner() {
            if (isNavigating)
                return;

            isNavigating = true;

            setTimeout(() => {
                var url = payload.currentUrl + `?redirect=/home/private${encodeURIComponent(window.location.hash)}`;
                console.log("Session End", "Redirect to", url);
                window.location = url;
            }, 15000);
        }

        if ($.browser.mozilla)
            setTimeout(() => inner(), 1000);
        else
            inner();
    }
}
