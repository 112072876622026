<template>
    <newForm v-bind:title='$t("appearance_management.ui_setup")' v-bind:loading="loading" className="col-xs-12">
        <div class="jquery-ui-scope view bpms-ui">
            <div class="ui-widget jquery-ui-scope">

                <div class="ui-widget-content ui-corner-bottom" style="padding: 10px;">
                    <ul class="nav nav-tabs">
                        <li class="active"><a href="#tabs-1" data-toggle="tab">{{$t("appearance_management.back_image")}}</a></li>
                        <!--<li><a href="#tabs-2" data-toggle="tab">{{$t("appearance_management.default_theme")}}</a></li>-->
                        <li><a href="#tabs-3" data-toggle="tab">{{$t("appearance_management.banner")}}</a></li>
                    </ul>
                    <br />
                    <div id="myTabContent" class="tab-content">

                        <!--Background Tab(1)-->
                        <div class="tab-pane fade active in" id="tabs-1">
                            <!--<p>{{$t("appearance_management.back_image_dsc")}}</p>-->
                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="beforeLoginBanner" class="btn btn-primary"><b>{{$t("appearance_management.before_login")}}</b> <span id="lblbeforeLoginBanner"></span></label>
                                        <input id="beforeLoginBanner" type="file" @change="fileUploadChange($event,'beforeLoginBanner',false)" style="display:none" />
                                        <template v-if="beforeLoginBanner.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="beforeLoginBanner.src+beforeLoginBanner.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(beforeLoginBanner.id,'beforeLoginBanner')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="afterLoginBanner" class="btn btn-primary"><b>{{$t("appearance_management.after_login")}}</b> <span id="lblafterLoginBanner"></span></label>
                                        <input id="afterLoginBanner" type="file" @change="fileUploadChange($event,'afterLoginBanner',false)" style="display:none" />
                                        <template v-if="afterLoginBanner.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="afterLoginBanner.src+afterLoginBanner.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(afterLoginBanner.id,'afterLoginBanner')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-2">
                                    <div class="form-group">
                                        <label for="beforeAuthBackground"><b>{{$t("appearance_management.location")}}</b></label>
                                        <label class="radio">
                                            <input type="radio" name="backgroundRepeat" value="repeat" v-model="backgroundPosition">
                                            {{$t("appearance_management.repeat")}}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="backgroundRepeat" value="no-repeat" v-model="backgroundPosition">
                                            {{$t("appearance_management.no_repeat")}}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" name="backgroundRepeat" value="center" v-model="backgroundPosition">
                                            {{$t("appearance_management.center")}}
                                        </label>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="txtBackgroundColor"><b>{{$t("appearance_management.back_color")}}</b></label>
                                        <input type="text" id="txtBackgroundColor" class="form-control colorpicker" data-control="hue" value="#FFFFFF" style="direction: ltr" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div class="row form-group">
                                <div class="col-md-5"></div>
                                <div class="col-md-1">
                                    <button class="btn btn-primary"
                                            v-on:click="submitBackgroundTab">
                                        {{$t("save")}}
                                    </button>
                                </div>
                            </div>


                            <div style="clear: both"></div>
                            <br />

                        </div>

                        <!--Theme Tab(2)
                        <div class="tab-pane fade" id="tabs-2">
                            <div class="row" style="margin-bottom:20px;">
                                <div class="col-md-12">
                                    <p>{{$t("appearance_management.default_theme_dsc")}}</p>
                                </div>
                                <div class="col-xs-12 col-md-12">
                                    <label for="drpWorkbenchThemes">{{$t("appearance_management.select_theme")}} :</label>
                                </div>


                                <div class="col-md-2">
                                    <select class="form-control" id="drpWorkbenchThemes" style="width: 140px;" v-model="theme">
                                        <option v-for="theme in themes" :value="theme.Name">{{theme.Title}}</option>
                                    </select>
                                </div>

                                <div class="col-md-3">
                                    <button type="button" class="btn btn-primary" v-on:click="submitTheme">
                                        {{$t("appearance_management.set_theme")}}
                                    </button>
                                </div>
                            </div>
                        </div>-->
                        <!--Banner Tab(3)-->
                        <div class="tab-pane fade" id="tabs-3">
                            <p>
                                {{$t("appearance_management.banner_dsc")}}
                                <em>{{$t("appearance_management.banner_attention")}}</em>
                                <ul>
                                    <li>{{$t("messages.file_type_attention")}}</li>
                                    <li>{{$t("appearance_management.height_attention")}}</li>
                                    <li>{{$t("appearance_management.image_attention")}}</li>
                                </ul>
                            </p>
                            <br />

                            <div class="row">
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="topBanner" class="btn btn-primary"><b>{{$t("appearance_management.back_image_file")}}</b> <span id="lbltopBanner"></span></label>
                                        <input id="topBanner" type="file" @change="fileUploadChange($event,'topBanner',true)" style="display:none" />
                                        <template v-if="topBanner.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="topBanner.src+topBanner.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(topBanner.id,'topBanner')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="topBannerRight" class="btn btn-primary"><b>{{$t("appearance_management.right_iamge")}}</b> <span id="lbltopBannerRight"></span></label>
                                        <input id="topBannerRight" type="file" @change="fileUploadChange($event,'topBannerRight',true)" style="display:none" />
                                        <template v-if="topBannerRight.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="topBannerRight.src+topBannerRight.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(topBannerRight.id,'topBannerRight')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="topBannerCenter" class="btn btn-primary"><b>{{$t("appearance_management.center_image")}}</b> <span id="lbltopBannerCenter"></span></label>
                                        <input id="topBannerCenter" type="file" @change="fileUploadChange($event,'topBannerCenter',true)" style="display:none" />
                                        <template v-if="topBannerCenter.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="topBannerCenter.src+topBannerCenter.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(topBannerCenter.id,'topBannerCenter')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3">
                                    <div class="form-group">
                                        <label for="topBannerLeft" class="btn btn-primary"><b>{{$t("appearance_management.left_image")}}</b> <span id="lbltopBannerLeft"></span></label>
                                        <input id="topBannerLeft" type="file" @change="fileUploadChange($event,'topBannerLeft',true)" style="display:none" />
                                        <template v-if="topBannerLeft.serv.Size > 0">
                                            <div class="bannerImageBox">
                                                <label>{{$t("appearance_management.current_img")}}</label>
                                                <br />
                                                <img class="img-thumbnail img-responsive" :src="topBannerLeft.src+topBannerLeft.id" />
                                                <br />
                                                <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="resetImage(topBannerLeft.id,'topBannerLeft')">{{$t("remove")}}</button>
                                            </div>
                                            <hr class="visible-xs" />
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <br />
                            <div class="row form-group">
                                <div class="col-md-5"></div>
                                <div class="col-sm-1">
                                    <button class="btn btn-primary" @click="submitBanner">
                                        {{$t("save")}}
                                    </button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>

                        <span>
                            {{$t("attention")}}<br />
                            {{$t("page_refresh")}}
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </newForm>
</template>

<script>
    import NewForm from '../forms/newform/newForm.vue';
    import utils from 'jsf/utils.js';

    export default {
        data() {
            return {
                direction: payload.currentLocale.IsRightToLeft,
                loading: true,
                backgroundPosition: 'repeat',
                beforeLoginBanner: {
                    id: '11D59C80-FF59-42F8-8231-84474FBC8DEE',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                afterLoginBanner: {
                    id: '3A33F52B-BD1E-4112-84ED-A6E0013A1631',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                topBanner: {
                    id: 'D6BC76F4-863F-41A5-A41B-DDEE5FAC2F89',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                topBannerCenter: {
                    id: '8253F319-ED91-49E7-8E72-B8B5FECC880B',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                topBannerLeft: {
                    id: '98ADEBE9-5941-4518-ACD6-13A9AC64A97C',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                topBannerRight: {
                    id: '2133A591-BF8E-4172-B906-BF36D98B8A9E',
                    src: 'api/file/getfile?outputType=inline&imageId=',
                    serv: {
                        Size: 0
                    }
                },
                color: '#FFFFFF',
                //themes: [],
                //theme: 'Default'
            }
        },
        methods: {
            submitBackgroundTab() {
                var that = this;

                var data = {};
                data.backColor = $("#txtBackgroundColor").val();

                if (that.beforeLoginBanner.image) {
                    data.beforeLoginBanner = {
                        image: that.beforeLoginBanner.image,
                        name: that.beforeLoginBanner.name
                    };
                }

                if (that.afterLoginBanner.image) {
                    data.afterLoginBanner = {
                        image: that.afterLoginBanner.image,
                        name: that.afterLoginBanner.name
                    };
                }

                data.position = this.backgroundPosition;

                let callback = (result) => {
                    var params = {
                        text: that.$t('messages.sucsses_message'),
                        type: 'information',
                        layout: 'center',
                        timeout: 3000
                    };

                    noty(params);
                };

                this.doAjaxRequest('api/ui/update-background',
                    JSON.stringify(data),
                    callback,
                    () => { },
                    'POST');
            },
            //submitTheme() {
            //    var that = this;

            //    var theme = that.theme;

            //    if (theme) {
            //        var url = "api/ui/set-theme/" + theme;

            //        let callback = (result) => {
            //            var params = {
            //                text: that.$t('messages.sucsses_message'),
            //                type: 'information',
            //                layout: 'center',
            //                timeout: 3000
            //            };

            //            noty(params);
            //        };

            //        that.doAjaxRequest(url,
            //            {},
            //            callback,
            //            () => { },
            //            'POST');
            //    }
            //},
            submitBanner() {
                var that = this;

                var data = {};

                if (that.topBanner.image) {
                    data.topBanner = {
                        image: that.topBanner.image,
                        name: that.topBanner.name
                    };
                }

                if (that.topBannerCenter.image) {
                    data.topBannerCenter = {
                        image: that.topBannerCenter.image,
                        name: that.topBannerCenter.name
                    };
                }

                if (that.topBannerLeft.image) {
                    data.topBannerLeft = {
                        image: that.topBannerLeft.image,
                        name: that.topBannerLeft.name
                    };
                }

                if (that.topBannerRight.image) {
                    data.topBannerRight = {
                        image: that.topBannerRight.image,
                        name: that.topBannerRight.name
                    };
                }

                let callback = (result) => {
                    var params = {
                        text: that.$t('messages.sucsses_message'),
                        type: 'information',
                        layout: 'center',
                        timeout: 3000
                    };

                    noty(params);
                };

                this.doAjaxRequest('api/ui/update-banner',
                    JSON.stringify(data),
                    callback,
                    () => { },
                    'POST');
            },
            resetImage(id, propName) {
                var that = this;

                if (id) {

                    utils.confirm(i18n.$t("are_you_sure"), {
                        yes: function () {
                            var url = "api/ui/reset-image/" + id;

                            let callback = (result) => {
                                var params = {
                                    text: that.$t('messages.delete_sucsses'),
                                    type: 'information',
                                    layout: 'center',
                                    timeout: 3000
                                };

                                noty(params);

                                that[propName].serv.Size = 0;
                            };

                            that.doAjaxRequest(url,
                                {},
                                callback,
                                () => { },
                                'POST');
                        },
                        no: function () { }
                    });
                }
            },
            fileUploadChange(e, id, validateSize) {
                var that = this;
                var self = $("#" + id);
                function reset() {
                    self.wrap('<form>').parent('form').trigger('reset');
                    self.unwrap();
                }

                var reader = new FileReader();
                var file = self[0].files[0];

                if (file.type !== "image/png" && file.type !== "image/gif" && file.type !== "image/jpeg") {
                    alert(i18n.$t("appearance_management.file_type"));
                    reset();
                    return;
                } else if (validateSize && file.size > 307200) {
                    alert(i18n.$t("messages.file_volume") + ' ' + i18n.$t("messages.file_upload"));
                    reset();
                    return;
                }

                var lblFileName = $("#lbl" + id);

                reader.onloadend = function () {
                    that[id].image = reader.result;
                    that[id].name = file.name;
                    //console.log(lblFileName);
                    lblFileName.text(file.name);
                }

                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            getCookieValue(a) {
                var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
                return b ? b.pop() : '';
            },
            doAjaxRequest(url, data, onSuccess, onFailure, method) {
                let ajaxConfig = {
                    url: url,
                    data: data,
                    dataType: "json",
                    contentType: "application/json;charset=utf-8"
                };
                if (method) {
                    ajaxConfig.method = method;
                }
                //console.log('Main.vue call', url);
                $.ajax(ajaxConfig)
                    .done((result) => {
                        if (typeof onSuccess === 'function')
                            onSuccess(result);
                    }).error((error) => {
                        if (typeof onFailure === 'function') {

                            var response = null;

                            if (error.status == 400) {
                                response = JSON.parse(error.responseText);
                                onFailure(response);
                            }
                        }
                    });
            }
        },
        components: {
            NewForm
        },
        mounted() {

            $(".colorpicker").each(function () {
                $(this).minicolors({
                    control: $(this).attr("data-control") || "hue",
                    defaultValue: $(this).attr("data-defaultValue") || "",
                    inline: $(this).attr("data-inline") === "true",
                    letterCase: $(this).attr("data-letterCase") || "uppercase",
                    opacity: $(this).attr("data-opacity"),
                    position: $(this).attr("data-position") || "top left",
                    change: function (hex, opacity) {
                        if (!hex) return;
                        if (opacity) hex += ", " + opacity;
                        if (typeof console === "object") {
                        }
                    },
                    theme: "bootstrap"
                });
            });

        },
        created() {
            var that = this;
            
            that.doAjaxRequest(
                "api/ui/load-appearance", {},
                (data) => {
                    that.beforeLoginBanner.serv = data.BeforeLoginBanner;
                    that.afterLoginBanner.serv = data.AfterLoginBanner;
                    that.topBanner.serv = data.TopBanner;
                    that.topBannerCenter.serv = data.TopBannerCenter;
                    that.topBannerLeft.serv = data.TopBannerLeft;
                    that.topBannerRight.serv = data.TopBannerRight;
                    that.backgroundPosition = data.BackgroundPosition;
                    that.color = data.BackgroundColor;
                    $("#txtBackgroundColor").val(that.color).trigger("keyup");
                });
        }
    }
</script>
