var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c(
        "DxDataGrid",
        {
          ref: _vm.dataGridRefKey,
          attrs: {
            "show-borders": true,
            "data-source": _vm.dataSource,
            "remote-operations": true,
            "column-auto-width": true,
          },
          on: { "row-prepared": _vm.onRowPrepared },
          scopedSlots: _vm._u([
            {
              key: "subject-cell-template",
              fn: function (ref) {
                var data = ref.data
                return [
                  _c(
                    "a",
                    {
                      staticClass: "timeago subj",
                      attrs: {
                        href:
                          _vm.messageurl +
                          data.data.Id +
                          "/" +
                          _vm.isReceivedMessage,
                      },
                    },
                    [_vm._v(_vm._s(data.data.Subject))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("DxColumn", {
            attrs: {
              "data-field": "Subject",
              caption: _vm.$t("message_management.subject"),
              "cell-template": "subject-cell-template",
            },
          }),
          _vm._v(" "),
          _c("DxColumn", {
            attrs: { "data-field": "User", caption: _vm.userColumnName },
          }),
          _vm._v(" "),
          _c("DxColumn", {
            attrs: {
              "data-field": "CreatedAt",
              caption: _vm.$t("message_management.date_time_send"),
              "data-type": "datetime",
              format: _vm.datetimeFormat,
              "sort-index": 1,
              "sort-order": "desc",
            },
          }),
          _vm._v(" "),
          _c("DxPaging", { attrs: { "page-size": 15 } }),
          _vm._v(" "),
          _c("DxPager", {
            attrs: {
              "show-page-size-selector": true,
              "allowed-page-sizes": [15, 20, 50],
              "show-navigation-buttons": true,
              "show-info": true,
            },
          }),
          _vm._v(" "),
          _c("DxFilterRow", { attrs: { visible: true } }),
          _vm._v(" "),
          _c("DxHeaderFilter", { attrs: { visible: true } }),
          _vm._v(" "),
          _c("DxGroupPanel", { attrs: { visible: true } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }