var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("news_management.news"),
        className: "col-xs-12 newsManagementContent",
      },
    },
    [
      _c(
        "div",
        { class: [_vm.direction ? "k-rtl" : ""] },
        [
          _c("grid", {
            attrs: {
              columns: _vm.columns,
              reloadGrid: _vm.reloadGrid,
              dataSourceUrl: "api/news/list",
              toolbar: _vm.toolbar,
              hasPaging: "true",
              pagingType: "numeric",
              resizable: "true",
              selectable: "false",
              sortable: "true",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.currentModalComponent, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNewsModal,
            expression: "showNewsModal",
          },
        ],
        tag: "component",
        attrs: { showNewsModal: _vm.showNewsModal, modalData: _vm.modalData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }