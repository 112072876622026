var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _vm.item.IsExternal
        ? _c("a", { attrs: { href: _vm.item.Url, target: "_blank" } }, [
            _vm._v(_vm._s(_vm.item.Text)),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.item.IsExternal
        ? _c(
            "router-link",
            {
              class: _vm.checkChild(_vm.item) ? "dropdown more" : "",
              attrs: {
                id: _vm.item.Id,
                to: _vm.checkChild(_vm.item) ? "" : _vm.item.Url,
                "data-toggle": _vm.checkChild(_vm.item) ? "dropdown" : "",
              },
            },
            [
              _vm._v("\n        " + _vm._s(_vm.item.Text) + "\n        "),
              _vm.item.ChildItems.length !== 0
                ? _c("span", { staticClass: "caret" })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.checkChild(_vm.item)
        ? _c(
            "ul",
            { staticClass: "dropdown-menu" },
            _vm._l(_vm.item.ChildItems, function (item, key) {
              return _c("navbarItem", { key: key, attrs: { item: item } })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }