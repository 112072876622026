var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.breadcrumbs.length > 1
      ? _c("div", [
          _c(
            "ol",
            {
              staticClass: "breadcrumb",
              staticStyle: { "background-color": "transparent" },
              attrs: { slot: "breadcrumb" },
              slot: "breadcrumb",
            },
            _vm._l(_vm.breadcrumbs, function (crumb) {
              return _c(
                "li",
                { key: crumb.url },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "breadcrumb-item",
                      attrs: { to: crumb.url },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(crumb.text) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.homeMenuItems.length !== 0
      ? _c(
          "div",
          { staticClass: "homeMenu col-md-10 col-md-offset-1" },
          _vm._l(_vm.homeMenuItems, function (item) {
            return _c(
              "router-link",
              {
                key: item.Id,
                staticClass: "homeMenu-item col-xs-2",
                style: {
                  width: item.IconWidth + "px",
                  height: item.IconHeight + "px",
                },
                attrs: {
                  to: item.IsMenu ? "/" + item.Id : "/form/" + item.Id,
                  id: "rmenu" + item.Id.substring(0, 8),
                },
              },
              [
                item.TextPosition == "Top"
                  ? _c("h4", {
                      staticClass: "homeMenu-title",
                      style: { width: item.IconWidth + "px" },
                      domProps: { innerHTML: _vm._s(item.Text) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                item.IsFontAwesome
                  ? _c(
                      "div",
                      {
                        style: {
                          width: _vm.getWidth(item),
                          height: _vm.getHeight(item),
                          borderRadius: _vm.getBorderRadius(item),
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            style: {
                              color: item.FAIconColor
                                ? item.FAIconColor
                                : "var(--main-bg-color)",
                              "vertical-align": "super",
                              "text-align": "center",
                              "font-size": "110px",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa-fw",
                              class: item.FAIcon,
                              staticStyle: { "vertical-align": "super" },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !item.IsFontAwesome
                  ? _c(
                      "div",
                      {
                        staticClass: "panel panel-default text-center",
                        style: {
                          backgroundImage: "url(image/" + item.ImageUrl + ")",
                          width: _vm.getWidth(item),
                          height: _vm.getHeight(item),
                          borderRadius: _vm.getBorderRadius(item),
                        },
                      },
                      [
                        item.TextPosition == "Middle"
                          ? _c("h4", {
                              staticClass: "homeMenu-title",
                              class: _vm.checkMenuTextPosition(
                                item.TextPosition
                              ),
                              style: { width: item.IconWidth + "px" },
                              domProps: { innerHTML: _vm._s(item.Text) },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.TextPosition == "Bottom"
                  ? _c("h4", {
                      staticClass: "homeMenu-title",
                      style: { width: item.IconWidth + "px" },
                      domProps: { innerHTML: _vm._s(item.Text) },
                    })
                  : _vm._e(),
              ]
            )
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }