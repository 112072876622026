var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        showModal: _vm.showModal,
        "data-backdrop": "false",
        size: { "my-col": true, "col-lg-8": true },
      },
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("label", [_vm._v(_vm._s(_vm.$t("timesheet_register.timesheet")))]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-6 col-xs-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("label", { staticClass: "control-label" }, [
                  _vm._v(_vm._s(_vm.$t("timesheet_register.date")) + ":"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("input", {
                  staticClass: "form-control",
                  attrs: { type: "text", id: "dpTimesheet" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-3" }),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-success",
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("save")) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default cancelModal",
                attrs: { "data-dismiss": "modal" },
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.$t("cancel_nested")) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }