<template>
    <modal v-bind:showModal="showModal" data-backdrop="false" v-bind:size="{'my-col':true,'col-lg-8':true}">
        <div slot="header">
            <label>{{modalHeader}}</label>
        </div>
        <div slot="body">

            <!-- -->
            <div class="row">
                <div class="col-sm-12">

                    <div class="form-group">
                        <label>{{$t("news_management.title")}}</label>
                        <input type="text" class="form-control" v-model="title">
                    </div>

                    <div class="form-group">
                        <label>{{$t("news_management.text")}}</label>
                        <textarea class="form-control" rows="5" v-model="body"></textarea>
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <input type="checkbox" v-model="isByAnonymous">{{$t("news_management.enable_show")}}
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <input type="checkbox" v-model="isNews" @change="changeIsNews">{{$t("news_management.news_setup")}}
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <label>{{$t("news_management.text_color")}}</label>
                        Vue Component
                        <input type="hidden" id="txtNewsColor" class="form-control colorpicker" data-control="hue" value="#000000" style="direction: ltr" v-bind:placeholder="$t('news_management.text_color')">
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <label>{{$t("news_management.news_priority")}}</label>
                        <input type="text" class="form-control" v-model="priority" :disabled="isNews===false">
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                        <label for="inpNewsPicture" class="btn btn-primary" :disabled="isNews===false">{{$t("news_management.news_image")}}</label>
                        <input type="file" id="inpNewsPicture" :disabled="isNews===false" @change="fileUploadChange" style="display:none">

                        <p class="help-block">{{$t("news_management.pic_type")}}</p>
                    </div>

                    <div class="form-group col-md-6 col-xs-12" v-if="imageData!==''">
                        <img class="img-responsive" v-if="imageData!==''" :src="imageData">
                        <button class="btn btn-xs btn-danger" style="margin-top:3px;" @click="removePic">{{$t("remove")}}</button>
                    </div>

                </div>
            </div>

        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div v-if="errors.length" class="form-validate-summary alert alert-danger">
                        <ul id="appform-validationsummary">
                            <li :style="{textAlign : (direction ? 'right' : 'left')}" v-for="e in errors">{{e}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <button class="btn btn-success" @click="submit()"> 
                        {{$t("save")}}
                    </button>
                    <button class="btn btn-default cancelModal"
                            data-dismiss="modal">
                        {{$t("cancel_nested")}}
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from "../../widgets/modal/modal.vue";

    export default {
        props: [
            'showNewsModal',
            'modalData'
        ],
        data() {
            return {
                direction: payload.currentLocale.IsRightToLeft,
                modalHeader: '',
                errors: [],
                title: '',
                body: '',
                isByAnonymous: false,
                isNews: false,
                priority: 0,
                imageData: '',
                pictureId: '',
                id: '',
                fileName:''
            }
        },
        computed: {
            showModal() {
                this.init();

                this.modalHeader = this.modalData.isCreation ? i18n.$t("insert") : i18n.$t("edit");

                if (!this.modalData.isCreation) {
                    this.edit();
                    return this.showNewsModal;
                } else {
                    return this.showNewsModal;
                }
            }
        },
        methods: {
            init() {
                this.title = '';
                this.body = '';
                this.isByAnonymous = false;
                this.isNews = false;
                this.priority = 0;
                this.imageData = '';
                this.id = '';
                this.pictureId = '';
                this.fileName = '';
                this.errors = [];
            },
            changeIsNews() {
                if (!this.isNews) {
                    this.priority = 0;
                    $('#inpNewsPicture').val('');
                }
            },
            validation() {
                this.errors = [];
                var result = true;
                var priority = parseInt(this.priority);
                var file = $("#inpNewsPicture")[0].files[0];

                if ($.trim(this.title).length === 0) {
                    this.errors.push(i18n.$t("news_management.message.insert_title"));
                    result = false;
                } else if (this.title.length > 255) {
                    this.errors.push(i18n.$t("news_management.message.title_character_length"));
                    result = false;
                }

                if (this.body.length > 4000) {
                    this.errors.push(i18n.$t("news_management.message.text_character_length"));
                    result = false;
                } else if ($.trim(this.body).length === 0) {
                    this.errors.push(i18n.$t("news_management.message.insert_text"));
                    result = false;
                }

                if (this.isNews) {
                    if (isNaN(this.priority) && $.trim(this.priority).length !== 0) {
                        this.errors.push(i18n.$t("news_management.message.number_priority"));
                        result = false;
                    } else if (!(priority >= -9999 && priority <= 9999)) {
                        this.errors.push(i18n.$t("news_management.message.number_large"));
                        result = false;
                    }
                }

                return result;
            },
            submit() {
                if (this.validation()) {

                    var isCreation = this.modalData.isCreation;

                    var url = isCreation ? "api/news/add" : "api/news/update";
                    var method = isCreation ? 'POST' : 'PUT';

                    var data = {
                        title: this.title,
                        body: this.body,
                        isByAnonymous: this.isByAnonymous,
                        isNews: this.isNews,
                        priority: this.priority,
                        color: $('#txtNewsColor').val(),
                        imageData: this.imageData,
                        fileName:this.fileName
                    }

                    if (!isCreation) {
                        data.id = this.id;
                        data.pictureId = this.pictureId;
                    }

                    var callback = (result) => {
                        $('.cancelModal', this.$el).click();

                        var params = {
                            text: this.$t('messages.sucsses_message'),
                            type: 'information',
                            layout: 'center',
                            timeout: 3000
                        };
                        noty(params);

                        this.modalData.callback();
                    };

                    var that = this;

                    this.doAjaxRequest(url,
                        JSON.stringify(data),
                        callback,
                        function (data) {
                            for (var i = 0; i < data.length; i++) {
                                var item = data[i];
                                for (var j = 0; j < item.Errors.length; j++) {
                                    var errorItem = item.Errors[j];
                                    that.errors.push(i18n.$t(errorItem.ErrorMessage));
                                }
                            }
                        },
                        method);
                }
            },
            fileUploadChange(e) {
                var that = this;
                var self = $("#inpNewsPicture");

                function reset() {
                    self.wrap('<form>').parent('form').trigger('reset');
                    self.unwrap();
                }

                var reader = new FileReader();
                var file = self[0].files[0];
                
                if (file.type !== "image/png" && file.type !== "image/gif" && file.type !== "image/jpeg") {
                    alert(i18n.$t("messages.file_type_attention"));
                    reset();
                    return;
                } else if (file.size > 102400) {
                    alert(i18n.$t("news_management.message.file_volume"));
                    reset();
                    return;
                }

                reader.onloadend = function () {
                    that.imageData = reader.result;
                    that.fileName = file.name;
                    reset();
                }

                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            edit() {
                var that = this,
                    txtColor=$("#txtNewsColor");

                var id = this.modalData.id;

                var url = "api/news/get/" + id;

                this.doAjaxRequest(
                    url, {},
                    (data) => {
                        that.title = data.Title;
                        that.body = data.Body;
                        that.isByAnonymous = data.IsByAnonymous;
                        that.isNews = data.IsNews;
                        that.priority = data.Priority;
                        that.imageData = data.ImageData;
                        that.pictureId = data.PictureId;
                        that.id = id;
                        that.fileName = data.FileName;
                        txtColor.minicolors('value',data.Color);
                    },"GET");
            },
            removePic() {
                this.imageData = '';
            },
            doAjaxRequest(url, data, onSuccess, onFailure, method) {
                let ajaxConfig = {
                    url: url,
                    data: data,
                    dataType: "json",

                    contentType: "application/json;charset=utf-8"
                };
                if (method) {
                    ajaxConfig.method = method;
                }
                $.ajax(ajaxConfig)
                    .done((result) => {
                        if (typeof onSuccess === 'function')
                            onSuccess(result);
                    }).error((error) => {
                        if (typeof onFailure === 'function') {

                            var response = null;

                            if (error.status == 400) {
                                response = JSON.parse(error.responseText);
                                onFailure(response);
                            }
                        }
                    });
            }
        },
        mounted() {
            $(".colorpicker").each(function () {
                $(this).minicolors({
                    control: $(this).attr("data-control") || "hue",
                    defaultValue: $(this).attr("data-defaultValue") || "",
                    inline: $(this).attr("data-inline") === "true",
                    letterCase: $(this).attr("data-letterCase") || "uppercase",
                    opacity: $(this).attr("data-opacity"),
                    position: $(this).attr("data-position") || "top left",
                    change: function (hex, opacity) {
                        if (!hex) return;
                        if (opacity) hex += ", " + opacity;
                    },
                    theme: "bootstrap"
                });
            });
        },
        created() {
        },
        components: {
            Modal
        }
    }

</script>
