import NewForm from '../forms/newform/newForm.vue';
import Tree from "../widgets/tree/tree.vue";
import utils from 'jsf/utils.js';
import { DxForm, DxItem } from "devextreme-vue/form";
import { DxTextBox, DxCheckBox, DxRadioGroup, DxNumberBox, DxColorBox, DxButton } from 'devextreme-vue';

export default {
    components: {
        NewForm, Tree,
        DxForm, DxItem, DxTextBox, DxCheckBox, DxRadioGroup, DxNumberBox, DxColorBox, DxButton
    },

    data() {
        return {
            isRTL: payload.currentLocale.IsRightToLeft,
            isFr: payload.languages.some((l) => l.Language === "fr"),
            isDe: payload.languages.some((l) => l.Language === "de"),
            isAr: payload.languages.some((l) => l.Language === "ar"),
            isSv: payload.languages.some((l) => l.Language === "sv"),
            isHe: payload.languages.some((l) => l.Language === "he"),
            messages: [],
            dataTree: [],
            treeTemp: null,
            selectedItem: null,
            enabled: false,
            showInFirstLevel: true,
            removeFromTopMenu: false,
            displayText: "",
            displayTextFr: "",
            displayTextDe: "",
            displayTextAr: "",
            displayTextSv: "",
            displayTextHe: "",
            displayIcon: 0,
            iconWidth: 140,
            iconHeight: 140,
            textPosition: 1,
            imageData: null,
            textColor: "",
            textColorHover: "",
            lastId: "",
            isMenu: false,
            expandRootItem: true,
            FAIcon: "",
            FAIconColor: "",
            IsFontAwesome: false,
            IconShapeDS: [],
            TextPositionDS: [],
            OrderIndex: 0,
            isCategory: false
        }
    },

    methods: {
        removePicture() {
            this.imageData = null;
        },

        saveFormToItems(saveItem) {
            saveItem.Id = this.lastId;
            saveItem.Enabled = this.enabled;
            saveItem.Root = this.showInFirstLevel;
            saveItem.TopMenu = this.removeFromTopMenu;
            saveItem.DisplayName = this.displayText;
            saveItem.DisplayNameFr = this.displayTextFr;
            saveItem.DisplayNameDe = this.displayTextDe;
            saveItem.DisplayNameAr = this.displayTextAr;
            saveItem.DisplayNameSv = this.displayTextSv;
            saveItem.DisplayNameHe = this.displayTextHe;
            saveItem.IconShape = this.displayIcon;
            saveItem.IconWidth = this.iconWidth;
            saveItem.IconHeight = this.iconHeight;
            saveItem.TextPosition = this.textPosition;
            saveItem.TextColor = this.textColor; // $("#textColor").val();
            saveItem.TextColorHover = this.textColorHover; // $("#textColorHover").val();
            saveItem.TextColorHighlight = $("#textColorHighlight").val();
            saveItem.ImageData = this.imageData;
            saveItem.IsMenu = this.isMenu;
            saveItem.FAIcon = this.FAIcon;
            saveItem.FAIconColor = this.FAIconColor;
            saveItem.OrderIndex = this.OrderIndex;
        },

        submit() {
            var that = this;

            utils.confirm(i18n.$t("home_menu_management.message.save_confirm"), {
                yes: function () {
                    let saveItem = this.selectedItem || {};
                    that.saveFormToItems(saveItem);

                    let callback = () => {
                        let params = {
                            text: that.$t('messages.sucsses_message'),
                            type: 'information',
                            layout: 'center',
                            timeout: 3000
                        };

                        noty(params);
                    };

                    utils.callWebAPI("api/home-management/saveone", saveItem, callback, () => { }, 'POST');
                },
                no: function () { }
            });
        },

        catgeoryOrderChange() {
            let val = parseInt(this.OrderIndex);
            if (isNaN(val) === true) {
                alert(i18n.$t("home_menu_management.message.insert_only_number"));
                this.OrderIndex = 0;
            }
        },

        iconHeightChange() {
            let val = parseInt(this.iconHeight);
            if (isNaN(val)) {
                alert(i18n.$t("home_menu_management.message.insert_number"));
                this.iconHeight = 140;
            } else if (val < 25 || val > 140) {
                alert(i18n.$t("home_menu_management.message.icon_size"));
                this.iconHeight = 140;
            }
        },

        iconWidthChange() {
            let val = parseInt(this.iconWidth);
            if (isNaN(val) === true) {
                alert(i18n.$t("home_menu_management.message.insert_number"));
                this.iconWidth = 140;
            } else if (val < 25 || val > 300) {
                alert(i18n.$t("home_menu_management.message.icon_size"));
                this.iconWidth = 140;
            }
        },

        showMenuChange() {
            this.$refs.hmTree.setProp(this.lastId, 'Enabled', this.enabled);
        },

        init() {
            this.enabled = false;
            this.showInFirstLevel = true;
            this.removeFromTopMenu = false;
            this.displayText = "";
            this.displayTextFr = "";
            this.displayTextDe = "";
            this.displayTextAr = "";
            this.displayTextSv = "";
            this.displayTextHe = "";
            this.displayIcon = 0;
            this.iconWidth = 140;
            this.iconHeight = 140;
            this.textPosition = 1;
            this.FAIcon = "";
            this.FAIconColor = null;
            this.IsFontAwesome = false;

            this.textColor = "#000000";
            this.textColorHover = "#ADADAD";
            //$("#textColor").val("#000000").trigger("keyup");
            //$("#textColorHover").val("#ADADAD").trigger("keyup");
            //$("#textColorHighlight").val("#DDDDDD").trigger("keyup");

            this.imageData = null;
            this.OrderIndex = 0;
        },

        fileUploadChange() {
            var that = this;
            var self = $("#fileDisplayIcon");
            function reset() {
                self.wrap('<form>').parent('form').trigger('reset');
                self.unwrap();
            }

            var reader = new FileReader();
            var file = self[0].files[0];

            if (file.type !== "image/png" && file.type !== "image/gif" && file.type !== "image/jpeg") {
                alert(i18n.$t("home_menu_management.message.file_type"));
                reset();
                return;
            } else if (file.size > 30720) {
                alert(i18n.$t("home_menu_management.message.icon_volume"));
                reset();
                return;
            }

            reader.onloadend = function () {
                that.imageData = reader.result;
                reset();
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        },

        nodeSelect: function (node, nodeData) {
            let nodeId = nodeData.Id;

            if (nodeId === this.lastId)
                return;

            this.setSelectedNodeId = nodeId;

            utils.callWebAPI("api/home-management/getitem", { id: nodeId }, (data) => {
                this.selectedItem = data;

                if (this.selectedItem) {
                    this.enabled = this.selectedItem.Enabled;

                    this.showInFirstLevel = this.selectedItem.Root;
                    this.removeFromTopMenu = this.selectedItem.TopMenu;
                    this.displayText = this.selectedItem.DisplayName;
                    this.displayTextFr = this.selectedItem.DisplayNameFr;
                    this.displayTextDe = this.selectedItem.DisplayNameDe;
                    this.displayTextAr = this.selectedItem.DisplayNameAr;
                    this.displayTextSv = this.selectedItem.DisplayNameSv;
                    this.displayTextHe = this.selectedItem.DisplayNameHe;
                    this.displayIcon = this.selectedItem.IconShape;
                    this.iconWidth = this.selectedItem.IconWidth;
                    this.iconHeight = this.selectedItem.IconHeight;
                    this.textPosition = this.selectedItem.TextPosition;
                    this.isMenu = this.selectedItem.IsMenu;

                    this.textColor = this.selectedItem.TextColor;
                    this.textColorHover = this.selectedItem.TextColorHover;
                    //$("#textColor").val(this.selectedItem.TextColor).trigger("keyup");
                    //$("#textColorHover").val(this.selectedItem.TextColorHover).trigger("keyup");
                    //$("#textColorHighlight").val(this.selectedItem.TextColorHighlight).trigger("keyup");

                    this.OrderIndex = this.selectedItem.OrderIndex;

                    this.isCategory = this.selectedItem.IsCategory;
                    if (this.selectedItem.IsFontAwesome)
                        this.imageData = null;
                    else
                        this.imageData = this.selectedItem.ImageData;
                    this.FAIcon = this.selectedItem.FAIcon;
                    this.FAIconColor = this.selectedItem.FAIconColor;
                    this.IsFontAwesome = this.selectedItem.IsFontAwesome;
                } else {
                    this.init();
                    this.displayText = nodeData.Text;
                }
            });

            this.lastId = nodeId;
            //this.isMenu = nodeData.HasChildren;
        }
    },

    mounted() {
        this.treeTemp = $("#homeManagementTreeViewTemplate", this.$el).html();

        $(".colorpicker").each(function () {
            $(this).minicolors({
                control: $(this).attr("data-control") || "hue",
                defaultValue: $(this).attr("data-defaultValue") || "",
                inline: $(this).attr("data-inline") === "true",
                letterCase: $(this).attr("data-letterCase") || "uppercase",
                opacity: $(this).attr("data-opacity"),
                position: $(this).attr("data-position") || "top left",
                change: function (hex, opacity) {
                    if (!hex) return;
                    if (opacity) hex += ", " + opacity;
                    if (typeof console === "object") {}
                },
                theme: "bootstrap"
            });
        });
    },

    created() {
        let that = this;
        utils.callWebAPI("api/home-management/tree", {}, (data) => {
            that.dataTree = data.tree;
            that.messages = data.messages;

            that.IconShapeDS = [
                { text: data.messages.circul, value: 0 },
                { text: data.messages.squared, value: 1 },
                { text: data.messages.rectangle, value: 2 }
            ];

            that.TextPositionDS = [
                { text: data.messages.up_image, value: 0 },
                { text: data.messages.center_image, value: 1 },
                { text: data.messages.bottom_image, value: 2 }
            ];
        });
    }
}
