﻿export default {
    props: {
        id: {},
        showDismissBtn: {},
        showModal: {},
        backdrop: {
            default: 'static'    
        },
        size: {
            default: 'my-col col-lg-6'
        }
    },
    methods: {
        show() {
            $(this.$el).modal('show');
        },
        hide() {
            $(this.$el).modal('hide');
        }
    },
    watch: {
        showModal() {
            if (this.showModal == undefined)
                return;
            if (this.showModal) {
                this.show();
            } else {
                this.hide();
            }
        }
    },
    mounted() {
        if (this.showModal) {
            this.show();
        }
    }
}
