import Restful from 'jsf/restful.js';
import utils from 'jsf/utils.js';
import dateFormat from "dateformat";
import DataGrid from "devextreme/ui/data_grid";
import DataSource from "devextreme/data/data_source"
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

let EnumGenericGridNestedAction;
let EditType;
let gridEl;

var showVerticalLinesInHeader;

var RayGenericGrid = (function () {
    function RayGenericGrid(options, el) {
        this.grid = null;
        this.rowNumber = 0;
        this.sortColumn = null;
        this.sortDirection = null;
        this.columnMap = {};
        this.columns = [];
        this.fieldMaps = {};
        this.aggregateFields = [];
        this.cachedData = null;
        this.isFooterToolBarInitialized = false;
        this.isInitialized = false;
        this.footerToolbarButtons = {
            AddButton: null,
            EditButton: null,
            ExportButton: null,
            ViewButton: null
        };
        this.element = el;
        this.currentLocalStorageKey = '';
        this.options = options;
        this.widthInProc = false;
        this.defaultPageSize = 20;
        this._create();
    }
    RayGenericGrid.prototype._create = function () {
        if (this.options.controlState.RightToLeft && payload.currentLocale.IsRightToLeft)
            this.element.parent().addClass("k-rtl");
        this.mapColumns();
        this.initDataSource();
        this.createGrid();
    };
    RayGenericGrid.prototype.createGrid = function () {
        const _this = this;

        _this.currentLocalStorageKey = _this.options.controlState.Id + ":" + _this.options.controlState.Columns.map((a) => a.DataField).join("_");
        _this.localStorageContainsCurrentState = localStorage.hasOwnProperty(_this.currentLocalStorageKey);

        let filterRow = null;
        if (this.options.controlState.ShowFilter) {
            filterRow = { visible: true, applyFilter: 'auto' };
        }
        let summaryRow = null;
        if (this.options.controlState.ShowAggregate) {
            var colsWithSummary = _this.options.controlState.Columns.filter(item => item.AggregateKey && item.AggregateColString !== "None");
            if (colsWithSummary) {
                var totalItems = [];
                colsWithSummary.forEach(col => {
                    let type = '';
                    switch (col.AggregateColString) {
                        case "Avg": type = 'avg'; break;
                        case "Sum": type = 'sum'; break;
                    }
                    totalItems.push({ column: col.DataField, summaryType: type });

                });
                summaryRow = { totalItems: totalItems };
            }
        }

        if (this.options.controlState.ShowFilter) {
            filterRow = { visible: true, applyFilter: 'auto' };
        }
        const apage = this.element.attr("defaultpage");
        if (apage)
            this.defaultPageSize = parseInt(apage);

        const sVerticalLinesInHeader = this.element.attr("showVerticalLinesInHeader");
        if (sVerticalLinesInHeader)
            showVerticalLinesInHeader = sVerticalLinesInHeader.toLowerCase() === "true";

        const sVerticalLinesInBody = this.element.attr("showVerticalLinesInBody");
        if (sVerticalLinesInBody)
            this.showVerticalLinesInBody = sVerticalLinesInBody.toLowerCase() === "true";

        const sHorizontalLines = this.element.attr("showHorizontalLines");
        if (sHorizontalLines)
            this.showHorizontalLines = sHorizontalLines.toLowerCase() === "true";

        const altRowColors = this.element.attr("alternatingRowColors");
        if (altRowColors)
            this.alternatingRowColors = altRowColors.toLowerCase() === "true";

        const sOuterBorder = this.element.attr("showOuterBorder");
        if (sOuterBorder)
            this.showOuterBorder = sOuterBorder.toLowerCase() === "true";


        _this.gridEl = new DataGrid(this.element, {
            columns: this.columns,
            dataSource: this.dataSource,
            remoteOperations: {
                filtering: true,
                sorting: true,
                paging: true,
                grouping: false
            },
            selection: {
                mode: 'single',
            },
            filterRow: filterRow,
            summary: summaryRow,
            selectable: true,
            scrollable: true,
            sortable: {
                mode: "single",
                allowUnsort: true
            },
            resizable: true,
            height: `${this.getHeight()}px`,
            width: '100%',
            paging: {
                pageSize: this.defaultPageSize,
            },
            pager: {
                visible: true,
                allowedPageSizes: [5, 10, 20, 30, 50, 100],
                showPageSizeSelector: true,
                showInfo: true,
                showNavigationButtons: true,
                displayMode: 'compact'
            },
            showColumnLines: true, //work together with css, to ot show column line for data rows
            showRowLines: this.showHorizontalLines,
            rowAlternationEnabled: this.alternatingRowColors,
            showBorders: this.showOuterBorder, 
            allowColumnReordering: true,
            allowColumnResizing: true,
            columnResizingMode: 'widget',
            columnAutoWidth: true,
            stateStoring: {
                enabled: true,
                type: 'localStorage',
                storageKey: _this.currentLocalStorageKey
            },
            export: { enabled: true },
            onExporting(e) {
                //e.component.columnOption("GereneridGridId", "visible", true);
                //e.component.columnOption("GereneridGridId", "visibleIndex", 0);

                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Main');

                DevExpress.excelExporter.exportDataGrid({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        var caption = _this.options.controlState.InitCaption.replace(/ /g, "_");
                        if (!caption) {
                            caption = 'Table';
                        }
                        var fileName = `${caption}_${utils.rayGuid()}.xlsx`;
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
                });
                e.cancel = true;
            },
            //onExported: function (e) {
            //    e.component.columnOption("#", "visible", true);
            //    e.component.columnOption("GereneridGridId", "visible", false);
            //},
            onContentReady(e) {
                _this.onDataBound();

                //Hide pagination when the number of items is lower than page size
                if (e.component.pageSize() > _this.dataSource._totalCount) {
                    //_this.gridEl.option("pager.visible", false);
                    _this.element.find('.dx-pages .dx-page-indexes').hide();
                    _this.gridEl.option('pager.showInfo', false);
                } else {
                    //_this.gridEl.option("pager.visible", true);
                    _this.element.find('.dx-pages .dx-page-indexes').show();
                    _this.gridEl.option('pager.showInfo', true);
                }
            },
            onRowPrepared(e) {
                //Add unique id to each tr object
                if (e.rowType == "data") {
                    e.rowElement.attr('data-uid', e.data.GereneridGridId)
                }
            },
            onCellPrepared(e) {
                if (e.rowType == "data") {
                    e.cellElement.attr('data-fieldName', e.column.name)
                }
                if (e.rowType == "header") {
                    var col = _this.options.controlState.Columns.find(item => item.DataField === e.column.name);
                    if (col) {
                        e.cellElement.css("text-align", col.TextAlignString).css("direction", col.TextDirectionString);
                        if (!showVerticalLinesInHeader) {
                            e.cellElement.css("border-right", "0px").css("border-left", "0px");
                        }
                    }
                }
            },
        });
        this.grid = this.element.data("kendoGrid");
        _this.gridEl.on('rowDblClick', $.proxy(this.onGrid_DblClick, this));
    };

    RayGenericGrid.prototype.recalculateColumnsWidth = function () {
        var _this = this;
        if (!_this.columnRecalculated && !_this.localStorageContainsCurrentState) {
            const totalWidth = _this.element.width();
            var gridCols = _this.gridEl.option('columns');
            for (let _i = 0, _a = _this.options.controlState.Columns; _i < _a.length; _i++) {
                const col = _a[_i];

                var gridCol = gridCols.find(x => x.dataField == col.DataField);
                if (gridCol) {
                    var gridColIndex = gridCols.indexOf(gridCol);
                    var colWidth = col.Width;
                    if (_this.widthInProc) {
                        var newWidth = Math.floor(totalWidth * colWidth / 100);
                        if (newWidth < 30) {
                            gridCols[gridColIndex].minWidth = newWidth;
                        } else {
                            gridCols[gridColIndex].width = newWidth;
                        }
                    }
                }
            }
            try {
                _this.gridEl.option('columns', gridCols);
            } catch (e) {
                console.error(e);
            }

            _this.gridEl.updateDimensions();
            _this.columnRecalculated = true;
        }
    };

    RayGenericGrid.prototype.setState = function (controlState) {
        var _this = this;
        this.options.controlState = controlState;
        this.options.controlState.Behavior.Visible ? this.element.show() : this.element.hide();
        if (this.isInitialized && this.options.controlState.AutoGeneratedColumns) {
            this.options.controlState.Columns = this.AutoColumns;
        }
        if (!this.options.controlState.AutoGeneratedColumns)
            this.mapColumns();
        if (this.options.controlState.DoInit === true && this.isInitialized) {
            this.cachedData = null;
            const dataSource = this.dataSource;
            const totalPagesBeforeRead = _this.gridEl.pageCount();
            dataSource.reload().then(function () {
                const totalPagesAfterRead = _this.gridEl.pageCount();
                const curentPage = dataSource.pageIndex() + 1;
                console.log("Table pages", totalPagesBeforeRead, totalPagesAfterRead, curentPage);
                if (totalPagesBeforeRead !== totalPagesAfterRead && curentPage !== 1) {
                    dataSource.pageIndex(0);
                    dataSource.reload(); //necessary to change page
                    this.recalculateColumnsWidth();
                }
            });
        }
        if (this.options.controlState.Caption !== null) {
            this.element.find('.grid-title').text(this.options.controlState.Caption);
        }
        this.isInitialized = true;
    };

    RayGenericGrid.prototype.getValue = function () {
        const cs = this.options.controlState;
        return cs.Value;
    };

    RayGenericGrid.prototype.initDataSource = function () {
        const _this = this;

        this.dataSource = new DataSource({
            load: function (options) {
                const deferred = $.Deferred();

                if (!_this.options.controlState.Behavior.Visible) {
                    deferred.resolve([], { totalCount: 0 });
                }
                else if (_this.cachedData != null) {
                    for (let index = 0; index < _this.cachedData.data.length; index++) {
                        _this.cachedData.data[index] = _this.transformOriginalDataFieldToField(_this.cachedData.data[index]);
                    }
                    const items = _this.cachedData;
                    _this.cachedData = null;
                    deferred.resolve(items, { totalCount: items.length });
                }
                else {
                    _this.readDevExpressData(options)
                        .done(function (resultData) {
                            var res = resultData.d;
                            var loadResult = res.LoadResult;

                            if (typeof res.AutoColumns !== "undefined" && res.AutoColumns != null && res.AutoColumns.length !== 0) {
                                _this.cachedData = res.LoadResult;
                                _this.options.controlState.Columns = res.AutoColumns;
                                _this.AutoColumns = res.AutoColumns;
                                options.success([]);
                                deferred.resolve();
                            }
                            var skipNumber = 0;
                            if (options.skip) skipNumber = options.skip;

                            for (let index = 0; index < loadResult.data.length; index++) {
                                loadResult.data[index] = _this.transformOriginalDataFieldToField(loadResult.data[index]);
                                loadResult.data[index].ManuallyGeneratedRowNumber = index + 1 + skipNumber;
                            }

                            deferred.resolve(loadResult.data, {
                                totalCount: loadResult.totalCount,
                                summary: loadResult.summary,
                                groupCount: loadResult.groupCount,
                            });
                        })
                        .fail(function (e) { deferred.reject(`Error while reading data ${_this.options.controlState.Id}`); });
                }
              
                return deferred.promise();
            }
        });
        //const originalFilter = this.dataSource.filter;
        //this.dataSource.filter = function () {
        //    if (arguments.length > 0 && typeof arguments[0].filters !== "undefined" && arguments[0].filters.length > 0) {
        //        for (let i = 0; i < arguments[0].filters.length; i++) {
        //            const value = arguments[0].filters[i].value;
        //            if (typeof value !== "boolean" && typeof value !== "number")
        //                arguments[0].filters[i].value = value;
        //        }
        //    }
        //    if (arguments.length > 0)
        //        this.trigger("filtering", arguments);
        //    return originalFilter.apply(this, arguments);
        //};
        //return this.dataSource;
    };

    RayGenericGrid.prototype.readDevExpressData = function (data) {
        let sortDirection = 'asc';
        let sortColumn = null;

        // if there is no saved sort but there is initial sort, use it
        if (data.sort == null && this.options.controlState.InitialSortColumns != null && this.options.controlState.InitialSortColumns != '') {
            sortDirection = this.options.controlState.InitialSortDirectionString.toLowerCase();
            sortColumn = this.options.controlState.InitialSortColumns;
            data.sort = [{ selector: sortColumn, desc: sortDirection === 'desc' }];
            this.options.controlState.InitialSortColumns = null;
        }

        if (data.sort != null && typeof data.sort !== "undefined" && data.sort.length > 0) {
            sortColumn = this.getOriginalDataFieldByDataField(data.sort[0].field);
            sortDirection = data.sort[0].dir;
        }

        const params = {
            bindingId: this.options.controlState.BindingId,
            clientId: this.options.controlState.Id,
            pageInstance: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            //filters: data.filter,
            loadOptions: data
        };
        const headers = {
            pageInstanceId: this.options.bpmsAppForm.dto.formState.PageInstanceId
        };
        return Restful.post('api/gridsimple/de/getdata', params, headers, true);
    };


    RayGenericGrid.prototype.findColumnByName = function (name) {
        for (let _i = 0, _a = this.options.controlState.Columns; _i < _a.length; _i++) {
            const item = _a[_i];
            if (item.DataField === name)
                return item;
        }
        return null;
    };
    RayGenericGrid.prototype.getHeight = function (content) {
        if (content === void 0) { content = false; }
        let height = parseInt(this.element.attr("gridheight"));
        if (content)
            this.element[0].style.cssText += `height: ${height}px !important`;
        return height;
    };

    RayGenericGrid.prototype.onDataBound = function (e) {
        const _this = this;
        this.colorizeRowsAndColumns();
        this.element.css("height", 'auto');
        this.getHeight(true);
        if (this.cachedData != null) {
            setTimeout(function () {
                _this.isFooterToolBarInitialized = false;
                _this.mapColumns();
                _this.grid.destroy();
                _this.element.empty();
                _this.createGrid();
            }, 100);
        }



        //hmmmm
        //setTimeout(function () {
        //    if (this.grid != null && this.grid.pager != null) {
        //        if (this.dataSource.total() <= this.defaultPageSize) {
        //            this.grid.pager.element.hide();
        //            if (this.dataSource.pageIndex() + 1 > 1)
        //                this.dataSource.page(1);
        //        } else
        //            this.grid.pager.element.show();
        //    }
        //}, 100);
    };
    RayGenericGrid.prototype.colorizeRowsAndColumns = function () {
        const rows = this.dataSource._items;
        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];
            for (let colorIndex = 0; colorIndex < this.options.controlState.ColorAlerts.length; colorIndex++) {
                const colorAlert = this.options.controlState.ColorAlerts[colorIndex];
                if (row[colorAlert.AlertName] == true || (typeof row[colorAlert.AlertName] === "string" && row[colorAlert.AlertName].toLowerCase() === "true"))                 {
                    var cssText = `background-color: ${colorAlert.ColorHtml} !important; color: ${colorAlert.FontColorHtml} !important;`;
                    if (colorAlert.fieldName == null) {
                        const selectedRow = $(`tr[data-uid=${row.GereneridGridId}]`, this.element);
                        selectedRow.css('cssText', cssText);
                        selectedRow.find('td').each(function (i, obj) { obj.style.cssText += cssText; });
                    } else {
                        const selectedTd = $(`tr[data-uid=${row.GereneridGridId}] > td[data-fieldName=${colorAlert.fieldName}]`, this.element);
                        selectedTd.each(function (i, obj) { obj.style.cssText += cssText; });
                    }
                }
            }
        }
    };

    RayGenericGrid.prototype.getSelectedRowId = function () {
        const _this = this;
        const selectedItem = _this.gridEl.getSelectedRowsData()[0];
        //const selectedItem = this.grid.dataItem(this.grid.select());
        return selectedItem == null ? null : selectedItem.GereneridGridId;
    };

    RayGenericGrid.prototype.getSelectedRow = function () {
        const _this = this;
        const selectedItem = _this.gridEl.getSelectedRowsData()[0];
        return selectedItem == null ? null : selectedItem;
    };

    RayGenericGrid.prototype.viewTargetFormObject = function () {
        const self = this;
        const deferred = $.Deferred();
        const selectedRow = this.getSelectedRow();
        const params = {
            selectedRowKey: selectedRow.GereneridGridId,
            clientId: this.options.controlState.Id,
            pageInsId: this.options.bpmsAppForm.dto.formState.PageInstanceId,
            bindingId: self.options.controlState.BindingId,
            row: this.prepareData(selectedRow)
        };
        utils.callWebAPI('api/gridsimple/activateobject', params, function () { deferred.resolve(); }, function (e) { console.log('error:', e); deferred.reject(); }, "POST");
        return deferred.promise();
    };
    RayGenericGrid.prototype.prepareData = function (rowData) {
        const record = {};
        for (let _i = 0, _a = this.options.controlState.Columns; _i < _a.length; _i++) {
            const column = _a[_i];
            if (column.DataType === 'Fake') continue;
            const field = column.OriginDataField.replace(/\s/gi, '_');
            record[field] = this.sanitizeDataColumn(column, rowData[field]);
        }
        record["GereneridGridId"] = rowData["GereneridGridId"];
        return record;
    };

    RayGenericGrid.prototype.sanitizeDataColumn = function (column, data) {
        switch (column.EditType) {
            case "Date":
                return (data === '' || data == null) ? null : data.toString().replace(/\//gmi, '');
            case "CheckBox":
                if (data == null || typeof data == "undefined" || data == '')
                    return 'No';
                if ((typeof data == 'boolean' && data) || data.toString().toLowerCase() === 'true')
                    return 'Yes';
        }
        return data == '' ? null : data;
    };

    RayGenericGrid.prototype.onGrid_DblClick = function (e) {
        if (e.data == null) return;
        if (this.options.controlState.ViewButton.Enabled)
            this.onViewModal_Click(e);
        else {
            this.options.controlState.Value = this.prepareData(e.data);
            this.options.rayControlChanged(this.element, this.options.controlState);
        }
    };

    RayGenericGrid.prototype.onViewModal_Click = function (e) {
        const _this = this;
        //e.preventDefault();
        if (e.data == null || e.data.GereneridGridId == null) {
            utils.showMessage(i18n.$t('grid_control.no_rows_selected'), 1500, "warning");
            return;
        }
        this.options.bpmsAppForm
            .saveFormObject()
            .done(() => {
                this.viewTargetFormObject()
                    .done(() => {
                        this.showModal(EnumGenericGridNestedAction.View);
                    });
            });
        this.showModal(EnumGenericGridNestedAction.View);
        this.viewTargetFormObject().done(function () { _this.showModal(EnumGenericGridNestedAction.View); });
    };

    RayGenericGrid.prototype.showModal = function (action, modaldiv) {
        if (modaldiv === void 0) { modaldiv = null; }
        let nestedData = null;
        switch (action) {
            case EnumGenericGridNestedAction.View:
                nestedData = JSON.parse(this.options.controlState.ViewButton.Parameters);
                break;
        }
        if (modaldiv == null)
            modaldiv = $('<div id="modalDialog" />');
        const params = {
            nested: nestedData,
            parentBpmsAppForm: this.options.bpmsAppForm
        };
        modaldiv.bpmsAppForm(params);
    };

    RayGenericGrid.prototype.mapColumns = function () {
        const _this = this;
        this.fieldMaps = {};
        this.columns = [];
        this.aggregateFields = [];
        this.fieldMaps["GereneridGridId"] = { type: 'PrimaryKey' };
        // row number column
        this.columns.push( {
                //cellTemplate: function (element, info) {
                //    element.append(info.rowIndex + 1);
                //},
                dataField: "ManuallyGeneratedRowNumber",
                title: "GeneratedRowNumber",
                caption: "#",
                width: '40',
                allowSorting: false,
                allowResizing: false,
                allowReordering: false,
                allowSearch: false,
                allowEditing: false,
                allowExporting: false,
                allowFiltering: false,
                allowGrouping: false,
            }
            , {
                caption: "Id",
                dataField: "GereneridGridId",
                width: '315',
                visible: false,
            }
        );
        this.widthInProc = this.options.controlState.WidthInPercents;
        const nonEnglishWordRegex = new RegExp(/[^\w\d]/gi);
        const startNumberRegex = new RegExp(/^\d/gi);
        let matchIndx = 0;
        if (this.options.controlState.Columns) {
            const this_1 = this;
            const _loop_1 = function (col) {
                let cBehavior;
                if (!col.IsConvertedColumnName) {
                    if (col.DataField.match(nonEnglishWordRegex)) {
                        matchIndx++;
                        cBehavior = this_1.options.controlState.ColumnBehaviour.filter(function (value) {
                            return value.DataField === col.DataField;
                        });
                        col.OriginDataField = col.DataField;
                        if (col.DataField.match(startNumberRegex)) {
                            col.DataField = `_${col.DataField}`;
                        }
                        col.DataField = col.DataField.replace(/\s/gi, '_').replace(/[^\w\d]/gi, `RegRep${matchIndx}`);
                        if (cBehavior.length) {
                            cBehavior[0].OriginDataField = col.OriginDataField;
                            cBehavior[0].DataField = col.DataField;
                        }
                    } else {
                        cBehavior = this_1.options.controlState.ColumnBehaviour.filter(function (value) {
                            return value.DataField === col.DataField;
                        });
                        col.OriginDataField = col.DataField;
                        if (col.DataField.match(startNumberRegex)) {
                            col.DataField = `_${col.DataField}`;
                        }
                        col.DataField = col.DataField.replace(/\s/gi, '_');
                        if (cBehavior.length) {
                            cBehavior[0].OriginDataField = col.OriginDataField;
                            cBehavior[0].DataField = col.DataField;
                        }
                    }
                    col.IsConvertedColumnName = true;
                }

                var currentWidth = col.Width;
                if (this_1.widthInProc) {
                    currentWidth = currentWidth + '%';
                } else {
                    if (currentWidth < 30)
                        currentWidth = 30;
                }

                this_1.columnMap[col.DataField] = col.DataField;
                const fieldType = this_1.dotNetTypeToJavaScriptType(col.EditType);
                const column = {
                    caption: col.HeaderText,
                    dataField: col.DataField,
                    dataType: this_1.getDataType(col.EditType),
                    format: null,
                    visible: col.Visible,
                    width: currentWidth,
                    allowSorting: col.Sortable,
                    allowResizing: col.Resizable,
                    allowFiltering: this_1.options.controlState.ShowFilter,
                    cellTemplate: function (element, info) {
                        element.append(this_1.getTemplate(info, col)).css("text-align", col.TextAlignString).css("direction", col.TextDirectionString);
                    }
                };
                var format = this_1.getFormat(col.EditType);
                if (format) {
                    column.format = format;
                }
                if (!this_1.options.controlState.ShowFilter || col.EditType == EditType.DropDown)
                    column.allowFiltering = false;
                const field = { type: fieldType };
                if (col.DataField !== "GereneridGridId")
                    this_1.fieldMaps[col.DataField] = field;
                this_1.columns.push(column);
            };
            for (let _i = 0, _a = this.options.controlState.Columns; _i < _a.length; _i++) {
                const col = _a[_i];
                if (col.DataType === 'Fake') continue;
                _loop_1(col);
            }
        }
    };

    RayGenericGrid.prototype.getTemplate = function (info, column) {
        var val = info.text;
        switch (column.EditType) {
            case EditType.CheckBox:
                var chkAlignmentClass = '';
                switch (column.TextAlignString.toLowerCase()) {
                    case 'left': chkAlignmentClass = 'chkInGridToLeft'; break;
                    case 'right': chkAlignmentClass = 'chkInGridToRight'; break;
                    case 'center': chkAlignmentClass = 'chkInGridToMiddle'; break;
                    default: break;
                }
                var checked = val == 'Yes' ||  val == 'true' ? 'checked="checked"' : '';
                return `<input class='${chkAlignmentClass}' type="checkbox" ${checked} disabled="disabled" ></input>`;
            default:
                return `<div style="overflow:auto; white-space:normal;">
                            <div style=" vertical-align:middle;">${val}</div>
                        </div>`;
        }
    };
    RayGenericGrid.prototype.dotNetTypeToJavaScriptType = function (type) {
        switch (type) {
            case "CheckBox":
                return "boolean";
            case "Numeric":
            case "Numeric1":
            case "Numeric2":
            case "Numeric3":
                return "number";
            default:
                return "string";
        }
    };
    RayGenericGrid.prototype.getFormat = function (type) {
        switch (type) {
            case EditType.DateBaseOnCulture:
            case EditType.Date:
            case EditType.GregorianDate:
                return `${payload.currentLocale.ShortDateFormatG_JS}`;
            case EditType.Time:
                return `${payload.currentLocale.ShortTimeFormatG_JS}`;
            case EditType.GregorianDateTime:
            case EditType.ServerDateTime:
                return `${payload.currentLocale.ShortDateFormatG_JS} ${payload.currentLocale.ShortTimeFormatG_JS}`;
            case EditType.Numeric:
                return {type: "fixedPoint", precision: 0 };
            case EditType.Numeric1:
                return {type: "fixedPoint", precision: 1 };
            case EditType.Numeric2:
                return {type: "fixedPoint", precision: 2 };
            case EditType.Numeric3:
                return {type: "fixedPoint", precision: 3 };
            default:
                return "";
        }
    };
    RayGenericGrid.prototype.getDataType = function (type) {
        switch (type) {
            case EditType.CheckBox:
                return "boolean";
            case EditType.Numeric:
            case EditType.Numeric1:
            case EditType.Numeric2:
            case EditType.Numeric3:
                return "number";
            case EditType.DateBaseOnCulture:
            case EditType.Date:
            case EditType.GregorianDate:
                return "date";
            case EditType.Time:
            case EditType.GregorianDateTime:
            case EditType.ServerDateTime:
                return "datetime";
            case EditType.TextBox:
            case EditType.RichTextScroll:
            case EditType.RichTextExpand:
            case EditType.RichTextPopup:
            case EditType.RichTextTooltip:
            case EditType.DropDown:
            default:
                return "string";
        }
    };

    RayGenericGrid.prototype.transformOriginalDataFieldToField = function (row) {
        const newRow = {};
        for (let key in row)
            if (row.hasOwnProperty(key))
                if (key !== 'GereneridGridId')
                    newRow[this.getDataFieldByOriginalDataField(key)] = row[key];
                else
                    newRow[key] = row[key];
        return newRow;
    };
    RayGenericGrid.prototype.getDataFieldByOriginalDataField = function (originalDataField) {
        let origName = '';
        const foundItem = this.options.controlState.Columns.filter(value => value.OriginDataField === originalDataField);
        if (foundItem.length)
            origName = foundItem[0].DataField;
        const column = origName ? this.columnMap[origName] : this.columnMap[originalDataField];
        return typeof column === "undefined" ? null : column;
    };
    RayGenericGrid.prototype.getOriginalDataFieldByDataField = function (dataField) {
        const foundItem = this.options.controlState.Columns.filter(value => value.DataField === dataField);
        return foundItem.length ? foundItem[0].OriginDataField : null;
    };
    RayGenericGrid.prototype.getDataTypeByDataField = function (dataField) {
        const foundItem = this.options.controlState.Columns.filter(value => value.DataField === dataField);
        return foundItem.length ? foundItem[0].DataType : null;
    };
    return RayGenericGrid;
}());

(function ($) {
    $.widget('ui.rayGenericGrid', $.ui.rayControl, {
        _grid: RayGenericGrid,
        _create: function () {
            this._grid = new RayGenericGrid(this.options, this.element);
        },
        setState: function (cs) {
            this._grid.setState(cs);
            this.setHelp(cs);
        },
        getValue: function () {
            return this._grid.getValue();
        },
        getCaptionElement: function () {
            return this.element.find('.grid-title:first');
        }
    });
})(jQuery);

(function (EnumGenericGridNestedAction) {
    EnumGenericGridNestedAction[EnumGenericGridNestedAction["View"] = 0] = "View";
    EnumGenericGridNestedAction[EnumGenericGridNestedAction["Export"] = 1] = "Export";
})(EnumGenericGridNestedAction || (EnumGenericGridNestedAction = {}));
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["TextBox"] = 1] = "TextBox";
    EditType[EditType["CheckBox"] = 2] = "CheckBox";
    EditType[EditType["Numeric"] = 3] = "Numeric";
    EditType[EditType["Date"] = 4] = "Date";
    EditType[EditType["Time"] = 5] = "Time";
    EditType[EditType["Numeric1"] = 6] = "Numeric1";
    EditType[EditType["Numeric2"] = 7] = "Numeric2";
    EditType[EditType["Numeric3"] = 8] = "Numeric3";
    EditType[EditType["DropDown"] = 9] = "DropDown";
    EditType[EditType["GregorianDate"] = 10] = "GregorianDate";
    EditType[EditType["GregorianDateTime"] = 11] = "GregorianDateTime";
    EditType[EditType["DateBaseOnCulture"] = 12] = "DateBaseOnCulture";
    EditType[EditType["ServerDateTime"] = 13] = "ServerDateTime";
    EditType[EditType["RichTextScroll"] = 14] = "RichTextScroll";
    EditType[EditType["RichTextExpand"] = 15] = "RichTextExpand";
    EditType[EditType["RichTextPopup"] = 16] = "RichTextPopup";
    EditType[EditType["RichTextTooltip"] = 17] = "RichTextTooltip";
})(EditType || (EditType = {}));

