import ContextMenu from "../contextMenu/contextMenu.vue";

export default {
    props: [
        "loadGrid",
        "autoBind",
        "additionalParameters",
        "columns",
        "contextMenuItems",
        "gridContextOpen",
        "data",
        "dataSourceUrl",
        "hasDetail",
        "detailType", // text , grid
        "detailConfig",
        "detailTemplate",
        "filterable",
        "hasCheckbox",
        "hasContextMenu",
        "hasSearch",
        "isDraft",
        "hasPaging",
        "pagingType", // scroll, numeric
        "selectable",
        "schema",
        "sortable",
        "toolbar",
        "getToolbar",
        "resizable",
        "reloadGrid",
        "boldOnField",
        "columnsMenu",
        "group",
        "hasServerSorting",
        "height",
        "getColumns"
    ],
    data() {
        return {
            hiddenColumns: [],
            visibleColumns: [],
            gridEl: null,
            gridData: null,
            gridPrevFilters: null,
            gridToolbarEl: null,
            gridToolbarData: null,
            toolbarMapped: [],
            scrollBarWidth: kendo.support.scrollbar(),
            dataBindingFlag: false,
            selectedItems: [],
            pagingIncrement: 5,
            direction: payload.currentLocale.IsRightToLeft
        };
    },
    watch: {
        data() {
            this.init();
        },
        dataSourceUrl(newValue, oldValue) {
            this.dataSourceUrl = newValue;
            //console.log("Grid: get dataSourceUrl", this.gridData);
            if (this.gridData) {
                var that = this;
                if (that.getColumns != undefined)
                    this.getColumns(function () {
                        that.gridData.setOptions(that.buildConfig());
                        //console.log("Grid: get dataSource 1");
                        that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                        that.buildToolbar();
                        that.bindEvents();
                    });
                else {
                    that.gridData.setOptions(that.buildConfig());
                    //console.log("Grid: get dataSource 2");
                    that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                    that.buildToolbar();
                    that.bindEvents();
                }

            }
        },
        loadGrid() {
            this.init();
        },

        reloadGrid() {
            //console.log("Grid: reload", this.hasDetail, this.hasSearch);
            if (this.gridData) {
                var that = this;
                if (that.getColumns != undefined)
                    this.getColumns(function () {
                        const config = that.buildConfig();
                        //console.log("Grid: get dataSource 3", config);
                        that.gridData.setOptions(config);
                        that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                        that.buildToolbar();
                        that.bindEvents();
                        const searchStr = sessionStorage.getItem('cartableSearchItem');
                        if (searchStr != undefined && searchStr != null) {
                            $(that.gridEl).find('.rayvarzBPMSGridSearch').val(searchStr);
                            if (searchStr != '') {
                                that.gridSearch(searchStr);
                            }
                        }
                        setTimeout(function () {
                            //console.log("Grid: deal with hidden 4", that.hiddenColumns);

                            if (window.localStorage.getItem('hiddenColumns') != null &&
                                window.localStorage.getItem('hiddenColumns') != undefined) {
                                that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                                for (let i = 0; i < that.hiddenColumns.length; i++)
                                    that.gridData.hideColumn(that.hiddenColumns[i].field);
                            }
                            if (window.localStorage.getItem('visibleColumns') != null &&
                                window.localStorage.getItem('visibleColumns') != undefined) {
                                that.visibleColumns = JSON.parse(window.localStorage.getItem('visibleColumns'));
                                for (let i = 0; i < that.visibleColumns.length; i++)
                                    that.gridData.showColumn(that.visibleColumns[i].field);
                            }
                        }, 10);
                    });
                else {
                    that.gridData.setOptions(that.buildConfig());
                    that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                    that.buildToolbar();
                    that.bindEvents();
                    const searchStr = sessionStorage.getItem('cartableSearchItem');
                    if (searchStr != undefined && searchStr != null) {
                        $(that.gridEl).find('.rayvarzBPMSGridSearch').val(searchStr);
                        if (searchStr != '')
                            that.gridSearch(searchStr);
                    }
                    setTimeout(function () {
                        //if ($window.localStorage.getItem('hiddenColumns') != null && window.localStorage.getItem('hiddenColumns') != undefined) {
                        //    that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                        //    for (var i = 0; i < that.hiddenColumns.length; i++) {
                        //        that.gridData.hideColumn(that.hiddenColumns[i].name);
                        //    }
                        //}

                        //console.log("Grid: deal with hidden 3", that.hiddenColumns);
                        if (window.localStorage.getItem('hiddenColumns') != null &&
                            window.localStorage.getItem('hiddenColumns') != undefined)
                            that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                        else
                            that.hiddenColumns = that.columns.filter(function (col) { return col.hidden; });

                        //console.log("Grid: deal with hidden 3", that.hiddenColumns, that.columns);

                        for (let i = 0; i < that.hiddenColumns.length; i++)
                            that.gridData.hideColumn(that.hiddenColumns[i].filed);

                        if (window.localStorage.getItem('visibleColumns') != null && window.localStorage.getItem('visibleColumns') != undefined) {
                            that.visibleColumns = JSON.parse(window.localStorage.getItem('visibleColumns'));
                            for (let i = 0; i < that.visibleColumns.length; i++)
                                that.gridData.showColumn(that.visibleColumns[i].filed);
                        }
                    }, 10);
                }
            }

        }
    },
    computed: {
        gridContextItems() {
            return typeof this.contextMenuItems == 'function' ? this.getContextMenuItems : this.contextMenuItems;
        },
        isMultipleSelectable() {
            return this.selectable.toString().indexOf('multiple') > -1;
        }
    },
    methods: {
        init() {
            //console.log("Grid: init", this.hasDetail, this.hasSearch);
            window.TaskGridRecord = 0;
            if (this.loadGrid == "false")
                return;
            if (!this.gridData) {
                this.gridEl = $(this.$el).find('.rayvarzBPMSGrid');
                if (this.data || this.dataSourceUrl) {
                    var that = this;
                    if (that.getColumns != undefined)
                        that.getColumns(function() {
                            const config = that.buildConfig();
                            //console.log("Grid: need to build config (init 1)", config);
                            that.gridData = that.gridEl.kendoGrid(config).data('kendoGrid');
                            that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                            that.buildToolbar();
                            that.bindEvents();
                            const searchStr = sessionStorage.getItem('cartableSearchItem');
                            if (searchStr != undefined && searchStr != null) {
                                $(that.gridEl).find('.rayvarzBPMSGridSearch').val(searchStr);
                                if (searchStr != '') {
                                    that.gridSearch(searchStr);
                                }
                            }
                            setTimeout(function() {
                                    //console.log("Grid: deal with hidden 1", that.hiddenColumns);
                                    if (window.localStorage.getItem('hiddenColumns') != null &&
                                        window.localStorage.getItem('hiddenColumns') != undefined)
                                        that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                                    else
                                        that.hiddenColumns = that.columns.filter(function(col) { return col.hidden; });

                                    //console.log("Grid: deal with hidden 1", that.hiddenColumns, that.columns);

                                    for (let i = 0; i < that.hiddenColumns.length; i++)
                                        that.gridData.hideColumn(that.hiddenColumns[i].field);

                                    if (window.localStorage.getItem('visibleColumns') != null &&
                                        window.localStorage.getItem('visibleColumns') != undefined) {
                                        that.visibleColumns = JSON.parse(window.localStorage.getItem('visibleColumns'));
                                        for (let i = 0; i < that.visibleColumns.length; i++)
                                            that.gridData.showColumn(that.visibleColumns[i].field);
                                    }
                                },
                                10);
                        });
                    else {
                        const config = that.buildConfig();
                        //console.log("Grid: need to build config (init 2)", config);
                        that.gridData = that.gridEl.kendoGrid(config).data('kendoGrid');
                        that.gridToolbarEl = that.gridEl.find('.rayBPMSGridToolbarTemplate');
                        that.buildToolbar();
                        that.bindEvents();
                        const searchStr = sessionStorage.getItem('cartableSearchItem');
                        if (searchStr != undefined && searchStr != null) {
                            $(that.gridEl).find('.rayvarzBPMSGridSearch').val(searchStr);
                            if (searchStr != '')
                                that.gridSearch(searchStr);
                        }
                        setTimeout(function() {
                                //if (window.localStorage.getItem('hiddenColumns') != null && window.localStorage.getItem('hiddenColumns') != undefined) {
                                //    that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                                //    for (var i = 0; i < that.hiddenColumns.length; i++) {
                                //        that.gridData.hideColumn(that.hiddenColumns[i].name);
                                //    }
                                //}

                                if (window.localStorage.getItem('hiddenColumns') != null &&
                                    window.localStorage.getItem('hiddenColumns') != undefined)
                                    that.hiddenColumns = JSON.parse(window.localStorage.getItem('hiddenColumns'));
                                else
                                    that.hiddenColumns = that.columns.filter(function(col) { return col.hidden; });

                                //console.log("Grid: deal with hidden 2", that.hiddenColumns, that.gridData.columns);

                                for (let i = 0; i < that.hiddenColumns.length; i++) {
                                    //console.log("hide column", that.hiddenColumns[i].field, that.gridData.columns.find(e => e.field == that.hiddenColumns[i].field));
                                    that.gridData.hideColumn(that.hiddenColumns[i].field);
                                }

                                if (window.localStorage.getItem('visibleColumns') != null &&
                                    window.localStorage.getItem('visibleColumns') != undefined) {
                                    that.visibleColumns = JSON.parse(window.localStorage.getItem('visibleColumns'));
                                    //console.log("show columns 2 ", that.visibleColumns);
                                    for (let i = 0; i < that.visibleColumns.length; i++)
                                        that.gridData.showColumn(that.visibleColumns[i].field);
                                }
                            },
                            10);
                    }
                    that.gridData.pager.element.show();
                }
            } 
        },

        destroy() {
            if (this.gridData) {
                this.gridData.destroy();
                this.gridEl.html('');
            }
        },

        buildConfig() {
            //console.log("Grid: buildConfig", this.hasDetail, this.hasSearch);
            var self = this;
            return {
                allowCopy: true,
                autoBind: this.autoBind !== undefined ? this.autoBind : true,
                columns: this.buildColumns(),
                columnMenu: this.columnsMenu === "true" ? { columns: true } : false,

                columnHide: function (e) {
                    var isExist = false;
                    for (let i = 0; i < self.hiddenColumns.length; i++)
                        if (e.column.name === self.hiddenColumns[i].name)
                            isExist = true;

                    if (!isExist)
                        self.hiddenColumns.push(e.column);

                    //console.log("hide column custom", e, self.visibleColumns);

                    for (let i = 0; i < self.visibleColumns.length; i++)
                        if (e.column.name === self.visibleColumns[i].name)
                            self.visibleColumns.splice(i, 1);

                    window.localStorage.setItem('hiddenColumns', JSON.stringify(self.hiddenColumns));
                    window.localStorage.setItem('visibleColumns', JSON.stringify(self.visibleColumns));
                },

                columnShow: function (e) {
                    var isExist = false;
                    for (let i = 0; i < self.visibleColumns.length; i++)
                        if (e.column.name === self.visibleColumns[i].name)
                            isExist = true;

                    if (!isExist)
                        self.visibleColumns.push(e.column);

                    for (let i = 0; i < self.hiddenColumns.length; i++)
                        if (e.column.name === self.hiddenColumns[i].name)
                            self.hiddenColumns.splice(i, 1);

                    //console.log("Grid: get columns", self.hiddenColumns, self.visibleColumns);
                    window.localStorage.setItem('hiddenColumns', JSON.stringify(self.hiddenColumns));
                    window.localStorage.setItem('visibleColumns', JSON.stringify(self.visibleColumns));
                },
                filter: function () { },
                change: this.gridSelectChange,
                dataSource: this.buildDataSource(),
                dataBound: this.dataBound,
                detailTemplate: this.hasDetail && this.detailTemplate ? kendo.template(this.detailTemplate) : null,
                detailInit: this.hasDetail ? (typeof this.detailConfig === 'function' ? this.detailConfig : this.gridDetailInit) : null,
                //mobile:true,
                pageable: this.buildPaging(),
                resizable: !!this.resizable,
                selectable: this.selectable !== undefined ? this.selectable : false,
                toolbar: "<div class='rayBPMSGridToolbarTemplate'></div>",
                filterable: this.filterable == "true",
                sortable: this.sortable == "true",
                height: this.height
            };
        },

        buildColumns() {
            let cols = [];
            //console.log("Grid: buildColumns() 1", this.columns);

            if (this.columns != undefined && this.columns.length > 1)
                cols = this.columns;
            if (this.getColumns != undefined)
                cols = this.getColumns();

            if (this.hasCheckbox === "true" && cols && cols.filter((value) => { return value.field === 'checkbox'; }).length === 0) {
                cols.splice(1, 0,
                    {
                        field: 'checkbox',
                        title: "",
                        filterable: false,
                        sortable: false,
                        groupable: false,
                        selectable: false,
                        headerTemplate: this.isMultipleSelectable ? "<input class='rayBPMSGridCheckAll' type='checkbox'/>" : "",
                        menu: false,
                        width: 50,
                        template: function (dataItem) {
                            return `<input taskId='${dataItem.Id}' class='rayBPMSGridCheckbox'  type='checkbox'/>`;
                        }
                    }
                );
            }
            //console.log("Grid: buildColumns() 2", cols);
            return cols;
        },

        buildDataSource() {
            const dSource = this.dataSourceUrl ?
                new kendo.data.DataSource(
                    {
                        transport: {
                            type: "json",
                            read: {
                                url: this.dataSourceUrl,
                                type: "POST",
                                dataType: "json",
                                contentType: "application/json; charset=utf-8",
                                beforeSend: function (xhr) {
                                    xhr.setRequestHeader("__RequestVerificationToken", $("[name='__RequestVerificationToken']").val());
                                }
                            },
                            parameterMap: (data, operation) => {
                                if (this.additionalParameters) {
                                    data.Data = this.additionalParameters;
                                }
                                return JSON.stringify(data);
                            }
                        },
                        schema: this.schema == null ? {
                            data: "Data",
                            total: function (response) { return response.Total; },
                            aggregates: "Aggregates"
                        } : this.schema,
                        page: 1,
                        pageSize: this.pagingType === "scroll" ? 30 : 10,
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: this.hasServerSorting === undefined ? true : this.hasServerSorting == "true",
                        group: this.group,
                    }) :
                (Array.isArray(this.data) ?
                    {
                        data: this.data,
                        pageSize: 10
                    } : []);
            return dSource;
        },
        buildPaging() {
            let result = false;
            if (this.hasPaging) {
                switch (this.pagingType) {
                    case "scroll":
                        result = {
                            pageSize: 10,
                            refresh: true,
                            numeric: true,
                            buttonCount: 0,
                            previousNext: false,
                            info: true
                        };
                        break;
                    case "numeric":
                    default:
                        result = {
                            pageSize: 10,
                            refresh: true,
                            pageSizes: [5, 10, 20],
                            numeric: true,
                            buttonCount: 5
                            //previousNext: true
                        };
                }
            }
            return result;
        },
        bindEvents() {
            const vm = this;
            const gridEl = this.gridEl;
            const gridData = this.gridData;
            const self = this;
            if (this.hasCheckbox) {
                gridEl.on('change.rayBPMSGridCheckAll', '.rayBPMSGridCheckAll', function () {
                    const cbx = $(this);
                    if (cbx.prop('checked'))
                        gridEl.find('tbody .rayBPMSGridCheckbox').prop('checked', true).trigger('change');
                    else
                        gridEl.find('tbody .rayBPMSGridCheckbox').prop('checked', false).trigger('change');
                });
                gridEl.on('change.rayBPMSGridCheckbox', '.rayBPMSGridCheckbox', function (e) {
                    const cbx = $(this);
                    if (!vm.isMultipleSelectable) {
                        gridEl.find('tbody .rayBPMSGridCheckbox').not(cbx).each(function () {
                            $(this).prop('checked', false);
                            $(this).closest('tr').removeClass('k-state-selected');
                        });
                    }

                    if (cbx.prop('checked')) {
                        cbx.closest('tr').addClass('k-state-selected');
                        cbx.closest('tr').attr('aria-selected', 'true');
                        cbx.attr('isSelected', 'true');

                        self.gridData.select(cbx.closest('tr'));

                    } else {
                        cbx.closest('tr').removeClass('k-state-selected');
                        cbx.closest('tr').removeAttr('aria-selected');

                    }
                    vm.reviewAllCbxCondition();
                    vm.reviewToolbar();
                });
            }
            if (this.hasPaging && this.pagingType === "scroll") {
                const thisClass = this;

                const gridDataSource = this.gridData.dataSource;
                this.gridEl.children(".k-grid-content")
                    .on("scroll", function (e) {
                        if (thisClass.dataBindingFlag) {
                            const searchStr = $(thisClass.gridEl).find('.rayvarzBPMSGridSearch').val();
                            const dataDiv = e.target;
                            const currentPageSize = gridDataSource.pageSize();
                            if (dataDiv.scrollTop >= dataDiv.scrollHeight - dataDiv.offsetHeight - thisClass.scrollBarWidth
                                && gridDataSource.total() > currentPageSize) {
                                self.selectedItems = [];
                                $(self.gridData.select()).each(function () {
                                    self.selectedItems.push(this);
                                });

                                thisClass.dataBindingFlag = false;
                                if (searchStr != "") {

                                    thisClass.gridData.dataSource.query({
                                        aggregate: thisClass.gridData.dataSource.aggregate(),
                                        page: 1,
                                        pageSize: currentPageSize + thisClass.pagingIncrement,
                                        filter: thisClass.gridData.dataSource.filter(),
                                        sort: thisClass.gridData.dataSource.sort(),
                                        searchStr: searchStr
                                    });
                                }
                                else {
                                    gridDataSource.pageSize(currentPageSize + thisClass.pagingIncrement);
                                }
                            }
                        }
                    });
            }
            if (this.hasSearch) {
                this.gridEl.on('keyup.gridSearch', '.rayvarzBPMSGridSearch', (e) => {
                    const searchInput = $(e.target);
                    sessionStorage.setItem('cartableSearchItem', searchInput.val());
                    this.gridSearch(searchInput.val());
                });
            }
        },

        buildToolbar() {
            //console.log("Build toolbar", this.hasSearch);
            let toolbar = Array.isArray(this.toolbar) ? this.toolbar.slice() : [];
            if (this.getToolbar != undefined)
                toolbar = this.getToolbar();
            //console.log("Build toolbar", toolbar);
            if (this.hasSearch && (toolbar.filter((value) => {
                return value.key == 'search';
            }).length == 0)) {
                if (toolbar.length)
                    toolbar.push({
                        type: "separator"
                    });
                toolbar.push({
                    key: 'search',
                    template: "<input type='text' class='rayvarzBPMSGridSearch form-control input-sm' placeholder='" + i18n.$t("search") + "...'/>"
                });
            }
            if (toolbar.length) {
                let idIndex = 0;
                const toolbarMapFn = (value) => {
                    return {
                        id: value.type + (idIndex++),
                        type: value.type,
                        text: value.text,
                        click: this.toolbarAction(value.click),
                        icon: value.icon,
                        //imageUrl: value.imageUrl,
                        enable: value.enable,
                        buttons: value.buttons ? value.buttons.map(toolbarMapFn) : undefined,
                        menuButtons: value.menuButtons ? value.menuButtons.map(toolbarMapFn) : undefined,
                        template: value.template ? value.template : undefined
                    };
                };
                this.toolbarMapped = toolbar.map(toolbarMapFn);
                this.gridToolbarData = this.gridToolbarEl.kendoToolBar({
                    items: this.toolbarMapped
                }).data('kendoToolBar');
            }

            //console.log("remove menu 2",
            //    this.hasCheckbox,
            //    this.gridData.thead.find("[data-field=checkbox]>.k-header-column-menu"));

            if (this.hasCheckbox)
                this.gridData.thead.find("[data-field=checkbox]>.k-header-column-menu").remove();
        },

        dataBound(e) {
            this.dataBindingFlag = true;
            if (this.boldOnField) {
                this.gridEl.find('tr').each((index, value) => {
                    const slf = $(value);
                    const dataItem = this.gridData.dataItem(slf);
                    if (dataItem && !dataItem[this.boldOnField])
                        slf.addClass('rayBPMSGridBoldRow');
                });
            }
            const self = this;

            $(self.selectedItems).each(function (index, value) {
                const el = $(value);
                const taskId = el.children('td').children('.rayBPMSGridCheckbox').attr("taskId");
                const child = $(self.gridEl).find('.rayBPMSGridCheckbox[taskId=' + taskId + ']');
                if (child.length > 0) {
                    self.reviewMultipleSelect();
                    const cbx = $(child[0]);
                    cbx.prop('checked', true);
                    cbx.closest('tr').addClass('k-state-selected');
                    cbx.closest('tr').attr('aria-selected', 'true');
                    cbx.attr('isSelected', 'true');
                    self.gridData.select(cbx.closest('tr'));
                }
            });
            if (this.isDraft) {
                this.gridEl.find('tr').each((index, value) => {
                    $(value).find('.k-hierarchy-cell a').hide()
                });
            }

            // if (this.gridData.dataSource.sort() != undefined)
            //  sessionStorage.setItem('cartableSort', JSON.stringify(this.gridData.dataSource.sort()));

            $(self.selectedItems).each(function (index, value) {
                const el = $(value);
                const taskId = el.children('td').children('.rayBPMSGridCheckbox').attr("taskId");
                const child = $(self.gridEl).find('.rayBPMSGridCheckbox[taskId=' + taskId + ']');
                if (child.length > 0) {
                    self.reviewMultipleSelect();
                    const cbx = $(child[0]);
                    cbx.prop('checked', true);
                    cbx.closest('tr').addClass('k-state-selected');
                    cbx.closest('tr').attr('aria-selected', 'true');
                    cbx.attr('isSelected', 'true');
                    self.gridData.select(cbx.closest('tr'));
                }
            });

        },
        deselectRow(tr) {
            tr = $(tr);
            tr.removeAttr('aria-selected');
            tr.removeClass('k-state-selected');
        },
        getContextMenuItems(node) {
            if (typeof this.contextMenuItems == 'function' && this.gridData) {
                const nodeData = this.gridData.dataItem(node);
                return this.contextMenuItems(node, nodeData);
            }
        },
        gridContextItemSelect(node, callback) {
            const nodeData = this.gridData.dataItem(node);
            const selectedEls = this.gridData.select();
            const selectedDatas = [];
            selectedEls.each((index, data) => {
                selectedDatas.push(this.gridData.dataItem(data));
            });

            callback(node, [nodeData], selectedDatas);
        },
        gridContextItemOpen(node, isSubMenu) {
            node = $(node);
            if (this.selectable) {
                this.reviewMultipleSelect();
                this.gridData.select(node);
                node.children('td').children('input[type="checkbox"]').each((index, value) => {
                    var cbx = $(value);
                    cbx.prop('checked', true);
                });
                const selecteds = this.gridData.select();
                if (!isSubMenu)
                    this.gridContextOpen(selecteds, node, this.gridData.dataItem(node));
            }
            this.reviewAllCbxCondition();
        },
        gridDetailInit(value) { },
        gridSearch(searchStr) {
            let filter = searchStr === "";
            if (this.dataSourceUrl === undefined) {
                filter = {
                    logic: "or",
                    filters: this.columns.map((value) => {
                        return {
                            field: value.field,
                            operator: function (item) {
                                return item !== undefined ?
                                    item.toString().toLowerCase().indexOf(searchStr) > -1 :
                                    false;
                            },
                            value: searchStr
                        };
                    })
                };
                this.gridData.dataSource.filter(filter);
            } else {
                // var s = this.gridData.dataSource.sort();
                //if (s === null || s === undefined) {
                //  s = sessionStorage.getItem('cartableSort');
                //if (s === undefined)
                //  s = null;
                //}
                this.gridData.dataSource.query({
                    aggregate: this.gridData.dataSource.aggregate(),
                    page: 1,
                    pageSize: this.gridData.dataSource.pageSize(),
                    filter: this.gridData.dataSource.filter(),
                    sort: this.gridData.dataSource.sort(),
                    searchStr: searchStr
                });
            }
        },
        gridSelectChange(e) {
            const selectedEls = this.gridData.select();
            const thisClass = this;
            const cbxAll = this.gridEl.find('.rayBPMSGridCheckAll');
            cbxAll.prop('checked', false);
            selectedEls.each((index, value) => {
                const el = $(value);
                const cbx = el.children('td').children('.rayBPMSGridCheckbox');
                thisClass.reviewMultipleSelect();
                cbx.prop('checked', true);
            });
            this.reviewAllCbxCondition();
            this.reviewToolbar();
        },
        reviewAllCbxCondition() {
            if (this.hasCheckbox === "true" && this.isMultipleSelectable) {
                const cbxAll = this.gridEl.find('.rayBPMSGridCheckAll');
                const allCbx = this.gridEl.find('tbody tr > td > .rayBPMSGridCheckbox');
                const allCheckedCbxLenght = allCbx.filter((index, value) => {
                    return $(value).prop('checked');
                }).length;
                if (allCbx.length === allCheckedCbxLenght)
                    cbxAll.prop('checked', true);
                else
                    cbxAll.prop('checked', false);
            }
        },
        reviewMultipleSelect() {
            const thisClass = this;
            if (!this.isMultipleSelectable)
                this.gridEl.find('tbody tr > td > .rayBPMSGridCheckbox').prop('checked', false);
        },
        reviewToolbar() {
            let enable = false;
            const forEachFn = (value) => {
                this.gridToolbarData.enable(`#${value.id}`, enable);
                if (value.buttons)
                    value.buttons.forEach(forEachFn);
                if (value.menuButtons)
                    value.menuButtons.forEach(forEachFn);
            };
            enable = !!this.gridData.select().length;
            this.toolbarMapped.forEach(forEachFn);
        },
        toolbarAction(callback) {
            const gridEl = this.gridEl;
            const gridData = this.gridData;
            return function (e) {
                const selectedEls = gridData.select();
                const selectedDatas = [];
                selectedEls.each((index, data) => {
                    selectedDatas.push(gridData.dataItem(data));
                });
                if (typeof callback === 'function')
                    return callback(selectedEls, selectedDatas, selectedDatas);
            };
        },
        reload() {
            this.destroy();
            this.init();
        }
    },
    updated() {
    },
    mounted() {
        this.init();
    },
    components: {
        ContextMenu
    }
}
