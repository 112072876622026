(function ($) {
    var settings;


    var addMask = function (obj) {
        var mask = $(obj).attr('mask');
        if (mask) {
            $(obj).mask(mask);            
            $(obj).on("focus", function () {
                if ($(obj).val() == mask) {
                    utils.setSelectionRange(this.input, 0, 1);
                }
            });
        }

    }

    var methods = {
        init: function (options) {
            settings = $.extend(
            {
            }, options);
            //Init Controls
            $(this).each(function () {
                $(this).change(function () {
                    fixData($(this), $(this).attr('NumberFormat'), $(this).attr('DecimalSeparator'));
                });
                $(this).keypress(function (event) {
                    check($(this), $(this).attr('NumberFormat'), $(this).attr('DecimalSeparator'), event);
                });

                addMask(this);
            });



            return this;
        },
    }; /* Private Methods */
    var numericInputKillExtraDigits = function (val, type, decimalSeparator) {
        if (type == 0)
            return (val);
        if (type == 1)
            return (val);
        var dotPos = val.indexOf(decimalSeparator);
        if (dotPos == -1)
            return (val + decimalSeparator + "00");
        val += "00";
        return (val.slice(0, dotPos + 3));
    };
    var fixData = function (obj, type, decimalSeparator) {
        var val = obj.val();
        var newVal = "";
        var gotDot = false;
        for (var i = 0; i < val.length; i++) {
            var c = val.charAt(i);
            if (c == "-" && i == 0) {
                newVal += c;
                continue;
            }
            if (c == decimalSeparator) {
                if (type == 0) {
                    obj.val(newVal);
                    return;
                }
                if (gotDot == true) {
                    obj.val(numericInputKillExtraDigits(newVal, type, decimalSeparator));
                    return;
                }
                newVal += c;
                gotDot = true;
                continue;
            }
            if (c >= '0' && c <= '9')
                newVal += c;
        }
        obj.val(numericInputKillExtraDigits(newVal, type, decimalSeparator));
    };
    var check = function (obj, type, decimalSeparator, event) {
        var val = obj.val();
        if (val.lenght > 18) {
            event.preventDefault();
            return false;
        }
        var key = event.which;
        var keyAscii = String.fromCharCode(key);
        if (key == 0 || key == 8)
            return;

        if (String.fromCharCode(event.which).toLowerCase() === "v" && event.ctrlKey)
            return;

        if (keyAscii == "-") {
            if (obj.val() != "") {
                event.preventDefault();
                return false;
            }
            return;
        }
        switch (type) {
            case "0": 	// integer                
                if (keyAscii < '0' || keyAscii > '9') {
                    event.preventDefault();
                    return false;
                }
                return;
            case "1": 	// Float
            case "2": 	// Currency
                var dotPos = val.indexOf(decimalSeparator);
                if (keyAscii == decimalSeparator) {
                    if (dotPos >= 0) {
                        event.preventDefault();
                        return false;
                    }
                    return;
                }
                if (keyAscii < '0' || keyAscii > '9') {
                    event.preventDefault();
                    return false;
                }
                return;
        }
    };
    $.fn.raynumerictextbox = function (options) {
        if (methods[options]) return methods[options].apply(this, Array.prototype.slice.call(arguments, 1)); else if (typeof options === 'object' || !options) return methods.init.apply(this, arguments); else $.error('Method ' + options + ' Does Not Exist!');
        return methods[options].apply(this, Array.prototype.slice.call(arguments, 1));
    };
})(jQuery);