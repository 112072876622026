export default {
    data() {
        return {
            ErrorMessage: ''
        }
    },
    methods: {
    },
    updated() { },
    mounted() {
        console.log("Error init", this.$route.params.code);
        this.ErrorMessage = this.$route.params.code;
    }
}
