import utils from 'jsf/utils.js';

export function processLog() {
    var $root = $("<div></div>");
    var activate = function (params) {
        config.currentViewCanLeave(canLeave);
        var $processLogForm = $("<div></div>");
        utils.showModalHtml('Views/ProcessLog.html').done(
            function (html) {

                $root.rayModal({
                    title: i18n.$t("title.procces_history"),
                    width: 1200,
                    height: 700,
                    onOpened: function () {
                        $root.empty();
                        $root.append(html);
                        init(params.id, params.domainObjectId, params.taskTypeId);
                    },
                    onClosed: function () { 
                        $($root).rayModal('destroy');
                        $root.remove();
                    }
                });
                new legacyComponent({ i18n: vueI18n }).$mount($root[0]);
            }
        );
        $processLogForm.show();
    };
    var noderender = function (r, n, color) {
        var width = 30;
        var height = 20;
        var strokewidth = "2px";
        var fontSize = "12px";
        if (n.isAssign) {
            width = 80;
            height = 60;
            strokewidth = "4px";
        }
        var set = r.set().push(
            r.ellipse(n.point[0], n.point[1], width, height).attr({ "fill": color, r: "12px", "stroke-width": strokewidth })).push(
            r.text(n.point[0], n.point[1], n.label).attr({ "font-size": fontSize, "font-family": "tahoma" })
            );
        return set;
    },
        canLeave = function () {
            return true;
        };
    var renderStart = function (r, n) {
        return noderender(r, n, "#FF0000");
    };
    var renderHuman = function (r, n) {
        return noderender(r, n, "#FF8000");
    };
    var renderFork = function (r, n) {
        return noderender(r, n, "#0054A8");
    };
    var renderJoin = function (r, n) {
        return noderender(r, n, "#0054A8");
    };
    var renderSystem = function (r, n) {
        return noderender(r, n, "#C0C0C0");
    };
    var renderCommon = function (r, n) {
        return noderender(r, n, "#2C2C2C");
    };
    var init = function (id, domainObjectId, taskTypeId) {
        var graph = new Graph();
        var graphStructur;
        var url = "api/task/gethistorygraph";
        var params = { taskInstanceId: id };

        if (typeof id === "undefined") {
            url = "api/task/gethistorygraphbydomain";
            params = { domainObjectId: domainObjectId, taskTypeId: taskTypeId };
        }

        utils.callWebAPIp(url, params, "GET")
            .done(function (result) {
                graphStructur = result.d;
                if (graphStructur != null) {
                    for (var i = 0; i < graphStructur.Nodes.length; i++) {
                        var node = graphStructur.Nodes[i];
                        var key = node.Key.toString();
                        var label = node.Label.toString();
                        var isAssign = node.IsAssign;
                        var nrender = null;
                        if (node.Render == "renderStart")
                            nrender = renderStart;
                        else if (node.Render == "renderHuman")
                            nrender = renderHuman;
                        else if (node.Render == "renderFork")
                            nrender = renderFork;
                        else if (node.Render == "renderJoin")
                            nrender = renderJoin;
                        else if (node.Render == "renderSystem")
                            nrender = renderSystem;
                        else if (node.Render == "renderCommon")
                            nrender = renderCommon;
                        graph.addNode(key, { label: label, render: nrender, isAssign: isAssign });
                    }

                    for (var j = 0; j < graphStructur.Edges.length; j++) {
                        var edge = graphStructur.Edges[j];
                        var from = edge.FromNode.toString();
                        var to = edge.ToNode.toString();
                        var edgelabel = edge.Label.toString();
                        graph.addEdge(from, to, { label: edgelabel, stroke: "#000000", fill: "#000000", directed: true });
                    }
                    var layouter = new Graph.Layout.Ordered(graph, topological_sort(graph));
                    layouter.layout();
                    setTimeout(function () {
                        var renderer = new Graph.Renderer.Raphael("graph_div",
                            graph,
                            graphStructur.Width,
                            graphStructur.Height);
                        renderer.draw();
                    },
                        100);
                }
            })
            .fail(function (xmlHttpRequest) {
                utils.showMessage(xmlHttpRequest.responseText);
            });
    };
    return {
        activate: activate
    };
}
