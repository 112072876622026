var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "newForm",
    {
      attrs: {
        title: _vm.$t("delegation_management.delegations"),
        className: "col-xs-12",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c("dx-button", {
            attrs: {
              width: 120,
              text: _vm.$t("delegation_management.delegation_create"),
              icon: "fad fa-plus-square",
              type: "normal",
              "styling-mode": "contained",
            },
            on: {
              click: function ($event) {
                return _vm.onClickAdd()
              },
            },
          }),
          _vm._v(" "),
          _c("dx-button", {
            attrs: {
              width: 120,
              text: _vm.$t("edit"),
              icon: "fad fa-edit",
              type: "normal",
              "styling-mode": "contained",
              disabled: !_vm.isGridRowSelected,
            },
            on: {
              click: function ($event) {
                return _vm.onClickEdit()
              },
            },
          }),
          _vm._v(" "),
          _c("dx-button", {
            attrs: {
              width: 120,
              text: _vm.$t("remove"),
              icon: "fad fa-trash-alt",
              type: "normal",
              "styling-mode": "contained",
              disabled: !_vm.isGridRowSelected,
            },
            on: {
              click: function ($event) {
                return _vm.onClickDelete()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dx-data-grid",
        {
          ref: _vm.dataGridRefKey,
          attrs: {
            "show-borders": false,
            "show-column-lines": false,
            "allow-column-reordering": true,
            "allow-column-resizing": true,
            "column-auto-width": true,
            "hover-state-enabled": true,
            selection: { mode: "single" },
            "data-source": "api/delegations/list",
          },
          on: { "selection-changed": _vm.onSelectionChanged },
        },
        [
          _c("dx-paging", { attrs: { "page-size": 20 } }),
          _vm._v(" "),
          _c("dx-pager", {
            attrs: {
              "show-page-size-selector": true,
              visible: "auto",
              "allowed-page-sizes": _vm.pageSizes,
              "show-info": true,
            },
          }),
          _vm._v(" "),
          _c("dx-column", {
            attrs: {
              "data-field": "DestinationTitle",
              caption: _vm.$t("delegation_management.delegate_to"),
            },
          }),
          _vm._v(" "),
          _c("dx-column", {
            attrs: {
              "data-field": "FromDate",
              "data-type": "date",
              width: 250,
              caption: _vm.$t("delegation_management.start_date"),
            },
          }),
          _vm._v(" "),
          _c("dx-column", {
            attrs: {
              "data-field": "ToDate",
              "data-type": "date",
              width: 250,
              caption: _vm.$t("delegation_management.finish_date"),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.currentModalComponent, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDelegationModal,
            expression: "showDelegationModal",
          },
        ],
        tag: "component",
        attrs: {
          showDelegationModal: _vm.showDelegationModal,
          modalData: _vm.modalData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }