var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : ""] },
    [
      _c(
        "newForm",
        {
          class: [_vm.direction ? "k-rtl" : ""],
          attrs: {
            useTitle: "true",
            title: _vm.$t("logs_management.application_log"),
          },
        },
        [
          _c(
            "DxDataGrid",
            {
              ref: _vm.dataGridRefKey,
              attrs: {
                id: "gridContainer",
                "show-borders": true,
                "data-source": _vm.dataSource,
                "remote-operations": true,
                "column-auto-width": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
              },
              on: { exporting: _vm.onExporting },
              scopedSlots: _vm._u([
                {
                  key: "refreshTemplate",
                  fn: function () {
                    return [
                      _c("DxButtonToolbar", {
                        attrs: { icon: "refresh" },
                        on: { click: _vm.refreshDataGrid },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("DxSearchPanel", {
                attrs: {
                  visible: true,
                  width: 240,
                  placeholder: _vm.$t("logs_management.search"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Id",
                  caption: "ID",
                  "allow-header-filtering": false,
                  "sort-index": 1,
                  "sort-order": "desc",
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Date",
                  "data-type": "datetime",
                  format: _vm.datetimeFormat,
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Level",
                  caption: _vm.$t("logs_management.severity"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: { "data-field": "Logger", caption: "Module" },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Duration",
                  "data-type": "number",
                  caption: _vm.$t("logs_management.duration"),
                },
              }),
              _vm._v(" "),
              _c("DxColumn", {
                attrs: {
                  "data-field": "Message",
                  "allow-header-filtering": false,
                },
              }),
              _vm._v(" "),
              _c("DxExport", { attrs: { enabled: true } }),
              _vm._v(" "),
              _c(
                "DxToolbar",
                [
                  _c("DxItem", { attrs: { name: "searchPanel" } }),
                  _vm._v(" "),
                  _c("DxItem", {
                    attrs: { location: "after", template: "refreshTemplate" },
                  }),
                  _vm._v(" "),
                  _c("DxItem", { attrs: { name: "exportButton" } }),
                ],
                1
              ),
              _vm._v(" "),
              _vm._v(" "),
              _c("DxPaging", { attrs: { "page-size": 15 } }),
              _vm._v(" "),
              _c("DxPager", {
                attrs: {
                  "show-page-size-selector": true,
                  "allowed-page-sizes": [15, 20, 50],
                  "show-navigation-buttons": true,
                  "show-info": true,
                },
              }),
              _vm._v(" "),
              _c("DxFilterRow", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxHeaderFilter", { attrs: { visible: true } }),
              _vm._v(" "),
              _c("DxGroupPanel", { attrs: { visible: true } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }