var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.direction ? "k-rtl" : "", "rayvarzBPMSTreeWrapper"] },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.enableSearch,
              expression: "enableSearch",
            },
          ],
          staticStyle: {
            width: "100%",
            position: "relative",
            "margin-bottom": "5px",
          },
        },
        [
          _c("input", {
            staticClass: "treeViewSearch",
            staticStyle: {
              width: "100%",
              border: "2px solid #9c9c9c !important",
              "border-radius": "10px",
            },
            attrs: {
              type: "text",
              placeholder: _vm.$t("logs_management.search"),
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "clearTreeViewSearch",
              staticStyle: {
                position: "absolute",
                top: "0",
                "border-radius": "5px",
                right: "7px",
                "z-index": "2",
                border: "none",
                bottom: "15px",
                height: "22px",
                cursor: "pointer",
                color: "darkslategray",
                "background-color": "transparent",
                transform: "translateX(2px)",
              },
            },
            [_vm._v("x")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.HasCartablAccess
        ? _c("div", { staticClass: "rayvarzBPMSTreeView" })
        : _vm._e(),
      _vm._v(" "),
      _c("contextMenu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasContextMenu,
            expression: "hasContextMenu",
          },
        ],
        attrs: {
          target: ".rayvarzBPMSTreeView",
          filter: "span.k-in",
          data: _vm.treeContextItems,
          select: _vm.treeContextItemSelect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }