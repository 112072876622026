import ContextMenuItem from "./contextmenuitem/contextMenuItem.vue";

export default {
    props: [
        "target",
        "filter",
        "data",
        "select",
        "open"
    ],
    data() {
        return {
            contextMenuEl: null,
            contextData: null,
            items: {},
            direction: payload.currentLocale.IsRightToLeft
        }
    },
    watch: {
        data() {
            if (this.contextData && this.data && this.data.length) {
                this.items = this.data;
                this.updateDataModel();
                this.updateDataSource();
            } else {
                this.init();
            }
        }
    },
    methods: {
        init() {
            if (this.data) {
                if (Array.isArray(this.data)) {
                    this.items = this.data;
                    this.updateDataModel();
                }
                this.contextData = this.contextMenuEl.kendoContextMenu(this.buildConfig()).data('kendoContextMenu');
                if (this.direction && !this.contextMenuEl.hasClass('k-rtl')) {
                    this.contextMenuEl.addClass('k-rtl');
                }
            }
        },
        buildConfig() {
            return {
                orientation: "vertical",
                target: this.target,
                filter: this.filter,
                select: this.onSelect,
                open: this.onOpen,
                activate: this.onActivate,
                close: this.onClose,
                deactivate: this.onDeactivate,
                //alignToAnchor: false,
                dataSource: this.items
            }
        },
        updateDataSource() {
            if (this.data && this.data.length) {
                this.contextData.setOptions({
                    dataSource: this.items
                });
            }
        },
        updateDataModel() {
            if (this.items) {
                let mapping = (val) => {
                    return {
                        key: val.key,
                        text: (val.icon) ?
                            `<span key="${val.key}"><i class="${val.icon} fa-fw fa-lg"></i> ${val.text}</span>` :
                            `<span key="${val.key}">${val.text}</span>`,
                        items: val.items && val.items.length ? val.items.map(mapping) : null,
                        // imageUrl: val.imageUrl,
                        encoded: false,
                        action: val.action
                    };
                };
                this.items = this.items.map(mapping);
            }
        },
        onSelect(e) {
            let key = $('[key]', e.item).attr('key');
            let item = this.items.find((value, index, obj) => { return value.key == key; });
            if (item === undefined) {
                let itemWithSubItems = this.items.filter((value) => {
                    return value.items;
                })
                if (itemWithSubItems.length) {
                    itemWithSubItems.some((n) => {
                        let subItem = n.items.find((subValue) => {
                            return subValue.key == key;
                        });
                        if (subItem) {
                            item = subItem;
                            return true;
                        }
                    });
                }
            }
            let action = item ? item.action : null;
            if (typeof action === 'function') {
                if (typeof this.select === 'function') {
                    this.select(e.target, action);
                }
            }
        },
        onOpen(e) {
            let that = e.target;
            var attr = $(e.item.context).attr('data-role');
            let isSubMenu =attr=== undefined;
            if (typeof this.data === 'function') {
                this.items = this.data(that);
                this.updateDataModel();
                this.updateDataSource();
            }
            if (typeof this.open === 'function') {
                this.open(that,isSubMenu);
            }
        },
        onActivate(e) {
        },
        onClose(e) {
        },
        onDeactivate(e) {
        }
    },
    updated() {
    },
    mounted() {
        this.contextMenuEl = $('.rayvarzBPMSContextMenu', this.$el);
        this.init();
    },
    components: {
        ContextMenuItem
    }
}
