import NewForm from '../forms/newform/newForm.vue';
import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRequiredRule,
    DxRangeRule,
    DxValueFormat,
    DxPager,
    DxPaging,
    DxExport
} from 'devextreme-vue/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

const url = 'api/Logs/GetAuditTrail';

const dataSource = createStore({
    key: 'Id',
    loadUrl: url,
    onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        ajaxOptions.headers = { "Content-type": "application/json" };
    }
});

export default {
    data() {
        return {
            direction: payload.currentLocale.IsRightToLeft,
            datetimeFormat: `${payload.currentLocale.ShortDateFormat} ${payload.currentLocale.ShortTimeFormat}`,
            url,
            dataSource
        }
    },
    methods: {
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function (options) {
                    options.excelCell.font = { name: 'Arial', size: 12 };
                    options.excelCell.alignment = { horizontal: 'left' };
                }
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'AuditTrail.xlsx');
                    });
            });
        }
    },

    updated() {
    },

    mounted() {
    },

    components: {
        NewForm,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRangeRule,
        DxRequiredRule,
        DxValueFormat,
        DxPager,
        DxPaging,
        DxExport
    }
}
